<style lang="scss" scoped>
path {
    fill: currentColor;
}
</style>

<template>
    <svg
        width="40"
        height="44"
        viewBox="0 0 40 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M26.2942 37.9628C26.0907 41.2621 23.3505 43.875 20 43.875C16.6495 43.875 13.9092 41.2621 13.7058 37.9628H4.24201C2.06066 37.9628 0.29277 36.1989 0.29277 34.0214C0.29277 31.847 2.05933 30.08 4.23586 30.08C4.66826 30.08 5.0225 29.7255 5.0225 29.2903V19.8316C5.0225 13.1644 9.41238 7.37644 15.6644 5.49165V4.46126C15.6644 2.06768 17.6082 0.125 20 0.125C22.3959 0.125 24.3356 2.06326 24.3356 4.46126V5.49174C30.5874 7.37677 34.9775 13.1659 34.9775 19.8316V29.2903C34.9775 29.7299 35.3277 30.08 35.7641 30.08C37.9371 30.08 39.7072 31.8465 39.7072 34.0214C39.7072 36.1962 37.9375 37.9628 35.7579 37.9628H26.2942ZM24.7135 37.9628H15.2864C15.4867 40.3906 17.5205 42.2984 20 42.2984C22.4794 42.2984 24.5132 40.3906 24.7135 37.9628ZM16.6491 6.85324C10.7767 8.36399 6.59908 13.6797 6.59908 19.8316V29.2903C6.59908 30.596 5.53919 31.6565 4.23586 31.6565C2.93014 31.6565 1.86935 32.7176 1.86935 34.0214C1.86935 35.3274 2.93059 36.3863 4.24201 36.3863H35.7579C37.0674 36.3863 38.1306 35.3249 38.1306 34.0214C38.1306 32.7179 37.0671 31.6565 35.7641 31.6565C34.4571 31.6565 33.4009 30.6008 33.4009 29.2903V19.8316C33.4009 13.6811 29.223 8.36421 23.3508 6.8533L22.759 6.70101V4.46126C22.759 2.93423 21.5255 1.70158 20 1.70158C18.4787 1.70158 17.241 2.93861 17.241 4.46126V6.70097L16.6491 6.85324Z"
            fill="#1C9DD5" />
    </svg>
</template>

<script>
    export default {}
</script>
