import Vue from 'vue'
import * as helpers from 'helpers/'

Object.defineProperties(Vue.prototype, {
    $help: {
        get () {
            return {
                ...helpers,
            }
        },
    },
})
