
import als from 'api/als'
import common from 'config/common'
import i18n from 'plugins/i18n'

export default {
    state: {
        notificationsLoading: false,
        notifications: [],
        notificationsError: null,
    },
    mutations: {
        setNotificationsLoading(state, payload) {
            state.notificationsLoading = payload
        },
        setNotifications(state, payload) {
            state.notifications = payload
        },
        setNotificationsError(state, payload) {
            state.notificationsError = payload
        },
    },
    actions: {
        async getNotifications({ commit, }) {
            commit('setNotificationsLoading', true)
            try {
                const { data, } = await als.get('notifications')
                commit('setNotifications', data || [])
                commit('setNotificationsError', false)
            } catch (error) {
                commit('showMessage', { text: error.errors, type: common.messageType.ERROR, })
                commit('setNotificationsError', error)
            }
            commit('setNotificationsLoading', false)
        },
        async createNotification({ commit, dispatch, }, notificationData) {
            commit('setNotificationsLoading', true)
            notificationData['enabled'] = true // should always be true for a new notification till toggle is implemented on UI
            try {
                const { data, } = await als.post('notifications', notificationData)
                if (data) {
                    await dispatch('getNotifications')
                    commit('showMessage', { text: i18n.t('success.notificationCreated'), type: common.messageType.SUCCESS, })
                }
            } catch (error) {
                commit('showMessage', { text: error.errors, type: common.messageType.ERROR, })
                commit('setNotificationsError', error)
            }
            commit('setNotificationsLoading', false)
        },
        async updateNotification({ commit, dispatch, }, {id, notificationData, }) {
            commit('setNotificationsLoading', true)
            try {
                const { data, } = await als.put(`notifications/${id}`, notificationData)
                if (data) {
                    await dispatch('getNotifications')
                    commit('showMessage', { text: i18n.t('success.notificationEdited'), type: common.messageType.SUCCESS, })
                }
                commit('setNotificationsError', false)
            } catch (error) {
                commit('showMessage', { text: error.errors, type: common.messageType.ERROR, })
                commit('setNotificationsError', error)
            }
            commit('setNotificationsLoading', false)
        },
        async deleteNotifications({ commit, dispatch, }, notificationsArray) {
            commit('setNotificationsLoading', true)
            try {
                await Promise.all(notificationsArray.map(async notification => {
                    await als.delete(`notifications/${notification.id}`)
                }))
                await dispatch('getNotifications')
            } catch (error) {
                commit('showMessage', { text: error.errors, type: common.messageType.ERROR, })
                commit('setNotificationsError', error)
            }
            commit('setNotificationsLoading', false)
        },
    },
    getters: {
        notificationsLoading: (state) => state.notificationsLoading,
        notifications: (state) => state.notifications,
        notificationsError: (state) => state.notificationsError,
    },
}
