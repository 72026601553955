import base from 'api/base'
const baseUrl = process.env.VUE_APP_API_GATEWAY_BASE_URL
const policyURI = 'als/v1'

export default {
    post (uri, body, params, options) {
        return base.post(`${baseUrl}/${policyURI}/${uri}`, body, params, options)
    },
    get (uri, params, options) {
        return base.get(`${baseUrl}/${policyURI}/${uri}`, params, options)
    },
    delete (uri, params) {
        return base.delete(`${baseUrl}/${policyURI}/${uri}`, params)
    },
}
