<style lang="scss" scoped>
.check-mark {
    color: $success
}
</style>
<template>
    <v-row class="pa-0 ma-0">
        <v-col
            class="pa-0 ma-0 mt-1"
            cols="1">
            <v-icon
                class="check-mark"
                size="20">
                $vuetify.icons.statusOk
            </v-icon>
        </v-col>
        <v-col class="pa-0">
            {{ text }}
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'FeatureLine',
        props: {
            text: {
                type: String,
                required: true,
            },
        },
    }
</script>
