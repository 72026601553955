<template>
    <span
        v-if="topology && topology.inboundReplication &&
            topology.inboundReplication.externalReplica &&
            topology.inboundReplication.internalReplica === server.dataSourceId">

        <!-- Status OK -->
        <span
            v-if="topology.inboundReplication.status === 'OK'"
            class="grey--text text--lighten-1">
            {{ $tc('inboundReplicationFrom') }}
            {{ topology.inboundReplication.externalReplica }}
            <span
                v-if="topology.inboundReplication.metadata.slaveLag"
                class="color text-text-subtle">
                (+{{ topology.inboundReplication.metadata.slaveLag }}s)
            </span>
        </span>

        <!-- Status CRITICAL || WARNING --> <!-- STOP || STOPPING || STARTING statues missing  for IB -->
        <span
            v-else-if="topology.inboundReplication.status === 'CRITICAL' ||
                topology.inboundReplication.status === 'WARNING'"
            :class="[`color text-${topology.inboundReplication.status === 'CRITICAL' ? 'error' : 'warning'}`]">
            {{ $tc('inboundReplicationFrom') }}
            {{ topology.inboundReplication.externalReplica }}
            - {{ $tc('error') }}:
            <span
                v-if="topology.inboundReplication.metadata.slaveLastError &&
                    topology.inboundReplication.metadata.slaveLastError !== '0'">
                {{ topology.inboundReplication.metadata.slaveLastError }}
            </span>
            <span
                v-else
                class="color text-error">
                {{ $tc('errors.replicationNotRunning') }}
            </span>
        </span>
    </span>
</template>

<script>
    export default {
        name: 'InboundReplicationInfo',
        props: {
            topology: { type: Object, required: true, },
            server: { type: Object, required: true, },
        },
    }
</script>
