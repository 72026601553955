<style lang="scss" scoped>
    @keyframes transparencyAnimation {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.4;
        }
        100% {
            opacity: 1;
        }
    }

    .animate-transparency {
        animation: transparencyAnimation 2s infinite;
    }
</style>

<template>
    <v-icon
        :class="['pa-0', { 'animate-transparency': animate }]"
        :size="size"
        :color="colorValue">
        {{ icon }}
    </v-icon>
</template>

<script>
    /*eslint-disable */
    // TODO: Icons needs underlying refactoring
    export default {
        name: 'IconSpriteSheet',
        props: {
            frame: {
                type: String,
                required: true,
            },
            size: [Number, String],
            color: String,
            frames: [Array, Object],
        },
        data () {
            return {
                emptyIcon: 'mdi-bug', // icon to be shown if there is a missing "frame"
                sheets: {
                    status: {
                        frames: [
                            '$vuetify.icons.statusError', '$vuetify.icons.statusWarning',
                            '$vuetify.icons.statusOk', '$vuetify.icons.statusInfo',
                            '$vuetify.icons.stopped', '$vuetify.icons.statusWarning'
                        ],
                    },
                    health: {
                        frames: {
                            // TODO: Refactor after topology status integration
                            CRITICAL: '$vuetify.icons.healthBad',
                            failed: '$vuetify.icons.serviceFailed',
                            WARNING: '$vuetify.icons.healthAlert',
                            SCALINGUP: '$vuetify.icons.scalingUp',
                            SCALINGDOWN: '$vuetify.icons.scalingDown',
                            STOPPED: '$vuetify.icons.stopped',
                            stopped: '$vuetify.icons.serviceStopped',
                            STOPPING: '$vuetify.icons.stopped',
                            STARTING: '$vuetify.icons.starting',
                            OK: '$vuetify.icons.healthOk',
                            ready: '$vuetify.icons.serviceReady',
                            pending: '$vuetify.icons.servicePending',
                            pending_create: '$vuetify.icons.servicePending',
                            pending_modifying: '$vuetify.icons.servicePending',
                            pending_start: '$vuetify.icons.servicePendingStart',
                            pending_stop: '$vuetify.icons.servicePendingStop',
                            pending_scale: '$vuetify.icons.servicePendingScale',
                            pending_delete: '$vuetify.icons.servicePendingDelete',
                            UNKNOWN: '$vuetify.icons.unknown',
                            unknown: '$vuetify.icons.unknown',
                        },
                    },
                    server: {
                        frames: {
                            PROVISIONING: '$vuetify.icons.serverProvisioning',
                            STARTING: '$vuetify.icons.starting',
                            WARNING: '$vuetify.icons.healthAlert',
                            STOPPED: '$vuetify.icons.stopped',
                            STOPPING: '$vuetify.icons.stopped',
                            PRIMARY: '$vuetify.icons.primary',
                            REPLICA: '$vuetify.icons.replica',
                            CRITICAL: '$vuetify.icons.serverCritical',
                            OK: '$vuetify.icons.serverOk',
                            CONNECTIONCRITICAL: '$vuetify.icons.connectivityBad',
                            UNKNOWN: '$vuetify.icons.unknown',
                        },
                        colors: {
                            PRIMARY: '#424F62',
                            REPLICA: '#2F99A3',
                        },
                    },
                    alert: {
                        frames: {
                            CRITICAL: '$vuetify.icons.healthBad',
                            WARNING: '$vuetify.icons.healthAlert',
                            INFO: '$vuetify.icons.alertInfo',
                        },
                    },
                    notification: {
                        frames: {
                            ok: '$vuetify.icons.healthOk',
                            info: '$vuetify.icons.statusInfo',
                            warn: '$vuetify.icons.statusWarning',
                            error: '$vuetify.icons.healthBad',
                        },
                    },
                    delete: {
                        frames: ['$vuetify.icons.restore', '$vuetify.icons.delete'],
                    },
                    toggle: {
                        frames: ['mdi-plus', 'mdi-minus'],
                    },
                    cloud: {
                        frames: {
                            gcp: '$vuetify.icons.gcp',
                            aws: '$vuetify.icons.aws',
                            private: '$vuetify.icons.dataCenter',
                        },
                    },
                },
            }
        },
        computed: {
            sheet () {
                const name = this.$slots.default ? this.$slots.default[0].text.trim() : ''
                const sheet = this.sheets[name] || {}
                const frames = this.frames || sheet.frames || []
                const colors = sheet.colors || {}

                return { frames, colors, }
            },
            icon () {
                if (!this.sheet.frames[this.frame]) return this.emptyIcon

                return this.sheet.frames[this.frame]
            },
            colorValue () {
                return this.color || this.sheet.colors[this.frame]
            },
            animate () {
                return this.frame === 'STOPPING' ||
                    this.frame === 'STARTING' ||
                    this.frame.includes('pending')
            },
        },
    }
</script>
