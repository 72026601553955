<style lang="scss" scoped>
.title-text {
    font-size: 18px
}
</style>

<template>
    <div>
        <div class="title-text font-weight-bold mb-3">
            {{ $t('serviceDetails') }}
        </div>
        <div>
            <v-row>
                <v-col cols="4">
                    {{ isPrivateConnect ? $t('serviceDetailsTab.secureEndpoint') : $t('fullyQualifiedDomainName') }}
                </v-col>
                <v-col cols="5">
                    {{ secureEndpointValue || $t('notApplicable') }}
                </v-col>
                <v-col
                    v-if="secureEndpointValue"
                    class="px-0">
                    <v-btn
                        color="primary"
                        icon
                        x-small
                        @click="copyToClipboard(secureEndpointValue)">
                        <v-icon size="16">
                            mdi-content-copy
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    class="py-0"
                    cols="4">
                    {{ $t('readOnlyPort') }}
                </v-col>
                <v-col
                    class="py-0"
                    cols="4">
                    {{ readOnlyPort || $t('notApplicable') }}
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    class="py-0"
                    cols="4">
                    {{ $t('readWritePort') }}
                </v-col>
                <v-col
                    class="py-0"
                    cols="4">
                    {{ readWritePort || $t('notApplicable') }}
                </v-col>
            </v-row>
            <v-row v-if="service.nosql_enabled">
                <v-col
                    class="py-0"
                    cols="4">
                    {{ $t('noSQLPort') }}
                </v-col>
                <v-col
                    class="py-0"
                    cols="4">
                    {{ noSQLPort || $t('notApplicable') }}
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    class="py-0"
                    cols="4">
                    {{ $t('username') }}
                </v-col>
                <v-col
                    cols="3"
                    class="py-0 mr-0 pr-0">
                    <div>
                        {{ serviceCredentials.username || $t('notApplicable') }}
                        <v-btn
                            color="primary"
                            class="ml-2"
                            icon
                            x-small
                            @click="copyToClipboard(serviceCredentials.username)">
                            <v-icon size="16">
                                mdi-content-copy
                            </v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    class="py-0"
                    cols="4">
                    {{ $t('defaultPassword') }}
                </v-col>
                <v-col
                    class="py-0"
                    cols="5">
                    <div
                        v-if="serviceCredentials.password"
                        class="d-flex align-center">
                        <div
                            class="pointer"
                            @click="copyToClipboard(serviceCredentials.password)">
                            <span v-if="showPassword">
                                {{ serviceCredentials.password }}
                            </span>
                            <span v-else>{{ serviceCredentials.password.replace(/./g, '*') }}</span>
                        </div>
                        <div>
                            <v-btn
                                color="primary"
                                height="20"
                                class="ml-1"
                                icon
                                x-small
                                @click="showPassword = !showPassword">
                                <v-icon>
                                    {{ showPassword ? 'mdi-eye-off' : 'mdi-eye' }}
                                </v-icon>
                            </v-btn>
                            <v-btn
                                color="primary"
                                class="ml-1"
                                icon
                                x-small
                                @click="copyToClipboard(serviceCredentials.password)">
                                <v-icon size="16">
                                    mdi-content-copy
                                </v-icon>
                            </v-btn>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    class="py-0"
                    cols="4">
                    {{ $t('dualEndpoints.certificateChain') }}
                </v-col>
                <v-col class="py-0">
                    <a
                        :href="serviceCredentials.authChainURL"
                        target="_blank">{{ $t('download') }}</a>
                </v-col>
            </v-row>
        </div>
        <ConnectModalWarningBlock
            :service="service"
            :endpoint="endpoint"
            :is-private-connect="isPrivateConnect"
            :private-connect-type="privateConnectType"
            :has-private-connect-projects="hasPrivateConnectProjects"
            @on-setup-private-connect="setupPrivateConnect"
            @on-manage-allowlist="manageAllowList" />
    </div>
</template>
<script>
    import { mapActions } from 'vuex'
    import { getNoSQLPort, getReadOnlyPort, getReadWritePort, isEndpointPrivateConnect, privateConnectLabel } from 'utils/service'
    import ConnectModalWarningBlock from 'components/service/ConnectModalWarningBlock.vue'

    const ConnectServiceDetails = {
        name: 'ConnectServiceDetails',
        components: { ConnectModalWarningBlock, },
        props: {
            service: {
                type: Object,
                required: true,
            },
            serviceCredentials: {
                type: Object,
                default: () => { },
            },
            endpoint: {
                type: Object,
                required: true,
            },
            hasPrivateConnectProjects: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                showPassword: false,
            }
        },
        computed: {
            readOnlyPort() {
                const readOnlyPort = getReadOnlyPort(this.endpoint)
                return readOnlyPort && readOnlyPort.port
            },
            readWritePort() {
                const readOnlyPort = getReadWritePort(this.endpoint)
                return readOnlyPort && readOnlyPort.port
            },
            isPrivateConnect() {
                return isEndpointPrivateConnect(this.endpoint)
            },
            secureEndpointValue() {
                return this.isPrivateConnect ? this.endpoint.endpoint_service : this.endpoint.fqdn
            },
            privateConnectType() {
                return privateConnectLabel(this.service.provider, true)
            },
            noSQLPort() {
                const noSQLPort = getNoSQLPort(this.endpoint)
                return noSQLPort && noSQLPort.port
            },
        },
        methods: {
            ...mapActions(['copyToClipboard']),
            setupPrivateConnect() {
                return this.$emit('on-setup-private-connect')
            },
            manageAllowList() {
                return this.$emit('on-manage-allowlist')
            },
        },
    }

    export default ConnectServiceDetails
</script>
