<style lang="scss">
label.required:before {
    content: "* ";
    color: $required-asterisk;
}

iframe {
    width: 100% !important;
}

.field-required {
    &:before {
        content: "* ";
        color: $required-asterisk;
    }
}

.otp-wrapper {
    width: 300px;
}

.edit-phone-number{
    min-height: 200px;
}

</style>
<template>
    <div>
        <div class="payment-option">
            <base-dialog
                v-model="isPaymentOpen"
                :min-width="800"
                :max-width="800"
                :title="$t('billings.addCreditCard')"
                :on-close="closePaymentModal"
                :on-cancel="closePaymentModal"
                @input="loadChargifyJs()">
                <template #body>
                    <div class="pt-6 pl-6 pr-6">
                        <div
                            v-if="!$unleash.isFeatureEnabled('disable-portal-free-trial') && product !== 'monitor'"
                            class="pb-6 mb-6">
                            {{ $t('billings.paymentTrialNote') }}
                        </div>
                        <v-row>
                            <v-col class="pt-0 pb-0">
                                <label class="required text-uppercase">{{ $t('billings.firstName') }}</label>
                                <v-text-field
                                    v-model="firstName"
                                    outlined
                                    dense />
                            </v-col>
                            <v-col class="pt-0 pb-0">
                                <label class="required text-uppercase">{{ $t('billings.lastName') }}</label>
                                <v-text-field
                                    v-model="lastName"
                                    outlined
                                    dense />
                            </v-col>
                        </v-row>
                        <div>
                            <form id="chargify-form">
                                <div id="chargify3"></div>
                                <v-row>
                                    <v-col
                                        class="pt-0 pb-0"
                                        cols="3">
                                        <div id="chargify4"></div>
                                    </v-col>
                                    <v-col
                                        class="pt-0 pb-0"
                                        cols="3">
                                        <div id="chargify5"></div>
                                    </v-col>
                                    <v-col
                                        class="pt-0 pb-0"
                                        cols="3" />
                                    <v-col
                                        class="pt-0 pb-0"
                                        cols="3">
                                        <div id="chargify6"></div>
                                    </v-col>
                                </v-row>
                                <div class="host-field">
                                    <input
                                        type="hidden"
                                        class="host-input"
                                        data-chargify="firstName"
                                        :value="firstName" />
                                </div>
                                <div class="host-field">
                                    <input
                                        type="hidden"
                                        class="host-input"
                                        data-chargify="lastName"
                                        :value="lastName" />
                                </div>
                                <div class="host-field">
                                    <input
                                        type="hidden"
                                        class="host-input"
                                        data-chargify="address"
                                        :value="address" />
                                </div>
                                <div class="host-field">
                                    <input
                                        type="hidden"
                                        class="host-input"
                                        data-chargify="address2"
                                        :value="address2" />
                                </div>
                                <div class="host-field">
                                    <input
                                        type="hidden"
                                        class="host-input"
                                        data-chargify="city"
                                        :value="city" />
                                </div>
                                <div class="host-field">
                                    <input
                                        type="hidden"
                                        class="host-input"
                                        data-chargify="state"
                                        :value="state" />
                                </div>
                                <div class="host-field">
                                    <input
                                        type="hidden"
                                        class="host-input"
                                        data-chargify="country"
                                        :value="country" />
                                </div>
                                <div class="host-field">
                                    <input
                                        type="hidden"
                                        class="host-input"
                                        data-chargify="zip"
                                        :value="zip" />
                                </div>
                                <div class="host-field">
                                    <input
                                        type="hidden"
                                        class="host-input"
                                        data-chargify="vatNumber"
                                        :value="vatNumber" />
                                </div>
                            </form>
                        </div>
                        <v-row class="mt-2">
                            <v-col class="pt-0 pb-0">
                                <label class="text-uppercase">{{ $t('billings.code') }}</label>
                                <v-text-field
                                    v-model="couponCode"
                                    outlined
                                    :placeholder="$t('billings.code')"
                                    dense />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pt-0 pb-0">
                                <label class="required text-uppercase">{{ $t('billings.address1') }}</label>
                                <v-text-field
                                    v-model="address"
                                    outlined
                                    :placeholder="$t('billings.address1')"
                                    dense />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pt-0 pb-0">
                                <label class="text-uppercase">{{ $t('billings.address2') }}</label>
                                <v-text-field
                                    v-model="address2"
                                    outlined
                                    :placeholder="$t('billings.address2')"
                                    dense />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pt-0 pb-0">
                                <label class="required text-uppercase">{{ $t('billings.country') }}</label>
                                <v-select
                                    v-model="currentCountry"
                                    :items="sortedCountries"
                                    item-text="name"
                                    item-val="iso31662"
                                    height="40"
                                    :dense="true"
                                    class="mt-0 select-usage"
                                    outlined
                                    return-object
                                    @change="changeCountry" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pt-0 pb-0">
                                <label class="required text-uppercase">{{ $t('billings.stateProvince') }}</label>
                                <v-select
                                    v-model="currentState"
                                    :items="sortedStates"
                                    item-text="name"
                                    item-val="iso31662"
                                    height="40"
                                    :dense="true"
                                    class="mt-0 select-usage"
                                    outlined
                                    return-object
                                    @change="changeState" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pt-0 pb-0">
                                <label class="required text-uppercase">{{ $t('billings.city') }}</label>
                                <v-text-field
                                    v-model="city"
                                    outlined
                                    :placeholder="$t('billings.city')"
                                    dense />
                            </v-col>
                            <v-col class="pt-0 pb-0">
                                <label class="required text-uppercase">{{ $t('billings.zipCode') }}</label>
                                <v-text-field
                                    v-model="zip"
                                    outlined
                                    :placeholder="$t('billings.zipCode')"
                                    dense />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pt-0 pb-0">
                                <label class="text-uppercase">{{ $t('billings.vatNumber') }}</label>
                                <v-text-field
                                    v-model="vatNumber"
                                    outlined
                                    :placeholder="$t('billings.vatNumber')"
                                    dense />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pt-0 pb-0">
                                <label class="required text-uppercase">{{ $t('billings.phoneNumber') }}</label>
                                <VueTelInput
                                    v-model="phone"
                                    mode="auto"
                                    @input="onPhoneInput" />
                            </v-col>
                        </v-row>
                    </div>
                </template>
                <template #actions="{ cancel }">
                    <v-btn
                        color="primary"
                        rounded
                        :disabled="isPhoneVerified || isFetchingOTP"
                        class="text-capitalize px-3"
                        @click="triggerPhoneVerification">
                        {{ $t('billings.validatePhone') }}
                        <v-progress-circular
                            v-if="isFetchingOTP"
                            class="ml-4"
                            :width="5"
                            :size="20"
                            indeterminate />
                    </v-btn>
                    <v-spacer />
                    <v-btn
                        color="primary"
                        depressed
                        rounded
                        outlined
                        class="cancel"
                        @click="cancel">
                        <span class="text-none">{{ $t('cancel') }} </span>
                    </v-btn>
                    <v-btn
                        color="primary"
                        depressed
                        rounded
                        :disabled="!isPhoneVerified"
                        :loading="isSaving"
                        @click.prevent="submitCCData">
                        <span class="text-none"> {{ $t('save') }}</span>
                    </v-btn>
                </template>
            </base-dialog>
        </div>
        <div class="phone-verification">
            <base-dialog
                v-model="isPhoneVerifyOpen"
                :min-width="900"
                :max-width="900">
                <template #title>
                    {{ $t('billings.pleaseCheckPhone') }}
                </template>
                <template #body>
                    <div class="pt-2">
                        <div v-if="isOTPSent">
                            <div>
                                {{ $t('billings.sentOTP', { phone }) }}  <a @click="changePhone">{{ $t('billings.changePhone') }}</a>
                            </div>
                            <div class="otp-wrapper mt-6">
                                <v-otp-input
                                    v-model="otpCode"
                                    type="number"
                                    length="6" />
                            </div>
                            <div>
                                {{ $t('billings.codeNotReceived') }}
                                <span v-if="secondBeforeResend">
                                    {{ $t('billings.sendAgainCode', { seconds : secondBeforeResend }) }}
                                </span>
                                <a
                                    v-else
                                    @click="resendOTPCode">
                                    {{ $t('billings.resendCode') }}
                                </a>
                            </div>
                            <div
                                v-if="otpErrorMsg"
                                class="mt-2 text-h6 red--text font-weight-bold">
                                <span> {{ otpErrorMsg }} </span>
                            </div>
                        </div>
                        <div
                            v-else
                            class="edit-phone-number">
                            <div class="mb-2">
                                {{ $t('billings.editPhone') }}
                            </div>
                            <v-row>
                                <v-col cols="5">
                                    <VueTelInput
                                        v-model="updatedPhoneNumber"
                                        @input="onUpdatedPhoneInput" />
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </template>
                <template #actions="{ cancel }">
                    <v-spacer />
                    <v-btn
                        color="primary"
                        depressed
                        rounded
                        outlined
                        class="cancel"
                        @click="cancel">
                        <span class="text-none">{{ $t('cancel') }} </span>
                    </v-btn>
                    <v-btn
                        color="primary"
                        depressed
                        rounded
                        @click="phoneVerificationAction">
                        <span class="text-none"> {{ $t('save') }}</span>
                    </v-btn>
                </template>
            </base-dialog>
        </div>
    </div>
</template>

<script>
    import to from 'await-to-js'
    import { sendCodeToPhone, verifyPhoneCode } from 'services/billings'
    import { mapGetters, mapMutations, mapActions } from 'vuex'
    import { VueTelInput } from 'vue-tel-input'
    import 'vue-tel-input/dist/vue-tel-input.css'

    const MARIADB_AB_CORP_CF = 'mariadb-ab'

    export default {
        name: 'Payment',
        components: {
            VueTelInput,
        },
        props: {
            paymentProfile: {
                type: Object,
                required: true,
            },
            legalEntityIdentifier: {
                type: String,
                required: true,
            },
            product: {
                type: String,
                default: '',
            },
            primaryContactCountry: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                chargify: new window.Chargify(),
                chargifyToken: '',
                isOTPSent: false,
                updatedPhoneNumber: null,
                secondBeforeResend: 30,
                timer: null,
                firstName: null,
                lastName: null,
                address: null,
                address2: null,
                country: null,
                state: null,
                city: null,
                zip: null,
                vatNumber: null,
                phone: null,
                couponCode: null,
                currentCountry: {},
                currentState: {},
                otpCode: null,
                otpErrorMsg: null,
                verifiedPhone: null,
                corpAB: null,
                isFetchingOTP: false,
                isSaving: false,
            }
        },
        computed: {
            ...mapGetters([
                'isPaymentDialogOpen',
                'user',
                'isPhoneVerificationDialogOpen',
                'billingCountries',
                'billingStates',
                'orgData',
                'isFetchingPaymentDetail',
                'updateProfileSuccess'
            ]),
            serverHost() {
                if (this.corpAB) return process.env.VUE_APP_CHARGIFY_AB_CORP_URL
                return process.env.VUE_APP_CHARGIFY_URL
            },
            publicKey() {
                if (this.corpAB) return process.env.VUE_APP_CHARGIFY_AB_CORP_PUBLIC_KEY
                return process.env.VUE_APP_CHARGIFY_PUBLIC_KEY
            },
            isPaymentOpen: {
                get() {
                    return this.isPaymentDialogOpen
                },
                set(value) {
                    this.setPaymentDialog(value)
                },
            },
            isPhoneVerifyOpen: {
                get() {
                    return this.isPhoneVerificationDialogOpen
                },
                set(value) {
                    this.setPhoneVerificationDialog(value)
                },
            },
            sortedCountries(){
                const clonedCountries = JSON.parse(JSON.stringify(this.billingCountries))
                return clonedCountries.sort((a, b) => a.name.localeCompare(b.name))
            },
            sortedStates() {
                const clonedStates = JSON.parse(JSON.stringify(this.billingStates))
                clonedStates.sort((a, b) => a.name.localeCompare(b.name))
                if (this.$typy(this.currentCountry, 'name').safeString) {
                    clonedStates.filter(state => state.country === this.currentCountry.name)
                }
                return clonedStates
            },
            isPhoneVerified(){
                if(!this.verifiedPhone || !this.phone) return false
                return this.verifiedPhone === this.phone
            },
        },
        watch:{
            billingCountries(countries){
                this.currentCountry = countries.find(country => country.iso31662 === this.country)
                this.fetchStatesForCountry({country: this.country,})
            },
            isPaymentDialogOpen(value){
                if(value) {
                    // This work around is to delay iframe
                    // rendering of chargify module
                    this.$nextTick(()=>{
                        this.loadChargifyJs()
                    })
                }
            },
            billingStates(states){
                if(!this.state) return
                this.currentState = states.find(state => state.isoShort === this.state)
            },
            otpCode(value,oldValue){
                if(value !== oldValue) {
                    this.otpErrorMsg = null
                }
            },
        },
        methods: {
            ...mapMutations(['setPaymentDialog', 'setPhoneVerificationDialog']),
            ...mapActions([
                'togglePhoneVerification',
                'fetchCountries',
                'fetchStatesForCountry',
                'registerPaymentProfile'
            ]),
            submitCCData() {
                this.chargify.token(
                    document.querySelector('#chargify-form'),
                    (token) => {
                        // The chargify js library provides this
                        // old school success,error callback function
                        // to handle async request.
                        // TODO:: Promisify this library method
                        this.chargifyToken = token
                        this.updateProfileInfo()
                    },
                    (error) => {
                        // eslint-disable-next-line no-console
                        console.log('{host} token ERROR - err: ', error)
                    }
                )
            },
            loadChargifyJs() {
                this.chargify.load({
                    publicKey: this.publicKey,
                    type: 'card',
                    serverHost: this.serverHost,
                    addressDropdowns: true,
                    fields: {
                        number: {
                            selector: '#chargify3',
                            placeholder: 'xxxx xxxx xxxx xxxx',
                        },
                        month: {
                            selector: '#chargify4',
                            placeholder: 'MM',
                        },
                        year: {
                            selector: '#chargify5',
                            placeholder: 'YYYY',
                        },
                        cvv: {
                            selector: '#chargify6',
                            placeholder: 'CVV',
                            required: true,
                        },
                    },
                })
            },
            async triggerPhoneVerification() {
                await this.initiateOTPForPhone()
                this.togglePhoneVerification(true)
            },
            changePhone(){
                this.isOTPSent = false
                this.updatedPhoneNumber = this.phone
            },
            async phoneVerificationAction() {
                if (this.isOTPSent) {
                    const [error, success] = await to(verifyPhoneCode(this.phone, this.otpCode))
                    if (error) {
                        this.otpErrorMsg = this.$t('billings.unknownOTPError')
                    } else {
                        if (success && success.status) {
                            // Code has been verified
                            // close the OTP modal and mark
                            // the Phone verified and enable
                            // save profile button
                            this.verifiedPhone = this.phone
                            this.togglePhoneVerification(false)
                        } else {
                            this.otpErrorMsg = this.$t('billings.invalidCodeEntered')
                        }
                    }
                } else {
                    this.phone = this.updatedPhoneNumber
                    await this.resendOTPCode()
                }
            },
            countDownTimer() {
                this.timer = setInterval(this.reduceTimer, 1000)
            },
            reduceTimer(){
                if(this.secondBeforeResend > 0){
                    this.secondBeforeResend -=1
                }else{
                    this.resetTimer()
                }
            },
            changeCountry(data){
                this.country = data.iso31662
                this.fetchStatesForCountry({country: data.iso31662,})
                if(!this.legalEntityIdentifier) {
                    // New user whose profile is not created
                    // lei depends on country selection
                    this.corpAB = data.lei === MARIADB_AB_CORP_CF
                    this.loadChargifyJs()
                }
            },
            changeState(data){
                this.state = data.isoShort
            },
            async initiateOTPForPhone(setFetchingFlag=true){
                if(setFetchingFlag) this.isFetchingOTP = true
                const [error] = await to(sendCodeToPhone(this.phone))
                if(error){
                    const errorMsg = this.$typy(error, 'response.data.errors[0].message').safeString
                        || this.$i18n.t('errors.unknown')
                    this.$store.commit('showMessage', { text: errorMsg, type: this.$config.messageType.ERROR, })
                }
                this.isFetchingOTP = false
                this.secondBeforeResend = 30
                this.isOTPSent = true
                this.countDownTimer()
            },
            async resendOTPCode() {
                await this.initiateOTPForPhone(false)
            },
            resetTimer() {
                clearInterval(this.timer)
            },
            async updateProfileInfo() {
                this.isSaving = true
                let profileObject = this.$lodash.pick(this, [
                    'address',
                    'address2',
                    'chargifyToken',
                    'city',
                    'country',
                    'firstName',
                    'lastName',
                    'phone',
                    'state',
                    'vatNumber',
                    'zip'
                ])

                profileObject = {
                    ...profileObject,
                    email: this.user.email,
                    organization: this.orgData.name,
                    orgId: this.orgData.org_id,
                    // Need to pass empty vatNumber & address2 in case not provided
                    // due to api limitation as Chargify required this field
                    // even if empty
                    ...(!profileObject.vatNumber && {vatNumber: '',}),
                    ...(!profileObject.address2 && {address2: '',}),
                }
                if (this.product.length) profileObject.product = this.product
                await this.registerPaymentProfile(profileObject)
                this.isSaving = false
                if (this.updateProfileSuccess) return this.$emit('on-update-profile')

                this.$store.commit('showMessage', { text: this.$t('billings.errorUpdatingPaymentProfile'), type: this.$config.messageType.ERROR, })
            },
            setupBillingDetails() {
                if(this.paymentProfile && this.paymentProfile.id){
                    this.firstName =  this.paymentProfile.firstName
                    this.lastName =  this.paymentProfile.lastName
                    this.address =  this.paymentProfile.billingAddress
                    this.address2 =  this.paymentProfile.billingAddress2
                    this.country =  this.paymentProfile.billingCountry || this.primaryContactCountry
                    this.state =  this.paymentProfile.billingState
                    this.city =  this.paymentProfile.billingCity
                    this.zip =  this.paymentProfile.billingZip
                    this.vatNumber =  this.paymentProfile.vatNumber
                    this.phone =  this.paymentProfile.phone
                    this.corpAB = this.legalEntityIdentifier === MARIADB_AB_CORP_CF
                } else {
                    this.firstName = this.user.firstName
                    this.lastName = this.user.lastName
                    // TODO:: The country will come from Account data
                    // in case the payment profile is empty
                    // prefill the account country
                    this.country = 'US'
                }
            },
            closePaymentModal() {
                this.$emit('on-payment-close')
            },
            onPhoneInput(_, phoneObject) {
                this.phone = phoneObject.number
            },
            onUpdatedPhoneInput(_, phoneObject) {
                this.updatedPhoneNumber = phoneObject.number
            },
        },
        mounted(){
            this.setupBillingDetails()
        },
    }
</script>
