<template>
    <svg viewBox="0 0 16 16">
        <path
            d="M10.0137 1.22944C9.6039 1.06551 9.11209 1.27043 8.94816 1.68026C8.78423 2.0901 8.98915 2.5819 9.39898 2.74584C11.4482 3.60649 12.8006 5.61469 12.8006 7.8278C12.8006 10.8606 10.3416 13.3606 7.26783 13.3606C4.19406 13.3606 1.73505 10.9016 1.73505 7.8278C1.73505 5.61469 3.04652 3.60649 5.0957 2.74584C5.50554 2.5819 5.71046 2.0901 5.54652 1.68026C5.38259 1.27043 4.89078 1.06551 4.48095 1.22944C1.81701 2.336 0.0957031 4.95895 0.0957031 7.8278C0.0957031 11.7622 3.29242 14.9999 7.26783 14.9999C11.2023 14.9999 14.44 11.8032 14.44 7.8278C14.44 4.95895 12.7187 2.37698 10.0137 1.22944Z"
            fill="#0E6488" />
        <path
            d="M7.26791 7.37705C7.71873 7.37705 8.08759 7.0082 8.08759 6.55738V0.819672C8.08759 0.368852 7.71873 0 7.26791 0C6.81709 0 6.44824 0.368852 6.44824 0.819672V6.55738C6.44824 7.0082 6.81709 7.37705 7.26791 7.37705Z"
            fill="#0E6488" />
    </svg>
</template>

<script>
    export default {}
</script>
