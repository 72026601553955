<template>
    <svg
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
            fill="#F59D34" />
        <line
            x1="7.76538"
            y1="4.44232"
            x2="7.76538"
            y2="10.3269"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round" />
        <line
            x1="7.76538"
            y1="9.84619"
            x2="7.76538"
            y2="11.077"
            stroke="#F59D34"
            stroke-width="1.5" />
        <ellipse
            cx="7.93846"
            cy="12"
            rx="0.923077"
            ry="0.923077"
            fill="white" />
    </svg>
</template>

<script>
    export default {}
</script>
