
<style lang="scss" scoped>
    path {
        fill: currentColor;
    }
</style>

<template>
    <svg viewBox="0 0 58 61">
        <path
            d="M23.5407 1.69982L23.6028 1.69199L23.6638 1.6778C25.4558 1.26096 27.2232 1.12897 28.6819 1.26264L28.7371
            1.2677H28.7925C30.6232 1.2677 32.3133 1.40922 34.1561 1.69501L34.1732 1.69766L34.1903 1.69982C34.8272
            1.78006 35.174 2.2108 35.174 2.70498V2.7336L35.1753 2.76219C35.3401 6.2514 37.1631 9.28358 40.1622
            10.9689C43.047 12.6728 46.681 12.6229 49.8847 11.1697L49.9381 11.1454L49.989 11.1161C50.4377 10.8577
            50.9698 10.9427 51.37 11.4269L51.3836 11.4433L51.3977 11.4592C53.764 14.1251 55.5709 17.2037 56.6866
            20.5549C56.8482 21.271 56.5594 21.8855 56.1608 22.1571C53.3287 24.0086 51.6762 27.191 51.6762
            30.5276C51.6762 33.8676 53.332 37.053 56.1693 38.9037L56.1866 38.9149L56.2042 38.9256C56.5494 39.1344
            56.7875 39.6111 56.7875 39.949C56.7875 40.1084 56.786 40.1808 56.7785 40.2414C56.7754 40.266 56.7722 40.2794
            56.7705 40.2856C56.7699 40.2877 56.7694 40.2893 56.769 40.2904C56.7683 40.2923 56.7679 40.2931 56.7677
            40.2935L56.7396 40.3502L56.7175 40.4095C55.4516 43.8125 53.6231 46.9314 51.374 49.6235L51.37 49.6283C51.0633
            49.9994 50.5317 50.1452 49.9136 49.8971C46.8904 48.2335 43.4009 48.4361 40.4672 50.079C37.4684 51.7584
            35.6561 54.918 35.4709 58.2262C35.363 58.8695 34.9361 59.2982 34.4824 59.3554L34.4653 59.3575L34.4482
            59.3602C32.608 59.6456 30.7717 59.7875 29.0846 59.7875C27.2539 59.7875 25.5638 59.646 23.721 59.3602L23.7039
            59.3575L23.6867 59.3554C23.0499 59.2751 22.7031 58.8444 22.7031 58.3502V58.3216L22.7018 58.293C22.537
            54.8038 20.714 51.7717 17.715 50.0863C14.8371 48.3865 11.197 48.4249 8.11689 49.8956L8.07468 49.9157L8.03414
            49.9391C7.58542 50.1976 7.05334 50.1125 6.6531 49.6283L6.63953 49.6119L6.6254 49.596C4.25914 46.9301 2.45227
            43.8515 1.33651 40.5003C1.1749 39.7842 1.46377 39.1697 1.86238 38.8981C4.69449 37.0466 6.34694 33.8642
            6.34694 30.5276C6.34694 27.198 4.70148 24.0221 1.88036 22.1689C1.21655 21.6807 1.123 21.0784 1.285
            20.6973L1.29588 20.6717L1.30558 20.6457C2.57153 17.2427 4.40006 14.1238 6.64911 11.4317L6.64913
            11.4317L6.6531 11.4269C6.95986 11.0558 7.49139 10.91 8.10951 11.1581C11.1327 12.8217 14.6222 12.6191 17.5559
            10.9762C20.5547 9.29679 22.3671 6.13721 22.5522 2.82901C22.6601 2.18566 23.087 1.75699 23.5407
            1.69982ZM28.9385 43.5169C36.043 43.5169 41.834 37.6653 41.834 30.5276C41.834 23.3899 36.043 17.5383 28.9385
            17.5383C21.8341 17.5383 16.043 23.3899 16.043 30.5276C16.043 37.6653 21.8341 43.5169 28.9385 43.5169Z"
            stroke-width="4.5" />
    </svg>
</template>

<script>
    export default {}
</script>
