<template>
    <svg
        width="18"
        height="13"
        viewBox="0 0 18 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1697_21178)">
            <path
                d="M-1.5 0H19.5V12.871H-1.5V0Z"
                fill="white" />
            <path
                d="M8.99956 9.48349C10.6831 9.48349 12.0479 8.11868 12.0479 6.43511C12.0479 4.75153 10.6831 3.38672 8.99956 3.38672C7.31598 3.38672 5.95117 4.75153 5.95117 6.43511C5.95117 8.11868 7.31598 9.48349 8.99956 9.48349Z"
                fill="#D22F27" />
            <path
                d="M6.33293 4.97111C6.14806 5.32397 6.10895 5.73524 6.224 6.11662C6.33904 6.49799 6.59906 6.81903 6.94822 7.01079C7.29737 7.20255 7.7078 7.24974 8.09136 7.14221C8.47492 7.03468 8.80101 6.78101 8.99959 6.43569C9.00738 6.42215 9.01111 6.40758 9.01822 6.39403L9.02635 6.39877C9.23579 6.05861 9.57059 5.81444 9.95848 5.71895C10.3464 5.62347 10.7563 5.68433 11.0997 5.8884C11.4431 6.09248 11.6925 6.4234 11.794 6.80974C11.8956 7.19609 11.8412 7.60688 11.6425 7.95345L11.6436 7.95413C11.4477 8.30285 11.1851 8.60958 10.8706 8.8568C10.5562 9.10403 10.1962 9.2869 9.81113 9.39499C9.03342 9.61328 8.20084 9.51368 7.49657 9.1181C6.79229 8.72253 6.274 8.06339 6.05571 7.28568C5.83742 6.50796 5.93702 5.67539 6.33259 4.97111H6.33293ZM6.40202 4.83936L6.4088 4.84308C6.39864 4.85866 6.38611 4.87187 6.37662 4.88813C6.38577 4.87221 6.39254 4.85494 6.40202 4.83936Z"
                fill="#1E50A0" />
            <path
                d="M12.5565 2.33773L13.5726 4.09767M5.01411 8.43451L6.03024 10.1944L5.01411 8.43451ZM3.84082 9.11193L4.85695 10.8719L3.84082 9.11193ZM4.42747 8.77322L4.76618 9.35986L4.42747 8.77322ZM5.10488 9.94651L5.44359 10.5332L5.10488 9.94651ZM12.2178 10.5332L12.5565 9.94651L12.2178 10.5332ZM12.8952 9.35986L13.2339 8.77322L12.8952 9.35986ZM12.8044 10.8719L13.1431 10.2852L12.8044 10.8719ZM13.4819 9.69857L13.8206 9.11193L13.4819 9.69857ZM11.6311 10.1944L11.9699 9.6078L11.6311 10.1944ZM12.3086 9.02115L12.6473 8.43451L12.3086 9.02115ZM3.84082 3.75896L4.85695 1.99902L3.84082 3.75896ZM4.42747 4.09767L5.44359 2.33773L4.42747 4.09767ZM5.01411 4.43638L6.03024 2.67644L5.01411 4.43638ZM11.9699 2.67644L12.3086 3.26309L11.9699 2.67644ZM12.6473 3.84973L12.986 4.43638L12.6473 3.84973ZM13.1431 1.99902L13.4819 2.58567L13.1431 1.99902ZM13.8206 3.17231L14.1593 3.75896L13.8206 3.17231Z"
                stroke="black"
                stroke-width="0.5"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M-1.5 0H19.5V12.871H-1.5V0Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
        </g>
        <rect
            x="0.5"
            y="0.5"
            width="17"
            height="11.6844"
            stroke="#D2D2D2" />
        <defs>
            <clipPath id="clip0_1697_21178">
                <rect
                    x="1"
                    y="1"
                    width="16"
                    height="10.6844"
                    fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
    export default {}
</script>
