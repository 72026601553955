<template>
    <v-expansion-panel
        id="optionsPanel"
        :disabled="isDisabled"
        :data-auto="dataAutoPrefix()">
        <AccordianPanelHeader
            :title="getLocaleFor('additionalOptions')"
            :parent-data-auto-prefix="dataAutoPrefix">
            <!-- Tooltip goes here -->
            <template #tooltip-content>
                <p>{{ $t('launchService.additionalOptionsInfo') }}</p>
                <a
                    rel="noopener noreferrer"
                    class="text-capitalize"
                    target="_blank"
                    href="https://r.mariadb.com/snr-launch-additional-options">{{ $t('learn-more') }}</a>
            </template>
        </AccordianPanelHeader>
        <v-expansion-panel-content :data-auto="dataAutoPrefix('content')">
            <v-row
                v-for="({ name, label, hint}, i) in options"
                :key="i"
                class="ml-1 my-2">
                <v-checkbox
                    class="font-weight-bold mb-0 pb-0"
                    :label="getLocaleFor(label)"
                    :data-auto="dataAutoPrefix(`content-optionSelection-${i + 1}`)"
                    @change="(value) => onOptionChange(name, value)" />
                <div class="ml-8 pt-0 mt-n3 text-caption">
                    {{ getLocaleFor(hint) }}
                </div>
            </v-row>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>
<script>
    import AccordianPanelHeader from 'components/launchService/AccordianPanelHeader.vue'
    export default {
        name: 'LaunchServiceOptionsSelection',
        components: { AccordianPanelHeader, },
        props: {
            options: {
                type: Array,
                default: () => [],
            },
            getLocaleFor: {
                type: Function,
                default: (string) => string,
            },
            parentDataAutoPrefix: {
                type: Function,
                default: (string) => string,
            },
            isDisabled: {
                type: Boolean,
                default: true,
            },
        },
        methods: {
            dataAutoPrefix(string) {
                return this.parentDataAutoPrefix(
                    `optionsPanel${string ? '-' + string : ''}`
                )
            },
            onOptionChange(optionName, value) {
                this.$emit('onOptionToggle', {
                    optionName,
                    value,
                })
            },
        },
    }
</script>
