<style lang="scss" scoped>
    $input-size: 0.875rem;
    .condition-fields {
        .condition-label {
            font-size: 0.6875rem;
        }
        ::v-deep .v-input--is-disabled {
            opacity: 0.5;
        }
        .condition-field {
            &--for {
                width: 100px;
                ::v-deep .v-input__control {
                    .v-input__slot {
                        padding: 0 0 0 12px;
                    }
                }
                .suffix-select ::v-deep .v-select__selections input,
                .std ::v-deep .v-select__selections input {
                    display: none;
                }
                .suffix-select {
                    border-radius: 0px;
                    ::v-deep .v-input__control {
                        min-width: 20px;
                        margin-top: -6px;
                        .v-input__slot {
                            padding: 0 0 0 9px !important;
                            margin-top: 5px;
                            .v-select__slot {
                                .v-select__selection.v-select__selection--comma {
                                    text-align: center;
                                    width: 100%;
                                }
                            }
                            .v-input__append-inner {
                                padding-left: 0px !important;
                            }
                        }
                        fieldset {
                            border-right: none;
                            border-top: none;
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }
</style>

<template>
    <div
        class="condition-fields d-inline-block"
        :class="className">
        <label
            v-if="label"
            class="d-block condition-label color text-small-text"
            :class="{ 'label-required': required }">
            {{ label }}
        </label>
        <v-text-field
            v-model.trim="timeRange.value"
            class="condition-field--for std v-text-field--with-bottom-details"
            type="text"
            placeholder="--"
            height="36"
            single-line
            outlined
            required
            :rules="rules"
            :disabled="disabled"
            :hide-details="hideDetails"
            @keypress="timeRangeKeyPress($event)">
            <template #append>
                <v-select
                    v-model="timeRange.suffix"
                    class="std suffix-select"
                    hide-details
                    :menu-props="{
                        bottom: true,
                        offsetY: true,
                        contentClass: 'std--dropdown-list'
                    }"
                    :items="timeRangeSuffixes"
                    outlined
                    single-line />
            </template>
        </v-text-field>
    </div>
</template>

<script>
    export default {
        name: 'TimeRange',
        props: {
            className: { type: String, default: '', },
            time: { type: String, required: false, default: null, },
            label: { type: String, default: '', },
            disabled: { type: Boolean, default: false, },
            required: { type: Boolean, default: false, },
            validationHandler: { type: Function, required: true, },
            hideDetails: { type: [Boolean, String], default: false, },
        },
        data () {
            return {
                timeRange: {
                    value: null,
                    suffix: 'm',
                },
                timeRangeSuffixes: ['s', 'm', 'h'],
                rules: [(val) => this.validationHandler(val, this.timeRange.suffix)],
            }
        },
        watch: {
            timeRange: {
                deep: true,
                handler (v) {
                    this.$emit('on-time-range-change', v)
                },
            },
            'timeRange.suffix': function () {
                // trigger validation handler
                this.rules = []
                this.$nextTick(() => {
                    this.rules = [(val) => this.validationHandler(val, this.timeRange.suffix)]
                })
            },
            time: {
                handler (v) {
                    this.assignTimeRange(v)
                },
            },
        },
        methods: {
            assignTimeRange (timeRange) {
                this.timeRange.suffix = timeRange.replace(/[0-9]|(null)+/g, '')
                this.timeRange.value = timeRange.replace(this.timeRange.suffix, '')
            },
            // allow to enter only number
            timeRangeKeyPress (e) {
                switch (e.key) {
                    case 's':
                    case 'm':
                    case 'h':
                        this.timeRange.suffix = e.key
                }
                // prevent non numerical value
                return !e.key.match(/^\d*$/g) && e.preventDefault()
            },
        },
        mounted () {
            if (this.time) {
                this.assignTimeRange(this.time)
            }
        },
    }
</script>
