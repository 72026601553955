<template>
    <svg
        width="16"
        height="11"
        viewBox="0 0 16 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0 0H16V11H0V0Z"
            fill="#F1B31C" />
        <path
            d="M0 3.76318H16V7.23687H0V3.76318Z"
            fill="#D22F27" />
        <path
            d="M0 0H16V3.76316H0V0Z"
            fill="black" />
    </svg>
</template>

<script>
    export default {}
</script>
