<style lang="scss" scoped>
.table-wrapper {
    width: 100%;
    height: 100%;
    background: transparent;
    overflow-y: auto;
}

</style>

<template>
    <v-data-table
        class="table-wrapper"
        :headers="fullOptions.headers"
        :items="items"
        hide-default-footer
        disable-pagination>
        <template #item="{ item, headers }">
            <tr>
                <td
                    v-for="({ metricIndex, value, icons, format, progress }, i) in headers"
                    :key="i"
                    :set="rowVal = $typy(metricIndex).isNumber ? item[metricIndex][value] : item[value]">
                    <v-icon
                        v-if="icons"
                        size="18">
                        {{ icons[rowVal] || icons['default'] }}
                    </v-icon>
                    <div
                        v-else
                        class="d-flex align-center">
                        <v-progress-linear
                            v-if="progress"
                            class="mr-1"
                            :value="Math.min(rowVal, 100)"
                            background-color="grey"
                            background-opacity="0.15"
                            :color="progressColor(rowVal)"
                            height="14"
                            style="width: 100px;" />
                        <div>
                            {{ format ? $format(format.type, rowVal, format.decimals) : rowVal }}
                        </div>
                    </div>
                </td>
            </tr>
        </template>
    </v-data-table>
</template>

<script>
    export default {
        name: 'SimpleTableList',
        props: {
            data: {
                type: Array,
                default: () => [],
            },
            options: {
                type: Object,
                default: () => { },
            },
        },
        data() {
            return {
                defaultOptions: {
                    hasFlatRows: false,
                    headers: [],
                },
                fullOptions: {},
                items: [],
            }
        },
        watch: {
            data(newVal) {
                this.parseData(newVal)
            },
        },
        methods: {
            parseData(dataPoints) {
                // Maps the metrics data from Cortex to the v-data-table model
                const { hasFlatRows, } = this.fullOptions
                let items = []

                dataPoints.forEach(({ result, }, metricIndex) => {
                    result.forEach(({ metric, value, label, }, itemIndex) => {
                        const row = {
                            ...metric,
                            label,
                            value: value[1],
                        }
                        if (hasFlatRows) {
                            items.push(row)
                        } else {
                            if (!items[itemIndex]) items[itemIndex] = {}
                            items[itemIndex][metricIndex] = row
                        }
                    })
                })

                this.items = items
            },
            progressColor(value) {
                if (value >= 0.9) return 'red'
                if (value >= 0.7) return 'orange'
                return 'green'
            },
        },
        async created() {
            this.fullOptions = this.$lodash.merge(this.defaultOptions, this.options)
            this.parseData(this.data)
        },
    }
</script>
