
<template>
    <svg
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.748 6.3297C15.58 6.49144 15.3582 6.57231 15.1385 6.57231C14.9167 6.57231 14.697 6.49144 14.529 6.3297C10.928 2.86267 5.06986 2.86267 1.47099 6.3297C1.13501 6.65318 0.587966 6.65318 0.251985 6.3297C-0.0839951 6.00414 -0.0839951 5.47953 0.251985 5.15605C4.52282 1.04206 11.475 1.04206 15.748 5.15605C16.084 5.4816 16.084 6.00622 15.748 6.3297ZM13.4326 8.55881C13.2646 8.72055 13.0428 8.80142 12.8231 8.80142C12.6013 8.80142 12.3816 8.72055 12.2158 8.55881C9.89189 6.32141 6.10995 6.32141 3.78609 8.55881C3.44795 8.88229 2.90306 8.88229 2.56708 8.55881C2.2311 8.23326 2.2311 7.70864 2.56708 7.38516C5.56291 4.50081 10.4368 4.50081 13.4326 7.38516C13.7686 7.71072 13.7686 8.23533 13.4326 8.55881ZM10.5085 11.0305C10.7282 11.0305 10.95 10.9496 11.118 10.7879C11.454 10.4644 11.454 9.93979 11.118 9.61424C9.39932 7.95952 6.60163 7.95952 4.88297 9.61424C4.54698 9.93772 4.54698 10.4623 4.88297 10.7879C5.21895 11.1114 5.76599 11.1114 6.10197 10.7879C7.14868 9.78013 8.85228 9.78013 9.90114 10.7879C10.067 10.9496 10.2867 11.0305 10.5085 11.0305ZM9.36239 12.6168C9.36239 13.3417 8.75201 13.9294 7.99908 13.9294C7.24615 13.9294 6.63578 13.3417 6.63578 12.6168C6.63578 11.8919 7.24615 11.3042 7.99908 11.3042C8.75201 11.3042 9.36239 11.8919 9.36239 12.6168Z"
            fill="#EB5757" />
    </svg>
</template>

<script>
    export default {}
</script>
