<style lang="scss" scoped>
.partner-category-title {
    font-size: 10px;
}
</style>
<template>
    <div
        v-if="partners.length"
        class="mb-4">
        <p class="text-uppercase partner-category-title mb-3">
            {{ categoryTitle }}
        </p>
        <v-row
            v-for="(partner, index) in partners"
            :key="index">
            <PartnerBox
                :partner="partner"
                :is-selected="selectedPartner === partner.name"
                class="my-1"
                @on-click="onPartnerSelection">
                <template #icon>
                    <v-icon
                        :style="{ top: partner.top }"
                        :size="partner.iconSize">
                        {{ partner.icon }}
                    </v-icon>
                </template>
            </PartnerBox>
        </v-row>
    </div>
</template>

<script>
    import PartnerBox from 'components/integrations/PartnerBox.vue'

    export default {
        name: 'PartnerCategory',
        components: { PartnerBox, },
        props: {
            partners: {
                type: Array,
                required: true,
            },
            categoryTitle: {
                type: String,
                required: true,
            },
            selectedPartner: {
                type: String,
                default: '',
            },
        },
        methods: {
            onPartnerSelection(partner) {
                this.$emit('on-click', partner)
            },
        },
    }
</script>
