<style lang="scss" scoped>
    .empty-list{
        border: 1px solid $walls-of-santorini;
        border-radius: 3px;
    }
</style>

<template>
    <v-dialog
        :value="isDialogOpen"
        :eager="true"
        persistent
        max-width="620">
        <v-card>
            <v-card-title class="text-h5 pa-12 py-6">
                {{ $t('ipAllowList') }}
            </v-card-title>
            <v-card-text class="px-12 py-0 pb-4">
                <v-container class="pa-0">
                    <v-row>
                        <v-col
                            cols="12"
                            class="pt-0">
                            {{ $t('addAllIPv4') }}
                        </v-col>
                    </v-row>
                </v-container>
                <Whitelist
                    ref="allowList"
                    :service-id="serviceId"
                    @updated="updated"
                    @on-changed="hasChanged" />
            </v-card-text>
            <v-divider />
            <v-card-actions class="justify-center px-12 py-6">
                <v-spacer />
                <v-btn
                    color="primary"
                    depressed
                    rounded
                    outlined
                    @click="close">
                    <span class="text-none">{{ $t('cancel') }}</span>
                </v-btn>

                <v-btn
                    color="primary"
                    depressed
                    rounded
                    :disabled="disableSave"
                    @click="save">
                    <span class="text-none">{{ $t('save') }}</span>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import Whitelist from './Whitelist.vue'

    export default {
        name: 'WhitelistModal',
        components: { Whitelist, },
        data () {
            return {
                serviceId: '',
                isDialogOpen: false,
                disableSaveBtn: false,
            }
        },
        computed: {
            disableSave() {
                return !this.disableSaveBtn
            },
        },
        methods: {
            close () {
                this.isDialogOpen = false
                this.serviceId = ''
            },
            open (serviceId) {
                this.isDialogOpen = true
                this.serviceId = serviceId
            },
            updated (){
                this.isDialogOpen = false
                this.serviceId = ''
            },
            hasChanged(hasChanged) {
                this.disableSaveBtn = hasChanged
            },
            save () {
                this.$refs.allowList.save()
                this.$emit('on-did-save')
            },
        },
    }
</script>
