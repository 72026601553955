/* eslint-disable no-console */
import { setupEventSource } from 'services/serverSentEvents'
import store from 'store/index'
import { camelize } from 'helpers/camelCase'
import t from 'typy'

/*
    some internal util methods
    that are used for retrying the
    connection on increasing intervals
*/

let waitFunc = function () {
    return reconnectFrequencySeconds * 1000
}
let tryToSetupFunc = function () {
    setupEventSource(store.getters.authToken)
    reconnectFrequencySeconds *= 2
    // set the max reconnection frequency to ~ 1 min
    if (reconnectFrequencySeconds >= 64) {
        reconnectFrequencySeconds = 64
    }
}
// start reconnect with 1 sec delay
let reconnectFrequencySeconds = 1

const reconnect = function () {
    setTimeout(tryToSetupFunc, waitFunc())
}

/**
    All SSE specific methods starts from here
 */

const onOpen = function () {
    // this is when connection is established
    // no action is required here
    // no need to print response
}

const onMessage = function (data) {
    // read the data object and perform operations
    // based on the data type
    if (data.event === 'notification') {
        try {
            handleSSENotification(JSON.parse(data.data))
        } catch (e) {
            // parsing failed
            console.log(e)
        }
    }
}

const onClose = function () {
    // try reconnect in case server
    // closes the connection abruptly
    throw new Error('Server unexpectedly closed the connection, retry connecting')
}

const onError = function (err) {
    console.log(err)
    reconnect()
    // throw error to stop the
    // library from retrying the old connection
    // as that always fails with stale access token
    throw err
}


const handleSSENotification = function (data) {
    const { type, payload, } = data

    // Currently supporting only service progress
    // as part of this integration
    // later this is going to get extended to different
    // types
    switch (type) {
    case 'DB_SERVICE_PROGRESS_UPDATED':
        if (payload.length) {
            store.commit('setServiceProgress', camelize(payload[0]))
        }
        break
    case 'NOTIFICATION_CREATED':
        if(!t(payload).isEmptyObject){
            store.commit('setNewNotification', payload)
        }
        break
    }
}

export default { onOpen, onMessage, onClose, onError, }
