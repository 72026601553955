<template>
    <div>
        <v-row
            v-if="defaultIopsType === $config.iops.io1"
            class="mt-0">
            <v-col :cols="defaultIopsType ? 8:12">
                <div>
                    <Slider
                        v-model="volumeSize"
                        :default-value="currentValue"
                        :min-value="minValue"
                        :max-value="maxValue"
                        :step-value="stepValue"
                        :data-auto="dataAutoPrefix('content-storageSlider')"
                        class-name="iops-slider"
                        :show-input="true"
                        @handleChange="handleVolumeChange">
                        <template #input-text>
                            {{ $t('iop-calculation', {storageSize} ) }}
                            <span class="font-weight-bold">{{ $t('iops-total', { iops: iopsAllocated()}) }}</span>
                        </template>
                    </Slider>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import Slider from 'components/Slider'
    export default {
        components: { Slider, },
        props: {
            currentValue: { type: Number, default: 0, },
            minValue: { type: Number, default: 0, },
            maxValue: { type: Number, default: 1, },
            stepValue: { type: Number, default: 1, },
            parentDataAutoPrefix: {
                type: Function,
                default: (txt) => txt,
            },
            defaultIopsType: { type: String, default: '', },
            storageSize: {
                type: Number,
                default: 100,
            },
        },
        data () {
            return {
                volumeSize: this.currentValue,
            }
        },
        methods: {
            dataAutoPrefix (string) {
                return this.parentDataAutoPrefix(
                    `iops-selector${string ? '-' + string : ''}`
                )
            },
            handleVolumeChange (volume) {
                this.volumeSize = volume
                this.$emit('handleIopsVolumeChange', volume)
            },
            iopsAllocated () {
                return this.volumeSize * this.storageSize
            },
            onVolumeTypeChange () {
                this.$emit('handleIopsTypeChange', this.currentVolumeType)
            },
        },
    }
</script>
