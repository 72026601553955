import offerings from 'api/offerings'
import { architecturesMapper, instanceSizeMapper, regionsMapper } from 'mappers/offerings'
import { getTopologyAttributes, addMockTopologies } from 'helpers/offerings'

export async function getAllTopologies(onlyLegacy) {
    return offerings
        .get('topologies', {
            // eslint-disable-next-line camelcase
            only_legacy: onlyLegacy,
        })
        .then((response) => response.data)
}

export async function getTopologyDetails() {
    return offerings
        .get('topologies')
        .then((response) => addMockTopologies(response.data))
        .then((data) => {
            return data
                .sort(
                    (topologyA, topologyB) => topologyA.order - topologyB.order
                )
                .map((topology) => {
                    return {
                        ...topology,
                        ...getTopologyAttributes(topology.name),
                    }
                })
        })
}

export async function getDatabaseTypes() {
    return offerings.get('topologies').then((response) => {
        const products = new Set()
        response.data
            .sort((serviceA, serviceB) => serviceA.order - serviceB.order)
            .map(service => {
                products.add(service.database)
            })
        return [...products]
    })
}

export async function getCloudProviders() {
    return offerings.get('providers').then(response => { return response.data })
}

export async function getRegionsForProvider(provider) {
    const params = {
        provider,
    }

    return offerings.get('regions', params).then(response => {
        return response.data.map(regionsMapper)
    })
}

export async function getArchitectures(options = {}) {
    const { topology, provider, } = options

    return offerings.get('cpu-architectures', {
        topology,
        provider,
    }).then(response => response.data.map(architecturesMapper))
}

export async function getSizes(options) {
    return offerings.get('sizes', options).then(response => {
        return response.data.map(instanceSizeMapper)
    })
}

export async function getServerVersions(topology) {
    return offerings.get('versions', { topology, }).then(response => response.data)
}

export async function getTopologyOptions(topology) {
    return offerings.get(`topologies/${topology}/options`, { topology, }).then((response) => response.data)
}

export async function getStorageSizes(topology) {
    return offerings.get(`topologies/${topology}/storage-sizes`).then((response) => response.data)
}

export async function getNodesSize(topology) {
    return offerings.get(`topologies/${topology}/nodes`).then((response) => response.data)
}

export async function getIops() {
    return offerings.get('providers/aws/iops').then((response) => response.data)
}
export async function getMaintenanceWindowList() {
    return offerings.get('maintenance-windows').then(response => { return response.data })
}

export async function getAvailabilityZones(provider) {
    return offerings.get(`providers/${provider}/zones`).then(response => { return response.data })
}

export async function getVolumeTypes(provider) {
    return offerings.get(`providers/${provider}/volume-types`).then(response => { return response.data })
}
