<style lang="scss" scoped>
    path {
        fill: currentColor;
    }
</style>

<template>
    <svg viewBox="0 0 53 45">
        <path
            d="M 26.50121,5.9136573e-6 C 25.072541,5.9136573e-6 23.759541,0.70400691 23.019976,1.9434609 L 0.81177385,39.005872
            l -0.06603,0.113198 v 0.04717 c -0.664475,1.211328 -0.645738,2.665656 0.06603,3.858603 0.69157795,1.159248 1.94107795,1.877562
            3.26896495,1.962322 14.8685462,0.05659 29.7374782,-0.0989 44.6050862,0.0094 1.441965,0.0077 2.759213,-0.724704 3.500104,
            -1.967041 0.711563,-1.19256 0.734954,-2.6469 0.07076,-3.858603 v -0.06132 L 52.181219,39.001128 29.977737,1.9434339 C 29.238394,
            0.70440591 27.929652,5.9136573e-6 26.50121,5.9136573e-6 Z m 0,3.8067159863427 c -0.0087,0 0.05888,0.0094 0.122642,0.04246
            0.02628,0.01377 0.03818,0.02319 0.05188,0.03301 l 0.01425,0.02367 22.142162,36.9492001 v 0.03301 l 0.07076,0.108489 c -0.0383,
            -0.06219 1.45e-4,-0.0051 0,0.04717 -1.3e-4,0.04596 -0.02589,0.08728 -0.0048,0.05188 0.03521,-0.05944 -0.0094,0.01401 -0.07547,
            0.05188 -0.06108,0.035 -0.136698,0.04234 -0.113223,0.04246 h -0.02367 -44.3692302 -0.02367 c 0.02029,0 -0.05168,-0.0075
            -0.113223,-0.04246 -0.06661,-0.03816 -0.110469,-0.110953 -0.07547,-0.05188 0.03191,0.05299 1.57e-4,0.0029 0,-0.05188 0,-0.05297
            0.03828,-0.109601 0,-0.04717 v -0.0048 l 0.06603,-0.113199 v -0.02367 L 26.312339,3.9056919 l 0.01425,-0.02367 c 0.01377,-0.0099
            0.02565,-0.01932 0.05188,-0.03302 0.06404,-0.03304 0.130916,-0.04227 0.122777,-0.04227 z m 0,11.0757991 c -1.076673,0 -1.943455,
            0.883383 -1.943455,1.985906 v 12.439046 c 0,1.102523 0.866782,1.990623 1.943455,1.990623 1.076673,0 1.943455,-0.8881 1.943455,
            -1.990623 V 16.868427 c 0,-1.102523 -0.866782,-1.985906 -1.943455,-1.985906 z m 0,17.774105 a 2.6227187,2.6132843 0 0 0
            -2.62272,2.613287 2.6227187,2.6132843 0 0 0 2.62272,2.613284 2.6227187,2.6132843 0 0 0 2.62272,-2.613284 2.6227187,2.6132843
            0 0 0 -2.62272,-2.613287 z" />
    </svg>
</template>

<script>
    export default {}
</script>
