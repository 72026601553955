<style lang="scss" scoped>
    path {
        stroke: currentColor;
        fill: currentColor;
    }
</style>

<template>
    <svg
        viewBox="0 0 17 20"
        fill="none">
        <path
            d="M14.945 1H4.03163C3.78834 0.999999 3.55497 1.09646 3.38269 1.26824C3.21041 1.44002 3.11326 1.6731 3.11255
            1.91639V15.8235C3.11326 16.0668 3.21041 16.2999 3.38269 16.4717C3.55497 16.6435 3.78834 16.7399 4.03163
            16.7399H14.945C15.1881 16.7399 15.4212 16.6434 15.593 16.4715C15.7649 16.2997 15.8614 16.0666 15.8614
            15.8235V1.91639C15.8614 1.67335 15.7649 1.44026 15.593 1.26841C15.4212 1.09655 15.1881 1 14.945
            1ZM15.0552 15.8235C15.0545 15.8525 15.0427 15.8802 15.0222 15.9007C15.0017 15.9212 14.974 15.933
            14.945 15.9337H4.03163C4.00215 15.9337 3.97385 15.9222 3.95275 15.9016C3.93166 15.881 3.91946 15.853
            3.91876 15.8235V1.91639C3.91946 1.88693 3.93166 1.8589 3.95275 1.83831C3.97385 1.81772 4.00215 1.8062
            4.03163 1.80621H14.945C14.974 1.80689 15.0017 1.81872 15.0222 1.83924C15.0427 1.85976 15.0545 1.88739
            15.0552 1.91639V15.8235Z"
            stroke-width="0.5" />
        <path
            d="M12.8515 18.1992H1.89509C1.85747 18.1992 1.80641 18.1481 1.80641 18.0702V4.20871C1.80641 4.1018 1.76394
            3.99927 1.68834 3.92367C1.61275 3.84807 1.51021 3.8056 1.4033 3.8056C1.29639 3.8056 1.19386 3.84807 1.11826
            3.92367C1.04267 3.99927 1.0002 4.1018 1.0002 4.20871V18.0648C0.995152 18.3074 1.08653 18.5421 1.25429
            18.7174C1.42206 18.8927 1.6525 18.9943 1.89509 19H12.8515C12.9584 19 13.0609 18.9575 13.1365 18.8819C13.2121
            18.8063 13.2546 18.7038 13.2546 18.5969C13.2546 18.49 13.2121 18.3874 13.1365 18.3118C13.0609 18.2362
            12.9584 18.1938 12.8515 18.1938V18.1992Z"
            stroke-width="0.5" />
    </svg>
</template>

<script>
    export default {}
</script>
