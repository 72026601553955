<style lang="scss" scoped>
div.month-sort ::v-deep.v-select__selection.v-select__selection--comma {
    font-size: 12px !important;
}

.deep-ocean-font {
    color: $deep-ocean;
}
</style>
<template>
    <div class="mt-6">
        <v-row>
            <v-col cols="3">
                <span class="font-weight-bold deep-ocean-font">{{ $t('billings.month') }}</span>
                <div class="month-sort">
                    <v-select
                        v-model="selectedMonth"
                        :items="sortedMonths"
                        item-text="text"
                        item-val="date"
                        height="40"
                        :dense="true"
                        class="mt-0 select-usage"
                        return-object
                        outlined />
                </div>
            </v-col>
            <v-col cols="4">
                <BillingSortToggle
                    class="font-weight-bold deep-ocean-font"
                    :default-usage-sort="currentUsageSort"
                    @on-sort-toggle="updateUsageSort" />
            </v-col>
        </v-row>
        <v-row>
            <v-col
                class="text-h6 font-weight-medium"
                cols="3">
                {{ selectedMonth.text }} {{ $t('billings.usage').toLowerCase() }}
            </v-col>
            <v-col v-if="invoiceUrl">
                <a
                    rel="noopener noreferrer"
                    class="text-capitalize"
                    target="_blank"
                    :href="invoiceUrl">{{
                        $t('billings.invoice') }}</a>
            </v-col>
        </v-row>
        <div v-if="!isFetchingBillingUsage">
            <div class="mt-4">
                <BillingUsageTable
                    :current-usage-sort="currentUsageSort"
                    :usage-data="usageData" />
                <v-row>
                    <v-col cols="8" />
                    <BillingUsageTotal :total="total" />
                    <v-col cols="2" />
                </v-row>
            </div>
        </div>
        <v-container
            v-else
            class="fill-height mt-8"
            fluid>
            <v-row
                align="center"
                justify="center">
                <v-progress-circular
                    size="70"
                    width="7"
                    color="primary"
                    indeterminate />
            </v-row>
        </v-container>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    import BillingSortToggle from 'components/billing/BillingSortToggle.vue'
    import BillingUsageTable from 'components/billing/BillingUsageTable.vue'
    import BillingUsageTotal from 'components/billing/BillingUsageTotal.vue'
    import { prepareUsageByEntities, prepareUsageByServices } from 'utils/billing'

    export default {
        name: 'HistoricalUsage',
        components: { BillingSortToggle, BillingUsageTable, BillingUsageTotal, },
        data() {
            return {
                currentUsageSort: 'service',
                selectedMonth: '',
                invoiceUrl: '',
            }
        },
        computed: {
            ...mapGetters([
                'historicalUsage',
                'orgData',
                'isFetchingBillingUsage',
                'billingRecord',
                'invoices'
            ]),
            sortedMonths() {
                if (this.$lodash.isEmpty(this.billingRecord)) return []
                const { startDate, } = this.billingRecord
                let startDateMoment = this.$moment([this.$moment(startDate).year(), this.$moment(startDate).month()])
                const historicalStartDate = this.$moment(startDateMoment).startOf('month')
                const historicalEndDate = this.$moment().startOf('month').subtract(1, 'month')

                let monthsRange = []
                let currentDate = historicalEndDate
                while (historicalStartDate <= currentDate) {
                    const text = this.$moment(currentDate).format('MMMM') + ' ' + this.$moment(currentDate).format('YYYY')
                    monthsRange.push(
                        { text, date: this.$moment(currentDate).format('YYYY-MM-DD'), }
                    )
                    currentDate = this.$moment(currentDate).subtract(1, 'month')
                }
                return monthsRange
            },
            usageData() {
                if (this.$lodash.isEmpty(this.historicalUsage)) return []
                return this.currentUsageSort === 'service' ? prepareUsageByServices(this.historicalUsage) : prepareUsageByEntities(this.historicalUsage)
            },
            total() {
                return this.historicalUsage.
                    reduce((total, lineItem) =>
                        parseFloat(lineItem.cost) + total, 0
                    )
            },
        },
        watch: {
            sortedMonths(values, prevValues) {
                if (prevValues.length !== values.length) {
                    this.selectedMonth = values.at(0)
                }
            },
            selectedMonth() {
                this.getBillingUsage()
            },
            invoices(values) {
                if (values.length) {
                    this.setCurrentInvoiceUrl()
                }
            },
        },
        methods: {
            ...mapActions(['fetchBillingUsage', 'fetchBillingRecord', 'fetchInvoices']),
            updateUsageSort(sortType) {
                this.currentUsageSort = sortType
            },
            getBillingUsage() {
                this.fetchBillingUsage({ orgId: this.orgData.org_id, startDate: this.selectedMonth.date, isHistorical: true, })
                this.setCurrentInvoiceUrl()
            },
            setCurrentInvoiceUrl() {
                if (this.invoices.length) {
                    const currentInvoice = this.invoices.find((invoice) => {
                        const isMonthSame = this.$moment(invoice.createdAt).month() === this.$moment(this.selectedMonth.date).month()
                        const isYearSame = this.$moment(invoice.createdAt).year() === this.$moment(this.selectedMonth.date).year()
                        return isMonthSame && isYearSame
                    })
                    if (currentInvoice) {
                        this.invoiceUrl = currentInvoice.publicUrl
                    }
                }
            },
        },
        created() {
            if (this.$lodash.isEmpty(this.invoices)) this.fetchInvoices()
            if (this.$lodash.isEmpty(this.billingRecord)) {
                this.fetchBillingRecord()
            }
        },
        mounted() {
            if (this.sortedMonths.length > 0) {
                this.selectedMonth = this.sortedMonths.at(0)
            }
        },
    }
</script>
