<template>
    <div class="d-flex justify-space-between align-baseline color text-navigation view-headline font-weight-light">
        <div>
            <v-icon
                size="19"
                class="color text-navigation expand-arrow mr-2"
                @click="$router.go(-1)">
                $vuetify.icons.goBack
            </v-icon>
            <span
                v-for="(route, index) in multipleRoutes"
                :key="index">
                <span v-if="index"> / </span>
                <span
                    :class="[{ 'color text-links pointer': isClickable(index) }]"
                    @click="navigateTo(index)">{{ route.text
                    }}</span>
            </span>
        </div>
        <div>
            <v-btn
                v-if="$route.name === $t('configurationManager')"
                outlined
                rounded
                color="primary"
                class="ml-4 text-capitalize"
                @click="toNewDatabaseConfig">
                <v-icon>mdi-plus</v-icon> {{ $t('create') }} {{ $t('new') }}
            </v-btn>
            <actions-menu
                v-if="showActionsMenu"
                :current-config="selectedConfig"
                :is-button="true" />
        </div>
    </div>
</template>

<script>
    import ActionsMenuVue from '../../components/configurationManager/ActionsMenu.vue'
    import { mapGetters } from 'vuex'
    export default {
        name: 'SettingHeader',
        components: {
            'actions-menu': ActionsMenuVue,
        },
        props: {
            headerText: {
                type: String,
                required: true,
            },
        },
        computed: {
            ...mapGetters(['selectedConfig', 'configInEditMode']),
            showActionsMenu() {
                return this.$route.params && this.$route.params.configName && this.selectedConfig && !this.configInEditMode
            },
            multipleRoutes() {
                let routes = []
                const paths = this.$route.path.split('/')
                const getLink = (index) => {
                    if (!index) return `${paths[index]}`
                    else {
                        return `${getLink(index - 1)}/${paths[index]}`
                    }
                }
                const params = this.$route.params
                paths.forEach((path, index) => {
                    if (path) {
                        if (index === paths.length - 1 && !this.$lodash.isEmpty(params)) {
                            const param = Object.values(params)[0]
                            routes.push({ text: param, link: getLink(index), })
                        } else {
                            // Capitalize routes - some words can be separated by '-'
                            // Trim extra white spaces and '%20' that appears in url path in some cases
                            routes.push({ text: this.$help.capitalize(path.split('-').join(' ').replace(/\s+|%20/g, ' ').trim()), link: getLink(index), })
                        }
                    }
                })
                return routes
            },
        },
        methods: {
            toNewDatabaseConfig() {
                this.$router.push('/settings/configuration-manager/new-database-configuration')
            },
            isClickable(index) {
                return this.multipleRoutes.length - 1 != index
            },
            navigateTo(index) {
                if (this.isClickable(index)) this.$router.push(this.multipleRoutes[index].link)
            },
        },
    }
</script>
