import { fetchEventSource } from '@microsoft/fetch-event-source'
import sseHandler from 'utils/sseHandler'

export function setupEventSource(authToken) {
    const baseUrl = process.env.VUE_APP_API_GATEWAY_BASE_URL
    // Decided to use this package as other packages were not supporting
    // CORS and no data was received from backend on message
    // handler. Native EventSource do not support custom header
    // and EventSourcePolyfill also was not working
    // vue-sse package is also not working with CORS setting
    // although it supports custom headers but not working
    fetchEventSource(`${baseUrl}/notifications/v1/sse/connect`, {
        headers: {
            'Content-Type': 'text/event-stream',
            'Cache-Control': 'no-cache',
            'Connection': 'keep-alive',
            'X-Accel-Buffering': 'no',
            'Authorization': `Bearer ${authToken}`,
        },
        async onopen(response) {
            sseHandler.onOpen(response)
        },
        onmessage(msg) {
            // if the server emits an error message, throw an exception
            // so it gets handled by the onerror callback below:
            sseHandler.onMessage(msg)
        },
        onclose() {
            // if the server closes the connection unexpectedly, retry:
            sseHandler.onClose()
        },
        onerror(err) {
            sseHandler.onError(err)
        },
        // keeps the connection open
        // even when the tab is switched
        // as by default the library
        // closes the connection when tab is changed
        openWhenHidden: true,
    })
}
