<style lang="scss" scoped>
    path {
        fill: currentColor;
    }
</style>

<template>
    <svg viewBox="0 0 13 13">
        <path
            d="M 6.5,4.9999999e-8 C 2.91015,4.9999999e-8 5e-8,2.91015 5e-8,6.5 5e-8,10.0899 2.91015,13 6.5,13 10.0899,13 13,10.0899 13,
            6.5000001 13,2.9101501 10.0899,5.0000001e-8 6.5,4.9999999e-8 Z M 6.462891,2.7480469 C 6.705415,2.7480469 6.915766,2.8317979
            7.095703,3 7.275641,3.1721139 7.365234,3.3863604 7.365235,3.6445312 7.365234,3.8987904 7.275641,4.11499 7.095703,4.2910156
            6.919677,4.4670412 6.709327,4.5546875 6.462891,4.5546875 6.212543,4.5546875 6.000239,4.4670415 5.828125,4.2910156 5.656011,
            4.11499 5.568365,3.8987904 5.564453,3.6445312 5.564453,3.3902721 5.6521,3.1779733 5.828125,3.0058594 6.000239,2.8298338
            6.212543,2.7441352 6.462891,2.7480469 Z m 0.09375,2.2871093 h 0.011718 c 0.234701,0 0.437262,0.087652 0.609376,0.2597657
            0.172114,0.1721138 0.257812,0.3785695 0.257812,0.6210937 v 3.4628903 c 0,0.242524 -0.085698,0.44898 -0.257812,0.621094
            C 7.005621,10.172114 6.80306,10.255865 6.568359,10.251953 H 6.556641 c -0.242524,0 -0.450933,-0.08375 -0.623047,-0.2519531
            C 5.765391,9.8278859 5.681641,9.6214299 5.681641,9.3789059 V 5.9160156 c 0,-0.2425242 0.085698,-0.4489799 0.257812,-0.6210937
            C 6.115479,5.1228079 6.32194,5.0351562 6.556641,5.0351562 Z" />
    </svg>
</template>

<script>
    export default {}
</script>
