import Vue from 'vue'
import { CONFIG } from 'config/'

Object.defineProperties(Vue.prototype, {
    $config: {
        get () {
            return CONFIG
        },
    },
})
