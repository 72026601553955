/* eslint-disable camelcase */
import { CONFIG } from 'config/'

export function mapToAutoScalePolicyObjects(autoScaleOption) {
    const { type, maxValue, minValue, value, enabled=true,} = autoScaleOption
    switch (type) {
    case CONFIG.AUTO_VERTICAL_SCALE:
        return {
            group: CONFIG.VERTICAL_SCALE_GROUP,
            params: {
                max_node_size: maxValue,
                min_node_size: minValue,
            },
            enabled,
        }
    case CONFIG.AUTO_HORIZONTAL_SCALE:
        return  {
            group: CONFIG.HORIZONTAL_SCALE_GROUP,
            params: {
                max_nodes: maxValue,
                min_nodes: minValue,
            },
            enabled,
        }
    case CONFIG.AUTO_STORAGE_SCALE:
        return  {
            group: CONFIG.STORAGE_SCALE_GROUP,
            params: { max_storage_size_gbs: value, },
            enabled,
        }
    }
}
