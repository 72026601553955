<template>
    <svg
        width="16"
        height="8"
        viewBox="0 0 16 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.0265503 0.0266113H15.9734V8.00003H0.0265503V0.0266113Z"
            fill="#FF0000" />
        <path
            d="M4.01324 0.0264893H11.9867V7.99991H4.01324V0.0264893Z"
            fill="white" />
        <path
            d="M7.99985 0.774048L7.45585 1.78869C7.39413 1.89894 7.28352 1.88873 7.1729 1.82712L6.77906 1.62316L7.07259 3.1816C7.13431 3.4663 6.9363 3.4663 6.83855 3.34319L6.15124 2.57376L6.03966 2.96451C6.0268 3.0158 5.97019 3.0697 5.88535 3.05689L5.01619 2.87414L5.2445 3.70407C5.29335 3.88873 5.33152 3.96517 5.19517 4.01391L4.88538 4.15951L6.38151 5.37476C6.44072 5.42075 6.47065 5.5034 6.4496 5.5783L6.31863 6.00801C6.83376 5.94864 7.29537 5.85928 7.81077 5.80427C7.85627 5.79943 7.93245 5.87449 7.93213 5.92722L7.86387 7.50154H8.11435L8.0749 5.93057C8.07459 5.87784 8.14369 5.79938 8.18919 5.80421C8.70459 5.85923 9.1662 5.94859 9.68134 6.00796L9.55036 5.57825C9.52931 5.50335 9.55918 5.42069 9.61845 5.37471L11.1146 4.1594L10.8048 4.01381C10.6684 3.96512 10.7066 3.88863 10.7555 3.70396L10.9838 2.87403L10.1146 3.05679C10.0297 3.0696 9.97316 3.01575 9.9603 2.9644L9.84872 2.57365L9.16142 3.34314C9.06366 3.46625 8.8656 3.46625 8.92737 3.18154L9.2209 1.62311L8.82706 1.82707C8.71645 1.88868 8.60588 1.89888 8.54411 1.78864"
            fill="#FF0000" />
    </svg>
</template>

<script>
    export default {}
</script>
