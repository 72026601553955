<template>
    <v-data-table
        :headers="headers"
        :loading="loading"
        :items="configurations"
        sort-desc
        :sort-by="['created_date']"
        class="data-table-full"
        :no-data-text="noDataTxt">
        <template #item="{ item: config }">
            <tr
                :key="config.index"
                class="pointer"
                @click="handleRowClick(config.name)">
                <td class="color text-links">
                    {{ config.name }}
                </td>
                <td>
                    {{ new Date(config.updated_date * 1000) | moment('from', 'now') }}
                </td>
                <td v-if="allTopologies">
                    {{ getTopologyName(config.topology_id) }}
                </td>
                <td>
                    {{ config.public ? $t('configManager.default') : user.name }}
                </td>
                <td>
                    <actions-menu
                        :current-config="config"
                        :is-button="false" />
                </td>
            </tr>
        </template>
    </v-data-table>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    import ActionsMenuVue from './ActionsMenu.vue'
    export default {
        name: 'SavedConfigurations',
        components: {
            'actions-menu': ActionsMenuVue,
        },
        props: {
            loading: {
                type: Boolean,
                default: false,
            },
            configurations: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                headers: [
                    { text: this.$t('configManager.configName'), value: 'configName', width: '300px', sortable: true, },
                    { text: this.$t('updated'), value: 'updated', width: '110px', sortable: true, },
                    { text: this.$t('launchService.topology'), value: 'topology', width: '200px', sortable: true, },
                    { text: this.$t('owner'), value: 'owner', width: '100px', sortable: true, },
                    { text: '', value: 'menu', width: '30px', sortable: false, }
                ],
                noDataTxt: '$vuetify.noDataText',
                selectedConfig: '',
            }
        },
        computed: {
            ...mapGetters(['allTopologies', 'user']),
        },
        methods: {
            ...mapActions(['getAllTopologies']),
            handleRowClick(configName) {
                this.$router.push(`/settings/configuration-manager/${configName}`)
            },
            getTopologyName(topologyId) {
                const topology = this.allTopologies.find(topology => topology.id === topologyId)
                return topology ? this.$help.getTopologyName(topology.name) : this.$i18n.t('notApplicable')
            },
        },
        async created() {
            if (!this.allTopologies) await this.getAllTopologies()
        },
    }
</script>
