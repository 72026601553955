<template>
    <v-menu
        transition="slide-y-transition"
        offset-y>
        <template #activator="{ on, attrs }">
            <v-icon
                v-bind="attrs"
                v-on="on">
                mdi-dots-horizontal
            </v-icon>
        </template>
        <v-list>
            <v-list-item @click="edit">
                <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
            </v-list-item>
            <v-list-item
                class="error--text"
                @click="remove">
                <v-list-item-title>{{ $t('remove') }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
<script>
    export default {
        name: 'ReplicationMenu',
        methods: {
            edit() {
                this.$emit('on-edit')
            },
            remove() {
                this.$emit('on-remove')
            },
        },
    }
</script>
