<style lang="scss" scoped>
.header {
    background-color: $portal-header-bg!important;

    .product-link {
        display: block;
    }

    div.product-title {
        float: right;
        font-weight: 500;
        font-size: 18px;
        display: inline;
        margin-top: 0.5rem;
    }
}

.v-btn {
    letter-spacing: normal;
}

.v-menu__content {
    border-radius: 0;

    & .v-list {
        padding-top: 0;
        padding-bottom: 0;
        border-radius: 0;
    }
}
</style>

<template>
    <v-app-bar
        class="header"
        clipped-left
        fixed
        app
        flat
        dark>
        <v-toolbar-title class="pl-8">
            <a
                class="product-link"
                to="/dashboard">
                <img
                    class="float-left"
                    height="38px"
                    src="@/assets/logo.svg"
                    alt="MariaDB" />
                <div class="product-title ft-azo-sans white--text ml-3">
                    {{ $config.productName }}
                    <a
                        v-if="showOldReleaseLink"
                        data-auto="header.go-back-to-current-release"
                        class="ml-4 text-caption white--text text-decoration-underline"
                        @click="navigateToOldPortal">{{ $t('switchToPreviousRelease') }}</a>
                </div>
            </a>
        </v-toolbar-title>

        <v-spacer />
        <v-btn
            v-if="canUpgradeToPower"
            color="accent"
            depressed
            class="mr-3 text-caption font-weight-medium"
            height="21"
            small
            rounded
            @click="openTierUpgradeDialog">
            {{ $t('tierUpgrade.upgrade') }}
        </v-btn>

        <UpgradeTierModal
            :show-dialog="showTierUpgrade"
            @cancel-upgrade="resetModal" />

        <NotificationsList />

        <v-menu
            transition="slide-y-transition"
            offset-y
            content-class="py-0">
            <template #activator="{ on }">
                <v-btn
                    class="mr-0"
                    text
                    tile
                    v-on="on"
                    @click="isProfileOpened = !isProfileOpened">
                    <span class="ft-adrianna text-none d-md-inline">
                        <span v-if="impersonatedUser">
                            {{ user.firstName }} (
                            {{ impersonatedUserName }}
                            <v-tooltip bottom>
                                <template #activator="{ on: tooltipHover }">
                                    <v-icon
                                        size="18"
                                        class="mb-1"
                                        v-on="tooltipHover">
                                        mdi-information
                                    </v-icon>
                                </template>
                                <span> {{ $t('impersonating') }} </span>
                            </v-tooltip>)
                        </span>
                        <span v-else>
                            {{ user.name }}
                        </span>
                        <v-icon
                            class="mr-0 ml-1"
                            left>
                            {{
                                "mdi-chevron-" + (isProfileOpened ? "up" : "down")
                            }}
                        </v-icon>
                    </span>
                </v-btn>
            </template>
            <v-list>
                <v-list-item
                    data-auto="user-tier">
                    <v-list-item-title>
                        <TierBadge
                            class="mr-4"
                            :tier-name="tier" />
                    </v-list-item-title>
                </v-list-item>
                <v-list-item
                    data-auto="user-preference"
                    @click="$router.push('/user-preferences')">
                    <v-list-item-title> {{ $t("userPreferences") }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                    data-auto="user-billing"
                    @click="$router.push('/billing')">
                    <v-list-item-title> {{ $t("billing") }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                    data-auto="logout-user"
                    @click="doLogout()">
                    <v-list-item-title>{{ $t("logout") }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<script>
    import NotificationsList from 'components/alerts/NotificationsList'
    import TierBadge from 'components/service/TierBadge.vue'
    import UpgradeTierModal from 'components/common/UpgradeTierModal.vue'
    import { isFoundationTier } from 'utils/organization'

    export default {
        name: 'Header',
        components: {
            NotificationsList,
            TierBadge,
            UpgradeTierModal,
        },
        props: {
            tier: { type: String, required: true, },
            user: { type: Object, required: true, },
            showOldReleaseLink:{ type: Boolean, required: true,},
            impersonatedUser: { type : Object, default: null,},
        },
        data () {
            return {
                isProfileOpened: false,
                showTierUpgrade: false,
            }
        },
        computed: {
            previousRelease(){
                return this.$config.OLD_PORTAL_URL
            },
            canUpgradeToPower() {
                return isFoundationTier(this.tier)
            },
            impersonatedUserName() {
                const { firstName, lastName, } = this.impersonatedUser
                return firstName + ' ' + lastName
            },
        },
        methods: {
            doLogout () {
                this.$emit('logoutUser')
            },
            navigateToOldPortal() {
                this.$emit('on-show-previous-release')
            },
            openTierUpgradeDialog() {
                if (!this.canUpgradeToPower) {
                    return
                }
                this.showTierUpgrade = true
            },
            resetModal() {
                this.showTierUpgrade = false
            },
        },
    }
</script>
