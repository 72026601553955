import { grantToken, refreshToken, logout } from 'services/auth'
import to from 'await-to-js'
import { getUserById } from 'services/users'
import { getOrgDetails } from 'services/organizations'

export default {
    state: {
        authToken: null,
        expiry: null,
        authError: false,
        isFetchingToken: false,
        interval: null,
        authorized: true,
        redirectSSO: false,
        serverlessToken: null,
        refreshTokenExpiry: null,
        impersonatedUser: null,
    },
    mutations: {
        setToken(state, payload) {
            state.authToken = payload.access_token
            state.expiry = payload.expires_in
            state.userData = payload.user
            state.serverlessToken = payload.serverless_token
            state.refreshTokenExpiry = payload.refresh_expires_in
        },
        setUserAuthorized(state, payload) {
            state.authorized = payload
        },
        setTokenErrors(state, payload) {
            state.authError = payload
        },
        setIsFetchingToken(state, payload) {
            state.isFetchingToken = payload
        },
        setRefreshInterval(state, intervalId) {
            state.interval = intervalId
        },
        setSSORedirect(state, flag) {
            state.redirectSSO = flag
        },
        setImpersonation(state, payload) {
            state.impersonatedUser = payload
        },
    },
    actions: {
        async getToken({ commit, dispatch, }, { code, state, }) {
            commit('setIsFetchingToken', true)
            const [error, response] = await to(grantToken(code, state))

            if (!error) {
                const {
                    data: { user, org, actor, },
                } = response
                commit('setUserAuthorized', true)
                commit('setToken', response.data)
                dispatch('setUser', user, { root: true, })
                dispatch('setOrg', org, { root: true, })
                dispatch('setupImpersonation', actor, { root: true, })
                commit('setTokenErrors', false)
            } else {
                commit('setUserAuthorized', false)
                commit('setTokenErrors', error)
            }
            commit('setIsFetchingToken', false)
        },

        async getRefreshToken({ commit, dispatch, }) {
            const [error, response] = await to(refreshToken())
            if (response) {
                const {
                    data: { user, org, actor, },
                } = response
                commit('setUserAuthorized', true)
                commit('setToken', response.data)
                dispatch('setUser', user, { root: true, })
                dispatch('setOrg', org, { root: true, })
                dispatch('setupImpersonation', actor, { root: true, })
                commit('setTokenErrors', false)
            } else {
                commit('setTokenErrors', error)
                commit('setUserAuthorized', false)
                commit('setToken', {})
            }
        },

        refreshAccessTokenRecursively({ state, commit, }, seconds) {
            clearInterval(state.interval)

            const intervalId = setInterval(async () => {
                const [error, response] = await to(refreshToken())
                if (response) {
                    commit('setToken', response.data)
                    commit('setTokenErrors', false)
                } else {
                    commit('setTokenErrors', error)
                    commit('setUserAuthorized', false)
                }
                // Keeping the access token refresh half of
                // what API standard is
            }, (seconds * 1000) / 2)

            commit('setRefreshInterval', intervalId)
        },
        stopRefreshingAccessToken({ state, commit, }) {
            clearInterval(state.interval)
            commit('setRefreshInterval', null)
        },
        async logoutUser({ commit, }) {
            commit('setToken', {
                /* eslint-disable camelcase */
                access_token: null,
                expires_in: null,
                user: null,
            })
            commit('setUserAuthorized', true)
            await to(logout())
        },
        ssoRedirect({ commit, }, flag) {
            commit('setSSORedirect', flag)
        },
        async setupImpersonation({ commit, }, actor) {
            let impersonatedUser = null
            if (actor) {
                const [error, response] = await to(
                    Promise.all([
                        getOrgDetails(actor.orgId),
                        getUserById(actor.userId)
                    ])
                )
                if (!error) {
                    // In case of impersonation set the
                    // Org Details of the actor user
                    commit('setOrg', response[0])
                    impersonatedUser = response[1]
                }
            }
            // Commit if actor found or not to reflect true state
            commit('setImpersonation', impersonatedUser)
        },
    },
    getters: {
        authToken: (state) => state.authToken,
        expiry: (state) => state.expiry,
        authError: (state) => state.authError,
        isFetchingToken: (state) => state.isFetchingToken,
        userData: (state) => state.userData,
        redirectSSO: (state) => state.redirectSSO,
        authorized: (state) => state.authorized,
        serverlessToken: (state) => state.serverlessToken,
        refreshTokenExpiry: (state) => state.refreshTokenExpiry,
        impersonatedUser: (state) => state.impersonatedUser,
    },
}
