<template>
    <v-container
        class="ma-0 pa-12"
        fluid>
        <v-col cols="auto pa-0 wrapper-container">
            <SettingHeader :header-text="$tc('notification', 2)" />
            <NotificationsTable
                :notifications="notifications"
                :channels="channels"
                :tags="tags"
                :loading="notificationsLoading || channelsLoading"
                @notification-delete="handleDeleteNotifications" />
        </v-col>
    </v-container>
</template>

<script>
    import SettingHeader from './SettingHeader'
    import NotificationsTable from 'components/alerts/NotificationsTable'
    import { mapActions, mapGetters } from 'vuex'

    export default {
        name: 'Notifications',
        components: {
            SettingHeader,
            NotificationsTable,
        },
        computed: {
            ...mapGetters(['notifications', 'notificationsLoading', 'channels', 'tags', 'channelsLoading']),
        },
        methods: {
            ...mapActions(['getNotifications', 'getTags', 'deleteNotifications']),
            handleDeleteNotifications(notifications) {
                this.$store.dispatch('deleteNotifications', notifications)
            },
        },
        created() {
            this.getNotifications()
            this.getTags()
            this.$store.dispatch('fetchChannels')
        },
    }
</script>
