// TODO: Once operator, threshold value are stored in metadata, this function is obsolete
export function getValueFromKeyword ({ strToFind, keyword, }) {
    if (keyword === null) return null
    const keywordIndex = strToFind.lastIndexOf(keyword)
    /* e.g. expr: "(......mariadb == 0 )"
     * keyword is ==, keywordIndex = 8, slice from index 9
     * return 0
     */
    return strToFind.slice(keywordIndex + keyword.length).replace(/\D+/g, '')
}
