<style lang="scss" scoped>
    $gridSizeX: 24;
    $gridGap: 8px;

    .db-grid {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        display: grid;
        grid-template-columns: repeat($gridSizeX, 1fr);
        gap: $gridGap;
    }
    .db-grid-item {
        width: 100%;
        height: 100%;
    }
    .db-wrapper {
        position: relative;
        padding-top: 52px;
    }
    .db-controls {
        width: 100%;
        position: absolute;
            top: 0;
            left: 0;
    }
    .db-link {
        min-width: 70px;
        color: $links;
        font-size: 12px;
        border: 1px solid $links;
        border-radius: 4px;
        &.selected {
            background: $blue-azure;
            border-color: $blue-azure;
            color: #FFF;
        }
    }
</style>

<template>
    <div
        v-resize="setHeight"
        class="db-wrapper px-4"
        :style="{ height: `${height}px`}">
        <div class="db-controls px-4 d-flex justify-space-between">
            <TimePicker
                :options="$config.DASHBOARD_TIME_RANGES"
                :default-value="$config.DASHBOARD_DEFAULT_TIME_RANGE"
                @change="onTimeChange" />

            <div class="db-links d-flex">
                <div
                    v-for="({ id, title }) in links"
                    :key="id"
                    :class="[{ selected: dashboard.id === id }, 'db-link text-center pa-2 ml-1 pointer']"
                    @click="$emit('dashboardChange', id)">
                    {{ title }}
                </div>
            </div>
        </div>
        <div
            ref="grid"
            class="db-grid"
            :style="{ gridTemplateRows: `repeat(${gridSizeY}, 26px)` }">
            <div
                v-for="({ size, pos, title, helpLink, help, metrics, setTime, widget, options, }, i) in dashboard.panels"
                :key="`${dashboard.id}-panel-${i}`"
                class="db-grid-item"
                :style="setGridPosition(size, pos)">
                <DashboardPanel
                    :title="getPanelLocale(title)"
                    :help-link="helpLink"
                    :help-text="getPanelLocale(help)"
                    :metrics="metrics"
                    :refresh-interval="dashboard.refreshInterval"
                    :service="service"
                    :server="server"
                    :time="setTime || time"
                    :load-delay="i * 650">
                    <template #content="{ data }">
                        <component
                            :is="widget"
                            :data="data"
                            :options="options" />
                    </template>
                </DashboardPanel>
            </div>
        </div>
    </div>
</template>

<script>
    import DashboardPanel from 'components/monitoring/Panel.vue'
    import TimePicker from 'components/monitoring/TimePicker.vue'

    export default {
        name: 'MonitoringDashboard',
        components: {
            DashboardPanel,
            TimePicker,
        },
        props: {
            dashboard: {
                type: Object,
                default: () => ({}),
            },
            service: {
                type: Object,
                default: () => ({}),
            },
            server: {
                type: Object,
                default: () => ({}),
            },
            links: {
                type: Array,
                default: () => ([]),
            },
        },
        data() {
            return {
                height: 800,
                time: {
                    selected: true,
                    start: this.$config.DASHBOARD_DEFAULT_TIME_RANGE[0],
                    end: this.$config.DASHBOARD_DEFAULT_TIME_RANGE[1],
                },
            }
        },
        computed: {
            gridSizeY () {
                const { panels, } = this.dashboard
                const gridSizeY = panels.reduce(
                    (prevValue, { size, pos, }) => Math.max(prevValue, (size.y + pos.y) - 1),
                    this.$config.DASHBOARD_MIN_GRID_SIZE_Y)

                return gridSizeY
            },
        },
        watch: {
            dashboard () {
                this.$refs.grid.scrollTop = 0
            },
        },
        methods: {
            setGridPosition (size, pos) {
                return {
                    gridColumn: `${pos.x} / span ${size.x}`,
                    gridRow: `${pos.y} / ${pos.y + size.y}`,
                }
            },
            onTimeChange ([start, end]) {
                this.time = {
                    selected: true,
                    start,
                    end,
                }
            },
            setHeight () {
                // TODO: Fixed until new layout is ready
                this.height = window.innerHeight - 280
            },
            getPanelLocale (locale) {
                return this.$t(`monitoring.panels.${locale}`)
            },
        },
        mounted () {
            this.setHeight()
        },
    }
</script>
