import { render, staticRenderFns } from "./SecureAccess.vue?vue&type=template&id=6f6b46f3&scoped=true&"
import script from "./SecureAccess.vue?vue&type=script&lang=js&"
export * from "./SecureAccess.vue?vue&type=script&lang=js&"
import style0 from "./SecureAccess.vue?vue&type=style&index=0&id=6f6b46f3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f6b46f3",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/skysql-ui-client/skysql-ui-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6f6b46f3')) {
      api.createRecord('6f6b46f3', component.options)
    } else {
      api.reload('6f6b46f3', component.options)
    }
    module.hot.accept("./SecureAccess.vue?vue&type=template&id=6f6b46f3&scoped=true&", function () {
      api.rerender('6f6b46f3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/settings/SecureAccess.vue"
export default component.exports