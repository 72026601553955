<style lang="scss" scoped>
:deep(.custom-fields) {
    width: 240px !important;
    margin-top: 12px;

    .v-input__slot {
        min-height: 0px !important;
    }

    .v-text-field__details {
        padding: 0px !important;
    }

    input[type=number] {
        text-align: left !important;

    }
}
</style>
<template>
    <div v-if="inEditMode && !config.readonly">
        <div v-if="showInputBox(config.flags)">
            <v-text-field
                v-if="isRangeConfig(config.flags)"
                :value="getValue(config)"
                class="text-body-2 font-weight-medium custom-fields"
                :hint="getHint(config.minimum_value, config.maximum_value)"
                :placeholder="isOperatorGenerated(config) ? $t('sizeDefault') : ''"
                :rules="config.rules"
                outlined
                single-line
                type="number"
                @change="onSelect($event, config)" />
            <v-textarea
                v-else
                rows="1"
                class="text-body-2 font-weight-medium custom-fields"
                auto-grow
                outlined
                single-line
                :value="getValue(config)"
                @change="onSelect($event, config)" />
        </div>
        <div v-else>
            <v-select
                v-if="!config.multiselect"
                :value="getValue(config)"
                :items="config.allowed_values"
                class="std std--dropdown"
                :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list' }"
                height="32"
                outlined
                attach
                single-line
                required
                hide-details
                @change="onSelect($event, config)" />
            <v-select
                v-else
                :items="config.allowed_values"
                class="std std--dropdown multiselect"
                :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list' }"
                :placeholder="getValue(config)"
                height="32"
                outlined
                attach
                single-line
                multiple
                required
                hide-details>
                <template #item="{ item, }">
                    <v-checkbox
                        class="scale-reduce"
                        :input-value="isItemSelected(item, config)"
                        primary
                        @click="onMultiSelect(item, config)" />
                    {{ item }}
                </template>
                <template #selection="{ index }">
                    <span v-if="index === 0">{{ getMultiSelectPlaceholderText(config) }}</span>
                </template>
            </v-select>
        </div>
    </div>
    <div v-else>
        {{ getValue(config) }}
    </div>
</template>

<script>
    export default {
        name: 'ConfigValue',
        props: {
            config: {
                type: Object,
                default: () => { },
            },
            inEditMode: {
                type: Boolean,
                default: true,
            },
        },
        methods: {
            getValue(config) {
                const { selected_values: selectedVal, default_value: defaultVal, } = config
                if (this.isOperatorGenerated(config)) return this.$t('sizeDefault')
                if (this.$help.hasFlags([this.$config.CONFIG_MANAGER_FLAGS.SI_UNITS], config.flags) && selectedVal) {
                    // Int to byte conversion
                    const k = 1024
                    let i = 0
                    let val = parseInt(selectedVal, 10) || 0
                    while (val >= k && ++i) {
                        val /= k
                    }
                    return val.toFixed(0) + this.$config.SIZE_UNITS[i]
                }
                return selectedVal ? this.handleMultiValues(config.multiselect, selectedVal) : this.handleMultiValues(config.multiselect, defaultVal)
            },
            handleMultiValues(isMulti, values) {
                // No need of undefined or empty string values
                values = values.filter(value => !this.$typy(value).isUndefined && !this.$help.isNullOrEmptyStr(value))
                // return single value or "<no. of values> + 'selected'"
                return isMulti && values.length > 1 ? `${values.length} ${this.$t('selected')}` : values[0]
            },
            isRangeConfig(flags) {
                const { RANGE, } = this.$config.CONFIG_MANAGER_FLAGS
                return this.$help.hasFlags([RANGE], flags)
            },
            showInputBox(flags) {
                const { FREETEXT, } = this.$config.CONFIG_MANAGER_FLAGS
                return this.isRangeConfig(flags) || this.$help.hasFlags([FREETEXT], flags)
            },
            getHint(min, max) {
                return this.$t('hints.range', { min: min, max: max, })
            },
            onSelect(value, config) {
                this.$emit('on-value-change', config, value)
            },
            isItemSelected(item, config) {
                return config.selected_values ? config.selected_values.includes(item) : config.default_value.includes(item)
            },
            onMultiSelect(value, config) {
                if (!this.$help.isNullOrEmptyStr(value)) {
                    if (!config['selected_values']) config['selected_values'] = this.$lodash.cloneDeep(config.default_value)
                    let selectedVal = config.selected_values
                    if (this.$help.isNullOrEmptyStr(selectedVal)) config['selected_values'] = [] // Remove empty string if any
                    if (selectedVal.includes(value)) {
                        config['selected_values'] = selectedVal.filter(val => val != value)
                    } else {
                        config['selected_values'].push(value)
                    }
                    this.$emit('on-value-change', config)
                }
            },
            getMultiSelectPlaceholderText(config) {
                return config.selected_values.length > 1 ? `${config.selected_values.length} selected` : config.selected_values[0]
            },
            isOperatorGenerated(config) {
                return config['default_value'] == this.$config.OPERATOR_GENERATED
            },
        },
    }
</script>
