function calcTableHeight() {
    const header = 64
    const footer = 40
    const containerPadding = 96
    const nav = 106
    return `calc(100vh - ${(header + footer + containerPadding + nav)}px)`
}

export default Object.freeze({
    alertManagerTableHeight: calcTableHeight(),
    customTagPrefix: '__customTag__',
    serverServiceTagPrefix: 'service-server-',
    allTopologiesTag: 'all/all',
    context: 'context',
    tagType: {
        custom: 'custom',
        system: 'system',
        more: 'more',
    },
    // Adding constants per new API changes, above ones will be deleted during cleanup
    TAG_BODY: {
        TYPE: {
            CUSTOM: 'custom',
            SYSTEM: 'system',
        },
        NAME: {
            SEVERITY: 'severity',
            CONTEXT: 'context',
        },
    },
    SILENCE_MODAL_TYPE: {
        ALL: 'all',
        ALL_SERVICE: 'allService',
        SERVICE: 'service',
        SERVER: 'server',
    },
})
