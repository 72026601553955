<style lang="scss" scoped>
.region-heading {
    color: #a6a4a6;
}

.region-icons {
    height: 2.5rem;
}

.region-wrapper {
    position: relative;
    padding: 5px;
    border: 2px solid transparent;
}

.region-wrapper div.selection-status {
    position: absolute;
    right: 0px;
    top: 0px;
}

.region-wrapper.highlight-instance-row {
    border-radius: 10px;
}

.unavailable-region{
    color: $disabled-region;
}

</style>
<template>
    <v-expansion-panel
        id="cloudProviderPanel"
        :disabled="isDisabled"
        :data-auto="dataAutoPrefix()">
        <AccordianPanelHeader
            :title="getLocaleFor('cloudProvider')"
            :parent-data-auto-prefix="dataAutoPrefix">
            <!-- Tooltip goes here -->
            <template #tooltip-content>
                <p>{{ $t('launchService.cloudProviderInfo') }}</p>
                <a
                    rel="noopener noreferrer"
                    class="text-capitalize"
                    target="_blank"
                    href="https://r.mariadb.com/snr-launch-cloud">{{ $t('learn-more') }}</a>
            </template>
        </AccordianPanelHeader>
        <v-expansion-panel-content :data-auto="dataAutoPrefix('content')">
            <v-row class="pt-4">
                <CloudServiceProvider
                    :is-selected="providerType === 'aws'"
                    :icon="`$vuetify.icons.aws`"
                    :icon-size="80"
                    type="aws"
                    :is-position-right="true"
                    :parent-data-auto-prefix="dataAutoPrefix"
                    @handleCloudProviderSelection="handleCloudProviderSelection" />
                <CloudServiceProvider
                    :is-selected="providerType === 'gcp'"
                    :icon="`$vuetify.icons.googleCloud`"
                    :icon-size="250"
                    type="gcp"
                    :parent-data-auto-prefix="dataAutoPrefix"
                    :is-position-right="false"
                    :is-disabled="isServerless && !$unleash.isFeatureEnabled('enable-portal-severless-on-gcp')"
                    @handleCloudProviderSelection="handleCloudProviderSelection" />
            </v-row>
            <v-row
                class="region-heading"
                :data-auto="dataAutoPrefix('content-regions')">
                <v-col>Americas</v-col>
                <v-col>EMEA</v-col>
                <v-col>APAC</v-col>
            </v-row>
            <v-row v-if="regions.length">
                <RegionList
                    :location-data="americaLocations"
                    region="america"
                    :parent-data-auto-prefix="dataAutoPrefix"
                    :current-location="location"
                    @handleRegionSelection="handleRegionSelection" />
                <RegionList
                    :location-data="emeaLocations"
                    region="emea"
                    :parent-data-auto-prefix="dataAutoPrefix"
                    :current-location="location"
                    @handleRegionSelection="handleRegionSelection" />

                <RegionList
                    :location-data="apacLocations"
                    region="apac"
                    :parent-data-auto-prefix="dataAutoPrefix"
                    :current-location="location"
                    @handleRegionSelection="handleRegionSelection" />
            </v-row>
            <div
                v-if="showClusterMessage"
                class="mt-4 font-italic">
                <v-icon
                    size="20"
                    color="primary">
                    mdi-star
                </v-icon> {{ $t('cluster-exists') }}
            </div>
            <div
                v-if="showMaintenanceMessage && region.maintenanceWindow"
                class="mt-4">
                {{ $t('launchService.maintenanceFoundation') }}
                <i18n
                    path="launchService.maintenanceWindow"
                    tag="p">
                    <span
                        class="font-weight-medium"
                        place="region">{{ region.name }}</span>
                    <span place="window">
                        {{ region.maintenanceWindow }}
                    </span>
                </i18n>
            </div>
            <CloudZoneSelection
                v-if="$unleash.isFeatureEnabled('enable-portal-single-zone') && region.name"
                :key="region.name"
                :zones="zones"
                :is-single-node="isSingleNode"
                @on-single-zone-opted="onSingleZoneOpted"
                @on-zone-select="onProviderZoneSelect" />
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>
<script>
    import i18n from 'plugins/i18n'
    import { getFlagIconForRegion } from 'utils/countries'
    import AccordianPanelHeader from 'components/launchService/AccordianPanelHeader'
    import CloudServiceProvider from 'components/launchService/CloudServiceProvider.vue'
    import RegionList from 'components/launchService/RegionList.vue'
    import CloudZoneSelection from 'components/launchService/CloudZoneSelection.vue'
    import { mapGetters } from 'vuex'
    import { isStandAloneTopology } from 'utils/service'

    export default {
        name: 'CloudProviderSelection',
        components: { AccordianPanelHeader, CloudServiceProvider, RegionList, CloudZoneSelection, },
        props: {
            getLocaleFor: {
                type: Function,
                default: (txt) => txt,
            },
            topology: { type: String, default: '', },
            location: { type: String, default: '', },
            providerType: { type: String, default: '', },
            parentDataAutoPrefix: {
                type: Function,
                default: (txt) => txt,
            },
            currentTier: {
                type: String,
                required: true,
            },
            isServerless: {
                type: Boolean,
                default: false,
            },
            isDisabled: {
                type: Boolean,
                default: true,
            },
            regions: {
                type: Array,
                required: true,
            },
        },
        data() {
            return{
                region: '',
            }
        },
        computed:{
            ...mapGetters(['availabilityZones']),
            americaLocations(){
                return this.filterRegionsForRegions(this.$config.regions.america).map(this.mapFlagForRegion)
            },
            emeaLocations(){
                return  this.filterRegionsForRegions(this.$config.regions.emea).map(this.mapFlagForRegion)
            },
            apacLocations(){
                return this.filterRegionsForRegions(this.$config.regions.apac).map(this.mapFlagForRegion)
            },
            // TODO:: Add coverage later
            showClusterMessage(){
                return this.currentTier === this.$config.POWER_TIER
            },
            showMaintenanceMessage(){
                return this.currentTier === this.$config.FOUNDATION_TIER
            },
            zones(){
                return this.availabilityZones.filter(zone=> zone.region_name === this.region.name)
            },
            isSingleNode(){
                return isStandAloneTopology(this.topology)
            },
        },
        methods: {
            async handleCloudProviderSelection (type) {
                this.region = ''
                this.$emit('onCloudProviderSelect', type)
            },
            handleRegionSelection (region) {
                this.region = region
                this.$emit('onRegionSelect', region)
            },
            dataAutoPrefix (string) {
                return this.parentDataAutoPrefix(
                    `cloudProviderPanel${string && '-' + string}`
                )
            },
            filterRegionsForRegions (regionName) {
                // This function is basically doing a filter on the regions data
                // based on the region name provided.
                // Returns regions data that belong to passed region name.
                return (
                    (this.regions &&
                        this.regions.filter(
                            (prvoiderRegion) => prvoiderRegion.region === regionName
                        )) ||
                    []
                )
            },
            mapFlagForRegion (providerRegion) {
                let icon = `$vuetify.icons.${getFlagIconForRegion(providerRegion.name)}`
                let isDisabled = false
                return {
                    ...providerRegion,
                    icon,
                    label: i18n.t('regions.' + providerRegion.name),
                    isDisabled,
                }
            },
            onProviderZoneSelect(currentZone){
                this.currentZone = currentZone
                this.$emit('on-zone-select', currentZone)
            },
            onSingleZoneOpted(isOpted){
                if(!isOpted) this.currentZone = ''
                this.$emit('on-zone-select', '' )
            },
        },
    }
</script>
