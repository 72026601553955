<style lang="scss" scoped>
.menu-label>div:hover {
    font-weight: bold
}
</style>
<template>
    <v-menu
        v-model="isMenuOpen"
        transition="slide-y-transition"
        offset-y>
        <template #activator="{ on, attrs }">
            <div
                v-bind="attrs"
                v-on="on">
                <span class="text-decoration-none manage-text font-weight-bold">{{ $t('setUp') }} </span>
            </div>
        </template>
        <v-list
            :data-auto="`service-menu-list-${service.name}`"
            class="menu-label">
            <v-list-item @click="setupAllowList">
                <v-list-item-title>{{ $t('setupAllowList') }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="setupPrivateConnect">
                <v-list-item-title>
                    {{ $t("privateConnect.setup", { connectionName: privateConnectType })
                    }}
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>
<script>
    import { privateConnectLabel } from 'utils/service'
    export default {
        name: 'SetupSecureAccessMenu',
        props: {
            service: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                isMenuOpen: false,
            }
        },
        computed: {
            privateConnectType() {
                return privateConnectLabel(this.service.provider)
            },
        },
        methods: {
            setupAllowList() {
                this.$emit('on-setup-allowlist', this.service.id)
            },
            setupPrivateConnect() {
                this.$emit('on-setup-private-connect', this.service.id)
            },
        },
    }
</script>
