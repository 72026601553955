<template>
    <div>
        <h2>Summary</h2>
        <h4>Overall Service Quality from 2023-05-03 to 2023-05-05:</h4>
        <p class="my-2">
            Our analysis identified significant anomalies in the Memory_used and delta_Connections metrics. The observed data showed higher values than expected in these metrics, following a similar trend during the period from 2023-05-05 12:00:00 to 2023-05-05 22:00:00. Our predictive model suggests that these anomalous metrics are likely to reduce and return to a more normal range in the near future. To ensure optimal database performance, we recommend optimizing memory allocation, controlling connection limits, implementing connection caching, setting binary log expiration, and addressing slow queries.
        </p>
        <v-row>
            <v-col>
                <img
                    width="500"
                    src="@/assets/wla/memory-used.png" />
            </v-col>
            <v-col>
                <img
                    width="500"
                    src="@/assets/wla/delta-connections.png" />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <img
                    width="500"
                    src="@/assets/wla/delta-slow-queries.png" />
            </v-col>
            <v-col>
                <img
                    width="500"
                    src="@/assets/wla/open-tables.png" />
            </v-col>
        </v-row>
        <p class="ml-12">
            Image of Machine learning model output for a 48 hour window.
        </p>
    </div>
</template>
<script>
    export default {
        name: 'Summary',
    }
</script>
