<style lang="scss" scoped>
.good--text {
    color: $good;
}

.lag-text {
    font-size: 0.625rem;
}

.status-wrapper {
    position: relative;
}
</style>
<template>
    <div
        :id="wrapperId"
        class="d-flex flex-column align-center status-wrapper">
        <v-tooltip
            :disabled="!hasMultipleReplicas"
            open-delay="300"
            top>
            <template #activator="{ on }">
                <canvas
                    :id="canvasId"
                    :style="{ width: canvasStyle.width + 'px', height: canvasStyle.height + 'px' }"
                    v-on="on"></canvas>
            </template>
            <div>
                <div class="font-weight-bold">
                    {{ $t('globalXpand.oneToMany') }}
                </div>
                <div>{{ $t('globalXpand.replicatesTo') }}</div>
                <span
                    v-for="(replica, ind) in replicas"
                    :key="ind">
                    <v-icon
                        :color="getColorByStatus(replica.service.status)"
                        :size="10">
                        mdi-circle
                    </v-icon> {{ replica.service.name }}<br />
                </span>
            </div>
        </v-tooltip>
        <div
            class="text-caption text-capitalize"
            :class="getReplicationStatusTextClass">
            {{ getReplicationStatusText }}
        </div>
        <div
            v-if="!hasMultipleReplicas"
            class="lag-text grey--text">
            {{ $t('globalXpand.lag') }} {{ lagData }}
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import { getClass, getLag, getReplicas, getColor, getReplicationStatus, getStrokeStyle } from 'utils/replication'

    export default {
        name: 'ReplicationStatus',
        props: {
            service: {
                type: Object,
                required: true,
            },
        },
        computed: {
            ...mapGetters(['services', 'topologiesData']),
            lagData() {
                return getLag(this.topologiesData, this.replicas[0], this.service)
            },
            replicas() {
                return getReplicas(this.services, this.service)
            },
            hasMultipleReplicas() {
                return this.replicas.length > 1
            },
            wrapperId() {
                return `wrapper-${this.service.id}`
            },
            canvasId() {
                return `panel-canvas-${this.service.id}`
            },
            markerId() {
                return `marker-${this.service.id}`
            },
            overlayId() {
                return `overlay-${this.service.id}`
            },
            overlayEl() {
                return document.getElementById(this.overlayId)
            },
            canvasStyle() {
                return { width: 100, height: this.hasMultipleReplicas ? 50 : 32, }
            },
            getReplicationStatusText() {
                const { MODIFYING, PENDING, CRITICAL, } = this.$config.REPLICATION_STATUS
                switch (getReplicationStatus(this.topologiesData, this.replicas, this.service)) {
                    case MODIFYING:
                        return this.$t('Service.pendingModifying')
                    case PENDING:
                        return this.$t('Service.pending')
                    // Replication is "Broken" if inbound status is CRITICAL; "Active" if status is OK / WARNING
                    case CRITICAL:
                        return this.$t('globalXpand.broken')
                    default:
                        return this.$t('active')
                }
            },
            getReplicationStatusTextClass() {
                const { MODIFYING, PENDING, CRITICAL, } = this.$config.REPLICATION_STATUS
                switch (getReplicationStatus(this.topologiesData, this.replicas, this.service)) {
                    case MODIFYING:
                    case PENDING:
                        return 'grey--text'
                    case CRITICAL:
                        return 'error--text'
                    default:
                        return 'good--text'
                }
            },
        },
        watch: {
            services: {
                handler() {
                    this.mapMarkers()
                },
            },
        },
        methods: {
            ...mapActions(['getServiceById']),
            getClassByStatus(status, isReplica) {
                return getClass(status, isReplica)
            },
            getColorByStatus(status) {
                return getColor(status)
            },
            // Markers co-ordinates based on widget dimension
            mapMarkers() {
                let wrapper = document.getElementById(this.wrapperId)
                if (this.overlayEl) {
                    // Remove old overlay if present to avoid overlapping new ones
                    wrapper.removeChild(this.overlayEl)
                }
                let overlay = document.createElement('span')
                overlay.id = this.overlayId
                let markerEl = document.getElementById(this.markerId) ? document.getElementById(this.markerId) : document.createElement('span')
                markerEl.id = `marker-${this.service.id}`
                markerEl.classList.add('marker')
                markerEl.classList.add(this.getClassByStatus(this.service.status, false))
                markerEl.style.left = '25px'
                markerEl.style.top = '10px'
                overlay.appendChild(markerEl)
                if (this.hasMultipleReplicas) {
                    markerEl.style.top = '20px'
                    let replicaElTop = document.createElement('span')
                    replicaElTop.classList.add('marker')
                    replicaElTop.classList.add(this.getClassByStatus(this.replicas[0].service.status, true))
                    replicaElTop.style.left = '65px'
                    replicaElTop.style.top = '5px'
                    overlay.appendChild(replicaElTop)
                    let replicaElBottom = document.createElement('span')
                    replicaElBottom.classList.add('marker')
                    replicaElBottom.classList.add(this.getClassByStatus(this.replicas[1].service.status, true))
                    replicaElBottom.style.left = '65px'
                    replicaElBottom.style.top = '33px'
                    overlay.appendChild(replicaElBottom)
                } else {
                    let replicaEl = document.createElement('span')
                    replicaEl.classList.add('marker')
                    replicaEl.classList.add(this.getClassByStatus(this.replicas[0].service.status, true))
                    replicaEl.style.left = '65px'
                    replicaEl.style.top = '10px'
                    overlay.appendChild(replicaEl)
                }
                wrapper.appendChild(overlay)
                this.drawLines()
            },
            // Lines co-ordinates based on widget dimension
            drawLines() {
                let context = document.getElementById(this.canvasId).getContext('2d')
                context.lineWidth = 10
                context.setLineDash([20, 10])
                context.beginPath()
                // Clear previous lines and later update with new ones
                context.clearRect(0, 0, this.canvasStyle.width, this.canvasStyle.height)
                context.save()
                context.strokeStyle = getStrokeStyle(this.topologiesData, this.replicas[0], this.service, this.$vuetify.theme.themes.light)
                if (this.hasMultipleReplicas) {
                    context.translate(0.5, 0.5)
                    context.lineWidth = 7
                    context.moveTo(95, 75)
                    context.lineTo(215, 30)
                    context.stroke()

                    context.beginPath()
                    context.strokeStyle = getStrokeStyle(this.topologiesData, this.replicas[1], this.service, this.$vuetify.theme.themes.light)
                    context.moveTo(95, 75)
                    context.lineTo(215, 120)
                    context.stroke()
                } else {
                    context.moveTo(95, 75)
                    context.lineTo(215, 75)
                    context.stroke()
                }
                context.restore()
            },
        },
        mounted() {
            this.mapMarkers()
        },
    }
</script>
