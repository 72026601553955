<style lang="scss" scoped>

.old-portal-margin{
    .selection-wrapper {
        top: 18%;
    }
}
.selection-wrapper {
    position: fixed;
    min-width: 280px;
    max-width: 370px;
    top: 12%;
    padding-bottom: 200px;
    transform: translateZ(0);

    .selected-replica-box {
        border-radius: 1.25rem;
        border: 1px solid;
        min-width: 6rem;
        padding: 7px;
        color: #0e6488;
        width: auto;
        height: 2.5rem;
        font-weight: 500;
    }

    .selection-category{
        font-size: 10px;
        color: $grayed-out;
    }
}

.coupon {
    color: green;
    font-weight: 600
}
</style>

<template>
    <div
        class="selection-wrapper"
        :data-auto="dataAutoPrefix()">
        <v-card class="pa-4 pt-6 pb-4 mb-8 selection-entity-box">
            <v-row class="d-flex align-end">
                <SelectedConfig
                    :title="$t(`dbType.${typeOfProduct}`)"
                    :sub-title="$t('launchService.technology')"
                    :icon-size="36"
                    v-bind="getDatabaseTypeProps()">
                    <div v-if="!typeOfProduct">
                        ...
                    </div>
                </SelectedConfig>

                <SelectedConfig
                    :show-info="isTopologySelected"
                    :title="topologyType"
                    :should-capitalize="false"
                    :sub-title="$t('launchService.topology')"
                    :icon="topologyIcon"
                    :icon-size="60">
                    <div v-if="!isTopologySelected">
                        ...
                    </div>
                </SelectedConfig>
            </v-row>
            <v-row
                v-if="isTopologySelected"
                class="d-flex align-end">
                <SelectedConfig
                    :title="providerName"
                    :sub-title="$t('launchService.provider')"
                    v-bind="getProviderIcon()" />
                <SelectedConfig
                    :title="location"
                    :sub-title="$t('launchService.location')"
                    :show-info="!!providerType"
                    :icon="currentLocationIcon"
                    :icon-size="28">
                    <div v-if="!location">
                        ...
                    </div>
                </SelectedConfig>
            </v-row>
            <div
                v-if="!isServerLess && isInstanceSelected"
                class="text-caption">
                <v-row
                    class="justify-center text-center mt-6">
                    <div class="selected-replica-box">
                        {{ isNodes ? $help.getNodesTextForNonReplicatedTopology(replicas) : $help.getNodesTextForReplicatedTopology(replicas) }}
                    </div>
                </v-row>
                <div
                    class="justify-center text-center mt-6">
                    <div>
                        {{ selectedInstance.display_name }}: <strong>{{ selectedInstance.cpu }}</strong> •
                        <strong>{{ selectedInstance.ram }}</strong> •
                        <strong>
                            {{ selectedInstanceDisk }} {{ $t('launchService.gb') }}
                            <span v-if="showIopsLabel">({{ $t('iops', { iops: iopsVolumeSize }) }} )</span>
                        </strong> •
                        <strong>{{ selectedArchitecture }}</strong>
                    </div>
                    <div
                        class="text-center font-weight-thin mt-n1 selection-category"
                        block>
                        {{ $t('launchService.instanceAndStorage') }}
                    </div>
                </div>
                <div
                    v-if="selectedServer"
                    class="justify-center text-center mt-6 font-weight-bold">
                    <div>
                        {{ selectedServer }}
                    </div>
                    <div
                        class="text-center font-weight-thin mt-n1 selection-category"
                        block>
                        {{ $t('launchService.server') }}
                    </div>
                </div>
            </div>
        </v-card>

        <ServiceComputation
            v-if="isInstanceSelected && !isServerLess"
            :billing-items="unitCosts"
            :total-estimate="serviceCostEstimate" />

        <div
            v-if="showCoupon"
            class="mt-6 mb-4 text-caption"
            :data-auto="dataAutoPrefix('coupon-section')">
            <div class="coupon">
                {{ $t('launchService.couponApplied', [couponValue]) }}
            </div>
            <p class="pb-2 mb-0">
                {{ $t('launchService.complimentFromUs') }}
            </p>
            <p>
                {{ $t('launchService.freeUsage') }}

                <a>{{ $t('launchService.learnMore') }}</a>
            </p>
        </div>
    </div>
</template>

<script>
    import SelectedConfig from 'components/launchService/SelectedConfig'
    import ServiceComputation from 'components/common/ServiceComputation'

    export default {
        name: 'SelectedServiceComputation',
        components: {
            SelectedConfig,
            ServiceComputation,
        },
        props: {
            selectedServer: { type: String, default: '', },
            typeOfProduct: { type: String, default: '', },
            isTransactionType: Boolean,
            selectedArchitecture: { type: String, default: '', },
            selectedInstanceDisk: { type: Number, default: 0, },
            topologyType: { type: String, default: '', },
            topologyIcon: { type: String, default: '', },
            isTopologySelected: Boolean,
            providerType: { type: String, default: '', },
            providerName: { type: String, default: '', },
            location: { type: String, default: '', },
            currentLocationIcon: { type: String, default: '', },
            unitCosts: {
                type: Array,
                required: true,
            },
            showCoupon: {
                type: Boolean,
                default: false,
                required: true,
            },
            parentDataAutoPrefix: {
                type: Function,
                default: (txt) => txt,
            },
            replicas: { type: Number, default: 0, },
            iopsVolumeSize: { type: Number, default: 0, },
            iopsVolumeType: { type: String, default: '', },
            serviceCostEstimate: {
                type: Object,
                required: true,
            },
            isServerLess: {
                type: Boolean,
                required: true,
                default: false,
            },
            selectedInstance: {
                type: Object,
                default: ()=>{},
            },
            topology: {
                type: String,
                required: true,
            },
        },
        data: function () {
            return {
                mariaDBServer: null,
                serviceName: null,
            }
        },
        computed: {
            showIopsLabel() {
                const { cloudProvider: { AWS, }, iops: { io1, }, } = this.$config
                return this.providerType === AWS && this.iopsVolumeType === io1
            },
            couponValue() {
                return this.$config.COUPON
            },
            isInstanceSelected(){
                return this.selectedInstance && !this.$typy(this.selectedInstance).isEmptyObject
            },
            isNodes(){
                return this.$help.hasNodesInTopology(this.topology)
            },
        },
        methods: {
            getDatabaseTypeProps() {
                return {
                    icon: `$vuetify.icons.${this.typeOfProduct}`,
                    showInfo: !!this.typeOfProduct,
                }
            },

            getProviderIcon() {
                return {
                    icon:
                        this.providerType === 'gcp'
                            ? '$vuetify.icons.gcp'
                            : '$vuetify.icons.aws',
                    iconSize: 40,
                    showInfo: !!this.providerType,
                }
            },
            dataAutoPrefix(string) {
                return this.parentDataAutoPrefix(
                    `selectedServiceOverview${string ? '-' + string : ''}`
                )
            },
        },
    }
</script>
