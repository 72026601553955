
<style lang="scss" scoped>
    path {
        stroke: currentColor;
    }
</style>

<template>
    <svg
        viewBox="0 0 18 18"
        fill="none">
        <path
            d="M9.2 17.2C13.6183 17.2 17.2 13.6183 17.2 9.2C17.2 4.78172 13.6183 1.2 9.2
               1.2C4.78172 1.2 1.2 4.78172 1.2 9.2C1.2 13.6183 4.78172 17.2 9.2 17.2Z"
            stroke="#8899AA"
            stroke-width="1.5" />
        <path
            d="M9.3 12.9V8.7"
            stroke="#8899AA"
            stroke-width="1.5"
            stroke-linecap="round" />
        <path
            d="M9.3 4.8C8.80294 4.8 8.4 5.20294 8.4 5.7C8.4 6.19706 8.80294 6.6 9.3 6.6C9.79706 6.6
               10.2 6.19706 10.2 5.7C10.2 5.20294 9.79706 4.8 9.3 4.8Z"
            fill="#8899AA" />
    </svg>
</template>

<script>
    export default {}
</script>
