import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters(['rules']),
        getRuleIndex: function () {
            let map = new Map()
            for (const [i, rule] of this.rules.entries()) {
                map.set(rule.alert, i)
            }
            return map
        },
    },

    methods: {
        validateRuleName (v) {
            const maxLengthRuleName = 120
            if (!v) return this.$t('errors.fieldRequired', { field: this.$t('ruleName'), })
            else if (this.$typy(this.getRuleIndex.get(v)).isNumber) return this.handleShowDuplicatedErrMsg(v)
            else if (v.length > maxLengthRuleName) return this.$t('errors.ruleNameMaxLength', { maxLength: maxLengthRuleName, })
            return true
        },
        handleShowDuplicatedErrMsg (v) {
            if (this.isEditMode) {
                if (this.initialRule.alert === v) return true
                return this.$t('errors.duplicatedValue', { v, })
            }
            return this.$t('errors.duplicatedValue', { v, })
        },
    },
}
