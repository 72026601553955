import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from 'store/index'

const apiClient = axios.create({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
})

apiClient.interceptors.request.use(function (config) {
    // Check if unauth request made for other service API's
    // this will remove the dependency to clear out
    // unhandled polling api calls when session is terminated
    const isSessionUrl = config.url.includes('iam/v1/session')
    if(!store.getters.authorized && !isSessionUrl){
        const controller = new AbortController()
        const signal = controller.signal
        let cfg = {
            ...config,
            signal,
        }
        controller.abort()
        return cfg
    }
    return config
}, function (error) {
    // Do something with request error
    return Promise.reject(error)
})

apiClient.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        // Add a way to check error status code type
        // based on that take further actions
        let errorMessage = error.message

        if (error.response) {
            const { errors, } = error.response.data
            errorMessage = errors[0].message
            if (errors[0].error === 'ERR_AUTH_TOKEN_INVALID') {
                // Access token expired trigger token refresh 1 time
                // if token refresh coming unauthorized user will be
                // redirected to Login page
                store.dispatch('getRefreshToken', { root: true, })
            }
        }
        // eslint-disable-next-line no-console
        console.error(errorMessage)
        return Promise.reject(error)
    }
)

Vue.use(VueAxios, apiClient)
