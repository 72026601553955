import { t } from 'typy'

const MAX_LENGTH_OF_STRING = 100

function getDecimalsForValue(value) {
    const log10 = Math.floor(Math.log(Math.abs(value)) / Math.LN10)
    let dec = -log10 + 1
    const magn = Math.pow(10, -dec)
    const norm = value / magn // norm is between 1.0 and 10.0
    // special case for 2.5, requires an extra decimal
    if (norm > 2.25) ++dec
    if (value % 1 === 0) dec = 0
    const decimals = Math.max(0, dec)

    return decimals
}

export function toFixed(value, decimals) {
    if (t(value).isNullOrUndefined) return ''

    if (value === Number.NEGATIVE_INFINITY || value === Number.POSITIVE_INFINITY) {
        return value.toLocaleString()
    }
    if (decimals === null || decimals === undefined) {
        decimals = getDecimalsForValue(value)
    }
    const factor = decimals ? Math.pow(10, Math.max(0, decimals)) : 1
    const formatted = String(Math.round(value * factor) / factor)
    // if exponent return directly
    if (formatted.indexOf('e') !== -1 || value === 0) {
        return formatted
    }
    const decimalPos = formatted.indexOf('.')
    const precision = decimalPos === -1 ? 0 : formatted.length - decimalPos - 1
    if (precision < decimals) {
        return (precision ? formatted : formatted + '.') + String(factor).substr(1, decimals - precision)
    }

    return formatted
}

export function toFixedScaled(value, decimals, ext) {
    return {
        text: toFixed(value, decimals),
        suffix: ext,
    }
}

export function toFixedUnit(unit, asPrefix) {
    return (size, decimals) => {
        if (t(size).isNullOrUndefined) {
            return { text: '', }
        }
        const text = toFixed(size, decimals)
        if (unit) {
            if (asPrefix) {
                return { text, prefix: unit, }
            }
            return { text, suffix: ' ' + unit, }
        }
        return { text, }
    }
}
// Formatter which scales the unit string geometrically according to the given
// numeric factor. Repeatedly scales the value down by the factor until it is
// less than the factor in magnitude, or the end of the array is reached.
export function scaledUnits(factor, extArray) {
    return (size, decimals) => {
        if (t(size).isNullOrUndefined) {
            return { text: '', }
        }
        if (size === Number.NEGATIVE_INFINITY || size === Number.POSITIVE_INFINITY || isNaN(size)) {
            return { text: size.toLocaleString(), }
        }
        let steps = 0
        const limit = extArray.length
        while (Math.abs(size) >= factor) {
            steps++
            size /= factor
            if (steps >= limit) {
                return { text: 'NA', }
            }
        }
        return { text: toFixed(size, decimals), suffix: extArray[steps], }
    }
}

export function locale(value, decimals) {
    if (t(value).isNullOrUndefined) {
        return { text: '', }
    }
    return {
        text: value.toLocaleString(undefined, { maximumFractionDigits: decimals, }),
    }
}

export function simpleCountUnit(symbol) {
    const units = ['', 'K', 'M', 'B', 'T']
    const scaler = scaledUnits(1000, units)
    return (size, decimals, scaledDecimals) => {
        if (t(size).isNullOrUndefined) {
            return { text: '', }
        }
        const v = scaler(size, decimals, scaledDecimals)
        v.suffix += ' ' + symbol
        return v
    }
}

export function stringFormatter(value) {
    return { text: `${value}`, }
}

export function trimString(text) {
    if (text.length > MAX_LENGTH_OF_STRING) {
        return { text: text.substring(0, MAX_LENGTH_OF_STRING - 3) + ' ...', }
    }
    return { text, }
}

export function roundOff(value, decimal) {
    return {
        text: Math.round(value * Math.pow(10, decimal)) / Math.pow(10, decimal),
    }
}
