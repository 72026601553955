<template>
    <v-progress-linear
        v-if="isInitializing"
        indeterminate
        color="primary" />
    <mxs-query-editor
        v-else
        class="fill-height">
        <slot
            v-for="(_, slot) in $slots"
            :slot="slot"
            :name="slot">
        </slot>
    </mxs-query-editor>
</template>

<script>
    export default {
        name: 'SkyQueryEditor',
        props: {
            isInitializing: {
                type: Boolean,
                required: true,
            },
        },
    }
</script>
