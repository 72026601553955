<template>
    <svg
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
            fill="#EB5757" />
        <path
            d="M5.17725 5.01971L10.8915 10.734"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round" />
        <path
            d="M10.8914 5.01971L5.17707 10.734"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round" />
    </svg>
</template>

<script>
    export default {}
</script>
