<style lang="scss" scoped>
::v-deep .alerts-table {
    width: 100%;

    td {
        font-size: 0.75rem !important;
    }

    th {
        white-space: nowrap;
    }

    td:not(:first-of-type):not(:last-of-type),
    th:not(:first-of-type):not(:last-of-type) {
        padding: 0 12px;
    }

    td:first-of-type,
    th:first-of-type {
        padding: 0 12px 0 16px;
    }

    td:last-of-type,
    th:last-of-type {
        padding: 0 16px 0 12px;
    }
}

.opened {
    transform: rotate(90deg);
}

.scale-down {
    transform: scale(0.8);
}

.silenced-overlay {
    background-color: #f5f5f5;
    height: 100%;
    width: 100%;
    opacity: 0.5;
    z-index: 5;
}
</style>

<template>
    <v-data-table
        :headers="headers"
        :loading="loading"
        :items="tableRows"
        :server-items-length="alertsTotalCount"
        :sort-desc="true"
        multi-sort
        :footer-props="footerOptions"
        :options.sync="options"
        item-key="alertname"
        class="data-table-full alerts-table"
        :no-data-text="noDataTxt">
        <template #item="{ item: alertGroup, index }">
            <tr
                :key="Math.random()"
                class="font-weight-medium "
                :class="[{ 'red lighten-5': alertGroup.isActive }, { 'silenced-overlay': activeSilence }]"
                @click="clicked(index)">
                <td>
                    <active-label v-if="alertGroup.isActive" />
                </td>
                <td>
                    <v-icon
                        size="12"
                        :class="['color text-links expand-arrow mr-3', { opened: expanded.includes(index) }]">
                        $vuetify.icons.arrowRight
                    </v-icon>
                    {{ alertGroup.groupName }}
                </td>
                <td> {{ alertGroup.groupItems.length }} events </td>
                <td> </td>
                <td> </td>
            </tr>
            <template v-if="expanded.includes(index)">
                <tr
                    v-for="(alert, _index) in alertGroup.groupItems"
                    :key="Math.random(_index)"
                    :class="[{ 'silenced-overlay': activeSilence }]"
                    :colspan="headers.length"
                    class="grey lighten-4">
                    <td>
                        <active-label
                            v-if="alert.firing"
                            class="scale-down" />
                    </td>
                    <td>
                        <span class="ml-10">
                            {{ alert.rule_name }}
                        </span>
                    </td>
                    <td>
                        <tag-listing
                            :tags="$help.alerts.cortexLabelsToTags(alert.labels)"
                            small-tags
                            disable-delete-tag
                            :show-tags-count="6" />
                    </td>
                    <td>
                        <span v-if="alert.service"> {{ alert.service }} /</span>
                        {{ alert.server }}
                    </td>
                    <td>
                        <v-tooltip top>
                            <template #activator="{ on }">
                                <span
                                    class="text-body-2 grey--text text--lighten2"
                                    v-on="on">
                                    {{ $moment(alert.starts_at).format('YYYY-MM-DD HH:MM:SS UTC') }}
                                </span>
                            </template>
                            <div>
                                {{ $t('startedAt') }} : {{ $moment(alert.starts_at).format('YYYY-MM-DD HH:MM:SS UTC')
                                }}<br />
                                <span v-if="!alert.firing">
                                    {{ $t('endedAt') }} : {{ $moment(alert.ends_at).format('YYYY-MM-DD HH:MM:SS UTC') }}
                                </span>
                            </div>
                        </v-tooltip>
                    </td>
                </tr>
            </template>
        </template>
    </v-data-table>
</template>

<script>
    import ActiveLabel from 'components/common/ActiveLabel'
    export default {
        name: 'AlertsTable',
        components: { ActiveLabel, },
        props: {
            alerts: { type: Array, required: true, },
            alertsTotalCount: { type: Number, required: true, },
            loading: { type: Boolean, default: false, },
            noDataTxt: { type: String, default: '$vuetify.noDataText', },
            activeSilence: { type: Object, default: () => { }, },
        },
        data() {
            return {
                headers: [
                    { text: '', value: 'alertState', width: '100px', sortable: false, },
                    { text: this.$t('alertName'), value: 'rule_name', width: '300px', },
                    { text: this.$tc('tag', 1), value: 'tags', width: '200px', sortable: false, },
                    { text: '', value: 'server', width: '230px', },
                    { text: this.$t('timestamp'), value: 'starts_at', width: '200px', }
                ],
                options: {},
                footerOptions: {
                    'items-per-page-options': [30, 50, 100, -1],
                },
                expanded: [],
                singleExpand: false,
            }
        },
        computed: {
            tableRows: function () {
                const sorted =
                    this.$lodash.chain(this.alerts)
                        .groupBy('firing')
                        .value()

                const groupFiring = this.groupAlerts(sorted.true, true)
                const groupHistory = this.groupAlerts(sorted.false)

                return [...groupFiring, ...groupHistory]
            },
        },
        watch: {
            options: {
                handler(options) {
                    this.$emit('optionsChange', options)
                },
                deep: true,
            },
        },
        methods: {
            groupAlerts(alerts, active = false) {
                return this.$lodash.chain(alerts).groupBy('rule_name')
                    .map((value, key) => ({
                        groupName: key,
                        isActive: active,
                        groupItems: value,
                    }))
                    .value()
            },
            clicked(value) {
                const index = this.expanded.indexOf(value)
                if (index === -1) {
                    this.expanded.push(value)
                } else {
                    this.expanded.splice(index, 1)
                }
            },
        },
    }
</script>
