<template>
    <mxs-dlg
        v-bind="{ ...$attrs }"
        :title="$t('connectTo_X', [serviceName])"
        :lazy-validation="false"
        min-body-width="512px"
        :has-saving-err="conn_err_state"
        :on-save="onSave"
        v-on="$listeners"
        @is-form-valid="isFormValid = $event">
        <template #actions="{ cancel, save }">
            <v-spacer />
            <v-btn
                small
                height="36"
                color="primary"
                class="cancel font-weight-medium px-7 text-capitalize"
                rounded
                outlined
                depressed
                @click="cancel">
                {{ $t("cancel") }}
            </v-btn>
            <v-btn
                small
                height="36"
                color="primary"
                class="save font-weight-medium px-7 text-capitalize"
                rounded
                depressed
                :disabled="!isFormValid"
                @click="save">
                {{ $t("connect") }}
            </v-btn>
        </template>
        <template #form-body>
            <v-container class="pa-1">
                <v-row class="my-0 mx-n1">
                    <v-col
                        cols="12"
                        md="6"
                        class="pa-1">
                        <label
                            class="field__label mxs-color-helper text-small-text label-required">
                            {{ $t("username") }}
                        </label>
                        <v-text-field
                            id="db-user"
                            v-model.trim="body.user"
                            :rules="rules.user"
                            class="vuetify-input--override error--text__bottom user"
                            name="db-user"
                            autocomplete="new-username"
                            dense
                            :height="36"
                            hide-details="auto"
                            outlined
                            required />
                    </v-col>

                    <v-col
                        cols="12"
                        md="6"
                        class="pa-1">
                        <label
                            class="field__label mxs-color-helper text-small-text label-required">
                            {{ $t("password") }}
                        </label>
                        <v-text-field
                            id="db-password"
                            v-model.trim="body.password"
                            :rules="rules.password"
                            :type="isPasswordVisible ? 'text' : 'password'"
                            class="vuetify-input--override error--text__bottom password"
                            name="db-password"
                            autocomplete="new-password"
                            dense
                            :height="36"
                            hide-details="auto"
                            outlined
                            required>
                            <v-icon
                                slot="append"
                                size="20"
                                @click="isPasswordVisible = !isPasswordVisible">
                                {{ isPasswordVisible ? "mdi-eye-off" : "mdi-eye" }}
                            </v-icon>
                        </v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        md="6"
                        class="pa-1">
                        <label
                            class="field__label mxs-color-helper text-small-text">
                            {{ $t("database") }}
                        </label>
                        <v-text-field
                            v-model.trim="body.db"
                            class="vuetify-input--override error--text__bottom"
                            name="db"
                            dense
                            :height="36"
                            hide-details="auto"
                            outlined
                            required />
                    </v-col>
                    <v-col
                        cols="12"
                        md="6"
                        class="pa-1">
                        <label
                            class="field__label mxs-color-helper text-small-text">
                            {{ $t("timeout") }}
                        </label>
                        <v-text-field
                            v-model.number="body.timeout"
                            type="number"
                            class="vuetify-input--override error--text__bottom timeout"
                            name="timeout"
                            dense
                            :height="36"
                            hide-details="auto"
                            outlined
                            required />
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </mxs-dlg>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'OpenConnectionDialog',
        inheritAttrs: false,
        props: {
            serviceName: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                isPasswordVisible: false,
                body: {
                    user: '',
                    password: '',
                    db: '',
                    timeout: 300,
                },
                rules: {
                    user: [(val) => !!val || this.$t('errors.usernameRequired')],
                    password: [
                        (val) => !!val || this.$t('errors.passwordRequired')
                    ],
                },
                isFormValid: false,
            }
        },
        computed: {
            ...mapState({
                // eslint-disable-next-line camelcase
                conn_err_state: (state) => state.queryConn.conn_err_state,
            }),
        },
        methods: {
            async onSave() {
                this.$emit('onOpenConnection', this.body)
            },
        },
    }
</script>
