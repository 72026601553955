<template>
    <div>
        {{ $t('billings.viewBy') }}
        <div class="text-caption font-weight-normal">
            <v-radio-group
                v-model="currentUsageSort"
                class="mt-0"
                row
                mandatory
                @change="onSortChange">
                <v-radio
                    v-for="(sort, index) in usageSort"
                    :key="index"
                    :value="sort.value"
                    :label="sort.text" />
            </v-radio-group>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'BillingSortToggle',
        props: {
            defaultUsageSort: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                currentUsageSort: this.defaultUsageSort,
            }
        },
        computed: {
            usageSort() {
                return [{
                    text: this.$i18n.t('billings.byService'),
                    value: 'service',
                }, {
                    text: this.$i18n.t('billings.byResource'),
                    value: 'resource',
                }]
            },
        },
        methods: {
            onSortChange() {
                this.$emit('on-sort-toggle', this.currentUsageSort)
            },
        },
    }
</script>
