<style lang="scss" scoped>
.notice {
    background: $estimate-calculations;
}
</style>
<template>
    <div class="notice rounded">
        <slot></slot>
    </div>
</template>

<script>
    const WarningBlock = {
        name: 'WarningBlock',
    }

    export default WarningBlock
</script>
