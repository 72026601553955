<style lang="scss" scoped>
div.database-processing {
    border-radius: 8px;
    height: 7rem;
    width: 100%;

    .database-type-icon {
        height: 36px;
        transition: 0.3s all;
    }
    &.highlight-instance-row .database-type-icon,
    &:hover .database-type-icon {
        transform: scale(1.15);
    }
    .icon-text {
        max-width: 160px;
        font-size: 0.75rem;
        font-weight: 500;
        line-height: normal;
    }
}
div.oversize-icon-box {
    border: 2px solid #e8eef1;
}
</style>
<style lang="scss">
    .database-type-icon.v-icon {
        width: auto !important;
        svg {
            width: auto !important;
        }
    }
</style>

<template>
    <div
        :class="[
            'database-processing d-flex flex-column align-center justify-center text-center pointer',
            (isSelected && 'highlight-instance-row') || 'oversize-icon-box',
        ]"
        :data-auto="dataAutoPrefix('card')"
        @click="onClick">
        <div>
            <v-icon
                class="database-type-icon"
                :size="36">
                {{ `$vuetify.icons.${icon}` }}
            </v-icon>
        </div>
        <div
            class="mt-1 icon-text"
            :data-auto="dataAutoPrefix('title')">
            {{ title }}
        </div>
    </div>
</template>
<script>
    const DatabaseTypeCard = {
        name: 'database-type-card',
        props: {
            name: String,
            title: String,
            icon: String,
            isSelected: Boolean,
            parentDataAutoPrefix: Function,
        },
        methods: {
            onClick() {
                this.$emit('handleDatabaseRequirementSelection', this.name)
            },
            dataAutoPrefix(string) {
                return this.parentDataAutoPrefix(this.name + '-' + string)
            },
        },
    }

    export default DatabaseTypeCard
</script>
