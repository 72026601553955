<style lang="scss" scoped>
    @import 'styles/constants';
    .text {
       color: $accent;
    }
</style>

<template>
    <div class="unauthorized-message">
        <div class="loading-icon">
            <img
                src="@/assets/icon-globe.svg"
                alt="MariaDB" />
        </div>
        <div class="text text-center">
            {{ $t('errors.unauthorized') }}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'UnauthorizedMessage',
    }
</script>
