import to from 'await-to-js'
import { t } from 'typy'
import { createPolicies, getNodeSizesForVerticalScale, getPoliciesForService, deletePolicyForActionId } from 'services/policies'

export default {
    state: {
        scalePolicySuccess: null,
        scalePolicyError: null,
        autoVerticalSizes: [],
        verticalNodeSizeError: null,
        servicePolicies: [],
        servicePolicyErrors: null,
        deletePolicyError: null,
    },
    mutations: {
        setPolicySuccess(state, payload) {
            state.scalePolicySuccess = payload
        },
        setPolicyErrors(state, payload) {
            state.scalePolicyError = payload
        },
        setVerticalSizes(state, payload) {
            state.autoVerticalSizes = payload
        },
        setVerticalSizeErrors(state, payload) {
            state.verticalNodeSizeError = payload
        },
        setServicePolicies(state, payload) {
            state.servicePolicies = payload
        },
        setPoliciesError(state, payload) {
            state.servicePolicyErrors = payload
        },
        setPolicyDeleteError(state, payload) {
            state.deletePolicyError = payload
        },
    },
    actions: {
        async createAutoScalePolicies({ commit, } , scalingPolicies ) {
            const [error, response] = await to(createPolicies(scalingPolicies))
            if (error) {
                commit('setPolicyErrors', error)
            } else {
                commit('setPolicySuccess', response)
            }
        },
        async fetchVerticalScaleNodeSizes({ commit, } , params ) {
            const [error, response] = await to(getNodeSizesForVerticalScale(params))
            if (error) {
                commit('setVerticalSizeErrors', error)
            } else {
                commit('setVerticalSizes', response)
                commit('setVerticalSizeErrors', null)
            }
        },
        async fetchPoliciesForService({ commit, } , params) {
            const [error, response] = await to(getPoliciesForService(params))

            if (error || !t(response, 'data').isArray) {
                commit('setPoliciesError', error)
            } else {
                commit('setServicePolicies', response.data)
                commit('setPoliciesError', null)
            }
        },
        async deletePolicy({ commit, } , actionId){
            const [error, response] = await to(deletePolicyForActionId(actionId))
            if (!response) {
                commit('setPolicyDeleteError', error)
            } else {
                commit('setPolicyDeleteError', null)
            }
        },
    },
    getters: {
        scalePolicySuccess: state => state.scalePolicySuccess,
        scalePolicyError: state => state.scalePolicyError,
        autoVerticalSizes: state => state.autoVerticalSizes,
        verticalNodeSizeError: state => state.verticalNodeSizeError,
        servicePolicies: state => state.servicePolicies,
        servicePolicyErrors: state => state.servicePolicyErrors,
        deletePolicyError: state => state.deletePolicyError,
    },
}
