import * as monitoringMetrics from 'data/monitoring/metrics/index.js'
import { queryCortex, queryCortexRange, mapServiceToCortexRegion, queryObservability } from 'services/observability.js'
import { CONFIG } from 'config/'
import { parseRelativeTime } from 'helpers/'
import store from 'store/index'

function mapVector(data, options = {}) {
    return data.map(({ metric, value, }) => ({
        metric,
        value,
        label: getMetricLabel(metric, options.legend),
    }))
}

function mapMatrix(data, options = {}) {
    return data.map(({ metric, values, }) => ({
        metric,
        values,
        label: getMetricLabel(metric, options.legend),
    }))
}

function getMetricLabel(metric = {}, label = '') {
    // Parse double braces {{ }}
    const template = label.match(/\{\{(.*?)\}\}/)
    if (!template) return label
    const values = template[1].split('||').map(value => value.trim())
    for (let i = 0; i < values.length; i++) {
        if (metric[values[i]]) return template.input.replace(template[0], metric[values[i]])
    }
    // Return the last value if there is no label present for the metric
    return template.input.replace(template[0], values.slice(-1))
}

function getMetricExpression(metricName) {
    const metricExpression = monitoringMetrics[metricName]
    if (!metricExpression) throw new Error('code:1')

    return metricExpression
}

function getMapFunction(resultType) {
    // TODO: Extend
    return resultType === 'vector' ? mapVector : mapMatrix
}

function mapCortexResponse(data, options) {
    const mapData = getMapFunction(data.resultType)
    data.result = mapData(data.result, options)
    return data
}

export function parseTimeRange({ start, end, } = {}) {
    return [
        parseRelativeTime(start),
        parseRelativeTime(end)
    ]
}

function calcDataStep(time, dataPoints) {
    dataPoints = dataPoints || CONFIG.DEFAULT_DATA_POINTS
    return Math.floor((time[1] - time[0]) / 1000 / dataPoints)
}

export async function getMetricsData(metrics = [], options = {}, time = {}, pid = '') {
    const metricsPromises = []
    const timeRange = parseTimeRange(time)

    metrics.forEach(({ name, dataPoints, timeDuration, legend, formatters, instant, isOutsideCortex, ...params }) => {
        const dataQuery = getMetricExpression(name)
        const metricOptions = {
            ...options,
            timeDuration,
            ...params,
        }
        const cortexRegion = mapServiceToCortexRegion(store.getters.regions, options.region)
        const mapOptions = { legend, formatters, }
        let queryPromise

        if (isOutsideCortex)
            queryPromise = queryObservability(name, dataQuery({
                fromDate: new Date(timeRange[0]),
                toDate: new Date(timeRange[1]),
                serverContext: options.servers.map(item => item.dataSourceId),
            }))
        else {
            if (instant) {
                queryPromise = queryCortex(
                    dataQuery(metricOptions),
                    cortexRegion,
                    `${pid}-${name}`
                )
            } else {
                queryPromise = queryCortexRange(
                    dataQuery(metricOptions),
                    cortexRegion,
                    timeRange,
                    calcDataStep(timeRange, dataPoints),
                    `${pid}-${name}`
                )
            }
            queryPromise.then(res => mapCortexResponse(res, mapOptions))
        }

        metricsPromises.push(queryPromise)
    })

    return Promise.all(metricsPromises)
}
