<style lang="scss" scoped>
    .pricing-info {
        height: 56px;
        border: 1px solid $azure;
        border-right: none;
        background: #E2F3F7;
    }
    .pricing-tile {
        position: relative;
        border-right: 1px solid $azure;
        height: 100%;
        line-height: normal;
        vertical-align: top;
        &:nth-child(1) {
            width: 23%;
        }
        &:nth-child(2) {
            width: 57%;
        }
        &:nth-child(3) {
            width: 20%;
            border-right: none;
        }
    }
    .pricing-quota {
        position: absolute;
        bottom: -32px;
        left: 0;
    }
</style>

<template>
    <div>
        <base-dialog
            v-model="isModalOpen"
            :min-width="800"
            :max-width="800"
            :on-close="closeModal"
            :is-action-separator-required="false">
            <template #title>
                <p class="font-weight-regular text-h4 ma-0">
                    {{ $t('ros.subscribeToService') }}
                </p>
            </template>
            <template #body>
                <p class="my-4 text-body-2">
                    {{ $t('ros.pricingDetails') }}
                </p>
                <div class="mb-8 pricing-info text-subtitle-1 color text-blue-azure">
                    <div
                        v-for="(price, i) in $config.OBSERVABILITY_PRICING"
                        :key="i"
                        class="pricing-tile d-inline-block pa-2">
                        {{ $t(price.label1, [price.perMonth]) }}<br />
                        {{ $t(price.label2) }}
                        <div class="pricing-quota text-h6">
                            {{ price.db }} <span
                                v-if="i === 0"
                                class="text-subtitle-1">{{ $tc('database', 2) }}</span>
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <v-btn
                        class="mt-6"
                        color="primary"
                        depressed
                        rounded
                        :loading="isLoading"
                        @click="openPaymentDialog">
                        <span class="text-none">{{ $t('ros.addCreditCard') }}</span>
                    </v-btn>
                </div>
                <p class="mt-2 mb-0 text-center text-body-2">
                    {{ $t('ros.billingDetails') }}
                </p>
            </template>
            <template #actions>
                <div></div>
            </template>
        </base-dialog>

        <div v-if="showPaymentDialog">
            <PaymentModal
                product="monitor"
                :payment-profile="paymentProfile"
                :legal-entity-identifier="legalEntityIdentifier"
                @on-update-profile="handleProfileUpdate"
                @on-payment-close="handleProfileClose" />
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import PaymentModal from 'components/billing/Payment.vue'

    export default {
        name: 'RosPricingModal',
        components: {
            PaymentModal,
        },
        props: {
            openDialog: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                isModalOpen: this.openDialog,
                showPaymentDialog: false,
                isLoading: false,
            }
        },
        computed: {
            ...mapGetters(['billingRecord', 'paymentProfile', 'monitoringSubscription']),
            legalEntityIdentifier() {
                // TODO: Move into component
                if (!this.billingRecord) return ''
                return this.billingRecord.legalEntityIdentifier || ''
            },
        },
        watch: {
            openDialog(val) {
                this.isModalOpen = val
            },
        },
        methods: {
            ...mapActions(['fetchPaymentProfile', 'fetchCountries', 'togglePaymentDialog', 'fetchBillingRecord']),
            closeModal() {
                this.$emit('close')
            },
            async openPaymentDialog() {
                this.isLoading = true
                if (this.$lodash.isEmpty(this.paymentProfile)) {
                    await this.fetchPaymentProfile()
                }
                this.showPaymentDialog = true
                if (this.$lodash.isEmpty(this.billingCountries)) {
                    await this.fetchCountries()
                } else {
                    // Minor delay is required to open the
                    // payment dialog for cached data
                    await this.$nextTick()
                }
                this.togglePaymentDialog(true)
                this.isLoading = false
            },
            async handleProfileUpdate() {
                this.togglePaymentDialog(false)
                this.showPaymentDialog = false

                // Check subscription
                this.isLoading = true
                // Set the request with no cache otherwise we dont get the latest state
                await this.fetchBillingRecord(true)
                this.isLoading = false

                if (this.monitoringSubscription) {
                    this.$router.push({ name: 'rosOnboarding', })
                    this.closeModal()
                } else {
                    this.$store.commit('showMessage', {
                        text: this.$i18n.t('errors.unknown'),
                        type: this.$config.messageType.ERROR,
                    })
                }
            },
            handleProfileClose() {
                this.togglePaymentDialog(false)
                this.showPaymentDialog = false
            },
        },
    }
</script>
