export default Object.freeze({
    CORTEX_REGIONS: {
        'Americas': 'us-central1',
        'EMEA': 'europe-west1',
        'APAC': 'asia-southeast1',
    },
    DEFAULT_DATA_POINTS: 60,
    DASHBOARD_MIN_GRID_SIZE_Y: 24,
    DASHBOARD_TIME_RANGES: {
        minutes: [5, 15, 30],
        hours: [1, 3, 6, 12, 24],
        days: [2, 7, 30],
    },
    DASHBOARD_DEFAULT_TIME_RANGE: ['now-30 minutes', 'now'],
    OBSERVABILITY_PRICING: [
        {
            label1: 'ros.pricePerMonth',
            label2: 'ros.perDb',
            perMonth: '$49',
            db: '1-4',
        },
        {
            label1: 'ros.pricePerMonth',
            label2: 'ros.perDb',
            perMonth: '$45',
            db: '5-19',
        },
        {
            label1: 'ros.bulkPricing',
            label2: 'ros.contactUs',
            perMonth: '',
            db: '20+',
        }
    ],
    AGENT_BUNDLE_URL: 'https://dlm.mariadb.com/2912765/monitoring/agent-manager/v1.3.0/mariadb-agent-manager-for-pdc-v1.3.0.linux-amd64.tar.gz',
    ROS_AGENT_MANAGER_DOCS: 'https://r.mariadb.com/AgentManager',
})
