<template>
    <v-container
        class="ma-0 pa-12"
        fluid>
        <v-col cols="auto pa-0 wrapper-container">
            <SettingHeader :header-text=" $t('notificationChannels') " />

            <notification-channels-table
                :loading="channelsLoading"
                :channels="channels"
                @channel-delete="handleDeleteChannel" />
        </v-col>
    </v-container>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'
    import NotificationChannelsTable from 'components/alerts/NotificationChannelsTable'
    import SettingHeader from './SettingHeader'

    export default {
        name: 'Channels',
        components: {
            'notification-channels-table': NotificationChannelsTable,
            SettingHeader,
        },
        computed: {
            ...mapState(['config']),
            ...mapGetters(['channels', 'channelsLoading']),
        },
        methods: {
            async handleDeleteChannel (channels) {
                await this.$store.dispatch('deleteChannels', channels)
            },
        },
        beforeCreate () {
            this.$store.dispatch('fetchChannels')
        },
    }
</script>
