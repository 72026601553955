<style lang="scss" scoped>
.v-input {
    height: 20px;
}
</style>
<template>
    <v-row :class="[{ 'pb-1': !showDismiss }]">
        <v-col class="pb-3">
            <v-text-field
                v-model="projectId"
                :placeholder="$t('privateConnect.addProjectId', { type})"
                outlined
                dense
                height="10px"
                class="shrink input-class"
                @keyup="onProjectIdInput" />
        </v-col>
        <v-col class="pb-3">
            <v-text-field
                v-model="projectAlias"
                :placeholder="$t('privateConnect.projectAlias')"
                outlined
                height="10px"
                class="shrink input-class"
                dense
                @keyup="onProjectAliasInput" />
        </v-col>
        <v-col
            class="pb-0"
            cols="4">
            <v-btn
                v-if="showDismiss"
                icon
                @click="onRemoveField">
                <v-icon size="15">
                    $vuetify.icons.remove
                </v-icon>
            </v-btn>
        </v-col>
    </v-row>
</template>
<script>
    export default {
        name: 'PrivateConnectInputs',
        props: {
            index: {
                type: Number,
                default: 0,
            },
            showDismiss: {
                type: Boolean,
                default: true,
            },
            type: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                projectId: '',
                projectAlias: '',
            }
        },
        methods: {
            onProjectIdInput() {
                this.$emit('on-project-input', this.projectId, this.index)
            },
            onProjectAliasInput() {
                this.$emit('on-alias-input', this.projectAlias, this.index)
            },
            onRemoveField() {
                this.$emit('on-remove-project', this.projectId)
            },
        },
    }
</script>
