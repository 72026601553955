<style lang="scss" scoped>
.v-time-picker-custom .v-picker__title {
    height: 84px;
    padding-top: 10px;
}
</style>
<template>
    <v-dialog
        v-model="display"
        :width="dialogWidth">
        <template #activator="{ on }">
            <v-row>
                {{ label }}
            </v-row>
            <v-row>
                <v-text-field
                    :disabled="disabled"
                    :loading="loading"
                    :value="formattedDatetime"
                    class="py-4 pr-6"
                    prepend-inner-icon="$vuetify.icons.calendar"
                    readonly
                    outlined
                    hide-details
                    height="32"
                    dense
                    single-line
                    v-on="on">
                    <template #progress>
                        <slot name="progress">
                            <v-progress-linear
                                color="primary"
                                indeterminate
                                absolute
                                height="2" />
                        </slot>
                    </template>
                </v-text-field>
            </v-row>
        </template>

        <v-card>
            <v-card-text class="pa-0">
                <v-tabs
                    v-model="activeTab"
                    fixed-tabs>
                    <v-tab
                        id="date-tab"
                        key="calendar">
                        <slot name="dateIcon">
                            <v-icon>$vuetify.icons.calendar</v-icon>
                        </slot>
                    </v-tab>
                    <v-tab
                        id="time-tab"
                        key="timer"
                        :disabled="dateSelected">
                        <slot name="timeIcon">
                            <v-icon size="28">
                                mdi-clock-outline
                            </v-icon>
                        </slot>
                    </v-tab>
                    <v-tab-item key="calendar">
                        <v-date-picker
                            v-model="date"
                            full-width
                            :allowed-dates="allowedDates"
                            @input="showTimePicker" />
                    </v-tab-item>
                    <v-tab-item key="timer">
                        <v-time-picker
                            ref="timer"
                            v-model="time"
                            class="v-time-picker-custom"
                            :allowed-hours="allowedHours"
                            :allowed-minutes="allowedMinutes"
                            format="24hr"
                            full-width />
                    </v-tab-item>
                </v-tabs>
            </v-card-text>
            <v-card-actions class="pb-5">
                <v-spacer />
                <slot
                    name="actions"
                    :parent="this">
                    <v-btn
                        color="primary"
                        depressed
                        rounded
                        outlined
                        class="cancel px-4"
                        @click.native="clearHandler">
                        {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                        color="primary px-4"
                        depressed
                        rounded
                        @click="okHandler">
                        {{ $t('ok') }}
                    </v-btn>
                </slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    const DEFAULT_DATE = ''
    const DEFAULT_TIME = '00:00:00'
    const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY'
    const DEFAULT_TIME_FORMAT = 'HH:mm:ss'
    const DEFAULT_DIALOG_WIDTH = 350
    const ISO_DATE_FORMAT = 'YYYY-MM-DD'

    export default {
        name: 'DatetimePicker',
        props: {
            datetime: {
                type: [Date, String, Object],
                default: () => new Date(),
            },
            disabled: {
                type: Boolean,
            },
            loading: {
                type: Boolean,
            },
            label: {
                type: String,
                default: '',
            },
            dialogWidth: {
                type: Number,
                default: DEFAULT_DIALOG_WIDTH,
            },
            dateFormat: {
                type: String,
                default: DEFAULT_DATE_FORMAT,
            },
            timeFormat: {
                type: String,
                default: 'HH:mm:ss',
            },
            baseDateTime: {
                type: [Date, String, Object],
                default: () => '',
            },
        },
        data() {
            return {
                display: false,
                activeTab: 0,
                date: DEFAULT_DATE,
                time: DEFAULT_TIME,
            }
        },
        computed: {
            dateTimeFormat() {
                return this.timeFormat + ' ' + this.dateFormat
            },
            formattedDatetime() {
                return this.selectedDatetime ? this.$moment(this.selectedDatetime).format(this.dateTimeFormat) : ''
            },
            selectedDatetime() {
                if (this.date && this.time) {
                    let datetimeString = this.date + ' ' + this.time
                    if (this.time.length === 5) {
                        datetimeString += ':00'
                    }
                    return datetimeString
                } else {
                    return ''
                }
            },
            dateSelected() {
                return !this.date
            },
        },
        watch: {
            datetime: function () {
                this.init()
            },
        },
        methods: {
            init() {
                if (!this.datetime) {
                    this.date = DEFAULT_DATE
                    this.time = DEFAULT_TIME
                    return
                }

                let initDateTime
                if (this.datetime instanceof Date) {
                    initDateTime = this.datetime
                } else if (typeof this.datetime === 'string' || this.datetime instanceof String || this.datetime instanceof Object) {
                    initDateTime = this.$moment(this.datetime)
                }
                // Date-picker accepts only ISO 8601 date format
                // More info: https://v2.vuetifyjs.com/en/components/date-pickers/#caveats
                this.date = this.$moment(initDateTime).format(ISO_DATE_FORMAT)
                this.time = this.$moment(initDateTime).format(DEFAULT_TIME_FORMAT)
            },
            okHandler() {
                this.resetPicker()
                this.$emit('input', this.selectedDatetime)
            },
            clearHandler() {
                this.resetPicker()
                this.date = DEFAULT_DATE
                this.time = DEFAULT_TIME
                this.$emit('input', null)
            },
            resetPicker() {
                this.display = false
                this.activeTab = 0
                if (this.$refs.timer) {
                    this.$refs.timer.selectingHour = true
                }
            },
            showTimePicker() {
                this.activeTab = 1
            },
            doesSelectedDateMatch(date) {
                return this.$moment(this.selectedDatetime).format(DEFAULT_DATE_FORMAT) == this.$moment(date).format(DEFAULT_DATE_FORMAT)
            },
            allowedDates(val) {
                let currentDate = this.$moment().format('YYYYMMDD')
                if (this.baseDateTime) {
                    currentDate = this.$moment(this.baseDateTime).format('YYYYMMDD')
                }
                const selectedDate = this.$moment(val).format('YYYYMMDD')
                return selectedDate - currentDate >= 0
            },
            isHourValueAllowed(date, hours) {
                return hours - this.$moment(date).format('HH') >= 0
            },
            allowedHours(hours) {
                const today = new Date()
                if (this.baseDateTime && this.doesSelectedDateMatch(this.baseDateTime)) {
                    return this.isHourValueAllowed(this.baseDateTime, hours)
                }
                if (this.doesSelectedDateMatch(today)) {
                    return this.isHourValueAllowed(today, hours)
                }
                return true
            },
            isMinsValueAllowed(date, mins) {
                const currentHours = this.$moment(date).format('HH')
                const selectedHours = this.$moment(this.selectedDatetime).format('HH')
                if (!(currentHours - selectedHours)) {
                    const currentMins = this.$moment(date).format('mm')
                    return (mins - Number(currentMins)) >= 0
                }
                return true
            },
            allowedMinutes(mins) {
                const today = new Date()
                if (this.baseDateTime && this.doesSelectedDateMatch(this.baseDateTime)) {
                    return this.isMinsValueAllowed(this.baseDateTime, mins)
                }
                if (this.doesSelectedDateMatch(today)) {
                    return this.isMinsValueAllowed(today, mins)
                }
                return true
            },
        },
        mounted() {
            this.init()
        },
    }
</script>
