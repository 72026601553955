/* eslint-disable camelcase */
import BILLING_CONFIG from 'config/billing'
import i18n from 'plugins/i18n'

const INFRA_PREFIX = 'Multi-Node Infrastructure '

export function mapBillingUsageEntity(usageObject) {
    const { category, cost, durationMin, price, } = usageObject

    return {
        cost: parseFloat(cost),
        runtime: parseFloat(durationMin),
        perMinCost: price,
        type: getEntityType(category),
    }
}

export function mapBillingUsageEntityByLineItem(usageObject) {
    return {
        ...mapBillingUsageEntity(usageObject),
        title: getTitleForEntity(usageObject),
    }
}

export function mapBillingUsageEntityByServiceName(usageObject) {
    return {
        ...mapBillingUsageEntity(usageObject),
        title: usageObject.configurationItem,
    }
}

export function getTitleForEntity({category, component, quantity,}) {
    switch (category) {
    case BILLING_CONFIG.NODE:
        return component
    case BILLING_CONFIG.IOPS:
        return `${quantity} ${i18n.t('billings.iops')}`
    case BILLING_CONFIG.SCALE_FABRIC:
        return component.split(INFRA_PREFIX)[1]
    case BILLING_CONFIG.STORAGE:
    case BILLING_CONFIG.THROUGHPUT:
        return quantity
    case BILLING_CONFIG.NETWORK:
        return i18n.t('billings.secondaryEndpoint')
    }
}

export function getEntityType(category) {
    switch (category) {
    case BILLING_CONFIG.NODE:
        return i18n.t('billings.nodes')
    case BILLING_CONFIG.IOPS:
        return i18n.t('launchService.provisionedIOPs')
    case BILLING_CONFIG.SCALE_FABRIC:
        return i18n.t('billings.scaleFabric')
    case BILLING_CONFIG.STORAGE:
        return i18n.t('billings.storage')
    }
}
