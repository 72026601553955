<style lang="scss" scoped>
.empty-list {
    border: 1px solid $cornsilk;
    border-radius: 6px;
}

.project-input-container {
    min-height: 50px;
    max-height: 200px;
    overflow: auto;
    overflow-x: hidden;
}
</style>
<template>
    <div>
        <base-dialog
            v-model="isPrivateConnectModalOpen"
            :min-width="900"
            :max-width="900"
            :on-close="resetProjectList"
            :on-cancel="resetProjectList"
            :on-save="updatePrivateConnect">
            <template #title>
                <p class="font-weight-regular text-h4 ma-0">
                    {{ title }}
                </p>
            </template>
            <template #body>
                <div class="pt-6">
                    {{
                        $t('privateConnect.enterProjectIdText', {
                            cloudProvider: service.provider.toUpperCase(),
                            connectionName: privateConnectType,
                            entityType
                        })
                    }}
                </div>
                <div class="project-input-container mt-4 mb-8 pb-12">
                    <PrivateConnectInputs
                        v-for="(project, index) in projectList"
                        :key="project.key"
                        :index="index"
                        :show-dismiss="index !== 0"
                        :type="entityType"
                        @on-project-input="onProjectIdInput"
                        @on-alias-input="onProjectAliasInput"
                        @on-remove-project="removeProject" />
                </div>
                <div class="mb-4 ml-3">
                    <v-row v-if="hasExistingProjects">
                        <v-col>
                            <PrivateConnectProjectList
                                :project-list="existingProjects"
                                :private-connect-type="privateConnectType"
                                :provider="service.provider"
                                @on-remove-project="deleteExistingProject"
                                @on-disconnect-project-link="disconnectProjectLink"
                                @on-update-list="updateExistingProjectList" />
                        </v-col>
                    </v-row>
                    <v-row v-else>
                        <v-col class="empty-list mr-4 text-center">
                            {{ $t('privateConnect.emptyProjectList', { type: entityType }) }}
                        </v-col>
                        <v-col cols="4" />
                    </v-row>
                </div>
                <div class="pt-10 pl-4">
                    <v-row>
                        <v-col
                            class="pa-0"
                            cols="9">
                            <i18n
                                path="privateConnect.guidesAfterProjectAddition"
                                tag="p">
                                <span
                                    class="font-weight-medium"
                                    place="important">{{ $t('privateConnect.important')
                                    }}</span>
                                <span place="connectionName">
                                    {{ privateConnectType }}
                                </span>
                                <span place="type">
                                    {{ entityType }}
                                </span>
                                <span
                                    place="cloud"
                                    class="text-uppercase">
                                    {{ service.provider }}
                                </span>
                                <a
                                    place="followInstructions"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    :href="instructionsLink">{{ $t('privateConnect.followInstructions') }}</a>
                            </i18n>
                        </v-col>
                    </v-row>
                </div>
                <RemovePrivateConnectModal
                    :type="entityType"
                    :open-dialog="showRemoveProject"
                    :project-id="currentProjectId"
                    :connection-name="privateConnectType"
                    @on-close-modal="onCloseRemoveProject"
                    @on-confirm-project-remove="onConfirmRemoveProject" />

                <DisconnectPrivateConnectModal
                    :open-dialog="showDisconnectProjectLink"
                    :connection-name="privateConnectType"
                    @on-close-modal="onCloseDisconnectProjectLink"
                    @on-confirm-disconnect="onConfirmDisconnectionOfPrivateConnect" />
            </template>
            <template #actions="{ cancel, save }">
                <v-spacer />
                <v-btn
                    color="primary"
                    depressed
                    rounded
                    outlined
                    class="cancel"
                    @click="cancel">
                    <span class="text-none">{{ $t('cancel') }} </span>
                </v-btn>
                <v-btn
                    color="primary"
                    depressed
                    rounded
                    :disabled="disableSave"
                    @click="save">
                    <span class="text-none text-uppercase"> {{ $t("ok") }}</span>
                </v-btn>
            </template>
        </base-dialog>
    </div>
</template>
<script>
    import { doPrivateConnectHaveProjects, getPrivateConnectInstructionLink, getPrivateConnectProjects, privateConnectEntity, privateConnectLabel } from 'utils/service'
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
    import PrivateConnectInputs from 'components/common/PrivateConnectInputs.vue'
    import PrivateConnectProjectList from 'components/common/PrivateConnectProjectList.vue'
    import { range } from 'helpers'
    import RemovePrivateConnectModal from 'components/common/RemovePrivateConnectModal.vue'
    import DisconnectPrivateConnectModal from 'components/common/DisconnectPrivateConnectModal.vue'
    export default {
        name: 'PrivateConnectModal',
        components: { PrivateConnectInputs, PrivateConnectProjectList, RemovePrivateConnectModal, DisconnectPrivateConnectModal, },
        props: {
            openDialog: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                projectList: [this.getEmptyProject(false)],
                showRemoveProject: false,
                showDisconnectProjectLink: false,
                currentProjectId: '',
                updatedExistingProjectList: [],
            }
        },
        computed: {
            ...mapState(['manageServices']),
            ...mapGetters(['getServiceById', 'isPrivateConnectOpen', 'removeProjectError', 'disconnectProjectError']),
            service() {
                return this.getServiceById(this.manageServices.serviceId)
            },
            title() {
                const connectionName = this.privateConnectType
                return this.hasExistingProjects
                    ? this.$i18n.t('privateConnect.manageModalTitle', { connectionName, })
                    : this.$i18n.t('privateConnect.setupModalTitle', { connectionName, })
            },
            isPrivateConnectModalOpen: {
                get() {
                    return this.manageServices.isPrivateConnectOpen
                },
                set(value) {
                    this.setPrivateConnectDialog(value)
                },
            },
            privateConnectType() {
                return privateConnectLabel(this.service.provider)
            },
            hasExistingProjects() {
                return doPrivateConnectHaveProjects(this.service.endpoints)
            },
            existingProjects() {
                if (this.hasExistingProjects) {
                    return getPrivateConnectProjects(
                        this.service.endpoints,
                        this.service.provider
                    )
                }
                return []
            },
            instructionsLink() {
                return getPrivateConnectInstructionLink(this.service.provider)
            },
            disableSave() {
                const hasProjectIds = this.projectList.some(project => project.id.trim() !== '')
                // Other cases when existing project ids are changed
                if (hasProjectIds || this.updatedExistingProjectList.length > 0) {
                    return false
                }
                return true
            },
            entityType() {
                return privateConnectEntity(this.service.provider)
            },
        },
        methods: {
            ...mapMutations(['setPrivateConnectDialog']),
            ...mapActions(['setPrivateConnect', 'removePrivateConnect', 'disconnectPrivateConnectService']),
            async updatePrivateConnect() {
                let projects = this.projectList
                    .filter(project => project.id !== '')
                    .map(({ id, alias, }) => ({ id, alias, }))

                if (this.hasExistingProjects) {
                    projects = [...(this.updatedExistingProjectList.length ? this.updatedExistingProjectList : this.existingProjects), ...projects]
                }

                await this.setPrivateConnect({ serviceId: this.manageServices.serviceId, projects, provider: this.service.provider, })
                this.resetProjectList()
            },
            getEmptyProject(isDismissible = true) {
                return {
                    id: '',
                    alias: '',
                    dismissible: isDismissible,
                    // unique key ensures removal of proper project
                    // during remove project event
                    key: `project-${range(0, 99999999999)}`,
                }
            },
            onProjectIdInput(id, index) {
                this.projectList[index].id = id
                // don't create a new input if previous is empty
                if (id.trim() === '') return

                if (this.projectList.length === index + 1) {
                    this.projectList.push(
                        this.getEmptyProject()
                    )
                }
            },
            onProjectAliasInput(alias, index) {
                this.projectList[index].alias = alias
            },
            resetProjectList() {
                this.projectList = [this.getEmptyProject(false)]
                this.updatedExistingProjectList = []
            },
            removeProject(projectId) {
                const removeIndex = this.projectList.findIndex(
                    (project) => project.id === projectId
                )
                if (removeIndex !== -1) {
                    this.projectList.splice(removeIndex, 1)
                }
            },
            deleteExistingProject(id) {
                this.showRemoveProject = true
                this.currentProjectId = id
            },
            disconnectProjectLink() {
                this.showDisconnectProjectLink = true
            },
            onCloseRemoveProject() {
                this.showRemoveProject = false
                this.currentProjectId = ''
                this.resetProjectList()
            },
            onCloseDisconnectProjectLink() {
                this.showDisconnectProjectLink = false
                this.resetProjectList()
            },
            async onConfirmRemoveProject(projectId) {
                // Call API to remove the Project
                await this.removePrivateConnect({
                    serviceId: this.manageServices.serviceId,
                    existingProjects: this.existingProjects,
                    provider: this.service.provider,
                    removeProjectId: projectId,
                })
                //empty the project Id post removal
                this.onCloseRemoveProject()
                this.isPrivateConnectModalOpen = false
            },
            async onConfirmDisconnectionOfPrivateConnect() {
                await this.disconnectPrivateConnectService({ serviceId: this.manageServices.serviceId, })
                if (!this.disconnectProjectError) this.onCloseDisconnectProjectLink()
                this.isPrivateConnectModalOpen = false
            },
            updateExistingProjectList(projectList) {
                this.updatedExistingProjectList = projectList
            },
        },
    }
</script>
