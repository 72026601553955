<template>
    <v-tooltip top>
        <template #activator="{ on }">
            <v-icon
                size="18"
                class="ml-2"
                color="orange"
                v-on="on">
                $vuetify.icons.statusWarning
            </v-icon>
        </template>
        <span>{{ $t('configManager.changingRequiresRestart') }}</span>
    </v-tooltip>
</template>
<script>
    export default {
        name: 'RestartTooltip',
    }
</script>
