import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { Resize } from 'vuetify/lib/directives'
import MxsQueryEditor from 'mxs-query-editor'

Vue.use(Vuetify, { directives: { Resize, }, })
/* store and i18n must be valid and defined.
 * e.g. Vue.use(Vuex) is called before importing store.
 */
Vue.use(MxsQueryEditor, {
    store: require('store/index').default,
    i18n: require('plugins/i18n').default,
    // eslint-disable-next-line camelcase
    hidden_comp: ['wke-nav-ctr'],  // a list of component name to be hidden
})
