<style lang="scss" scoped>
    path {
        fill: currentColor;
    }
</style>

<template>
    <svg
        viewBox="0 0 10 11"
        style="fill:none">
        <path
            d="M 4.6855469 0 L 3.3671875 0.78320312 L 2.0507812 1.5722656 L 3.3671875 2.3613281 L 4.6855469 3.1445312 L 4.6875 2.0742188
            A 0.50019257 0.50019257 0 0 0 4.7226562 2.0722656 C 6.3799385 1.957959 7.9312372 2.8675976 8.6289062 4.3613281 C 9.3265754
            5.8550587 9.0258128 7.6154249 7.8691406 8.796875 C 6.7124684 9.9783251 4.9433664 10.32973 3.4179688 9.6777344 C 1.8925709
            9.0257385 0.93539777 7.5116753 1.0039062 5.8671875 A 0.50042037 0.50042037 0 1 0 0.00390625 5.8261719 C -0.08183959 7.8844263
            1.1235245 9.7847479 3.0253906 10.597656 C 4.9272567 11.410565 7.1395049 10.973469 8.5839844 9.4980469 C 10.028464 8.0226243
            10.407657 5.8075031 9.5351562 3.9394531 C 8.6673731 2.0815034 6.7398955 0.94634044 4.6875 1.0742188 L 4.6855469 0 z " />
    </svg>
</template>

<script>
    export default {}
</script>
