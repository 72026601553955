import Workspace from 'pages/Workspace'
import SkyQuery from 'pages/workspace/SkyQuery'

export default [
    {
        path: '/workspace',
        name: 'workspace',
        component: Workspace,
        meta: {
            requiresAuth: true,
            title: 'Workspace',
        },
    },
    {
        path: '/workspace/query-editor',
        name: 'Query Editor',
        component: SkyQuery,
        meta: {
            requiresAuth: true,
            title: 'Query Editor',
        },
    },
    {
        path: '/workspace/sky-book',
        name: 'Notebook',
        // component: SkyBook,
        meta: { requiresAuth: true, title: 'Notebook', },
    }
]
