<style lang="scss">
    .base-dialog {
        .close {
            position: absolute;
            top: 15px;
            right: 36px;
        }
        .v-btn--depressed {
            min-width: 28% !important;
        }
        .v-expansion-panel {
            .v-expansion-panel-header {
                font-weight: bold;
                text-transform: uppercase;
                font-size: 13px;
                padding-bottom: 8px;
                min-height: 0px;
            }
            .v-expansion-panel-content {
                .v-expansion-panel-content__wrap {
                    padding-bottom: 0px;
                }
            }
            &::before {
                content: none;
            }
            td {
                .v-input.std {
                    height: 36px;
                    width: 230px;
                }
            }
            .v-input.std.v-text-field {
                .v-select__slot,
                input {
                    height: 36px;
                    font-size: 14px;
                }
                fieldset {
                    legend {
                        width: 0 !important;
                    }
                }
                .v-label {
                    top: 7px;
                    &.v-label--active {
                        left: -12px !important;
                        top: 5px;
                    }
                    &.error--text {
                        display: none;
                    }
                }
            }
        }
    }
</style>
<template>
    <v-dialog
        v-model="isOpen"
        scrollable
        :max-width="maxWidth"
        :min-width="minWidth"
        persistent
        content-class="base-dialog">
        <v-form
            ref="form"
            v-model="isFormValid">
            <v-card v-if="isOpen">
                <v-card-title class="pa-12 pb-0 d-block">
                    <slot name="title">
                        <p class="px-3 ma-0 text-h4">
                            {{ title }}
                        </p>
                    </slot>
                    <span
                        v-if="subTitle"
                        class="px-3 text-uppercase text-subtle text-caption">{{ subTitle }}</span>
                    <v-btn
                        class="close"
                        tabindex="-1"
                        large
                        icon
                        @click="close">
                        <v-icon large>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text
                    class="px-12 py-0 pb-12"
                    :style="cardTextStyle">
                    <v-container class="pa-0">
                        <v-row>
                            <v-col cols="12">
                                <slot name="body"></slot>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider v-if="isActionSeparatorRequired" />
                <v-card-actions class="d-flex justify-center px-12 py-6">
                    <span class="text-caption red--text"> {{ savingErrorMessage }} </span>
                    <slot
                        name="actions"
                        :cancel="cancel"
                        :save="save">
                        <v-btn
                            class="cancel"
                            color="primary"
                            small
                            text
                            rounded
                            @click="cancel">
                            <span class="text-none">{{ $t('cancel') }}</span>
                        </v-btn>
                        <v-btn
                            color="primary"
                            depressed
                            :disabled="!hasChanged"
                            rounded>
                            <span class="text-none">{{ $t('save') }}</span>
                        </v-btn>
                    </slot>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>
<script>
    import { mapMutations } from 'vuex'
    import { OVERLAY_TRANSPARENT_LOADING } from 'store/overlayTypes'
    export default {
        name: 'BaseDialog',
        props: {
            title: { type: String, default: '', },
            subTitle: { type: String, default: '', },
            onClose: {
                type: Function,
                default: () => {},
            },
            onCancel: {
                type: Function,
                default: () => {},
            },
            onSave: {
                type: Function,
                default: () => {},
            },
            value: Boolean,
            maxWidth: { type: [String, Number], default: 550, },
            minWidth: { type: [String, Number], default: 550, },
            hasChanged: { type: Boolean, default: true, },
            hasSavingError: { type: Boolean, default: false, },
            savingErrorMessage: { type: String, default: '', },
            isActionSeparatorRequired: {
                type: Boolean,
                default: true,
            },
            cardTextStyle: {
                type: Object,
                default: () => {},
            },
        },
        data () {
            return {
                isOpen: false,
                isFormValid: true,
            }
        },
        watch: {
            value: function (val) {
                this.isOpen = val
                if (val) this.$nextTick(() => this.$refs.form.resetValidation())
            },
            isFormValid: function (v) {
                this.$emit('is-form-valid', v)
            },
        },
        methods: {
            ...mapMutations(['showMessage', 'showOverlay', 'hideOverlay']),
            close () {
                this.onClose()
                this.$emit('input', false)
            },
            cancel () {
                this.onCancel()
                this.$emit('input', false)
            },
            async save () {
                if (!this.$refs.form.validate()) return
                this.showOverlay(OVERLAY_TRANSPARENT_LOADING)
                await this.onSave()
                this.hideOverlay()
                if (!this.hasSavingError) this.$emit('input', false)
            },
        },
    }
</script>
