<style lang="scss" scoped>
    path {
        fill: currentColor;
    }
</style>

<template>
    <svg
        viewBox="0 0 22 22"
        fill="none">
        <path
            d="M 11.271914,0.00361896 C 10.464713,-0.01701724 9.6543036,0.05047546 8.8541906,0.20963681 6.7205223,0.63407934 4.7601765,
            1.6822938 3.2218897,3.2206688 1.6836144,4.7590558 0.63546821,6.719529 0.2110533,8.853336 c -0.42440156,2.133849 -0.20543887,
            4.344855 0.62706901,6.354861 0.83252029,2.010009 2.24108219,3.728944 4.04991409,4.93764 1.8088319,1.208697 3.9367284,1.854162
            6.1122236,1.854162 2.915207,-0.0054 5.710189,-1.166726 7.771581,-3.228369 C 20.833226,16.710109 21.994406,13.915047 22,10.999545
            22,8.8239422 21.354698,6.6981417 20.145958,4.889188 18.937345,3.0802456 17.21852,1.6693226 15.208639,0.83674658 14.2037,
            0.4204648 13.14869,0.15890248 12.077823,0.05342543 11.810104,0.02705633 11.540983,0.01049763 11.271914,0.00361896 Z M 11.00026,
            1.5996923 c 2.491801,0.00458 4.879957,0.9953423 6.64195,2.7574713 1.761999,1.7621464 2.752682,4.1503459 2.757292,6.6423814 0,
            1.859344 -0.551764,3.676948 -1.584649,5.222895 -1.03301,1.545957 -2.499787,2.750093 -4.217436,3.461555 -1.717648,0.711572
            -3.607358,0.899202 -5.430824,0.536551 C 7.3431486,19.857787 5.6684004,18.961202 4.3537829,17.646455 3.0391483,16.3317 2.1425704,
            14.656797 1.7798575,12.83333 1.4171551,11.00975 1.6049022,9.1199091 2.3163748,7.4021545 3.0278482,5.6843987 4.231872,4.2151466
            5.7777045,3.182182 7.3235489,2.1492169 9.1410618,1.5996923 11.00026,1.5996923 Z M 9.3839171,7.2640546 C 9.2495934,7.2640776
            9.1176482,7.298737 9.0013366,7.3659313 8.8850252,7.4331382 8.7877249,7.5302936 8.720627,7.6466592 8.6535176,7.7630246 8.6186748,
            7.8949319 8.6187566,8.0292639 v 5.9405611 c -8.11e-5,0.134344 0.034762,0.266343 0.1018704,0.382605 0.067098,0.116377 0.1643982,
            0.213499 0.2807096,0.280728 0.1163116,0.06723 0.2482568,0.10187 0.3825805,0.10187 0.1343225,0 0.2662565,-0.03475 0.3825795,
            -0.10187 L 14.90982,11.662877 c 0.116485,-0.06711 0.213484,-0.164352 0.280709,-0.280728 0.06734,-0.116492 0.10187,-0.248145
            0.10187,-0.382605 0,-0.13446 -0.03453,-0.266228 -0.10187,-0.382604 -0.06723,-0.116378 -0.164224,-0.213615 -0.280709,-0.280728
            L 9.7664966,7.3659316 C 9.6501736,7.2987595 9.5182396,7.2640307 9.3839171,7.2640546 Z" />
    </svg>
</template>

<script>
    export default {}
</script>
