<style lang="scss" scoped>
    @import 'styles/constants';
    .initializing-message {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: table;
        .wrapper {
            display: table-cell;
            vertical-align: middle;
        }
        .loading-icon {
            text-align: center;
            padding: 20px 0;
        }
        .text-center {
            color: $accent;
        }
        .weclome-text {
            color: $navigation;
        }
    }
</style>

<template>
    <div class="initializing-message">
        <div class="wrapper">
            <welcome-message class="weclome-text" />
            <div class="loading-icon">
                <img
                    src="@/assets/icon-globe.svg"
                    alt="MariaDB" />
            </div>
            <div class="text text-center">
                {{ msg }}
            </div>
        </div>
    </div>
</template>

<script>
    import i18n from 'plugins/i18n'
    import WelcomeMessage from './Welcome'

    export default {
        name: 'InitializingMessage',
        components: { 'welcome-message': WelcomeMessage, },
        props: {
            msg: {
                type: String,
                default: i18n.t('initializing'),
            },
        },
    }
</script>
