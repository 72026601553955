<style lang="scss" scoped>
.v-text-field input {
    font-size: 11px;
}

.alert-rule-name--label {
    font-size: 0.6875rem;
}

label {
    font-size: 11px;
}

.alert-rule-name--text {
    font-size: 1.425rem;
    color: $deep-ocean;
    font-style: normal;
    font-weight: 350;
}

.fields-header {
    color: $deep-ocean;
    font-style: normal;
    font-size: 0.875rem;
}

.field-divider {
    margin-bottom: 5px;
    margin-left: -36px;
    flex: 0 0 calc(100% + 72px);
}

.v-btn {
    &.cancel {
        min-width: 0;
    }

    &.v-btn--depressed {
        min-width: 17% !important;
    }
}

.alert-rule--switch {
    position: absolute;
    top: 24px;
    right: 60px;
    transform: scale(1.2);
    transform-origin: 50% 50%;
}

.cmpt-notif-btn {
    font-size: 0.6875rem;
    color: $links;

    &--disabled {
        pointer-events: none;
        color: rgba(0, 0, 0, 0.26) !important;
    }
}

.v-radio {
    :deep(.v-label) {
        font-size: 12px;
    }
}
</style>

<template>
    <base-dialog
        v-model="isDialogOpen"
        :on-save="saveRule"
        :on-cancel="onCancel"
        :min-width="776"
        :max-width="776"
        :on-close="closeModal"
        @is-form-valid="isFormValid = $event">
        <template #body>
            <div>
                <div class="d-flex align-center justify-space-between">
                    <label
                        class="alert-rule-name--label text-uppercase font-weight-bold color text-text-subtle d-block label-required">
                        {{ $t('policy') }}
                    </label>
                    <v-switch
                        v-model="active"
                        tabindex="-1"
                        hide-details
                        class="ma-0" />
                </div>
                <v-text-field
                    v-model="ruleName"
                    class="std v-text-field--with-bottom-details"
                    type="text"
                    height="36"
                    :rules="[(val) => validateRuleName(val)]"
                    outlined
                    required />
            </div>
            <div>
                <div class="text-uppercase font-weight-medium fields-header">
                    {{ $t('condition') }}
                </div>
                <v-row no-gutters>
                    <v-col cols="6">
                        <div class="mr-2">
                            <label :class="conditionLabelClasses"> {{ $t('metric') }} </label>
                            <v-autocomplete
                                ref="metricName"
                                v-model="tmpMetricId"
                                :class="['std v-text-field--with-bottom-details std--dropdown select-dropdown', ...conditionFieldClasses]"
                                :items="metrics"
                                item-text="id"
                                item-value="id"
                                :placeholder="$t('selectMetric')"
                                :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list' }"
                                height="36"
                                outlined
                                attach
                                single-line
                                required
                                :rules="validationRules.metricId"
                                hide-details="auto"
                                @change="onMetricChange">
                                <template #item="{ item }">
                                    <span class="list-item">
                                        <truncate-string
                                            :text="$t(`metrics.${item.id}`)"
                                            :max-width="'400px'"
                                            :dot-count="3"
                                            :has-tooltip="true"
                                            :single-char-with-in-px="7.3" />
                                    </span>
                                </template>
                                <template #selection="{ item }">
                                    <truncate-string
                                        :text="$t(`metrics.${item.id}`)"
                                        :max-width="'400px'"
                                        :dot-count="3"
                                        :has-tooltip="true"
                                        :single-char-with-in-px="7.3" />
                                </template>
                            </v-autocomplete>
                        </div>
                    </v-col>

                    <v-col cols="4">
                        <div
                            v-if="selectedComparison"
                            class="mr-2">
                            <label :class="conditionLabelClasses"> {{ $t('comparison') }}</label>
                            <v-select
                                ref="comparisonValue"
                                v-model="selectedComparison"
                                :class="['std std--dropdown select-dropdown', ...conditionFieldClasses]"
                                :items="comparisonOperators"
                                item-text="txt"
                                item-value="val"
                                placeholder="--"
                                :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list' }"
                                height="36"
                                outlined
                                attach
                                single-line
                                required
                                :rules="validationRules.comparison"
                                hide-details="auto"
                                @change="onComparisonChange">
                                <template #item="{ item: { txt } }">
                                    <span class="list-item"> {{ $t(txt) }} </span>
                                </template>
                                <template #selection="{ item: { txt } }">
                                    <span class="text-truncate"> {{ $t(txt) }}</span>
                                </template>
                            </v-select>
                        </div>
                    </v-col>
                    <v-col cols="2">
                        <div v-if="selectedComparison">
                            <label :class="conditionLabelClasses"> {{ $t('threshold') }} </label>
                            <v-text-field
                                v-model="selectedThreshold"
                                :class="conditionFieldClasses"
                                placeholder="--"
                                height="36"
                                single-line
                                outlined
                                required
                                :suffix="unitTypeSuffix"
                                :rules="validationRules.threshold"
                                hide-details="auto"
                                @change="onThresholdChange" />
                        </div>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <div
                            v-if="showDataType"
                            class="d-inline-block mr-2 condition-field condition-field--comparison">
                            <label :class="conditionLabelClasses"> {{ $t('diskType') }} </label>
                            <v-select
                                v-model="selectedDiskType"
                                :class="['std std--dropdown select-dropdown', ...conditionFieldClasses]"
                                :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list' }"
                                :items="diskTypes"
                                placeholder="--"
                                multiple
                                height="36"
                                outlined
                                :rules="validationRules.diskType"
                                attach
                                single-line
                                required />
                        </div>
                    </v-col>
                </v-row>
            </div>

            <div class="condition-field mt-2 mb-2">
                <label :class="conditionLabelClasses">
                    {{ $t('alertIfSustainedFor') }}
                </label>
                <v-radio-group
                    v-model="alertImmediately"
                    hide-details="auto"
                    row
                    class="mt-0 pt-0"
                    @change="onImmediatelyChange">
                    <v-radio
                        :label="$t('immediately')"
                        :value="true" />
                    <v-radio :value="false">
                        <template #label>
                            <div class="d-inline-flex align-center">
                                <span class="sustained mr-2">{{ $t('ifSustainedFor') }}</span>
                                <time-range
                                    ref="timeRange"
                                    height="36"
                                    class-name="trigger-period"
                                    :time="selectedTriggerPeriod"
                                    :validation-handler="validateTriggerPeriod"
                                    :disabled="alertImmediately"
                                    hide-details="auto"
                                    @on-time-range-change="onPeriodChange" />
                            </div>
                        </template>
                    </v-radio>
                </v-radio-group>
            </div>
            <div class="mb-3">
                <div
                    v-if="metricDescription"
                    class="d-inline-flex align-center pointer"
                    @click="showMetricDes = !showMetricDes">
                    <span class="metric-des-title font-weight-medium">
                        {{ $t(`metrics.${selectedMetric.id}`) }}
                        <span class="text-lowercase">{{ $t('description') }}</span>
                    </span>

                    <v-icon
                        class="ml-2 arrow-toggle"
                        :class="[showMetricDes ? 'rotate--up' : 'rotate--down']"
                        size="12"
                        color="deep-ocean">
                        $expand
                    </v-icon>
                </div>
                <v-expand-transition v-if="metricDescription">
                    <div
                        v-show="showMetricDes"
                        class="collapse-content">
                        <span class="d-block metric-des">{{ metricDescription }}</span>
                    </div>
                </v-expand-transition>
            </div>

            <div class="field-divider">
                <v-divider />
            </div>
            <v-col
                cols="12"
                class="pa-0 mb-5">
                <p class="my-2 text-uppercase font-weight-medium fields-header">
                    {{ $t('context') }}
                </p>
                <alert-tags-configurator
                    ref="alertTagsConfigurator"
                    :labels="labels"
                    :context="context"
                    :required-tag-types="['sysTags']"
                    :show-only-topologies="selectedMetric.metricType === 'service'"
                    @on-context-change="onContextChange"
                    @on-labels-change="onLabelChange" />
            </v-col>
            <div class="field-divider">
                <v-divider />
            </div>
            <v-col
                cols="12"
                class="pa-0 py-2">
                <p class="my-2 text-uppercase font-weight-medium fields-header color text-deep-ocean">
                    {{ $t('notificationsTriggered') }}
                </p>
            </v-col>
            <NotificationsTriggered :matching-tags="formattedTags" />
        </template>
        <template #actions="{ cancel, save }">
            <v-spacer />
            <v-btn
                color="primary"
                text
                rounded
                class="cancel"
                @click="cancel">
                <span class="text-none">{{ $t('cancel') }} </span>
            </v-btn>
            <v-btn
                color="primary"
                class="save"
                :disabled="isSaveDisabled"
                depressed
                rounded
                @click="save">
                <span class="text-none"> {{ $t('save') }}</span>
            </v-btn>
        </template>
    </base-dialog>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex'
    import notifsMatchedByLabels from 'mixins/alertMatching.js'
    import alertRuleValidation from 'mixins/alertRuleValidation.js'
    import AlertTagsConfigurator from './AlertTagsConfigurator'
    import TimeRange from './TimeRange'
    import NotificationsTriggered from 'components/alerts/NotificationsTriggered.vue'

    export default {
        name: 'RuleModal',
        components: {
            'time-range': TimeRange,
            'alert-tags-configurator': AlertTagsConfigurator,
            NotificationsTriggered,
        },
        mixins: [notifsMatchedByLabels, alertRuleValidation],
        data() {
            return {
                conditionLabelClasses: 'condition-field__label color text-small-text label-required',
                conditionFieldClasses: 'std v-text-field--with-bottom-details',
                showMetricDes: true,
                metricDescription: '',
                isDialogOpen: false,
                isFormValid: false,
                ruleName: null,
                active: true,
                alertImmediately: false,
                tmpMetricId: null,
                selectedComparison: null,
                selectedThreshold: null,
                selectedDiskType: '',
                selectedTriggerPeriod: null,
                labels: {},
                context: '',
                expr: null,
                timeSuffixes: ['s', 'm', 'h'],
                initRuleObject: {},
                selectedMetric: {
                    id: null,
                    metricType: null,
                    unitType: null,
                    description: null,
                    defaultValues: {
                        operator: null,
                        threshold: null,
                        for: null,
                    },
                },
                comparisonOperators: [
                    { val: '==', txt: 'isEqualTo', },
                    { val: '>', txt: 'isGreaterThan', },
                    { val: '<', txt: 'isSmallerThan', },
                    { val: '<=', txt: 'isSmallerThanOrEqualTo', },
                    { val: '>=', txt: 'isGreaterThanOrEqualTo', },
                    { val: '!=', txt: 'isNotEqualTo', }
                ],
                validationRules: {
                    metricId: [(val) => !!val || this.$t('errors.fieldRequired', { field: this.$t('metric'), })],
                    comparison: [(val) => !!val || this.$t('errors.fieldRequired', { field: this.$t(this.conditions.comparison.label), })],
                    diskType: [
                        () => {
                            return !!this.selectedDiskType.length
                        }
                    ],
                    threshold: [
                        (val) =>
                            this.selectedMetric.unitType === 'integer'
                                ? this.validateInteger(val, this.selectedThreshold.label)
                                : this.validateNonNegativeNumber(val, this.selectedThreshold.label)
                    ],
                },
            }
        },
        computed: {
            ...mapGetters(['metrics', 'metric', 'metricsMap', 'rules', 'rulesError', 'diskTypes']),
            unitTypeSuffix() {
                switch (this.selectedMetric.unitType) {
                    case 'percent':
                        return '%'
                    case 'seconds':
                        return 's'
                    case 'predefined':
                        return ''
                    default:
                        return ''
                }
            },
            isSaveDisabled() { return !this.isFormValid },
            showDataType() {
                return this.tmpMetricId === 'diskUtilization' || this.tmpMetricId === 'diskUtilizationTrend'
            },
            formattedTags() {
                const alertTagKeys = [this.$config.customTagPrefix, 'context']
                let tags = this.$help.objects.pickPropertyStartsWith({ object: this.labels, keys: alertTagKeys, })
                tags.context = this.context
                if (this.$typy(tags, 'context').isDefined) tags.context = this.$help.alerts.unescapeTags(tags.context)
                return this.$help.alerts.cortexLabelsToTags(tags)
            },
        },
        methods: {
            ...mapActions(['fetchMetrics', 'fetchAlertManagerConfiguration', 'editRule', 'addRule', 'updateAlertManagerConfig']),

            open({ mode, rule, }) {
                this.isDialogOpen = true
                switch (mode) {
                    case 'edit':
                        this.initRuleObject = rule
                        this.ruleName = rule.name
                        this.labels = rule.labels
                        this.context = rule.context
                        this.expr = rule.expr
                        this.active = rule.active
                        this.preselectMetric(rule)
                        this.extractDataType(rule.expr)
                        this.viewMode = 'edit'
                        break
                    case 'create':
                        this.viewMode = 'create'
                        break
                    case 'copy':
                        this.viewMode = 'create'
                        break
                }
            },
            onMetricChange(metricId) {
                this.selectedMetric = this.metric(metricId)
                this.tmpMetricId = this.selectedMetric.id
                this.alertImmediately = false
                this.selectedComparison = this.selectedMetric.defaultValues.operator ? this.selectedMetric.defaultValues.operator : null
                this.selectedThreshold = this.selectedMetric.defaultValues.threshold
                this.selectedTriggerPeriod = this.selectedMetric.defaultValues.for ? this.selectedMetric.defaultValues.for : null
                this.updateExpr()
                this.metricDescription = this.selectedMetric.description.replace(/\\n/g, '\n')
            },
            preselectMetric(rule) {
                this.selectedMetric = this.metric(rule.metricId)
                this.tmpMetricId = rule.metricId
                this.alertImmediately = rule.for === this.$config.REPEAT_INTERVAL_IMMEDIATELY ? true : false
                this.selectedComparison = rule.operator ? rule.operator : null
                this.selectedThreshold = rule.threshold
                this.selectedTriggerPeriod = rule.for ? rule.for : null
            },
            validateTriggerPeriod(v, suffix) {
                if (this.alertImmediately) return true
                if (!v) return this.$t('errors.fieldRequired', { field: this.$t(this.triggerPeriodLabel), })
                else if (this.$help.toSeconds({ v, suffix, }) < 120) return this.$t('errors.minTriggerPeriod')
                return true
            },
            onPeriodChange(timeRange) {
                this.selectedTriggerPeriod = `${timeRange.value}${timeRange.suffix}`
                this.updateExpr()
            },
            onImmediatelyChange(isImmediatelySelected) {
                if (isImmediatelySelected) { this.selectedTriggerPeriod = this.$config.REPEAT_INTERVAL_IMMEDIATELY }
                else {
                    if (this.selectedTriggerPeriod === this.$config.REPEAT_INTERVAL_IMMEDIATELY) {
                        this.selectedTriggerPeriod = this.selectedMetric.defaultValues.for
                    }
                }
                this.updateExpr()
            },
            clearState() {
                this.ruleName = null
                this.labels = {}
                this.active = true
                this.context = ''
                this.expr = null
                this.tmpMetricId = null
                this.selectedComparison = null
                this.selectedThreshold = null
                this.alertImmediately = false
                this.selectedTriggerPeriod = this.$config.REPEAT_INTERVAL_IMMEDIATELY
                this.viewMode = null
                this.initRuleObject = {}
                this.metricDescription = ''
                this.selectedMetric = {
                    id: null,
                    metricType: null,
                    unitType: null,
                    description: null,
                    defaultValues: {
                        operator: null,
                        threshold: null,
                        for: null,
                    },
                }
            },
            closeModal() {
                this.clearState()
            },
            onCancel() {
                this.clearState()
            },
            onContextChange(context) {
                this.context = context
                this.updateExpr()
            },
            onLabelChange(labels) {
                this.labels = labels
                this.updateExpr()
            },
            onComparisonChange() {
                this.updateExpr()
            },
            onThresholdChange() {
                this.updateExpr()
            },
            async saveRule() {
                const ruleForSave = {
                    ...this.initRuleObject,
                    annotations: {},
                    context: this.context,
                    labels: this.labels,
                    expr: this.expr,
                    for: this.selectedTriggerPeriod || '',
                    metricId: this.selectedMetric.id,
                    name: this.ruleName,
                    operator: this.selectedComparison,
                    threshold: Number(this.selectedThreshold),
                    active: this.active,
                }
                if (this.viewMode === 'edit') { this.editRule(ruleForSave) }
                if (this.viewMode === 'create') { this.addRule(ruleForSave) }
                this.clearState()
            },
            validateInteger(val, label) {
                const regex = RegExp(/^[-]?\d*$/g) // allow minus or hyphen minus and numbers
                if (this.$help.isNullOrEmptyStr(val)) return this.$t('errors.fieldRequired', { field: this.$t(label), })
                else if (!regex.test(val)) return this.$t('errors.nonInt')
                return true
            },
            validateNonNegativeNumber(val, label) {
                const regex = RegExp(/^\d*$/g)
                if (this.$help.isNullOrEmptyStr(val)) return this.$t('errors.fieldRequired', { field: this.$t(label), })
                else if (!regex.test(val)) return this.$t('errors.nonNegative')
                return true
            },
            buildServiceExpr(fullExpr, service, diskType = 'null') {
                let arr = service.split('/')
                const _diskType = diskType ? diskType.join('|') : null
                let serviceValue = arr[0] === 'all' ? '.*' : arr[0]
                let serverValue = arr[1] === 'all' ? '.*' : arr[1]
                return fullExpr
                    .replace(/__service__/g, serviceValue)
                    .replace(/__server__/g, serverValue)
                    .replace(/__disk_purpose__/g, _diskType)
            },
            updateExpr() {
                const metric = this.selectedMetric
                let metricExpr = metric.expr
                let fullExpr = metric.unitType === 'predefined' ? metricExpr : `${metricExpr} ${this.selectedComparison} ${this.selectedThreshold}`

                let resultExpr = ''
                if (this.context) {
                    let servicesArr = this.context.split(',')
                    let diskType = this.selectedDiskType

                    servicesArr.forEach((service, i) => {
                        let separator = i > 0 ? ' OR ' : ''
                        resultExpr += `${separator}${this.buildServiceExpr(fullExpr, service, diskType)}`
                    })
                }
                this.expr = resultExpr || fullExpr
            },
            extractDataType(expr) {
                const dataType = /(?:disk_purpose=~'([^ ]*)')/g.exec(expr)
                if (dataType) {
                    this.selectedDiskType = dataType[1].split('|')
                }
            },
        },
        async created() {
            await Promise.all([this.fetchMetrics()])
            await this.$store.dispatch('fetchDiskTypeInputs')
        },
    }
</script>
