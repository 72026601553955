import base from 'api/base'

const baseUrl = process.env.VUE_APP_API_GATEWAY_BASE_URL
const provisioningURI = 'als/v1'

export default {
    post (uri, body, params, options) {
        return base.post(`${baseUrl}/${provisioningURI}/${uri}`, body, params, options)
    },
    put (uri, body, params, options) {
        return base.put(`${baseUrl}/${provisioningURI}/${uri}`, body, params, options)
    },
    get (uri, params, options) {
        return base.get(`${baseUrl}/${provisioningURI}/${uri}`, params, options)
    },
    delete (uri, params) {
        return base.delete(`${baseUrl}/${provisioningURI}/${uri}`, params)
    },
}
