<style lang="scss" scoped>
.topology-container {
    height: auto;
    border-right: 1px solid #bed1da;
    position: relative;
    padding-bottom: 2rem;
    flex: 1 0 21%;

    .icon-container {
        min-height: 6rem;
    }
    .topology-subtitle {
        font-size: 12px;
        color: #a6a4a6;
    }

    .topology-selected-icon {
        position: absolute;
        right: 0px;
        top: 10px;
    }
}

.gray-bg {
    opacity: 0.35;
    cursor: auto;
}

.tech-preview {
    position: absolute;
    top: -20px
}
.coming-soon {
    position: absolute;
    top: 0;
    left: 50%;
    font-size: 10px;
    padding: 2px 8px;
    background: $selection-highlight;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    transform: translateX(-50%);
}
</style>
<template>
    <div
        :class="[
            'topology-container px-4 text-center',
            isSelected && 'highlight-instance-row',
        ]"
        @click="onClick">
        <div
            v-if="shouldDisable"
            class="coming-soon white--text text-uppercase font-weight-bold">
            {{ $t('comingSoon') }}
        </div>
        <div :class="['d-inline-block text-left', shouldDisable && 'gray-bg']">
            <TechPreview
                v-if="isTechPreview"
                class="mt-8" />
            <div
                v-if="isSelected"
                class="topology-selected-icon">
                <v-icon
                    size="20"
                    class="mr-4 mt-4">
                    $vuetify.icons.statusOk
                </v-icon>
            </div>
            <div class="text-center mt-4 pt-6 icon-container">
                <v-icon
                    :size="iconSize"
                    :class="['mr-3', { 'gray-icon': shouldDisable }]">
                    {{ icon }}
                </v-icon>
            </div>
            <div class="mt-3 text-subtitle-1 font-weight-medium topology-server">
                {{ serverType }}
            </div>
            <div class="mb-3 text-subtitle-1 font-weight-medium topology-title">
                {{ title }}
            </div>
            <p class="topology-description">
                {{ description }}
            </p>
            <ul>
                <li
                    v-for="(feature, index) in features"
                    :key="index">
                    {{ feature }}
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
    import TechPreview from 'components/common/TechPreview.vue'

    const TopologyCard = {
        name: 'topology-card',
        components: { TechPreview, },
        props: {
            title: String,
            description: String,
            id: String,
            features: Array,
            icon: String,
            iconSize: Number,
            currentTopologyId: String,
            serverType: String,
            shouldDisable: {
                type: Boolean,
                default: false,
            },
            type: {
                type: String,
                default: '',
            },
            isTechPreview: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            isSelected () {
                return this.currentTopologyId === this.id
            },
        },
        methods: {
            onClick () {
                if(!this.shouldDisable) this.$emit('handleTopologySelection', this.id)
            },
        },
    }

    export default TopologyCard
</script>
