<template>
    <v-menu
        v-model="isOpen"
        content-class="py-0"
        transition="slide-y-transition"
        rounded="lg"
        min-width="500"
        max-width="500"
        :close-on-content-click="false"
        left
        offset-y>
        <template #activator="{ on }">
            <v-btn
                icon
                v-on="on">
                <v-badge
                    :value="newNotifications.length"
                    color="pink"
                    dot
                    overlap>
                    <v-icon>
                        $vuetify.icons.bell
                    </v-icon>
                </v-badge>
            </v-btn>
        </template>
        <v-list
            v-if="!newNotifications.length"
            class="pa-4">
            <div class="text-center mb-1">
                <v-icon
                    color="primary"
                    size="38">
                    mdi-check-circle-outline
                </v-icon>
            </div>
            <div class="text-h6 color text-navigation text-center">
                {{ $t('info.allGood') }}
            </div>
            <div class="text-body-2 color text-navigation text-center">
                {{ $t('info.noNewNotifications') }}
            </div>
            <div class="text-body-2 color text-navigation text-center">
                <router-link to="/notifications">
                    {{ $t('viewAll') }}
                </router-link>
            </div>
        </v-list>
        <v-list
            v-else
            class="pb-0">
            <v-subheader>
                <span
                    class="color text-navigation"
                    style="flex: 1">
                    {{ $tc('newNotifications', newNotifications.length, [newNotifications.length]) }}
                </span>
                <span
                    v-if="newNotifications.length"
                    class="color text-links d-inline-block pointer"
                    @click="setRead">
                    {{ $t('clearAll') }}
                </span>
            </v-subheader>

            <v-divider />
            <v-list-item
                v-for="(alert, i) in newNotifications.slice(0, showLimit)"
                :key="i"
                @click="viewMore">
                <icon-sprite-sheet
                    :size="12"
                    :frame="alert.level"
                    class="mr-1">
                    notification
                </icon-sprite-sheet>
                <v-list-item-content>
                    <v-list-item-subtitle class="grey--text text--lighten2">
                        {{ $help.serviceServerName(alert) }}
                    </v-list-item-subtitle>
                    <v-list-item-title class="color text-links">
                        <truncate-string
                            :text="alert.display_string"
                            max-width="390px"
                            :dot-count="3" />
                    </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                    <v-tooltip top>
                        <template #activator="{ on }">
                            <span
                                class="text-body-2 grey--text text--lighten2"
                                v-on="on">
                                {{ alert.created_on | unixToFromNow }}
                            </span>
                        </template>
                        <div>{{ alert.created_on | unixToDate }}</div>
                    </v-tooltip>
                </v-list-item-action>
            </v-list-item>

            <v-divider />
            <v-subheader class="justify-center">
                <span
                    class="color text-links d-inline-block pointer"
                    @click="viewMore">
                    {{ $t('viewMore') }}
                </span>
            </v-subheader>
        </v-list>
    </v-menu>
</template>
<script>
    import notifications from 'api/notifications'
    import { mapGetters } from 'vuex'

    export default {
        name: 'NotificationsList',
        data () {
            return {
                isOpen: false,
                showLimit: 8,
                lastAlertReadAt: localStorage.getItem('lastAlertReadAt') || '',
                notifications: [],
            }
        },
        computed: {
            ...mapGetters(['newNotification']),
            latestNotifications () {
                const active = this.notifications
                return active.sort((a, b) => parseInt(b.created_on) - parseInt(a.created_on))
            },
            newNotifications () {
                const newNotifications = []
                for (let i = 0; i < this.notifications.length; i++) {
                    if (this.notifications[i].created_on > this.lastAlertReadAt){
                        newNotifications.push(this.notifications[i])
                    }
                }
                return newNotifications
            },
            newNotificationsCount () {
                return this.newNotifications.length
            },
        },
        watch: {
            newNotification() {
                if(this.$unleash.isFeatureEnabled('enable-portal-sse-notifications')) this.updateNewNotification()
            },
        },
        methods: {
            setRead () {
                if (!this.latestNotifications.length) return
                this.lastAlertReadAt = this.$moment().unix()
                localStorage.setItem('lastAlertReadAt', this.lastAlertReadAt)
            },
            viewMore () {
                this.isOpen = false
                this.$router.push('/notifications')
            },
            async fetchNotifications() {
                // eslint-disable-next-line camelcase
                const { data, } = await notifications.get('notifications', { page_size: 10,})
                if(data.length) {
                    this.notifications = data
                }
            },
            initLastReadAt(){
                if(localStorage.hasOwnProperty('lastAlertReadAt')) return
                this.lastAlertReadAt = this.$moment().unix()
                localStorage.setItem('lastAlertReadAt', this.lastAlertReadAt)
            },
            updateNewNotification(){
                this.notifications = [ this.newNotification, ...this.notifications ]
            },
        },
        async mounted() {
            this.fetchNotifications()
            this.initLastReadAt()
            if(!this.$unleash.isFeatureEnabled('enable-portal-sse-notifications')){
                setInterval(async() => { this.fetchNotifications() }, this.$config.QUICK_NOTIFICATION_FETCH_INTERVAL_SECONDS * 1000)
            }
        },
    }
</script>
