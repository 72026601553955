import Vue from 'vue'
import { t } from 'typy'
import i18n from 'plugins/i18n'

export default {
    state: {
        isHealthy: true,
        healthMessage: '',
        isCheckingAPI: false,
        healthCheckInterval: null,
    },
    mutations: {
        setHealth (state, { status = true, message = 'info.allGood', }) {
            state.isHealthy = status
            state.healthMessage = i18n.t(message)
        },
        setStatusAPI (state, status) {
            state.isCheckingAPI = status
        },
    },
    actions: {
        async checkConnection ({ commit, }) {
            commit('setStatusAPI', false)

            // Check what the browser reports first
            if (navigator && navigator.onLine === false) {
                return commit('setHealth', { status: false, message: 'warn.noInternetConnection', })
            }

            // Ping our API for health status
            try {
                commit('setStatusAPI', true)
                const healthReq = await Vue.axios.get('/api/keepalive')
                commit('setStatusAPI', false)

                if (t(healthReq, 'data').isString) {
                    if (healthReq.data !== 'OK') return commit('setHealth', { status: false, message: 'warn.apiProblem', })
                } else {
                    return commit('setHealth', { status: false, message: 'warn.apiProblem', })
                }
            } catch (err) {
                commit('setStatusAPI', false)
                return commit('setHealth', { status: false, message: 'warn.apiProblem', })
            }

            commit('setHealth', { status: true, })
        },
        startHealthCheckInterval ({ state, dispatch, }, seconds) {
            clearInterval(state.healthCheckInterval)
            dispatch('checkConnection')

            state.healthCheckInterval = setInterval(() => {
                dispatch('checkConnection')
            }, seconds * 1000)
        },
        stopHealthCheckInterval ({ state, }) {
            clearInterval(state.healthCheckInterval)
        },
    },
    getters: {
        appHealth: state => {
            const { isHealthy, healthMessage, isCheckingAPI, } = state

            return {
                status: isHealthy,
                message: healthMessage,
                isChecking: isCheckingAPI,
            }
        },
    },
}
