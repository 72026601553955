import AuthAPI from 'api/auth'

const SESSION_URI = 'iam/v1/session'

export function grantToken (code, state) {
    const params = new URLSearchParams({
        code,
        state,
    })
    return AuthAPI.get(`${SESSION_URI}/grant?${params}`, {
        withCredentials: true,
    })
}

export function refreshToken () {
    return AuthAPI.get(`${SESSION_URI}/refresh`, {
        withCredentials: true,
    })
}

export function logout () {
    return AuthAPI.post(
        `${SESSION_URI}/logout`,
        {},
        {
            withCredentials: true,
        }
    )
}
