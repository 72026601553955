<template>
    <v-card
        outlined
        class="details-card">
        <v-card-title class="details-title text-body-2 d-flex justify-space-between text-uppercase">
            {{ $t('globalDistributedReplication') }}
            <ReplicationMenu
                @on-edit="edit"
                @on-remove="remove" />
        </v-card-title>
        <v-card-text>
            <WorldMap :replications="replications" />
            <ReplicationTable
                :service="service"
                :replications="replications" />
        </v-card-text>
        <GlobalXpandModal
            ref="editModal"
            :dialog-type="$config.DIALOG_TYPE.EDIT"
            :selected-service="service" />
        <GlobalXpandModal
            ref="removeModal"
            :dialog-type="$config.DIALOG_TYPE.REMOVE"
            :selected-service="service" />
    </v-card>
</template>
<script>
    import ReplicationMenu from 'components/service/ReplicationMenu.vue'
    import GlobalXpandModal from 'components/service/GlobalXpandModal.vue'
    import WorldMap from 'components/service/WorldMap.vue'
    import ReplicationTable from 'components/service/ReplicationTable.vue'
    export default {
        name: 'XpandReplication',
        components: {
            ReplicationMenu,
            GlobalXpandModal,
            WorldMap,
            ReplicationTable,
        },
        props: {
            service: {
                type: Object,
                required: true,
            },
            replications: {
                type: Array,
                required: true,
            },
        },
        methods: {
            edit() {
                this.$refs.editModal.open()
            },
            remove() {
                this.$refs.removeModal.open()
            },
        },
    }
</script>
