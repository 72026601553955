import to from 'await-to-js'
import common from 'config/common'
import { listUsers, getRoles, patchUser, detachRole, attachRole } from 'services/users'
export default {
    state: {
        users: [],
        usersLoading: false,
        roles: [],
        updateUserResponse: null,
        editUserResponse: null,
    },
    mutations: {
        setUsers(state, users) {
            state.users = users
        },
        setUsersLoading(state, payload) {
            state.usersLoading = payload
        },
        setRoles(state, roles) {
            state.roles = roles
        },
        setUpdateUserResponse(state, roles) {
            state.updateUserResponse = roles
        },
        setEditUserResponse(state, roles) {
            state.editUserResponse = roles
        },
    },
    actions: {
        async getUsers({ commit, }) {
            commit('setUsersLoading', true)
            const [error, response] = await to(listUsers())
            if (error) {
                commit('showMessage', { text: error.response.data.errors[0].message, type: common.messageType.ERROR, })
                commit('setUsersLoading', false)
            } else {
                commit('setUsers', response)
            }
            commit('setUsersLoading', false)
        },
        async getRoles({ commit, }) {
            commit('setUsersLoading', true)
            const [error, response] = await to(getRoles())
            if (error) {
                commit('showMessage', { text: error.response.data.errors[0].message, type: common.messageType.ERROR, })
                commit('setUsersLoading', false)
            } else {
                commit('setRoles', response)
            }
            commit('setUsersLoading', false)
        },
        async updateUser({ commit, }, { userId, userObject, }) {
            commit('setUsersLoading', true)
            commit('setUpdateUserResponse', null)
            const [error, response] = await to(patchUser(userId, userObject))
            if (error) {
                commit('showMessage', { text: error.response.data.errors[0].message, type: common.messageType.ERROR, })
                commit('setUsersLoading', false)
                commit('setUpdateUserResponse', false)
            } else {
                commit('setUpdateUserResponse', response || true)
            }
            commit('setUsersLoading', false)
        },
        async editUser({ commit, }, { userId, detachRoleId, attachRoleId, }) {
            commit('setUsersLoading', true)
            commit('setEditUserResponse', null)
            const [error] = await to(detachRole(userId, { roles: [detachRoleId], }))
            if (error) {
                commit('showMessage', { text: error.response.data.errors[0].message, type: common.messageType.ERROR, })
                commit('setUsersLoading', false)
                commit('setEditUserResponse', false)
            } else {
                const [error, response] = await to(attachRole(userId, { roles: [attachRoleId], }))
                if (error) {
                    commit('showMessage', { text: error.response.data.errors[0].message, type: common.messageType.ERROR, })
                    commit('setUsersLoading', false)
                    commit('setEditUserResponse', false)
                } else {
                    commit('setEditUserResponse', response || true)
                }
            }
            commit('setUsersLoading', false)
        },
    },
    getters: {
        users: state => state.users,
        usersLoading: state => state.usersLoading,
        roles: state => state.roles,
        updateUserResponse: state => state.updateUserResponse,
        editUserResponse: state => state.editUserResponse,
    },
}
