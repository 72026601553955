<template>
    <div>
        <h2>Predictive Analysis</h2>
        <p>Beginning with 2023-05-06 00:00:00, our predictive machine learning model generates predictions for the data. Observations can be made by examining these predicted outcomes in conjunction with the prior analysis conducted on historical data, as follows:</p>
        <p>Comparing the predicted data to the historical data, it appears that the previously identified anomalous metrics, <i>Memory_used</i> and <i>delta_Connections</i>, are likely to show different behavior in the future:</p>
        <ul>
            <li>For <i>Memory_used</i>, predictions indicate a decrease in values compared to the highest peak observed in the historical analysis. The predicted values appear to stabilize around 4107400713 to 4121079676 between 2023-05-06 00:00:00 to 2023-05-06 02:00:00. This suggests that the metric may be moving towards a more normal range.</li>
            <li>The <i>delta_Connections</i> metric is predicted to also show a significant decrease in comparison to the extremely high values observed in the historical data. For instance, the predicted value at 2023-05-06 00:00:00 is 243818, which is notably lower than the peak value of 555192 observed previously. This indicates that the delta_Connections could be returning to a more typical range.</li>
        </ul>

        <p class="my-3">
            In summary, based on the predictive analysis of future data in combination with our initial analysis of historical data, it appears that the anomalies seen in the <i>Memory_used</i> and <i>delta_Connections</i> metrics are likely to reduce and return to a more normal range. However, it is essential to continuously monitor the actual metrics as data becomes available to validate these insights and make necessary adjustments to ensure optimal database performance.
        </p>
    </div>
</template>
<script>
    export default {
        name: 'PredictiveAnalysis',
    }
</script>
