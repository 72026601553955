export default {
    methods: {
        computeIopsRange(storageSize) {
            if (!this.iopsValues || !this.iopsValues.length) return []
            const selectedIops = this.iopsValues.find(
                (iopsObj) =>
                    storageSize <= iopsObj.storage_range_max
                    && storageSize >= iopsObj.storage_range_min
            )

            this.iopsMinValue = selectedIops.iops_range_min
            this.iopsMaxValue = selectedIops.iops_range_max
        },
    },
}
