<style lang="scss" scoped>
.billing-summary {
    border: 1px solid $cornsilk;
    color: $deep-ocean;
}
</style>

<template>
    <div>
        <div v-if="!isFetchingBillingUsage">
            <div v-if="billingUsageDetails.length">
                <v-row
                    data-auto="billing-summary"
                    class="font-weight-bold text-subtitle-1 billing-summary pa-2">
                    <v-col cols="2">
                        {{ $t('billings.current-charges') }}
                        <div data-auto="total-charges">
                            {{ $t('currency', [total.toFixed(2)]) }}
                        </div>
                    </v-col>
                    <v-col
                        v-if="!$unleash.isFeatureEnabled('disable-portal-free-trial')"
                        cols="2">
                        {{ $t('billings.coupon-remaining') }}
                        <div
                            data-auto="coupon-remaining"
                            class="text-subtitle-2 font-weight-regular">
                            {{ $t('currency', [couponRemaining.toFixed(2)]) }}
                        </div>
                    </v-col>
                    <v-col cols="2">
                        {{ $t('billings.last-billed') }}
                        <div
                            data-auto="last-billed"
                            class="text-subtitle-2 font-weight-regular text-uppercase">
                            {{ lastBilled }}
                        </div>
                    </v-col>
                    <v-col cols="2">
                        {{ $t('billings.next-invoice') }}
                        <div
                            data-auto="next-invoice"
                            class="text-subtitle-2 font-weight-regular">
                            {{ nextInvoice }}
                        </div>
                    </v-col>
                    <v-col
                        v-if="$unleash.isFeatureEnabled('disable-portal-free-trial')"
                        cols="2" />
                    <v-col cols="4">
                        <BillingSortToggle
                            :default-usage-sort="currentUsageSort"
                            @on-sort-toggle="updateUsageSort" />
                    </v-col>
                </v-row>
                <div
                    class="mt-4">
                    <BillingUsageTable
                        :current-usage-sort="currentUsageSort"
                        :usage-data="usageData" />
                    <v-row>
                        <v-col cols="8" />
                        <BillingUsageTotal
                            :total="total" />
                        <v-col cols="2" />
                    </v-row>
                </div>
            </div>
            <div v-else>
                <v-row
                    justify="center"
                    data-auto="billing-summary"
                    class="font-weight-bold text-subtitle-1 billing-summary pa-2">
                    {{ $t('errors.missingUsageData') }}
                </v-row>
            </div>
        </div>
        <v-container
            v-else
            class="fill-height"
            fluid>
            <v-row
                align="center"
                justify="center">
                <v-progress-circular
                    size="70"
                    width="7"
                    color="primary"
                    indeterminate />
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import BillingSortToggle from 'components/billing/BillingSortToggle.vue'
    import BillingUsageTable from 'components/billing/BillingUsageTable.vue'
    import BillingUsageTotal from 'components/billing/BillingUsageTotal.vue'
    import { prepareUsageByEntities, prepareUsageByServices } from 'utils/billing'
    const BillingSummary = {
        name: 'BillingSummary',
        components: {
            BillingSortToggle,
            BillingUsageTable,
            BillingUsageTotal,
        },
        props: {
            lastBilled: {
                required: true,
                type: String,
            },
            nextInvoice: {
                required: true,
                type: String,
            },
        },
        data() {
            return {
                currentUsageSort: 'service',
            }
        },
        computed: {
            ...mapGetters([
                'billingUsageDetails',
                'orgData',
                'isFetchingBillingUsage',
                'billingRecord'
            ]),
            usageSort() {
                return [{
                    text: 'By service',
                    value: 'service',
                }, {
                    text: 'By resource',
                    value: 'resource',
                }]
            },
            usageData(){
                if(this.$lodash.isEmpty(this.billingUsageDetails)) return []
                return this.currentUsageSort === 'service' ? prepareUsageByServices(this.billingUsageDetails) : prepareUsageByEntities(this.billingUsageDetails)
            },
            total() {
                return this.billingUsageDetails.
                    reduce((total, lineItem) =>
                        parseFloat(lineItem.cost) + total, 0
                    )
            },
            couponRemaining(){
                const { trialTotal, trialUse, } = this.billingRecord
                return trialUse <= trialTotal ? trialTotal - trialUse : 0
            },
        },
        methods: {
            ...mapActions(['fetchBillingUsage', 'fetchBillingRecord']),
            updateUsageSort(sortType){
                this.currentUsageSort = sortType
            },
        },
        created() {
            const monthStartDate = this.$moment().startOf('month').format('YYYY-MM-DD')
            if (this.$lodash.isEmpty(this.billingRecord)) {
                this.fetchBillingRecord()
            }
            if (this.$lodash.isEmpty(this.billingUsageDetails)) {
                // eslint-disable-next-line camelcase
                this.fetchBillingUsage({ orgId: this.orgData.org_id, startDate: monthStartDate, })
            }
        },
    }
    export default BillingSummary
</script>
