<template>
    <div>
        <v-menu
            transition="slide-y-transition"
            offset-y>
            <template #activator="{ on, attrs }">
                <div
                    v-bind="attrs"
                    v-on="on">
                    <v-icon>
                        mdi-dots-horizontal
                    </v-icon>
                </div>
            </template>
            <v-list class="pointer">
                <span v-if="selectedUser.status === $config.USER_STATUS.EXPIRED">
                    <v-list-item>
                        <v-list-item-title
                            @click="openCancel">
                            {{ $t('userManagementTab.cancelInvitation') }}
                        </v-list-item-title>
                    </v-list-item>
                </span>
                <span v-else>
                    <v-list-item>
                        <v-list-item-title @click="openEdit">
                            {{ $t('edit') }}
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        v-if="selectedUser.is_active"
                        class="color text-error"
                        @click="openDeactivate">
                        <v-list-item-title>{{ $t('deactivate') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-else>
                        <v-list-item-title @click="openActivate">
                            {{ $t('activate') }}
                        </v-list-item-title>
                    </v-list-item>
                </span>
            </v-list>
        </v-menu>
        <user-actions
            ref="editModal"
            :dialog-type="$config.DIALOG_TYPE.EDIT"
            :selected-user="selectedUser" />
        <user-actions
            ref="deactivateModal"
            :dialog-type="$config.DIALOG_TYPE.DEACTIVATE"
            :selected-user="selectedUser" />
        <user-actions
            ref="activateModal"
            :dialog-type="$config.DIALOG_TYPE.ACTIVATE"
            :selected-user="selectedUser" />
        <user-actions
            ref="cancelModal"
            :dialog-type="$config.DIALOG_TYPE.CANCEL"
            :selected-user="selectedUser" />
    </div>
</template>
<script>
    import UserActionsModalVue from 'components/userManagement/UserActionsModal.vue'
    export default {
        name: 'ActionsMenu',
        components: {
            'user-actions': UserActionsModalVue,
        },
        props: {
            selectedUser: {
                type: Object,
                default: null,
            },
        },
        methods: {
            openEdit() {
                this.$refs.editModal.open()
            },
            openDeactivate() {
                this.$refs.deactivateModal.open()
            },
            openActivate() {
                this.$refs.activateModal.open()
            },
            openCancel() {
                this.$refs.cancelModal.open()
            },
        },
    }
</script>
