<template>
    <v-container
        class="ma-0 pa-12"
        fluid>
        <v-col cols="auto pa-0 wrapper-container">
            <SettingHeader :header-text=" $t('policies') " />

            <rules-table
                class="mt-2"
                :loading="rulesLoading"
                :rules="sortedPolices"
                @rule-edit="handleEditRule"
                @rule-delete="handleDeleteRules" />
        </v-col>
    </v-container>
</template>


<script>
    import { mapState, mapGetters } from 'vuex'
    import RulesTable from 'components/alerts/RulesTable'
    import SettingHeader from './SettingHeader'

    export default {
        name: 'Policies',
        components: {
            'rules-table': RulesTable,
            SettingHeader,
        },
        computed: {
            ...mapState(['config']),
            ...mapGetters(['rules', 'rulesLoading']),
            sortedPolices(){
                let policies = this.rules
                return policies.sort((a, b) => a.name.localeCompare(b.name))
            },
        },
        methods: {
            async handleEditRule (rule) {
                await this.$store.dispatch('editRule', rule)
            },
            async handleDeleteRules (rules) {
                await this.$store.dispatch('deleteRules', rules)
            },
        },
        beforeCreate () {
            this.$store.dispatch('fetchRules')
        },
    }
</script>
