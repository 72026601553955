export function serviceQueriesPerSecond ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(mariadb_global_status_queries{mariadb=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serviceSlowQueries ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `rate(mariadb_global_status_slow_queries{mariadb=~"${name}", kubernetes_namespace="${id}"}[300s])`
    }
}

export function serviceCommands ({ name, id, } = {}, dataSource) {
    switch (dataSource) {
    default:
        return `sum(rate(mariadb_global_status_commands_total{mariadb=~"${name}", kubernetes_namespace="${id}"}[300s])>0) by (command)`
    }
}

export function serviceTopCommands ({ name, id, } = {}, dataSource) {
    switch (dataSource) {
    default:
        return `topk(30, (sum(rate(mariadb_global_status_commands_total{mariadb=~"${name}", kubernetes_namespace="${id}"}[1h])) by (command))>0)`
    }
}

export function serviceQuestionsPerSecond ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(mariadb_global_status_questions{mariadb=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}

export function queryCacheSize ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(mariadb_global_variables_key_buffer_size{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}

export function serverCommands ({ name, id, } = {}, dataSource) {
    switch (dataSource) {
    default:
        return `sum(rate(mariadb_global_status_commands_total{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name, command)`
    }
}

export function serverTopCommands ({ name, id, timeDuration = '300s', } = {}, dataSource) {
    switch (dataSource) {
    default:
        return `sum(topk(30, rate(mariadb_global_status_commands_total{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[${timeDuration}])>0)) by (kubernetes_pod_name, command)`
    }
}

export function serverSelectFullJoin ({ name, id, } = {}, dataSource) {
    switch (dataSource) {
    default:
        return `avg(rate(mariadb_global_status_select_full_join{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serverSelectFullRangeJoin ({ name, id, } = {}, dataSource) {
    switch (dataSource) {
    default:
        return `avg(rate(mariadb_global_status_select_full_range_join{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serverSelectRange ({ name, id, } = {}, dataSource) {
    switch (dataSource) {
    default:
        return `avg(rate(mariadb_global_status_select_range{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serverSelectRangeCheck ({ name, id, } = {}, dataSource) {
    switch (dataSource) {
    default:
        return `avg(rate(mariadb_global_status_select_range_check{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serverSelectScan ({ name, id, } = {}, dataSource) {
    switch (dataSource) {
    default:
        return `avg(rate(mariadb_global_status_select_scan{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serverSortRows ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(rate(mariadb_global_status_sort_rows{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serverSortRange ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(rate(mariadb_global_status_sort_range{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serverSortMergePasses ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(rate(mariadb_global_status_sort_merge_passes{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serverSortScan ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(rate(mariadb_global_status_sort_scan{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serverHandlers ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(rate(mariadb_global_status_handlers_total{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}", handler!~"commit|rollback|savepoint.*|prepare"}[300s])) by (kubernetes_pod_name, handler)`
    }
}

export function serverTransactionalHandlers ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(rate(mariadb_global_status_handlers_total{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}", handler=~"commit|rollback|savepoint.*|prepare"}[300s])) by (kubernetes_pod_name, handler)`
    }
}

export function serverQueriesPerSecond ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(rate(mariadb_global_status_queries{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serverQuestionsPerSecond ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(rate(mariadb_global_status_questions{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serverSlowQueries ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(rate(mariadb_global_status_slow_queries{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serverRowsRead ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(rate(mariadb_global_status_rows_read{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serverRowsSent ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(rate(mariadb_global_status_rows_sent{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}
