import Logs from 'pages/Logs'

export default [
    {
        path: '/logs',
        name: 'logs',
        component: Logs,
        meta: {
            requiresAuth: true,
            title: 'Logs',
        },
    }
]
