
<template>
    <div>
        <div class="text-capitalize">
            {{ $t('configManager.createNewDatabaseConfig') }}
        </div>
        <configure-config />
    </div>
</template>
<script>
    import ConfigureConfigVue from 'components/configurationManager/ConfigureConfig.vue'

    export default {
        name: 'NewDatabaseConfiguration',
        components: {
            'configure-config': ConfigureConfigVue,
        },
    }
</script>
