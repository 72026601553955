<style lang="scss" scoped>
    circle {
        stroke: currentColor;
    }
    path {
        fill: currentColor;
    }
</style>

<template>
    <svg
        viewBox="0 0 32 32"
        fill="none">
        <circle
            cx="16"
            cy="16"
            r="11.3"
            stroke-width="1.4" />
        <path
            d="M11.8153 12.8074C11.8153 10.441 13.7652 8.52345 16.1669 8.52345C18.5687 8.52345 20.5186 10.441 20.5186 12.8074C20.5186
            15.1738 18.5687 17.0914 16.1669 17.0914C13.7632 17.0914 11.8153 15.1718 11.8153 12.8074ZM23.9936 23.8315C23.4424 20.7205 21.0801
            18.3154 18.0774 17.5891C20.0149 16.8384 21.3888 14.978 21.3888 12.8074C21.3888 9.97184 19.0472 7.66666 16.1668 7.66666C13.2864
            7.66666 10.9448 9.97388 10.9448 12.8074C10.9448 14.978 12.3187 16.8384 14.2542 17.5912C11.2536 18.3174 8.89333 20.7185 8.34005
            23.8294C8.2986 24.062 8.45816 24.2844 8.6944 24.3252C8.93063 24.366 9.1565 24.2089 9.19795 23.9763C9.7906 20.641 12.7207 18.2195
            16.1668 18.2195C19.6129 18.2195 22.5451 20.641 23.1357 23.9784C23.173 24.1864 23.3554 24.3333 23.5647 24.3333C23.5895 24.3333
            23.6144 24.3313 23.6393 24.3272C23.8755 24.2864 24.0351 24.0661 23.9936 23.8315Z" />
        <path
            d="M18.0774 17.5891L18.0052 17.4026L17.4096 17.6334L18.0304 17.7835L18.0774 17.5891ZM14.2542 17.5912L14.3012 17.7856L14.9204
            17.6357L14.3267 17.4048L14.2542 17.5912ZM8.34005 23.8294L8.53695 23.8645L8.53696 23.8645L8.34005 23.8294ZM9.19795
            23.9763L9.39485 24.0114L9.39486 24.0113L9.19795 23.9763ZM23.1357 23.9784L22.9388 24.0132L22.9388 24.0137L23.1357
            23.9784ZM23.6393 24.3272L23.6716 24.5246L23.6733 24.5243L23.6393 24.3272ZM12.0153 12.8074C12.0153 10.5545 13.8727 8.72345
            16.1669 8.72345V8.32345C13.6578 8.32345 11.6153 10.3276 11.6153 12.8074H12.0153ZM16.1669 8.72345C18.4612 8.72345 20.3186
            10.5545 20.3186 12.8074H20.7186C20.7186 10.3276 18.6761 8.32345 16.1669 8.32345V8.72345ZM20.3186 12.8074C20.3186 15.0603
            18.4612 16.8914 16.1669 16.8914V17.2914C18.6761 17.2914 20.7186 15.2873 20.7186 12.8074H20.3186ZM16.1669 16.8914C13.8707
            16.8914 12.0153 15.0584 12.0153 12.8074H11.6153C11.6153 15.2851 13.6556 17.2914 16.1669 17.2914V16.8914ZM24.1905 23.7966C23.625
            20.6045 21.2014 18.139 18.1244 17.3947L18.0304 17.7835C20.9587 18.4918 23.2598 20.8365 23.7967 23.8664L24.1905 23.7966ZM18.1497
            17.7756C20.16 16.9967 21.5888 15.0649 21.5888 12.8074H21.1888C21.1888 14.891 19.8699 16.6801 18.0052 17.4026L18.1497
            17.7756ZM21.5888 12.8074C21.5888 9.85844 19.1547 7.46666 16.1668 7.46666V7.86666C18.9397 7.86666 21.1888 10.0852 21.1888
            12.8074H21.5888ZM16.1668 7.46666C13.1789 7.46666 10.7448 9.86053 10.7448 12.8074H11.1448C11.1448 10.0872 13.394 7.86666 16.1668
            7.86666V7.46666ZM10.7448 12.8074C10.7448 15.0649 12.1736 16.9966 14.1817 17.7776L14.3267 17.4048C12.4638 16.6803 11.1448 14.891
            11.1448 12.8074H10.7448ZM14.2071 17.3968C11.1322 18.141 8.71083 20.6024 8.14314 23.7944L8.53696 23.8645C9.07583 20.8345 11.3749
            18.4938 14.3012 17.7856L14.2071 17.3968ZM8.14315 23.7944C8.08163 24.1396 8.31841 24.4632 8.66036 24.5222L8.72844 24.1281C8.59792
            24.1055 8.51558 23.9844 8.53695 23.8645L8.14315 23.7944ZM8.66036 24.5222C9.00306 24.5814 9.33375 24.3543 9.39485 24.0114L9.00105
            23.9412C8.97926 24.0635 8.8582 24.1505 8.72844 24.1281L8.66036 24.5222ZM9.39486 24.0113C9.97013 20.7738 12.8152 18.4195 16.1668
            18.4195V18.0195C12.6262 18.0195 9.61107 20.5081 9.00103 23.9413L9.39486 24.0113ZM16.1668 18.4195C19.5185 18.4195 22.3655 20.7738
            22.9388 24.0132L23.3327 23.9435C22.7247 20.5081 19.7074 18.0195 16.1668 18.0195V18.4195ZM22.9388 24.0137C22.9938 24.3202 23.2618
            24.5333 23.5647 24.5333V24.1333C23.4489 24.1333 23.3522 24.0527 23.3326 23.9431L22.9388 24.0137ZM23.5647 24.5333C23.6005 24.5333
            23.6362 24.5304 23.6716 24.5246L23.6069 24.1298C23.5926 24.1322 23.5785 24.1333 23.5647 24.1333V24.5333ZM23.6733 24.5243C24.0149
            24.4653 24.2519 24.1441 24.1906 23.7967L23.7967 23.8663C23.8182 23.9881 23.7361 24.1075 23.6052 24.1301L23.6733 24.5243Z" />
    </svg>
</template>

<script>
    export default {}
</script>
