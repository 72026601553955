import { CONFIG } from 'config/'
import i18n from 'plugins/i18n'

export const alerts = {
    unescapeTags (tags) {
        return tags.replace('(', '').replace('|.*)', '').replace(/\\/g, '')
    },
    cortexLabelsToTags (labels) {
        let sysTags = []
        let allTags = []
        for (const key of Object.keys(labels)) {
            const val = labels[key]

            if (key.includes(CONFIG.customTagPrefix)) {
                // Custom tags here
                allTags.push({
                    type: 'custom',
                    text: val,
                    cortexFormat: `${key}: ${val}`,
                })
            } else if (key === 'context') {
                // System tags here
                if (val === CONFIG.allTopologiesTag) {
                    allTags.push({
                        type: CONFIG.tagType.system,
                        text: i18n.t('anyServer'),
                        cortexFormat: `${key}: ${val}`,
                    })
                } else {
                    if(val){
                        sysTags = val.replace(/\/all/g, '/all').split(',')
                        sysTags.forEach(element => {
                            allTags.push({
                                type: CONFIG.tagType.system,
                                text: element,
                                cortexFormat: `${key}: ${element}`,
                            })
                        })
                    }
                }
            }
        }
        return allTags
    },
}
