<style lang="scss" scoped>
    path {
        fill: currentColor;
    }
</style>

<template>
    <svg viewBox="0 0 16 18">
        <path
            d="M 6.796875 0 C 5.8841972 0 5.1484375 0.7357636 5.1484375 1.6484375 L 5.1484375 2.2558594 L 0.6875 2.2558594 C 0.33257113
            2.2558594 0.052734375 2.5356942 0.052734375 2.890625 C 0.052734375 3.2455558 0.33257113 3.5234374 0.6875 3.5234375 L 1.9785156
            3.5234375 L 1.9785156 15.845703 C 1.9785156 17.037303 2.9412611 18 4.1328125 18 L 12.070312 18 C 13.261914 18 14.224609
            17.037303 14.224609 15.845703 L 14.224609 3.5234375 L 15.3125 3.5234375 C 15.667441 3.5234375 15.947266 3.2455558 15.947266
            2.890625 C 15.947266 2.5356942 15.643907 2.2558594 15.289062 2.2558594 L 11.029297 2.2558594 L 11.029297 1.6484375 C 11.029297
            0.7357636 10.293577 0 9.3808594 0 L 6.796875 0 z M 6.796875 1.2675781 L 9.40625 1.2675781 C 9.6090662 1.2675781 9.7871094
            1.4456213 9.7871094 1.6484375 L 9.7871094 2.2558594 L 6.4160156 2.2558594 L 6.4160156 1.6484375 C 6.4160156 1.4456213 6.5940491
            1.2675781 6.796875 1.2675781 z M 3.2207031 3.5234375 L 12.929688 3.5234375 L 12.929688 15.845703 L 12.957031 15.845703
            C 12.957031 16.327422 12.552033 16.732422 12.070312 16.732422 L 4.109375 16.732422 C 3.6276853 16.732422 3.2207031 16.327422
            3.2207031 15.845703 L 3.2207031 3.5234375 z M 7.9980469 5.3769531 L 7.6230469 5.5996094 L 6.5683594 6.2246094 L 5.1582031
            7.0683594 L 6.5683594 7.9121094 L 7.9980469 8.7617188 L 8 7.765625 C 9.1387829 7.7699728 10.192624 8.3691667 10.675781 9.4023438
            C 11.189779 10.501471 10.96592 11.790327 10.113281 12.660156 C 9.2607626 13.529863 7.9588847 13.790766 6.8339844 13.310547
            C 5.7088604 12.830232 5.0061901 11.71734 5.0566406 10.507812 C 5.0647333 10.312354 4.989013 10.12922 4.8671875 10.011719
            C 4.7453621 9.894217 4.5890512 9.8383995 4.4335938 9.8320312 C 4.2781363 9.8256631 4.1193542 9.8694669 3.9882812 9.9765625
            C 3.8572085 10.083658 3.7659052 10.259618 3.7578125 10.455078 C 3.6849628 12.201614 4.7079289 13.814747 6.3222656 14.503906
            C 7.9364357 15.192995 9.8165486 14.821458 11.042969 13.570312 C 12.269596 12.318956 12.592682 10.436363 11.851562 8.8515625
            C 11.15456 7.3610983 9.6340127 6.4926962 8 6.4863281 L 7.9980469 5.3769531 z " />
    </svg>
</template>

<script>
    export default {}
</script>
