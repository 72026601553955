<style lang="scss" scoped>
.std--dropdown.v-select {
    width: 250px !important;
}
.ctx-dropdown-label {
    font-size: 0.75rem;
}
</style>
<template>
    <div class="ml-auto d-flex align-center">
        <QueryCnfGearBtn />
        <MinMaxBtnCtr />
    </div>
</template>

<script>
    import { MinMaxBtnCtr, QueryCnfGearBtn } from 'mxs-query-editor'
    export default {
        name: 'ToolbarBtns',
        components: { QueryCnfGearBtn, MinMaxBtnCtr, },
    }
</script>
