<style lang="scss" scoped>
// https://stackoverflow.com/a/60552591
// To allow click events inside tooltip
.v-tooltip__content {
    pointer-events: initial;
}
</style>
<template>
    <v-tooltip
        open-delay="400"
        close-delay="2000"
        open-on-hover
        :max-width="300"
        :disabled="!isServiceReady"
        color="rgba(0, 53, 69, 0.9)"
        top>
        <template #activator="{ on }">
            <div v-on="on">
                <div v-if="allEndpointsAreSet">
                    <v-icon
                        size="20"
                        class="ml-2">
                        $vuetify.icons.endpointsHealthy
                    </v-icon>
                </div>
                <div v-else-if="minimumOneEndpointIsConfigured">
                    <v-icon
                        color="warning"
                        size="20"
                        class="ml-2">
                        $vuetify.icons.endpointsUnHealthy
                    </v-icon>
                </div>
                <div v-else>
                    <v-icon
                        color="error"
                        size="20"
                        class="ml-2">
                        $vuetify.icons.endpointsUnHealthy
                    </v-icon>
                </div>
            </div>
        </template>
        <div
            data-auto="service-status.dual-endpoints.tooltip.content"
            class="text-caption">
            <div v-if="hasSecondaryEP">
                <div
                    v-if="areBothEPAllowList"
                    data-auto="service-status.dual-endpoints.tooltip.content.multiple-ip-allowlist">
                    {{ $t('dualEndpoints.bothEPAsIPAllowList') }}
                </div>
                <div v-else>
                    <div data-auto="service-status.dual-endpoints.tooltip.content.primary-endpoint">
                        {{ $t('dualEndpoints.primaryEndpointType', {
                            type: isPrimaryEndpointPSC ?
                                privateConnectTextForDualEP : $t('dualEndpoints.ipAllowList')
                        }) }}
                    </div>
                    <div data-auto="service-status.dual-endpoints.tooltip.content.secondary-endpoint">
                        {{ $t('dualEndpoints.secondaryEndpointType', { type: $t('dualEndpoints.ipAllowList') }) }}
                    </div>
                </div>
            </div>
            <div
                v-else
                data-auto="service-status.dual-endpoints.tooltip.content.primary-endpoint">
                {{ $t('dualEndpoints.primaryEndpointType', {
                    type: isPrimaryEndpointPSC ? privateConnectTextForDualEP :
                        $t('dualEndpoints.ipAllowList')
                }) }}
            </div>
            <div>
                <div v-if="!hasSecondaryEP">
                    <a
                        v-if="allEndpointsAreSet"
                        data-auto="service-status.dual-endpoints.tooltip.content.more-info"
                        rel="noopener noreferrer"
                        class="text-capitalize"
                        :href="moreInfoDocLinkForPrimaryEndpoint"
                        target="_blank">
                        {{ $t('moreInfo') }}
                    </a>
                    <a
                        v-else
                        data-auto="service-status.dual-endpoints.tooltip.content.fix"
                        class="text-decoration-underline"
                        @click="$router.push('/settings/secure-access')">
                        {{ $t('dualEndpoints.fix') }}
                    </a>
                </div>
                <div v-else>
                    <a
                        v-if="allEndpointsAreSet"
                        data-auto="service-status.dual-endpoints.tooltip.content.moreInfo"
                        class="text-decoration-underline"
                        @click="$router.push('/settings/secure-access')">
                        {{ $t('moreInfo') }}
                    </a>
                    <a
                        v-if="!allEndpointsAreSet"
                        data-auto="service-status.dual-endpoints.tooltip.content.fix"
                        class="text-decoration-underline"
                        @click="$router.push('/settings/secure-access')">
                        {{ $t('dualEndpoints.fix') }}
                    </a>
                </div>
            </div>
        </div>
    </v-tooltip>
</template>

<script>
    import {
        privateConnectLabel,
        hasMultipleEndpoints,
        areBothSecureEndpointsAllowList,
        isPrimaryEndpointPrivateServiceConnect,
        getIpAllowList,
        doPrivateConnectHaveProjects,
        getPrivateConnectInstructionLink
    } from 'utils/service'

    const DualEndpointStatus = {
        name: 'DualEndpointStatus',
        props: {
            service: {
                required: true,
                type: Object,
            },
        },
        computed: {
            isServiceReady() {
                const { status, } = this.service
                return status === this.$config.serviceStatus.READY
            },
            havePrivateConnectProjects() {
                return doPrivateConnectHaveProjects(this.service.endpoints)
            },
            hasSecondaryEP() {
                return hasMultipleEndpoints(this.service.endpoints)
            },
            areBothEPAllowList() {
                return areBothSecureEndpointsAllowList(this.service.endpoints)
            },
            privateConnectTextForDualEP() {
                return privateConnectLabel(this.service.provider, true)
            },
            isPrimaryEndpointPSC() {
                return isPrimaryEndpointPrivateServiceConnect(this.service.endpoints)
            },
            allEndpointsAreSet() {
                const { endpoints, } = this.service
                // Only have a Single Endpoint
                // Check if it's set or not
                const hasProjects = this.isPrimaryEndpointPSC && this.havePrivateConnectProjects
                const hasIPWhiteListed = Boolean(getIpAllowList(endpoints).length)
                if (!this.hasSecondaryEP) {
                    return this.isPrimaryEndpointPSC ? hasProjects : hasIPWhiteListed
                } else if (this.areBothEPAllowList) {
                    return hasIPWhiteListed
                }
                return hasProjects && hasIPWhiteListed
            },
            minimumOneEndpointIsConfigured() {
                const { endpoints, } = this.service
                // Only have a Single Endpoint
                // Check if it's set or not
                const hasProjects = this.isPrimaryEndpointPSC && this.havePrivateConnectProjects
                const hasIPWhiteListed = Boolean(getIpAllowList(endpoints).length)
                return hasProjects || hasIPWhiteListed
            },
            moreInfoDocLinkForPrimaryEndpoint() {
                return this.isPrimaryEndpointPSC ? getPrivateConnectInstructionLink(this.service.provider) : 'https://mariadb.com/docs/skysql/security/firewalls/ip-allowlist-services'
            },
        },
    }
    export default DualEndpointStatus
</script>
