<style lang="scss">
@import "styles/main";

.old-portal-margin{
    .main-nav{
        margin-top: 120px;
        height: calc(100vh - 120px)!important;
    }

    .v-main {
        padding-top: 130px!important
    }
}

.no-banner-margin{
    .main-nav{
        margin-top: 64px;
        height: calc(100vh - 64px)!important;
    }
}

</style>

<template>
    <v-app :class="[isOldPortalBannerVisible ? 'old-portal-margin' : 'no-banner-margin']">
        <div v-if="!isPageLoading && authorized">
            <app-header
                :user="user"
                :tier="currentTier"
                :show-old-release-link="isOldPortalLinkVisible"
                :impersonated-user="impersonatedUser"
                @logoutUser="logout"
                @on-show-previous-release="showOldReleaseModal" />
            <PreviousReleaseBanner
                v-if="isOldPortalBannerVisible"
                @on-banner-dismiss="onOldReleaseBannerDismiss"
                @on-show-previous-release="showOldReleaseModal" />
            <navigation />
            <snackbars />
            <PreviousReleaseModal
                v-model="isPreviousReleaseModalVisible"
                @on-cancel-navigation="closeReleaseModal"
                @on-action="navigateToOldPortal" />
        </div>
        <overlay />

        <v-main>
            <v-container
                :class="['fill-height py-0', { 'pa-0 ma-0': !authorized }]"
                fluid>
                <v-row
                    v-if="!isPageLoading && authorized"
                    class="fill-height">
                    <router-view />
                </v-row>
                <v-row v-else>
                    <Initializing
                        v-if="redirectSSO"
                        :msg="$t('ssoRedirect')" />
                    <Initializing v-else-if="isFetchingToken" />
                    <Unauthorized v-else-if="!authorized" />
                </v-row>
            </v-container>
        </v-main>
        <app-footer />
    </v-app>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import Layouts from 'layouts'
    import Overlay from 'components/overlay/Index'
    import Unauthorized from 'pages/Unauthorized.vue'
    import Initializing from 'components/messages/Initializing.vue'
    import PreviousReleaseBanner from 'layouts/PreviousReleaseBanner.vue'
    import PreviousReleaseModal from 'components/common/PreviousReleaseModal.vue'
    import { setupEventSource } from 'services/serverSentEvents'
    import { isDevEnv, isTestEnv } from 'helpers'

    const redirectURI = `${window.location.origin}/login/oauth2/code/grant`

    export default {
        name: 'Stardust',
        components: {
            ...Layouts,
            overlay: Overlay,
            Unauthorized,
            Initializing,
            PreviousReleaseBanner,
            PreviousReleaseModal,
        },
        data(){
            return {
                isPageLoading: true,
                isPreviousReleaseModalVisible: false,
            }
        },
        computed: {
            ...mapGetters([
                'authToken',
                'user',
                'userData',
                'expiry',
                'currentTier',
                'isFetchingToken',
                'redirectSSO',
                'authorized',
                'isOldPortalBannerVisible',
                'orgData',
                'impersonatedUser'
            ]),
            isOldPortalLinkVisible() {
                return !this.isOldPortalBannerVisible && !this.isOrgCreatedAfter(this.$config.SKY1_BANNER_HIDDEN_AFTER)
            },
        },
        watch: {
            authorized(isAuth){
                if(!isAuth){
                    this.stopServicesInterval()
                    this.stopRefreshingAccessToken()
                    window.location.href = process.env.VUE_APP_LOGOUT_URL
                }
            },
        },
        methods: {
            ...mapActions([
                'startServicesInterval',
                'stopServicesInterval',
                'getRefreshToken',
                'refreshAccessTokenRecursively',
                'stopRefreshingAccessToken',
                'getToken',
                'setUser',
                'logoutUser',
                'ssoRedirect',
                'setOldPortalBannerVisibility',
                'fetchServiceTypes',
                'fetchTopologyDetails',
                'getInstanceSizes',
                'getMaxScaleInstanceSizes',
                'fetchAvailabilityZones'
            ]),
            shouldRedirectForAuth(code, state) {
                return !code && !state && !this.authToken
            },
            async logout() {
                await this.logoutUser()
                window.location.href = process.env.VUE_APP_LOGOUT_URL
            },
            isOrgCreatedAfter(date) {
                const { created_date: createdDate, } = this.orgData
                return this.$moment(createdDate * 1000).isAfter(date)
            },
            onOldReleaseBannerDismiss(){
                this.setOldPortalBannerVisibility(false)
                const { org_id: orgId, } = this.orgData
                localStorage.setItem(this.$config.SKY1_BANNER_KEY + orgId, false)
            },
            initOldBannerState(){
                const { org_id: orgId, } = this.orgData
                const bannerKey = this.$config.SKY1_BANNER_KEY + orgId
                const isVisible = localStorage.getItem(bannerKey) !== 'false' && !this.isOrgCreatedAfter(this.$config.SKY1_BANNER_HIDDEN_AFTER)
                localStorage.setItem(bannerKey, isVisible)
                this.setOldPortalBannerVisibility(isVisible)
            },
            showOldReleaseModal(){
                this.isPreviousReleaseModalVisible = true
            },
            navigateToOldPortal(){
                window.location.href = this.$config.OLD_PORTAL_URL
            },
            closeReleaseModal(){
                this.isPreviousReleaseModalVisible = false
            },
            initAppDependencies(){
                let dependencies = []
                // Prefetching Launch flow deps to speed up
                // the launch flow
                // This hook can be used to pre fetch other
                // services and cache
                if (this.$unleash.isFeatureEnabled('enable-portal-single-zone')) dependencies.push(this.fetchAvailabilityZones())
                dependencies.push(this.fetchServiceTypes())
                dependencies.push(this.fetchTopologyDetails())
                dependencies.push(this.getInstanceSizes())

                dependencies.push(this.getMaxScaleInstanceSizes(
                    { type: this.$config.INSTANCE_SIZE_TYPE.PROXY,}
                ))

                Promise.all(dependencies)
                if (this.$unleash.isFeatureEnabled('enable-portal-sse-for-service-progress')) this.connectSSE()
            },
            connectSSE() {
                setupEventSource(this.authToken)
            },
            async setupUnleash(){
                await this.$unleash.start()

                this.isPageLoading = false

                this.$unleash.on('update', () => {
                })

                // fallback when error returned from Unleash server
                // will rely on bootstrap or last fetched feature flags
                this.$unleash.on('error', () => {
                })
            },
            setupLogRocketUser(){
                const {user_id: id, first_name: firstName, last_name: lastName, email, employee, } = this.userData
                this.$LogRocket.identify( id,{
                    email,
                    name: `${firstName} ${lastName}`,
                    isEmployee: employee,
                })
            },
        },
        mounted() {
            document.title = this.$config.productName
        },
        async created() {
            window.appVersion = `v${process.env.VUE_APP_VERSION}`
            // eslint-disable-next-line no-console
            console.info(window.appVersion)


            const { code, state, } = this.$route.query

            if (code && state) {
                // If code and state are available
                // it means it has been redirected from ID server
                // using them get the token and proceed
                await this.getToken({ code, state, })

                // If able to get auth token
                // the user is marked authorized, allow
                // user to check portal
                if(this.authorized){
                    this.$router.push('/')
                }else{
                    // If the we're not able to get token
                    // from the state and then some issue from GW
                    // need to redirect to unauthorized view
                    this.$router.push('/unauthorized')
                    return
                }
            } else {
                // When a user hits portal url a regular
                // refreshToken call will be made to
                // update authToken into store as its in
                // browser memory
                // Also, In case a regular refresh done by user
                // just make a call to get new auth token
                await this.getRefreshToken()
            }

            // When a user hits the portal url first time no authToken present.
            // Also no redirection happened from ID server so no code and state
            // In such scenario redirect the user to id server
            if (this.shouldRedirectForAuth(code, state)) {
                this.ssoRedirect(true)
                const params = new URLSearchParams({
                    /* eslint-disable camelcase */
                    response_type: 'code',
                    state: this.$help.getRandomString(18, true),
                    client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
                    scope: 'mariadb email profile openid api_auth organization',
                    nonce: this.$help.getRandomString(32, true),
                    redirect_uri: redirectURI,
                /* eslint-enable */
                })

                const idUrl = process.env.VUE_APP_ID_URL

                const redirectURl = `${idUrl}?${params}`
                window.location.href = redirectURl
                return
            }

            if (this.authToken) {
                if (isDevEnv() || isTestEnv()) {
                    this.setupLogRocketUser()
                }
                this.initOldBannerState()
                // TODO:: Move the polling to respective
                // views/components rather on app starting point
                this.startServicesInterval(this.$config.fetchServicesInterval)
                this.setupUnleash()
                this.initAppDependencies()
                this.refreshAccessTokenRecursively(this.expiry)
            }
        },
        destroy () {
            // This is never called
            // Need to move this logic to the polling component as
            // and when we refactor above polling
            this.stopServicesInterval()
            this.stopRefreshingAccessToken()
            this.$unleash.stop()
        },
    }
</script>
