<template>
    <svg
        width="18"
        height="13"
        viewBox="0 0 18 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1697_20949)">
            <path
                d="M1 1H17V12H1V1Z"
                fill="#1E50A0" />
            <path
                d="M1 1H17V4.76316H1V1Z"
                fill="#D22F27" />
            <path
                d="M1 4.76318H17V8.23687H1V4.76318Z"
                fill="white" />
        </g>
        <rect
            x="0.5"
            y="0.5"
            width="17"
            height="11.6844"
            stroke="#D2D2D2" />
        <defs>
            <clipPath id="clip0_1697_20949">
                <rect
                    x="1"
                    y="1"
                    width="16"
                    height="10.6844"
                    fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
    export default {}
</script>
