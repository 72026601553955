<template>
    <div>
        <h2>Descriptive Analysis</h2>
        <p>The following metrics are likely to have consequential impacts to the workloads:</p>
        <ol>
            <li> <i>delta_Queries</i>: The difference in the number of queries executed between two consecutive time intervals.</li>
            <li><i>delta_Slow_queries</i>: The difference in the number of slow queries executed between two consecutive time intervals.</li>
            <li><i>delta_Connections</i>: The difference in the number of connections made between two consecutive time intervals.</li>
            <li><i>Open_tables</i>: The number of tables that are currently open in the database.</li>
            <li><i>Memory_used</i>: The amount of memory utilized by the database at a given time.</li>
        </ol>
        <div class="my-3">
            <b>The observed behavior from the actual_workload data:</b> Over the course of the given dates and times, we see larger than usual fluctuations in the metrics delta_Connections, delta_Queries, and delta_Slow_queries, while Open_tables and Memory_used see relatively smaller variations. The metrics have their highest peak at 2023-05-05 14:00:00 to 2023-05-05 16:00:00, with extreme differences in delta_Queries, delta_Slow_queries, and delta_Connections compared to previous hours.
        </div>
        <div class="font-weight-bold my-3">
            Comparing the observed and expected values with our machine learning model capturing normal behaviors, the top 2 novel anomalous metrics are:
        </div>
        <ul>
            <li>
                <i>Memory_used</i>: The observed behavior of Memory_used indicates a significant difference compared to the expected values produced by our machine learning model. While the expected values remain relatively stable, the actual values show more fluctuations, especially between 2023-05-05 12:00:00 and 2023-05-05 22:00:00, where we can see a sizable increase in the observed Memory_used metric, reaching as high as 4679334000 on 2023-05-05 13:00:00.
            </li>
            <li>
                <i>delta_Connections</i>: Delta_Connections also show notable deviations in their observed and expected values. While the expected values remain within the range of 100000 to 200000, the actual values vary significantly, reaching as high as 555192 on 2023-05-05 14:00:00.
            </li>
        </ul>

        <div class="my-3">
            <b>Considering the relationship between the top 2 anomalous metrics</b>: Memory_used and delta_Connections follow a similar trend during the period from 2023-05-05 12:00:00 to 2023-05-05 22:00:00, with both metrics experiencing dramatic increases during the interval. This suggests that periods with a high number of delta_Connections might cause the database's Memory_used to rise, potentially impacting the performance or stability of the system.
        </div>

        <div class="my-3">
            <b>Connections between the anomalies and database performance</b>: The dramatic increases in delta_Connections and extreme values of delta_Queries and delta_Slow_queries occurring at the same time as the increases in Memory_used suggest that there may be a relationship between these anomalies and the database's throughput. When higher than usual query workload or connection volume takes place, the database's resource utilization might be impacted, leading to decreased performance or efficiency, impacting database users' experience. However, it's worth noting that "anomalous" means "something has changed from what’s been seen in the past" rather than "worse," and it's important to further investigate these anomalies and their effects on the actual performance of the database.
        </div>
    </div>
</template>
<script>
    export default {
        name: 'DescriptiveAnalysis',
    }
</script>
