<template>
    <v-container
        class="ma-0 pa-12"
        fluid>
        <v-col cols="auto pa-0 wrapper-container">
            <SettingHeader :header-text="$t('userManagement')" />
            <user-team
                :users="usersList"
                :team-name="teamName" />
        </v-col>
    </v-container>
</template>

<script>
    import SettingHeader from 'pages/settings/SettingHeader.vue'
    import UserTeam from 'components/userManagement/UserTeam.vue'
    import { mapActions, mapGetters } from 'vuex'
    export default {
        name: 'UserManagement',
        components: {
            SettingHeader,
            UserTeam,
        },
        data() {
            return {
                usersList: [],
            }
        },
        computed: {
            ...mapGetters(['invites', 'roles', 'userData', 'users', 'usersLoading', 'inviteUserResponse', 'cancelInviteResponse', 'updateUserResponse', 'editUserResponse']),
            inviteAndUserChanges() {
                return `${this.inviteUserResponse}|${this.cancelInviteResponse}|${this.updateUserResponse}|${this.editUserResponse}`
            },
            teamName() {
                // TODO: Removing for now: DBAAS-11320, need to add logic later to include user name
                // return `${this.userData.first_name}\'s Team`
                return this.$t('team')
            },
        },
        watch: {
            inviteAndUserChanges: {
                handler(newVal) {
                    const arr = newVal.split('|')
                    if (!arr.every(item => item === JSON.stringify(null)))
                        this.fetchData()
                },
            },
        },
        methods: {
            ...mapActions(['getInvitesList', 'getRoles', 'getUsers']),
            listUsers() {
                let invitees = []
                this.invites.sort((a, b) => b.created_on - a.created_on)
                this.invites.forEach(invite => {
                    invitees = [...invitees, ...invite.invitees]
                })
                this.usersList = [...this.users, ...invitees]
                // Get unique invitees as per latest status
                this.usersList = this.$lodash.uniqBy(this.usersList, 'email')
            },
            async fetchData() {
                await this.getInvitesList()
                await this.getUsers()
                this.listUsers()
            },
        },
        async created() {
            await this.getRoles()
            this.fetchData()
        },
    }
</script>
