var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-wrapper text-body-2 info--text"},[_c('div',{class:[
            'nav-item pointer mb-2 px-4 py-2',
            { 'selected': _vm.currentTab === 'summary' }
        ],on:{"click":function($event){return _vm.handleClick('summary')}}},[_vm._v(" Summary ")]),_c('div',{class:[
            'nav-item pointer mb-2 px-4 py-2',
            { 'selected': _vm.currentTab === 'descriptive-analysis' }
        ],on:{"click":function($event){return _vm.handleClick('descriptive-analysis')}}},[_vm._v(" Descriptive Analysis ")]),_c('div',{class:[
            'nav-item pointer mb-2 px-4 py-2',
            { 'selected': _vm.currentTab === 'predictive-analysis' }
        ],on:{"click":function($event){return _vm.handleClick('predictive-analysis')}}},[_vm._v(" Predictive Analysis ")]),_c('div',{class:[
            'nav-item pointer mb-2 px-4 py-2',
            { 'selected': _vm.currentTab === 'prescriptive-analysis' }
        ],on:{"click":function($event){return _vm.handleClick('prescriptive-analysis')}}},[_vm._v(" Prescriptive Analysis ")])])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }