
export default {
    state: {
        newNotification: null,
    },
    mutations: {
        setNewNotification(state, payload) {
            state.newNotification = payload
        },
    },
    getters: {
        newNotification: (state) => state.newNotification,
    },
}
