<style lang="scss" scoped>
    .truncate-string {
        display: flex;
        > span {
            white-space: nowrap;
            overflow: hidden;
        }
    }
</style>

<template>
    <span
        ref="wrapper"
        class="truncate-string"
        :style="{ maxWidth: maxWidth }">
        <v-tooltip
            v-if="isTruncated"
            :disabled="!hasTooltip"
            top>
            <template #activator="{ on }">
                <span
                    ref="string"
                    v-on="on">
                    {{ text | truncateWidth(parseInt(maxWidth, 10), dotCount, singleCharWithInPx) }}
                </span>
            </template>
            <span>{{ text }}</span>
        </v-tooltip>
        <span
            v-else
            ref="string">{{ text }}</span>
    </span>
</template>

<script>
    export default {
        name: 'TruncateString',
        filters: {
            truncateWidth: function (value, maxWidth = 150, dotCount = 3, singleCharWithInPx) {
                if (!value) return ''

                const frontChars = ((maxWidth / singleCharWithInPx - dotCount) / 2).toFixed()
                const totalLength = frontChars * 2 + dotCount
                let truncatedStr = ''

                if (totalLength <= value.length) {
                    truncatedStr += value.substring(0, frontChars)
                    truncatedStr += '.'.repeat(dotCount)
                    truncatedStr += value.substring(value.length - frontChars + dotCount, value.length)
                    return truncatedStr
                } else {
                    return value
                }
            },
        },
        props: {
            text: { type: String, required: true, },
            maxWidth: { type: String, default: 'none', },
            dotCount: { type: Number, default: 3, },
            hasTooltip: { type: Boolean, default: true, },
            singleCharWithInPx: { type: Number, default: 7.5, },
        },
        data () {
            return {
                isTruncated: false,
            }
        },
        watch: {
            text: function () {
                this.checkTruncated()
            },
        },
        methods: {
            checkTruncated () {
                if (!this.$refs.string) return false
                const parentWidth = parseInt(this.maxWidth, 10)
                const stringWidthInPx = this.text.length * (this.singleCharWithInPx)
                this.isTruncated = stringWidthInPx > parentWidth
            },
        },
        mounted () {
            this.checkTruncated()
            // Fire check at the end of resize event
            let resizeId
            window.addEventListener('resize', () => {
                clearTimeout(resizeId)
                resizeId = setTimeout(this.checkTruncated(), 200)
            })
        },
    }
</script>
