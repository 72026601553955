<style lang="scss" scoped>
.service-name,
.manage-text {
    color: $links;
}

.opened {
    transform: rotate(90deg);
}

.entity-subtitle {
    font-size: 9px;
    color: $grayed-out;
}
</style>
<template>
    <div>
        <v-data-table
            :headers="headers"
            :loading="servicesLoading"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            :items="sortedServices"
            class="data-table-full"
            hide-default-footer>
            <template #item="{ item: service, index, expand, isExpanded }">
                <tr :key="index">
                    <td>
                        <v-icon
                            v-if="$unleash.isFeatureEnabled('enable-portal-dual-endpoints') && haveSecureAccessEndpoints(service)"
                            size="12"
                            :class="['color text-links expand-arrow mr-3', { opened: isExpanded }]"
                            @click="expand(!isExpanded)">
                            $vuetify.icons.arrowRight
                        </v-icon>
                        <v-icon
                            v-else-if="isSecureAccessAvailable(service)"
                            size="12"
                            :class="['color text-links expand-arrow mr-3', { opened: isExpanded }]"
                            @click="expand(!isExpanded)">
                            $vuetify.icons.arrowRight
                        </v-icon>
                        <span
                            v-else
                            class="mr-6"></span>
                        <span class="font-weight-medium service-name">{{ service.name }} </span>
                    </td>
                    <td>
                        <v-row>
                            <v-col cols="6">
                                <span
                                    v-if="$unleash.isFeatureEnabled('enable-portal-dual-endpoints')"
                                    class="font-weight-bold">{{ $t('dualEndpoints.primaryEndpoint') }}:</span>
                                <span>
                                    {{ secureAccessLabel(service) }}
                                </span>
                            </v-col>
                            <v-col cols="3">
                                <span v-if="!isServicePendingCreate(service)">
                                    <span
                                        v-if="isSecureAccessAvailable(service)"
                                        class="pointer manage-text font-weight-bold"
                                        @click="triggerManage(service)">
                                        {{ $t('manage') }}
                                    </span>
                                    <span
                                        v-else
                                        class="manage-text font-weight-bold">
                                        <SetupSecureAccessMenu
                                            :service="service"
                                            @on-setup-allowlist="setupAllowList"
                                            @on-setup-private-connect="setupPrivateConnect" />
                                    </span>
                                </span>
                            </v-col>
                            <v-col
                                v-if="$unleash.isFeatureEnabled('enable-portal-dual-endpoints')"
                                class="d-flex justify-center align-right">
                                <span
                                    v-if="!hasDualEndpoints(service)"
                                    class="pointer manage-text font-weight-bold"
                                    @click="addSecondaryEndpoint(service)">
                                    <span>{{ $t('add') }}</span> <span class="text-lowercase">{{
                                        $t('dualEndpoints.secondaryEndpoint') }}</span>
                                </span>
                            </v-col>
                        </v-row>
                    </td>
                </tr>
            </template>
            <template #expanded-item="{ item: service }">
                <template v-if="$unleash.isFeatureEnabled('enable-portal-dual-endpoints')">
                    <SecureAccessEndpoints
                        v-for="(entity, index) in getSecureAccessEndpoints(service)"
                        :key="service.id + '-' + (entity.label ? entity.label + index : entity.id + index)"
                        :service-id="service.id"
                        :entity="entity"
                        @on-secondary-endpoint-remove="removeSecondaryEndpoint" />
                </template>
                <template v-else>
                    <tr
                        v-for="(entity, index) in getEndpointsList(service)"
                        :key="entity.id + index"
                        class="expanded-item">
                        <td></td>
                        <td>
                            <v-row>
                                <v-col cols="5">
                                    <div
                                        v-if="index === 0"
                                        class="pt-2 pb-2 entity-subtitle">
                                        {{ getSubTitleText(service) }}
                                    </div>
                                    <div :class="[{ 'pb-2': index === 0 }]">
                                        {{ entity.id }}
                                    </div>
                                </v-col>
                                <v-col>
                                    <div
                                        v-if="index === 0"
                                        class="pt-2 pb-2 entity-subtitle">
                                        {{ $t('alias') }}
                                    </div>
                                    <div>
                                        {{ entity.alias }}
                                    </div>
                                </v-col>
                            </v-row>
                        </td>
                    </tr>
                </template>
            </template>
        </v-data-table>
        <WhitelistModal ref="whitelistModal" />
        <PrivateConnectModal />
        <RemoveEndpointPrompt
            :open-dialog="openRemoveEndpointPrompt"
            :fqdn="removeEndpointFQDN"
            @on-confirm-remove="onConfirmRemoval"
            @on-close-modal="onCancelRemoval" />
    </div>
</template>
<script>
    import {
        doPrivateConnectHaveProjects,
        getIpAllowList,
        getPrimaryEndpoint,
        getPrivateConnectProjects,
        getSecondaryEndpoint,
        hasMultipleEndpoints,
        isPrimaryEndpointPrivateServiceConnect,
        isServicePendingCreate,
        privateConnectEntityLabel,
        privateConnectLabel
    } from 'utils/service'
    import { mapGetters, mapActions } from 'vuex'
    import WhitelistModal from 'components/service/WhitelistModal.vue'
    import PrivateConnectModal from 'components/service/PrivateConnectModal.vue'
    import SetupSecureAccessMenu from 'components/settings/SetupSecureAccessMenu.vue'
    import SecureAccessEndpoints from 'components/settings/SecureAccessEndpoints.vue'
    import RemoveEndpointPrompt from 'components/service/RemoveEndpointPrompt.vue'

    export default {
        name: 'SecureAccessTable',
        components: { WhitelistModal, PrivateConnectModal, SetupSecureAccessMenu, SecureAccessEndpoints, RemoveEndpointPrompt, },
        data() {
            return {
                expanded: [],
                singleExpand: false,
                endpointRemoveServiceId: null,
                openRemoveEndpointPrompt: false,
                removeEndpointFQDN: null,
                secondaryEndpointName: null,
            }
        },
        computed: {
            ...mapGetters(['services', 'servicesLoading', 'getServiceById']),
            headers() {
                return [
                    { text: this.$tc('service', 1), value: 'services', width: '250px', sortable: false, },
                    { text: this.$t('secureAccessType'), sortable: false, }
                ]
            },
            sortedServices() {
                const clonedServices = [...this.services]
                clonedServices.sort((serviceA, serviceB) => serviceB.created_on - serviceA.created_on)
                return clonedServices
            },
        },
        methods: {
            ...mapActions(['openPrivateConnectModal', 'removeEndpoint', 'addEndpoint']),
            secureAccessType(service) {
                let hasIpAllowList = false
                let { endpoints, } = service
                if (this.$unleash.isFeatureEnabled('enable-portal-dual-endpoints')) {
                    // in case of dual endpoints always select the first one
                    endpoints = [getPrimaryEndpoint(endpoints)]
                }
                const hasPrivateConnect = doPrivateConnectHaveProjects(endpoints)
                const ipList = getIpAllowList(endpoints)

                if (ipList.length) {
                    hasIpAllowList = true
                }
                return { hasPrivateConnect, hasIpAllowList, }
            },
            secureAccessLabel(service) {
                const { hasPrivateConnect, hasIpAllowList, } = this.secureAccessType(service)

                if (hasPrivateConnect) return privateConnectLabel(service.provider, this.$unleash.isFeatureEnabled('enable-portal-dual-endpoints'))
                if (hasIpAllowList) return this.$t('allowList')
                return this.$t('secureAccessNotSetup')
            },
            getEndpointsList(service) {
                const { hasPrivateConnect, hasIpAllowList, } = this.secureAccessType(service)

                if (hasPrivateConnect) return getPrivateConnectProjects(service.endpoints, service.provider)

                if (hasIpAllowList) {
                    return getIpAllowList(service.endpoints).map(({ ip, comment, }) => ({ id: ip, alias: comment, }))
                }
            },
            getSubTitleText(service) {
                const { hasPrivateConnect, hasIpAllowList, } = this.secureAccessType(service)

                if (hasPrivateConnect) {
                    return privateConnectEntityLabel(service.provider)
                }

                if (hasIpAllowList) return this.$t('allowedIP')
            },
            isSecureAccessAvailable(service) {
                const { hasPrivateConnect, hasIpAllowList, } = this.secureAccessType(service)
                return hasPrivateConnect || hasIpAllowList
            },
            triggerManage(service) {
                const { hasPrivateConnect, hasIpAllowList, } = this.secureAccessType(service)

                if (hasPrivateConnect) {
                    return this.openPrivateConnectModal({ popupState: true, serviceId: service.id, })
                }

                if (hasIpAllowList) {
                    this.$refs.whitelistModal.open(service.id)
                }
            },
            setupAllowList(serviceId) {
                this.$refs.whitelistModal.open(serviceId)
            },
            setupPrivateConnect(serviceId) {
                this.openPrivateConnectModal({ popupState: true, serviceId, })
            },
            hasDualEndpoints(service) {
                return hasMultipleEndpoints(service.endpoints)
            },
            getSecureAccessEndpoints(service) {
                const { endpoints, provider, } = service
                // If only Primary is setup and PSC
                // if only Primary setup and AllowList
                // if primary is setup and PSC. Secondary is chosen
                // if primary is setup and allowlist, secondary is also setup by default
                // if primary and secondary are allowlist and not setup

                let endpointAttributes = []

                let privateConnectProjects = []
                let ipAllowList = []

                if (doPrivateConnectHaveProjects(endpoints)) {
                    privateConnectProjects = getPrivateConnectProjects(endpoints, provider)
                }

                ipAllowList = getIpAllowList(service.endpoints)
                const isPrimaryPSC = isPrimaryEndpointPrivateServiceConnect(endpoints)

                if (isPrimaryPSC) {
                    privateConnectProjects.length && privateConnectProjects.forEach(
                        (project, index) => {
                            let currentObj = {}
                            if (index === 0) {
                                currentObj.subText = 'primary'
                                currentObj.subTextLabel = privateConnectEntityLabel(provider)
                            }
                            currentObj = { ...currentObj, id: project.id, alias: project.alias, }
                            endpointAttributes.push(currentObj)
                        })
                } else {
                    ipAllowList.length && ipAllowList.forEach((ipObject, index) => {
                        let currentObj = {}
                        if (index === 0) {
                            currentObj.subText = 'primary'
                            currentObj.subTextLabel = this.$t('allowedIP')
                        }
                        currentObj = { ...currentObj, id: ipObject.ip, alias: ipObject.comment, }
                        endpointAttributes.push(currentObj)
                    })
                }

                if (hasMultipleEndpoints(endpoints)) {
                    if (!isPrimaryPSC) {
                        // Drop existing allowList when
                        // primary ep is allowlist itself
                        endpointAttributes = []
                    }

                    endpointAttributes.push({
                        type: 'title',
                        label: this.$t('dualEndpoints.secureIPAllowList'),
                    })

                    ipAllowList.forEach((ipObject, index) => {
                        let currentObj = {}
                        if (index === 0) {
                            currentObj.subText = 'secondary'
                            currentObj.subTextLabel = this.$t('allowedIP')
                        }
                        currentObj = { ...currentObj, id: ipObject.ip, alias: ipObject.comment, }
                        endpointAttributes.push(currentObj)
                    })
                }
                return endpointAttributes
            },
            removeSecondaryEndpoint(serviceId) {
                this.endpointRemoveServiceId = serviceId
                const service = this.getServiceById(serviceId)
                const secondaryEndpoint = getSecondaryEndpoint(service.endpoints)
                this.secondaryEndpointName = secondaryEndpoint.name
                this.removeEndpointFQDN = secondaryEndpoint.fqdn
                this.openRemoveEndpointPrompt = true
            },
            async onConfirmRemoval() {
                await this.removeEndpoint({ serviceId: this.endpointRemoveServiceId, endpointName: this.secondaryEndpointName, })
                this.resetDeleteEndpointState()
            },
            onCancelRemoval() {
                this.openRemoveEndpointPrompt = false
                this.resetDeleteEndpointState()
            },
            resetDeleteEndpointState() {
                this.endpointRemoveServiceId = null
                this.removeEndpointFQDN = null
                this.secondaryEndpointName = null
            },
            async addSecondaryEndpoint(service) {
                const endpointName = this.$help.getUniqueString(16)
                await this.addEndpoint({ serviceId: service.id, endpointName, })
            },
            haveSecureAccessEndpoints(service) {
                return this.isSecureAccessAvailable(service) || this.hasDualEndpoints(service)
            },
            isServicePendingCreate,
        },
        mounted() {
            // Default behavior is to show the table
            // fully expanded
            this.expanded = this.services
        },
    }
</script>
