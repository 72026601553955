import { t } from 'typy'

export function formatNum (number) {
    if (!t(number).isNumber) return number

    let toFixed = 0

    if (number < 100) toFixed = 1
    if (number < 10) toFixed = 2

    return number.toFixed(toFixed)
}
