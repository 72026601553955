import base from 'api/base'

const baseUrl = process.env.VUE_APP_API_GATEWAY_BASE_URL
const provisioningURI = 'provisioning/v1'

export default {
    get (uri, params, options) {
        return base.get(`${baseUrl}/${provisioningURI}/${uri}`, params, options)
    },
}
