<style lang="scss">
.service-select {
    width: 215px;
    .ctx-dropdown-label {
        font-size: 0.75rem;
    }
    .std--dropdown .std--dropdown-list .v-list-item {
        padding: 0px 12px;
    }
}
</style>

<template>
    <div class="d-flex flex-column ml-3 service-select">
        <label class="ctx-dropdown-label color text-small-text mb-1">
            {{ $tc("service", 1) }}
        </label>
        <v-select
            v-bind="{ ...$attrs }"
            item-text="name"
            item-value="id"
            return-object
            class="std std--dropdown"
            :menu-props="{
                bottom: true,
                offsetY: true,
                contentClass: 'std--dropdown-list',
            }"
            :placeholder="$t('selectService')"
            height="28"
            outlined
            attach
            single-line
            required
            hide-details
            v-on="$listeners">
            <template #selection="{ item }">
                <icon-sprite-sheet
                    class="mr-1"
                    :size="14"
                    :frame="serviceStatus(item)">
                    health
                </icon-sprite-sheet>
                <span class="text-truncate">{{ item.name }}</span>
            </template>
            <template #item="{ item }">
                <icon-sprite-sheet
                    class="mr-1"
                    :size="14"
                    :frame="serviceStatus(item)">
                    health
                </icon-sprite-sheet>
                <span class="text-truncate">{{ item.name }}</span>
            </template>
        </v-select>
    </div>
</template>

<script>
    export default {
        name: 'ServiceSelect',
        inheritAttrs: false,
        methods: {
            serviceStatus(service) {
                const { status, topologyStatus, } = service
                if (topologyStatus && status === this.$config.serviceStatus.READY) return topologyStatus
                return status
            },
        },
    }
</script>
