import organization from 'api/organization'

export async function listInvites() {
    return organization.get('invites').then((response) => response.data)
}

export async function createInvite(inviteArray) {
    return organization.put('invites', inviteArray).then((response) => response.data)
}

export async function getInvitesByInviteeId(inviteeId) {
    return organization.get(`invitees/${inviteeId}`).then((response) => response.data)
}

export async function removeInvite(inviteeId) {
    return organization.delete(`invitees/${inviteeId}`).then((response) => response.data)
}

export async function validateUsers(inviteArray) {
    return organization.post('invites/check', inviteArray).then((response) => response.data)
}
