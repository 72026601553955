<template>
    <svg
        width="10"
        height="14"
        viewBox="0 0 10 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 5.02801C0 2.25683 2.23693 0 5 0C7.76307 0 10 2.25683 10 5.02801C10 6.02698 9.69937 6.7864 9.20719 7.55887L5.15626 13.9168C5.13953 13.9423 5.11644 13.9633 5.08913 13.9778C5.06182 13.9924 5.03116 14 5 14C4.96884 14 4.93818 13.9924 4.91087 13.9778C4.88356 13.9633 4.86047 13.9423 4.84374 13.9168L0.792815 7.55887C0.30063 6.78642 0 6.02698 0 5.02801ZM5 7C6.10457 7 7 6.10457 7 5C7 3.89543 6.10457 3 5 3C3.89543 3 3 3.89543 3 5C3 6.10457 3.89543 7 5 7Z"
            fill="#0F79A6" />
    </svg>
</template>
<script>
    export default {}
</script>
