<template>
    <v-overlay
        :opacity="1"
        :absolute="false"
        class="overlay">
        <initializing-message class="mt-0" />
    </v-overlay>
</template>

<script>
    import InitializingMessage from 'components/messages/Initializing'

    export default {
        name: 'LoadOverlay',
        components: { 'initializing-message': InitializingMessage, },
    }
</script>
