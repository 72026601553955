import Vue from 'vue'
import { getFormatter } from 'formatters/index.js'

Object.defineProperties(Vue.prototype, {
    $format: {
        get () {
            return function(id, value, decimals) {
                const formatter = getFormatter(id)
                if (!formatter) return value
                const { text, prefix, suffix, } = formatter.fn(value, decimals)
                if (prefix) return prefix + text
                if (suffix) return text + suffix
                return text
            }
        },
    },
})
