<template>
    <svg
        width="18"
        height="13"
        viewBox="0 0 18 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1697_21304)">
            <path
                d="M0 1H17.3684V11.6452H0V1Z"
                fill="#5C9E31" />
            <path
                d="M15.1335 6.32897L8.6464 10.4274L2.23438 6.40405L8.72148 2.30566L15.1335 6.32897V6.32897Z"
                fill="#FCEA2B" />
            <path
                d="M8.68431 8.84371C10.0767 8.84371 11.2055 7.71492 11.2055 6.32249C11.2055 4.93006 10.0767 3.80127 8.68431 3.80127C7.29188 3.80127 6.16309 4.93006 6.16309 6.32249C6.16309 7.71492 7.29188 8.84371 8.68431 8.84371Z"
                fill="#1E50A0" />
            <path
                d="M10.9695 7.3821C11.0619 7.1805 11.1275 6.96765 11.1645 6.74899C10.9561 6.31348 10.6524 5.9305 10.2758 5.62849C9.89914 5.32647 9.45932 5.11318 8.98896 5.00445C8.5186 4.89572 8.02979 4.89435 7.55882 5.00044C7.08786 5.10652 6.64685 5.31734 6.26853 5.61724C6.21432 5.80085 6.18124 5.99004 6.16992 6.18115C6.54169 5.87619 6.97968 5.66247 7.44884 5.55711C7.918 5.45175 8.40531 5.45766 8.87178 5.57438C9.33825 5.6911 9.77092 5.91538 10.1352 6.22928C10.4994 6.54318 10.7852 6.93798 10.9695 7.3821V7.3821Z"
                fill="white" />
        </g>
        <rect
            x="0.5"
            y="0.5"
            width="17"
            height="11.6844"
            stroke="#D2D2D2" />
        <defs>
            <clipPath id="clip0_1697_21304">
                <rect
                    x="1"
                    y="1"
                    width="16"
                    height="10.6844"
                    fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
    export default {}
</script>
