<template>
    <div>
        <h2>Prescriptive Analysis</h2>
        <p>By following the proactive measures listed below, database users can ensure the system remains stable and performs optimally in the face of the predicted workload changes:</p>
        <ol>
            <li><b>Optimize memory allocation</b> by adjusting the global buffer sizes such as <i>innodb_buffer_pool_size</i>, <i>key_buffer_size</i>, and <i>aria_pagecache_buffer_size</i> according to the available system resources (RAM) and requirements of the specific database engine being used (1).</li>
            <li><b>Control connection limits</b> by monitoring the <i>threads_connected</i> and <i>max_connections</i> server variables, and adjusting the <i>max_connections</i> value if necessary. This can help avoid the <i>too_many_connections</i> error and maintain optimal performance during high connection volume (2).</li>
            <li><b>Implement connection caching</b> by setting a small, non-zero value for <i>thread_cache_size</i>. This can help reduce the overhead of connection creation and improve overall performance (1).</li>
            <li><b>Set binary log expiration</b> by configuring <i>expire_logs_days</i> to automatically remove older logs and prevent disk space from filling up (1).</li>
            <li><b>Monitor and address slow queries</b> by identifying bottlenecks and optimizing the problematic queries. This can help maintain system stability and performance during high query workload (2).</li>
        </ol>
        <div class="text-h6 my-2">
            SOURCES:
        </div>
        <ol>
            <li><a href="https://mariadb.com/kb/en/mariadb-memory-allocation/">https://mariadb.com/kb/en/mariadb-memory-allocation/</a></li>
            <li><a href="https://mariadb.com/kb/en/handling-too-many-connections/">https://mariadb.com/kb/en/handling-too-many-connections/</a></li>
        </ol>
    </div>
</template>
<script>
    export default {
        name: 'PrescriptiveAnalysis',
    }
</script>
