<style lang="scss" scoped>
.payment-option {
    border: 1px solid $cornsilk
}
</style>
<template>
    <div class="mt-8">
        <div v-if="!isFetchingInvoiceDeps">
            <v-row
                v-if="isRemittance"
                class="text-subtitle-1 payment-option pa-2">
                <v-col cols="8">
                    <div class="text-h6">
                        <span class="font-weight-bold">
                            {{ $t("billings.paymentMethod") }}:
                        </span>{{ $t('billings.remittance') }}
                    </div>
                    <div>{{ $t('billings.contactBillingAdmin') }}</div>
                </v-col>
            </v-row>
            <v-row
                v-else
                data-auto="invoice-summary"
                class="font-weight-bold text-subtitle-1 payment-option pa-2">
                <v-col cols="3">
                    <div
                        data-auto="payment-method"
                        class="text-h6 font-weight-bold">
                        {{ $t("billings.paymentMethod") }}
                    </div>
                    <div v-if="hasPaymentProfile">
                        {{ $t('billings.creditCard') }} {{ maskedCCNumber }}
                    </div>
                </v-col>
                <v-col>
                    <v-btn
                        outlined
                        large
                        rounded
                        color="primary"
                        class="px-8 text-subtitle-1"
                        @click="openPaymentDialog">
                        <span v-if="hasPaymentProfile"> {{ $t('billings.update') }}</span>
                        <span v-else>
                            <span class="pr-1">+</span> {{ $t('addNew') }}
                        </span>
                    </v-btn>
                </v-col>
                <v-col />
            </v-row>
            <div v-if="showPaymentDialog">
                <Payment
                    :payment-profile="paymentProfile"
                    :legal-entity-identifier="legalEntityIdentifier"
                    @on-update-profile="handleProfileUpdate"
                    @on-payment-close="handleProfileClose" />
            </div>
        </div>
        <div
            v-else
            class="text-center pt-3">
            <v-progress-circular
                size="70"
                width="7"
                color="primary"
                indeterminate />
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import Payment from 'components/billing/Payment.vue'

    export default {
        name: 'Invoice',
        components: { Payment, },
        data() {
            return {
                isFetchingInvoiceDeps: true,
                showPaymentDialog: false,
            }
        },
        computed: {
            ...mapGetters([
                'billingRecord',
                'paymentProfile',
                'isFetchingBillingRecord',
                'isFetchingPaymentDetail',
                'billingCountries'
            ]),
            hasPaymentProfile() {
                return Boolean(this.paymentProfile.id)
            },
            maskedCCNumber() {
                const splitCC = this.paymentProfile.maskedCardNumber.split('-')
                return '**** ' + splitCC.at(-1)
            },
            isRemittance() {
                if (this.billingRecord) return this.billingRecord.remittanceAccount
                return false
            },
            legalEntityIdentifier() {
                if (!this.billingRecord) return ''
                return this.billingRecord.legalEntityIdentifier || ''
            },
        },
        methods: {
            ...mapActions([
                'togglePaymentDialog',
                'fetchBillingRecord',
                'fetchPaymentProfile',
                'fetchCountries'
            ]),
            async openPaymentDialog() {
                if (this.$lodash.isEmpty(this.paymentProfile)) {
                    await this.fetchPaymentProfile()
                }
                this.showPaymentDialog = true
                if (this.$lodash.isEmpty(this.billingCountries)) {
                    await this.fetchCountries()
                } else {
                    // Minor delay is required to open the
                    // payment dialog for cached data
                    await this.$nextTick()
                }
                this.togglePaymentDialog(true)
            },
            handleProfileUpdate() {
                this.togglePaymentDialog(false)
                this.showPaymentDialog = false
                // This is cleaning up the custom
                // events set by Chargify
                // post updating, no clean method to
                // do till the Chargify supports it
                this.$router.go()
            },
            handleProfileClose() {
                this.togglePaymentDialog(false)
                this.showPaymentDialog = false
            },
        },
        async mounted() {
            if (this.$lodash.isEmpty(this.billingRecord)) {
                this.fetchBillingRecord()
            }
            if (this.$lodash.isEmpty(this.paymentProfile)) {
                await this.fetchPaymentProfile()
            }
            this.isFetchingInvoiceDeps = false
        },
    }
</script>
