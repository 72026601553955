<template>
    <svg
        width="18"
        height="13"
        viewBox="0 0 18 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1697_21085)">
            <path
                d="M0 0H21.2105V13H0V0Z"
                fill="#D22F27" />
            <path
                d="M0 6.5H21.2105V13H0V6.5Z"
                fill="#1E50A0" />
            <path
                d="M0 13L7.18421 8.55263H21.2105V4.44737H7.18421L0 0V13Z"
                fill="#5C9E31"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M5.13158 6.50015L0 9.92121V3.0791L5.13158 6.50015Z"
                fill="black"
                stroke="#F1B31C"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M0 0H21.2105V13H0V0Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
        </g>
        <rect
            x="0.5"
            y="0.5"
            width="17"
            height="11.6844"
            stroke="#D2D2D2" />
        <defs>
            <clipPath id="clip0_1697_21085">
                <rect
                    x="1"
                    y="1"
                    width="16"
                    height="10.6844"
                    fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
    export default {}
</script>
