<style lang="scss" scoped>
.table-wrapper {
    width: 100%;
    height: 100%;
    background: transparent;
    overflow-y: auto;
}

::v-deep tr:hover {
    cursor: pointer;
}

pre {
    white-space: break-spaces;
}

.query-wrapper {
    position: relative;
    background: $cornsilk;
}

.slow-query-detail-title {
    font-size: 1.875rem;
    color: $deep-ocean;
}
</style>

<template>
    <v-data-table
        class="table-wrapper row-pointer"
        :headers="fullOptions.headers"
        :items="items"
        hide-default-footer
        disable-pagination
        @click:row="(item) => onRowClick(item)">
        <template #footer>
            <base-dialog
                v-if="isSlowQueryMetric"
                v-model="isDialogOpen"
                :min-width="400"
                :max-width="1200">
                <template #title>
                    <div class="slow-query-detail-title font-weight-light">
                        {{ $t('slowQueryModalTitle') }}
                    </div>
                </template>
                <template #body>
                    <v-col cols="12">
                        <div class="mt-2 target-item-list">
                            <div>
                                <h3 class="my-2">
                                    {{ $tc('query', [1]) }}
                                </h3>
                                <pre class="overflow-auto query-wrapper pa-3">{{ currentSlowQueryLog.fullQuery }}</pre>
                            </div>
                            <div v-if="$typy(currentSlowQueryLog, 'explain').safeObject">
                                <h3 class="mt-4 mb-2">
                                    {{ $t('queryExplain') }}
                                </h3>
                                <SlowQueryDetails :data="currentSlowQueryLog.explain" />
                            </div>
                        </div>
                    </v-col>
                </template>
                <template #actions="{ cancel }">
                    <v-spacer />
                    <v-btn
                        color="primary"
                        depressed
                        rounded
                        outlined
                        class="cancel"
                        @click="cancel">
                        <span class="text-none">{{ $t('close') }} </span>
                    </v-btn>
                </template>
            </base-dialog>
        </template>
    </v-data-table>
</template>

<script>
    import SlowQueryDetails from 'components/monitoring/widgets/SlowQueryDetails.vue'
    const METRIC_TYPE_SLOW_QUERY = 'slowQuery'
    export default {
        name: 'TableList',
        components: { SlowQueryDetails, },
        props: {
            data: {
                type: Array,
                default: () => [],
            },
            options: {
                type: Object,
                default: () => { },
            },
        },
        data() {
            return {
                defaultOptions: {
                    hasFlatRows: false,
                    headers: [],
                },
                fullOptions: {},
                items: [],
                headers: [],
                isDialogOpen: false,
                currentSlowQueryLog: null,
            }
        },
        computed: {
            isSlowQueryMetric() {
                const { metricType, } = this.fullOptions
                return metricType && metricType === METRIC_TYPE_SLOW_QUERY
            },
        },
        watch: {
            data(newVal) {
                this.parseData(newVal)
            },
        },
        methods: {
            parseData(dataPoints) {
                this.headers = this.fullOptions.headers
                let items = []
                dataPoints.forEach(({ result, }) => {
                    let resultData = result
                    if (this.fullOptions.top)
                        resultData = resultData.slice(0, this.fullOptions.top)
                    resultData.forEach(row => {
                        let formattedRow = {}
                        // run formatting on each cell if applicable
                        this.headers.forEach((header) => {
                            const { format, } = header
                            const cellValue = format ? this.$format(format.type, row[header.value], format.decimals) : row[header.value]
                            formattedRow[header.value] = cellValue
                        })
                        const fullQuery = row.query
                        items.push({ ...row, ...formattedRow, fullQuery,})
                    })
                })
                this.items = items
            },
            onRowClick(item) {
                if (this.isSlowQueryMetric) {
                    this.currentSlowQueryLog = item
                    this.isDialogOpen = true
                }
            },
        },
        created() {
            this.fullOptions = this.$lodash.merge(this.defaultOptions, this.options)
            this.parseData(this.data)
        },
    }
</script>
