<template>
    <div>
        <v-row>
            <v-col
                v-if="defaultIopsType"
                class="mt-0 pt-0">
                <div class="pb-2">
                    {{ $tc('iops-volume-type') }}
                    <HelpToolTip>
                        <template #message>
                            <p>{{ storageTypeInfo }}</p>
                            <a
                                rel="noopener noreferrer"
                                class="text-capitalize"
                                target="_blank"
                                href="https://r.mariadb.com/snr-launch-aws-transactional-storage">{{ $t('learn-more') }}</a>
                        </template>
                    </HelpToolTip>
                </div>
                <v-radio-group
                    v-model="currentVolumeType"
                    class="mt-0"
                    row
                    @change="onVolumeTypeChange()">
                    <v-radio
                        v-for="(iops, index) in iopsTypes"
                        :key="index"
                        :label="iops.name"
                        :value="iops.value" />
                </v-radio-group>
            </v-col>
        </v-row>
        <v-row class="pt-0 mt-0">
            <v-col class="pt-0 mt-0 text-body-2">
                <p>{{ getDescription }}</p>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import HelpToolTip from 'components/common/HelpTooltip'
    export default {
        components: { HelpToolTip, },
        props: {
            defaultIopsType: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                currentVolumeType: this.defaultIopsType,
            }
        },
        computed: {
            iopsTypes() {
                const { io1, gp2, gp3, } = this.$config.iops
                let iopsTypes = [{
                    name: this.$t('iopsTypes.io1'),
                    value: io1,
                }]
                iopsTypes.unshift(this.isGP3Enabled ? {
                    name: this.$t('iopsTypes.gp3'),
                    value: gp3,
                } : {
                    name: this.$t('iopsTypes.gp2'),
                    value: gp2,
                })
                return iopsTypes
            },
            isIopsSliderDisabled() {
                return !this.isIO1Selected
            },
            isIO1Selected() {
                return this.currentVolumeType === this.$config.iops.io1
            },
            isGP3Enabled() {
                return this.$unleash.isFeatureEnabled('enable-portal-gp3-storage')
            },
            getDescription() {
                const { gp2, gp3, } = this.$config.iops
                return this.isIO1Selected ? this.$t('launchService.io1Description') : this.$t('launchService.gpDescription', { gpType: this.isGP3Enabled ? gp3 : gp2, })
            },
            storageTypeInfo() {
                const { gp2, gp3, } = this.$config.iops
                return this.$t('launchService.storageTypeInfo', { gpType: this.isGP3Enabled ? gp3 : gp2, })
            },
        },
        methods: {
            onVolumeTypeChange() {
                this.$emit('handleIopsTypeChange', this.currentVolumeType)
            },
        },
    }
</script>
