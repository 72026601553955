<template>
    <v-overlay z-index="203">
        <v-progress-circular
            indeterminate
            size="64" />
    </v-overlay>
</template>

<script>
    export default {}
</script>
