<style lang="scss" scoped>
    .opened {
        transform: rotate(90deg);
    }
</style>
<template>
    <v-container
        class="ma-0 pa-12"
        fluid>
        <v-col cols="auto pa-0 wrapper-container">
            <SettingHeader
                :header-text=" $t('firewall')"
                class="mb-10" />

            <v-data-table
                :headers="headers"
                :items="services"
                :loading="servicesLoading"
                :expanded.sync="expanded"
                :single-expand="singleExpand"
                sort-desc
                class="data-table-full"
                :no-data-text="noDataTxt"
                hide-default-footer>
                <template #item="{ item: service, expand, isExpanded }">
                    <tr
                        :key="service.index"
                        class="font-weight-medium"
                        @click="expand(!isExpanded)">
                        <td>
                            <v-icon
                                size="12"
                                :class="['color text-links expand-arrow mr-3', { opened: isExpanded }]">
                                $vuetify.icons.arrowRight
                            </v-icon>
                            {{ service.name }}
                        </td>
                        <td></td>
                    </tr>
                </template>
                <template #expanded-item="{ item: service }">
                    <tr
                        :colspan="headers.length"
                        class="grey lighten-5">
                        <td></td>
                        <td>
                            <Whitelist
                                :show-save-btn="true"
                                :service-id="service.id"
                                class=" pa-2" />
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-col>
    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex'
    import Whitelist from 'components/service/Whitelist.vue'
    import SettingHeader from './SettingHeader'

    export default {
        name: 'Firewall',
        components: { Whitelist,SettingHeader, },

        data () {
            return {
                headers: [
                    { text: this.$tc('service', 1), value: 'services', width: '200px', sortable: false, },
                    { text: 'IP AllowList', value: 'ipAllowList', width: '500px', sortable: false, }
                ],
                expanded: [],
                singleExpand: true,
                noDataTxt: '$vuetify.noDataText',
            }
        },
        computed: {
            ...mapGetters(['services', 'servicesLoading']),
        },
    }
</script>
