<template>
    <div class="text-center">
        <div v-if="count === null || !service.servers.length">
            {{ $t('errors.noData') }}
        </div>
        <div
            v-else
            :class="['text-h5 color', colorClass]">
            {{ count }}<span v-if="count >= limit">+</span>
        </div>
    </div>
</template>

<script>
    import als from 'api/als'

    // Temporary control for showing alert count for a given service
    // TODO: Extend the metrics layer and move as a widget
    export default {
        name: 'AlertsCount',
        props: {
            refreshInterval: {
                type: Number,
                default: 0,
            },
            service: {
                type: Object,
                default: () => ({}),
            },
            time: {
                type: Object,
                default: () => ({}),
            },
        },
        data() {
            return {
                count: null,
                limit: 99,
                interval: null,
            }
        },
        computed: {
            colorClass () {
                return this.count === 0 ? 'text-success' : 'text-warning'
            },
        },
        methods: {
            async fetchActiveAlerts () {
                if (!this.service.servers.length) return
                const { data, } = await als.get('alerts', {
                    limit: this.limit,
                    offset: 0,
                    firing: true,
                    serverContext: this.service.servers.reduce((prev, current) => `${this.service.id}/${current.name},${prev}`, ''),
                })
                this.count = data.count || 0
            },
            startRefreshInterval () {
                if (this.refreshInterval <= 0) return

                this.interval = setInterval(() => {
                    this.fetchActiveAlerts()
                }, this.refreshInterval * 1000)
            },
            stopRefreshInterval () {
                clearInterval(this.interval)
            },
        },
        async created () {
            this.fetchActiveAlerts()
            this.startRefreshInterval()
        },
        beforeDestroy () {
            this.stopRefreshInterval()
        },
    }
</script>
