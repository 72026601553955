<style lang="scss" scoped>
.control-label {
    font-size: 0.675rem;
}

.silence-banner {
    background-color: $light-yellow;
    border-radius: 7px;

    .v-icon {
        color: $inputs;
    }
}
</style>

<template>
    <div>
        <div
            v-if="isActiveSilencePresent"
            class="silence-banner text-body-2 pa-3 d-flex justify-center mb-3">
            <v-icon
                class="pr-1"
                size="24">
                $vuetify.icons.silencedAlert
            </v-icon>
            {{ bannerDescription }}
            <span
                class="color text-links pl-2 pointer"
                @click="cancelSilence">{{ $t('cancel') }}</span>
        </div>
        <div class="d-flex align-center justify-space-between mb-2">
            <div style="width: 328px">
                <label class="d-block color text-small-text control-label">
                    {{ $t('context') }}
                </label>
                <SelectServers
                    :preselected="preselectedServices"
                    :filter="allServiceAndServers"
                    @change="onContextChange" />
            </div>
            <div class="ml-2">
                <label class="d-block color text-small-text control-label">
                    {{ $t('time') }}
                </label>
                <date-range-picker
                    :has-custom-range="true"
                    @change="onTimeChange" />
            </div>
            <v-tooltip
                v-if="isSilencingEnabled"
                top>
                <template #activator="{ on }">
                    <v-icon
                        class="ml-auto pt-4"
                        size="28"
                        color="primary"
                        @click="silenceAlerts"
                        v-on="on">
                        $vuetify.icons.silencedAlert
                    </v-icon>
                </template>
                <span>{{ selectedService ? $t('silencing.onThisService') : $t('silencing.allAlerts') }}</span>
            </v-tooltip>
            <div
                v-else
                class="ml-auto pt-4"></div>
            <router-link
                class="settings ml-2 pt-4"
                to="/settings/policies">
                <v-tooltip top>
                    <template #activator="{ on }">
                        <v-icon
                            size="28"
                            color="primary"
                            v-on="on">
                            $vuetify.icons.settings
                        </v-icon>
                    </template>
                    <span>{{ $t('editAlerts') }}</span>
                </v-tooltip>
            </router-link>
        </div>

        <alerts-table
            :loading="alertsLoading"
            :alerts-total-count="alertsTotalCount"
            :alerts="alerts"
            :no-data-txt="$t('info.noAlerts')"
            :active-silence="activeSilence"
            @optionsChange="onOptionsChange" />
        <SilenceAlertsModal
            ref="silenceAlertsModal"
            :selected-service="selectedService" />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import AlertsTable from 'components/alerts/AlertsTable'
    import SelectServers from 'components/common/SelectServers'
    import SilenceAlertsModal from 'components/alerts/SilenceAlertsModal'
    import { findActiveSilence } from 'utils/alert'

    export default {
        name: 'Alerts',
        components: {
            'alerts-table': AlertsTable,
            SelectServers,
            SilenceAlertsModal,
        },
        props: {
            preselectedServices: { type: Array, default: () => [], },
        },
        data() {
            return {
                options: {},
                serverContext: [],
                dateRange: { from: 0, to: 0, },
                allServiceAndServers: [],
            }
        },
        computed: {
            ...mapGetters(['alerts', 'services', 'alertsLoading', 'alertsTotalCount', 'silences']),
            activeSilence() {
                // Check if there is any "all" type silence active first
                let foundActiveSilence = findActiveSilence(this.silences, this.$config.allTopologiesTag)
                // If not all and if there exists any service then check for same
                if (this.selectedService && !foundActiveSilence) foundActiveSilence = findActiveSilence(this.silences, `${this.selectedService.name}/all`)
                return foundActiveSilence
            },
            isActiveSilencePresent() {
                if (!this.silences.length) return false
                return !!this.activeSilence
            },
            untilDate() {
                if (!this.isActiveSilencePresent) return
                return this.$moment(this.activeSilence['end_time']).format('hh:mmA MM/DD/YYYY')
            },
            isSilencingEnabled() {
                return this.$unleash.isFeatureEnabled('enable-portal-silence-alerts') && !this.isActiveSilencePresent
            },
            selectedService() {
                const selectedId = this.$route.params.id
                return this.services.find(service => service.id === selectedId)
            },
            bannerDescription() {
                if (!this.activeSilence) return ''
                const suffix = this.activeSilence.context === this.$config.allTopologiesTag ? this.$t('silencing.onYourAccountUntil') : this.$t('silencing.onThisServiceUntil')
                return  `${suffix} ${this.untilDate}`
            },
        },
        methods: {
            ...mapActions(['fetchAlerts', 'fetchServices', 'stopAlertsInterval', 'startAlertsInterval', 'getSilences', 'deleteSilence']),

            onContextChange(servers) {
                this.serverContext = servers
                this.fetchAlertsWithParams()
            },
            onTimeChange(range) {
                this.dateRange = range
                this.fetchAlertsWithParams()
            },
            onOptionsChange(options) {
                this.options = options
                this.fetchAlertsWithParams()
            },
            async setAllServiceAndServers() {
                await this.fetchServices()

                const serverList = []
                this.services.forEach(({ name, servers, }) => {
                    servers.forEach(server => {
                        serverList.push({
                            server: server.name,
                            serverDataSourceId: server.dataSourceId,
                            service: name,
                        })
                    })
                })
                this.allServiceAndServers = serverList
            },
            generateOrderBy(sortBy, sortDesc) {
                if (!sortBy && !sortDesc) return
                let sort = ''
                for (let i = 0; i < sortBy.length; i++) {
                    sort += `${sortBy[i]}.${sortDesc[0] ? 'desc' : 'asc'}${sortBy.length > 1 ? ',' : ''}`
                }
                return sort
            },
            async fetchAlertsWithParams() {
                if (!this.serverContext.length) { return }

                const { sortBy, sortDesc, page, itemsPerPage, } = this.options
                const params = {
                    limit: itemsPerPage > 0 ? itemsPerPage : null,
                    offset: itemsPerPage > 0 ? (page - 1) * itemsPerPage : null,
                    fromDate: new Date(this.dateRange.from * 1000),
                    toDate: new Date(this.dateRange.to * 1000),
                    serverContext: this.serverContext.toString(),
                    orderBy: this.generateOrderBy(sortBy, sortDesc) || null,
                }

                await this.startAlertsInterval({ seconds: this.$config.fetchAlertsInterval, params, })
            },
            silenceAlerts() {
                this.$refs.silenceAlertsModal.open()
            },
            cancelSilence() {
                this.deleteSilence(this.activeSilence.id)
            },
        },
        created() {
            this.setAllServiceAndServers()
            this.getSilences()
        },
        destroy() {
            this.stopAlertsInterval()
        },
    }
</script>
