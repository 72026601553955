<template>
    <svg
        width="18"
        height="13"
        viewBox="0 0 18 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1697_20958)">
            <g clip-path="url(#clip1_1697_20958)">
                <path
                    d="M1 1H17V12H1V1Z"
                    fill="white" />
                <path
                    d="M1 1H17.3158V11H1V1Z"
                    fill="white" />
                <path
                    d="M9.15846 8.36819C10.4665 8.36819 11.5269 7.30781 11.5269 5.99977C11.5269 4.69173 10.4665 3.63135 9.15846 3.63135C7.85042 3.63135 6.79004 4.69173 6.79004 5.99977C6.79004 7.30781 7.85042 8.36819 9.15846 8.36819Z"
                    fill="#D22F27" />
            </g>
        </g>
        <rect
            x="0.5"
            y="0.5"
            width="17"
            height="11.6844"
            stroke="#D2D2D2" />
        <defs>
            <clipPath id="clip0_1697_20958">
                <rect
                    x="1"
                    y="1"
                    width="16"
                    height="10.6844"
                    fill="white" />
            </clipPath>
            <clipPath id="clip1_1697_20958">
                <rect
                    width="16"
                    height="10.6844"
                    fill="white"
                    transform="translate(1 1)" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
    export default {}
</script>
