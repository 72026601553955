import als from 'api/als'
import common from 'config/common'

export default {
    state: {
        rules: [],
        rulesError: false,
        rulesLoading: false,
    },
    mutations: {
        setRules(state, rules) {
            state.rules = rules
        },
        setRulesLoading(state, payload) {
            state.rulesLoading = payload
        },
        setRulesError(state, payload) {
            state.rulesError = payload
        },
    },
    actions: {
        async fetchRules({ commit, }) {
            commit('setRulesLoading', true)
            try {
                const { data, } = await als.get('rules')
                commit('setRules', data|| [])
                commit('setRulesError', false)
            } catch (error) {
                const message = error.message || 'Error fetching rules'
                commit('showMessage', { text: message, type: 'error', })
                commit('setRulesError', error)
            }
            commit('setRulesLoading', false)
        },
        async addRule({ commit, dispatch, }, rule) {
            commit('setRulesLoading', true)
            try {
                await als.post('rules', rule)
                await dispatch('fetchRules')

                commit('showMessage', { text: 'Policy successfully added', type: common.messageType.SUCCESS, })
            } catch (error) {
                const message = error.message || 'error add policy'

                commit('showMessage', { text: message, type: 'error', })
                commit('setRulesError', error)
            }
            commit('setRulesLoading', false)
        },
        async editRule({ dispatch, commit, }, rule) {
            commit('setRulesLoading', true)
            try {
                await als.put(`rules/${rule.id}`, rule)
                await dispatch('fetchRules')

                commit('showMessage', { text: 'Policy successfully updated', type: common.messageType.SUCCESS, })
            } catch (error) {
                const message = error.message || 'error edit policy'
                commit('showMessage', { text: message, type: 'error', })
                commit('setRulesError', error)
            }
            commit('setRulesLoading', false)
        },

        async deleteRules({ commit, dispatch, }, rules) {
            commit('setRulesLoading', true)
            try {
                await Promise.all(
                    rules.map(async (rule) => {
                        await als.delete(
                            `rules/${rule.id}`
                        )
                    })
                )
                commit('showMessage', { text: 'Policy successfully deleted', type: common.messageType.SUCCESS, })
                await dispatch('fetchRules')

            } catch (error) {
                const message = error.message || 'error delete policy'

                commit('showMessage', { text: message, type: 'error', })
                commit('setRulesError', error)
            }
            commit('setRulesLoading', false)
        },
    },
    getters: {
        rules: (state) => state.rules,
        rulesLoading: (state) => state.rulesLoading,
        rulesError: (state) => state.rulesError,
    },
}
