<template>
    <base-dialog
        v-model="isModalOpen"
        :min-width="400"
        :max-width="600"
        :on-close="closeModal"
        :on-cancel="closeModal"
        :on-save="removeEndpoint">
        <template #title>
            <p class="font-weight-regular text-h4 ma-0">
                {{ $t('dualEndpoints.removeEndpointPrompt') }}
            </p>
        </template>
        <template #body>
            <div class="pt-6">
                {{
                    $t('dualEndpoints.removeEndpointDisclaimer')
                }}
            </div>
            <div class="pt-4">
                <div class="font-weight-bold">
                    {{ $t('fullyQualifiedDomainName') }}
                </div>
                <div>
                    {{ fqdn }}
                </div>
            </div>
        </template>
        <template #actions="{ cancel, save }">
            <v-spacer />
            <v-btn
                color="primary"
                depressed
                rounded
                outlined
                class="cancel"
                @click="cancel">
                <span class="text-none">{{ $t('cancel') }} </span>
            </v-btn>
            <v-btn
                color="error"
                depressed
                rounded
                @click="save">
                <span class="text-none"> {{ $t("remove") }}</span>
            </v-btn>
        </template>
    </base-dialog>
</template>
<script>
    const RemoveEndpointPrompt = {
        name: 'RemoveEndpointPrompt',
        props: {
            openDialog: {
                type: Boolean,
                default: false,
            },
            fqdn: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                isModalOpen: this.openDialog,
            }
        },
        watch: {
            openDialog(val) {
                this.isModalOpen = val
            },
        },
        methods: {
            closeModal() {
                this.$emit('on-close-modal')
            },
            removeEndpoint() {
                this.$emit('on-confirm-remove')
            },
        },
    }
    export default RemoveEndpointPrompt
</script>
