<template>
    <svg
        width="24"
        height="39"
        viewBox="0 0 24 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M23.59 7.52979V25.3498C23.59 27.0198 22.48 28.6898 20.28 29.9598C17.802 31.2216 15.0607 31.8794 12.28 31.8794C9.49926 31.8794 6.75796 31.2216 4.28 29.9598C2.1 28.6898 1 27.0198 1 25.3498V7.52979"
            fill="#D9DAE0" />
        <path
            d="M23.59 7.52979V25.3498C23.59 27.0198 22.48 28.6898 20.28 29.9598C17.802 31.2216 15.0607 31.8794 12.28 31.8794C9.49926 31.8794 6.75796 31.2216 4.28 29.9598C2.1 28.6898 1 27.0198 1 25.3498V7.52979"
            stroke="#003545"
            stroke-width="0.8"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-dasharray="2 2" />
        <path
            d="M23.59 7.52959C23.5876 8.01221 23.496 8.49022 23.32 8.93959C22.7154 10.318 21.6566 11.4474 20.32 12.1396C17.842 13.4014 15.1007 14.0592 12.32 14.0592C9.53926 14.0592 6.79796 13.4014 4.32 12.1396C2.96621 11.4546 1.88928 10.3247 1.27 8.93959C1.09353 8.49035 1.00197 8.01225 1 7.52959C1 5.85959 2.1 4.18959 4.31 2.91959C6.78796 1.65777 9.52927 1 12.31 1C15.0907 1 17.832 1.65777 20.31 2.91959C22.48 4.18959 23.59 5.85959 23.59 7.52959Z"
            fill="white"
            stroke="#003545"
            stroke-width="0.8"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-dasharray="2 2" />
        <path
            d="M23.5898 33.0296V25.3496"
            stroke="#003545"
            stroke-width="0.8"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-dasharray="2 2" />
        <path
            d="M18.6895 37.2895V30.7295"
            stroke="#003545"
            stroke-width="0.8"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-dasharray="2 2" />
        <path
            d="M5.90039 37.2895V30.7295"
            stroke="#003545"
            stroke-width="0.8"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-dasharray="2 2" />
        <path
            d="M12.29 38.4396V31.8696"
            stroke="#003545"
            stroke-width="0.8"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-dasharray="2 2" />
        <path
            d="M1 33.0296V25.3496"
            stroke="#003545"
            stroke-width="0.8"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-dasharray="2 2" />
    </svg>
</template>
<script>
    export default {}
</script>
