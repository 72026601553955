export function maxScaleModules ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `maxscale_modules{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}`
    }
}

export function gtidServiceCurrentPosition ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(mariadb_global_variables_gtid_current_pos{mariadb="${name}", kubernetes_namespace="${id}"} UNLESS mariadb_global_variables_gtid_current_pos{gtid_server_id="", gtid_domain_id=""}) by (kubernetes_pod_name, gtid_domain_id, gtid_server_id)`
    }
}

export function gtidServiceMaxCurrentPosition ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `max(mariadb_global_variables_gtid_current_pos{mariadb="${name}", kubernetes_namespace="${id}"} UNLESS mariadb_global_variables_gtid_current_pos{gtid_server_id="", gtid_domain_id=""}) by (gtid_domain_id, gtid_server_id)`
    }
}

export function mdbSecondsBehindMaster ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(mariadb_slave_status_seconds_behind_master{mariadb="${name}", kubernetes_namespace="${id}", connection_name=""}) by (kubernetes_pod_name, connection_name) OR avg(mariadb_slave_status_seconds_behind_master{mariadb="${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name, connection_name)`
    }
}

export function mdbMasterLogPosition ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(mariadb_slave_status_exec_master_log_pos{mariadb="${name}", kubernetes_namespace="${id}", connection_name=""}) by (kubernetes_pod_name, connection_name) OR avg(mariadb_slave_status_exec_master_log_pos{mariadb="${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name, connection_name)`
    }
}

export function mdbReadMasterLogPosition ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(mariadb_slave_status_read_master_log_pos{mariadb="${name}", kubernetes_namespace="${id}", connection_name=""}) by (kubernetes_pod_name, connection_name) OR avg(mariadb_slave_status_read_master_log_pos{mariadb="${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name, connection_name)`
    }
}

export function mdbRelayLogPosition ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(mariadb_slave_status_relay_log_pos{mariadb="${name}", kubernetes_namespace="${id}", connection_name=""}) by (kubernetes_pod_name, connection_name) OR avg(mariadb_slave_status_relay_log_pos{mariadb="${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name, connection_name)`
    }
}
