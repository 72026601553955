<style lang="scss" scoped>
    .btn-container {
        .del-btn,
        .add-btn {
            height: 32px !important;
        }
    }
</style>

<template>
    <div class="d-flex justify-end mb-2">
        <div class="btn-container d-flex align-center">
            <v-tooltip
                v-if="selectedItems.length"
                top>
                <template #activator="{ on }">
                    <v-btn
                        outlined
                        rounded
                        color="error"
                        class="mt-1 del-btn text-capitalize"
                        :disabled="loading"
                        :loading="loading"
                        v-on="on"
                        @click="openDelModal">
                        {{ $t('delete') }}
                    </v-btn>
                </template>
                <span>{{ $t('deleteSelected') }}</span>
            </v-tooltip>
            <v-tooltip
                v-else
                top>
                <template #activator="{ on }">
                    <v-btn
                        outlined
                        rounded
                        color="primary"
                        class="mt-1 add-btn text-capitalize"
                        :disabled="loading || disableAdd"
                        v-on="on"
                        @click="$emit('add-new')">
                        {{ $t('add') }}
                    </v-btn>
                </template>
                <span>{{ $t('addNew') }}</span>
            </v-tooltip>
        </div>

        <alert-delete-modal
            ref="delModal"
            :title="deleteModalTitle"
            :sub-title="deleteModalsubTitle"
            :delete-msg="deleteModalMsg"
            :item-key-name="deleteModalItemKeyName"
            :on-delete="onDelete"
            :on-cancel="() => $emit('on-cancel-delete')" />
    </div>
</template>

<script>
    import AlertDeleteModal from 'components/alerts/AlertDeleteModal'

    export default {
        name: 'TableAction',
        components: {
            'alert-delete-modal': AlertDeleteModal,
        },
        props: {
            loading: { type: Boolean, default: false, },
            selectedItems: { type: Array, required: true, },
            deleteModalTitle: { type: String, default: '', },
            deleteModalsubTitle: { type: String, default: '', },
            deleteModalMsg: { type: String, default: '', },
            deleteModalItemKeyName: { type: String, default: '', },
            disableAdd: { type: Boolean, default: false, },
        },

        methods: {
            async onDelete () {
                await this.$emit('on-delete')
            },
            openDelModal () {
                this.$refs.delModal.open(this.selectedItems)
            },
        },
    }
</script>
