export function topSlowQueries(param) {
    return {
        ...param,
        limit: -1,
        offset: 0,
        orderByField: 'startTime',
        orderByDirection: 'desc',
        logType: ['slow-query-log'],
    }
}
