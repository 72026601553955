<template>
    <v-container
        class="ma-0 pa-12"
        fluid>
        <v-col
            cols="12"
            class="pa-0 ma-0 wrapper-container">
            <div class="mb-8 view-headline color text-navigation font-weight-light">
                {{ $t('ros.connectDb') }}
            </div>
            <v-row no-gutters>
                <v-col cols="7">
                    <div>
                        <p>
                            {{ $t('ros.descriptionLine1') }}
                        </p>
                        <i18n
                            path="ros.descriptionLine2"
                            tag="p">
                            <a
                                place="doc"
                                :href="$config.ROS_AGENT_MANAGER_DOCS">{{ $t('ros.agentManagerDoc') }}</a>
                        </i18n>
                    </div>
                    <RosQuickStart v-if="showQuickStart" />
                    <RosConnectInstructions />
                </v-col>
                <v-col
                    cols="3"
                    class="pl-8">
                    <div class="d-flex mb-8">
                        <v-icon
                            size="24"
                            class="mt-1 mr-3">
                            $vuetify.icons.skybook
                        </v-icon>
                        <div>
                            <div class="mb-2 ft-adrianna text-h5">
                                {{ $t('ros.obsDocumentation') }}
                            </div>
                            <a
                                :href="$config.ROS_AGENT_MANAGER_DOCS"
                                target="_blank">{{ $t('ros.readDocumentation') }}</a>
                        </div>
                    </div>
                    <div class="d-flex">
                        <v-icon
                            size="28"
                            class="mt-1 mr-2">
                            $vuetify.icons.user
                        </v-icon>
                        <div>
                            <div class="mb-2 ft-adrianna text-h5">
                                {{ $t('ros.inviteTeam') }}
                            </div>
                            <a
                                class="text-decoration-underline"
                                @click.prevent="$router.push({ name: 'User Management' })">{{ $t('ros.inviteTeamMember') }}</a>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-col>
    </v-container>
</template>

<script>
    import RosQuickStart from 'components/monitoring/RosQuickStart.vue'
    import RosConnectInstructions from 'components/monitoring/RosConnectInstructions.vue'

    export default {
        name: 'RosOnboarding',
        components: {
            RosQuickStart,
            RosConnectInstructions,
        },
        data: function() {
            return {
                showQuickStart: false,
            }
        },
    }
</script>
