import { render, staticRenderFns } from "./IopsAndThroughputSelection.vue?vue&type=template&id=956b0ade&scoped=true&"
import script from "./IopsAndThroughputSelection.vue?vue&type=script&lang=js&"
export * from "./IopsAndThroughputSelection.vue?vue&type=script&lang=js&"
import style0 from "./IopsAndThroughputSelection.vue?vue&type=style&index=0&id=956b0ade&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "956b0ade",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/skysql-ui-client/skysql-ui-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('956b0ade')) {
      api.createRecord('956b0ade', component.options)
    } else {
      api.reload('956b0ade', component.options)
    }
    module.hot.accept("./IopsAndThroughputSelection.vue?vue&type=template&id=956b0ade&scoped=true&", function () {
      api.rerender('956b0ade', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/launchService/IopsAndThroughputSelection.vue"
export default component.exports