<style scoped lang="scss">
:deep(.v-input__append-inner) {
    margin: 0 !important;
}

.custom-table {
    border: 1px solid $cornsilk;

    :deep(th) {
        color: $navigation !important;
        font-weight: 700;
    }
}
</style>
<template>
    <v-data-table
        :headers="headers"
        :items="replications"
        sort-desc
        class="custom-table"
        :disable-pagination="true"
        :hide-default-footer="true">
        <template #item="{ item: replication, index }">
            <tr :key="index">
                <td class="pl-3">
                    {{ $t(replication.direction) }}
                </td>
                <td class="pl-3">
                    <v-icon
                        :color="getColorByStatus(replication.service.status)"
                        :size="10">
                        mdi-circle
                    </v-icon> {{ replication.service.name }}
                </td>
                <td class="pl-3">
                    {{ getLagData(replication) }}
                </td>
            </tr>
        </template>
    </v-data-table>
</template>
<script>
    import { getColor, getLag } from 'utils/replication'
    import { mapGetters } from 'vuex'
    export default {
        name: 'ReplicationTable',
        props: {
            service: {
                type: Object,
                required: true,
            },
            replications: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                headers: [
                    { text: this.$t('globalXpand.direction'), value: 'direction', width: '100px', sortable: false, },
                    { text: this.$tc('service', 1), value: 'service', width: '200px', sortable: false, },
                    { text: this.$tc('globalXpand.lag'), value: 'lag', width: '80px', sortable: false, }
                ],
            }
        },
        computed: {
            ...mapGetters(['services', 'getServiceById', 'topologiesData']),
        },
        methods: {
            getColorByStatus(status) {
                return getColor(status)
            },
            getLagData(replica) {
                return getLag(this.topologiesData, replica, this.service)
            },
        },
    }
</script>
