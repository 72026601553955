<style lang="scss" scoped>
    .monitoring-nav-wrapper {
        width: 250px;
    }
    .monitoring-nav-item {
        border: 1px solid $cornsilk;
        border-radius: 8px;
        &.selected {
            background: $cornsilk;
            color: $navigation;
            font-weight: 700;
        }
    }
</style>

<template>
    <div class="monitoring-nav-wrapper text-body-2 info--text">
        <div
            :class="[
                'monitoring-nav-item pointer mb-2 px-4 py-2',
                { 'selected': $route.path.includes('overview') }
            ]"
            @click="handleTabChange(`/service/${$route.params.id}/monitoring/overview`)">
            {{ $t('serviceOverview') }}
        </div>

        <div
            v-for="({ id, name, serverStatus, metadata = {}, }, i) in service.servers"
            :key="i"
            :class="[
                'monitoring-nav-item pointer mb-2 px-4 py-2',
                { 'error--text': serverStatus === 'CRITICAL' },
                { 'selected': id === server.id }
            ]"
            @click="handleTabChange(`/service/${$route.params.id}/monitoring/${id}`)">
            <truncate-string
                max-width="220px"
                :text="name"
                :dot-count="3"
                :single-char-with-in-px="7" />
            <div>
                <icon-sprite-sheet
                    :frame="serverStatus"
                    :size="serverIconSize"
                    class="status-icon">
                    server
                </icon-sprite-sheet>
                <icon-sprite-sheet
                    v-if="metadata.primaryReplicaRole"
                    :frame="metadata.primaryReplicaRole.toUpperCase()"
                    :size="serverIconSize"
                    class="status-icon ml-2">
                    server
                </icon-sprite-sheet>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Navigation',
        props: {
            service: {
                type: Object,
                default: () => ({}),
            },
            server: {
                type: Object,
                default: () => ({}),
            },
        },
        data () {
            return {
                serverIconSize: 18,
            }
        },
        methods: {
            handleTabChange (newPath) {
                this.$router.push(newPath)
            },
        },
    }
</script>
