<style lang="scss" scoped>
.current-ip {
    background: $pale-yellow;
}

.not-recommended {
    color: $error
}
</style>
<template>
    <v-expansion-panel
        id="securityPanel"
        :disabled="isDisabled"
        :data-auto="dataAutoPrefix()">
        <AccordianPanelHeader
            :title="$t('launchService.security')"
            :parent-data-auto-prefix="dataAutoPrefix"
            :show-tool-tip="false" />
        <v-expansion-panel-content :data-auto="dataAutoPrefix('content')">
            <v-radio-group
                v-if="isPscSupported"
                v-model="whitelistOption"
                class="pb-0 mb-0"
                @change="onServiceWhiteListOptionSelection">
                <v-radio :value="$config.IP_WHITELIST">
                    <template #label>
                        <div>
                            <span class="mr-2">{{ $t('launchService.allowWhiteListFromOtherIPs') }}</span>
                            <HelpTooltip>
                                <template #message>
                                    <p>{{ $t('launchService.whitelistInfo') }}</p>
                                    <a
                                        rel="noopener noreferrer"
                                        class="text-capitalize"
                                        target="_blank"
                                        href="https://mariadb.com/docs/skysql/security/firewalls/ip-allowlist-services/">{{
                                            $t('learn-more')
                                        }}</a>
                                </template>
                            </HelpTooltip>
                        </div>
                    </template>
                </v-radio>
                <v-checkbox
                    v-model="isCurrentIpSelected"
                    :disabled="whitelistOption !== $config.IP_WHITELIST"
                    class="pt-0 mt-0 pl-8"
                    @change="useCurrentIP">
                    <template #label>
                        <div>
                            {{ $t('launchService.addCurrentIp') }}
                            <span v-if="whitelistOption === 'ip-whitelist'">
                                : <span class="current-ip pl-2">{{ currentIp }}</span>
                            </span>
                        </div>
                    </template>
                </v-checkbox>
                <v-radio :value="$config.PSC">
                    <template #label>
                        <span class="mr-2">{{ textForPrivateServiceConnect }} </span>
                        <HelpTooltip>
                            <template #message>
                                <p>{{ $t('launchService.privateConnectInfo', { connectionName: privateConnectType }) }}</p>
                                <a
                                    rel="noopener noreferrer"
                                    class="text-capitalize"
                                    target="_blank"
                                    :href="privateConnectLearnMoreLink">{{
                                        $t('learn-more')
                                    }}</a>
                            </template>
                        </HelpTooltip>
                    </template>
                </v-radio>
            </v-radio-group>
            <!-- For Serverless this should be only one option to whitelist -->
            <v-checkbox
                v-else-if="isServerless"
                v-model="isCurrentIpSelected"
                class="ml-3 pt-0 mt-5"
                @change="useCurrentIP">
                <template #label>
                    <div>
                        {{ $t('launchService.allowWhiteList') }}
                        <span v-if="whitelistOption === $config.IP_WHITELIST">
                            : <span class="current-ip pl-2 mr-3">{{ currentIp }}</span>
                        </span>
                        <HelpTooltip>
                            <template #message>
                                <p>{{ $t('launchService.whitelistInfo') }}</p>
                                <a
                                    rel="noopener noreferrer"
                                    class="text-capitalize"
                                    target="_blank"
                                    href="https://mariadb.com/docs/skysql/security/firewalls/ip-allowlist-services/">{{
                                        $t('learn-more')
                                    }}</a>
                            </template>
                        </HelpTooltip>
                    </div>
                </template>
            </v-checkbox>
            <v-row
                v-if="hasSsl"
                :class="['mt-1', { 'mt-4': !isPscSupported }]"
                :data-auto="dataAutoPrefix('content-ssl-check')">
                <v-checkbox
                    v-model="isSSLDisabled"
                    class="ml-3 pt-0 mt-0"
                    @change="onChangeSSLToggle">
                    <template #label>
                        <div>
                            <span class="mr-3">{{ $t('launchService.disable-ssl') }}</span>
                            <HelpTooltip>
                                <template #message>
                                    <p>{{ $t('launchService.disableSSLInfo') }}</p>
                                    <a
                                        rel="noopener noreferrer"
                                        class="text-capitalize"
                                        target="_blank"
                                        href="https://r.mariadb.com/nr-disable-ssltls">{{
                                            $t('learn-more')
                                        }}</a>
                                </template>
                            </HelpTooltip>
                            <span class="not-recommended pl-2 font-italic">
                                {{ $t('launchService.notRecommended') }}
                            </span>
                        </div>
                    </template>
                </v-checkbox>
            </v-row>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>
<script>
    import to from 'await-to-js'
    import { getPrivateConnectInstructionLink, isProviderGCP } from 'utils/service'
    import AccordianPanelHeader from 'components/launchService/AccordianPanelHeader.vue'
    import HelpTooltip from 'components/common/HelpTooltip.vue'
    export default {
        name: 'SecuritySelection',
        components: { AccordianPanelHeader, HelpTooltip, },
        props: {
            isSSlEnabled: Boolean,
            parentDataAutoPrefix: {
                type: Function,
                default: (string) => string,
            },
            isDisabled: {
                type: Boolean,
                default: true,
            },
            hasSsl: {
                type: Boolean,
                default: true,
            },
            providerType: {
                type: String,
                required: true,
            },
            isPscSupported: {
                type: Boolean,
                required: true,
            },
            isServerless: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            return {
                whitelistOption: this.$config.IP_WHITELIST,
                isSSLDisabled: !this.isSSlEnabled,
                currentIp: null,
                isCurrentIpSelected: false,
            }
        },
        computed: {
            textForPrivateServiceConnect() {
                return this.$i18n.t('launchService.enablePSC', { pscType: this.privateConnectType, })
            },
            privateConnectLearnMoreLink() {
                return getPrivateConnectInstructionLink(this.providerType)
            },
            privateConnectType(){
                return isProviderGCP(this.providerType) ? this.$i18n.t('privateConnect.privateServiceConnect') : this.$i18n.t('privateConnect.privateLink')
            },
        },
        watch: {
            isSSlEnabled(val) {
                this.isSSLDisabled = !val
            },
        },
        methods: {
            dataAutoPrefix(string) {
                return this.parentDataAutoPrefix(
                    `securityPanel${string ? '-' + string : ''}`
                )
            },
            onChangeSSLToggle(value) {
                this.$emit('onSSLToggle', value)
            },
            onServiceWhiteListOptionSelection() {
                let whitelistOptions = []
                if (this.whitelistOption === this.$config.PSC) {
                    whitelistOptions.push({ name: this.whitelistOption, })
                } else {
                    whitelistOptions.push({ name: this.whitelistOption, value: this.isCurrentIpSelected ? this.getAPISupportedIP() : null, })
                }
                this.$emit('on-service-security-select', whitelistOptions)
            },
            useCurrentIP() {
                this.$emit('on-service-security-select', [{ name: this.whitelistOption, value: this.isCurrentIpSelected ? this.getAPISupportedIP() : null, }])
            },
            getAPISupportedIP() {
                // appending /32 to support as per API needs
                // to properly whitelist single IP rather than range
                return `${this.currentIp}/32`
            },
        },
        async mounted() {
            const [error, response] = await to(this.axios.get(process.env.VUE_APP_CLIENT_IP_API))
            if (!error) this.currentIp = response.data.trim()
        },
    }
</script>
