<template>
    <v-tooltip top>
        <template #activator="{ on }">
            <div
                class="d-inline"
                v-on="on">
                <v-icon
                    v-if="data.isPrivate"
                    color="primary"
                    :size="size">
                    mdi-dns
                </v-icon>
                <icon-sprite-sheet
                    v-else
                    :size="size"
                    :frame="provider">
                    cloud
                </icon-sprite-sheet>
            </div>
        </template>
        <div>
            {{ $t(data.label) }}
        </div>
    </v-tooltip>
</template>

<script>
    export default {
        name: 'CloudProvider',
        props: {
            provider: {
                type: String,
                default: '',
            },
            size: {
                type: Number,
                default: 24,
            },
        },
        computed: {
            data () {
                const data = {
                    label: '',
                    isPrivate: false,
                }

                switch (this.provider) {
                    case 'gcp':
                    case 'aws':
                        data.label = this.provider
                        data.icon = this.provider
                        break
                    default:
                        data.label = 'private'
                        data.isPrivate = true
                }

                data.label = `cloudProvider.${data.label}`

                return data
            },
        },
    }
</script>
