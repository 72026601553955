<template>
    <div>
        <div class="text-center">
            <v-progress-circular
                v-if="allowlistLoading"
                indeterminate
                size="40" />
        </div>
        <v-form
            v-if="list.length"
            ref="form"
            v-model="valid"
            class="ma-3"
            @submit.prevent>
            <div
                v-for="(element, index) in list"
                :key="index"
                class="d-flex">
                <v-text-field
                    :ref="`ipRef-${index}`"
                    v-model="list[index].ip"
                    :rules="rules.ipAddress"
                    class="std mb-2 v-text-field--with-bottom-details mr-2"
                    :placeholder="$t('enterIpAddress')"
                    height="36"
                    single-line
                    outlined
                    hide-details="auto"
                    @blur="onBlur($event)" />
                <v-text-field
                    v-model="list[index].comment"
                    class="std mb-2 v-text-field--with-bottom-details"
                    :placeholder="$t('alias')"
                    height="36"
                    single-line
                    outlined
                    hide-details="auto" />
                <div
                    v-if="index !== list.length-1"
                    @click="deleteRow(index)">
                    <v-icon
                        :size="20"
                        class="color text-links icon mt-2 ml-2 pointer">
                        mdi-close
                    </v-icon>
                </div>
                <div
                    v-else
                    class="ml-7">
                    <span v-show="false">{{ hasChanged }}</span>
                </div>
            </div>
            <span
                class="pl-2 pointer text-caption color text-links text-decoration-underline"
                @click="addIpAddress">
                {{ $t('addMyCurrentIAddress') }}
            </span>
            <v-progress-circular
                v-if="fetchingUserIp"
                indeterminate
                size="12" />
        </v-form>
        <div
            v-if="showSaveBtn"
            class="d-flex justify-end">
            <v-btn
                color="primary"
                rounded
                :disabled="!hasChanged"
                :loading="allowlistUpdatingAddress"
                @click="save">
                <span class="text-none">{{ $t('save') }}</span>
            </v-btn>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'

    export default {
        name: 'WhitelistModal',
        props: {
            serviceId: { type: String, required: true, },
            showSaveBtn:  { type: Boolean, required: false, default: false, },
        },
        data () {
            return {
                valid: true,
                inputIP: null,
                rules: {
                    ipAddress: [
                        v => this.$help.validateIpAddress(v) || this.$t('invalidIpAddressFormat'),
                        v => !this.$help.isDuplicateIpAddress(v, this.list) || this.$t('duplicateIpAddress')
                    ],
                },
                fetchingUserIp: false,
                list: [],
            }
        },
        computed: {
            ...mapGetters(['allowlist', 'allowlistLoading', 'allowlistUpdatingAddress']),
            isEmptyExist() { return this.list.find(field => field.ip === '') ? true : false },
            hasChanged () {
                const hasChanged = this.$lodash.xorBy(this.allowlist, this.list.filter(e => e.ip !== '' ), 'ip').length !== 0 ||
                    this.$lodash.xorBy(this.allowlist, this.list.filter(e => e.ip !== '' ), 'comment').length !== 0
                this.$emit('on-changed', hasChanged && this.valid)
                return hasChanged && this.valid
            },
        },
        watch: {
            serviceId: {
                handler () {
                    this.fetchData()
                },
                deep: true,
            },
        },
        methods: {
            ...mapActions(['fetchAllowList', 'updateAddressToAllowList']),
            async onBlur (e) {
                if (!e.target.value) this.$refs.form.resetValidation()
                this.addRow()
            },
            async addIpAddress () {
                this.fetchingUserIp = true
                try {
                    // NOTE: this endpoint will be replace with internal one when its ready
                    const { data, } = await this.axios.get(process.env.VUE_APP_CLIENT_IP_API)
                    const ip = `${data.trim()}/32`
                    const temp = this.list.map(obj =>
                        obj.ip === '' ? { ip, comment: '',  } : obj
                    )
                    this.list = temp
                    this.$refs[`ipRef-${this.list.length-1}`][0].focus()
                } catch (e) {
                    //
                }
                this.fetchingUserIp = false
                this.addRow()
            },
            async addRow () {
                if(this.isEmptyExist) return
                this.list.push({ ip:'', comment:'',})
            },
            async deleteRow (index) {
                this.list.splice(index, 1)
                this.$refs.form.resetValidation()
            },
            async save () {
                await this.$refs.form.validate()
                if (this.valid) {
                    await this.updateAddressToAllowList({ serviceId: this.serviceId, allowList: this.list.filter(e => e.ip !== '' ), })
                    this.$emit('updated')
                }
            },
            async fetchData() {
                if(this.serviceId) {
                    await this.fetchAllowList(this.serviceId)
                    this.list = this.$lodash.cloneDeep([...this.allowlist, { ip:'', comment: '', }])
                    await this.$nextTick()
                    await this.$refs.form.validate()
                }
            },
        },
        async mounted () {
            await this.fetchData()
        },
    }
</script>
