import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters(['alertNotifications', 'rules']),
    },
    methods: {
        pickSevCusTags (labels) {
            return this.$help.objects.pickPropertyStartsWith({ object: labels, keys: ['severity', this.$config.customTagPrefix], })
        },
        isMatchedCmp ({ ruleCmpArr, notifCmpArr, }) {
            return ruleCmpArr.every(v => notifCmpArr.includes(v))
        },
        isMatchedCtx ({ notifCtx, ruleCtx, }) {
            return notifCtx === this.$config.allTopologiesTag || ruleCtx === this.$config.allTopologiesTag
        },
        rulesMatchedByLabels (labels) {
            const notifCtx = labels.context ? this.$help.alerts.unescapeTags(labels.context) : null
            let notifCmpArr = labels.compare ? this.$help.alerts.unescapeTags(labels.compare).split(',') : []
            const notifTags = this.pickSevCusTags(labels)
            return this.rules.filter(rule => {
                const ruleTags = this.pickSevCusTags(rule.labels)
                // first level check for custom and severity tags
                const isMatchedL1 = this.$lodash.isMatch(ruleTags, notifTags)
                if (isMatchedL1) {
                    // second level check for context tag, if it fails check value in compare
                    let isMatchedL2 = this.isMatchedCtx({ notifCtx, ruleCtx: rule.labels.context, })
                    if (!isMatchedL2) {
                        const ruleCmpArr = rule.labels.compare.split(',')
                        isMatchedL2 = this.isMatchedCmp({ ruleCmpArr, notifCmpArr, })
                    }
                    return isMatchedL2
                }
                return false
            })
        },
        notifsMatchedByLabels (labels) {
            const ruleCtx = labels.context
            let ruleCmpArr = labels.compare ? labels.compare.split(',') : []
            const ruleTags = this.pickSevCusTags(labels)
            return this.alertNotifications.filter(notif => {
                const notifTags = this.pickSevCusTags(notif.matchRe)
                // first level check for custom and severity tags
                const isMatchedL1 = this.$lodash.isMatch(ruleTags, notifTags)
                if (isMatchedL1) {
                    const notifCtx = this.$help.alerts.unescapeTags(notif.matchRe.context)
                    // second level check for context tag, if it fails check value in compare
                    let isMatchedL2 = this.isMatchedCtx({ notifCtx, ruleCtx, })
                    if (!isMatchedL2) {
                        const notifCmpArr = this.$help.alerts.unescapeTags(notif.matchRe.compare).split(',')
                        isMatchedL2 = this.isMatchedCmp({ ruleCmpArr, notifCmpArr, })
                    }
                    return isMatchedL2
                }
                return false
            })
        },
    },
}
