var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"monitoring-nav-wrapper text-body-2 info--text"},[_c('div',{class:[
            'monitoring-nav-item pointer mb-2 px-4 py-2',
            { 'selected': _vm.$route.path.includes('overview') }
        ],on:{"click":function($event){return _vm.handleTabChange(`/service/${_vm.$route.params.id}/monitoring/overview`)}}},[_vm._v(" "+_vm._s(_vm.$t('serviceOverview'))+" ")]),_vm._l((_vm.service.servers),function({ id, name, serverStatus, metadata = {}, },i){return _c('div',{key:i,class:[
            'monitoring-nav-item pointer mb-2 px-4 py-2',
            { 'error--text': serverStatus === 'CRITICAL' },
            { 'selected': id === _vm.server.id }
        ],on:{"click":function($event){return _vm.handleTabChange(`/service/${_vm.$route.params.id}/monitoring/${id}`)}}},[_c('truncate-string',{attrs:{"max-width":"220px","text":name,"dot-count":3,"single-char-with-in-px":7}}),_c('div',[_c('icon-sprite-sheet',{staticClass:"status-icon",attrs:{"frame":serverStatus,"size":_vm.serverIconSize}},[_vm._v(" server ")]),(metadata.primaryReplicaRole)?_c('icon-sprite-sheet',{staticClass:"status-icon ml-2",attrs:{"frame":metadata.primaryReplicaRole.toUpperCase(),"size":_vm.serverIconSize}},[_vm._v(" server ")]):_vm._e()],1)],1)})],2)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }