<style lang="scss" scoped>
.tab-content>.tab-pane:not(.active) {
    display: block;
    height: 0;
    overflow-y: hidden;
}

.map-container {
    #map-overlay {
        width: 495px;
        height: 220px;

        img {
            width: 420px;
            height: 220px;
        }

        #canvas {
            position: absolute;
        }

    }
}
</style>
<template>
    <div
        id="map-container"
        class="map-container d-flex justify-center">
        <div
            id="map-overlay"
            class="d-flex justify-center">
            <img
                id="world-map"
                src="@/assets/map.png" />
            <canvas
                id="canvas"
                :width="canvasWidth"
                :height="canvasHeight"></canvas>
        </div>
    </div>
</template>
<script>
    import { coords } from 'config/mapCoordinates'
    import { mapGetters } from 'vuex'
    import { getClass, getStrokeStyle } from 'utils/replication'
    export default {
        name: 'WorldMap',
        props: {
            replications: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                canvasWidth: 495,
                canvasHeight: 220,
            }
        },
        computed: {
            ...mapGetters(['services', 'getServiceById', 'topologiesData']),
            service() {
                return this.getServiceById(this.$route.params.id)
            },
        },
        watch: {
            services: {
                handler() {
                    this.mapLocation()
                },
            },
        },
        methods: {
            getCoord(region) {
                return coords.find(coord => coord.region === region)
            },
            getElement(id) {
                return document.getElementById(id)
            },
            getMarker(service, isReplica) {
                let markerId = `marker-${service.id}`
                if (this.getElement(markerId)) {
                    // Remove existing marker if present
                    let marker = this.getElement(markerId)
                    marker.parentElement.removeChild(marker)
                }
                let marker = document.createElement('span')
                marker.id = markerId
                marker.style.position = 'absolute'
                marker.classList.add('marker')
                marker.classList.add(getClass(service.status, isReplica))
                const { x, y, } = this.getCoord(service.region)
                marker.style.left = `${x - 11}px`
                marker.style.top = `${y - 11}px`
                return marker
            },
            mapLocation() {
                let overlay = document.getElementById('map-overlay')
                let container = document.getElementById('map-container')
                let serviceMarker = this.getMarker(this.service, false)
                const children = overlay.children
                if (children.length > 2) {
                    // Remove previous children(markers) if any except image and canvas
                    children.forEach((child, index) => {
                        if (index > 1) overlay.removeChild(child)
                    })
                }
                // Adjust responsiveness as per screen width
                serviceMarker.style.marginLeft = `${(container.clientWidth - overlay.clientWidth) / 2}px`
                overlay.appendChild(serviceMarker)
                this.replications.forEach(replica => {
                    let replicaMarker = this.getMarker(replica.service, true)
                    replicaMarker.style.marginLeft = `${(container.clientWidth - overlay.clientWidth) / 2}px`
                    overlay.appendChild(replicaMarker)
                })
                this.drawLines()
            },
            drawLines() {
                let context = document.getElementById('canvas').getContext('2d')
                let overlay = document.getElementById('map-overlay')
                context.lineWidth = 2
                context.setLineDash([6, 3])
                // Clear previous lines and later update with new ones
                context.clearRect(0, 0, this.canvasWidth, this.canvasHeight)
                context.save()
                this.replications.forEach(replica => {
                    context.beginPath()
                    context.strokeStyle = getStrokeStyle(this.topologiesData, replica, this.service, this.$vuetify.theme.themes.light)
                    let { x: x1, y: y1, } = this.getCoord(this.service.region)
                    let { x: x2, y: y2, } = this.getCoord(replica.service.region)
                    // adjust transition
                    const overlayWidth = overlay.clientWidth
                    const diff = this.canvasWidth - overlayWidth
                    x1 -= 45 - diff/2
                    x2 -= 45 - diff/2
                    y1 -= 95
                    y2 -= 95
                    context.moveTo(x1, y1)
                    // context.bezierCurveTo(x1, y2 + 10, x2, y2 + 10, x2, y2) //TODO: kept for curved lines in future if needed
                    context.lineTo(x2, y2)
                    context.stroke()
                })
                context.restore()
            },
        },
        mounted() {
            this.mapLocation()
        },
        created() {
            window.addEventListener('resize', this.mapLocation)
        },
        destroyed() {
            window.removeEventListener('resize', this.mapLocation)
        },
    }
</script>
