<template>
    <LogsWithViewer
        v-if="$unleash.isFeatureEnabled('enable-portal-logs-with-viewer')"
        class="mt-5"
        :servers="servers" />

    <div
        v-else
        class="mt-5">
        <Logs
            :preselected-services="selectedServices" />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import Logs from 'components/logs/Logs.vue'
    import LogsWithViewer from 'components/logs/LogsWithViewer.vue'
    export default {
        name: 'LogsServicePage',
        components: {
            Logs,
            LogsWithViewer,
        },
        computed: {
            ...mapGetters(['services']),
            selectedServices() {
                return this.services.find(service=> service.id === this.$route.params.id).servers.map(server=> server.dataSourceId)
            },
            service() {
                return this.services.find(
                    (service) => service.id === this.$route.params.id
                )
            },
            servers() {
                return this.service.servers.map((server) => ({
                    service: this.service.name,
                    server: server.name,
                    serverDataSourceId: server.dataSourceId,
                }))
            },
        },
    }
</script>
