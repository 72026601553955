<template>
    <div>
        <div
            v-if="hasSingleEndpointPrivateConnectEndpoint"
            data-auto="query-editor.no-public-endpoint">
            <p data-auto="query-editor.no-public-endpoint.message">
                {{ $t('dualEndpoints.queryEditorNeedsPublicEndpoint') }}
            </p>
            <v-btn
                data-auto="query-editor.no-public-endpoint.add-public-endpoint"
                class="text-capitalize"
                outlined
                color="primary"
                rounded
                :disabled="!isServiceReady"
                @click="addPublicEndpoint">
                {{ $t('dualEndpoints.queryEditorAddPublicEndpoint') }}
            </v-btn>
        </div>
        <template v-else>
            <div
                v-if="hasAllowListButNotOpted"
                data-auto="query-editor.endpoint-not-setup">
                <p data-auto="query-editor.endpoint-not-setup.message">
                    {{ $t('dualEndpoints.queryEditorSetupAllowListMsg') }}
                </p>
                <v-btn
                    data-auto="query-editor.endpoint-not-setup.setup-allowlist"
                    class="text-capitalize"
                    outlined
                    color="primary"
                    rounded
                    :disabled="!isServiceReady"
                    @click="setupAllowList">
                    {{ $t('dualEndpoints.queryEditorSetupAllowList') }}
                </v-btn>
            </div>
            <div
                v-else
                data-auto="query-editor.endpoint-ip-not-in-allowlist">
                <p data-auto="query-editor.endpoint-ip-not-in-allowlist.message">
                    {{ $t('dualEndpoints.queryEditorAddIP') }}
                </p>
                <v-btn
                    data-auto="query-editor.endpoint-ip-not-in-allowlist.manage-allowlist"
                    class="text-capitalize"
                    outlined
                    color="primary"
                    rounded
                    :disabled="!isServiceReady"
                    @click="manageAllowList">
                    {{ $t('dualEndpoints.manageAllowlist') }}
                </v-btn>
            </div>
        </template>

        <WhitelistModal ref="whitelistModal" />
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import { getIpAllowedObject, getIpAllowList, hasMultipleEndpoints, isPrivateConnectOpted, isServiceStatusReady } from 'utils/service'
    import WhitelistModal from 'components/service/WhitelistModal.vue'

    const EndpointAction = {
        name: 'EndpointAction',
        components: { WhitelistModal, },
        props: {
            service: {
                type: Object,
                required: true,
            },
        },
        computed: {
            hasSecondaryEndpoints() {
                return hasMultipleEndpoints(this.service.endpoints)
            },
            hasAllowList() {
                const ipAllowList = getIpAllowList(this.service.endpoints)
                return Boolean(ipAllowList.length)
            },
            hasSingleEndpointPrivateConnectEndpoint() {
                const { endpoints, } = this.service
                return isPrivateConnectOpted(this.service.endpoints) && !hasMultipleEndpoints(endpoints)
            },
            isServiceReady() {
                return isServiceStatusReady(this.service)
            },
            hasAllowListButNotOpted() {
                const { endpoints, } = this.service
                const hasIPObject = Boolean(getIpAllowedObject(endpoints))
                const allowList = getIpAllowList(endpoints)
                return hasIPObject && allowList.length === 0
            },
        },
        methods: {
            ...mapActions(['addEndpoint']),
            async addPublicEndpoint() {
                const endpointName = this.$help.getUniqueString(16)
                await this.addEndpoint({ serviceId: this.service.id, endpointName, })
            },
            setupAllowList() {
                this.$refs.whitelistModal.open(this.service.id)
            },
            manageAllowList() {
                this.$refs.whitelistModal.open(this.service.id)
            },
        },
    }

    export default EndpointAction
</script>
