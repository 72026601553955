<style lang="scss" scoped>
    path {
        fill: currentColor;
    }
</style>

<template>
    <svg viewBox="0 0 24 24">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.1245 6.2091L11.7322 1.94115C11.1753 1.61962 10.4892 1.61962 9.93225 1.94115L2.53994 6.2091C1.98302 6.53064 1.63994 7.12487 1.63994 7.76795V16.3038C1.63994 16.9469 1.98302 17.5412 2.53994 17.8627L9.93225 22.1306C10.4892 22.4522 11.1753 22.4522 11.7322 22.1306L19.1245 17.8627C19.6815 17.5412 20.0245 16.9469 20.0245 16.3038V7.76795C20.0245 7.12487 19.6815 6.53064 19.1245 6.2091ZM12.3322 0.901924C11.404 0.366025 10.2604 0.366025 9.33225 0.901924L1.93994 5.16987C1.01174 5.70577 0.439941 6.69615 0.439941 7.76795V16.3038C0.439941 17.3756 1.01174 18.366 1.93994 18.9019L9.33225 23.1699C10.2604 23.7058 11.404 23.7058 12.3322 23.1699L19.7245 18.9019C20.6528 18.366 21.2245 17.3756 21.2245 16.3038V7.76795C21.2245 6.69615 20.6528 5.70577 19.7245 5.16987L12.3322 0.901924Z"
            fill="#2D9CDB" />
        <path
            d="M10.9617 17.0053C11.12 16.9881 11.266 16.9126 11.371 16.7938C11.476 16.675 11.5324 16.5215 11.5289 16.3635V10.6758C11.5301 10.5921 11.5145 10.509 11.4831 10.4312C11.4516 10.3535 11.4049 10.2828 11.3456 10.2232C11.2864 10.1635 11.2157 10.1162 11.1379 10.0838C11.06 10.0515 10.9764 10.0349 10.892 10.0349C10.8076 10.0349 10.7241 10.0515 10.6462 10.0838C10.5683 10.1162 10.4977 10.1635 10.4385 10.2232C10.3792 10.2828 10.3325 10.3535 10.301 10.4312C10.2696 10.509 10.254 10.5921 10.2552 10.6758V16.3635C10.2532 16.4534 10.2706 16.5428 10.3063 16.6255C10.3419 16.7083 10.395 16.7825 10.4619 16.8433C10.5287 16.904 10.6079 16.9499 10.6941 16.9779C10.7803 17.0058 10.8716 17.0152 10.9617 17.0053ZM10.892 9.09594C11.4197 9.09594 11.8474 8.67153 11.8474 8.148C11.8474 7.62447 11.4197 7.20006 10.892 7.20006C10.3644 7.20006 9.93671 7.62447 9.93671 8.148C9.93671 8.67153 10.3644 9.09594 10.892 9.09594Z"
            fill="#2D9CDB" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.1245 6.2091L11.7322 1.94115C11.1753 1.61962 10.4892 1.61962 9.93225 1.94115L2.53994 6.2091C1.98302 6.53064 1.63994 7.12487 1.63994 7.76795V16.3038C1.63994 16.9469 1.98302 17.5412 2.53994 17.8627L9.93225 22.1306C10.4892 22.4522 11.1753 22.4522 11.7322 22.1306L19.1245 17.8627C19.6815 17.5412 20.0245 16.9469 20.0245 16.3038V7.76795C20.0245 7.12487 19.6815 6.53064 19.1245 6.2091ZM12.3322 0.901924C11.404 0.366025 10.2604 0.366025 9.33225 0.901924L1.93994 5.16987C1.01174 5.70577 0.439941 6.69615 0.439941 7.76795V16.3038C0.439941 17.3756 1.01174 18.366 1.93994 18.9019L9.33225 23.1699C10.2604 23.7058 11.404 23.7058 12.3322 23.1699L19.7245 18.9019C20.6528 18.366 21.2245 17.3756 21.2245 16.3038V7.76795C21.2245 6.69615 20.6528 5.70577 19.7245 5.16987L12.3322 0.901924Z"
            stroke="#2D9CDB"
            stroke-width="0.2" />
        <path
            d="M10.9617 17.0053C11.12 16.9881 11.266 16.9126 11.371 16.7938C11.476 16.675 11.5324 16.5215 11.5289 16.3635V10.6758C11.5301 10.5921 11.5145 10.509 11.4831 10.4312C11.4516 10.3535 11.4049 10.2828 11.3456 10.2232C11.2864 10.1635 11.2157 10.1162 11.1379 10.0838C11.06 10.0515 10.9764 10.0349 10.892 10.0349C10.8076 10.0349 10.7241 10.0515 10.6462 10.0838C10.5683 10.1162 10.4977 10.1635 10.4385 10.2232C10.3792 10.2828 10.3325 10.3535 10.301 10.4312C10.2696 10.509 10.254 10.5921 10.2552 10.6758V16.3635C10.2532 16.4534 10.2706 16.5428 10.3063 16.6255C10.3419 16.7083 10.395 16.7825 10.4619 16.8433C10.5287 16.904 10.6079 16.9499 10.6941 16.9779C10.7803 17.0058 10.8716 17.0152 10.9617 17.0053ZM10.892 9.09594C11.4197 9.09594 11.8474 8.67153 11.8474 8.148C11.8474 7.62447 11.4197 7.20006 10.892 7.20006C10.3644 7.20006 9.93671 7.62447 9.93671 8.148C9.93671 8.67153 10.3644 9.09594 10.892 9.09594Z"
            stroke="#2D9CDB"
            stroke-width="0.2" />
    </svg>
</template>

<script>
    export default {}
</script>
