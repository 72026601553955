<template>
    <div></div>
</template>

<script>
    import { mapMutations } from 'vuex'
    import { OVERLAY_UNAUTHORIZED } from 'store/overlayTypes'

    export default {
        name: 'UnauthorizedPage',
        methods: {
            ...mapMutations(['showOverlay', 'hideOverlay']),
        },
        beforeDestroy () {
            this.hideOverlay()
        },
        created () {
            this.showOverlay(OVERLAY_UNAUTHORIZED)
        },
    }
</script>
