import AuthGrant from 'pages/AuthGrant'

export default [
    {
        path: '/login/oauth2/code/grant',
        name: 'auth-grant',
        component: AuthGrant,
        meta: {
            title: 'AuthGrant',
            requiresAuth: true,
        },
    }
]
