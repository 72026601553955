<template>
    <BillingSummary
        :last-billed="lastBilled"
        :next-invoice="nextInvoice"
        class="mt-8" />
</template>

<script>
    import * as moment from 'moment'
    import { mapGetters } from 'vuex'
    import BillingSummary from 'components/billing/BillingSummary'
    export default {
        name: 'Usage',
        components: { BillingSummary, },
        data() {
            return {
                nextInvoice: moment().endOf('month').toDate().toLocaleDateString(),
            }
        },
        computed:{
            ...mapGetters(['orgData']),
            lastBilled(){
                const {created_date: orgCreated, } = this.orgData
                let monthsElapsed = moment().diff(moment.unix(orgCreated), 'months')
                return monthsElapsed > 1 ? moment().subtract(1,'months').endOf('month').toDate().toLocaleDateString(): this.$t('notApplicable')
            },
        },
    }
</script>
