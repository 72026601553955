<style lang="scss" scoped>
    .allowed-ip-note{
        color: $grayed-out;
    }

</style>
<template>
    <v-container
        class="ma-0 pa-12"
        fluid>
        <v-col cols="auto pa-0 wrapper-container">
            <SettingHeader
                :header-text="$t('secureAccess')"
                class="mb-10" />

            <p class="allowed-ip-note">
                {{ $t('allowedIPSettingText') }}
            </p>

            <SecureAccessTable />
        </v-col>
    </v-container>
</template>
<script>

    import SecureAccessTable from 'components/settings/SecureAccessTable.vue'
    import SettingHeader from 'pages/settings/SettingHeader.vue'

    export default {
        components: { SettingHeader, SecureAccessTable, },
    }
</script>
