<style lang="scss" scoped>
.delete-title {
    font-size: 1.875rem;
    color: $deep-ocean;
}

.target-item-list {
    max-height: 360px;
    overflow-y: auto;
}

.v-btn {
    &.v-btn--depressed {
        min-width: 17% !important;
    }
}

.v-text-field {
    width: 400px;
}
</style>
<template>
    <base-dialog
        v-model="isDialogOpen"
        :on-save="doSave"
        :on-cancel="doCancel"
        :min-width="width"
        :max-width="width">
        <template #title>
            <div class="delete-title font-weight-light">
                {{ title }}
            </div>
            <div class="delete-title font-weight-light">
                {{ subTitle }}
            </div>
        </template>
        <template #body>
            <v-col cols="12">
                <div class="mt-2 target-item-list">
                    <b
                        v-for="(item, index) in targets"
                        :key="item[index]"
                        class="d-block">
                        <div v-if="itemKeyName === 'metadata'">
                            {{ item[itemKeyName].name }}
                        </div>
                        <div v-else>
                            {{ item[itemKeyName] }}
                        </div>
                    </b>
                </div>
                <br />
                {{ deleteMsg }}
                <slot name="content"></slot>
            </v-col>
        </template>
        <template #actions="{ cancel, save }">
            <v-spacer />
            <v-btn
                color="primary"
                depressed
                rounded
                outlined
                class="cancel"
                @click="cancel">
                <span class="text-none">{{ $t('cancel') }} </span>
            </v-btn>
            <v-btn
                color="primary"
                depressed
                rounded
                :disabled="isSaveDisabled"
                @click="save">
                <span class="text-none"> {{ $t('yesDelete') }}</span>
            </v-btn>
        </template>
    </base-dialog>
</template>
<script>

    export default {
        name: 'AlertDeleteModal',
        props: {
            onDelete: { type: Function, required: true, },
            onCancel: { type: Function, required: true, },
            title: { type: String, required: true, },
            subTitle: { type: String, default: '', },
            deleteMsg: { type: String, default: '', },
            itemKeyName: { type: String, required: true, },
            width: { type: Number, default: 800, },
            isSaveDisabled: { type: Boolean, default: false, },
        },
        data() {
            return {
                isDialogOpen: false,
                targets: [],
            }
        },

        methods: {
            open(targets) {
                this.isDialogOpen = true
                this.targets = targets
            },
            clearState() {
                this.targets = []
            },
            doCancel() {
                this.clearState()
                this.onCancel()
            },
            async doSave() {
                await this.onDelete()
                this.clearState()
            },
        },
    }
</script>
