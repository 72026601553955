<style lang="scss" scoped>
    path {
        fill: currentColor;
    }
</style>

<template>
    <svg viewBox="0 0 6 12">
        <path d="M 0.94335938,-0.00195313 0.16796875,0.62890625 4.5429688,6 0.171875,11.371094 0.94726562,12.001953 5.8320312,6 Z" />
    </svg>
</template>

<script>
    export default {}
</script>
