<style lang="scss" scoped>
.scale-reduce {
    transform: scale(0.625);
    transform-origin: 50% 80%;
}

::v-deep .selected-row {
    background: $light-cyan;
}
</style>

<template>
    <div>
        <TableActions
            :loading="loading"
            :selected-items="selectedItems"
            :delete-modal-title="`${$t('confirmations.alertDeleteTitle', {
                quantity: selectedItems.length,
                targetType: notificationPlural
            })}`"
            :delete-modal-msg="$t('confirmations.alertDeleteMsg', { targetType: notificationPlural })"
            delete-modal-item-key-name="name"
            @add-new="addNewNotification"
            @on-delete="deleteSelected"
            @on-cancel-delete="() => handleSelect(false)" />
        <v-data-table
            :headers="headers"
            fixed-header
            :height="$config.alertManagerTableHeight"
            :items="notifications"
            :loading="loading"
            hide-default-footer
            disable-pagination
            item-key="name"
            class="data-table-full">
            <template #no-data>
                <span>{{ $t('info.noNotificationsDefined') }} -</span>
                <span
                    class="ml-1 color pointer text-links"
                    @click="addNewNotification">{{ $t('addNotif')
                    }}</span>
            </template>
            <template #[`header.select`]>
                <v-checkbox
                    class="scale-reduce ma-0"
                    :input-value="areAllSelected"
                    primary
                    :disabled="!notifications.length"
                    @change="handleSelect" />
            </template>
            <template #item="{ item: notification }">
                <tr :class="[{ 'selected-row': notification.selected }]">
                    <td>
                        <v-checkbox
                            v-model="notification.selected"
                            class="scale-reduce ma-0"
                            :input-value="notification.selected"
                            primary />
                    </td>
                    <td>
                        <a
                            class="color text-links"
                            @click="editNotification(notification)"> {{ notification.name }}</a>
                    </td>
                    <td>
                        <a
                            class="color text-links"
                            @click="editChannel(notification.channel_id)">
                            {{ channelName(notification.channel_id) }}</a>
                    </td>
                    <td>
                        <tag-listing
                            :tags="filterTags(notification.tags)"
                            small-tags
                            disable-delete-tag
                            :show-tags-count="6"
                            :is-tag-without-label="true" />
                    </td>
                    <td>
                        {{ notification.repeat_interval }}
                    </td>
                </tr>
            </template>
        </v-data-table>
        <NotificationChannelModal ref="notificationChannelModal" />
        <NotificationModal ref="notificationModal" />
    </div>
</template>

<script>

    import TableActions from 'components/alerts/TableActions'
    import NotificationChannelModal from 'components/alerts/NotificationChannelModal'
    import NotificationModal from 'components/alerts/NotificationModal'
    export default {
        name: 'NotificationsTable',
        components: {
            TableActions,
            NotificationChannelModal,
            NotificationModal,
        },
        props: {
            notifications: { type: Array, required: true, },
            channels: { type: Array, required: true, },
            tags: { type: Array, required: true, },
            loading: { type: Boolean, default: false, },
            onChanged: { type: Function, default: () => { }, },
        },
        data() {
            return {
                headers: [
                    { text: this.$t('select'), value: 'select', sortable: false, width: '20px', },
                    { text: this.$t('notificationName'), value: 'notificationName', width: '25%', },
                    { text: this.$tc('channel'), value: 'channel', sortable: false, width: '60px', },
                    { text: this.$t('matchToTags'), value: 'matchToTags', sortable: false, width: '60px', },
                    { text: this.$t('repeatInterval'), value: 'repeatInterval', sortable: false, width: '60px', }
                ],
            }
        },
        computed: {
            selectedItems: function () {
                return this.notifications.filter((notification) => notification.selected)
            },
            areAllSelected: function () {
                return this.notifications.length > 0 && this.selectedItems.length === this.notifications.length
            },
            notificationPlural: function () {
                return this.$tc('notification', this.selectedItems.length)
            },
        },
        methods: {
            handleSelect(val) {
                this.notifications.forEach(notification => notification['selected'] = val)
            },
            addNewNotification() {
                this.$refs.notificationModal.open()
            },
            getChannel(id) {
                return this.channels ? this.channels.find(channel => channel.id === id) : {}
            },
            channelName(id) {
                return this.getChannel(id) ? this.getChannel(id).channel_name : ''
            },
            filterTags(tagIds) {
                return this.tags.filter(tag => tagIds.includes(tag.id))
            },
            async deleteSelected() {
                this.$emit('notification-delete', this.notifications.filter(notification => notification.selected))
            },
            editChannel(id) {
                const channel = this.getChannel(id)
                this.$refs.notificationChannelModal.open(channel)
            },
            editNotification(notification) {
                const { channel_id: chanId, tags, } = notification
                this.$refs.notificationModal.open(notification, this.getChannel(chanId), this.filterTags(tags))
            },
        },
        created() {
            this.handleSelect(false)
        },
    }
</script>
