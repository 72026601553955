<template>
    <div
        v-if="servicesForQueryEditor.length"
        class="pa-12 pb-0 fill-height d-flex flex-column flex-grow-1">
        <WorkspaceHeader class="d-flex flex-wrap mb-5" />
        <ConnectionManager
            v-if="isChosenServiceReady"
            ref="skyQueryConnectionManager"
            v-model="chosenService"
            has-multiple-services
            @is-initializing="isInitializing = $event" />
        <SkyQueryEditor
            v-if="isSkyQueryAccessible && isChosenServiceReady"
            :is-initializing="isInitializing">
            <template #query-editor-top>
                <div class="d-flex pb-3">
                    <ServiceSelect
                        v-model="chosenService"
                        :items="servicesForQueryEditor"
                        :disabled="getIsConnBusy || isInitializing" />
                    <v-spacer />
                    <ToolbarBtns />
                </div>
            </template>
        </SkyQueryEditor>
        <template v-else>
            <div class="d-flex pb-3">
                <ServiceSelect
                    v-model="chosenService"
                    :items="servicesForQueryEditor" />
            </div>
            <div
                class="pl-3 pt-3">
                <EndpointAction
                    v-if="$unleash.isFeatureEnabled('enable-portal-dual-endpoints')"
                    :service="chosenService" />
                <p
                    v-else>
                    {{ $t('info.queryEditorAccessibleCondition') }}
                </p>
            </div>
        </template>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapState } from 'vuex'
    import SkyQueryEditor from 'components/skyQuery/SkyQueryEditor'
    import WorkspaceHeader from 'components/workspace/WorkspaceHeader'
    import ConnectionManager from 'components/skyQuery/ConnectionManager.vue'
    import ServiceSelect from 'components/skyQuery/ServiceSelect.vue'
    import ToolbarBtns from 'components/skyQuery/ToolbarBtns.vue'
    import EndpointAction from 'components/skyQuery/EndpointAction.vue'

    export default {
        name: 'SkyQuery',
        components: {
            WorkspaceHeader,
            SkyQueryEditor,
            ConnectionManager,
            ServiceSelect,
            ToolbarBtns,
            EndpointAction,
        },
        // router hook to show confirm leaving page dialog
        beforeRouteLeave(to, from, next) {
            if (this.isSkyQueryAccessible && !this.isInitializing) {
                this.$typy(this.$refs, 'skyQueryConnectionManager.beforeRouteLeaveHandler').safeFunction(to, from, next)
            }
            else next()
        },
        data() {
            return {
                isInitializing: true,
                chosenService: {},
            }
        },
        computed: {
            ...mapGetters(['readyServicesForQueryEditor', 'servicesForQueryEditor']),
            ...mapGetters({
                getIsConnBusy: 'queryConn/getIsConnBusy',
                getActiveService: 'skyQuery/getActiveService',
                getCurrentWorksheetConnection: 'queryConn/getCurrWkeConn',
            }),
            ...mapState({
                isSkyQueryAccessible: state => state.skyQuery.isSkyQueryAccessible,
            }),
            isChosenServiceReady() {
                return this.readyServicesForQueryEditor.some(s => s.id === this.chosenService.id)
            },
        },
        watch: {
            servicesForQueryEditor: {
                immediate: true,
                handler(v) {
                    // Should run once
                    if (v.length && this.$typy(this.chosenService).isEmptyObject) {
                        this.assignDefaultChosenService()
                        this.watchChosenService()
                    } else {
                        // update the props of chosenService from server
                        const updatedService = v.find(service=> service.id === this.chosenService.id)
                        this.chosenService = { ...this.chosenService, ...updatedService, }
                    }
                },
            },
        },
        methods: {
            ...mapActions(['fetchServices', 'addEndpoint']),
            watchChosenService() {
                this.unwatchChosenService = this.$watch(
                    'chosenService',
                    async (v, oV) => {
                        if (!this.$typy(v).isEmptyObject && this.$typy(v, 'id').safeString !== this.$typy(oV, 'id').safeString) {
                            await this.$typy(this.$refs, 'skyQueryConnectionManager.handleInitSkyQuery').safeFunction({ service: v, })
                        }
                    },
                    { deep: true, }
                )
            },
            /**
             * Assign the first service if there is no active service connection.
             */
            assignDefaultChosenService() {
                let chosenService = {}
                // Assign the first service as the default
                if (this.$typy(this.getCurrentWorksheetConnection).isEmptyObject || this.$typy(this.getActiveService).isEmptyObject)
                    chosenService = this.servicesForQueryEditor[0]
                // Find service based on the current connection bound to the worksheet
                else chosenService = this.getActiveService
                this.chosenService = chosenService
            },
        },
        async created() {
            await this.fetchServices()
        },
        beforeDestroy() {
            this.$typy(this.unwatchChosenService).safeFunction()
        },
    }
</script>
