<style lang="scss" scoped>
    path {
        fill: currentColor;
    }
</style>

<template>
    <svg
        viewBox="0 0 16 16"
        fill="none">
        <g clip-path="url(#clip0_6:408)">
            <path
                d="M15.5494 7.51756L12.7942 5.23075C12.5241 4.99666 12.1278 5.03276 11.8936 5.30296C11.6594 5.57316 11.7134 5.9874 11.9655 6.20348L13.3701 7.35588L8.88547 7.37475C8.52525 7.37482 8.23703 7.66305 8.25497 8.00524C8.25494 8.18535 8.32696 8.32942 8.45301 8.45547C8.57906 8.58152 8.72313 8.65354 8.90323 8.6535L13.3699 8.65265L11.9648 9.80559C11.6946 10.0398 11.6585 10.436 11.8926 10.7061C11.9106 10.7241 11.9286 10.7421 11.9286 10.7421C12.1627 10.9762 12.5409 10.9942 12.7931 10.778L15.5492 8.49013C15.6933 8.38204 15.7834 8.18391 15.7834 8.0038C15.7654 7.80569 15.6934 7.6256 15.5494 7.51756Z"
                fill="#979797" />
            <path
                d="M0.447335 7.53137C0.30323 7.63946 0.213139 7.83759 0.213104 8.0177C0.231083 8.17979 0.285085 8.34187 0.411135 8.46792L0.447149 8.50394L3.20233 10.7908C3.47244 11.0248 3.86869 10.9887 4.10287 10.7185C4.33706 10.4483 4.30112 10.0521 4.031 9.81802L2.6264 8.66562L7.09303 8.62874C7.43523 8.64668 7.72345 8.35846 7.72352 7.99825C7.7416 7.65604 7.45348 7.36793 7.09327 7.368L2.62665 7.36885L4.03169 6.21591C4.3019 5.98172 4.33799 5.58548 4.10391 5.31537C3.88783 5.06326 3.4736 5.00931 3.20339 5.2435L0.447335 7.53137Z"
                fill="#979797" />
        </g>
        <defs>
            <clipPath id="clip0_6:408">
                <rect
                    width="16"
                    height="16"
                    fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
    export default {}
</script>
