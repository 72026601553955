<template>
    <div>
        <loading-transparent-overlay v-if="transparentLoading" />
        <loading-overlay v-if="loading" />
        <unauthorized-overlay v-if="unauthorized" />
    </div>
</template>

<script>
    import {
        OVERLAY_LOADING,
        OVERLAY_TRANSPARENT_LOADING,
        OVERLAY_UNAUTHORIZED
    } from 'store/overlayTypes'
    import LoadingTransparentOverlay from './LoadingTransparentOverlay'
    import LoadingOverlay from './LoadingOverlay'
    import UnauthorizedOverlay from './UnauthorizedOverlay'

    export default {
        name: 'Overlay',
        components: {
            'loading-overlay': LoadingOverlay,
            'loading-transparent-overlay': LoadingTransparentOverlay,
            'unauthorized-overlay': UnauthorizedOverlay,
        },
        computed: {
            transparentLoading () {
                return this.$store.state.overlay === OVERLAY_TRANSPARENT_LOADING
            },
            loading () {
                return this.$store.state.overlay === OVERLAY_LOADING
            },
            unauthorized () {
                return this.$store.state.overlay === OVERLAY_UNAUTHORIZED
            },
        },
    }
</script>
