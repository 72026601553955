<style lang="scss" scoped>
    path {
        fill: currentColor;
    }
</style>

<template>
    <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.7619 16.6034C13.1882 16.6034 11.9048 17.6837 11.9048 19.0083V38.2474C11.9048 39.572 13.1882 40.6523 14.7619 40.6523H25.8775L29.297 43.5305C29.6553 43.8321 30.2857 43.8321 30.644 43.5305L34.0635 40.6523H45.2381C46.8118 40.6523 48.0952 39.572 48.0952 38.2474V19.0083C48.0952 17.6837 46.8118 16.6034 45.2381 16.6034H14.7619ZM29.9728 45.3704C29.2109 45.3704 28.4943 45.1184 27.9546 44.6642L25.093 42.2555H14.7619C12.1361 42.2555 10 40.4575 10 38.2473V19.0082C10 16.7979 12.1361 15 14.7619 15H45.2381C47.8639 15 50 16.7979 50 19.0082V38.2473C50 40.4575 47.8639 42.2555 45.2381 42.2555H34.8571L31.9955 44.6642C31.4512 45.1184 30.7347 45.3704 29.9728 45.3704ZM40.4759 24.8715C40.4759 25.3143 40.0496 25.6731 39.5235 25.6731H20.4759C19.9498 25.6731 19.5235 25.3143 19.5235 24.8715C19.5235 24.4286 19.9498 24.0698 20.4759 24.0698H39.5235C40.0496 24.0698 40.4759 24.4286 40.4759 24.8715ZM40.4759 31.2846C40.4759 31.7274 40.0496 32.0862 39.5235 32.0862H20.4759C19.9498 32.0862 19.5235 31.7274 19.5235 31.2846C19.5235 30.8418 19.9498 30.4829 20.4759 30.4829H39.5235C40.0496 30.4829 40.4759 30.8418 40.4759 31.2846Z"
            fill="#2D9CDB" />
    </svg>
</template>

<script>
    export default {}
</script>
