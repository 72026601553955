<template>
    <v-container
        fluid
        class="mt-2">
        <div>
            <v-row>
                <v-col cols="3">
                    <SideNav @on-tab-change="tabChanged" />
                </v-col>
                <v-col>
                    <Content :current-tab="currentTab" />
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>
<script>
    import Content from 'components/wla/Content.vue'
    import SideNav from 'components/wla/SideNav.vue'
    export default {
        name: 'WLA',
        components: { SideNav, Content, },
        data(){
            return {
                currentTab : 'summary',
            }
        },
        methods: {
            tabChanged(tab){
                this.currentTab = tab
            },
        },
    }
</script>
