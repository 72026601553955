<template>
    <div>
        <div>
            <div class="mb-3">
                <RosStepContent
                    :step-number="1"
                    :title="$t('ros.connect_step_1')">
                    <template #content>
                        <v-btn
                            color="primary"
                            depressed
                            rounded
                            @click="downloadBundle">
                            <span class="text-none">{{ $t('ros.downloadBundle') }}</span>
                        </v-btn>
                    </template>
                </RosStepContent>
            </div>
            <div class="mb-3">
                <RosStepContent
                    :step-number="2"
                    :title="$t('ros.connect_step_2')">
                    <template #content>
                        <div>
                            {{ $t('ros.connect_step_2a') }}
                        </div>
                        <a
                            :href="generateKeyUrl"
                            target="_blank">{{ generateKeyUrl }}</a>
                        <div class="mt-2">
                            {{ $t('ros.connect_step_2b') }}
                        </div>
                    </template>
                </RosStepContent>
            </div>
            <div class="mb-3">
                <RosStepContent
                    :step-number="3"
                    :title="$t('ros.connect_step_3')">
                    <template #content>
                        <div>
                            {{ $t('ros.connect_step_3a') }}
                        </div>
                        <div class="mt-1">
                            {{ $t('ros.connect_step_3b') }}
                        </div>
                        <div>
                            <codeBlock :text="step3Instruction1" />
                        </div>
                        <div class="mt-2">
                            <codeBlock :text="step3Instruction2" />
                        </div>
                        <div>
                            {{ $t('ros.connect_step_3c') }}
                        </div>
                    </template>
                </RosStepContent>
            </div>
            <div class="mb-3">
                <RosStepContent
                    :step-number="4"
                    :title="$t('ros.connect_step_4')">
                    <template #content>
                        <div>
                            {{ $t('ros.connect_step_4a') }}
                        </div>
                        <div>
                            <codeBlock :text="step4Instruction" />
                        </div>
                    </template>
                </RosStepContent>
            </div>
            <div class="mb-3">
                <RosStepContent
                    :step-number="5"
                    :title="$t('ros.connect_step_5')">
                    <template #content>
                        <div>
                            {{ $t('ros.connect_step_5a') }}
                        </div>
                        <div class="mt-3">
                            {{ $t('ros.connect_step_5b') }}
                        </div>
                        <div>
                            <codeBlock :text="step5Instruction" />
                        </div>
                        <div class="mt-2">
                            {{ $t('ros.connect_step_5c') }}
                        </div>
                    </template>
                </RosStepContent>
            </div>

            <div class="mb-3">
                <RosStepContent
                    :step-number="6"
                    :title="$t('ros.connect_step_6')">
                    <template #content>
                        <div class="mt-2">
                            {{ $t('ros.connect_step_6a') }}
                        </div>
                        <div>
                            <codeBlock :text="step6Instruction1" />
                        </div>
                        <div class="mt-2">
                            {{ $t('ros.connect_step_6b') }}
                        </div>
                        <div class="mt-2">
                            <codeBlock :text="step6Instruction2" />
                        </div>
                        <div class="mt-2">
                            {{ $t('ros.connect_step_6c') }}
                        </div>
                        <div class="mt-2">
                            <codeBlock :text="step6Instruction3" />
                        </div>
                    </template>
                </RosStepContent>
            </div>
            <div class="mb-3">
                <RosStepContent
                    :step-number="7"
                    :title="$t('ros.connect_step_7')">
                    <template #content>
                        <div class="mt-2">
                            {{ $t('ros.connect_step_7a') }}
                        </div>
                        <div class="mt-2">
                            <codeBlock :text="step7Instruction" />
                        </div>
                        <div class="mt-2">
                            {{ $t('ros.connect_step_7b') }}
                        </div>
                        <div class="mt-2">
                            {{ $t('ros.connect_step_7c') }}
                        </div>
                    </template>
                </RosStepContent>
            </div>
            <div class="mb-3">
                <RosStepContent
                    :step-number="8"
                    :title="$t('ros.connect_step_8')">
                    <template #content>
                        <div>
                            {{ $t('ros.connect_step_8a') }}
                        </div>
                        <div>
                            {{ $t('ros.connect_step_8b') }}
                        </div>
                        <div>
                            <codeBlock :text="step8Instruction" />
                        </div>
                        <div>
                            {{ $t('ros.connect_step_8c') }}
                        </div>
                    </template>
                </RosStepContent>
            </div>
            <div class="mt-8">
                <i18n
                    path="ros.postAgentStart"
                    tag="div">
                    <a
                        place="skyObservability"
                        to="/dashboard"
                        @click="$router.push('/dashboard')">
                        {{ $t('ros.skyObservability') }}
                    </a>
                </i18n>
            </div>
        </div>
    </div>
</template>
<script>
    import codeBlock from 'components/common/codeBlock.vue'
    import RosStepContent from 'components/monitoring/RosStepContent.vue'

    export default {
        name: 'RosConnectInstructions',
        components: {
            codeBlock,
            RosStepContent,
        },
        data() {
            return {
                generateKeyUrl: 'https://id.mariadb.com/account/api/generate-key',
                step3Instruction1: 'useradd --create-home --user-group --groups sudo --shell /bin/bash mariadbagent',
                step3Instruction2: 'echo "mariadbagent  ALL=(ALL) NOPASSWD:ALL" | sudo tee /etc/sudoers.d/mariadbagent',
                step4Instruction: 'tar -xvzf mariadb-agent-manager-for-pdc-v1.3.0.linux-amd64.tar.gz \n cd mariadb-agent-manager-for-pdc-v1.3.0.linux-amd64',
                step5Instruction: './mariadb_agent_manager create-user',
                step6Instruction1: './mariadb_agent_manager install -s',
                step6Instruction2: './mariadb_agent_manager encrypt-creds -p mariadb-credentials.json',
                step6Instruction3: 'sudo gpasswd -d mariadbagent sudo \n sudo rm /etc/sudoers.d/mariadbagent',
                step7Instruction: './mariadb_agent_manager init',
                step8Instruction: './mariadb_agent_manager start -c ./configuration.json -k ./api_token.txt -f',
            }
        },
        methods: {
            downloadBundle() {
                const link = document.createElement('a')
                link.href = this.$config.AGENT_BUNDLE_URL
                link.click()
            },
        },
    }
</script>
