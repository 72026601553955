<style lang="scss" scopped>
    .search-autocomplete {
        min-width: 250px;
        .v-input__control > div {
            min-height: 36px !important;
            margin-bottom: 0 !important;
        }
        .v-input__slot {
            box-shadow: none !important;
        }
        fieldset {
            border: 1px solid $search-border !important;
        }
        .v-input__append-inner {
            .v-icon {
                margin: 0 !important;
            }
        }
        .v-text-field__details {
            display: none;
        }
        &.v-select--is-menu-active {
            fieldset {
                border-radius: 5px 5px 0 0 !important;
            }
            .v-icon {
                transform: rotate(0deg) !important;
            }
        }
    }
    .search-autocomplete-dropdown {
        margin-top: 0px;
        box-shadow: none !important;
        border: 1px solid $search-border;
        border-top: 0;
    }
    .group-header {
        text-transform: uppercase;
        color: #a3bac0;
        font-size: 0.875rem;
        line-height: 17px;
        margin-left: -10px;
        .icon {
            width: 20px;
            top: 4px;
            position: relative;
        }
    }
    .list-item {
        font-weight: normal;
        color: #4f5051;
        font-size: 0.875rem;
        &:not(.group-header) {
            padding-left: 0px;
        }
    }
</style>

<style lang="scss">
    .search-highlighted {
        background: #e7eef1 !important;
    }
</style>

<template>
    <div
        v-shortkey="['f']"
        @shortkey="focusSearchInput()">
        <v-autocomplete
            ref="searchAutocomplete"
            v-model="select"
            :menu-props="{ contentClass: 'search-autocomplete-dropdown' }"
            class="search-autocomplete"
            :search-input.sync="search"
            :items="filteredItems"
            :autofocus="true"
            item-text="name"
            append-icon="mdi-magnify"
            :label="$t('search')"
            return-object
            no-filter
            outlined
            dense
            solo
            @change="onSelect">
            <template
                slot="item"
                slot-scope="{ item }">
                <div :class="['list-item', { 'group-header': item.type === 'header' }]">
                    <img
                        v-if="item.type === 'header'"
                        :src="require(`assets/${item.icon}.svg`)"
                        alt="MariaDB icon"
                        class="icon" />
                    <icon-sprite-sheet
                        v-else
                        size="20"
                        class="status-icon"
                        :frame="item.status">
                        health
                    </icon-sprite-sheet>
                    <span class="pl-1">{{ item.name | truncate(26) }}</span>
                </div>
            </template>
        </v-autocomplete>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: 'SearchServices',
        data () {
            return {
                search: '',
                select: '',
            }
        },
        computed: {
            ...mapGetters(['services']),
            items () {
                let _servers = []
                let items = [
                    { name: 'Services', icon: 'icon-monitor', type: 'header', disabled: true, },
                    ...this.services.map((service) => {
                        _servers = _servers.concat(service.servers.map(server => {
                            return {
                                name: server.name,
                                status: server.serverStatus,
                                redirectUrl: `/service/${service.id}/monitoring/${server.id}`,
                                type: 'item',
                            }
                        }))
                        return {
                            name: service.name,
                            status: service.topologyStatus,
                            redirectUrl: `/service/${service.id}/monitoring`,
                            type: 'item',
                        }
                    })]
                items.push({ name: 'Servers', icon: 'icon-server', type: 'header', disabled: true, })
                items = items.concat(_servers)

                return items
            },
            filteredItems () {
                if (!this.search) {
                    return this.items
                }
                return this.items.filter(o => {
                    return (o.name || '').toLowerCase().indexOf((this.search || '').toLowerCase()) > -1 || o.type === 'header'
                })
            },
        },
        methods: {
            async onSelect (value) {
                if (!value) return
                await this.$router.push(value.redirectUrl)

                this.search = ''
                this.select = ''
            },
            focusSearchInput () {
                this.$refs.searchAutocomplete.focus()
                this.$refs.searchAutocomplete.activateMenu()
            },
            closeDropdown () {
                if (this.$refs.searchAutocomplete.isFocused) {
                    this.$refs.searchAutocomplete.blur()
                }
            },
        },
        mounted () {
            document.querySelector('.v-main__wrap').addEventListener('scroll', this.closeDropdown)
        },
        destroyed () {
            document.querySelector('.v-main__wrap').removeEventListener('scroll', this.closeDropdown)
        },
        beforeDestroy () {
            // this.search = ''
            this.select = ''
        },
    }
</script>
