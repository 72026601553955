<template>
    <v-col class="pa-0 mt-2">
        <div>
            <div class="d-flex font-weight-bold">
                <span class="mr-auto"> {{ $t('total') }} </span>
                <span> {{ $t('currency', [total.toFixed(2)]) }} </span>
            </div>
        </div>
    </v-col>
</template>
<script>
    export default {
        name: 'BillingUsageTotal',
        props: {
            total: {
                type: Number,
                default: 0,
            },
        },
    }
</script>
