<template>
    <svg
        width="16"
        height="11"
        viewBox="0 0 16 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_333_4858)">
            <path
                d="M0.0266113 0.0263672H15.9735V13.847H0.0266113V0.0263672Z"
                fill="black" />
            <path
                d="M5.34229 0.0263672H10.6579V13.847H5.34229V0.0263672Z"
                fill="#FAE042" />
            <path
                d="M10.6578 0.0263672H15.9735V13.847H10.6578V0.0263672Z"
                fill="#ED2939" />
        </g>
        <defs>
            <clipPath id="clip0_333_4858">
                <rect
                    width="16"
                    height="11"
                    fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
    export default {}
</script>
