export function serverOpenedFiles ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(rate(mariadb_global_status_opened_files{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serverOpenFiles ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(mariadb_global_status_open_files{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}

export function serverOpenFilesLimit ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(mariadb_global_variables_open_files_limit{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}

export function innoDbOpenFiles ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(mariadb_global_status_innodb_num_open_files{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}
