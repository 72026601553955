import base from 'api/base'
import dbServices from 'api/dbServices'

export async function getMaxScaleServers(baseURL) {
    return base.get(`${baseURL}/servers`, {}, { withCredentials: true, })
}

export async function checkSkyQueryAccess({ id, ip, }) {
    // eslint-disable-next-line camelcase
    const postBody = {ip_address: ip,}
    return dbServices
        .post(`services/${id}/security/is-ip-allowed`, postBody)
        .then((response) => response.data)
}
