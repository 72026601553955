<template>
    <v-progress-linear
        :color="mapStatusToColor(serviceStatus)"
        :value="progress"
        absolute
        bottom />
</template>

<script>
    const ServiceStatusBar = {
        name: 'service-status-bar',
        props: {
            serviceStatus: String,
            progress: {
                type: Number,
                default: 100,
            },
        },
        methods: {
            mapStatusToColor (status) {
                /*
                        TODO: Make a proper map after after topology status integration
                        Keeping as a reference all available topology statuses
                        "OK" - green
                        "WARNING" - orange
                        "CRITICAL" - red
                        "STARTING" - blue
                        "STOPPING" - blue
                        "STOPPED" - grey
                        "SCALINGUP" - blue
                        "SCALINGDOWN" - blue
                    */
                switch (status) {
                    case 'OK':
                    case 'ready':
                        return 'status-ok'
                    case 'WARNING':
                        return 'status-warning'
                    case 'CRITICAL':
                    case 'failed':
                        return 'status-critical'
                    case 'STOPPED':
                    case 'stopped':
                        return 'status-stopped'
                    default:
                        return 'primary'
                }
            },
        },
    }

    export default ServiceStatusBar
</script>
