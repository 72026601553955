<style lang="scss" scoped>
.service-name,
.manage-text {
    color: $links;
}

.entity-subtitle {
    font-size: 9px;
    color: $grayed-out;
}

.expanded-item {
    background: $even-bg;
}
</style>
<template>
    <tr class="expanded-item">
        <td></td>
        <td>
            <v-row>
                <v-col cols="6">
                    <div
                        v-if="entity.type"
                        data-auto="secure-access.secure-endpoint.secondary-endpoint">
                        <span class="font-weight-bold">{{ $t('dualEndpoints.secondaryEndpoint') }}:</span> {{ entity.label }}
                    </div>
                    <div v-else>
                        <div
                            v-if="entity.subText"
                            class="pt-2 pb-2 entity-subtitle">
                            {{ entity.subTextLabel }}
                        </div>
                        <div
                            data-auto="secure-access.secure-endpoint.endpoint.id"
                            :class="['pb-2']">
                            {{ entity.id }}
                        </div>
                    </div>
                </v-col>
                <v-col cols="3">
                    <div v-if="!entity.type">
                        <div
                            v-if="entity.subText"
                            class="pt-2 pb-2 entity-subtitle">
                            {{ $t('alias') }}
                        </div>
                        <div
                            data-auto="secure-access.secure-endpoint.endpoint.alias"
                            :class="['pb-2']">
                            {{ entity.alias }}
                        </div>
                    </div>
                </v-col>
                <v-col
                    v-if="entity.type && entity.type === 'title'"
                    data-auto="secure-access.secure-endpoint.endpoint.remove-secondary-endpoint"
                    class="pointer manage-text font-weight-bold px-0"
                    @click="removeSecondaryEndpoint">
                    {{ $t('dualEndpoints.removeSecondaryEndpoint') }}
                </v-col>
            </v-row>
        </td>
    </tr>
</template>
<script>
    const SecureAccessEndpoints = {
        name: 'SecureAccessEndpoints',
        props: {
            entity: {
                type: Object,
                required: true,
            },
            serviceId: {
                type: String,
                required: true,
            },
        },
        methods: {
            removeSecondaryEndpoint() {
                this.$emit('on-secondary-endpoint-remove', this.serviceId)
            },
        },

    }
    export default SecureAccessEndpoints
</script>
