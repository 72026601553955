export function findKeyword ({ strToFind, keywordList, }) {
    /* TODO: lastIndexOf is not a stable solution,
     * .e.g. ["==", ">", "<", "<=", ">=", "!="], if the keyword needs to be found
     * is `<=` then the `<` is also a match. A workaround is just pushing all
     * matches keyword to an array then find the keyword having largest length
     */
    let keywordMatches = []
    for (const keyword of keywordList) {
        const index = strToFind.lastIndexOf(keyword)
        if (index !== -1) keywordMatches.push(keyword)
    }
    if (keywordMatches.length === 0) return null
    else if (keywordMatches.length === 1) return keywordMatches[0]
    else {
        return keywordMatches.sort(function (a, b) { return b.length - a.length })[0]
    }
}
