<style lang="scss" scoped>
.connect-banner {
    font-size: 13px;

    &:before {
        background: $info;
        content: "";
    }
}
</style>

<template>
    <v-row
        v-if="isVisible"
        dense
        no-gutters>
        <v-col>
            <v-alert
                class="connect-banner ma-0 pa-0 px-2"
                tile
                text
                dense>
                <div
                    v-if="isPrivateLinkEnabled"
                    class="text-center py-1">
                    <span class="font-weight-medium">{{ $t('privateConnect.additionalInfoNeeded') }}</span>
                    <v-btn
                        class="open-modal txt-compact ml-2"
                        color="primary"
                        height="26"
                        depressed
                        rounded
                        @click.stop="openPrivateConnectModal">
                        <span class="text-none"> {{
                            $t('privateConnect.setup', { connectionName: privateConnectType })
                        }}</span>
                    </v-btn>
                </div>
                <div
                    v-else
                    class="text-center py-1">
                    <span class="font-weight-bold">{{ $t('success.settingUpService') }}</span>
                    {{ $t('info.setupSecureAccess') }}
                    <v-btn
                        class="open-modal txt-compact ml-2"
                        color="primary"
                        height="26"
                        depressed
                        rounded
                        @click.stop="openSecurityAccessDialog">
                        <span class="text-none"> {{ $t('setupSecureAccess') }}</span>
                    </v-btn>
                </div>
            </v-alert>
        </v-col>
    </v-row>
</template>

<script>
    import to from 'await-to-js'
    import { fetchAllowListForService } from 'services/dbServices'
    import { doPrivateConnectHaveProjects, privateConnectLabel } from 'utils/service'
    const ServiceProvisionStatus = {
        name: 'service-security-access-banner',
        props: {
            service: {
                type: Object,
                required: true,
            },
            serviceStatus: {
                type: String,
                required: true,
            },
            isPrivateLinkEnabled: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            return {
                serviceWhiteList: [],
            }
        },
        computed: {
            isVisible() {
                // Show banner if user opted for Private link connect
                if (this.isPrivateLinkEnabled) {
                    // Also check if already projects are applied to service
                    // if yes, then do not show this banner
                    return !doPrivateConnectHaveProjects(this.service.endpoints)
                }

                const { READY, } = this.$config.serviceStatus
                // Show banner only when the service is ready and no ips added
                // We need to keep the banner persistent till the user add the ips
                if (this.serviceStatus === READY && !this.serviceWhiteList.length) return true
                return false
            },
            privateConnectType() {
                return privateConnectLabel(this.service.provider)
            },
        },
        watch: {
            serviceStatus(value) {
                const { READY, } = this.$config.serviceStatus
                if (value === READY) this.fetchWhitelist()
            },
        },
        methods: {
            openSecurityAccessDialog() {
                this.$emit('openSecurityAccessDialog')
            },
            async fetchWhitelist() {
                const [err, data] = await to(fetchAllowListForService(this.service.id))
                if (!err) {
                    this.serviceWhiteList = data.length && data[0].allow_list ? data[0].allow_list : []
                }
            },
            openPrivateConnectModal() {
                this.$emit('on-open-private-connect')
            },
        },
        created() {
            this.fetchWhitelist()
        },
    }

    export default ServiceProvisionStatus
</script>
