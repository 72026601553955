export const objects = {
    clone (object) {
        return JSON.parse(JSON.stringify(object))
    },
    filterProperty (object, property) {
        return Object.keys(object).reduce((obj, key) => {
            return (key === property) ? obj : { ...obj, [key]: object[key], }
        }, {})
    },
    pickPropertyStartsWith ({ object, keys, }) {
        return Object.keys(object).reduce((obj, srcKey) => {
            return (keys.some(item => srcKey.startsWith(item))) ? { ...obj, [srcKey]: object[srcKey], } : obj
        }, {})
    },
    compare (obj1, obj2) {
        return JSON.stringify(obj1) === JSON.stringify(obj2)
    },
}
