<style lang="scss" scoped>
.breadcrumb,
.breadcrumb-divider {
    font-size: 30px;
    text-decoration: none;
}
</style>
<template>
    <div class="d-flex align-center color text-navigation font-weight-light">
        <v-btn
            v-if="breadcrumbPaths.length > 1"
            icon
            class="ml-n9"
            @click="$router.go(-1)">
            <v-icon
                size="24"
                color="navigation">
                $vuetify.icons.goBack
            </v-icon>
        </v-btn>
        <div
            v-for="(breadcrumb, i) in breadcrumbs"
            :key="breadcrumb.text">
            <router-link
                v-if="$route.path !== breadcrumb.path"
                :to="breadcrumb.path"
                class="breadcrumb d-inline-block">
                {{ breadcrumb.text }}
            </router-link>
            <span
                v-else
                class="breadcrumb color text-navigation">
                {{ breadcrumb.text }}
            </span>
            <span
                v-if="i < breadcrumbs.length - 1"
                class="breadcrumb-divider mx-1">/
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'WorkspaceHeader',
        computed: {
            breadcrumbPaths() {
                return this.$route.path.split('/').filter((route) => route) // filter empty string
            },
            breadcrumbs() {
                return this.breadcrumbPaths.map((path) => ({
                    /*Convert to title case
                     *query-editor -> Query Editor
                     */
                    text: this.$lodash.startCase(path),
                    path: this.$route.path.slice(
                        0,
                        this.$route.path.indexOf(path) + path.length
                    ),
                }))
            },
        },
    }
</script>
