export function serviceCpuLoad ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg by (kubernetes_pod_name) (service_cpu{mariadb="${name}", kubernetes_namespace="${id}", mariadb_com_server_type!="maxscale"})`
    }
}

export function serviceCpuLoadCS ({ id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg by (kubernetes_pod_name) (service_cpu{kubernetes_pod_name=~\"cs-node.*\", kubernetes_namespace="${id}"})`
    }
}

export function serviceThreads ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(mariadb_global_status_threads_running{mariadb=~"${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}

export function serverThreads ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(mariadb_global_status_threads_running{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}

export function serverCpuLoad ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg by (kubernetes_pod_name, cpu) (service_cpu{kubernetes_pod_name="${name}", kubernetes_namespace="${id}"})`
    }
}

export function cpuSystemUsage ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `((avg by (kubernetes_node) (rate(node_cpu_seconds_total{mode="system"}[300s]))) * on (kubernetes_node) group_right label_replace(avg by (pod, node, namespace) (kube_pod_info{namespace=~"db.*"}) * on (pod, namespace) group_left(mariadb_com_topology_type, mariadb_com_server_type, mariadb, kubernetes_pod_name, kubernetes_namespace) label_replace(label_replace(mariadb_up{kubernetes_pod_name="${name}", kubernetes_namespace="${id}"}, "pod", "$1", "kubernetes_pod_name", "(.*)"),  "namespace", "$1", "kubernetes_namespace", "(.*)"),  "kubernetes_node", "$1", "node", "(.*)"))`
    }
}

export function cpuUserUsage ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `((avg by (kubernetes_node) (rate(node_cpu_seconds_total{mode="user"}[300s]))) * on (kubernetes_node) group_right label_replace(avg by (pod, node, namespace) (kube_pod_info{namespace=~"db.*"}) * on (pod, namespace) group_left(mariadb_com_topology_type, mariadb_com_server_type, mariadb, kubernetes_pod_name, kubernetes_namespace) label_replace(label_replace(mariadb_up{kubernetes_pod_name="${name}", kubernetes_namespace="${id}"}, "pod", "$1", "kubernetes_pod_name", "(.*)"),  "namespace", "$1", "kubernetes_namespace", "(.*)"),  "kubernetes_node", "$1", "node", "(.*)"))`
    }
}

export function serverThreadsCached ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(increase(mariadb_global_status_threads_cached{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serverThreadsCreated ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(increase(mariadb_global_status_threads_created{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}

export function mxsThreadsHangups ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(maxscale_threads_hangups{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"})`
    }
}

export function mxsThreadsErrors ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(increase(maxscale_threads_errors{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s]))`
    }
}

export function mxsThreadsEventQueueLength ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(maxscale_threads_event_queue_length{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"})`
    }
}

export function mxsThreadsCurrentDescriptors ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(increase(maxscale_threads_current_descriptors{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s]))`
    }
}

export function mxsThreadsTotalDescriptors ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(maxscale_threads_total_descriptors{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"})`
    }
}

export function mxsThreadsReads ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(maxscale_threads_reads{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s]))`
    }
}

export function mxsThreadsWrites ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(maxscale_threads_writes{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s]))`
    }
}

export function mxsThreadsCount ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(maxscale_threads_count{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}

export function mxsThreadsStackSize ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(maxscale_threads_stack_size{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}

export function mxsThreadsMaxQueueTime ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(maxscale_threads_max_queue_time{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}) by (id)`
    }
}

export function xpandCPULoad ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg by (kubernetes_pod_name) (mariadb_xpand_cpu_load{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"})`
    }
}

export function xpandCPUNode ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(mariadb_xpand_cpu_load{mariadb=~"${name}", kubernetes_namespace=~"${id}"}) by (kubernetes_pod_name)`
    }
}
