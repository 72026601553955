import Vue from 'vue'
import to from 'await-to-js'
import { getMaxScaleServers, checkSkyQueryAccess } from 'services/skyQuery'
import { fetchServiceCredentials } from 'services/dbServices'
import { t } from 'typy'
import i18n from 'plugins/i18n'
import { getLoadBalancerFQDN } from 'utils/service'
import { CONFIG } from 'config'

export default {
    namespaced: true,
    state: {
        maxscaleAccessErrorMessage: '',
        masterServer: '', // MaxScale master server name to be connected to.
        skyQueryBaseURL: '',
        isSkyQueryAccessible: true,
        clientIP: '',
    },
    mutations: {
        setMaxScaleAccessErrorMessage(state, value) {
            state.maxscaleAccessErrorMessage = value
        },
        setMasterServerName(state, value) {
            state.masterServer = value
        },
        setSkyQueryBaseURL(state, value) {
            state.skyQueryBaseURL = value
        },
        setIsSkyQueryAccessible(state, value) {
            state.isSkyQueryAccessible = value
        },
        setClientIP(state, value) {
            state.clientIp = value
        },
    },
    actions: {
        async fetchClientIp({ commit, }) {
            const [error, response] = await to(Vue.axios.get(process.env.VUE_APP_CLIENT_IP_API))
            if (error) {
                commit('setClientIP', '')
            } else {
                commit('setClientIP', response.data.trim())
            }

        },
        async fetchSkyQueryAccessibleState({ commit, state, }, id) {
            if (state.clientIp) {
                const [error, response] = await to(checkSkyQueryAccess({ id, ip: state.clientIp, }))
                if (error) {
                    commit('setIsSkyQueryAccessible', false)
                }
                else {
                    commit('setIsSkyQueryAccessible', t(response, 'is_allowed').safeBoolean)
                }
            }
            /**
             * If for some reason, failed to get client IP, this assumes the user already
             * added their IP to the allowlist
             */
            else {
                commit('setIsSkyQueryAccessible', true)
            }
        },
        async fetchMaxScaleMasterServerName({ commit, }, baseURL) {
            const [error, response] = await to(getMaxScaleServers(baseURL))
            if (error) {
                const errorMessage = i18n.t('errors.failedToConnectToMaxScale')
                commit('setMaxScaleAccessErrorMessage', errorMessage)
                commit('showMessage', { text: errorMessage, type: 'error', }, { root: true, })
            } else {
                const servers = t(response, 'data.data').safeArray
                const masters = servers.filter(server => t(server, 'attributes.state').safeString.includes('Master'))
                const masterName = t(masters, '[0].id').safeString
                if (masterName) commit('setMasterServerName', masterName)
                commit('setMaxScaleAccessErrorMessage', '')
            }
        },
        /**
         * @param {Object} param.requestParams
         * @param {Object} param.service - skysql service object
         */
        async openConnection({ dispatch, state, }, { requestParams, service, }) {
            const {
                user = '',
                password = '',
                db = '',
                timeout = 300,
            } = requestParams
            await dispatch(
                'queryConn/openConnect',
                {
                    body: { target: state.masterServer, user, password, db, timeout, },
                    resourceType: 'servers',
                    meta: {
                        serviceId: service.id,
                        serviceName: service.name,
                    },
                },
                { root: true, }
            )
        },
        //Seamless auth, attempt to open connect with credentials passed in automatically.
        async autoAuth({ dispatch, }, service) {
            const { username: user, password, } = await fetchServiceCredentials(service.id)
            await dispatch('openConnection', { requestParams: { user, password, }, service, })
        },
        initSkyQueryAxiosOpts({ state, commit, rootGetters, }, service) {
            const fqdn = getLoadBalancerFQDN(service.endpoints) || service.fqdn
            if(!fqdn){
                return commit('setIsSkyQueryAccessible', false)
            }
            const { MAXSCALE_CONNECT_PORT, } = CONFIG
            commit('setSkyQueryBaseURL', `https://${fqdn}:${MAXSCALE_CONNECT_PORT}`)
            commit(
                'queryEditorConfig/SET_AXIOS_OPTS',
                {
                    withCredentials: true,
                    baseURL: state.skyQueryBaseURL,
                    headers: {
                        // For maxscale api auth
                        Authorization: `Bearer ${rootGetters.authToken}`,
                    },
                },
                { root: true, }
            )
        },
        async initSkyQuery({ dispatch, }, service) {
            await dispatch('fetchSkyQueryAccessibleState', service.id)
            dispatch('initSkyQueryAxiosOpts', service)
        },
        /**
         * Validate sql connections of a service
         * @param {Object} service - service having sql connections
         */
        async validateServiceConns({ dispatch, commit, rootState, rootGetters, }, service) {
            const serviceMap = rootGetters.readyServiceForQueryEditorMap

            let serviceConnMap = {}, // connections of the provided service
                otherServiceConnMap = {} // connections of other services
            Object.values(rootState.queryConn.sql_conns)
                .forEach(conn => {
                    if (t(conn, 'meta.serviceId').safeString === service.id)
                        serviceConnMap[conn.id] = conn
                    else if (serviceMap[t(conn, 'meta.serviceId').safeString]) {
                        otherServiceConnMap[conn.id] = conn
                    }
                })

            const customSetSqlConns = (aliveServiceConnMap) => {
                /**
                 * After finish validating, merge alive service connections with other service connections.
                 * This helps to keep connections of multiple services
                 */
                commit('queryConn/SET_SQL_CONNS', { ...aliveServiceConnMap, ...otherServiceConnMap, }, { root: true, })
            }
            await dispatch('queryConn/validateConns', { sqlConns: serviceConnMap, customSetSqlConns, }, { root: true, })
        },
        async initSkyQueryConn({ dispatch, rootGetters, state, }, service) {
            dispatch('cleanUpMem')
            await dispatch('validateServiceConns', service)
            const worksheetConns = rootGetters['queryConn/getWkeConns']
            const serviceConnection = worksheetConns.find(
                (conn) => t(conn, 'meta.serviceId').safeString === service.id
            )
            if (serviceConnection) {
                await dispatch('queryConn/onChangeConn', serviceConnection, { root: true, })
            } else {
                await dispatch('fetchMaxScaleMasterServerName', state.skyQueryBaseURL)
                if (!state.maxscaleAccessErrorMessage) await dispatch('autoAuth', service)
            }
        },
        async disconnectAllConnections({ commit, rootGetters, dispatch, }) {
            const serviceMap = rootGetters.readyServiceForQueryEditorMap
            const allWorksheetConnections = rootGetters['queryConn/getWkeConns']
            for (const connection of allWorksheetConnections) {
                const serviceId = t(connection, 'meta.serviceId').safeString
                const service = t(serviceMap, `[${serviceId}]`).safeObjectOrEmpty
                if (service.fqdn) {
                    dispatch('initSkyQueryAxiosOpts', service)
                    await dispatch('queryConn/disconnect', { showSnackbar: false, id: connection.id, }, { root: true, })
                } else {
                    // In case the service is already deleted, clear connection data in IndexedDB
                    dispatch(
                        'querySession/resetSessionStates',
                        // eslint-disable-next-line camelcase
                        { conn_id: connection.id, },
                        { root: true, }
                    )
                    commit('queryConn/DELETE_SQL_CONN', connection, { root: true, })
                }
            }
            // after deleting connections, set base url to an empty string
            commit('setSkyQueryBaseURL', '')
        },
        /**
         * In SkySQL, Query Editor has only 1 worksheet, so when switching between SkySQL services,
         * the query editor's memory states for the current service should be released as it will be
         * re-fetched anyway. This action helps to release memory states of the wke and querySession modules
         */
        cleanUpMem({ dispatch, rootState, rootGetters, }) {
            // release memory states that are keyed by query session
            const querySessions = rootState.querySession.query_sessions
            querySessions.forEach(session =>
                dispatch('querySession/releaseQueryModulesMem', session.id, { root: true, })
            )
            // release memory states that are keyed by worksheet ID
            const worksheetID = t(rootGetters['wke/getActiveWke'], 'id').safeString
            dispatch('wke/releaseQueryModulesMem', worksheetID, { root: true, })
        },
    },
    getters: {
        //Active service that has connection bound to the current active worksheet
        getActiveService: (state, getters, rootState, rootGetters) => {
            return (
                rootGetters.servicesForQueryEditor.find(
                    (service) =>
                        service.id ===
                        t(rootGetters['queryConn/getCurrWkeConn'], 'meta.serviceId')
                            .safeString
                ) || {}
            )
        },
    },
}
