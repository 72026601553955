import Integrations from 'pages/Integrations'


export default [
    {
        path: '/integrations',
        name: 'integrations',
        component: Integrations,
        meta: {
            requiresAuth: true,
            title: 'Integrations',
        },
    }
]
