<template>
    <svg
        width="84"
        height="76"
        viewBox="0 0 84 76"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_333_8362)">
            <path
                d="M56.58 22.58C56.5802 23.0652 56.4921 23.5464 56.32 24C55.7112 25.3781 54.6538 26.5095 53.32 27.21C50.8406 28.4672 48.0999 29.1223 45.32 29.1223C42.5401 29.1223 39.7994 28.4672 37.32 27.21C35.9842 26.5121 34.9261 25.3799 34.32 24C34.1274 23.5502 34.0189 23.0689 34 22.58C34 20.92 35.1 19.25 37.31 17.97C39.7894 16.7128 42.5301 16.0577 45.31 16.0577C48.0899 16.0577 50.8306 16.7128 53.31 17.97C55.48 19.25 56.58 20.92 56.58 22.58Z"
                fill="#61C9D2"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M56.58 22.58V40.41C56.58 42.08 55.48 43.74 53.27 45.02C50.792 46.2818 48.0507 46.9396 45.27 46.9396C42.4892 46.9396 39.7479 46.2818 37.27 45.02C35.09 43.74 34 42.08 34 40.41V22.58C33.9966 23.0646 34.0813 23.5457 34.25 24C34.8561 25.3799 35.9142 26.5121 37.25 27.21C39.7293 28.4672 42.4701 29.1223 45.25 29.1223C48.0299 29.1223 50.7706 28.4672 53.25 27.21C54.5838 26.5095 55.6412 25.3781 56.25 24C56.446 23.5509 56.5579 23.0696 56.58 22.58Z"
                fill="#2F99A3"
                stroke="#003545"
                stroke-width="0.8"
                stroke-miterlimit="10" />
            <path
                d="M56.58 48.09V40.41"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M51.6799 52.34V45.78"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M38.89 52.34V45.78"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M45.28 46.93V53.49"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M33.99 48.09V40.41"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M29.7999 7.12C29.7975 7.60262 29.706 8.08063 29.53 8.53C28.922 9.90615 27.8641 11.0346 26.53 11.73C24.0515 12.9903 21.3104 13.6472 18.53 13.6472C15.7495 13.6472 13.0084 12.9903 10.53 11.73C9.19339 11.0378 8.13453 9.90839 7.52995 8.53C7.33334 8.08457 7.22143 7.60642 7.19995 7.12C7.19995 5.45 8.30995 3.78 10.51 2.51C12.9884 1.24974 15.7295 0.59285 18.51 0.59285C21.2904 0.59285 24.0315 1.24974 26.51 2.51C28.69 3.78 29.7999 5.45 29.7999 7.12Z"
                fill="#61C9D2"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M29.7999 7.12V24.94C29.7999 26.61 28.6899 28.28 26.4899 29.55C24.0115 30.8103 21.2704 31.4672 18.4899 31.4672C15.7095 31.4672 12.9684 30.8103 10.4899 29.55C8.28993 28.28 7.17993 26.61 7.17993 24.94V7.12C7.18237 7.60262 7.2739 8.08063 7.44993 8.53C8.05451 9.90839 9.11337 11.0378 10.4499 11.73C12.9284 12.9903 15.6695 13.6472 18.4499 13.6472C21.2304 13.6472 23.9715 12.9903 26.4499 11.73C27.784 11.0346 28.842 9.90615 29.4499 8.53C29.6534 8.08592 29.7721 7.6077 29.7999 7.12Z"
                fill="#2F99A3"
                stroke="#003545"
                stroke-width="0.8"
                stroke-miterlimit="10" />
            <path
                d="M29.7999 32.62V24.94"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M24.89 36.88V30.32"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M12.11 36.88V30.32"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M18.5 31.46V38.03"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M7.19995 32.62V24.94"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M83.37 38.05C83.3684 38.5295 83.2768 39.0043 83.1 39.45C82.4959 40.8312 81.4372 41.964 80.1 42.66C77.6215 43.9203 74.8804 44.5772 72.1 44.5772C69.3195 44.5772 66.5784 43.9203 64.1 42.66C62.7607 41.9666 61.7012 40.833 61.1 39.45C60.9231 39.0043 60.8315 38.5295 60.83 38.05C60.83 36.38 61.94 34.71 64.14 33.44C66.6184 32.1797 69.3595 31.5229 72.14 31.5229C74.9204 31.5229 77.6615 32.1797 80.14 33.44C82.26 34.71 83.37 36.38 83.37 38.05Z"
                fill="#61C9D2"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M83.37 38.05V55.87C83.37 57.54 82.26 59.21 80.06 60.48C77.5816 61.7403 74.8404 62.3972 72.06 62.3972C69.2795 62.3972 66.5384 61.7403 64.06 60.48C61.86 59.21 60.75 57.54 60.75 55.87V38.05C60.7516 38.5295 60.8432 39.0044 61.02 39.45C61.6213 40.833 62.6807 41.9666 64.02 42.66C66.4984 43.9203 69.2395 44.5772 72.02 44.5772C74.8004 44.5772 77.5416 43.9203 80.02 42.66C81.3573 41.964 82.4159 40.8312 83.02 39.45C83.2243 39.0097 83.3431 38.5346 83.37 38.05Z"
                fill="#2F99A3"
                stroke="#003545"
                stroke-width="0.8"
                stroke-miterlimit="10" />
            <path
                d="M83.37 63.55V55.87"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M78.46 61.25V67.81"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M65.6799 67.81V61.25"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M72.0699 62.39V68.95"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M60.77 63.55V55.87"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M35.0799 54.56L19.6699 63.55"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M8.28998 39.1L2.08743 42.7215C1.4245 43.1086 1.42687 44.0673 2.0917 44.4511L52.112 73.3266C53.3485 74.0404 54.8718 74.041 56.1089 73.3282L61.85 70.02"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_333_8362">
                <rect
                    width="83.97"
                    height="75.08"
                    fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
    export default {}
</script>
