<template>
    <svg
        width="18"
        height="11"
        viewBox="0 0 18 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.0527344 0H18.0001V11H0.0527344V0Z"
            fill="#1E50A0" />
        <path
            d="M14.3034 6.3523L14.5717 5.48388L14.84 6.3523L14.1375 5.81533L15.0059 5.81562L14.3034 6.3523ZM4.53855 8.68372L4.08755 9.11128L4.13242 8.48312L3.52539 8.38817L4.03197 8.03212L3.726 7.48617L4.31305 7.67057L4.53855 7.08496L4.76405 7.67057L5.3511 7.48617L5.04513 8.03212L5.55171 8.38817L4.94468 8.48312L4.98955 9.11128L4.53855 8.68372V8.68372ZM11.4859 5.3322L11.1637 5.63759L11.1959 5.18891L10.7622 5.12088L11.1241 4.86672L10.9055 4.4768L11.325 4.60851L11.4859 4.19022L11.6469 4.60851L12.0663 4.4768L11.8478 4.86672L12.2096 5.12088L11.776 5.18891L11.8081 5.63759L11.4859 5.3322V5.3322ZM15.828 4.46378L15.5058 4.76917L15.538 4.32049L15.1043 4.25246L15.4662 3.9983L15.2476 3.60838L15.6671 3.74009L15.828 3.3218L15.989 3.74009L16.4084 3.60838L16.1899 3.9983L16.5517 4.25246L16.1181 4.32049L16.1502 4.76917L15.828 4.46378V4.46378ZM13.5122 2.72693L13.19 3.03233L13.2222 2.58365L12.7885 2.51562L13.1504 2.26146L12.9318 1.87154L13.3513 2.00325L13.5122 1.58496L13.6732 2.00325L14.0926 1.87154L13.8741 2.26146L14.2359 2.51562L13.8023 2.58365L13.8344 3.03233L13.5122 2.72693V2.72693ZM13.5122 8.80588L13.19 9.11128L13.2222 8.66259L12.7885 8.59457L13.1504 8.34041L12.9318 7.95049L13.3513 8.0822L13.5122 7.66391L13.6732 8.0822L14.0926 7.95049L13.8741 8.34041L14.2359 8.59457L13.8023 8.66259L13.8344 9.11128L13.5122 8.80588V8.80588Z"
            fill="white"
            stroke="white"
            stroke-width="0.222222"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M1.46698 0.289551H0.341797V0.964604L7.90082 5.50008H9.02601V4.82502L1.46698 0.289551Z"
            fill="white" />
        <path
            d="M9.02601 0.964604V0.289551H7.90082L0.341797 4.82502V5.50008H1.46698L9.02601 0.964604Z"
            fill="white" />
        <path
            d="M0.341797 2.02637H9.02601V3.76321H0.341797V2.02637Z"
            fill="white" />
        <path
            d="M3.81543 0.289551H5.55227V5.50008H3.81543V0.289551Z"
            fill="white" />
        <path
            d="M4.39453 0.289551H4.97348V5.50008H4.39453V0.289551Z"
            fill="#D22F27" />
        <path
            d="M0.341797 2.60534H9.02601V3.18429H0.341797V2.60534ZM9.02601 4.82502L7.25645 3.76323H6.13127L9.02601 5.50008V4.82502ZM9.02601 0.289551H7.90082L5.55232 1.69871V2.02639H6.13156L9.02601 0.289551ZM0.341797 0.964604L2.11135 2.02639H3.23682L0.342086 0.289551L0.341797 0.964604ZM0.341797 5.50008H1.46698L3.81548 4.09092V3.76323H3.23653L0.341797 5.50008Z"
            fill="#D22F27" />
    </svg>
</template>

<script>
    export default {}
</script>
