<template>
    <v-row
        v-if="!isInitializing"
        class="pa-4"
        dense
        no-gutters>
        <v-col
            v-if="!isServiceServerless"
            class="pr-4"
            cols="2">
            <DashboardPanel
                :title="$t('serviceDetails')"
                height="110px"
                :show-help="false"
                has-action
                @contentClick="$router.push(`/service/${service.id}/details`)">
                <template #content>
                    <div class="text-center">
                        <div class="text-body-2 text-capitalize">
                            {{ service.size }}
                        </div>
                        <div class="text-caption text-lowercase">
                            {{ isNodes ? $help.getNodesTextForNonReplicatedTopology(service.nodes) : $help.getNodesTextForReplicatedTopology(service.nodes) }}
                        </div>
                    </div>
                </template>
            </DashboardPanel>
        </v-col>
        <v-col
            v-if="!isRemoteService"
            class="pr-4"
            cols="2">
            <DashboardPanel
                :title="$t('spending')"
                height="110px"
                :show-help="false"
                has-action
                @contentClick="$router.push(`/service/${service.id}/details`)">
                <template #content>
                    <div class="text-center">
                        <div class="text-h6">
                            {{ $t('currency', [spending.toFixed(2)]) }}
                        </div>
                    </div>
                </template>
            </DashboardPanel>
        </v-col>
        <v-col
            v-if="isServiceServerless"
            cols="2">
            <DashboardPanel
                :title="$t('queries-ran')"
                height="110px"
                :show-help="false">
                <template #content>
                    <div class="text-center color text-warning">
                        <div class="text-h5 font-weight-black text-decoration-underline">
                            543
                        </div>
                    </div>
                </template>
            </DashboardPanel>
        </v-col>
        <v-col
            v-for="(panel, i) in widgets.panels"
            :key="i"
            class="pr-4"
            :cols="showGlobalReplication && i == 2 ? 1 : 2">
            <DashboardPanel
                :title="getPanelLocale(panel.title)"
                :title-size="panel.titleSize"
                height="110px"
                :show-help="false"
                :metrics="panel.metrics"
                :refresh-interval="widgets.refreshInterval"
                :service="service"
                :time="time"
                has-action
                @contentClick="$router.push(`/service/${service.id}/monitoring/overview`)">
                <template #content="{ data }">
                    <component
                        :is="panel.widget"
                        :data="data"
                        :options="panel.options" />
                </template>
            </DashboardPanel>
        </v-col>
        <v-col
            v-if="!isServiceServerless"
            :class="{'pr-4': showGlobalReplication}"
            :cols="showGlobalReplication? 1 : 2">
            <DashboardPanel
                :title="$t('alerts')"
                height="110px"
                :show-help="false"
                has-action
                @contentClick="!activeSilence && $router.push(`service/${service.id}/alerts`)">
                <template #content>
                    <AlertsSilenced
                        v-if="activeSilence"
                        :active-silence="activeSilence" />
                    <AlertsCount
                        v-else
                        :refresh-interval="widgets.refreshInterval"
                        :service="service"
                        :time="time" />
                </template>
            </DashboardPanel>
        </v-col>
        <v-col
            v-if="!isServiceServerless && showGlobalReplication"
            cols="2">
            <DashboardPanel
                :title="$t('globalReplication')"
                height="110px"
                class="pointer"
                has-action
                @contentClick="$router.push(`/service/${service.id}/details`)">
                <template #content>
                    <replication-status :service="service" />
                </template>
            </DashboardPanel>
        </v-col>
    </v-row>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import { getReplicas } from 'utils/replication'
    import DashboardPanel from 'components/monitoring/Panel.vue'
    import ReplicationStatus from 'components/service/ReplicationStatus.vue'
    import AlertsCount from 'components/service/AlertsCount.vue'
    import { isServerlessAnalytics, isOnPremService } from 'utils/service'
    import { findActiveSilence } from 'utils/alert'
    import AlertsSilenced from 'components/service/AlertsSilenced.vue'

    export default {
        name: 'ServiceListingWidgets',
        components: {
            DashboardPanel,
            AlertsCount,
            ReplicationStatus,
            AlertsSilenced,
        },
        props: {
            service: {
                required: true,
                type: Object,
            },
            widgets: {
                type: Object,
                default: () => {
                    return {
                        panels: [],
                    }
                },
            },
            spending: {
                required: true,
                type: Number,
            },
        },
        data() {
            return {
                isInitializing: true,
                time: {
                    selected: true,
                    start: 'now-30 minutes',
                    end: 'now',
                },
            }
        },
        computed: {
            ...mapGetters(['regions', 'services', 'silences']),
            isServiceServerless() {
                return isServerlessAnalytics(this.service.topology)
            },
            showGlobalReplication() {
                return !!getReplicas(this.services, this.service).length
            },
            isNodes(){
                return this.$help.hasNodesInTopology(this.service.topology)
            },
            isRemoteService(){
                return isOnPremService(this.service.provider)
            },
            activeSilence() {
                return findActiveSilence(this.silences, `${this.service.name}/all`)
            },
        },
        methods: {
            ...mapActions(['getRegions', 'getSilences']),
            getPanelLocale(locale) {
                return this.$t(`monitoring.panels.${locale}`)
            },
        },
        async created() {
            try {
                await this.getRegions()
                await this.getSilences()
            } catch (_) {
            //
            }
            this.isInitializing = false
        },
    }
</script>
