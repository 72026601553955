<template>
    <svg
        width="30"
        height="29"
        viewBox="0 0 30 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14 28L1 14.5L13.9899 1"
            stroke="#013646"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round" />
    </svg>
</template>

<script>
    export default {}
</script>
