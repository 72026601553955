export function xpandSessionsTimeState100ms ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `count(mariadb_xpand_sessions_time_in_state{mariadb=~"${name}", kubernetes_namespace="${id}", statement_state="executing"} <= 0.1) / count(mariadb_xpand_sessions_time_in_state{mariadb=~"${name}", kubernetes_namespace="${id}", statement_state="executing"}) OR on() vector(0)`
    }
}

export function xpandSessionsTimeState10s ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `count((mariadb_xpand_sessions_time_in_state{mariadb=~"${name}", kubernetes_namespace="${id}", statement_state="executing"} > 0.1) and (mariadb_xpand_sessions_time_in_state{mariadb=~"${name}", kubernetes_namespace="${id}", statement_state="executing"} <= 10)) OR on() vector(0) / count(mariadb_xpand_sessions_time_in_state{mariadb=~"${name}", kubernetes_namespace="${id}", statement_state="executing"})`
    }
}

export function xpandSessionsTimeState1m ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `count((mariadb_xpand_sessions_time_in_state{mariadb=~"${name}", kubernetes_namespace="${id}", statement_state="executing"} > 10) and (mariadb_xpand_sessions_time_in_state{mariadb=~"${name}", kubernetes_namespace="${id}", statement_state="executing"} <= 60))  OR on() vector(0) / count(mariadb_xpand_sessions_time_in_state{mariadb=~"${name}", kubernetes_namespace="${id}", statement_state="executing"})`
    }
}

export function xpandSessionsTimeStateGrater1m ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `count(mariadb_xpand_sessions_time_in_state{mariadb=~"${name}", kubernetes_namespace="${id}", statement_state="executing"} > 60) OR on() vector(0) / count(mariadb_xpand_sessions_time_in_state{mariadb=~"${name}", kubernetes_namespace="${id}", statement_state="executing"})`
    }
}

export function xpandResponseTimeSecondsRead ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        // TODO: Adjust, fow now we divide by 1mill until the exporter is fixed
        return `avg(rate(mariadb_xpand_response_time_seconds{mariadb=~"${name}", kubernetes_namespace=~"${id}", type="read"}[300s])) / 1000000`
    }
}

export function xpandResponseTimeSecondsWrite ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        // TODO: Adjust, fow now we divide by 1mill until the exporter is fixed
        return `avg(rate(mariadb_xpand_response_time_seconds{mariadb=~"${name}", kubernetes_namespace=~"${id}", type="write"}[300s])) / 1000000`
    }
}

export function xpandTPS ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(mariadb_xpand_tps{mariadb=~"${name}", kubernetes_namespace=~"${id}"}[300s]))`
    }
}
export function xpandQPS ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(mariadb_xpand_qps{mariadb=~"${name}", kubernetes_namespace=~"${id}"}[300s]))`
    }
}

export function xpandSessions ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(mariadb_xpand_sessions{mariadb=~"${name}", kubernetes_namespace=~"${id}"} >=0) by (statement_state)`
    }
}

export function xpandSessionsTransactionsAge ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(mariadb_xpand_sessions_trx_age{mariadb=~"${name}", kubernetes_namespace=~"${id}"} >= 0) by (agg)`
    }
}

export function xpandSessionsTimeState0 ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `(sum(rate(mariadb_xpand_sessions_time_in_state{mariadb=~"${name}", kubernetes_namespace=~"${id}", agg="avg"}[300s]) >=0) by (statement_state)) * 1000`
    }
}

export function xpandStatsConnections ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(increase(mariadb_xpand_stats_connections{mariadb=~"${name}", kubernetes_namespace=~"${id}"}[5m])/5) by (mariadb)`
    }
}
