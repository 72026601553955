import { CONFIG } from 'config/'
import  * as HELPERS from 'helpers/'
export function fixDefaultValues(configs) {
    // Forcing to update default_value as per DBAAS-13881
    const { MULTISELECT, } = CONFIG.CONFIG_MANAGER_FLAGS
    configs.forEach(config =>{
        if (config['default_value'].includes('""') && HELPERS.hasFlags([MULTISELECT], config.flags)) {
            config['default_value'] = []
        }
    })
}
