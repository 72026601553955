<style lang="scss" scoped>
.workspace-card-container {
    gap: 14px;
    .workspace-card {
        background-color: #f9fbfb;
        border-radius: 10px;
        &__title {
            font-size: 1.625rem;
        }
        &__sub-title {
            color: $grayed-out;
            font-size: 0.75rem;
        }
        &__text {
            font-size: 0.75rem;
        }
    }
}
.code-wrapper {
    position: relative;
    background: #E8EEF1;

    .new-tab {
        position: absolute;
        right: 4px;
    }
}

.tech-preview{
    position: absolute;
    top: -20px;
    left: 10px;
}
</style>

<template>
    <div class="workspace-card-container d-inline-flex flex-wrap">
        <v-card
            v-for="card in cards"
            :key="card.title"
            outlined
            class="workspace-card"
            width="330"
            :loading="servicesLoading">
            <div class="d-flex justify-center pt-12">
                <TechPreview
                    v-if="card.isTechPreview"
                    class="mt-8 d-inline" />
                <v-icon
                    color="blue-azure"
                    size="54"
                    class="mr-3">
                    {{ card.icon }}
                </v-icon>
                <div class="d-flex flex-column">
                    <span
                        class="d-block workspace-card__title color text-blue-azure">
                        {{ card.title }}
                    </span>
                    <span class="workspace-card__sub-title">
                        {{ card.subTitle }}
                    </span>
                </div>
            </div>
            <div
                class="d-flex flex-column workspace-card__text px-7 pt-5 pb-2 color text-navigation text-center">
                <p>{{ card.info }}</p>
                <div>
                    <span class="font-weight-medium">
                        {{ card.serviceAvailToUse }}
                    </span>
                    <span> {{ $t("toUseWith", { target: card.title }) }}</span>
                </div>
            </div>
            <v-card-actions class="justify-center mb-4">
                <v-btn
                    color="primary"
                    small
                    rounded
                    outlined
                    class="px-5 color bg-background text-capitalize font-weight-medium"
                    @click="exploreAction(card)">
                    {{ card.actionTxt }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <!-- Open Serverless selection Dialog -->
        <base-dialog
            v-model="openServerlessSelection"
            :min-width="500"
            :max-width="500">
            <template #title>
                {{ $t('selectServerless') }}
            </template>
            <template #body>
                <div class="mt-6">
                    <div
                        v-for="service in activeServerlessServices"
                        :key="service.id"
                        class="d-flex mt-2 code-wrapper pr-10 pa-3 rounded">
                        <a
                            rel="noopener noreferrer"
                            :href="skyBookUrl(service)"
                            target="_blank">{{ service.name }}</a>
                        <div
                            class="new-tab pointer"
                            @click="goToNotebook(service)">
                            <v-icon color="primary">
                                mdi-open-in-new
                            </v-icon>
                        </div>
                    </div>
                </div>
            </template>
            <template #actions="{ cancel }">
                <v-spacer />
                <v-btn
                    color="primary"
                    depressed
                    rounded
                    outlined
                    class="cancel"
                    @click="cancel">
                    <span class="text-none">{{ $t('close') }} </span>
                </v-btn>
            </template>
        </base-dialog>
    </div>
</template>
<script>
    import TechPreview from 'components/common/TechPreview.vue'
    import { mapGetters } from 'vuex'
    export default {
        name: 'WorkspaceNavCard',
        components: { TechPreview, },
        data(){
            return {
                openServerlessSelection: false,
            }
        },
        computed: {
            ...mapGetters([
                'servicesLoading',
                'servicesForQueryEditor',
                'serverlessServices'
            ]),
            serverServicesLength() {
                return this.servicesForQueryEditor.length
            },
            hasServiceMaxScale() {
                return Boolean(this.servicesForQueryEditor.length)
            },
            activeServerlessServices(){
                return this.serverlessServices.filter(service=> service.status === this.$config.serviceStatus.READY)
            },
            serverlessServicesLength() {
                return this.activeServerlessServices.length
            },
            hasServerlessService() {
                return Boolean(this.serverlessServicesLength)
            },
            cards() {
                return [
                    {
                        title: this.$t('skyQuery'),
                        type: 'skyQuery',
                        subTitle: this.$t('forServers'),
                        icon: '$vuetify.icons.dbQuery',
                        info: this.$t('info.skyQueryCard'),
                        serviceAvailToUse: this.hasServiceMaxScale
                            ? this.$tc('serviceAvail', this.serverServicesLength, [this.serverServicesLength])
                            : this.$t('noServiceAvail'),
                        to: this.hasServiceMaxScale
                            ? '/workspace/query-editor'
                            : '/launch-service',
                        actionTxt: this.hasServiceMaxScale
                            ? this.$t('explore')
                            : `+ ${this.$t('launchAService')}`,
                    },
                    {
                        title: this.$t('skybook'),
                        type: 'serverless',
                        subTitle: this.$t('forServerless'),
                        icon: '$vuetify.icons.skybook',
                        info: this.$t('info.skyBookCard'),
                        serviceAvailToUse: this.hasServerlessService
                            ? this.$tc('serviceAvail', this.serverlessServicesLength, [
                                this.serverlessServicesLength
                            ])
                            : this.$t('noServiceAvail'),
                        to: this.hasServerlessService
                            ? '/workspace/sky-book'
                            : '/launch-service',
                        actionTxt: this.hasServerlessService
                            ? this.$t('explore')
                            : `+ ${this.$t('launchAServerlessService')}`,
                        isTechPreview: true,
                    }
                ]
            },
        },
        methods: {
            openSASelection(){
                this.openServerlessSelection = true
            },
            exploreAction(card){
                if(card.type === 'serverless' && this.serverlessServicesLength) return this.openSASelection()
                this.$router.push(card.to)
            },
            goToNotebook(service){
                window.open(this.skyBookUrl(service))
            },
            skyBookUrl(service) {
                return `https://${service.fqdn}/skybook`
            },
        },
    }
</script>
