export function xpandActivityTil ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(mariadb_xpand_activity_til{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name, agg)`
    }
}

export function xpandActivityCore0Node ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(mariadb_xpand_activity_core0{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}
