<template>
    <div>
        {{ name }}
        <v-icon
            v-if="configUrl"
            size="18"
            class="ml-1 mb-1 pointer"
            color="primary"
            @click="openDocument">
            $vuetify.icons.questionCircle
        </v-icon>
    </div>
</template>
<script>
    export default {
        name: 'ConfigName',
        props: {
            name: {
                type: String,
                default: '',
            },
            configUrl: {
                type: String,
                default: '',
            },
        },
        methods: {
            openDocument() {
                if (this.configUrl) {
                    window.open(this.configUrl, '_blank')
                }
            },
        },
    }
</script>
