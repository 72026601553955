<style lang="scss" scoped>
.is-disabled {
    color: $grayed-out
}
</style>
<template>
    <div>
        <div>
            <v-icon
                v-if="isConnectionConfigured"
                data-auto="security-endpoint.healthy"
                size="20">
                $vuetify.icons.endpointsHealthy
            </v-icon>
            <v-icon
                v-else
                data-auto="security-endpoint.unhealthy"
                color="error"
                size="20">
                $vuetify.icons.endpointsUnHealthy
            </v-icon>
            <span
                data-auto="security-endpoint.label"
                class="ml-2">{{ securityLabel }}</span>
            <a
                data-auto="security-endpoint.action"
                :class="['ml-3', { 'is-disabled': !isReady }]"
                @click="onSecurityAction">{{ isConnectionConfigured ? $t('manage') : $t('setUp') }}</a>
        </div>
        <WhitelistModal ref="whitelistModal" />
    </div>
</template>

<script>
    import WhitelistModal from 'components/service/WhitelistModal.vue'
    import i18n from 'plugins/i18n'
    import { doPrivateConnectHaveProjects, getIpAllowList, isEndpointPrivateConnect, isServiceStatusReady, privateConnectLabel } from 'utils/service'
    import { mapActions } from 'vuex'

    const EndpointSecurity = {
        name: 'EndpointSecurity',
        components: {
            WhitelistModal,
        },
        props: {
            endpoint: {
                type: Object,
                required: true,
            },
            service: {
                type: Object,
                required: true,
            },
        },
        computed: {
            isPrivateServiceConnect() {
                return isEndpointPrivateConnect(this.endpoint)
            },
            securityLabel() {
                if (this.isPrivateServiceConnect) {
                    return privateConnectLabel(this.service.provider, true)
                }
                return i18n.t('launchService.allowIP')
            },
            isConnectionConfigured() {
                if (this.isPrivateServiceConnect) {
                    return doPrivateConnectHaveProjects([this.endpoint])
                }
                return Boolean(getIpAllowList([this.endpoint]).length)
            },
            isReady() {
                return isServiceStatusReady(this.service)
            },
        },
        methods: {
            ...mapActions(['openPrivateConnectModal']),
            onSecurityAction() {
                // If service is not ready, stop action
                if (!this.isReady) return
                if (this.isPrivateServiceConnect) {
                    return this.openPrivateConnectModal({ popupState: true, serviceId: this.service.id, })
                }
                this.$refs.whitelistModal.open(this.service.id)
            },
        },
    }

    export default EndpointSecurity
</script>
