<style lang="scss" scoped>
.chart-wrapper {
    width: 100%;
    height: 100%;
}
</style>

<template>
    <div class="text-center">
        <div class="text-h5">
            {{ value }}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SingleValue',
        props: {
            data: {
                type: Array,
                default: () => [],
            },
            options: {
                type: Object,
                default: () => { },
            },
        },
        data() {
            return {
                defaultOptions: {},
                fullOptions: {},
                value: [],
            }
        },
        watch: {
            data(newVal) {
                this.parseData(newVal)
            },
        },
        methods: {
            parseData(dataPoints) {
                const { format, } = this.fullOptions
                let series = []

                dataPoints.forEach(({ result, }) => {
                    const vectorData = result.map(({ value, }) => {
                        return format ? this.$format(format.type, value[1], format.decimals) : value[1]
                    })

                    series = series.concat(vectorData)
                })
                // Show the highest number from the vector
                this.value = this.fullOptions.showHighValue ? Math.max(...series) : series.join(', ')
            },
        },
        async created() {
            this.fullOptions = this.$lodash.merge(this.defaultOptions, this.options)
            this.parseData(this.data)
        },
    }
</script>
