<style lang="scss" scoped>
    .chart-wrapper {
        width: 100%;
        height: 100%;
    }
</style>

<template>
    <apexchart
        v-if="series.length"
        class="chart-wrapper"
        width="100%"
        height="100%"
        type="bar"
        :options="fullOptions.apex"
        :series="series" />
</template>

<script>
    export default {
        name: 'BarChart',
        props: {
            data: {
                type: Array,
                default: () => [],
            },
            options: {
                type: Object,
                default: () => {},
            },
        },
        data() {
            return {
                defaultOptions: {
                    apex: {
                        chart: {
                            toolbar: {
                                show: false,
                            },
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        legend: {
                            horizontalAlign: 'left',
                            showForSingleSeries: true,
                        },
                        xaxis: {
                            labels: {
                                datetimeUTC: false,
                            },
                        },
                        yaxis: {
                            labels: {},
                        },
                    },
                },
                fullOptions: {},
                series: [],
            }
        },
        watch: {
            data (newVal) {
                this.parseData(newVal)
            },
        },
        methods: {
            parseData (dataPoints) {
                let series = []

                dataPoints.forEach(({ result, resultType, }) => {
                    const vectorData = resultType === 'vector' ? this.mapVector(result) : this.mapMatrix(result)
                    series = series.concat(vectorData)
                })

                this.series = series
            },
            mapVector(data) {
                return data.map(({ metric, label, value, }) => {
                    return {
                        name: label || JSON.stringify(metric),
                        data: [
                            value.map(value => [value[0] * 1000, value[1]])
                        ],
                    }
                })
            },
            mapMatrix(data) {
                return data.map(({ metric, label, values, }) => {
                    return {
                        name: label || JSON.stringify(metric),
                        data: values.map(value => [value[0] * 1000, value[1]]),
                    }
                })
            },
        },
        async created () {
            const options = this.$lodash.merge(this.defaultOptions, this.options)
            if (options.format) {
                options.apex.yaxis.labels.formatter = (value) => {
                    return this.$format(options.format.type, value, options.format.decimals)
                }
            }
            this.fullOptions = options
            this.parseData(this.data)
        },
    }
</script>
