<style lang="scss" scoped>
    .error-banner {
        font-size: 13px;
        &:before {
            background: $error;
        }
    }
    .launch-img {
        height: 60px;
        vertical-align: middle;
    }
</style>

<template>
    <div v-if="isFailed">
        <v-alert
            class="error-banner ma-0 pa-0 px-2"
            tile
            text
            dense>
            <div class="py-2 text-center">
                {{ $t('errors.serviceLaunch') }}
                <a>{{ $t('contactSupport') }}</a>
            </div>
        </v-alert>

        <div class="py-6 font-azo-sans text-center">
            <v-icon size="40">
                $vuetify.icon.exclamation
            </v-icon>
            <span class="ml-3 font-weight-bold">{{ $t('errors.launchFailed') }}</span>
        </div>
    </div>
    <div
        v-else
        class="py-6 font-azo-sans text-center">
        <img
            src="@/assets/launch-progress.gif"
            class="launch-img mr-2" />
        <span class="font-weight-bold">{{ currentStep }}</span>
        <span v-if="showProgress"> ({{ progress }}%)</span>
    </div>
</template>

<script>
    import i18n from 'plugins/i18n'

    const ServiceProvisionStatus = {
        name: 'service-provision-status',
        props: {
            serviceStatus: {
                type: String,
                required: true,
            },
            progress: {
                type: Number,
                default: 0,
            },
            currentStep: {
                type: String,
                default: i18n.t('info.serviceLaunchProcess'),
            },
            showProgress:{
                type: Boolean,
                default: false,
            },
        },
        computed: {
            isFailed () {
                // TODO: Refactor after topology status integration
                const { FAILED, } = this.$config.serviceStatus
                return this.serviceStatus === FAILED
            },
        },
        methods: {
            openSecurityAccessDialog () {
                this.$emit('openSecurityAccessDialog')
            },
        },
    }

    export default ServiceProvisionStatus
</script>
