<style lang="scss">
    // the standard data table
    .data-table-full.v-data-table--fixed-header .v-data-table__wrapper table thead th {
        background: $table-border;
    }
    .data-table-full {
        a:hover {
            text-decoration: underline;
        }
        table {
            th {
                padding: 0 24px;
                background-color: $table-border;
                text-transform: uppercase;
                &:last-child {
                    border-radius: 0 5px 0 0;
                }
                &:first-child {
                    border-radius: 5px 0 0 0;
                }
                .toggle-icon {
                    color: inherit;
                }
            }
            tbody {
                tr {
                    &.selected {
                        background: $light-cyan !important;
                    }
                    td {
                        padding: 0 24px;
                        border-bottom: 1px solid $table-border;
                        &:last-child {
                            border-right: 1px solid $table-border;
                        }
                        &:first-child {
                            border-left: 1px solid $table-border;
                        }
                        .cell-accent {
                            height: 100%;
                            padding: 0 24px;
                            margin-left: -24px;
                            display: flex;
                            border-left: 8px solid #828282;
                            &.info-accent {
                                border-color: $accent;
                            }
                            &.success-accent {
                                border-color: $success;
                            }
                            &.error-accent {
                                border-color: $error;
                            }
                            &.warning-accent {
                                border-color: $warning;
                            }
                        }
                        &.actions {
                            text-align: right;

                            button {
                                opacity: 0;
                            }
                        }
                    }
                    &:hover {
                        td.actions button {
                            opacity: 1;
                        }
                    }
                    &.last-row {
                        td {
                            &:last-child {
                                border-radius: 0 0 5px 0;
                            }
                            &:first-child {
                                border-radius: 0 0 0 5px;
                            }
                        }
                    }
                }
            }
        }

        .v-data-footer {
            justify-content: center;
        }

        .v-data-footer__select {
            .v-select {
                margin-left: 8px;

                .v-input__slot {
                    padding: 0 8px;
                    border: 1px solid $tabs-border;
                    border-radius: 4px;
                    &:before,
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
</style>
<template>
    <div>
        <v-data-table
            item-key="name"
            :headers="visibleHeaders"
            :items="data"
            :hide-default-footer="data.length <= itemsPerPage"
            :hide-default-header="true"
            :footer-props="{ 'items-per-page-options': [] }"
            :class="['data-table-full', tableClass]"
            :search="search"
            :loading="loading"
            :loader-height="2"
            :options.sync="pagination"
            :items-per-page="itemsPerPage"
            :page="page"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            :server-items-length="totalItems">
            <template
                v-if="hasColumnToggle && isColumnToggleVisible"
                #body>
                <tr>
                    <td
                        class="pl-4"
                        :colspan="visibleHeaders.length"
                        style="height: 100%">
                        <div
                            class="d-flex"
                            style="height: 40px">
                            <v-checkbox
                                v-for="(header, i) in headers"
                                :key="`check-${i}`"
                                v-model="visible"
                                color="#0e9bc0"
                                class="ma-0 py-2 pa-0 pr-2"
                                :label="header.text || header.value"
                                :value="header" />
                        </div>
                    </td>
                </tr>
            </template>
            <template #header>
                <thead class="v-data-table-header">
                    <tr>
                        <th
                            v-for="(header, i) in visibleHeaders"
                            :key="header.text"
                            :width="header.width"
                            :class="[
                                'text-no-wrap',
                                header.sortable !== false ? 'pointer sortable' : '',
                                pagination.sortDesc[0] ? 'desc' : 'asc',
                                header.value === pagination.sortBy[0] ? 'active' : ''
                            ]"
                            @click="changeSort(header.value)">
                            <div
                                v-if="i === headers.length - 1"
                                class="d-flex justify-space-between">
                                <div class="my-auto">
                                    <span>{{ header.text }}</span>
                                    <v-icon
                                        v-if="header.sortable !== false"
                                        class="v-data-table-header__icon">
                                        mdi-chevron-up
                                    </v-icon>
                                </div>
                                <v-spacer />
                                <search-box
                                    v-if="hasSearch && !isColumnToggleVisible"
                                    v-model="search"
                                    class="pl-2 color text-text border-text-subtle"
                                    position="right"
                                    @click.native.stop />
                                <v-icon
                                    v-if="hasColumnToggle"
                                    size="18"
                                    class="pl-4 pr-0 toggle-icon"
                                    @click.stop="columnToggle">
                                    mdi-eye
                                </v-icon>
                            </div>
                            <template v-else>
                                <span>{{ header.text }}</span>
                                <v-icon
                                    v-if="header.sortable !== false"
                                    class="v-data-table-header__icon">
                                    mdi-chevron-up
                                </v-icon>
                            </template>
                        </th>
                    </tr>
                </thead>
            </template>
            <template #item="{ item, index }">
                <tr
                    :class="{ pointer: onRowClick || $scopedSlots['expandable'], 'last-row': index === data.length - 1 }"
                    @mouseenter="showHiddenData($event, item)"
                    @mouseleave="hiddenData.visible = false"
                    @mousemove="move($event)"
                    @click="rowClick(item, headers, visibleHeaders)">
                    <td
                        v-for="(header, i) in visibleHeaders"
                        :key="i"
                        :class="[header.value, header.tdClass || header.class]"
                        @click="cellClick(item, headers, visibleHeaders)">
                        <!-- optional slot for a expand row indicator, as for now showing in the first td -->
                        <slot
                            v-if="i === 0 && $scopedSlots['expandable']"
                            name="expandIndicator"
                            :expanded="expandedRows.includes(item.id)"></slot>

                        <slot
                            :name="header.value"
                            :data="{ item, header }">
                            <!-- no content for the corresponding header, usually this is an error -->
                            <span v-if="$typy(item[header.value]).isUndefined"></span>

                            <!-- regular cell -->
                            <span v-else>{{ getValue(item, header) }}</span>
                        </slot>
                    </td>
                </tr>
                <!-- optional expandable row -->
                <tr
                    v-if="expandedRows.includes(item.id)"
                    class="expanded-row">
                    <td
                        colspan="100%"
                        style="padding: 0">
                        <slot
                            :name="'expandable'"
                            :data="{ item, headers }"></slot>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <v-tooltip
            v-if="hiddenData.visible"
            v-model="hiddenData.visible"
            bottom
            nudge-top="20"
            transition="fade-transition"
            :position-y="hiddenData.posY"
            :position-x="hiddenData.posX">
            <slot
                name="hiddenData"
                :data="{ hiddenData, hiddenHeaders }">
                <div
                    v-for="(header, i) in hiddenHeaders"
                    :key="`hiddenHeader-${i}`">
                    {{ header.text }}: <b>{{ getValue(hiddenData.value, header) }}</b><br />
                </div>
            </slot>
        </v-tooltip>
    </div>
</template>

<script>
    /*eslint-disable */
    // TODO: This components needs refactoring
    export default {
        name: 'DataTable',
        props: {
            headers: { type: Array, },
            data: { type: Array, },
            tableClass: { type: String, default: 'data-table-full', },
            hasSearch: { type: Boolean, default: false, },
            hasColumnToggle: { type: Boolean, default: false, },
            onRowClick: { type: Function, },
            onCellClick: { type: Function, },
            loading: { type: Boolean, default: false, },
            totalItems: { type: Number, },
            itemsPerPage: { type: Number, default: 10, },
            page: { type: Number, default: 1, },
            sortBy: { type: String, },
            sortDesc: { type: Boolean, },
        },
        data () {
            return {
                search: '',
                expandedRows: [],
                pagination: {},
                visible: [],
                isColumnToggleVisible: false,
                hiddenData: {
                    visible: false,
                    value: null,
                    posX: 0,
                    posY: 0,
                },
            }
        },
        computed: {
            visibleHeaders () {
                return this.headers.filter(header => this.visible.includes(header))
            },
            hiddenHeaders () {
                return this.headers.filter(header => !this.visible.includes(header))
            },
        },
        watch: {
            pagination: {
                handler (val) {
                    this.$emit('pagination', val)
                },
                deep: true,
            },
            data: {
                handler (list) {
                    list.forEach(item => {
                        if (item.expanded) this.expandedRows.push(item.id)
                    })
                },
            },
        },
        methods: {
            changeSort (column) {
                // TODO: support multiple column sorting
                if (this.pagination.sortBy[0] === column) {
                    this.pagination.sortDesc = [!this.pagination.sortDesc[0]]
                } else {
                    this.pagination.sortBy = [column]
                    this.pagination.sortDesc = [false]
                }
            },
            rowClick (item, headers) {
                if (this.$scopedSlots['expandable']) this.toggleRow(item.id)
                if (this.onRowClick) this.onRowClick(item, headers)
            },
            cellClick (item, header) {
                if (this.onCellClick) this.onCellClick(item, header)
            },
            toggleRow (id) {
                if (!this.$scopedSlots['expandable']) return

                const isOpen = this.expandedRows.includes(id)

                if (isOpen) {
                    this.expandedRows = this.expandedRows.filter(i => i !== id)
                } else {
                    this.expandedRows.push(id)
                }
            },
            getValue (item, header) {
                const value = item[header.value] ? (item[header.value].text || item[header.value]) : 'n/a'

                return this.$typy(header.format).isFunction ? header.format(value) : value
            },
            columnToggle () {
                this.isColumnToggleVisible = !this.isColumnToggleVisible
            },
            showHiddenData (e, item) {
                if (!this.hasColumnToggle || this.visible.length === this.headers.length) return

                setTimeout(() => {
                    this.hiddenData = {
                        visible: true,
                        value: item,
                        posX: e.clientX,
                        posY: e.target.getBoundingClientRect().top,
                    }
                }, 0)
            },
            move (e) {
                this.hiddenData.posX = e.clientX
            },
        },
        created () {
            this.visible = this.headers.filter(h => !h.hidden)
        },
    }
</script>
