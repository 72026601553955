<style lang="scss" scoped>
    path {
        fill: currentColor;
    }
</style>

<template>
    <svg viewBox="0 0 16 18">
        <path
            d="M 6.7961359,0 C 5.8834581,0 5.148543,0.734919 5.148543,1.6475929 V 2.255701 H 0.68654984 c -0.35492887,0 -0.63471284,0.279782
            -0.63471284,0.6347128 0,0.3549308 0.27978397,0.6328125 0.63471284,0.6328126 H 1.9787796 V 15.845017 C 1.9787796,17.036617
            2.9422113,18 4.1337627,18 h 7.9358103 c 1.191601,0 2.154983,-0.963383 2.154983,-2.154983 V 3.5232264 h 1.088894 c 0.354941,0
            0.634713,-0.2778818 0.634713,-0.6328126 0,-0.3549308 -0.304574,-0.6347128 -0.659418,-0.6347128 H 11.030089 V 1.6475929
            C 11.030089,0.734919 10.293313,0 9.3805954,0 Z m 0,1.2675254 h 2.6110643 c 0.2028162,0 0.3800671,0.1772513 0.3800671,0.3800675
            V 2.255701 H 6.4160684 V 1.6475929 c 0,-0.2028162 0.1772416,-0.3800675 0.3800675,-0.3800675 z M 3.2216005,3.5232264 H 12.930426
            V 15.845017 h 0.0266 c 0,0.481719 -0.405738,0.887458 -0.887458,0.887458 H 4.1090583 c -0.4816897,0 -0.8874578,-0.405739
            -0.8874578,-0.887458 z m 2.1549831,1.850929 c -0.3549309,0 -0.6347128,0.2797821 -0.6347128,0.6347128 v 8.2132608 c 0,0.354842
            0.279782,0.634712 0.6347128,0.634712 0.3549308,0 0.6328125,-0.27987 0.6328125,-0.634712 V 6.0088682 c 0,-0.3549308 -0.2778815,
            -0.6347128 -0.6328125,-0.6347128 z m 2.7117821,0 c -0.3549308,0 -0.6328125,0.2797821 -0.6328125,0.6347128 v 8.2132608
            c 0,0.354842 0.2778817,0.634712 0.6328125,0.634712 0.3549308,0 0.6347129,-0.27987 0.6347129,-0.634712 V 6.0088682
            c 0,-0.3549308 -0.2797821,-0.6347128 -0.6347129,-0.6347128 z m 2.7630913,0 c -0.354844,0 -0.632812,0.2797821 -0.632812,0.6347128
            v 8.2132608 c 0,0.354842 0.303266,0.634712 0.632812,0.634712 0.35494,0 0.634713,-0.27987 0.634713,-0.634712 V 6.0088682
            c 0,-0.3549308 -0.279773,-0.6347128 -0.634713,-0.6347128 z" />
    </svg>
</template>

<script>
    export default {}
</script>
