<style lang="scss" scoped>
    .activeLabel {
        background: $error;
        color: $background;
        font-size: 9px;
    }
    .dot {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: $background;
        animation: blinker 1.5s linear infinite;
    }
    @keyframes blinker {
        50% {
            opacity: 0;
        }
    }
</style>

<template>
    <div class="px-2 py-1 rounded activeLabel d-flex align-center justify-center">
        <span class="text-uppercase font-weight-bold"> {{ $t('active') }} </span>
        <span class="dot ml-2"></span>
    </div>
</template>

<script>
    export default {
        name: 'ActiveLabel',
    }
</script>
