<style lang="scss" scoped>
    path {
        fill: currentColor;
    }
</style>

<template>
    <svg viewBox="0 0 18 18">
        <path
            d="M 0.58012503,17.990911 C 0.3678169,17.954057 0.16063794,17.790092 0.06315862,17.581784 c -0.09526019,-0.203567 -0.10759926,
        -0.09712 0.29822426,-2.572667 0.19902908,-1.214089 0.37580748,-2.254319 0.39284087,-2.311622 0.0170334,-0.0573 0.0553377,-0.13846
        0.0851207,-0.180351 C 0.91261942,12.414082 12.467106,0.85434977 12.621415,0.72972492 c 0.291741,-0.23561885 0.67636,-0.44358427
        1.041852,-0.56333423 1.055659,-0.34587634 2.093889,-0.15071396 3.02464,0.56855959 0.06676,0.0515926 0.130916,0.0938046 0.142562,
        0.0938046 0.04796,0 0.186038,0.10944397 0.303871,0.24086462 0.447181,0.4987479 0.715034,1.0574607 0.827091,1.7252225 0.04433,
        0.264148 0.03987,0.8069375 -0.0089,1.0792315 -0.102616,0.5733699 -0.323684,1.0612562 -0.685614,1.5131113 -0.05972,0.074561
        -2.725114,2.7535849 -5.923093,5.9533872 -5.7583738,5.761656 -5.815665,5.818371 -5.9344762,5.874714 -0.1132114,0.05368 -0.167274,
        0.06452 -0.9597537,0.192323 -0.4618814,0.07449 -1.3112635,0.211607 -1.8875157,0.304712 -0.5762521,0.09311 -1.1988923,0.193632
        -1.3836449,0.223396 -0.41783695,0.06731 -0.49012093,0.07399 -0.59834648,0.05519 z M 3.1885327,16.168645 4.7615521,15.912538
        9.6043094,11.069964 C 12.267825,8.4065475 14.447066,6.2201704 14.447066,6.2113473 c 0,-0.00882 -0.595657,-0.611667 -1.323683,
        -1.339653 L 11.7997,3.5480831 6.9421116,8.4068232 2.0845228,13.265564 1.8339611,14.80117 c -0.1378091,0.844583 -0.2543931,1.555401
        -0.2590756,1.579594 -0.00631,0.0326 -0.00215,0.04399 0.016057,0.04399 0.013514,0 0.7324295,-0.115248 1.5975901,-0.256108
        z M 15.896112,4.7771826 c 0.377275,-0.3825204 0.50282,-0.5693655 0.610912,-0.9092076 0.0715,-0.224803 0.08872,-0.3623628 0.07943,
        -0.6347031 -0.0063,-0.1853639 -0.01722,-0.2719629 -0.05,-0.3970322 C 16.486014,2.6437572 16.338318,2.337238 16.222767,2.1852319
        15.559709,1.3129849 14.365692,1.154823 13.527477,1.828208 13.465987,1.877604 13.279448,2.058629 13.112939,2.2304859 l -0.302745,
        0.312467 1.325274,1.3253455 1.325273,1.3253454 0.07301,-0.061548 c 0.04016,-0.033851 0.203217,-0.1935623 0.362356,-0.3549131 z" />
    </svg>
</template>

<script>
    export default {}
</script>
