<style lang="scss" scoped>
    path {
        fill: currentColor;
    }
</style>

<template>
    <svg
        viewBox="0 0 22 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.38308 18.2028H2.87318C1.47273 18.2028 0.333496 17.2439 0.333496 16.0651V2.47093C0.333496 1.29216 1.47273 0.333252 2.87318 0.333252H19.1271C20.5276 0.333252 21.6668 1.29216 21.6668 2.47093V16.0651C21.6668 17.2439 20.5276 18.2028 19.1271 18.2028H13.5906H8.38308ZM2.66681 1.18839C1.8275 1.18839 1.143 1.76455 1.143 2.471V16.0652C1.143 16.7717 1.8275 17.3478 2.66681 17.3478H8.80153H13.1674H19.3097C20.149 17.3478 20.8335 16.7717 20.8335 16.0652V2.471C20.8335 1.76455 20.149 1.18839 19.3097 1.18839H2.66681ZM7.3335 6.16659C7.3335 6.44278 7.10579 6.66659 7.03047 6.66659H4.30319C4.22787 6.66659 4.00016 6.44278 4.00016 6.16659C4.00016 5.8904 4.22787 5.66659 4.30319 5.66659H7.03047C7.10579 5.66659 7.3335 5.8904 7.3335 6.16659ZM17.5759 6.66659C17.8103 6.66659 18.0002 6.44278 18.0002 6.16659C18.0002 5.8904 17.8103 5.66659 17.5759 5.66659H9.09107C8.85673 5.66659 8.66683 5.8904 8.66683 6.16659C8.66683 6.44278 8.85673 6.66659 9.09107 6.66659H17.5759ZM7.3335 9.16659C7.3335 9.44278 7.10579 9.66659 7.03047 9.66659H4.30319C4.22787 9.66659 4.00016 9.44278 4.00016 9.16659C4.00016 8.8904 4.22787 8.66659 4.30319 8.66659H7.03047C7.10579 8.66659 7.3335 8.8904 7.3335 9.16659ZM17.5759 9.66659C17.8103 9.66659 18.0002 9.44278 18.0002 9.16659C18.0002 8.8904 17.8103 8.66659 17.5759 8.66659H9.09107C8.85673 8.66659 8.66683 8.8904 8.66683 9.16659C8.66683 9.44278 8.85673 9.66659 9.09107 9.66659H17.5759ZM7.3335 12.1666C7.3335 12.4428 7.10579 12.6666 7.03047 12.6666H4.30319C4.22787 12.6666 4.00016 12.4428 4.00016 12.1666C4.00016 11.8904 4.22787 11.6666 4.30319 11.6666H7.03047C7.10579 11.6666 7.3335 11.8904 7.3335 12.1666ZM17.5759 12.6666C17.8103 12.6666 18.0002 12.4428 18.0002 12.1666C18.0002 11.8904 17.8103 11.6666 17.5759 11.6666H9.09107C8.85673 11.6666 8.66683 11.8904 8.66683 12.1666C8.66683 12.4428 8.85673 12.6666 9.09107 12.6666H17.5759Z"
            fill="#424F62"
            stroke="#424F62"
            stroke-width="0.3" />
    </svg>
</template>

<script>
    export default {}
</script>
