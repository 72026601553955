import Vue from 'vue'
import store from 'store/index'

export default {
    put (url, body, params, options) {
        return Vue.axios.put(url, body, {
            params,
            headers: {
                Authorization: `Bearer ${store.getters.authToken}`,
            },
            ...options,
        })
    },
    post (url, body, params, options) {
        return Vue.axios.post(url, body, {
            params,
            headers: {
                Authorization: `Bearer ${store.getters.authToken}`,
            },
            ...options,
        })
    },
    get (url, params, options = {}) {
        options.headers = options.headers || {}
        options.headers['Authorization'] = `Bearer ${store.getters.authToken}`
        return Vue.axios.get(url, {
            params,
            ...options,
        })
    },
    delete (url, params, options) {
        return Vue.axios.delete(url, {
            data: {
                ...params,
            },
            headers: {
                Authorization: `Bearer ${store.getters.authToken}`,
            },
            ...options,
        })
    },
    patch (url, body, options) {
        return Vue.axios.patch(url, body, {
            headers: {
                Authorization: `Bearer ${store.getters.authToken}`,
            },
            ...options,
        })
    },
}
