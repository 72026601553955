import { CONFIG } from 'config/'
import moment from 'moment'
const { NAME, TYPE, } = CONFIG.TAG_BODY

export function createCustomTag(value) {
    return {
        name: NAME.SEVERITY,
        type: TYPE.CUSTOM,
        value,
    }
}
export function createSystemTag(value) {
    return {
        name: NAME.CONTEXT,
        type: TYPE.SYSTEM,
        value,
    }
}

export function findActiveSilence(silences, context) {
    return silences.find(silence => {
        if (silence.context == context) {
            // There can be multiple such silences which were not cancelled/deleted
            // Only need the one that is active with respect to current time
            const start = moment(silence['start_time']).unix()
            const end = moment(silence['end_time']).unix()
            const now = moment().unix()
            return start <= now && now <= end
        }
    })
}
