<template>
    <div
        v-if="!$typy(service).isEmptyObject"
        class="pa-0 pt-4 fill-height">
        <ConnectionManager
            v-if="isServiceReady"
            ref="skyQueryConnectionManager"
            v-model="service"
            @is-initializing="isInitializing = $event" />
        <SkyQueryEditor
            v-if="isSkyQueryAccessible && isServiceReady"
            :is-initializing="isInitializing"
            class="fill-height">
            <template #txt-editor-toolbar-right-slot>
                <ToolbarBtns />
            </template>
            <template #ddl-editor-toolbar-right-slot>
                <ToolbarBtns />
            </template>
        </SkyQueryEditor>
        <div
            v-else
            class="pl-3 pt-3">
            <EndpointAction
                v-if="$unleash.isFeatureEnabled('enable-portal-dual-endpoints')"
                :service="service" />
            <p v-else>
                {{ $t('info.queryEditorAccessibleCondition') }}
            </p>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    import SkyQueryEditor from 'components/skyQuery/SkyQueryEditor'
    import ToolbarBtns from 'components/skyQuery/ToolbarBtns.vue'
    import ConnectionManager from 'components/skyQuery/ConnectionManager.vue'
    import EndpointAction from 'components/skyQuery/EndpointAction.vue'

    export default {
        name: 'SkyQuery',
        components: {
            SkyQueryEditor,
            ToolbarBtns,
            ConnectionManager,
            EndpointAction,
        },
        beforeRouteLeave(to, from, next) {
            if (this.isSkyQueryAccessible && this.isServiceReady && !this.isInitializing) {
                this.$typy(this.$refs, 'skyQueryConnectionManager.beforeRouteLeaveHandler').safeFunction(to, from, next)
            }
            else next()
        },
        data() {
            return {
                isInitializing: true,
            }
        },
        computed: {
            ...mapGetters(['getServiceById', 'readyServicesForQueryEditor']),
            ...mapState({
                isSkyQueryAccessible: state => state.skyQuery.isSkyQueryAccessible,
            }),
            service() {
                return this.getServiceById(this.$route.params.id)
            },
            isServiceReady() {
                return this.readyServicesForQueryEditor.some(s => s.id === this.service.id)
            },
        },
    }
</script>
