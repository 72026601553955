var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-tooltip',{attrs:{"disabled":!_vm.servers.length && !_vm.topology,"nudge-left":_vm.nudgeLeft,"open-delay":"400","color":"rgba(0, 53, 69, 0.9)","top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({},on),[_vm._t("default")],2)]}}],null,true)},[_c('div',[(_vm.topology)?_c('div',{staticClass:"topology pb-1 mb-1 font-weight-bold"},[(_vm.topologyStatus)?_c('icon-sprite-sheet',{staticClass:"status-icon mr-1",attrs:{"size":"16","frame":_vm.topologyStatus,"color":_vm.topologyStatus === 'STARTING' ||
                        _vm.topologyStatus === 'STOPPING' ||
                        _vm.topologyStatus === 'STOPPED' ||
                        _vm.topologyStatus === 'SCALINGUP' ||
                        _vm.topologyStatus === 'SCALINGDOWN'
                        ? 'white'
                        : ''}},[_vm._v(" health ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.topology.name)+" "),_c('span',{staticClass:"grey--text text--lighten-1"},[_vm._v(" "+_vm._s(_vm.$help.getTopologyName(_vm.topology.topology, true))+" ")]),(_vm.topology.inboundReplication && _vm.topology.inboundReplication.internalReplica === null)?_c('span',{staticClass:"d-block pl-7 color red--text text--accent-2 font-weight-regular"},[_vm._v(" "+_vm._s(_vm.$tc('errors.replicationNotRunning'))+" ")]):_vm._e()],1):_vm._e(),(!_vm.servers.length)?_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$tc('info.noServers'))+" ")]):_vm._e(),_vm._l((_vm.servers),function(server){return _c('div',{key:server.id},[_c('icon-sprite-sheet',{staticClass:"status-icon mr-1",attrs:{"size":"13","frame":server.serverStatus,"color":server.serverStatus === 'STARTING' || server.serverStatus === 'STOPPING' ? 'white' : ''}},[_vm._v(" server ")]),(server.serverStatus === 'CRITICAL' || server.serverStatus === 'WARNING')?[_c('span',{class:[`color ${server.serverStatus === 'CRITICAL' ? 'red--text text--accent-2' : 'text-warning'}`],domProps:{"innerHTML":_vm._s(
                        _vm.$t(`serverStatusInfo.${server.serverStatusInfo ? server.serverStatusInfo.toLowerCase() : 'error'}`, {
                            name: `<span class='white--text'>${server.name}</span>`,
                            time: _vm.$moment(server.statusChangedAt).fromNow()
                        })
                    )}}),_c('inbound-replication-info',{staticClass:"d-block pl-7",attrs:{"topology":_vm.topology,"server":server}})]:(server.serverStatus === 'OK')?[_vm._v(" "+_vm._s(server.name)+" "),_c('span',{staticClass:"more-info grey--text text--lighten-1"},[(_vm.topology && _vm.topology.topologyType === 'galera' && server.serverRole === 'server')?_c('span',[_vm._v(" "+_vm._s(_vm.$tc(`serverStatusInfo.${server.serverStatusInfo ? server.serverStatusInfo.toLowerCase() : 'error'}`))+" ("+_vm._s(server.serverStatusInfo)+") ")]):(server.metadata.primaryReplicaRole)?_c('span',[_c('icon-sprite-sheet',{staticClass:"status-icon mb-1",attrs:{"frame":server.metadata.primaryReplicaRole.toUpperCase(),"size":"13","color":"white"}},[_vm._v(" server ")])],1):_c('span',[_vm._v(" "+_vm._s(_vm.$t(`serverRole.${server.serverRole}`))+" ")])]),(server.metadata && _vm.$typy(server.metadata.secondsBehindMaster).isNumber)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({staticClass:"color text-text-subtle"},on),[_vm._v(" (+"+_vm._s(server.metadata.secondsBehindMaster > 0 ? server.metadata.secondsBehindMaster.toFixed(0) : server.metadata.secondsBehindMaster)+"s) ")])]}}],null,true)},[_c('div',[_vm._v(_vm._s(_vm.$t('lag_in_secs')))])]):_vm._e(),(server.metadata && _vm.$typy(server.metadata.sendQueue || server.metadata.receiveQueue).isNumber)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({staticClass:"color text-text-subtle"},on),[_vm._v(" (↑"+_vm._s(server.metadata.sendQueue)+"/↓"+_vm._s(server.metadata.receiveQueue)+") ")])]}}],null,true)}):_vm._e(),_c('inbound-replication-info',{staticClass:"d-block pl-7",attrs:{"topology":_vm.topology,"server":server}})]:[_c('span',{staticClass:"more-info grey--text text--lighten-1",domProps:{"innerHTML":_vm._s(
                        _vm.$t(`serverStatusInfo.${server.serverStatusInfo ? server.serverStatusInfo.toLowerCase() : 'error'}`, {
                            name: `<span class='white--text'>${server.name}</span>`,
                            time: _vm.$moment(server.statusChangedAt).fromNow()
                        })
                    )}})]],2)})],2)])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }