<style lang="scss" scoped>
.grayed-text{
    color: $auro-metal-saurus;
}

</style>
<template>
    <div>
        <div>
            <v-checkbox
                v-model="isSingleZone"
                class="pt-0 mt-0"
                @change="handleSelect">
                <template #label>
                    <div>
                        {{ isSingleNode ? $t('launchService.singleZoneDeploySingleNode'): $t('launchService.singleZoneDeploy') }}
                        <HelpTooltip>
                            <template #message>
                                <p> {{ $t('launchService.zoneToolTip') }} </p>
                            </template>
                        </HelpTooltip>
                    </div>
                </template>
            </v-checkbox>
        </div>
        <div v-if="isSingleZone">
            <v-row>
                <v-col cols="6">
                    <div class="text-caption grayed-text mb-2 ml-1">
                        {{ $t('launchService.zoneSelectTitle') }}
                    </div>
                    <v-select
                        v-model="currentZone"
                        :items="zones"
                        item-text="name"
                        item-value="name"
                        required
                        :rules="[(val) => !!val || $t('errors.fieldRequired', { field: $t('launchService.zone') })]"
                        :menu-props="{ top: false, offsetY: true }"
                        outlined
                        @change="onZoneSelect" />
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script>
    import HelpTooltip from 'components/common/HelpTooltip.vue'
    export default {
        name: 'CloudZoneSelection',
        components: { HelpTooltip, },
        props: {
            zones: {
                type: Array,
                required: true,
            },
            isSingleNode: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            return {
                isSingleZone: false,
                currentZone: null,
            }
        },
        watch: {
            zones(values){
                // reset the currentZone if region is changed
                if(values) {
                    this.currentZone = ''
                }
            },
            isSingleZone(value){
                // if selection is made set first zone
                // as default value
                if(value){
                    this.currentZone = this.zones[0].name
                    this.$emit('on-zone-select', this.currentZone)
                }
            },
        },
        methods: {
            onZoneSelect() {
                this.$emit('on-zone-select', this.currentZone)
            },
            handleSelect(){
                if(!this.isSingleZone) this.currentZone = ''
                this.$emit('on-single-zone-opted', this.isSingleZone)
            },
        },
    }
</script>
