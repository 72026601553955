<style lang="scss" scoped>
.replica-controls {
    padding-left: 2rem;
    margin-top: 1.5rem;
}
</style>

<template>
    <div class="replica-controls">
        <v-icon
            class="minus-icon mr-2"
            size="25"
            :disabled="isDecreaseDisabled"
            :data-auto="dataAutoPrefix('content-replica-subtract')"
            @click="decrease">
            {{ isDecreaseDisabled ? '$vuetify.icons.minusDisabled' : '$vuetify.icons.minus' }}
        </v-icon>
        <span class="text--disabled">x</span> {{ currentCount }}
        <v-icon
            class="plus-icon ml-1"
            size="25"
            :disabled="isIncreaseDisabled"
            :data-auto="dataAutoPrefix('content-replica-add')"
            @click="increase">
            {{ isIncreaseDisabled ? '$vuetify.icons.plusDisabled' : '$vuetify.icons.plus' }}
        </v-icon>
    </div>
</template>
<script>
    const ReplicaSelector = {
        name: 'ReplicaSelector',
        props: {
            count: Number,
            parentDataAutoPrefix: Function,
            isDisabled: Boolean,
            range: Array,
        },
        data() {
            return {
                currentCount: this.count,
                rangePointer: this.range.indexOf(this.count),
            }
        },
        computed: {
            isIncreaseDisabled() {
                return this.isDisabled || this.range.length - 1 === this.rangePointer
            },
            isDecreaseDisabled() {
                return this.isDisabled || !this.rangePointer
            },
        },
        methods: {
            decrease() {
                if (this.rangePointer > 0 && this.range.length > 0) {
                    this.rangePointer--
                    this.currentCount = this.range[this.rangePointer]
                    this.$emit('handleReplicaChange', this.currentCount)
                }
            },
            increase() {
                if (this.rangePointer < this.range.length - 1) {
                    this.rangePointer++
                    this.currentCount = this.range[this.rangePointer]
                    this.$emit('handleReplicaChange', this.range[this.rangePointer])
                }
            },
            dataAutoPrefix(string) {
                return this.parentDataAutoPrefix(this.type + '-' + string)
            },
        },
        created() {
            if (this.hasRange) {
                this.rangePointer = this.range.indexOf(this.currentCount)
            }
        },
    }

    export default ReplicaSelector
</script>
