<style lang="scss" scoped>
.highlighted {
    border: 3px solid $selection-highlight;
}

.release-title {
    color: $blue-azure;
    font-size: 35px;
}

.release-wrapper {
    max-width: 470px;

    .release-card {
        min-height: 400px;
    }
}
</style>
<template>
    <div>
        <base-dialog
            v-model="shouldShowDialog"
            :min-width="700"
            :max-width="700"
            :is-action-separator-required="false"
            :on-close="cancelNavigation">
            <template #title>
                <p class="font-weight-regular text-h4 ma-0">
                    {{ $t('previousRelease.title') }}
                </p>
                <i18n
                    class="text-body-2 pt-3 mr-10"
                    path="previousRelease.subTitle"
                    tag="p">
                    <a
                        place="contactUs"
                        rel="noopener noreferrer"
                        :href="$config.CSM_URL"
                        target="_blank">
                        {{ $t('contact-us') }}</a>
                </i18n>
            </template>
            <template #body>
                <v-container class="release-wrapper pa-0">
                    <v-card class="highlighted release-card rounded-lg mt-4">
                        <div class="text-center pt-12 pb-2">
                            <v-card-title class="release-title py-0 justify-center">
                                {{ $t('previousRelease.cardTitle') }}
                            </v-card-title>
                        </div>

                        <v-card-text>
                            <div class="px-3">
                                <div>
                                    {{ $t('previousRelease.cardDescription') }}
                                </div>
                                <div class="mt-4 text-capitalize font-weight-bold">
                                    {{ $t('previousRelease.featureText') }}
                                </div>
                                <div class="mt-5">
                                    <FeatureLine
                                        v-for="(feature, i) in features"
                                        :key="i"
                                        :text="feature" />
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-container>
                <div class="text-center mt-10">
                    <v-btn
                        color="primary"
                        depressed
                        rounded
                        outlined
                        @click="triggerAction">
                        <span class="text-none text-body-1 px-3">{{ $t('previousRelease.switchToPrevious') }}</span>
                    </v-btn>
                </div>
            </template>
            <template #actions>
                <div></div>
            </template>
        </base-dialog>
    </div>
</template>
<script>
    import FeatureLine from 'components/common/FeatureLine.vue'
    export default {
        name: 'PreviousReleaseModal',
        components: { FeatureLine, },
        props: {
            value: Boolean,
        },
        data() {
            return {
                shouldShowDialog: false,
            }
        },
        computed: {
            features() {
                return this.$i18n.t('previousRelease.features')
            },
        },
        watch: {
            value(val) {
                this.shouldShowDialog = val
            },
        },
        methods: {
            cancelNavigation() {
                this.shouldShowDialog = false
                this.$emit('on-cancel-navigation')
            },
            triggerAction() {
                this.$emit('on-action')
            },
        },
    }
</script>
