<template>
    <svg
        width="24"
        height="39"
        viewBox="0 0 24 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_333_8328)">
            <path
                d="M23.19 7.12C23.1862 7.60156 23.0982 8.07875 22.93 8.53C22.3107 9.9151 21.2338 11.045 19.88 11.73C17.402 12.9918 14.6607 13.6496 11.88 13.6496C9.09924 13.6496 6.35794 12.9918 3.87998 11.73C2.53985 11.0363 1.47504 9.908 0.859976 8.53C0.691765 8.07875 0.603773 7.60156 0.599976 7.12C0.599976 5.45 1.69998 3.78 3.90998 2.51C6.38794 1.24818 9.12924 0.590408 11.91 0.590408C14.6907 0.590408 17.432 1.24818 19.91 2.51C22.09 3.78 23.19 5.45 23.19 7.12Z"
                fill="#61C9D2"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M23.19 7.12V24.94C23.19 26.61 22.09 28.28 19.88 29.55C17.402 30.8118 14.6607 31.4696 11.88 31.4696C9.09924 31.4696 6.35794 30.8118 3.87998 29.55C1.69998 28.28 0.599976 26.61 0.599976 24.94V7.12C0.603773 7.60157 0.691765 8.07876 0.859976 8.53C1.48266 9.91281 2.55863 11.0417 3.90998 11.73C8.89339 14.2676 14.9266 14.2676 19.91 11.73C21.2638 11.045 22.3407 9.9151 22.96 8.53C23.118 8.07693 23.1958 7.5998 23.19 7.12Z"
                fill="#2F99A3"
                stroke="#003545"
                stroke-width="0.8"
                stroke-miterlimit="10" />
            <path
                d="M23.1899 32.62V24.94"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M18.2899 36.88V30.32"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M5.5 36.88V30.32"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M11.9 38.03V31.46"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M0.599976 32.62V24.94"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_333_8328">
                <rect
                    width="23.79"
                    height="38.63"
                    fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
    export default {}
</script>
