import serviceDefaultDatabase from 'data/monitoring/dashboards/service/default-database.json'
import serviceDefaultQueries from 'data/monitoring/dashboards/service/default-queries.json'
import serviceDefaultStatus from 'data/monitoring/dashboards/service/default-status.json'
import serviceDefaultSystem from 'data/monitoring/dashboards/service/default-system.json'

import servicePrimaryReplicaStatus from 'data/monitoring/dashboards/service/primaryreplica-status.json'
import servicePrimaryReplicaLags from 'data/monitoring/dashboards/service/primaryreplica-lags.json'
import serviceMPrimaryReplicaDatabase from 'data/monitoring/dashboards/service/primaryreplica-database.json'

import serviceColumnstoreStatus from 'data/monitoring/dashboards/service/cs-status.json'
import serviceColumnstoreSystem from 'data/monitoring/dashboards/service/cs-system.json'

import serverDefaultStatus from 'data/monitoring/dashboards/server/default-status.json'
import serverDefaultDatabase from 'data/monitoring/dashboards/server/default-database.json'
import serverDefaultCaches from 'data/monitoring/dashboards/server/default-caches.json'
import serverDefaultQueries from 'data/monitoring/dashboards/server/default-queries.json'
import serverDefaultSystem from 'data/monitoring/dashboards/server/default-system.json'

import serverMaxScaleStatus from 'data/monitoring/dashboards/server/maxscale-status.json'
import serverMaxScaleClusterStatus from 'data/monitoring/dashboards/server/maxscale-cluster-status.json'
import serverMaxScalePerformance from 'data/monitoring/dashboards/server/maxscale-performance.json'
import serverMaxScaleModules from 'data/monitoring/dashboards/server/maxscale-modules.json'

import serverXpandStatus from 'data/monitoring/dashboards/server/xpand-status.json'
import serverXpandSystem from 'data/monitoring/dashboards/server/xpand-system.json'
import serverXpandHistorical from 'data/monitoring/dashboards/server/xpand-historical.json'

import serverColumnstoreStatus from 'data/monitoring/dashboards/server/cs-status.json'
import serverColumnstoreSystem from 'data/monitoring/dashboards/server/cs-system.json'

import serviceXpandStatus from 'data/monitoring/dashboards/service/xpand-status.json'
import serviceXpandQueries from 'data/monitoring/dashboards/service/xpand-queries.json'
import serviceXpandConnections from 'data/monitoring/dashboards/service/xpand-connections.json'
import serviceXpandSystem from 'data/monitoring/dashboards/service/xpand-system.json'
import serviceXpandSHistorical from 'data/monitoring/dashboards/service/xpand-historical.json'

export default [
    serviceDefaultDatabase,
    serviceDefaultQueries,
    serviceDefaultStatus,
    serviceDefaultSystem,

    servicePrimaryReplicaStatus,
    servicePrimaryReplicaLags,
    serviceMPrimaryReplicaDatabase,

    serviceColumnstoreStatus,
    serviceColumnstoreSystem,

    serverDefaultStatus,
    serverDefaultDatabase,
    serverDefaultCaches,
    serverDefaultQueries,
    serverDefaultSystem,

    serverMaxScaleStatus,
    serverMaxScaleClusterStatus,
    serverMaxScalePerformance,
    serverMaxScaleModules,

    serverXpandStatus,
    serverXpandSystem,
    serverXpandHistorical,

    serverColumnstoreStatus,
    serverColumnstoreSystem,

    serviceXpandStatus,
    serviceXpandQueries,
    serviceXpandConnections,
    serviceXpandSystem,
    serviceXpandSHistorical
]
