<style lang="scss" scoped>
    .code-wrapper{
        position: relative;
        background: #E8EEF1;
    }
    .copy-btn {
        position: absolute;
            right: 4px;
            top: 50%;
        margin-top: -14px;
    }
</style>

<template>
    <div class="code-wrapper pr-10 pa-3 rounded">
        <div
            v-for="(line, i) in lines"
            :key="i">
            {{ line }}
        </div>
        <v-btn
            class="copy-btn"
            color="primary"
            icon
            small
            @click="copyToClipboard(text)">
            <v-icon>mdi-content-copy</v-icon>
        </v-btn>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'CodeBlock',
        props: {
            text: {
                type: String,
                required: true,
            },
        },
        computed: {
            lines () {
                return this.text.split('\n')
            },
        },
        methods: {
            ...mapActions(['copyToClipboard']),
        },
    }
</script>
