<style lang="scss">
.selectable-table {
    .v-data-table__checkbox {
        transform: scale(0.625);
        transform-origin: 50% 80%;
        margin: 0px !important;
    }

    tbody {
        tr {
            cursor: pointer;

            &.v-data-table__selected {
                background: $light-cyan !important;
            }
        }
    }

    .v-data-footer {
        border-top: none !important
    }
}
</style>
<template>
    <v-data-table
        v-model="selectedRows"
        class="data-table-full selectable-table"
        :sort-desc="true"
        must-sort
        checkbox-color="primary"
        v-bind="{ ...$attrs }"
        @click:row="selectable ? selectRow($event) : null"
        v-on="$listeners">
        <template
            v-for="slot in Object.keys($scopedSlots)"
            #[slot]="slotData">
            <slot
                :name="slot"
                v-bind="slotData">
            </slot>
        </template>
    </v-data-table>
</template>

<script>
    export default {
        name: 'SelectableTable',
        inheritAttrs: false,
        computed: {
            selectable() {
                return this.$attrs['single-select'] || this.$attrs['show-select']
            },
            selectedRows: {
                get() {
                    return this.$attrs.value
                },
                set(v) {
                    this.$emit('input', v)
                },
            },
            displayedItems() {
                const { page = 1, itemsPerPage = 10, } = this.$attrs.options || {}
                const startIndex = (page - 1) * itemsPerPage
                const endIndex = startIndex + itemsPerPage
                const { items = [], } = this.$attrs || {}
                return items.slice(startIndex, endIndex)
            },
            tableHeight() {
                const headerHeight = this.$attrs['hide-default-header'] ? 0 : 48
                const footerHeight = this.$attrs['hide-default-footer'] ? 0 : 60
                const rowsLength = this.displayedItems.length
                const bodyHeight = (rowsLength ? rowsLength : 1) * 48 // no-data-text row
                return bodyHeight + headerHeight + footerHeight
            },
        },
        watch: {
            tableHeight: {
                immediate: true,
                handler(v) {
                    this.$emit('table-height', v)
                },
            },
        },
        methods: {
            selectRow(item) {
                const index = this.selectedRows.findIndex(
                    (selectedItem) => this.$lodash.isEqual(selectedItem, item)
                )
                if (index === -1) {
                    if (this.$attrs['single-select']) this.selectedRows = [item]
                    else this.selectedRows.push(item)
                } else {
                    this.selectedRows.splice(index, 1)
                }
            },
        },
    }
</script>
