import organization from 'api/organization'
import { convertToSnakeCase } from 'helpers/snakeCase'

export async function getProjects () {
    return organization.get('projects').then(response => {
        return response.data
    })
}

export async function upgradeTier(upgradeProfile) {
    return organization.patch('orgs/upgrade', convertToSnakeCase(upgradeProfile)).then(response => {
        return response.data
    })
}

export async function getOrgDetails(orgId) {
    return organization.get(`orgs/${orgId}`).then((response) => {
        return response.data
    })
}
