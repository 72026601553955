import { CONFIG } from 'config'
import i18n from 'plugins/i18n'

export function getDocumentationLinks(partner) {
    let docLinks

    const { PARTNER_INTEGRATIONS, } = CONFIG

    switch (partner.toLowerCase()) {
    case 'qlik':
        docLinks = PARTNER_INTEGRATIONS.qlik.docLinks
        break
    case 'striim':
        docLinks = PARTNER_INTEGRATIONS.striim.docLinks
        break
    case 'mindsdb':
        docLinks = PARTNER_INTEGRATIONS.mindsdb.docLinks
        break
    case 'hasura':
        docLinks = PARTNER_INTEGRATIONS.mindsdb.docLinks
        break
    case 'liquibase':
        docLinks = PARTNER_INTEGRATIONS.liquibase.docLinks
        break
    case 'hashicorp':
        docLinks = PARTNER_INTEGRATIONS.hashicorp.docLinks
        break
    default:
    }

    const partnerTranslateKey = `partnerIntegrations.partners.${partner.toLowerCase()}.docs`
    let docText = []
    if (i18n.te(partnerTranslateKey)) docText = i18n.t(partnerTranslateKey)

    if (docText.length)
        return docText.map((text, index) => ({ text, link: docLinks[index], }))
    return []
}

export function getDescriptionLinks(partner) {
    let links
    switch (partner.toLowerCase()) {
    case 'qlik':
        links = [
            'https://www.qlik.com/us/streaming-data/data-streaming-cdc',
            'https://www.qlik.com/us/products/catalog-and-lineage'
        ]
        break
    default:
    }

    const partnerTranslateKey = `partnerIntegrations.partners.${partner.toLowerCase()}.descriptionTexts`
    let descTexts = []
    if (i18n.te(partnerTranslateKey)) descTexts = i18n.t(partnerTranslateKey)

    if (descTexts.length)
        return descTexts.map((text, index) => ({ text, link: links[index], }))
    return []
}
