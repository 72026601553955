<style lang="scss" scoped>

.footnote{
    width: 300px;
}
</style>
<template>
    <v-container
        class="ma-0 pa-12"
        fluid>
        <v-col
            cols="12"
            class="pa-0 ma-0 wrapper-container">
            <WorkspaceHeader class="mb-12" />
            <WorkspaceNavCard />
            <div class="footnote mt-4 text-caption">
                <i18n
                    path="privateConnect.workspaceNote"
                    tag="p">
                    <span
                        class="font-weight-bold"
                        place="note">{{ $t('note') }}</span>
                </i18n>
            </div>
        </v-col>
    </v-container>
</template>

<script>
    import WorkspaceNavCard from 'components/workspace/WorkspaceNavCard'
    import WorkspaceHeader from 'components/workspace/WorkspaceHeader'
    export default {
        name: 'Workspace',
        components: {
            WorkspaceNavCard,
            WorkspaceHeader,
        },
    }
</script>
