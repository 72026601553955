export default Object.freeze({
    qlik: {
        docLinks: [
            'https://www.qlik.com/us/trial/replicate?ga-link=qdi-hero',
            'https://r.mariadb.com/Partner/Qlik'
        ],
    },
    striim: {
        docLinks: [
            'https://go2.striim.com/aws-trial',
            'https://r.mariadb.com/Partner/Striim'
        ],
    },
    mindsdb: {
        docLinks: [
            'https://cloud.mindsdb.com/register/mariadbskysql',
            'https://r.mariadb.com/Partner/MindsDB'
        ],
    },
    hasura: {
        docLinks: [
            'https://hasura.io/docs/latest/enterprise/try-hasura-enterprise-edition/'
        ],
    },
    liquibase: {
        docLinks: ['https://hubs.li/Q01X9yY_0'],
    },
    hashicorp: {
        docLinks: [
            'https://app.terraform.io/public/signup/account?_gl=1*214m4e*_ga*MjY2Njc0MTkwLjE2ODY3Nzk0ODI.*_ga_P7S46ZYEKW*MTY4NzM3OTIyNS4xLjAuMTY4NzM3OTIyNS42MC4wLjA.&ajs_aid=feecbb95-4501-4a23-8895-9f159e1408a5&product_intent=terraform'
        ],
    },
})
