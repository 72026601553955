<style lang="scss" scoped>
.notice {
    background: $estimate-calculations;
}
</style>
<template>
    <div>
        <div
            v-if="!isPrivateConnect"
            class="notice rounded pa-4 mt-4">
            <i18n
                v-if="hasIpAllowList"
                path="dualEndpoints.connectModalManageIP">
                <a
                    place="action"
                    class="color text-links text-decoration-underline pointer"
                    @click="openSetupWhiteList">{{
                        $t('dualEndpoints.manageAllowlist') }}</a>
            </i18n>
            <i18n
                v-else
                path="dualEndpoints.addIPForConnectModal">
                <a
                    place="action"
                    class="color text-links text-decoration-underline pointer"
                    @click="openSetupWhiteList">{{
                        $t('dualEndpoints.manageAllowlist') }}</a>
            </i18n>
        </div>
        <div
            v-else
            class="notice rounded pa-4 mt-4">
            <div v-if="hasPrivateConnectProjects">
                <i18n
                    path="dualEndpoints.makeSureToSetupInConnectCloud"
                    tag="p">
                    <span
                        class="font-weight-medium"
                        place="important">{{ $t('privateConnect.important') }}:</span>
                    <span place="privateConnectType">
                        {{ privateConnectType }}
                    </span>
                    <span
                        place="provider"
                        class="text-uppercase">
                        {{ service.provider }}
                    </span>
                    <a
                        place="instructions"
                        rel="noopener noreferrer"
                        target="_blank"
                        :href="instructionsLink">{{
                            $t('dualEndpoints.accountSetupInstructions', { provider: service.provider.toUpperCase() }) }}</a>
                </i18n>
            </div>
            <div v-else>
                <i18n path="dualEndpoints.setupPrivateServiceConnect">
                    <span place="privateConnectType">
                        {{ privateConnectType }}
                    </span>
                    <span place="idType">
                        {{ privateConnectEntity(service.provider) }}
                    </span>
                    <span
                        place="action"
                        class="color text-links text-decoration-underline pointer"
                        @click="openPrivateConnectSetup">
                        {{ $t('dualEndpoints.completeSetup') }}</span>
                </i18n>
            </div>
        </div>
    </div>
</template>
<script>
    import { getIpAllowList, getPrivateConnectInstructionLink, privateConnectEntity } from 'utils/service'

    const ConnectModalWarningBlock = {
        name: 'ConnectModalWarningBlock',
        props: {
            privateConnectType: {
                type: String,
                required: false,
            },
            hasPrivateConnectProjects: {
                type: Boolean,
                required: true,
            },
            isPrivateConnect: {
                type: Boolean,
                default: false,
            },
            service: {
                type: Object,
                required: true,
            },
            endpoint: {
                type: Object,
                required: true,
            },
        },
        computed: {
            instructionsLink() {
                return getPrivateConnectInstructionLink(this.service.provider)
            },
            hasIpAllowList() {
                return Boolean(this.getIpAllowList([this.endpoint]).length)
            },
        },
        methods: {
            openPrivateConnectSetup() {
                return this.$emit('on-setup-private-connect')
            },
            privateConnectEntity,
            openSetupWhiteList() {
                return this.$emit('on-manage-allowlist')
            },
            getIpAllowList,
        },
    }

    export default ConnectModalWarningBlock
</script>
