<template>
    <svg
        width="18"
        height="13"
        viewBox="0 0 18 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2012_39803)">
            <path
                d="M1 0H17V12H1V0Z"
                fill="#EC1C24" />
            <path
                d="M5.75 7.5H7.75V9.5H10V7.5H12V5.25H10V3.25H7.75V5.25H5.75V7.5Z"
                fill="white" />
            <path
                d="M-1.79004 0H19V12.7423H-1.79004V0Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
        </g>
        <rect
            x="0.5"
            y="0.5"
            width="17"
            height="11.6844"
            stroke="#D2D2D2" />
        <defs>
            <clipPath id="clip0_2012_39803">
                <rect
                    x="1"
                    y="1"
                    width="16"
                    height="10.6844"
                    fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
    export default {}
</script>
