
<style lang="scss" scoped>
    .auto-container{
        background-color: $cornsilk;
        border-radius: 8px;
    }
</style>
<template>
    <v-row class="auto-container pa-0">
        <v-col class="pa-0 ma-0">
            <v-checkbox
                v-model="selected"
                class="pa-0 pl-4"
                :label="title"
                @change="onChange" />
        </v-col>
    </v-row>
</template>
<script>
    const AutoScaleOptions = {
        name: 'AutoScaleOption',
        props: {
            title: {
                type: String,
                required: true,
            },
            isSelected: {
                type: Boolean,
                required: true,
            },
        },
        data(){
            return {
                selected: this.isSelected,
            }
        },
        methods:{
            onChange(){
                this.$emit('onChange', this.selected)
            },
        },
        mounted(){
            this.$emit('onChange', this.selected)
        },
    }

    export default AutoScaleOptions
</script>
