export function serviceDiskSizeUsedBy ({ name, id, diskPurpose, byServer, isRemoteService, } = {}, datasource) {
    switch (datasource) {
    default:
        if (isRemoteService) {
            const onPremMetric = diskPurpose == 'data' ? 'mariadb_datadir_bytes_used' : 'mariadb_datadir_binlog_bytes_used'
            return `sum(${onPremMetric}{mariadb="${name}", namespace="${id}"})${byServer ? ' by (kubernetes_pod_name)' : ''}`
        }
        return `sum(kubelet_volume_stats_used_bytes{mariadb="${name}", namespace="${id}", disk_purpose="${diskPurpose}"})${byServer ? ' by (kubernetes_pod_name)' : ''}`
    }
}

export function serviceDiskSizeCapacityBy ({ name, id, diskPurpose, byServer, isRemoteService, } = {}, datasource) {
    switch (datasource) {
    default:
        if (isRemoteService) {
            const onPremMetric = diskPurpose == 'data' ? 'mariadb_datadir_bytes_capacity' : 'mariadb_datadir_binlog_bytes_capacity'
            return `sum(${onPremMetric}{mariadb="${name}", namespace="${id}"})${byServer ? ' by (kubernetes_pod_name)' : ''}`
        }
        return `max(kubelet_volume_stats_capacity_bytes{mariadb="${name}", namespace="${id}", disk_purpose="${diskPurpose}"})${byServer ? ' by (kubernetes_pod_name)' : ''}`
    }
}

export function serviceDiskSizeUsageBy ({ name, id, diskPurpose, isRemoteService, } = {}, datasource) {
    switch (datasource) {
    default:
        if (isRemoteService) {
            const onPremMetricUsed = diskPurpose == 'data' ? 'mariadb_datadir_bytes_used' : 'mariadb_datadir_binlog_bytes_used'
            const onPremMetricCapacity = diskPurpose == 'data' ? 'mariadb_datadir_bytes_capacity' : 'mariadb_datadir_binlog_bytes_capacity'
            return `sum((${onPremMetricUsed}{mariadb="${name}", namespace="${id}"})/${onPremMetricCapacity}{mariadb="${name}", namespace="${id}"}) by (kubernetes_pod_name)`
        }
        return `sum((kubelet_volume_stats_used_bytes{mariadb="${name}", namespace="${id}", disk_purpose="${diskPurpose}"})/kubelet_volume_stats_capacity_bytes{mariadb="${name}", namespace="${id}", disk_purpose="${diskPurpose}"}) by (kubernetes_pod_name)`
    }
}

export function serviceDiskSizeUsedByDataCs ({ id, isRemoteService, } = {}, datasource) {
    switch (datasource) {
    default:
        if (isRemoteService) {
            return `sum(mariadb_datadir_bytes_used{kubernetes_pod_name=~"cs-node.*", namespace="${id}"}) by (kubernetes_pod_name)`
        }

        return `sum(kubelet_volume_stats_used_bytes{kubernetes_pod_name=~"cs-node.*", namespace="${id}", disk_purpose="data"}) by (kubernetes_pod_name)`
    }
}

export function serviceDiskSizeCapacityByDataCs ({ id, isRemoteService, } = {}, datasource) {
    switch (datasource) {
    default:
        if (isRemoteService) {
            return `max(mariadb_datadir_bytes_capacity{kubernetes_pod_name=~"cs-node.*", namespace="${id}"})`
        }

        return `max(kubelet_volume_stats_capacity_bytes{kubernetes_pod_name=~"cs-node.*", namespace="${id}", disk_purpose="data"})`
    }
}

export function serviceDiskSizeUsedByLogsCs ({ id, isRemoteService, } = {}, datasource) {
    switch (datasource) {
    default:
        if (isRemoteService) {
            return `sum(mariadb_datadir_binlog_bytes_used{kubernetes_pod_name=~"cs-node.*", namespace="${id}"}) by (kubernetes_pod_name)`
        }

        return `sum(kubelet_volume_stats_used_bytes{kubernetes_pod_name=~"cs-node.*", namespace="${id}", disk_purpose="logs"}) by (kubernetes_pod_name)`
    }
}

export function serviceDiskSizeCapacityByLogsCs ({ id, isRemoteService, } = {}, datasource) {
    switch (datasource) {
    default:
        if (isRemoteService) {
            return `max(mariadb_datadir_binlog_bytes_capacity{kubernetes_pod_name=~"cs-node.*", namespace="${id}"})`
        }

        return `max(kubelet_volume_stats_capacity_bytes{kubernetes_pod_name=~"cs-node.*", namespace="${id}", disk_purpose="logs"})`
    }
}

export function serverDiskSizeUsedByData ({ name, id, isRemoteService, } = {}, datasource) {
    switch (datasource) {
    default:
        if (isRemoteService) {
            return `sum(mariadb_datadir_bytes_used{kubernetes_pod_name=~"${name}", namespace="${id}"}) by (kubernetes_pod_name)`
        }
        return `sum(kubelet_volume_stats_used_bytes{kubernetes_pod_name=~"${name}", namespace="${id}", disk_purpose="data"}) by (kubernetes_pod_name)`
    }
}

export function serverDiskSizeCapacityByData ({ name, id, isRemoteService, } = {}, datasource) {
    switch (datasource) {
    default:
        if (isRemoteService) {
            return `max(mariadb_datadir_bytes_capacity{kubernetes_pod_name=~"${name}", namespace="${id}"})`
        }
        return `max(kubelet_volume_stats_capacity_bytes{kubernetes_pod_name=~"${name}", namespace="${id}", disk_purpose="data"})`
    }
}

export function serverDiskSizeUsedByLogs ({ name, id, isRemoteService, } = {}, datasource) {
    switch (datasource) {
    default:
        if (isRemoteService) {
            return `sum(mariadb_datadir_binlog_bytes_used{kubernetes_pod_name=~"${name}", namespace="${id}"}) by (kubernetes_pod_name)`
        }
        return `sum(kubelet_volume_stats_used_bytes{kubernetes_pod_name=~"${name}", namespace="${id}", disk_purpose="logs"}) by (kubernetes_pod_name)`
    }
}

export function serverDiskSizeCapacityByLogs ({ name, id, isRemoteService, } = {}, datasource) {
    switch (datasource) {
    default:
        if (isRemoteService) {
            return `max(mariadb_datadir_binlog_bytes_capacity{kubernetes_pod_name=~"${name}", namespace="${id}"})`
        }
        return `max(kubelet_volume_stats_capacity_bytes{kubernetes_pod_name=~"${name}", namespace="${id}", disk_purpose="logs"})`
    }
}

export function xpandCapacityTotalUsagePercent ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(mariadb_xpand_capacity_disk_total_usage_percent{kubernetes_pod_name=~"${name}", kubernetes_namespace=~"${id}"}) by (kubernetes_pod_name)`
    }
}

export function xpandCapacityDiskSpaceUsage ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(mariadb_xpand_capacity_disk_total_usage_percent{mariadb=~"${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}

export function xpandIoDiskLatencySeconds ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(mariadb_xpand_io_disk_latency_seconds{kubernetes_pod_name=~"${name}", kubernetes_namespace=~"${id}"}[300s])) by (kubernetes_pod_name, op)`
    }
}

export function xpandIoDiskLatencySecondsMax ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `max(rate(mariadb_xpand_io_disk_latency_seconds{mariadb=~"${name}", kubernetes_namespace=~"${id}"}[300s])) by (op)`
    }
}

export function xpandCapacityDiskSystemMaxBytesUsage ({ name, id, type = 'mariadb', } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum((mariadb_xpand_capacity_disk_system_max_bytes{${type}=~"${name}", kubernetes_namespace=~"${id}"} - mariadb_xpand_capacity_disk_system_avail_bytes{${type}=~"${name}", kubernetes_namespace=~"${id}"})/(mariadb_xpand_capacity_disk_system_max_bytes{${type}=~"${name}", kubernetes_namespace=~"${id}"} * 1.2)) by (kubernetes_pod_name)`
    }
}

export function xpandCapacityDiskSystemMaxBytesCapacity ({ name, id, type = 'mariadb', } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(mariadb_xpand_capacity_disk_system_max_bytes{${type}=~"${name}", kubernetes_namespace=~"${id}"} * 1.2) by (kubernetes_pod_name)`
    }
}

export function xpandCapacityDiskSystemMaxBytesUsed ({ name, id, type = 'mariadb', } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(mariadb_xpand_capacity_disk_system_max_bytes{${type}=~"${name}", kubernetes_namespace="${id}"} - mariadb_xpand_capacity_disk_system_avail_bytes{${type}=~"${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}

export function xpandCapacityDiskSystemUsageRatio ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(mariadb_xpand_capacity_disk_system_usage_ratio{mariadb=~"${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}
