<template>
    <svg
        width="18"
        height="13"
        viewBox="0 0 18 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1697_21321)">
            <path
                d="M0 0.5H21V13.371H0V0.5Z"
                fill="#D22F27" />
            <path
                d="M0.000976562 0.500488H10.839V7.27367H0.000976562V0.500488Z"
                fill="#1E50A0" />
            <path
                d="M4.91936 3.38713L4.51324 2.19352L5.34376 3.14224L5.58898 1.90527L5.83387 3.14224L6.66439 2.19352L6.25827 3.38713L7.45189 2.98068L6.50316 3.81153L7.74013 4.05642L6.50316 4.30164L7.45189 5.13216L6.25827 4.72571L6.66439 5.91966L5.83387 4.97093L5.58898 6.2079L5.34376 4.97093L4.51324 5.91966L4.91936 4.72571L3.72574 5.13216L4.67447 4.30164L3.4375 4.05642L4.67447 3.81153L3.72574 2.98068L4.91936 3.38713V3.38713Z"
                fill="white"
                stroke="white"
                stroke-width="0.5"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M5.58811 5.44087C6.35301 5.44087 6.97309 4.82079 6.97309 4.05588C6.97309 3.29098 6.35301 2.6709 5.58811 2.6709C4.8232 2.6709 4.20312 3.29098 4.20312 4.05588C4.20312 4.82079 4.8232 5.44087 5.58811 5.44087Z"
                stroke="#1E50A0"
                stroke-width="0.5"
                stroke-miterlimit="10" />
        </g>
        <rect
            x="0.5"
            y="0.5"
            width="17"
            height="11.6844"
            stroke="#D2D2D2" />
        <defs>
            <clipPath id="clip0_1697_21321">
                <rect
                    x="1"
                    y="1"
                    width="16"
                    height="10.6844"
                    fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
    export default {}
</script>
