<style lang="scss" scoped>
    .control-label {
        font-size: 0.675rem;
    }
    .scale-reduce {
        transform: scale(0.725);
        transform-origin: 50% 50%;
    }
    .downloadBtn {
        margin: 0% 5% 0% 5%;
    }
</style>

<template>
    <div>
        <div class="d-flex align-end justify-space-between mb-2">
            <div class="d-flex">
                <div style="width: 328px">
                    <label class="d-block color text-small-text control-label">
                        {{ $t('context') }}
                    </label>
                    <select-servers
                        :preselected="preselectedServices"
                        :filter="servers"
                        @change="onContextChange" />
                </div>
                <div
                    class="ml-2"
                    style="width: 235px">
                    <label class="d-block color text-small-text control-label">
                        {{ $t('logType') }}
                    </label>
                    <div>
                        <v-select
                            :value="selectedLogTypes"
                            multiple
                            :items="logTypes"
                            return-object
                            item-text="txt"
                            item-value="val"
                            class="std std--dropdown"
                            :placeholder="$t('allLogs')"
                            :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list' }"
                            height="36"
                            outlined
                            attach
                            single-line
                            hide-details>
                            <template #prepend-item>
                                <v-list-item
                                    class="list-item context-list-item d-flex align-center pl-2"
                                    @click="toggleLogTypes">
                                    <v-checkbox
                                        class="scale-reduce"
                                        :input-value="areAllLogTypesSelected"
                                        primary />
                                    {{ $t('allLogs') }}
                                </v-list-item>
                            </template>
                            <template #item="{ item }">
                                <v-list-item
                                    class="list-item context-list-item d-flex align-center pl-2"
                                    @click="item.selected = !item.selected">
                                    <v-checkbox
                                        class="scale-reduce"
                                        :input-value="item.selected"
                                        primary />
                                    {{ item.txt }}
                                </v-list-item>
                            </template>

                            <template #selection="{ item, index }">
                                <span v-if="index === 0 && areAllLogTypesSelected">{{ $t('allLogs') }}</span>
                                <div
                                    v-else-if="index === 0"
                                    class="v-select__selection v-select__selection--comma">
                                    {{ item.txt }}
                                    <span
                                        v-if="selectedLogTypes.length > 1"
                                        class="grey--text text--lighten2">
                                        ({{ $t('plusMore', [selectedLogTypes.length - 1]) }})
                                    </span>
                                </div>
                            </template>
                        </v-select>
                    </div>
                </div>
                <div class="ml-2">
                    <label class="d-block color text-small-text control-label"> {{ $t('timeFrame') }} (UTC) </label>
                    <date-range-picker
                        ref="dateRangePicker"
                        :has-custom-range="true"
                        @change="onTimeChange" />
                </div>
            </div>
            <div class="text-center">
                <v-menu
                    v-model="downloadMenu"
                    :close-on-content-click="false"
                    :nudge-width="100"
                    :min-width="300"
                    offset-y>
                    <template #activator="{ on, attrs }">
                        <v-btn
                            color="primary"
                            :loading="isDownloading"
                            v-bind="attrs"
                            :disabled="selectedLogs.length == 0"
                            depressed
                            rounded
                            v-on="on">
                            <span class="text-none"> {{ $t('downloadN', [selectedLogs.length]) }}</span>
                        </v-btn>
                    </template>
                    <v-card background-color="white">
                        <v-list>
                            <v-list-item-content>
                                <v-list-item-title class="text-center">
                                    {{ $t('selectLogFormat') }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list>
                        <v-divider />
                        <v-list-item>
                            <v-list-item-action>
                                <v-switch
                                    v-model="downloadFileTypes.enableJson"
                                    color="primary" />
                            </v-list-item-action>
                            <v-list-item-title>{{ $t('logFormat.json') }}</v-list-item-title>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-action>
                                <v-switch
                                    v-model="downloadFileTypes.enableRaw"
                                    color="primary" />
                            </v-list-item-action>
                            <v-list-item-title>{{ $t('logFormat.raw') }}</v-list-item-title>
                        </v-list-item>
                        <v-card-actions background-color="white">
                            <v-spacer />
                            <v-btn
                                class="ml-2 float-right downloadBtn"
                                color="primary"
                                text
                                rounded
                                @click="cancelSelected">
                                <span class="text-nonel">{{ $t('cancel') }}</span>
                            </v-btn>
                            <v-btn
                                class="ml-2 float-right downloadBtn"
                                color="primary"
                                :disabled="!downloadFileTypes.enableRaw && !downloadFileTypes.enableJson"
                                rounded
                                depressed
                                @click="downloadSelected">
                                <span class="text-none">{{
                                    $t('downloadN', [
                                        selectedLogs.length * (+downloadFileTypes.enableRaw + +downloadFileTypes.enableJson)
                                    ])
                                }}</span>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
            </div>
        </div>

        <v-data-table
            class="data-table-full logs-table"
            :headers="headers"
            :items="logsData"
            :options.sync="options"
            :server-items-length="logsCount"
            :loading="isLoading"
            :footer-props="footerOptions"
            :sort-by="'startTime'"
            :sort-desc="true"
            :no-data-text="$t('info.noLogs')"
            must-sort>
            <template #[`header.select`]>
                <v-checkbox
                    class="checkbox-small ma-0"
                    :input-value="areAllLogsSelected"
                    :disabled="logsCount === 0"
                    primary
                    @change="toggleLogs" />
            </template>

            <template #item="{ item: log }">
                <tr
                    :class="['pointer', { selected: log.selected }]"
                    @click="log.selected = !log.selected">
                    <td>
                        <v-checkbox
                            class="checkbox-small ma-0"
                            :input-value="log.selected"
                            primary />
                    </td>
                    <td>{{ $t('logForX', [getLogTypeName(log.type), log.date.format('YYYY-MM-DD UTC (ddd)')]) }}</td>
                    <td>
                        <tag
                            :small="true"
                            color="#e8eef1">
                            <template slot="left-slot">
                                <v-icon size="12">
                                    mdi-server
                                </v-icon>
                            </template>
                            <span>
                                <span class="font-weight-medium"> {{ log.service }}</span>
                                <span> /{{ log.server }}</span>
                            </span>
                        </tag>
                    </td>
                    <td>
                        <v-tooltip top>
                            <template #activator="{ on }">
                                <span
                                    class="text-body-2 grey--text text--lighten2"
                                    v-on="on">
                                    {{ log.date.format('YYYY-MM-DD UTC (ddd)') }}
                                </span>
                            </template>
                            <span>{{ $moment(log.date).format('YYYY-MM-DD') }} (00:00 - 23:59 UTC)</span>
                        </v-tooltip>
                    </td>
                    <td>{{ log.size }}</td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import SelectServers from 'components/common/SelectServers'
    import Tag from 'components/alerts/Tag'
    import observability from 'api/observability'

    export default {
        name: 'Logs',
        components: {
            Tag,
            SelectServers,
        },
        props: {
            preselectedServices: { type: Array,  default: () => [],},
        },
        data () {
            return {
                isLoading: false,
                pollingInterval: null,
                isDownloading: false,
                options: {},
                serverContext: [],
                servers: [],
                logTypes: [],
                dateRange: { from: 0, to: 0, },
                logsCount: 0,
                logsData: [],
                headers: [
                    { text: 'select', value: 'select', sortable: false, width: '20px', },
                    { text: this.$t('logName'), value: 'name', sortable: false, },
                    { text: this.$tc('server', 1), value: 'server', sortable: false, },
                    { text: this.$t('date'), value: 'startTime', },
                    { text: this.$t('size'), value: 'size', sortable: false, }
                ],
                footerOptions: {
                    'items-per-page-options': [ 10, 20, 50, 100, -1],
                },
                downloadMenu: false,
                downloadFileTypes: JSON.parse(localStorage.getItem('downloadFileTypes')) || { enableJson: true, enableRaw: false, },
            }
        },
        computed: {
            ...mapGetters(['services']),
            selectedLogs () {
                return this.logsData.filter(log => log.selected)
            },
            areAllLogsSelected () {
                return this.logsData.length > 0 && this.selectedLogs.length === this.logsData.length
            },
            selectedLogTypes () {
                return this.logTypes.filter(type => type.selected)
            },
            areAllLogTypesSelected () {
                return this.logTypes.length === this.selectedLogTypes.length
            },
            enableRaw () {
                return  this.downloadFileTypes.enableRaw ? 'raw' : 'json'
            },
        },
        watch: {
            options: {
                handler () {
                    this.fetchLogs()
                },
                deep: true,
            },
            selectedLogTypes: {
                handler (val) {
                    if (!val.length && val.length === this.logTypes.length) return
                    this.fetchLogs()
                },
                deep: true,
            },
            downloadMenu: {
                handler (val) {
                    if (val) {
                        this.downloadFileTypes =
                            JSON.parse(localStorage.getItem('downloadFileTypes')) ||
                            { enableJson: true, enableRaw: false, }
                    }
                },
            },
        },
        methods: {
            startPollingLogs (interval) {
                this.pollingInterval = setInterval(() => {
                    this.fetchLogs()
                }, interval)
            },
            stopPollingLogs () {
                clearInterval(this.pollingInterval)
            },
            setDateRange (range) {
                this.dateRange = range
            },
            async fetchServers () {
                const { data, } = await observability.get('logs/servers')
                this.servers = data.servers || []
            },
            async fetchLogTypes () {
                const logTypes = []
                const logTypesReq = await observability.get('logs/types')
                logTypesReq.data.logTypes.forEach(({ name, logType, }) => {
                    logTypes.push({
                        txt: name,
                        val: logType,
                        selected: true,
                    })
                })
                this.logTypes = logTypes
            },
            getLogTypeName (type) {
                const logType = this.logTypes.find(({ val, }) => val === type)
                return this.$typy(logType).isObject ? logType.txt : this.$t('logTypes.unknown')
            },
            async fetchLogs () {
                if (!this.serverContext.length || !this.selectedLogTypes.length) {
                    this.logsData = []
                    return
                }

                const { sortBy, sortDesc, page, itemsPerPage, } = this.options
                const params = {
                    limit: itemsPerPage > 0 ? itemsPerPage : null,
                    offset: itemsPerPage > 0 ? (page - 1) * itemsPerPage : null,
                    fromDate: new Date(this.dateRange.from * 1000),
                    toDate: new Date(this.dateRange.to * 1000),
                    serverContext: this.serverContext,
                    orderByField: sortBy[0],
                    orderByDirection: sortDesc[0] ? 'desc' : 'asc',
                    logType: this.selectedLogTypes.map(type => type.val),
                }

                this.isLoading = true

                const logsReq = await observability.post('logs/query', params)
                this.parseLogs(logsReq.data)
                this.isLoading = false
            },
            parseLogs (data) {
                const logs = []

                data.logs.forEach(({ id, logType, serverDataSourceId, size, service, server, endTime, }) => {
                    logs.push({
                        id,
                        selected: false,
                        type: logType,
                        service: service || '',
                        server: server || '',
                        dataSourceId: serverDataSourceId,
                        date: endTime ? this.$moment(endTime).utc() : 'n/a',
                        size: this.$typy(size).isNumber ? this.$help.formatBytes(size) : 'n/a',
                    })
                })

                this.logsData = logs
                this.logsCount = data.count
            },
            toggleLogs (val) {
                this.logsData.forEach(log => (log.selected = val))
            },
            onTimeChange (range) {
                this.setDateRange(range)
                this.fetchLogs()
            },
            onContextChange (servers) {
                this.serverContext = servers
                this.fetchLogs()
            },
            async cancelSelected () {
                try {
                    this.downloadMenu = false
                    this.downloadFileTypes = JSON.parse(localStorage.getItem('downloadFileTypes'))
                } catch (err) {
                    this.$store.dispatch('showMessage', {
                        text: this.$t('errors.unknown'),
                        type: 'error',
                    })
                }
            },
            async downloadSelected () {
                this.downloadMenu = false
                localStorage.setItem('downloadFileTypes', JSON.stringify(this.downloadFileTypes))
                if (!this.selectedLogs.length) return

                this.isDownloading = true

                try {
                    const logIds = this.selectedLogs.reduce((acc, log) => `${acc}${log.id},`, '')
                    const params = {
                        logIds: logIds.slice(0, -1),
                        logFormat:
                            (
                                this.downloadFileTypes &&
                                this.downloadFileTypes.enableRaw &&
                                this.downloadFileTypes.enableJson
                            ) ? ['raw', 'json'].toString() : this.enableRaw,
                    }

                    const logsReq = await observability.get('logs/archive', params, {
                        responseType: 'blob',
                    })

                    const filename = logsReq.headers['content-disposition'] ?
                        logsReq.headers['content-disposition'].split('filename=')[1] : 'logs.zip'

                    this.$help.downloadURL(logsReq, filename)
                } catch (_) {
                    this.$store.dispatch('showMessage', {
                        text: this.$t('errors.unknown'),
                        type: 'error',
                    })
                }

                this.isDownloading = false
            },
            toggleLogTypes () {
                const state = !this.areAllLogTypesSelected
                this.logTypes.forEach(log => (log.selected = state))
            },
            checkLocalTimeDiff () {
                const time = this.$moment(new Date())
                const daysDiff = time.days() - time.utc().days()
                if (daysDiff === 0) return

                this.$store.dispatch('showMessage', {
                    text: this.$t(`info.${daysDiff < 0 ? 'behindOfUTC' : 'aheadOfUTC'}`, [time.format('DD MMM YYYY (UTC Z)')]),
                    type: 'info',
                    timeout: 10,
                })
            },
        },
        created () {
            this.checkLocalTimeDiff()
            this.fetchServers()
            this.fetchLogTypes()
            this.startPollingLogs(this.$config.logsFetchInterval * 1000)
        },
        beforeDestroy () {
            this.stopPollingLogs()
        },
    }
</script>
