<style lang="scss" scoped>
    path {
        fill: currentColor;
    }
</style>

<template>
    <svg
        width="16"
        height="16"
        viewBox="0 0 17 16"
        fill="none">
        <path
            d="M4.36933 0C4.16847 0 4.00569 0.1628 4.00569 0.363636V2.59659C3.38155 2.76004 2.91478 3.32701
            2.91478 4C2.91478 4.799 3.57031 5.45455 4.36933 5.45455C5.16834 5.45455 5.82387 4.799 5.82387
            4C5.82387 3.32701 5.35709 2.76004 4.73296 2.59659V0.363636C4.73296 0.1628 4.57016 0 4.36933
            0ZM12.3693 0C12.1685 0 12.0057 0.1628 12.0057 0.363636V2.59659C11.3815 2.76004 10.9148 3.32701
            10.9148 4C10.9148 4.799 11.5703 5.45455 12.3693 5.45455C13.1683 5.45455 13.8239 4.799 13.8239
            4C13.8239 3.32701 13.3571 2.76004 12.733 2.59659V0.363636C12.733 0.1628 12.5702 0 12.3693 0ZM1.82387
            1.09091C0.820036 1.09091 0 1.91094 0 2.91477V14.1761C0 15.18 0.820036 16 1.82387 16H14.9034C15.9073
            16 16.7273 15.18 16.7273 14.1761V2.91477C16.7273 1.91094 15.9073 1.09091 14.9034 1.09091H13.8182C13.6261
            1.08818 13.4489 1.26242 13.4489 1.45455C13.4489 1.64667 13.6261 1.82089 13.8182 1.81818H14.9034C15.5162
            1.81818 16 2.30195 16 2.91477V6.18182H0.727273V2.91477C0.727273 2.30195 1.21104 1.81818 1.82387
            1.81818H2.90909C3.1012 1.82091 3.27842 1.64667 3.27842 1.45455C3.27842 1.26242 3.1012 1.0882 2.90909
            1.09091H1.82387ZM5.81818 1.09091C5.61735 1.09091 5.45455 1.25371 5.45455 1.45455C5.45455 1.65538 5.61735
            1.81818 5.81818 1.81818H10.9091C11.1099 1.81818 11.2727 1.65538 11.2727 1.45455C11.2727 1.25371 11.1099
            1.09091 10.9091 1.09091H5.81818ZM4.36933 3.27273C4.77529 3.27273 5.0966 3.59402 5.0966 4C5.0966 4.40596
            4.77529 4.72727 4.36933 4.72727C3.96336 4.72727 3.64205 4.40596 3.64205 4C3.64205 3.59402 3.96336 3.27273
            4.36933 3.27273ZM12.3693 3.27273C12.7753 3.27273 13.0966 3.59402 13.0966 4C13.0966 4.40596 12.7753 4.72727
            12.3693 4.72727C11.9634 4.72727 11.6421 4.40596 11.6421 4C11.6421 3.59402 11.9634 3.27273 12.3693
            3.27273ZM0.727273 6.90909H16V14.1761C16 14.789 15.5162 15.2727 14.9034 15.2727H1.82387C1.21104 15.2727
            0.727273 14.789 0.727273 14.1761V6.90909Z" />
    </svg>
</template>

<script>
    export default {}
</script>
