import { render, staticRenderFns } from "./PendingScale.vue?vue&type=template&id=a71273ee&"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/skysql-ui-client/skysql-ui-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a71273ee')) {
      api.createRecord('a71273ee', component.options)
    } else {
      api.reload('a71273ee', component.options)
    }
    module.hot.accept("./PendingScale.vue?vue&type=template&id=a71273ee&", function () {
      api.rerender('a71273ee', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/icons/serviceStatus/PendingScale.vue"
export default component.exports