<style lang="scss" scoped>
.recommended-text {
    color: $accent;
    font-style: italic;
}

.maintenance-window-width {
    width: 330px !important;
}

.tech-position {
    position: absolute;
}
</style>
<template>
    <v-expansion-panel
        id="serverPanel"
        :disabled="isDisabled"
        :data-auto="dataAutoPrefix()">
        <AccordianPanelHeader
            :title="getLocaleFor('serviceAttributes')"
            :parent-data-auto-prefix="dataAutoPrefix">
            <!-- Tooltip goes here -->
            <template #tooltip-content>
                <p>{{ $t('launchService.serverInfo') }}</p>
                <a
                    rel="noopener noreferrer"
                    class="text-capitalize"
                    target="_blank"
                    href="https://r.mariadb.com/snr-launch-service-attribute">{{ $t('learn-more') }}</a>
            </template>
        </AccordianPanelHeader>
        <v-expansion-panel-content :data-auto="dataAutoPrefix('content')">
            <div v-if="!isServerVersionFetching">
                <div v-if="shouldShowServerList">
                    <v-row class="mt-X">
                        <v-col> {{ getLocaleFor('version') }} </v-col>
                    </v-row>
                    <v-radio-group
                        v-if="sortedServerList.length"
                        v-model="serverId"
                        mandatory
                        :data-auto="dataAutoPrefix('content-serverList')"
                        @change="handleServerSelection()">
                        <v-radio
                            v-for="(server, index) in sortedServerList"
                            :key="server.id"
                            :value="server.id"
                            :label="server.display_name"
                            :data-auto="dataAutoPrefix(`content-serverSelection-${index + 1}`)
                            ">
                            <template #label>
                                <div v-if="index === 0">
                                    <strong>{{ server.display_name }}</strong>
                                    <span class="recommended-text pr-8">
                                        {{ getLocaleFor('latest-db') }}</span>
                                    <a @click="openNotesUrl(server.release_notes_url)">{{
                                        getLocaleFor('release-notes')
                                    }}</a>
                                </div>
                                <div v-else>
                                    <span>{{ server.display_name }}</span>
                                    <TechPreview
                                        v-if="isVersion23(server.version)"
                                        class="mt-n1 ml-3 tech-position" />
                                </div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </div>
                <v-row class="mt-4">
                    <v-col :data-auto="dataAutoPrefix('content-serviceName')">
                        <label>{{ getLocaleFor("serviceName") }}</label>
                        <v-form
                            ref="serviceForm"
                            v-model="valid"
                            @submit.prevent="focusOnMaintenanceWindow">
                            <v-text-field
                                v-model="serviceName"
                                solo
                                outlined
                                dense
                                :rules="[rules.required, rules.validService, rules.maxLength, rules.minLength]"
                                :data-auto="dataAutoPrefix('content-serviceName-input')"
                                @keyup="handleServiceNameChange" />
                        </v-form>
                    </v-col>
                    <v-col>
                        <p>
                            {{ getLocaleFor('pass-validation-text') }}
                        </p>
                    </v-col>
                </v-row>
                <div
                    class="mt-4"
                    :data-auto="dataAutoPrefix('content-maintenanceWindow')">
                    <v-row>
                        <v-col>
                            {{
                                isPowerTierUser ? getLocaleFor("selectMaintenanceWindow") :
                                getLocaleFor("foundationTierMaintenanceWindow")
                            }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div v-if="isPowerTierUser">
                                <v-select
                                    ref="maintenanceWindow"
                                    v-model="selectedMaintenanceWindow"
                                    :class="['std std--dropdown select-dropdown maintenance-window-width']"
                                    :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list' }"
                                    :items="maintenanceWindowList"
                                    item-value="display_name"
                                    item-text="display_name"
                                    height="36"
                                    outlined
                                    attach
                                    single-line
                                    required
                                    @change="onMaintenanceWindowSelect" />
                            </div>
                            <div
                                v-else
                                class="mt-n4">
                                <i18n
                                    path="launchService.maintenanceWindowFor"
                                    tag="p">
                                    <strong place="region">{{ region }}</strong>
                                    <span place="maintenanceWindow">{{ selectedMaintenanceWindow }}</span>
                                </i18n>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </div>
            <div
                v-else
                class="text-center pt-3">
                <v-progress-circular
                    size="70"
                    width="7"
                    color="primary"
                    indeterminate />
            </div>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>
<script>
    import { mapGetters } from 'vuex'
    import i18n from 'plugins/i18n'
    import AccordianPanelHeader from 'components/launchService/AccordianPanelHeader.vue'
    import TechPreview from 'components/common/TechPreview.vue'
    import { isVersion23, sortServerVersions } from 'utils/service'
    export default {
        name: 'ServerSelection',
        components: { AccordianPanelHeader, TechPreview, },
        props: {
            getLocaleFor: {
                type: Function,
                default: (txt) => txt,
            },
            serverList: {
                type: Array,
                default: () => ([]),
            },
            parentDataAutoPrefix: {
                type: Function,
                default: (txt) => txt,
            },
            shouldShowServerList: {
                type: Boolean,
                required: true,
            },
            defaultServiceName: {
                type: String,
                required: true,
            },
            isDisabled: {
                type: Boolean,
                default: true,
            },
            region: {
                type: String,
                default: '',
            },
            maintenanceWindow: {
                type: String,
                default: '',
            },
            isPowerTierUser: {
                type: Boolean,
                default: false,
            },
        },
        data: function () {
            return {
                serverId: null,
                serviceName: this.defaultServiceName,
                rules: {
                    required: value => !!value || i18n.t('errors.required'),
                    validService: value => {
                        const pattern = /^[a-z][a-z0-9-]*[a-z0-9]$/
                        return pattern.test(value) || i18n.t('errors.invalidServiceName')
                    },
                    maxLength: value => (!!value && value.length <= 24) || i18n.t('errors.serviceNameMaxLength', [24]),
                    minLength: value => (!!value && value.length >= 4) || i18n.t('errors.serviceNameMinLength', [4]),
                },
                valid: false,
                selectedMaintenanceWindow: '',
            }
        },
        computed: {
            ...mapGetters(['isServerVersionFetching', 'maintenanceWindowList']),
            sortedServerList() {
                return sortServerVersions(this.serverList)
            },
        },
        watch: {
            defaultServiceName(newValue) {
                // Reset the model to set as parent
                // The props update is not repsected by v-text-field
                // component so adding this change
                if (newValue === '') this.serviceName = newValue
            },
            maintenanceWindowList() {
                if (this.isPowerTierUser) {
                    // For Power Tier first element of the list acts as default selection
                    this.selectedMaintenanceWindow = this.maintenanceWindowList[0].display_name
                } else {
                    // Display time in UTC format if already present else search for its equivalent conversion to UTC
                    if (this.maintenanceWindow.includes(this.$config.UTC)) this.selectedMaintenanceWindow = this.maintenanceWindow
                    else {
                        const foundWindow = this.maintenanceWindowList.find(maintenanceWindow => [maintenanceWindow.display_name, maintenanceWindow.name].includes(this.maintenanceWindow))
                        if (foundWindow) this.selectedMaintenanceWindow = foundWindow.display_name
                    }
                }
            },
        },
        methods: {
            handleServerSelection() {
                const selectedServer = this.serverList.find(server => server.id === this.serverId)
                this.$emit('onMariaDBServerSelect', selectedServer)
            },
            async handleServiceNameChange() {
                this.$refs.serviceForm.validate()
                // There is some delay in flipping the valid flag
                // so adding this to workaround that lag
                await this.$nextTick()
                this.$emit('onServiceNameEntered', { name: this.serviceName, hasErrors: !this.valid, })
            },
            dataAutoPrefix(string) {
                return this.parentDataAutoPrefix(
                    `servePanel${string ? '-' + string : ''}`
                )
            },
            openNotesUrl(href) {
                window.open(href, '_blank')
            },
            onMaintenanceWindowSelect(value) {
                const foundWindow = this.maintenanceWindowList.find(maintenanceWindow => maintenanceWindow.display_name === value)
                this.$emit('onMaintenanceWindowSelect', foundWindow.name)
            },
            focusOnMaintenanceWindow() {
                if (this.$refs.maintenanceWindow) this.$refs.maintenanceWindow.focus()
            },
            isVersion23,
        },
    }
</script>
