<template>
    <svg
        width="18"
        height="13"
        viewBox="0 0 18 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1697_21284)">
            <path
                d="M-1 0H20V12.871H-1V0Z"
                fill="#D22F27" />
            <path
                d="M4.41935 0.33871L-1 0V12.871L4.41935 12.5323L7.49044 11.3129L4.41935 10.0935L7.49044 8.87419L4.41935 7.65484L7.44505 6.43548L4.41935 5.21613L7.44505 3.99677L4.41935 2.77742L7.44505 1.55806L4.41935 0.33871Z"
                fill="white" />
            <path
                d="M-1 0H20V12.871H-1V0Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
        </g>
        <rect
            x="0.5"
            y="0.5"
            width="17"
            height="11.6844"
            stroke="#D2D2D2" />
        <defs>
            <clipPath id="clip0_1697_21284">
                <rect
                    x="1"
                    y="1"
                    width="16"
                    height="10.6844"
                    fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
    export default {}
</script>
