<style lang="scss" scoped>
    .selection-group{
        height: 90px
    }

    .autoscale-label :deep label {
        font-size: 0.75em;
    }
</style>

<template>
    <div data-auto="node-auto-scale">
        <v-row
            v-if="title"
            data-auto="node-auto-scale.title"
            class="mt-2 text-h6 font-weight-bold">
            {{ title }}
        </v-row>
        <v-row class="mt-4">
            <v-col
                cols="4"
                class="d-flex">
                <v-checkbox
                    v-model="isNodeScaleEnabled"
                    :disabled="shouldDisableScaling"
                    class="autoscale-label"
                    :label="$t('enableAutoScaleNodes')"
                    @change="onCheck" />
                <div class="pl-2 mt-4">
                    <HelpToolTip>
                        <template #message>
                            <p>{{ $t('launchService.autoScaleNodesInfo') }}</p>
                            <a
                                rel="noopener noreferrer"
                                class="text-capitalize"
                                target="_blank"
                                href="https://r.mariadb.com/snr-launch-autoscale-nodes">{{ $t('learn-more') }}</a>
                        </template>
                    </HelpToolTip>
                </div>
            </v-col>
            <v-col
                v-if="isNodeScaleEnabled"
                class="selection-group"
                cols="4">
                <v-select
                    v-model="scaleOptionSelected"
                    :items="nodeScaleOptions"
                    item-text="key"
                    item-value="value"
                    :placeholder="scaleTypeOptionsPlaceholderText"
                    required
                    :rules="[(val) => !!val || $t('errors.fieldRequired', { field: $t('scale-type') })]"
                    :disabled="!isNodeScaleEnabled || shouldDisableScaling"
                    :menu-props="{ top: false, offsetY: true }"
                    outlined
                    @change="onNodeScaleTypeSelection" />
            </v-col>
            <v-col
                v-if="isNodeScaleEnabled"
                class="selection-group">
                <v-select
                    v-model="scaleSizeSelected"
                    :items="nodeSizeOptions"
                    item-text="text"
                    item-value="id"
                    required
                    :placeholder="$t('maximum-size')"
                    :rules="[(val) => !!val || $t('errors.fieldRequired', { field: $t('scale-size') })]"
                    :disabled="!scaleOptionSelected || shouldDisableScaling"
                    :menu-props="{ top: false, offsetY: true }"
                    outlined
                    @change="onNodeSizeSelection" />
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import i18n from 'plugins/i18n'
    import HelpToolTip from 'components/common/HelpTooltip'

    const NodeAutoScale = {
        name: 'NodeAutoScale',
        components: { HelpToolTip, },
        props: {
            title: {
                type: String,
                default: '',
            },
            canScaleHorizontal:{
                type: Boolean,
                default: true,
            },
            horizontalScaleRange: {
                type: Array,
                required: false,
            },
            verticalScaleRange: {
                type: Array,
                required: true,
            },
            defaultScaleOption: {
                type: String,
                default: null,
            },
            defaultScaleValue: {
                default: null,
                type: [ String, Number ],
            },
        },
        data(){
            return {
                isNodeScaleEnabled: Boolean(this.defaultScaleOption),
                scaleOptionSelected: this.defaultScaleOption,
                scaleSizeSelected: this.defaultScaleValue,
            }
        },
        computed:{
            nodeScaleOptions() {
                let scaleOptions = []

                this.canScaleHorizontal && this.horizontalScaleRange.length && scaleOptions.push({
                    key: i18n.t('out-in'),
                    //TODO:: Need to change this value once API for
                    // auto scale is in and we are sure of the values
                    value: this.$config.AUTO_HORIZONTAL_SCALE,
                })

                scaleOptions.push({
                    key: i18n.t('up-down'),
                    value: this.$config.AUTO_VERTICAL_SCALE,
                })
                return scaleOptions
            },
            nodeSizeOptions() {
                // Data to come from API
                return this.scaleOptionSelected === this.$config.AUTO_HORIZONTAL_SCALE
                    ? this.horizontalScaleRange.map(val=>({
                        text: val,
                        id: val,
                    }))
                    // eslint-disable-next-line camelcase
                    : this.verticalScaleRange.map((size)=>({
                        text: size,
                        id: size,
                    }))
            },
            shouldDisableScaling(){
                if(!this.canScaleHorizontal){
                    // If unable to scale horizontal
                    // Checking vertical scaling range is enough
                    // to enable/disable scaling
                    return !this.verticalScaleRange.length
                }
                return !this.verticalScaleRange.length && !this.horizontalScaleRange.length
            },
            scaleTypeOptionsPlaceholderText(){
                if(this.nodeScaleOptions.length) return this.nodeScaleOptions[0].key
                return this.$t('notApplicable')
            },
        },
        methods: {
            onNodeScaleTypeSelection(type){
                this.$emit('on-auto-scale-type', type)
                this.scaleSizeSelected = null
            },
            onCheck(){
                this.$emit('on-toggle-auto-scaling', this.isNodeScaleEnabled)
                if(this.isNodeScaleEnabled && this.scaleOptionSelected){
                    this.$emit('onMaxNodeSelected', this.scaleOptionSelected )
                }

                // Done to disable node size selection if parent is disabled
                if(!this.isNodeScaleEnabled){
                    this.scaleOptionSelected = null
                }
            },
            onNodeSizeSelection(value){
                return this.$emit('on-auto-scale-value', value)
            },
        },
    }

    export default NodeAutoScale
</script>
