<template>
    <div>
        <base-dialog
            v-model="isModalOpen"
            :min-width="700"
            :max-width="700"
            :on-close="closeModal"
            :is-action-separator-required="false">
            <template #body>
                <div class="text-center color text-deep-ocean">
                    <div class="mt-8">
                        <v-icon
                            size="130">
                            $vuetify.icons.dbMonitor
                        </v-icon>
                    </div>
                    <div class="text-h5 mt-12 mb-8">
                        {{ $t('ros.selectServerTitle') }}
                    </div>
                    <div>
                        <v-btn
                            color="primary"
                            class="mr-4"
                            min-width="120"
                            depressed
                            rounded
                            :loading="isGettingSubscriptions"
                            @click="selectServer('cs')">
                            <span class="text-none">{{ $t('no') }}</span>
                        </v-btn>
                        <v-btn
                            color="primary"
                            min-width="120"
                            depressed
                            rounded
                            :disabled="isGettingSubscriptions"
                            @click="selectServer('es')">
                            <span class="text-none">{{ $t('yes') }}</span>
                        </v-btn>
                    </div>
                </div>
            </template>
            <template #actions>
                <div></div>
            </template>
        </base-dialog>
        <RosPricingModal
            :open-dialog="showPricingModal"
            @close="showPricingModal = false" />
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import RosPricingModal from 'components/monitoring/RosPricingModal.vue'

    export default {
        name: 'RosServerModal',
        components: {
            RosPricingModal,
        },
        props: {
            openDialog: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                isModalOpen: this.openDialog,
                isGettingSubscriptions: false,
                showPricingModal: false,
            }
        },
        computed: {
            ...mapGetters(['billingRecord', 'monitoringSubscription']),
        },
        watch: {
            openDialog(val) {
                this.isModalOpen = val
            },
        },
        methods: {
            ...mapActions(['fetchBillingRecord']),
            closeModal() {
                this.$emit('close')
            },
            navigateToOnboarding() {
                this.$router.push({ name: 'rosOnboarding', })
                this.closeModal()
            },
            async selectServer(type) {
                if (type === 'es') return this.navigateToOnboarding()

                // Check subscriptions
                this.isGettingSubscriptions = true
                await this.fetchBillingRecord()
                this.isGettingSubscriptions = false

                if (this.monitoringSubscription) return this.navigateToOnboarding()

                this.showPricingModal = true
                this.closeModal()
            },
        },
    }
</script>
