import Vue from 'vue'
import Router from 'vue-router'
import { t } from 'typy'
import { capitalize } from 'helpers/'
import DataLayer from 'utils/dataLayer'
import HomeRoute from 'router/home'
import DashboardRoutes from 'router/dashboard'
import ServiceRoute from 'router/service'
import AlertsRoute from 'router/alerts'
import UnauthorizedRoute from 'router/unauthorized'
import LogsRoutes from 'router/logs'
import LaunchServiceRoute from 'router/launchService'
import AuthGrantRoute from 'router/authGrant'
import SettingsRoute from 'router/settings'
import NotificationsRoute from 'router/notifications'
import UserPreferencesRoute from 'router/userPreferences'
import BillingRoute from 'router/billing'
import WorkspaceRoute from 'router/workspace'
import IntegrationsRoute from 'router/integrations'
import rosOnboardingRoute from 'router/rosOnboarding.js'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        HomeRoute,
        ...AlertsRoute,
        ...ServiceRoute,
        ...DashboardRoutes,
        UnauthorizedRoute,
        ...LogsRoutes,
        ...LaunchServiceRoute,
        ...AuthGrantRoute,
        ...SettingsRoute,
        ...NotificationsRoute,
        ...UserPreferencesRoute,
        ...BillingRoute,
        ...WorkspaceRoute,
        ...IntegrationsRoute,
        ...rosOnboardingRoute,
        {
            path: '*',
            name: 'default',
            redirect: '/dashboard', // TODO: Make 404 page
        }
    ],
})

router.beforeEach(async (to, from, next) => {
    next()
})

router.afterEach((to) => {
    const fullPath = `${location.origin}${to.fullPath}`
    const pageTitle = t(to, 'meta.title').isString ? to.meta.title : 'Unknown'
    const subTitle = t(to, 'params.dbTab').isString
        ? ` - ${capitalize(to.params.dbTab, '-')}`
        : ''

    DataLayer.add({
        /* eslint-disable camelcase */
        event: 'pageview',
        page_location: fullPath,
        page_title: pageTitle + subTitle,
        /* eslint-enable */
    })
})

export default router
