<style lang="scss" scoped>
.partner-slot {
    top: -5px;
}
</style>
<template>
    <div>
        <PartnerCategory
            :partners="dataIntegrationPartners"
            :category-title="$t('partnerIntegrations.dataIntegrations')"
            :selected-partner="selectedPartner"
            @on-click="onPartnerSelect" />
        <PartnerCategory
            :partners="devopsPartners"
            :category-title="$t('partnerIntegrations.devops')"
            :selected-partner="selectedPartner"
            @on-click="onPartnerSelect" />
        <PartnerCategory
            :partners="mlAnalyticsPartners"
            :category-title="$t('partnerIntegrations.mlAnalytics')"
            :selected-partner="selectedPartner"
            @on-click="onPartnerSelect" />
        <PartnerCategory
            :partners="terraformPartners"
            :category-title="$t('partnerIntegrations.terraform')"
            :selected-partner="selectedPartner"
            @on-click="onPartnerSelect" />
    </div>
</template>
<script>
    import PartnerCategory from 'components/integrations/PartnerCategory.vue'
    export default {
        name: 'PartnerList',
        components: { PartnerCategory, },
        props: {
            partners: {
                type: Array,
                required: true,
            },
            selectedPartner: {
                type: String,
                default: '',
            },
        },
        computed: {
            dataIntegrationPartners() {
                return this.partners.filter(partner => partner.category === 'data-integrations' && partner.isVisible)
            },
            mlAnalyticsPartners() {
                return this.partners.filter(partner => partner.category === 'ml-analytics' && partner.isVisible)
            },
            observabilityPartners() {
                return this.partners.filter(partner => partner.category === 'observability' && partner.isVisible)
            },
            terraformPartners() {
                return this.partners.filter(partner => partner.category === 'terraform' && partner.isVisible)
            },
            devopsPartners() {
                return this.partners.filter(partner => partner.category === 'devops' && partner.isVisible)
            },
        },
        methods: {
            onPartnerSelect(partner) {
                this.$emit('on-click', partner)
            },
        },
    }
</script>
