<template>
    <div>
        <OpenConnectionDialog
            v-model="isDialogOpened"
            :service-name="$typy(chosenService, 'name').safeString"
            @onOpenConnection="openConnection({ requestParams: $event, service: chosenService, })
            "
            @on-cancel="onCancelOpenConnection"
            @on-close="onCancelOpenConnection" />
        <ReconnDlgCtr :on-reconnect-cb="onReconnectCallback" />
        <WhitelistModal
            ref="whitelistModal"
            @on-did-save="fetchServicesDebounce" />
        <ConfirmLeaveDlg
            v-model="isConfDlgOpened"
            :on-save="onLeave"
            :should-del-all.sync="shouldDelAll"
            @on-close="cancelLeave"
            @on-cancel="cancelLeave" />
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex'
    import OpenConnectionDialog from 'components/skyQuery/OpenConnectionDialog.vue'
    import WhitelistModal from 'components/service/WhitelistModal.vue'
    import { ConfirmLeaveDlg ,ReconnDlgCtr} from 'mxs-query-editor'
    import { getIpAllowedObject } from 'utils/service'

    export default {
        name: 'ConnectionManager',
        components: {
            OpenConnectionDialog,
            WhitelistModal,
            ConfirmLeaveDlg,
            ReconnDlgCtr,
        },
        props: {
            value: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                isDialogOpened: false,
                // confirmation on leave dialog
                isConfDlgOpened: false,
                shouldDelAll: true,
                to: '',
            }
        },
        computed: {
            ...mapState({
                connErrState: state => state.queryConn.conn_err_state,
                isSkyQueryAccessible: state => state.skyQuery.isSkyQueryAccessible,
                allConnections: state => state.queryConn.sql_conns,
            }),
            ...mapGetters({
                getActiveService:'skyQuery/getActiveService',
            }),
            chosenService: {
                get() {
                    return this.value
                },
                set(v) {
                    this.$emit('input', v)
                },
            },
        },
        watch: {
            // If autoAuth failed, open dialog to ask for entering the credential manually
            connErrState(v) {
                if (v && !this.isDialogOpened) this.isDialogOpened = true
            },
        },
        methods: {
            ...mapActions({
                fetchServices:'fetchServices',
                openConnection: 'skyQuery/openConnection',
                fetchClientIp: 'skyQuery/fetchClientIp',
                initSkyQuery: 'skyQuery/initSkyQuery',
                initSkyQueryConn: 'skyQuery/initSkyQueryConn',
                disconnectAllConnections: 'skyQuery/disconnectAllConnections',
                validateServiceConns: 'skyQuery/validateServiceConns',
            }),
            // If the user cancels open a new connection for a service, fallback to the current active
            onCancelOpenConnection() {
                this.chosenService = this.getActiveService
            },
            async handleInitSkyQuery({ service, initializingCb, }) {
                await this.initSkyQuery(service)
                if (this.isSkyQueryAccessible) {
                    await this.initSkyQueryConn(service)
                } else {
                    // check if it at least have an NLB endpoint to
                    // add ip whitelist else skip opening the modal
                    if (getIpAllowedObject(service.endpoints)) {
                        this.$refs.whitelistModal.open(service.id)
                    }
                }
                this.$typy(initializingCb).safeFunction()
            },
            // Connection cleans up
            async onLeave() {
                if (this.shouldDelAll)
                    await this.disconnectAllConnections()
                this.leavePage()
            },
            leavePage() {
                this.$router.push(this.to)
            },
            cancelLeave() {
                this.to = null
            },
            beforeRouteLeaveHandler(to, from, next) {
                if (this.to) {
                    next()
                } else {
                    this.to = to
                    /**
                     * Allow to leave page immediately if next path is to login page (user logouts)
                     * or if there is no active connections
                     */
                    if (Object.keys(this.allConnections).length === 0) this.leavePage()
                    else {
                        this.shouldDelAll = true
                        this.isConfDlgOpened = true
                    }
                }
            },
            async onReconnectCallback() {
                await this.validateServiceConns(this.chosenService)
            },
            /**
             * This function waits 1 second then fetches services to get accurate state of the services.
             * This helps to improve UX because after whitelisting the service, the users has no clue
             * when will the service be available to use.
             */
            async fetchServicesDebounce() {
                await this.asyncDebounce(1000).then(() => this.fetchServices())
            },
            asyncDebounce(t, v) {
                return new Promise(function (resolve) {
                    setTimeout(resolve.bind(null, v), t)
                })
            },
        },
        async created() {
            this.$emit('is-initializing', true)
            await this.fetchClientIp()
            await this.handleInitSkyQuery({ service: this.chosenService, initializingCb: () => this.$emit('is-initializing', false), })
        },
    }
</script>
