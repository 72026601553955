<style lang="scss" scoped>
    path {
        fill: currentColor;
        stroke: currentColor;
    }
</style>

<template>
    <svg viewBox="0 0 60 45">
        <path
            style="stroke-width:0.39110243;stroke:none"
            d="m 20.166016,19.033203 c -2.4289,0 -4.410157,1.946956 -4.410157,4.347656 0,1.570759 0.852414,2.939584 2.117188,3.703125
            -2.124885,0.779776 -3.752366,2.605164 -4.164063,4.919922 -0.0627,0.352 0.178838,0.678782 0.523438,0.738282 0.3456,0.0597
            0.68204,-0.169079 0.74414,-0.517579 0.440501,-2.4789 2.618354,-4.283203 5.189454,-4.283203 0.2152,0 0.427918,0.01251 0.636718,
            0.03711 l 0.658204,0.07617 -0.375,-0.544922 c -0.1881,-0.2738 -0.347963,-0.567654 -0.476563,-0.876954 l -0.08203,-0.193359
            -0.208985,0.0098 c -0.0499,0.0023 -0.101743,0.0039 -0.152343,0.0039 -1.731,0 -3.125,-1.380966 -3.125,-3.072266 0,-1.6927
            1.3955,-3.074218 3.125,-3.074218 0.6708,0 1.292081,0.207546 1.800781,0.560547 L 22.13672,20.62112 c 4.6e-4,3.19e-4 0.0015,
            -3.19e-4 0.002,0 v 0.002 l -0.171875,0.244141 0.171875,0.121093 0.171875,-0.121093 c 0.1872,-0.131701 0.384444,-0.250269
            0.589844,-0.355469 l 0.410156,-0.210938 -0.359375,-0.291015 c -0.7599,-0.6108 -1.730656,-0.976563 -2.785156,-0.976563
            z m 10.333984,0 c -1.1203,0 -2.145028,0.41305 -2.923828,1.09375 l -0.31836,0.279297 0.367188,0.207031 c 0.1941,0.1105 0.380541,
            0.234541 0.556641,0.369141 l 0.183593,0.140625 0.183594,-0.142578 c 0.5347,-0.4206 1.212572,-0.673828 1.951172,-0.673828 1.7295,
            0 3.125,1.381518 3.125,3.074218 0,1.6927 -1.3955,3.072266 -3.125,3.072266 -0.151,0 -0.298359,-0.01095 -0.443359,-0.03125
            l -0.232422,-0.0332 -0.08789,0.216797 c -0.1388,0.3399 -0.315191,0.659931 -0.52539,0.957031 l -0.427735,0.603516 0.726563,
            -0.134766 c 0.321,-0.0598 0.652334,-0.08984 0.990234,-0.08984 2.5712,0 4.752506,1.804856 5.191406,4.285156 0.0559,0.311501
            0.327713,0.527344 0.632813,0.527344 0.0361,0 0.07202,-0.004 0.107422,-0.0098 h 0.002 c 0.344,-0.0594 0.585937,-0.384382
            0.523437,-0.738282 -0.41015,-2.314761 -2.037795,-4.143468 -4.164062,-4.923828 1.26493,-0.762822 2.117187,-2.131212 2.117187,
            -3.701172 0,-2.4024 -1.981356,-4.347656 -4.410156,-4.347656 z"
            transform="matrix(2.556875,0,0,2.556875,-34.773306,-48.665485)" />
        <path
            d="m 29.573051,0.85399382 c -7.775751,0 -14.117747,6.23928128 -14.117747,13.91299518 0,5.232044 3.055555,9.661349 7.390967,
            12.03529 -7.143581,2.369374 -12.674504,8.344369 -14.0378421,16.010432 -0.1815637,1.01901 0.519332,1.983102 1.5281321,2.157364
            1.011152,0.17466 1.996812,-0.500342 2.17734,-1.513151 1.449715,-8.157618 8.608806,-14.087783 17.05915,-14.087783 8.450602,0
            15.619704,5.930546 17.064144,14.092776 0.161876,0.903636 0.958238,1.538119 1.852735,1.538119 0.104576,0 0.208385,-0.0087
            0.309622,-0.02506 h 0.01 c 1.006667,-0.173996 1.713814,-1.134754 1.533125,-2.157363 -1.358345,-7.666228 -6.894434,-13.649448
            -14.042836,-16.02042 4.339299,-2.370295 7.395961,-6.798654 7.395961,-12.030297 0,-7.678932 -6.347169,-13.91299979 -14.122738,
            -13.91299467 z m 0,3.72544618 c 5.730936,0 10.362334,4.5653156 10.362334,10.187549 0,5.621967 -4.631398,10.182555 -10.362334,
            10.182555 -5.735753,0 -10.357343,-4.565397 -10.357343,-10.182555 0,-5.6222334 4.62667,-10.187549 10.357343,-10.187549 z" />
    </svg>
</template>

<script>
    export default {}
</script>
