<template>
    <svg
        width="18"
        height="13"
        viewBox="0 0 18 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1697_21262)">
            <path
                d="M0 0H21.2105V13H0V0Z"
                fill="#1E50A0" />
            <path
                d="M21.2105 5.47368H8.55263V0H6.5V5.47368H0V7.52632H6.5V13H8.55263V7.52632H21.2105V5.47368Z"
                fill="#FCEA2B"
                stroke="#FCEA2B"
                stroke-miterlimit="10" />
            <path
                d="M0 0H21.2105V13H0V0Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
        </g>
        <rect
            x="0.5"
            y="0.5"
            width="17"
            height="11.6844"
            stroke="#D2D2D2" />
        <defs>
            <clipPath id="clip0_1697_21262">
                <rect
                    x="1"
                    y="1"
                    width="16"
                    height="10.6844"
                    fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
    export default {}
</script>
