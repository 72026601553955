<style lang="scss" scoped>
    .instructions-card {
        background-color: #f9fbfb;
        border-radius: 10px;
    }
</style>

<template>
    <v-card
        class="instructions-card pa-6"
        outlined>
        <div class="ft-adrianna text-h5 primary--text">
            {{ $t('ros.quickStart') }}
        </div>

        <div class="ft-adrianna text-h6 mt-6">
            {{ $t('ros.downloadAgent') }}
        </div>
        <div class="text-body-2 mb-2">
            {{ $t('ros.downloadAgentInstructions') }}
        </div>
        <v-btn
            color="primary"
            depressed
            rounded
            @click="downloadBundle">
            <span class="text-none">{{ $t('ros.downloadBundle') }}</span>
        </v-btn>
    </v-card>
</template>

<script>
    export default {
        name: 'RosQuickStart',
        methods: {
            downloadBundle(){
                const link = document.createElement('a')
                link.href = this.$config.AGENT_BUNDLE_URL
                link.click()
            },
        },
    }
</script>
