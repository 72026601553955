<template>
    <svg
        width="16"
        height="11"
        viewBox="0 0 16 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.0266113 0.0266113H15.9735V5.34223H0.0266113V0.0266113Z"
            fill="#ED2939" />
        <path
            d="M0.0266113 5.34224H15.9735V10.6579H0.0266113V5.34224ZM5.49611 2.68443C5.49611 3.78838 4.60118 4.68332 3.49723 4.68332C2.39328 4.68332 1.49835 3.78838 1.49835 2.68443C1.49835 1.58048 2.39328 0.685547 3.49723 0.685547C4.60112 0.685547 5.49611 1.58048 5.49611 2.68443Z"
            fill="white" />
        <path
            d="M6.12421 2.68442C6.12421 3.72772 5.27844 4.57343 4.2352 4.57343C3.19196 4.57343 2.34619 3.72766 2.34619 2.68442C2.34619 1.64113 3.19196 0.79541 4.2352 0.79541C5.27844 0.79541 6.12421 1.64113 6.12421 2.68442Z"
            fill="#ED2939" />
        <path
            d="M3.7446 2.73262L3.47308 2.5243L3.20161 2.73272L3.30612 2.39683L3.03369 2.18979L3.36985 2.19048L3.47297 1.85411L3.5762 2.19043L3.91231 2.18957L3.63999 2.39672L3.7446 2.73262ZM5.87527 2.73262L5.60374 2.5243L5.33227 2.73272L5.43678 2.39683L5.16435 2.18979L5.50051 2.19048L5.60364 1.85411L5.70687 2.19043L6.04297 2.18957L5.77065 2.39672L5.87527 2.73262ZM4.81102 1.94187L4.5395 1.73355L4.26803 1.94197L4.37254 1.60608L4.10011 1.39904L4.43622 1.39973L4.53934 1.06335L4.64257 1.39967L4.97873 1.39882L4.70641 1.60597L4.81102 1.94187ZM4.14328 3.99784L3.87175 3.78952L3.60028 3.99795L3.70479 3.66205L3.43236 3.45501L3.76852 3.4557L3.87165 3.11933L3.97488 3.45565L4.31098 3.4548L4.03866 3.66195L4.14328 3.99784ZM5.47659 3.99784L5.20507 3.78952L4.9336 3.99795L5.03811 3.66205L4.76568 3.45501L5.10184 3.4557L5.20497 3.11933L5.3082 3.45565L5.6443 3.4548L5.37198 3.66195L5.47659 3.99784Z"
            fill="white" />
    </svg>
</template>

<script>
    export default {}
</script>
