import AppHeader from 'layouts/Header'
import Navigation from 'layouts/Navigation'
import Snackbars from 'layouts/Snackbars'
import AppFooter from 'layouts/Footer'

export default {
    AppHeader,
    Navigation,
    Snackbars,
    AppFooter,
}
