import policies from 'api/policies'
export async function createPolicies (scalingPolicies) {
    return policies.post('actions', scalingPolicies).then(response => {
        return response.data
    })
}

export async function getNodeSizesForVerticalScale(params) {
    return policies.get('actions/default/autoScaleNodesVertical/params', params).then(response => {
        return response.data
    })
}

export async function deletePolicyForActionId(actionId){
    return policies.delete(`actions/${actionId}`)
}

export async function getPoliciesForService(serviceId){
    // eslint-disable-next-line camelcase
    return policies.get('actions',{service_id: serviceId,})
}
