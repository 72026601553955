import LaunchService from 'pages/LaunchService'

export default [
    {
        path: '/launch-service',
        name: 'Launch Service',
        component: LaunchService,
        meta: {
            requiresAuth: true,
            title: 'Launch Service',
        },
    }
]
