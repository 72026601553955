import crypto from 'crypto'

export function getRandomString(length, isUrlSafe) {
    let randomString = crypto.randomBytes(length).toString('base64')

    if (isUrlSafe) {
        randomString = randomString
            .replace(/\+/g, '0') // replace '+' with '0'
            .replace(/\//g, '0') // replace '/' with '0'
            .replace(/=/g, '0') // replace '=' with '0'
    }
    return randomString
}

export function getUniqueString(length) {
    let result = ''
    let counter = 0
    while (counter < length) {
        result += String.fromCharCode(Math.floor(Math.random() * 26) + 97)
        counter += 1
    }
    return result
}
