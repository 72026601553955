import Vue from 'vue'

const baseUrl = process.env.VUE_APP_API_GATEWAY_BASE_URL

export default {
    get (uri, options = {}) {
        return Vue.axios.get(`${baseUrl}/${uri}`, {
            ...options,
        })
    },

    post (uri, body, options = {}) {
        return Vue.axios.post(`${baseUrl}/${uri}`, body, {
            ...options,
        })
    },
}
