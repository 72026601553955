<style lang="scss" scoped>
    path {
        fill: currentColor;
    }
</style>

<template>
    <svg
        viewBox="0 0 20 20"
        fill="none">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.5 13C10.0899 13 13 10.0899 13 6.5C13 2.91015 10.0899 0 6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0899 2.91015 13 6.5 13Z"
            fill="#7DD012" />
        <path
            style="fill: white"
            d="M6.2161 8.77195C5.924 9.07602 5.45006 9.07602 5.1581 8.77195L3.51888 7.06557C3.22678 6.76165 3.22678 6.26829 3.51888
            5.96437C3.81083 5.66031 4.28478 5.66031 4.57687 5.96437L5.55353 6.98089C5.62726 7.0575 5.74694 7.0575 5.82081 6.98089L8.46531
            4.22805C8.75727 3.92398 9.23122 3.92398 9.52331 4.22805C9.66358 4.37406 9.74238 4.57217 9.74238 4.77865C9.74238 4.98512 9.66358
            5.18323 9.52331 5.32924L6.2161 8.77195Z" />
    </svg>
</template>

<script>
    export default {}
</script>
