<template>
    <v-data-table
        :items="filteredPolicies"
        item-key="id"
        :sort-by="defaultSort"
        :disable-pagination="true"
        :hide-default-footer="true"
        :no-data-text="noDataText">
        <template #item="{ item: policy, }">
            <tr class="font-weight-medium">
                <td :style="{ width: '70%' }">
                    {{ policy.name }}
                </td>
                <td>
                    <tag-listing
                        :tags="formattedTags(policy)"
                        small-tags
                        disable-delete-tag
                        :show-tags-count="6" />
                </td>
            </tr>
        </template>
    </v-data-table>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    export default {
        name: 'PolicyList',
        props: {
            matchingTags: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                noDataText: '$vuetify.noDataText',
                defaultSort: ['name'],
            }
        },
        computed: {
            ...mapGetters(['rules']),
            filteredPolicies() {
                let tagValues = this.matchingTags.map(tag => tag.value)
                // Old logic displays 'Any server' for 'all/all'
                tagValues = tagValues.sort().join('--').replace(this.$config.allTopologiesTag, this.$t('anyServer')).toLowerCase().split('--')
                return tagValues ?
                    this.rules.filter(policy => {
                        const texts = this.formattedTags(policy).map(tag => tag.text.toLowerCase())
                        return tagValues.every(tag => texts.includes(tag))
                    })
                    : []
            },
        },
        methods: {
            ...mapActions(['fetchRules']),
            formattedTags(policy) {
                const alertTagKeys = [this.$config.customTagPrefix, 'context']
                let tags = this.$help.objects.pickPropertyStartsWith({ object: policy.labels, keys: alertTagKeys, })
                tags.context = policy.context
                if (this.$typy(tags, 'context').isDefined) tags.context = this.$help.alerts.unescapeTags(tags.context)
                return this.$help.alerts.cortexLabelsToTags(tags)
            },
        },
        created() {
            this.fetchRules()
        },
    }
</script>
