import base from 'api/base'

const baseUrl = process.env.VUE_APP_API_GATEWAY_BASE_URL
const observabilityURI = 'observability/v2'

export default {
    post (uri, body, params, options) {
        return base.post(`${baseUrl}/${observabilityURI}/${uri}`, body, params, options)
    },
    get (uri, params, options) {
        return base.get(`${baseUrl}/${observabilityURI}/${uri}`, params, options)
    },
    delete (uri, params) {
        return base.delete(`${baseUrl}/${observabilityURI}/${uri}`, params)
    },
}
