<style lang="scss" scoped>
.tooltip-container {
    max-width: 400px;
}
</style>
<template>
    <div class="tags-list-container">
        <template v-for="tag in _tags">
            <v-tooltip
                :key="tag.index"
                top
                :disabled="(!enableClickToAdd && tag.type !== $config.tagType.more) || tag.type === $config.tagType.system">
                <template #activator="{ on, attrs }">
                    <span v-on="on">
                        <tag
                            :small="smallTags"
                            v-bind="attrs"
                            :color="tag.type === $config.tagType.custom ? '#c8f2ff' : '#e8eef1'"
                            v-on="on"
                            @click.native="onTagCliked(tag)">
                            <template
                                v-if="tag.type === $config.tagType.system"
                                slot="left-slot">
                                <v-icon size="12"> mdi-server </v-icon>
                            </template>

                            <template
                                v-if="!disableDeleteTag && tag.type !== $config.tagType.more"
                                slot="right-slot">
                                <v-icon
                                    size="12"
                                    @click.stop="onTagDeleteCliked(tag)">mdi-close</v-icon>
                            </template>

                            <template v-if="tag.type === $config.tagType.system">
                                <span class="font-weight-medium text-capitalize"> {{ serviceServer(tag.text ||
                                    tag.value).service }}</span>
                                <span
                                    v-if="serviceServer(tag.text || tag.value).server"
                                    class="text-capitalize"> /{{
                                        serviceServer(tag.text ||
                                            tag.value).server }} </span>
                            </template>
                            <template v-else>
                                <span class="text-capitalize">{{ tag.text || tag.value }}</span>
                            </template>
                        </tag>
                    </span>
                </template>
                <span v-if="tag.type === $config.tagType.more">
                    <span class="color white--text d-inline-block mb-1 font-weight-medium">{{ $t('currTags') }}</span>
                    <tag-listing
                        :tags="tags"
                        small-tags
                        :is-tag-without-label="isTagWithoutLabel"
                        disable-delete-tags
                        class="tooltip-container mb-2" />
                </span>
                <span v-else>{{ $t('addFilter') }}</span>
            </v-tooltip>
        </template>
    </div>
</template>
<script>
    import Tag from './Tag.vue'

    export default {
        name: 'TagListing',
        components: {
            'tag': Tag,
        },
        props: {
            tags: {
                type: Array,
                default: () => ([]),
                validator(tags) {
                    return tags.every(tag => tag.hasOwnProperty('type') && (tag.hasOwnProperty('text') || tag.hasOwnProperty('value')))
                },
            },
            smallTags: { type: Boolean, default: false, },
            disableDeleteTag: { type: Boolean, default: false, },
            showTagsCount: { type: Number, default: 200, },
            showOnlyCustomTags: { type: Boolean, default: false, },
            enableClickToAdd: { type: Boolean, default: false, },
            isTagWithoutLabel: { type: Boolean, default: false, },
        },
        computed: {
            _tags() {
                if (this.isTagWithoutLabel) {
                    let sortedTags = [...this.tags].sort(this.$help.sortArrayObjectsAlphabetically('value'))
                    const { SYSTEM, CUSTOM, } = this.$config.TAG_BODY.TYPE
                    let sysTags = sortedTags.filter(tag => tag.type === SYSTEM)
                    let customTags = sortedTags.filter(tag => tag.type === CUSTOM)
                    let allTags = [...sysTags, ...customTags]
                    if (allTags.length > this.showTagsCount) {
                        return this.truncateTags(allTags)
                    }
                    return allTags
                } else {
                    let tags = this.showOnlyCustomTags
                        ? [...this.tags].filter(tag => tag.type === this.$config.tagType.custom)
                        : [...this.tags]

                    const regex = new RegExp('[S|P][\\s]?[0-9]+|SEV[a-z]+[\\s]?[0-9]+|PRIO[a-z]+[\\s]?[0-9]+', 'gi')

                    const priorityTags = [...tags].filter(tag => tag.text.match(regex)).sort(this.$help.sortArrayObjectsAlphabetically('text'))

                    const rest = [...tags].filter(tag => !priorityTags.includes(tag))

                    const restCustomTagsOrdered = [...rest].filter(tag => tag.type === this.$config.tagType.custom).sort(this.$help.sortArrayObjectsAlphabetically(this.isTagWithoutLabel ? 'value' : 'text'))
                    const restSystemTagsOrdered = [...rest].filter(tag => tag.type === this.$config.tagType.system).sort(this.$help.sortArrayObjectsAlphabetically(this.isTagWithoutLabel ? 'value' : 'text'))

                    let sortedAndOrderedTags = [...priorityTags, ...restSystemTagsOrdered, ...restCustomTagsOrdered]

                    if (sortedAndOrderedTags.length > this.showTagsCount) {
                        return this.truncateTags(sortedAndOrderedTags)
                    }

                    return sortedAndOrderedTags
                }
            },
        },
        methods: {
            onTagCliked(tag) {
                this.$emit('on-tag-click', tag)
            },
            onTagDeleteCliked(tag) {
                this.$emit('on-tag-del', tag)
            },
            serviceServer(str) {
                if (str === this.$config.allTopologiesTag) return { service: this.$t('anyServer'), }
                const [service, server] = str.split('/')
                return { service, server, }
            },
            truncateTags(tags) {
                let truncatedTags = tags.slice(0, this.showTagsCount)
                const rest = tags.length - this.showTagsCount
                truncatedTags.push({ text: `+ ${rest} more`, type: this.$config.tagType.more, })
                return truncatedTags
            },
        },
    }
</script>
