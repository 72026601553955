<template>
    <svg
        width="16"
        height="10"
        viewBox="0 0 16 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0 0H16V9.80645H0V0Z"
            fill="#1E50A0" />
        <path
            d="M9.03223 3.05961V3.87097H11.6596L16 1.21058V0H14.0247L9.03223 3.05961Z"
            fill="white" />
        <path
            d="M16 0H15.0124L9.03223 3.66529V3.87097H9.93081L16 0.151226V0Z"
            fill="#D22F27" />
        <path
            d="M14.025 9.80651H16V8.59568L11.6598 5.93555H9.03223V6.74664L14.025 9.80651Z"
            fill="white" />
        <path
            d="M15.9996 9.80651V9.20084L10.6719 5.93555H9.43652L15.7524 9.80651H15.9996Z"
            fill="#D22F27" />
        <path
            d="M6.96774 6.7469V5.93555H4.34039L0 8.59593V9.80651H1.97523L6.96774 6.7469Z"
            fill="white" />
        <path
            d="M0 9.80651H0.987613L6.96774 6.14122V5.93555H6.06916L0 9.65529V9.80651Z"
            fill="#D22F27" />
        <path
            d="M1.97497 0H0V1.21084L4.34013 3.87097H6.96774V3.05987L1.97497 0Z"
            fill="white" />
        <path
            d="M0 0V0.605677L5.328 3.87097H6.5631L0.247226 0H0Z"
            fill="#D22F27" />
        <path
            d="M0 3.61279H16V6.19344H0V3.61279Z"
            fill="white" />
        <path
            d="M6.70898 0H9.28963V9.80645H6.70898V0Z"
            fill="white" />
        <path
            d="M0 4.12891H16V5.67729H0V4.12891Z"
            fill="#D22F27" />
        <path
            d="M7.22559 0H8.77397V9.80645H7.22559V0Z"
            fill="#D22F27" />
    </svg>
</template>

<script>
    export default {}
</script>
