import configurations from 'api/dbServices'

export async function listConfigs() {
    return configurations.get('configs?include_default=true').then((response) => response.data)
}
export async function getConfig(configId) {
    return configurations.get(`configs/${configId}`).then((response) => response.data)
}
export async function deleteConfig(configId) {
    return configurations.delete(`configs/${configId}`).then((response) => response.data)
}
export async function createConfig(configObject) {
    return configurations.post('configs', configObject).then((response) => response.data)
}
export async function updateConfig(configId, nameObject) {
    return configurations.patch(`configs/${configId}`, nameObject).then((response) => response.data)
}
export async function applyToService(serviceId, configId) {
    return configurations.post(`services/${serviceId}/config`, { 'config_id': configId, }).then((response) => response.data)
}
export async function removeConfigFromService(serviceId) {
    return configurations.delete(`services/${serviceId}/config`).then((response) => response.data)
}
export async function listProducts(topologyId) {
    return configurations.get(`products?topology_id=${topologyId}`).then((response) => response.data)
}
export async function listConfigsPerProduct(productName) {
    return configurations.get(`products/${productName}/configs`).then((response) => response.data)
}
export async function getConfigsPerTopology(topologyName) {
    return configurations.get(`topologies/${topologyName}/configs`).then((response) => response.data)
}
export async function getConfigValues(configId) {
    return configurations.get(`configs/${configId}/values`).then((response) => response.data)
}
export async function setConfigValues(configId, selectedValuesList) {
    return configurations.put(`configs/${configId}/values`, selectedValuesList).then((response) => response.data)
}
