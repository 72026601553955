<style lang="scss" scoped>
    .launch-btn {
        min-width: 48px !important;
        font-size: 24px;
        font-weight: 500;
        letter-spacing: normal;

        .v-btn__content>span {
            font-size: 23px;
        }
    }
</style>

<template>
    <v-container
        :class="['ma-0 pa-12', { 'fill-height': !services.length }]"
        fluid>
        <v-row class="ma-0">
            <RosServerModal
                :open-dialog="showRosServerModal"
                @close="showRosServerModal = false" />
            <v-col
                v-if="!servicesInitialized"
                cols="12"
                class="pa-0">
                <InitializingMessage />
            </v-col>
            <v-col
                v-else-if="!services.length"
                cols="12"
                class="pa-0">
                <v-row justify="center">
                    <WelcomeOptions @monitor-db="showRosServerModal = true" />
                </v-row>
            </v-col>
            <v-col
                v-else
                cols="auto"
                class="pa-0 wrapper-container"
                style="width: 100%;">
                <div class="mb-8 d-flex align-end justify-space-between">
                    <div class="view-headline">
                        <span>{{ $tc('service', 2) }}</span>
                        <v-menu offset-y>
                            <template #activator="{ on, attrs }">
                                <v-btn
                                    outlined
                                    rounded
                                    large
                                    color="primary"
                                    class="launch-btn pa-0 ml-4 text-capitalize"
                                    v-bind="attrs"
                                    v-on="on">
                                    +
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="$router.push({ name: 'Launch Service' })">
                                    <v-list-item-title>{{ $t('dashboardPage.welcomeOptionLaunch') }}</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    v-if="$unleash.isFeatureEnabled('enable-portal-remote-monitoring')"
                                    @click="showRosServerModal = true">
                                    <v-list-item-title>{{ $t('dashboardPage.welcomeOptionMonitor') }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                    <SearchServices />
                </div>

                <ConnectServiceModal />
                <ManageServicePopups />
                <ServerlessConnectModal />

                <ServiceCard
                    v-for="service in sortedServices"
                    :key="service.name"
                    class="mb-4"
                    :service-id="service.id"
                    :service-status="service.status"
                    :stopper-services="stopperServices"
                    :spending="getSpendingForService(service.id)"
                    :autonomous-policies="getAutoPoliciesForService(service.id)"
                    @updateStoppedServerList="updateStoppedServerList" />
                <v-col
                    v-if="servicesError && !services.length"
                    class="text-center"
                    cols="12">
                    <v-card
                        flat
                        color="transparent">
                        <v-card-text>
                            <v-icon
                                size="60"
                                color="#c7c6c7"
                                class="py-50">
                                mdi-web
                            </v-icon>
                            <div class="text-h6 mt-2">
                                {{ $t('noServices') }}
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import SearchServices from 'components/common/SearchServices'
    import ConnectServiceModal from 'components/service/ConnectServiceModal'
    import ServiceCard from 'components/service/Card'
    import InitializingMessage from 'components/messages/Initializing'
    import ManageServicePopups from 'components/service/ManageServicePopups'
    import ServerlessConnectModal from 'components/service/ServerlessConnectModal.vue'
    import WelcomeOptions from 'components/dashboard/WelcomeOptions.vue'
    import RosServerModal from 'components/monitoring/RosServerModal.vue'

    export default {
        components: {
            SearchServices,
            ConnectServiceModal,
            ServiceCard,
            InitializingMessage,
            ManageServicePopups,
            ServerlessConnectModal,
            WelcomeOptions,
            RosServerModal,
        },
        data() {
            return {
                stopperServices: [],
                showRosServerModal: false,
            }
        },
        computed: {
            ...mapGetters([
                'services',
                'servicesInitialized',
                'servicesError',
                'serviceTypes',
                'getServiceById',
                'servicePolicies',
                'servicesSpending'
            ]),
            sortedServices() {
                this.mapReplicationType()
                const clonedServices = [...this.services]
                clonedServices.sort((serviceA, serviceB) => serviceB.created_on - serviceA.created_on)
                return clonedServices
            },
        },
        methods: {
            ...mapActions([
                'fetchPoliciesForService',
                'fetchSpendingDetailsForAllServices'
            ]),
            updateStoppedServerList(serviceId) {
                let newServiceList = []
                newServiceList.push(serviceId)
                this.stopperServices.length = 0
                this.stopperServices = [...newServiceList]
            },
            mapReplicationType() {
                this.services.forEach(service => {
                    if (service.primary_host) {
                        let primaryService = this.getServiceById(service.primary_host)
                        // TODO: Workaround until this flag is provided by backend team
                        if (primaryService)
                            service['replication_type'] = primaryService.primary_host === service.id ? this.$config.REPLICATION_DIRECTIONS.BI : this.$config.REPLICATION_DIRECTIONS.UNI
                    }
                })
            },
            getAutoPoliciesForService(serviceId){
                return this.servicePolicies.filter(policy=> policy.service_id === serviceId)
            },
            getSpendingForService(serviceId){
                const spendFound = this.servicesSpending.find(spending=> spending.serviceId === serviceId)
                return spendFound ? spendFound.cost : 0
            },
        },
        mounted(){
            this.fetchPoliciesForService()
            this.fetchSpendingDetailsForAllServices()
        },
    }
</script>
