<style lang="scss" scoped>
    ::v-deep.email-input {
        label {
            font-size: 14px;
            color: $navigation;
            opacity: 0.5;
        }
        .v-input__append-inner {
            display: none;
        }
        .v-input__slot {
            box-shadow: none !important;
            min-height: 32px !important;
            padding: 6px 12px !important;
        }
    }
</style>
<template>
    <v-combobox
        v-model="emails"
        :items="emails"
        hide-selected
        :label="label"
        multiple
        small-chips
        class="email-input"
        :class="customClass"
        outlined
        single-line
        solo
        hide-details="auto"
        :rules="rules.emails"
        @change="onChange">
        <template #selection="{ attrs, item, parent, selected }">
            <label-chip
                v-bind="attrs"
                :text="item"
                small
                :custom-class="emailClass(item, selected)"
                :on-icon-click="() => parent.selectItem(item)" />
        </template>
    </v-combobox>
</template>
<script>

    export default {
        name: 'EmailsInput',
        props: {
            defaultEmails: { type: Array, default: () => [], },
            customClass: { type: String, default: '', },
            label: { type: String, default: '', },
        },
        data () {
            return {
                rules: {
                    emails: [(val) => this.validateEmails(val)],
                },
                emails: [],
            }
        },
        computed: {

        },
        methods: {
            onChange(val) {
                const validInputs = val.filter(v => /\S/.test(v)) // filter out whitespace items
                this.emails = validInputs
                const validEmails = validInputs.filter(email => this.isEmailValid(email))
                this.$emit('on-change', validEmails)
            },
            isEmailValid (email) {
                // RFC 5322
                // eslint-disable-next-line max-len
                const regex = RegExp(/^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g)
                return regex.test(email)
            },
            emailClass (email, selected) {
                const base = `${selected ? 'font-weight-bold' : ''}`
                if (this.isEmailValid(email)) return base
                return `${base} color text-error border-error`
            },
            validateEmails (emails) {
                if (emails.length > 0) {
                    const invalidEmails = emails.filter(email => !this.isEmailValid(email))
                    const msg = this.$tc('errors.invalidEmail', invalidEmails.length > 1 ? 2 : 1, { email: invalidEmails.join(', '), })
                    if (invalidEmails.length) return msg
                    else return true
                } else return this.$t('errors.recipientRequired')
            },
        },
        created () {
            this.emails = this.defaultEmails
        },
    }
</script>
