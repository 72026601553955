<template>
    <svg
        width="16"
        height="10"
        viewBox="0 0 16 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_333_4846)">
            <path
                d="M0.0266113 0.0443115H15.9735V9.7896H0.0266113V0.0443115Z"
                fill="white" />
            <path
                d="M0.0266113 3.58801H15.9735V6.24583H0.0266113V3.58801Z"
                fill="#003580" />
            <path
                d="M4.4563 0.0443115H7.11411V9.7896H4.4563V0.0443115Z"
                fill="#003580" />
        </g>
        <rect
            x="0.5"
            y="0.5"
            width="15"
            height="8.83389"
            stroke="#E6E6E6" />
        <defs>
            <clipPath id="clip0_333_4846">
                <rect
                    width="16"
                    height="9.83389"
                    fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
    export default {}
</script>
