los<style lang="scss" scoped>
.old-release-banner{
    top: 64px;
    position: fixed;
    background-color: $old-release;
    width: 100%;
    margin-bottom: 0!important;
}
</style>

<template>
    <v-alert
        class="mb-0 old-release-banner text-center"
        dismissible>
        <template #close>
            <v-icon
                size="15"
                @click="dismiss">
                $vuetify.icons.close
            </v-icon>
        </template>
        <i18n
            class="d-inline"
            path="previewRelease"
            tag="p">
            <a
                class="d-inline"
                place="link"
                @click="showOldReleaseModal"> {{ $t('clickHere') }} </a>
        </i18n>
    </v-alert>
</template>
<script>
    export default {
        name : 'PreviousReleaseBanner',
        methods: {
            dismiss(){
                this.$emit('on-banner-dismiss')
            },
            showOldReleaseModal(){
                this.$emit('on-show-previous-release')
            },
        },
    }
</script>
