import Alerts from 'pages/Alerts'

export default [
    {
        path: '/alerts',
        name: 'alerts',
        component: Alerts,
        meta: {
            requiresAuth: true,
            title: 'Alerts',
        },
    }
]
