<style lang="scss" scoped>
.computed-wrapper {
    background-color: $estimate-calculations
}

.unaffected-config {
    color: $grayed-out
}
</style>
<template>
    <div>
        <div v-if="title">
            <v-row class="pa-0">
                <v-col
                    data-auto="estimate-compute-title"
                    class="pl-6 mb-4 font-weight-bold">
                    {{ title }}
                </v-col>
            </v-row>
        </div>
        <div>
            <v-row
                v-for="item in billingItems"
                :key="item.name"
                :class="['pa-0 text-caption', { 'unaffected-config' : item.isStatic }]"
                data-auto="billing-units">
                <v-col
                    data-auto="unit-label"
                    class="pl-6 pb-1 pt-1 ">
                    {{ item.name }}
                    <HelpToolTip v-if="item.showToolTip">
                        <template #message>
                            <p>{{ item.tooltipMessage }}</p>
                            <a
                                rel="noopener noreferrer"
                                class="text-capitalize"
                                target="_blank"
                                :href="item.tooltipReference">{{ $t('learn-more') }}</a>
                        </template>
                    </HelpToolTip>
                </v-col>
                <v-col
                    data-auto="unit-value"
                    class="text-right pr-8 pa-0">
                    {{ $t('currency', [$t('perHour', { hour: parseFloat(item.value).toFixed(item.floatPrecision ? item.floatPrecision : 5) })]) }}
                </v-col>
            </v-row>
        </div>
        <div
            class="mt-4 pt-6 computed-wrapper text-caption">
            <v-row>
                <v-col class="pl-6 pa-0">
                    <span class="font-weight-bold">{{
                        $t("launchService.estimatedTotal")
                    }}</span>
                    <HelpToolTip>
                        <template #message>
                            <p>{{ $t('billings.costEstimateToolTip') }}</p>
                            <a
                                rel="noopener noreferrer"
                                class="text-capitalize"
                                target="_blank"
                                href="https://r.mariadb.com/skysql-pricing">{{
                                    $t('learn-more') }}</a>
                        </template>
                    </HelpToolTip>
                </v-col>
                <v-col
                    data-auto="estimate-total-hour"
                    class="text-right pr-8 pa-0">
                    {{ $t('currency',[$t('perHour', { hour: parseFloat(totalEstimate.costPerHour).toFixed(5)} )]) }}
                </v-col>
            </v-row>
            <v-row>
                <v-col />
                <v-col
                    data-auto="estimate-total-month"
                    class="text-right pr-8">
                    {{ $t('currency',[$t('perMonth', { month: parseFloat(totalEstimate.costPerMonth).toFixed(2)} )]) }}
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>

    import HelpToolTip from 'components/common/HelpTooltip'

    const ServiceComputation = {
        name: 'ServiceComputation',
        components: { HelpToolTip, },
        props: {
            title: {
                type: String,
                default: '',
            },
            billingItems: {
                type: Array,
                required: true,
            },
            totalEstimate: {
                type: Object,
                required: true,
            },
        },
    }

    export default ServiceComputation
</script>
