export function innoDbDataRead ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(rate(mariadb_global_status_innodb_data_read{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}

export function innoDbDataWrite ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(rate(mariadb_global_status_innodb_data_written{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}
