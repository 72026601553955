<template>
    <svg viewBox="0 0 16 16">
        <path
            d="M6.40596 16C6.28024 15.9998 6.15888 15.9538 6.06458 15.8707C5.84734 15.6793 0.716309 11.1069 0.716309 6.68965C0.716309 5.18066 1.31575 3.73348 2.38277 2.66646C3.44979 1.59944 4.89697 1 6.40596 1C7.91495 1 9.36214 1.59944 10.4292 2.66646C11.4962 3.73348 12.0956 5.18066 12.0956 6.68965C12.0956 11.1069 6.96458 15.6793 6.74734 15.8707C6.65305 15.9538 6.53169 15.9998 6.40596 16V16ZM6.40596 2.03448C5.17134 2.03448 3.98727 2.52494 3.11426 3.39795C2.24125 4.27097 1.75079 5.45503 1.75079 6.68965C1.75079 10.0259 5.27838 13.6879 6.40596 14.7741C7.53355 13.6879 11.0611 10.0259 11.0611 6.68965C11.0611 6.07833 10.9407 5.47299 10.7068 4.9082C10.4728 4.34341 10.1299 3.83022 9.69767 3.39795C9.2654 2.96568 8.75221 2.62278 8.18742 2.38884C7.62263 2.15489 7.01729 2.03448 6.40596 2.03448V2.03448Z"
            fill="#0E6488"
            stroke="#0E6488"
            stroke-width="0.3" />
        <path
            d="M6.40603 9.27593C5.89453 9.27593 5.39451 9.12425 4.96921 8.84008C4.54391 8.5559 4.21243 8.15199 4.01669 7.67942C3.82094 7.20685 3.76973 6.68686 3.86952 6.18518C3.96931 5.68351 4.21562 5.22269 4.57731 4.861C4.939 4.49931 5.39981 4.253 5.90149 4.15321C6.40316 4.05342 6.92316 4.10464 7.39573 4.30038C7.8683 4.49612 8.27221 4.8276 8.55639 5.2529C8.84056 5.6782 8.99224 6.17822 8.99224 6.68972C8.99224 7.37563 8.71976 8.03344 8.23476 8.51845C7.74975 9.00346 7.09194 9.27593 6.40603 9.27593ZM6.40603 5.138C6.09913 5.138 5.79912 5.22901 5.54394 5.39951C5.28876 5.57002 5.08987 5.81236 4.97243 6.0959C4.85498 6.37945 4.82425 6.69145 4.88412 6.99245C4.944 7.29346 5.09179 7.56995 5.3088 7.78696C5.52581 8.00397 5.8023 8.15176 6.10331 8.21163C6.40431 8.27151 6.71631 8.24078 6.99985 8.12333C7.28339 8.00588 7.52574 7.807 7.69624 7.55182C7.86675 7.29664 7.95776 6.99663 7.95776 6.68972C7.95776 6.27818 7.79427 5.88349 7.50327 5.59249C7.21226 5.30148 6.81758 5.138 6.40603 5.138Z"
            fill="#0E6488"
            stroke="#0E6488"
            stroke-width="0.3" />
    </svg>
</template>

<script>
    export default {}
</script>
