<template>
    <base-dialog
        v-model="isModalOpen"
        :min-width="900"
        :max-width="900"
        :on-close="closeModal"
        :on-cancel="closeModal"
        :on-save="removeProject">
        <template #title>
            <p class="font-weight-regular text-h4 ma-0">
                {{ $t('privateConnect.removeProjectTitle', { connectionName, type }) }}
            </p>
        </template>
        <template #body>
            <div class="pt-6">
                {{
                    $t('privateConnect.removeProjectDescription', {
                        connectionName,
                        type
                    })
                }}
            </div>
        </template>
        <template #actions="{ cancel, save }">
            <v-spacer />
            <v-btn
                color="primary"
                depressed
                rounded
                outlined
                class="cancel"
                @click="cancel">
                <span class="text-none">{{ $t('cancel') }} </span>
            </v-btn>
            <v-btn
                color="error"
                depressed
                rounded
                @click="save">
                <span class="text-none"> {{ $t("remove") }}</span>
            </v-btn>
        </template>
    </base-dialog>
</template>
<script>
    export default {
        name: 'RemovePrivateConnectModal',
        props: {
            connectionName: {
                type: String,
                required: true,
            },
            openDialog: {
                type: Boolean,
                default: false,
            },
            projectId: {
                type: String,
                required: true,
            },
            type: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                isModalOpen: this.openDialog,
            }
        },
        watch: {
            openDialog(val) {
                this.isModalOpen = val
            },
        },
        methods: {
            closeModal() {
                this.$emit('on-close-modal')
            },
            removeProject() {
                this.$emit('on-confirm-project-remove', this.projectId)
            },
        },
    }
</script>
