<style lang="scss" scoped>
.text-viewer {
    background-color: #F0F5F8;
    border: 1px solid $cornsilk;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;

    &__title {
        font-size: 13px;
    }

    pre {
        white-space: break-spaces;
    }
}
</style>
<template>
    <div
        class="text-viewer rounded d-flex flex-column"
        :style="{ height: `${height}px` }">
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary" />
        <div class="px-8 py-4 d-flex flex-column fill-height">
            <p class="text-viewer__title text-uppercase color text-blue-azure font-medium mb-2">
                {{ title }}
            </p>
            <pre class="overflow-auto">{{ text }}</pre>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LogViewer',
        props: {
            text: {
                type: String, required: true,
            },
            title: {
                type: String, required: true,
            },
            height: {
                type: Number, default: 400,
            },
            loading: {
                type: Boolean, default: false,
            },
        },
    }
</script>
