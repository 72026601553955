<template>
    <v-container
        class="ma-0 pa-12"
        fluid>
        <v-col cols="auto pa-0 wrapper-container">
            <div class="mb-4 view-headline color text-navigation font-weight-light">
                {{ $t('partnerIntegrations.title') }}
            </div>
            <PartnerIntegrations />
        </v-col>
    </v-container>
</template>


<script>
    import PartnerIntegrations from 'components/integrations/PartnerIntegrations.vue'

    export default {
        name: 'Integrations',
        components: { PartnerIntegrations, },
    }
</script>
