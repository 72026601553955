<style lang="scss" scoped>
.v-icon {
    color: $inputs;
}
.wrapper {
    text-align: center;
    font-size: 0.625rem !important;
}

</style>
<template>
    <div class="d-flex flex-column align-center wrapper">
        <v-icon
            class="pr-1"
            size="24">
            $vuetify.icons.silencedAlert
        </v-icon>
        <div id="silence-description">
            {{ description }}
        </div>
        <div
            id="silence-cancel"
            class="color text-links pointer"
            @click="cancelSilence">
            {{ $t('cancel') }}
        </div>
    </div>
</template>
<script>
    import { mapActions } from 'vuex'
    export default {
        name: 'AlertsSilenced',
        props: {
            activeSilence: { type: Object, required: true, },
        },
        computed: {
            untilDate() {
                return this.$moment(this.activeSilence['end_time']).format('hh:mmA MM/DD/YYYY')
            },
            description() {
                const suffix = this.activeSilence.context === this.$config.allTopologiesTag ? this.$t('silencing.onYourAccountUntil') : this.$t('silencing.onThisServiceUntil')
                return  `${suffix} ${this.untilDate}`
            },
        },
        methods: {
            ...mapActions(['deleteSilence']),
            cancelSilence() {
                this.deleteSilence(this.activeSilence.id)
            },
        },
    }
</script>
