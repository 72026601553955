<template>
    <v-container class="container ma-0 pa-12">
        <div class="text-h4 d-flex align-baseline">
            <span>{{ user.name }}</span>
        </div>
        <span class="email">
            {{ user.email }}
        </span>

        <div class="mt-5">
            <div class="text-uppercase font-weight-medium">
                Notification preferences
            </div>
            <div class="d-flex align-center font-weight-medium">
                <v-checkbox
                    :value="isAnySelected"
                    @change="toggleAll" />
                {{ $t('sendMeNotificationsByEmail') }}
            </div>
            <div>
                <div
                    v-for="(category,index) in preferences"
                    :key="preferences[index].key">
                    <v-checkbox
                        v-model="category.value"
                        class="pa-0 ma-0 ml-10"
                        :label="category.key"
                        @change="onChange" />
                </div>
            </div>
        </div>
    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex'
    import notifications from 'api/notifications'

    export default {
        name: 'Notifications',
        data () {
            return {
                preferences: [],
                selectedAll: null,
            }
        },
        computed: {
            ...mapGetters(['user']),
            isAnySelected() { return this.preferences.find(preference => preference.value ) ? true : false },
        },
        methods: {
            onChange() { this.editPreferences() },
            toggleAll(e) {
                if(e) this.preferences.map(el => el.value = true)
                else this.preferences.map(el => el.value = false)

                this.editPreferences()
            },
            async editPreferences() {
                const remappingObject = this.preferences.reduce((map, preference) =>{
                    map[preference.key] = preference.value
                    return map
                }, {})

                try {
                    await notifications.put('preferences', { categories: remappingObject, })
                }
                catch (error) {
                    /* eslint-disable */
                    console.log('error', error)
                }
            },
        },
        async mounted() {
            try {
                const { data, } = await notifications.get(`preferences`)

                this.preferences = Object.keys(data.categories).map(el => {
                    return {
                        key: el,
                        value: data.categories[el],
                    }
                })
            } catch (error) {
                /* eslint-disable */
                console.log('error', error)
            }
        },
    }
</script>
