<style lang="scss" scoped >
    ::v-deep.v-chip.tag {
        border-radius: 18px;
        height: 27px;
        font-size: 12px;
        &.small {
            height: 20px;
            font-size: 10px;
        }
    }
</style>

<template>
    <v-chip
        :color="color"
        :class="{ small }"
        class="tag mr-1 mt-1"
        v-bind="$attrs">
        <span
            v-if="$slots['left-slot']"
            class="mr-2 d-flex">
            <slot name="left-slot"></slot>
        </span>

        <slot></slot>

        <span
            v-if="$slots['right-slot']"
            class="ml-1 d-flex">
            <slot name="right-slot"></slot>
        </span>
    </v-chip>
</template>

<script>
    export default {
        name: 'Tag',
        props: {
            color: { type: String, default: 'e8eef1', },
            small: { type: Boolean, default: false, },
        },
    }
</script>
