<style lang="scss" scoped>
    .no-selection{
        height: 300px;
        p{
            width: 400px;
        }
    }
</style>
<template>
    <div>
        <v-row>
            <v-col cols="9">
                <p class="text-body-2">
                    {{ $t('partnerIntegrations.description') }}
                </p>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="2"
                class="ml-3">
                <PartnerList
                    :partners="partners"
                    :selected-partner="selectedPartner"
                    @on-click="onPartnerSelection" />
            </v-col>
            <v-col
                cols="8"
                class="ml-6">
                <PartnerDetails
                    v-if="selectedPartner"
                    :features="features"
                    :title="title"
                    :sub-title="subTitle"
                    :description="description"
                    :supported-databases="supportedDatabases">
                    <template #description>
                        <i18n
                            v-if="hasEmbeddedDescription"
                            :path="`partnerIntegrations.partners.${selectedPartner.toLowerCase()}.description`"
                            tag="p">
                            <a
                                v-for="(desc, index) in descriptionLinks"
                                :key="index"
                                rel="noopener noreferrer"
                                class="text-capitalize"
                                target="_blank"
                                :place="index"
                                :href="desc.link">
                                {{ desc.text }}
                            </a>
                        </i18n>
                    </template>
                    <template #documentation>
                        <div
                            v-for="(doc, index) in documentation"
                            :key="index">
                            <a
                                rel="noopener noreferrer"
                                class="text-capitalize"
                                target="_blank"
                                :href="doc.link">{{ doc.text }}</a>
                        </div>
                    </template>
                </PartnerDetails>
                <div
                    v-else
                    class="d-flex align-center justify-center no-selection">
                    <p
                        v-if="showIfMarketingAllows"
                        class="d-block mt-12 text-center">
                        {{ $t('partnerIntegrations.noPartnerSelectedText') }}
                    </p>
                </div>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import PartnerList from 'components/integrations/PartnerList.vue'
    import PartnerDetails from 'components/integrations/PartnerDetails.vue'
    import { getDescriptionLinks, getDocumentationLinks } from 'utils/integrations'

    export default {
        name: 'PartnerIntegrations',
        components: { PartnerList, PartnerDetails, },
        data(){
            return {
                selectedPartner: '',
            }
        },
        computed: {
            partners() {

                const partnerList = [
                    { name: this.$t('partnerIntegrations.partners.qlik.title'), icon: '$vuetify.icons.qlik', iconSize: 67, category: 'data-integrations', top: '-5px', isVisible: true, },
                    { name: this.$t('partnerIntegrations.partners.striim.title'), icon: '$vuetify.icons.striim', iconSize: 73, category: 'data-integrations', top: '-5px', isVisible: true, },
                    { name: this.$t('partnerIntegrations.partners.mindsdb.title'), icon: '$vuetify.icons.mindsDB', iconSize: 113, category: 'ml-analytics', top: '-25px', isVisible: true, },
                    { name: this.$t('partnerIntegrations.partners.hashicorp.title'), icon: '$vuetify.icons.hashiCorp', iconSize: 114, category: 'terraform', top: '-30px', isVisible: false, }
                ]

                if (this.$unleash.isFeatureEnabled('enable-portal-partner-integrations-v2')) {
                    partnerList.splice(2, 0,
                                       { name: this.$t('partnerIntegrations.partners.hasura.title'), icon: '$vuetify.icons.hasura', iconSize: 80, category: 'data-integrations', top: '-10px', isVisible: true, }
                    )

                    partnerList.splice(3, 0,
                                       { name: this.$t('partnerIntegrations.partners.liquibase.title'), icon: '$vuetify.icons.liquibase', iconSize: 80, category: 'devops', top: '-10px', isVisible: true, }
                    )

                    partnerList.push({ name: this.$t('partnerIntegrations.partners.striim.title'), icon: '$vuetify.icons.hashiCorp', iconSize: 90, category: 'terraform', top: '-15px', isVisible: true, })
                }
                return partnerList
            },
            title(){
                return this.getTranslationString('title')
            },
            features(){
                if(this.getTranslationString('features').length > 0)
                    return this.getTranslationString('features')
                return []
            },
            subTitle(){
                return this.getTranslationString('subTitle')
            },
            description(){
                return !this.hasEmbeddedDescription ? this.getTranslationString('description') : ''
            },
            documentation(){
                return getDocumentationLinks(this.selectedPartner)
            },
            hasEmbeddedDescription(){
                return ['qlik'].includes(this.selectedPartner.toLowerCase())
            },
            descriptionLinks(){
                return getDescriptionLinks(this.selectedPartner)
            },
            supportedDatabases(){
                return this.getTranslationString('supportedDatabases')
            },
            showIfMarketingAllows() {
                return false
            },
        },
        methods: {
            onPartnerSelection(partner){
                this.selectedPartner = partner.name
            },
            getTranslationString(translationKey){
                const translationProp = `partnerIntegrations.partners.${this.selectedPartner.toLowerCase()}.${translationKey}`
                return this.$i18n.te(translationProp) ? this.$i18n.t(translationProp) : ''
            },

        },
    }
</script>
