<style lang="scss" scoped>
    .control-label {
        font-size: 0.675rem;
    }
    :deep(.v-list-item__action) {
        transform: scale(0.6) !important;
        margin-right: 10px !important;
    }

</style>

<template>
    <v-container
        fluid
        class="ma-0 pa-12">
        <v-col cols="auto pa-0 wrapper-container">
            <div class="mb-4 view-headline  text-capitalize color text-navigation font-weight-light">
                <span>{{ $tc('notification', 2) }}</span>
            </div>

            <div class="d-flex align-center justify-space-between mb-2">
                <div style="width: 420px">
                    <label class="d-block color text-small-text control-label text-capitalize">
                        {{ $t('category') }}
                    </label>
                    <v-select
                        v-model="categories"
                        full-width
                        class="std--dropdown categories"
                        :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list category-list' }"
                        outlined
                        dense
                        attach
                        required
                        hide-details
                        :items="items"
                        multiple
                        @change="onCategoryChange">
                        <template #selection="{ item }">
                            <v-chip
                                class="my-1"
                                small>
                                <span>{{ item }}</span>
                            </v-chip>
                        </template>
                    </v-select>
                </div>
                <div class="ml-2">
                    <label class="d-block color text-small-text control-label"> {{ $t('timeFrame') }} (UTC) </label>
                    <date-range-picker
                        :has-custom-range="true"
                        @change="onTimeChange" />
                </div>
                <router-link
                    class="settings ml-auto ml-2 pt-4"
                    to="/settings/notification-channels">
                    <v-tooltip top>
                        <template #activator="{ on }">
                            <v-icon
                                size="28"
                                color="primary"
                                v-on="on">
                                $vuetify.icons.settings
                            </v-icon>
                        </template>
                        <span class="text-capitalize">{{ $tc('channel', 0) }}</span>
                    </v-tooltip>
                </router-link>
            </div>
            <div class="mt-5">
                <v-data-table
                    :headers="headers"
                    fixed-header
                    :items="notifications"
                    :options.sync="options"
                    :sort-by="'timestamp'"
                    :loading="isLoading"
                    item-key="name"
                    :footer-props="footerOptions"
                    must-sort
                    class="data-table-full">
                    <template #no-data>
                        <span>{{ $t('info.noNotifsDefined') }}</span>
                    </template>
                    <template #item="{ item: notification }">
                        <tr>
                            <td>
                            </td>
                            <td>
                                <icon-sprite-sheet
                                    :size="12"
                                    :frame="notification.level"
                                    class="mr-1">
                                    notification
                                </icon-sprite-sheet>
                                <router-link
                                    v-if="notification.event_type === 'CORTEX_ALERTS_FIRED'"
                                    to="/alerts">
                                    {{ notification.display_string }}
                                </router-link>
                                <span v-else>
                                    {{ notification.display_string }}
                                </span>
                            </td>
                            <td>
                                {{ notification.category }}
                            </td>

                            <td>
                                {{ notification.created_on | unixToDate }}
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </div>
        </v-col>
    </v-container>
</template>

<script>
    import notifications from 'api/notifications'

    export default {
        name: 'Notifications',

        data () {
            return {
                isLoading: false,
                headers: [
                    { text: '', value: 'new', sortable: false, },
                    { text: this.$t('title'), value: 'title', },
                    { text: this.$t('category'), value: 'category', },
                    { text: this.$t('timestamp'), value: 'timestamp', }
                ],
                notifications:[],
                categories: this.$config.ALL_CATEGORIES,
                items: this.$config.ALL_CATEGORIES,
                dateRange: null,
                options: {},
                footerOptions: {
                    'items-per-page-options': [100, 20, -1],
                },
            }
        },
        methods: {
            onTimeChange (range) {
                this.dateRange = range
                this.fetchNotifications()
            },
            onCategoryChange () {
                this.fetchNotifications()
            },
            async fetchNotifications() {
                this.isLoading = true
                const { itemsPerPage, } = this.options

                const params = {
                    /* eslint-disable camelcase */
                    page_size: itemsPerPage,
                    created_after: this.dateRange? this.dateRange.from : '',
                    created_before: this.dateRange ? this.dateRange.to : '',
                    /* eslint-enable */
                    category: this.categories.join(','),
                }

                try {
                    const { data, } = await notifications.get('notifications', params)
                    this.notifications = data
                } catch (error) {
                    /* eslint-disable */
                    console.log('error', error)
                }
                this.isLoading = false
            }
        }
    }
</script>
