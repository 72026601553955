import i18n from 'plugins/i18n'
import {
    isStandAloneTopology,
    isPostgresStandAlone,
    isPrimaryReplica,
    isXpand,
    isColumnstore,
    isServerlessAnalytics
} from 'utils/service'

//TODO:: Move all the features into i18n and refactor as an utility
// https://jira.mariadb.org/browse/DBAAS-11707

function setTopologyAttributes(
    features = [],
    iconSize = 0,
    icon = '',
    title = '',
    description = '',
    serverType = '',
    isTechPreview = false
) {
    return {
        features,
        iconSize,
        icon,
        title,
        description,
        serverType,
        isTechPreview,
    }
}

export function getTopologyAttributes(topology) {
    if (isStandAloneTopology(topology)) {
        return setTopologyAttributes(
            [
                'SQL for relational & JSON',
                'MySQL/Oracle compatible',
                'Self-healing',
                'Fast, easy and affordable'
            ],
            50,
            '$vuetify.icons.singleNode',
            i18n.t('topology.es-single'),
            i18n.t('launchService.txn-single-node-desc'),
            i18n.t('serverType.es-single')
        )
    }
    if (isPostgresStandAlone(topology)) {
        return setTopologyAttributes(
            [
                'SQL for relational & JSON',
                'Self-healing',
                'Self-healing',
                'Fast, easy and affordable'
            ],
            50,
            '$vuetify.icons.singleNode',
            i18n.t('topology.es-single'),
            'For small projects and development with moderate concurrency',
            i18n.t('dbType.postgres')
        )
    }
    if (isPrimaryReplica(topology)) {
        return setTopologyAttributes(
            [
                'All single node functionality',
                'Data proxy for load balancing and automatic zero-interruption failover',
                'Read scale (all replicas serve read requests)',
                'MongoDB compatible NoSQL API'
            ],
            80,
            '$vuetify.icons.replicated',
            i18n.t('topology.es-replica'),
            i18n.t('launchService.txn-replicated-desc'),
            i18n.t('serverType.es-replica')
        )
    }
    if (isXpand(topology)) {
        if (topology === 'xpand-pg') {
            return setTopologyAttributes(
                [
                    'Survive multiple zone and node failures',
                    'Millions of queries per second',
                    'Elastic horizontal scaling (out/in)',
                    'Tables sliced and distributed across nodes'
                ],
                80,
                '$vuetify.icons.distributed',
                i18n.t('topology.xpand-pg'),
                'Read/write scaling with high concurrency and availability',
                i18n.t('serverType.xpand-pg')
            )
        } else {
            return setTopologyAttributes(
                [
                    'Survive multiple zone and node failures',
                    'Millions of queries per second',
                    'Elastic horizontal scaling (out/in)',
                    'Tables sliced and distributed across nodes',
                    'MariaDB/MySQL compatibility'
                ],
                80,
                '$vuetify.icons.distributed',
                `${i18n.t('serverType.xpand')} ${i18n.t('topology.xpand')}`,
                i18n.t('launchService.txn-distributed-desc')
            )
        }
    }
    if (isColumnstore(topology)) {
        return setTopologyAttributes(
            [
                'SQL for relational & JSON',
                'Share and partition InnoDB data',
                'High-speed direct S3/GCS ingestion',
                '10X compression'
            ],
            80,
            '$vuetify.icons.distributed',
            i18n.t('topology.csdw'),
            i18n.t('launchService.analytics-multi-node-desc'),
            i18n.t('serverType.csdw')
        )
    }
    if (isServerlessAnalytics(topology)) {
        return setTopologyAttributes(
            [
                'Pay only for what you use',
                'Adhoc, high performance analytics without static provisioning',
                'Any data format. Eg. JSON, .CSV, Parquet, AVRO',
                'Federated join across any data source',
                'Notebook powered by Apache Zeppelin (Java, Python, Scala, R)'
            ],
            80,
            '$vuetify.icons.replicated',
            i18n.t('topology.sa'),
            i18n.t('launchService.serverlessDesc'),
            i18n.t('serverType.sa'),
            true
        )
    }

    return {}
}

export function getTopologyName(topology = '', isShortName) {
    return `${i18n.t(`serverType.${topology}`)} ${
        isShortName ? '' : i18n.t(`topology.${topology}`)
    }`.trim()
}

export function addMockTopologies(topologies) {
    // Add Postgres Single Node
    if (topologies.some(topology => topology.database === 'postgres')) {
        topologies.push({
            'id': 'not-real-00000',
            'name': 'pg-single',
            'display_name': 'PostgreSQL Single Node',
            'service_type': 'transactional',
            'storage_engine': 'default',
            'order': 340,
            'database': 'postgres',
            'shouldDisable': true,
        })
    }

    return topologies
}
