<style lang="scss" scoped>
.topology-selection-wrapper {
    border: 1px solid #bed1da;
    border-right: none;
    border-radius: 0.5rem;
    cursor: pointer;
}
</style>
<template>
    <v-expansion-panel
        id="topologyPanel"
        :data-auto="dataAutoPrefix()">
        <AccordianPanelHeader
            :title="$t('launchService.topologyTypeTitle')"
            :parent-data-auto-prefix="dataAutoPrefix">
            <!-- Tooltip goes here -->
            <template #tooltip-content>
                <p>{{ $t('launchService.topologyInfo') }}</p>
                <a
                    rel="noopener noreferrer"
                    class="text-capitalize"
                    target="_blank"
                    href="https://r.mariadb.com/snr-launch-type-topology">{{ $t('learn-more') }}</a>
            </template>
        </AccordianPanelHeader>
        <v-expansion-panel-content :data-auto="dataAutoPrefix('content')">
            <div
                v-if="!serviceTypes.length"
                class="text-center pt-3">
                <v-progress-circular
                    size="70"
                    width="7"
                    color="primary"
                    indeterminate />
            </div>
            <v-row
                class="mt-4"
                align="center"
                justify="center">
                <v-col
                    v-for="(database, index) in serviceTypes"
                    :key="index"
                    cols="3"
                    class="pa-1">
                    <DatabaseTypeCard
                        :name="database"
                        :title="$t(`dbType.${database}`)"
                        :icon="database"
                        :is-selected="database === selectedDbType"
                        :parent-data-auto-prefix="dataAutoPrefix"
                        @handleDatabaseRequirementSelection="
                            handleDatabaseRequirementSelection
                        " />
                </v-col>
            </v-row>

            <v-row v-if="selectedDbType">
                <v-col cols="auto mt-4 pa-0 wrapper-container">
                    {{ $t('launchService.withTopology') }}
                </v-col>
            </v-row>
            <v-expand-transition v-if="selectedDbType">
                <v-row
                    align="center"
                    justify="center"
                    class="pt-6 topology-wrapper">
                    <v-col cols="mt-3 mb-3 wrapper-container">
                        <div
                            v-if="isTopologyFetching"
                            class="text-center">
                            <v-progress-circular
                                size="20"
                                width="7"
                                color="primary"
                                indeterminate />
                        </div>
                        <v-row
                            v-else
                            class="d-flex topology-selection-wrapper">
                            <TopologyCard
                                v-for="topologyDetails in matchedTopologies"
                                :id="topologyDetails.id"
                                :key="topologyDetails.type"
                                :title="getTopologyTitle(topologyDetails)"
                                :server-type="topologyDetails.serverType"
                                :type="topologyDetails.name"
                                :description="topologyDetails.description"
                                :features="topologyDetails.features"
                                :icon="topologyDetails.icon"
                                :icon-size="topologyDetails.iconSize"
                                :current-topology-id="currentTopologyId"
                                :is-tech-preview="topologyDetails.isTechPreview"
                                :should-disable="topologyDetails.shouldDisable"
                                @handleTopologySelection="
                                    handleTopologySelection
                                " />
                        </v-row>
                    </v-col>
                </v-row>
            </v-expand-transition>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import TopologyCard from 'components/launchService/TopologyCard.vue'
    import DatabaseTypeCard from 'components/launchService/DatabaseTypeCard.vue'
    import AccordianPanelHeader from 'components/launchService/AccordianPanelHeader.vue'
    import { isServerlessAnalytics } from 'utils/service'

    export default {
        name: 'TopologySelection',
        components: {
            TopologyCard,
            DatabaseTypeCard,
            AccordianPanelHeader,
        },
        props: {
            isNeedForDBSelected: Boolean,
            isTopologySelected: Boolean,
            parentDataAutoPrefix: {
                type: Function,
                default: () => {},
            },
        },
        data: function () {
            return {
                currentTopologyId: null,
                selectedDbType: null,
                matchedTopologies: [],
            }
        },
        computed:{
            ...mapGetters(['serviceTypes','topologiesDetails', 'isTopologyFetching', 'isServiceTypesFetching']),
        },
        methods: {
            ...mapActions(['fetchServiceTypes','fetchTopologyDetails']),
            async handleDatabaseRequirementSelection (dbType) {
                this.matchedTopologies = this.topologiesDetails.filter(topology=> topology.database === dbType)
                this.selectedDbType = dbType
                this.currentTopologyId = null
                this.$emit('onRequirementOfDBSelect', dbType)
            },
            handleTopologySelection (id) {
                this.currentTopologyId = id
                const selectedTopology = this.topologiesDetails.find(topology =>
                    topology.id === id
                )
                this.$emit('onTopologySelect', selectedTopology)
            },
            dataAutoPrefix (string) {
                return this.parentDataAutoPrefix(
                    `topologyPanel${string ? '-' + string : ''}`
                )
            },
            getTopologyTitle(topology) {
                if (!isServerlessAnalytics(topology.name)) return topology.title
                return this.$i18n.t('poweredBy_X', { name: topology.title, })
            },
        },
        async created () {
            if (this.$lodash.isEmpty(this.serviceTypes) && !this.isServiceTypesFetching)
                await this.fetchServiceTypes()
            if(this.$lodash.isEmpty(this.topologiesDetails) && !this.isTopologyFetching)
                await this.fetchTopologyDetails()
        },
    }
</script>
