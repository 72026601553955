import RosOnboarding from 'pages/RosOnboarding.vue'

export default [
    {
        path: '/observability-onboarding',
        name: 'rosOnboarding',
        component: RosOnboarding,
        meta: {
            requiresAuth: true,
            title: 'Observability',
        },
    }
]
