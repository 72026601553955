<style lang="scss" scoped >
    ::v-deep.v-chip.label-chip {
        border-radius: 18px;
        height: 100%;

        .v-chip__content {
            height: 27px;
            .label-chip-icon {
                transform: translate(10px);
                height: 100%;
                padding: 0 10px 0 0;
                display: flex;
                align-items: center;
                cursor: pointer;
                svg,
                i {
                    color: inherit;
                }
            }
        }
    }
</style>

<template>
    <v-chip
        class="label-chip mr-1 mt-1"
        :class="[customClass]"
        v-bind="resetProps">
        <span> {{ text }}</span>
        <span
            v-if="!noIcon"
            class="label-chip-icon"
            @click="iconClickHandler">
            <v-icon size="8">{{ icon }}</v-icon>
        </span>
    </v-chip>
</template>

<script>
    // TODO: Remove as this components is used in only one place
    export default {
        name: 'LabelChip',
        props: {
            customClass: { type: String, default: '', },
            text: { type: String, default: 'label', },
            onIconClick: {
                type: Function,
                default: (txt) => txt,
            },
            icon: { type: String, default: '$vuetify.icons.close', },
            noIcon: { type: Boolean, default: false, },
            inverse: { type: Boolean, default: false, },
            color: { type: String, default: '#2d9cdb', },
            textColor: { type: String, default: '', },
        },
        computed: {
            resetProps () {
                return { ...this.$attrs, }
            },
        },
        methods: {
            iconClickHandler (e) {
                if (this.$typy(this.onIconClick).isFunction) this.onIconClick(this.text, e, this)
            },
        },
    }
</script>
