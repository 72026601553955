<template>
    <svg
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
            fill="#7DD012" />
        <path
            d="M7.65057 10.7963C7.29106 11.1705 6.70774 11.1705 6.34841 10.7963L4.33091 8.6961C3.9714 8.32205 3.9714 7.71483 4.33091 7.34078C4.69023 6.96655 5.27355 6.96655 5.63306 7.34078L6.83509 8.59189C6.92584 8.68617 7.07314 8.68617 7.16406 8.59189L10.4188 5.20377C10.7782 4.82954 11.3615 4.82954 11.721 5.20377C11.8936 5.38348 11.9906 5.62731 11.9906 5.88143C11.9906 6.13555 11.8936 6.37938 11.721 6.55909L7.65057 10.7963Z"
            fill="white" />
    </svg>
</template>

<script>
    export default {}
</script>
