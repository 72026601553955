<template>
    <div>
        <v-row>
            <v-col>
                <p class="font-weight-bold">
                    {{ title }}
                </p>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                {{ subTitle }}
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="8">
                <slot name="description">
                    {{ description }}
                </slot>
            </v-col>
        </v-row>
        <v-row v-if="features.length">
            <v-col cols="8">
                <ul>
                    <li
                        v-for="(feature, index) in features"
                        :key="index">
                        {{ feature }}
                    </li>
                </ul>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <slot name="documentation">
                </slot>
            </v-col>
        </v-row>
        <v-row v-if="supportedDatabases">
            <v-col>
                <span class="font-weight-bold">{{ $t('partnerIntegrations.certifiedSupportedDatabases') }}</span> {{ supportedDatabases }}
            </v-col>
        </v-row>
    </div>
</template>
<script>
    export default {
        name: 'PartnerDetails',
        props: {
            title: {
                type: String,
                required: true,
            },
            features: {
                type: Array,
                required: true,
            },
            subTitle: {
                type: String,
                required: true,
            },
            description: {
                type: String,
                required: true,
            },
            supportedDatabases: {
                type: String,
                default: '',
            },
        },
    }
</script>
