<template>
    <v-overlay
        :opacity="1"
        :absolute="false"
        class="overlay">
        <unauthorized-message />
    </v-overlay>
</template>

<script>
    import UnauthorizedMessage from 'components/messages/Unauthorized'

    export default {
        name: 'UnauthorizedOverlay',
        components: { 'unauthorized-message': UnauthorizedMessage, },
    }
</script>
