<template>
    <svg
        viewBox="0 0 66 63"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_333_8338)">
            <path
                d="M64.59 7.12V24.94C64.59 26.61 63.48 28.28 61.28 29.55C58.802 30.8118 56.0607 31.4696 53.28 31.4696C50.4993 31.4696 47.758 30.8118 45.28 29.55C43.1 28.28 42 26.61 42 24.94V7.12"
                fill="#D9DAE0" />
            <path
                d="M64.59 7.12V24.94C64.59 26.61 63.48 28.28 61.28 29.55C58.802 30.8118 56.0607 31.4696 53.28 31.4696C50.4993 31.4696 47.758 30.8118 45.28 29.55C43.1 28.28 42 26.61 42 24.94V7.12"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-dasharray="2 2" />
            <path
                d="M64.59 7.12C64.5876 7.60261 64.496 8.08063 64.32 8.53C63.7154 9.90839 62.6566 11.0378 61.32 11.73C58.842 12.9918 56.1007 13.6496 53.32 13.6496C50.5393 13.6496 47.798 12.9918 45.32 11.73C43.9662 11.045 42.8893 9.9151 42.27 8.53C42.0935 8.08076 42.002 7.60266 42 7.12C42 5.45 43.1 3.78 45.31 2.51C47.788 1.24818 50.5293 0.590408 53.31 0.590408C56.0907 0.590408 58.832 1.24818 61.31 2.51C63.48 3.78 64.59 5.45 64.59 7.12Z"
                fill="white"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-dasharray="2 2" />
            <path
                d="M64.5901 32.62V24.94"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-dasharray="2 2" />
            <path
                d="M59.6901 36.88V30.32"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-dasharray="2 2" />
            <path
                d="M46.9 36.88V30.32"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-dasharray="2 2" />
            <path
                d="M53.29 38.03V31.46"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-dasharray="2 2" />
            <path
                d="M42 32.62V24.94"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-dasharray="2 2" />
            <path
                d="M43.89 19.07V36.89C43.89 38.56 42.79 40.23 40.58 41.5C38.1021 42.7618 35.3608 43.4196 32.58 43.4196C29.7993 43.4196 27.058 42.7618 24.58 41.5C22.37 40.23 21.27 38.56 21.27 36.89V19.07"
                fill="#D9DAE0" />
            <path
                d="M43.89 19.07V36.89C43.89 38.56 42.79 40.23 40.58 41.5C38.1021 42.7618 35.3608 43.4196 32.58 43.4196C29.7993 43.4196 27.058 42.7618 24.58 41.5C22.37 40.23 21.27 38.56 21.27 36.89V19.07"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-dasharray="2 2" />
            <path
                d="M43.89 19.07C43.8862 19.5516 43.7982 20.0288 43.63 20.48C43.0073 21.8628 41.9314 22.9917 40.58 23.68C38.1021 24.9418 35.3608 25.5996 32.58 25.5996C29.7993 25.5996 27.058 24.9418 24.58 23.68C23.2262 22.995 22.1493 21.8651 21.53 20.48C21.3618 20.0288 21.2738 19.5516 21.27 19.07C21.27 17.4 22.37 15.73 24.58 14.46C27.058 13.1982 29.7993 12.5404 32.58 12.5404C35.3608 12.5404 38.1021 13.1982 40.58 14.46C42.79 15.73 43.89 17.4 43.89 19.07Z"
                fill="white"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-dasharray="2 2" />
            <path
                d="M43.89 44.57V36.89"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-dasharray="2 2" />
            <path
                d="M38.99 48.83V42.27"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-dasharray="2 2" />
            <path
                d="M26.2001 48.83V42.27"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-dasharray="2 2" />
            <path
                d="M32.5901 49.98V43.41"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-dasharray="2 2" />
            <path
                d="M21.3 44.57V36.89"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-dasharray="2 2" />
            <path
                d="M23.19 31C23.1862 31.4816 23.0982 31.9588 22.93 32.41C22.3108 33.7951 21.2338 34.925 19.88 35.61C17.4021 36.8718 14.6608 37.5296 11.88 37.5296C9.0993 37.5296 6.358 36.8718 3.88004 35.61C2.52869 34.9217 1.45272 33.7928 0.830036 32.41C0.672043 31.9569 0.594213 31.4798 0.600036 31C0.600036 29.33 1.70004 27.66 3.91004 26.39C6.388 25.1282 9.1293 24.4704 11.91 24.4704C14.6908 24.4704 17.4321 25.1282 19.91 26.39C22.09 27.68 23.19 29.35 23.19 31Z"
                fill="#61C9D2"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M23.19 31V48.84C23.19 50.51 22.09 52.18 19.88 53.45C17.402 54.7118 14.6607 55.3696 11.88 55.3696C9.09924 55.3696 6.35794 54.7118 3.87998 53.45C1.69998 52.18 0.599976 50.51 0.599976 48.84V31C0.603773 31.4816 0.691765 31.9588 0.859976 32.41C1.48266 33.7928 2.55863 34.9217 3.90998 35.61C6.38794 36.8718 9.12924 37.5296 11.91 37.5296C14.6907 37.5296 17.432 36.8718 19.91 35.61C21.2638 34.925 22.3407 33.7951 22.96 32.41C23.118 31.9569 23.1958 31.4798 23.19 31Z"
                fill="#2F99A3"
                stroke="#003545"
                stroke-width="0.8"
                stroke-miterlimit="10" />
            <path
                d="M23.1901 56.52V48.84"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M18.29 60.78V54.22"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M5.5 60.78V54.22"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M11.9 61.93V55.36"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M0.599976 56.52V48.84"
                stroke="#003545"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_333_8338">
                <rect
                    width="65.19"
                    height="62.53"
                    fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
    export default {}
</script>
