import notifications from 'api/notifications'

export default {
    state: {
        channels: [],
        channelsError: false,
        channelsLoading: false,
    },
    mutations: {
        setChannels(state, payload) {
            state.channels = payload
        },
        setChannelsLoading(state, payload) {
            state.channelsLoading = payload
        },
        setChannelsError(state, payload) {
            state.channelsError = payload
        },
    },
    actions: {
        async fetchChannels({ commit, }) {
            commit('setChannelsLoading', true)
            try {
                const { data, } = await notifications.get(
                    'channels/emails'
                )
                commit('setChannels', data)
                commit('setChannelsError', false)
            } catch (error) {
                const message = error.message || 'Error fetching channels.'
                commit('showMessage', { text: message, type: 'error', })
                commit('setChannelsError', error)
            }
            commit('setChannelsLoading', false)
        },
        async addChannel({ dispatch, commit, }, channel) {
            commit('setChannelsLoading', true)

            try {
                await notifications.post('channels/emails', channel)
                await dispatch('fetchChannels')
            } catch (error) {
                commit('setChannelsLoading', false)
                const message = error.message || 'error updating channel'

                commit('showMessage', { text: message, type: 'error', })
                commit('setChannelsError', error)
            }
            commit('setChannelsLoading', false)
        },
        async editChannel({dispatch, commit, }, channel) {
            commit('setChannelsLoading', true)
            try {
                await notifications.patch(`channels/emails/${channel.id}`, channel)
                await dispatch('fetchChannels')
            } catch (error) {
                const message = error.message || 'error updating channel'

                commit('showMessage', { text: message, type: 'error', })
                commit('setChannelsError', error)
            }
            commit('setChannelsLoading', false)
        },
        async deleteChannels({ dispatch, commit, }, channels) {
            commit('setChannelsLoading', true)
            try {
                await Promise.all(
                    channels.map(async (channel) => {
                        await notifications.delete(
                            `channels/emails/${channel.id}`
                        )
                    })
                )
                dispatch('fetchChannels')

            } catch (error) {
                const message = error.message || 'error updating channel'

                commit('showMessage', { text: message, type: 'error', })
                commit('setChannelsError', error)
            }
            commit('setChannelsLoading', false)
        },
    },
    getters: {
        channels: (state) => state.channels,
        channelsLoading: (state) => state.channelsLoading,
        channelsError: (state) => state.channelsError,
    },
}
