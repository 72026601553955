<style lang="scss" scoped>
    .scale-reduce {
        transform: scale(0.625);
        transform-origin: 50% 80%;
    }
    ::v-deep .selected-row {
        background: $light-cyan;
    }
</style>

<template>
    <div>
        <table-actions
            :loading="loading"
            :selected-items="selectedItems"
            :delete-modal-title="`${$t('confirmations.alertDeleteTitle', {
                quantity: selectedItems.length,
                targetType: channelPlural
            })}`"
            :delete-modal-msg="$t('confirmations.channelDeleteMsg', { targetType: channelPlural })"
            delete-modal-item-key-name="name"
            @add-new="addNewChannel"
            @on-delete="deleteSelected"
            @on-cancel-delete="() => handleSelect(false)" />

        <v-data-table
            :headers="headers"
            fixed-header
            :height="$config.alertManagerTableHeight"
            :items="channels"
            :loading="loading"
            hide-default-footer
            disable-pagination
            item-key="name"
            class="data-table-full">
            <template #no-data>
                <span>{{ $t('info.noChannelsDefined') }} -</span>
                <span
                    class="ml-1 color pointer text-links"
                    @click="addNewChannel">{{ $t('addChannel') }}</span>
            </template>
            <template #[`header.select`]>
                <v-checkbox
                    class="scale-reduce ma-0"
                    :input-value="isAllSelected"
                    primary
                    :disabled="!channels.length"
                    @change="handleSelect" />
            </template>
            <template #item="{ item: channel }">
                <tr :class="[{ 'selected-row': channel.selected }]">
                    <td>
                        <v-checkbox
                            v-model="channel.selected"
                            class="scale-reduce ma-0"
                            :input-value="channel.selected"
                            primary />
                    </td>
                    <td>
                        <a
                            class="color text-text text-links"
                            @click="editChannel(channel)"> {{ channel.channel_name }}</a>
                    </td>
                    <td>
                        <v-icon
                            size="21"
                            class="color text-links icon mr-2">
                            $vuetify.icons.emailCustom
                        </v-icon>
                    </td>
                    <td>
                        <div v-if="channel.receivers">
                            <span
                                v-for="(receiver, index) in channel.receivers"
                                :key="index">
                                {{ receiver.email }}
                                <span v-if="index+1 < channel.receivers.length">, </span>
                            </span>
                        </div>
                        <div v-else>
                            {{ $t('noData') }}
                        </div>
                    </td>
                </tr>
            </template>
        </v-data-table>

        <notification-channel-modal ref="notificationChannelModal" />
    </div>
</template>

<script>
    import NotificationChannelModal from 'components/alerts/NotificationChannelModal'
    import TableActions from 'components/alerts/TableActions'
    export default {
        name: 'ChannelsTable',
        components: {
            'notification-channel-modal': NotificationChannelModal,
            'table-actions': TableActions,
        },
        props: {
            channels: { type: Array, required: true, },
            loading: { type: Boolean, default: false, },
            onChanged: { type: Function, default: () => {},},
        },
        data () {
            return {
                headers: [
                    { text: 'select', value: 'select', sortable: false, width: '20px', },
                    { text: 'channel', value: 'channel', width: '25%',},
                    { text: 'type', value: 'type',  sortable: false, width: '60px', },
                    { text: 'to', value: 'to', }

                ],
                defaultChannel: {},
            }
        },
        computed: {
            selectedItems: function () {
                return this.channels.filter((channel) => channel.selected)
            },
            isAllSelected: function () {
                return (this.selectedItems.length === this.channels.length) && this.channels.length > 0
            },
            channelPlural: function () {
                return this.selectedItems.length > 1 ? this.$tc('channel', 2) : this.$tc('channel', 1)
            },
        },
        methods: {
            editChannel (channel) {
                this.$refs.notificationChannelModal.open(channel)
            },
            addNewChannel () {
                this.$refs.notificationChannelModal.open()
            },
            async deleteSelected () {
                this.$emit('channel-delete', this.channels.filter(channel => channel.selected))
            },
            handleSelect (val) {
                this.channels.forEach(channel => (channel.selected = val))
            },
        },
    }
</script>
