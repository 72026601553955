<style lang="scss" scoped>
.silence-title {
    font-size: 1.875rem;
}
</style>
<template>
    <base-dialog
        v-model="isDialogOpen"
        :on-save="doSave"
        :on-cancel="doCancel"
        :is-action-separator-required="false"
        :max-width="600"
        :card-text-style="{ overflow: 'inherit', height: '160px' }">
        <template #title>
            <div class="silence-title font-weight-light color text-deep-ocean pb-7">
                {{ title }}
            </div>
        </template>
        <template #body>
            <div class="color text-navigation">
                <v-row>
                    <v-col
                        :cols="selectedService ? 5 : 4"
                        class="mt-2 pr-0">
                        {{ description }}
                    </v-col>
                    <v-col
                        cols="6"
                        class="ml-n6 pl-0">
                        <v-select
                            v-model="selectedInterval"
                            :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list' }"
                            outlined
                            single-line
                            item-text="name"
                            item-value="id"
                            dense
                            attach
                            height="36"
                            width="200"
                            required
                            hide-details
                            :items="intervals">
                            <template #item="{ item }">
                                {{ item.name }}
                            </template>
                            <template #selection="{ item }">
                                <span class="color text-navigation text-body-2">{{ item.name }}</span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col />
                </v-row>
                <v-row
                    v-if="isCustomPeriodSelected"
                    class="px-4">
                    <v-col cols="6">
                        <DateTimePicker
                            :datetime="fromDateTime"
                            :label="$t('from')"
                            @input="handleFromChange" />
                    </v-col>
                    <v-col cols="6">
                        <DateTimePicker
                            :datetime="toDateTime"
                            :base-date-time="fromDateTime"
                            :label="$t('to')"
                            @input="handleToChange" />
                    </v-col>
                </v-row>
                <div class="pt-4">
                    {{ $t('silencing.youCanAlso') }}
                </div>
            </div>
        </template>
        <template #actions="{ cancel, save }">
            <v-spacer />
            <v-btn
                color="primary"
                depressed
                rounded
                outlined
                class="cancel px-4"
                @click="cancel">
                <span class="text-none">{{ $t('cancel') }} </span>
            </v-btn>
            <v-btn
                color="primary px-4"
                depressed
                rounded
                :disabled="isSilenceDisabled"
                @click="save">
                <span class="text-none"> {{ $t('silencing.silence') }}</span>
            </v-btn>
        </template>
    </base-dialog>
</template>
<script>
    import DateTimePicker from 'components/common/DateTimePicker.vue'
    import { mapActions } from 'vuex'
    export default {
        name: 'SilenceAlertsModal',
        components: {
            DateTimePicker,
        },
        props: {
            selectedService: {
                type: Object,
                default: () => {},
            },
        },
        data() {
            return {
                isDialogOpen: false,
                intervals: [
                    { name: `30 ${this.$t('mins')}`, id: 0, mins: 30, },
                    { name: `1 ${this.$tc('hour')}`, id: 1, mins: 60, },
                    { name: `3 ${this.$tc('hour', 2)}`, id: 2, mins: 180, },
                    { name: `8 ${this.$tc('hour', 2)}`, id: 3, mins: 480, },
                    { name: this.$t('customTimePeriod'), id: 4, }],
                selectedInterval: 0,
                dateRange: { from: 0, to: 0, },
                fromDateTime: this.$moment(),
                toDateTime: this.$moment().add(1, 'days'),
            }
        },
        computed: {
            title() {
                return this.isServiceSelected ? this.$t('silencing.allServiceAlerts') : this.$t('silencing.allAlerts')
            },
            description() {
                return this.title + ' ' + this.$t('for')
            },
            isCustomPeriodSelected() {
                return this.selectedInterval == 4
            },
            isSilenceDisabled() {
                return this.isCustomPeriodSelected && (!this.fromDateTime || !this.toDateTime)
            },
            isServiceSelected() {
                return !this.$typy(this.selectedService).isEmptyObject
            },
        },
        methods: {
            ...mapActions(['createSilence']),
            open() {
                this.isDialogOpen = true
            },
            doCancel() {
                this.isDialogOpen = false
            },
            getInterval(isStart) {
                if (isStart) {
                    return this.isCustomPeriodSelected ?
                        this.$moment(this.fromDateTime).utc().format() :
                        this.$moment().utc().format()
                } else {
                    return this.isCustomPeriodSelected ?
                        this.$moment(this.toDateTime).utc().format() :
                        this.$moment().add(this.intervals[this.selectedInterval].mins, 'minutes').utc().format()
                }
            },
            createSilenceBody() {
                let body = {
                    'context': this.$config.allTopologiesTag,
                    'enabled': true,
                    'start_time': this.getInterval(true),
                    'end_time': this.getInterval(false),
                }
                if (this.isServiceSelected) {
                    body['context'] = `${this.selectedService.name}/all`
                }
                return body
            },
            async doSave() {
                const silenceBody = this.createSilenceBody()
                await this.createSilence(silenceBody)
                this.isDialogOpen = false
            },
            handleFromChange(fromDateTime) {
                this.fromDateTime = fromDateTime
                if (this.toDateTime) {
                    if (this.$moment(this.toDateTime).valueOf() < this.$moment(fromDateTime).valueOf())
                        this.toDateTime = ''
                }
            },
            handleToChange(toDateTime) {
                this.toDateTime = toDateTime
            },
        },
    }
</script>
