import to from 'await-to-js'
import { t } from 'typy'
import {
    getInstancesCosts,
    getStorageCost,
    getIOPSCost,
    getBillingUsageDetails,
    getBillingRecord,
    getPaymentProfile,
    getCountries,
    getStatesForCountry,
    createPaymentProfile,
    getSpendingForAllServices,
    getInvoices,
    getThroughputCost,
    getEndpointCost
} from 'services/billings'
import launchService from 'config/launchService'
import { isProviderAWS, isProviderGCP } from 'utils/service'
import { isDevEnv, isTestEnv } from 'helpers'
export default {
    state: {
        instanceCosts: [],
        instanceCostsErrors: false,
        storageCosts: {},
        storageCostsErrors: false,
        iopsCosts: {},
        iopsCostsErrors: false,
        billingUsageDetails: [],
        billingUsageDetailsErrors: false,
        isFetchingBillingUsage: false,
        isPaymentDialogOpen: false,
        isPhoneVerificationDialogOpen: false,
        billingRecord: {},
        billingRecordErrors: false,
        paymentProfile: {},
        paymentProfileErrors: false,
        billingCountries: [],
        billingCountriesErrors: false,
        billingStates: [],
        billingStatesErrors: false,
        isFetchingBillingRecord: false,
        isFetchingPaymentDetail: false,
        servicesSpending: [],
        servicesSpendingErrors: false,
        updateProfileSuccess: false,
        invoices: [],
        invoicesError: false,
        historicalUsage: [],
        gp3IopsCosts: {},
        throughputCosts: {},
        endpointPricing: [],
        endpointPricingErrors: false,
        gp3StorageCosts: {},
    },
    mutations: {
        setInstanceCosts(state, payload) {
            state.instanceCosts = payload
        },
        setInstanceCostsErrors(state, payload) {
            state.instanceCostsErrors = payload
        },
        setStorageCosts(state, payload) {
            state.storageCosts = payload
        },
        setStorageCostsErrors(state, payload) {
            state.storageCostsErrors = payload
        },
        setIOPSCosts(state, payload) {
            state.iopsCosts = payload
        },
        setIOPSCostsErrors(state, payload) {
            state.iopsCostsErrors = payload
        },
        setBillingUsageErrors(state, payload) {
            state.billingUsageDetailsErrors = payload
        },
        setFetchingUsageFlag(state, payload) {
            state.isFetchingBillingUsage = payload
        },
        setPaymentDialog(state, payload) {
            state.isPaymentDialogOpen = payload
        },
        setPhoneVerificationDialog(state, payload) {
            state.isPhoneVerificationDialogOpen = payload
        },
        setBillingUsage(state, payload) {
            state.billingUsageDetails = payload
        },
        setBillingRecord(state, payload) {
            state.billingRecord = payload
        },
        setBillingRecordErrors(state, payload) {
            state.billingRecordErrors = payload
        },
        setPaymentProfile(state, payload) {
            state.paymentProfile = payload
        },
        setPaymentProfileErrors(state, payload) {
            state.paymentProfileErrors = payload
        },
        setCountries(state, payload) {
            state.billingCountries = payload
        },
        setCountriesErrors(state, payload) {
            state.billingCountriesErrors = payload
        },
        setStates(state, payload) {
            state.billingStates = payload
        },
        setStatesErrors(state, payload) {
            state.billingStatesErrors = payload
        },
        setFetchingBillingRecord(state, payload) {
            state.isFetchingBillingRecord = payload
        },
        setFetchingPaymentDetail(state, payload) {
            state.isFetchingPaymentDetail = payload
        },
        setSpendingDetail(state, payload) {
            state.servicesSpending = payload
        },
        setSpendingErrors(state, payload) {
            state.servicesSpendingErrors = payload
        },
        setPaymentProfileSuccess(state, payload) {
            state.updateProfileSuccess = payload
        },
        setInvoices(state, payload) {
            state.invoices = payload
        },
        setInvoicesErrors(state, payload) {
            state.invoicesError = payload
        },
        setHistoricalUsage(state, payload) {
            state.historicalUsage = payload
        },
        setGp3IOPSCosts(state, payload) {
            state.gp3IopsCosts = payload
        },
        setThroughputCosts(state, payload) {
            state.throughputCosts = payload
        },
        setThroughputCostsErrors(state, payload) {
            state.throughputCostsErrors = payload
        },
        setEndpointPricing(state, payload) {
            state.endpointPricing = payload
        },
        setEndpointPricingErrors(state, payload) {
            state.endpointPricingError = payload
        },
        setGp3StorageCosts(state, payload) {
            state.gp3StorageCosts = payload
        },
    },
    actions: {
        async fetchInstancesCost({ commit, }, params) {
            const [error, response] = await to(getInstancesCosts(params))

            if (!error) {
                commit('setInstanceCosts', response)
                commit('setInstanceCostsErrors', false)
            } else {
                commit('setInstanceCostsErrors', error.message)
            }
        },
        async fetchStorageCost({ commit, }, params) {
            const [error, response] = await to(getStorageCost(params))

            if (!error) {
                const { AWS, GCP, } = launchService.cloudProvider
                switch (params.provider) {
                case AWS:
                    commit('setStorageCosts', response.find(res => launchService.iops.io1 == res.name))
                    commit('setGp3StorageCosts', response.find(res => res.name == launchService.iops.gp3))
                    break
                case GCP:
                    const storageType = isDevEnv() || isTestEnv() ? launchService.GCP_STORAGE_TYPES.pdStandard : launchService.GCP_STORAGE_TYPES.zonalSsdPd
                    commit('setStorageCosts', response.find(res => storageType == res.name))
                    break
                }
                commit('setStorageCostsErrors', false)
            } else {
                commit('setStorageCostsErrors', error.message)
            }
        },
        async fetchIOPSCost({ commit, }, params) {
            const [error, response] = await to(getIOPSCost(params))

            if (!error) {
                commit('setIOPSCosts', response.find(res => res.name == launchService.iops.io1))
                commit('setGp3IOPSCosts', response.find(res => res.name == launchService.iops.gp3))
                commit('setIOPSCostsErrors', false)
            } else {
                commit('setIOPSCostsErrors', error.message)
            }
        },
        async fetchBillingUsage({ commit, }, params) {
            commit('setFetchingUsageFlag', true)
            const { orgId, startDate, isHistorical = false, } = params
            const [error, response] = await to(
                getBillingUsageDetails({ orgId, startDate, })
            )
            if (response) {
                const billingUsage = Object.values(response)
                const data = billingUsage.length ? billingUsage[0] : []
                if (!isHistorical) {
                    commit('setBillingUsage', data)
                } else {
                    commit('setHistoricalUsage', data)
                }
                commit('setBillingUsageErrors', false)
            } else {
                commit('setBillingUsageErrors', error.message)
            }
            commit('setFetchingUsageFlag', false)
        },
        togglePaymentDialog({ commit, }, isOpen) {
            commit('setPaymentDialog', isOpen)
        },
        togglePhoneVerification({ commit, }, isOpen) {
            commit('setPhoneVerificationDialog', isOpen)
        },
        async fetchBillingRecord({ commit, }, noCache) {
            commit('setFetchingBillingRecord', true)
            const [error, response] = await to(getBillingRecord(noCache))
            if (!error) {
                commit('setBillingRecord', response)
                commit('setBillingRecordErrors', false)
            } else {
                commit('setBillingRecordErrors', error.message)
            }
            commit('setFetchingBillingRecord', false)
        },
        async fetchPaymentProfile({ commit, }) {
            commit('setFetchingPaymentDetail', true)
            const [error, response] = await to(getPaymentProfile())
            if (!error) {
                commit('setPaymentProfile', response.paymentProfile)
                commit('setPaymentProfileErrors', false)
            } else {
                commit('setPaymentProfileErrors', error.message)
            }
            commit('setFetchingPaymentDetail', false)
        },
        async fetchCountries({ commit, }) {
            const [error, response] = await to(getCountries())
            if (!error) {
                commit('setCountries', response)
                commit('setCountriesErrors', false)
            } else {
                commit('setCountriesErrors', error.message)
            }
        },
        async fetchStatesForCountry({ commit, }, params) {
            const [error, response] = await to(getStatesForCountry(params))
            if (!error) {
                commit('setStates', response)
                commit('setStatesErrors', false)
            } else {
                commit('setStatesErrors', error.message)
            }
        },
        async registerPaymentProfile({ commit, }, params) {
            const [error, response] = await to(createPaymentProfile(params))
            if (!error) {
                commit('setPaymentProfileSuccess', response)
            } else {
                commit('setPaymentProfileSuccess', false)
            }
        },
        async fetchSpendingDetailsForAllServices({ commit, }) {
            const [error, response] = await to(getSpendingForAllServices())

            if (!error || !t(response).isArray) {
                commit('setSpendingDetail', response)
                commit('setSpendingErrors', false)
            } else {
                commit('setSpendingErrors', error.message)
            }
        },
        async fetchInvoices({ commit, }) {
            const [error, response] = await to(getInvoices())

            if (!error || !t(response).isArray) {
                commit('setInvoices', response.invoices)
                commit('setInvoicesErrors', false)
            } else {
                commit('setInvoicesErrors', error.message)
            }
        },
        async fetchThroughputCost({ commit, }, params) {
            params['volume_type'] = launchService.iops.gp3
            const [error, response] = await to(getThroughputCost(params))
            if (!error) {
                commit('setThroughputCosts', response.find(res => res.name == launchService.iops.gp3))
                commit('setThroughputCostsErrors', false)
            } else {
                commit('setThroughputCostsErrors', error.message)
            }
        },
        async fetchEndpointPricing({ commit, }) {
            const [error, response] = await to(getEndpointCost())

            if (!error || !t(response).isArray) {
                // for now just get single endpoint charges per
                // cloud provider as topology does not impact
                // secondary endpoint pricing in any way
                // API is still WIP, so handling it in UI
                // this business logic should go away once API
                // changes are streamlined as required
                const awsPrice = response.find((priceObj) =>
                    isProviderAWS(priceObj.provider)
                )
                const gcpPrice = response.find((priceObj) =>
                    isProviderGCP(priceObj.provider)
                )

                let endpointPricing = []

                if (awsPrice) endpointPricing.push(awsPrice)
                if (gcpPrice) endpointPricing.push(gcpPrice)

                commit('setEndpointPricing', endpointPricing)
                commit('setEndpointPricingErrors', false)
            } else {
                commit('setEndpointPricingErrors', error.message)
            }
        },
    },
    getters: {
        instanceCosts: (state) => state.instanceCosts,
        instanceCostsErrors: (state) => state.instanceCostsErrors,
        storageCosts: (state) => state.storageCosts,
        storageCostsErrors: (state) => state.storageCostsErrors,
        iopsCosts: (state) => state.iopsCosts,
        iopsCostsErrors: (state) => state.iopsCostsErrors,
        billingUsageDetails: (state) => state.billingUsageDetails,
        billingUsageDetailsErrors: (state) => state.billingUsageDetailsErrors,
        isFetchingBillingUsage: (state) => state.isFetchingBillingUsage,
        isPaymentDialogOpen: (state) => state.isPaymentDialogOpen,
        isPhoneVerificationDialogOpen: (state) =>
            state.isPhoneVerificationDialogOpen,
        billingRecord: (state) => state.billingRecord,
        billingRecordErrors: (state) => state.billingRecordErrors,
        paymentProfile: (state) => state.paymentProfile,
        paymentProfileErrors: (state) => state.paymentProfileErrors,
        billingCountries: (state) => state.billingCountries,
        billingCountriesErrors: (state) => state.billingCountriesErrors,
        billingStates: (state) => state.billingStates,
        billingStatesErrors: (state) => state.billingStatesErrors,
        isFetchingBillingRecord: (state) => state.isFetchingBillingRecord,
        isFetchingPaymentDetail: (state) => state.isFetchingPaymentDetail,
        servicesSpending: (state) => state.servicesSpending,
        servicesSpendingErrors: (state) => state.servicesSpendingErrors,
        updateProfileSuccess: (state) => state.updateProfileSuccess,
        invoices: (state) => state.invoices,
        invoicesErrors: (state) => state.invoicesErrors,
        historicalUsage: (state) => state.historicalUsage,
        billingSubscriptions: (state) =>
            state.billingRecord.subscriptions || [],
        monitoringSubscription: (state, getters) =>
            getters.billingSubscriptions.find(
                ({ product, }) => product === 'monitor'
            ),
        gp3IopsCosts: (state) => state.gp3IopsCosts,
        throughputCosts: (state) => state.throughputCosts,
        throughputCostsErrors: (state) => state.throughputCostsErrors,
        endpointPricing: (state) => state.endpointPricing,
        endpointPricingError: (state) => state.endpointPricingError,
        gp3StorageCosts: (state) => state.gp3StorageCosts,
    },
}
