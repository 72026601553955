<template>
    <div>
        <div class="my-2">
            <span class="font-weight-bold">{{ $t('ros.stepX', [stepNumber]) }}</span>: {{ title }}
        </div>
        <div class="mt-2 text-body-2">
            <slot name="content"></slot>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'RosStepContent',
        props: {
            stepNumber: {
                required: true,
                type: Number,
            },
            title: {
                required: true,
                type: String,
            },
        },
    }
</script>
