<style lang="scss" scoped>
    path {
        stroke: currentColor;
    }
</style>

<template>
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15 5L8 12.2222L1 5.00559"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round" />
    </svg>
</template>

<script>
    export default {}
</script>
