const cidrRegex = require('cidr-regex')

export function validateIpAddress (address) {
    if(!address) return true
    return cidrRegex({exact: true,}).test(address)
}

export function isDuplicateIpAddress(ip, list) {
    const count = list.filter(item => item.ip === ip).length
    return count > 1
}
