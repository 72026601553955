<style lang="scss" scoped>
::v-deep .config-input .v-input__slot {
    min-height: 32px !important;
    display: flex !important;
    align-items: center !important;
}

.service-header {
    line-height: 28px;
}

.no-service {
    color: $grayed-out;
}
</style>
<template>
    <div>
        <div v-if="configInEditMode">
            <configure-config
                :selected-config="selectedConfig"
                @on-save="getConfigDetails" />
        </div>
        <div v-else>
            <div class=" text-caption pb-4">
                <div class="text-uppercase service-header">
                    {{ $t('configManager.servicesWithThis') }}
                </div>
                <div v-if="doServicesExist">
                    <v-chip
                        v-for="(service, index) in configServices"
                        :key="index"
                        class="ma-2"
                        label
                        outlined
                        @click="openRemoveDialog(service)">
                        <v-icon
                            left
                            color="green"
                            size="14">
                            mdi-check-circle-outline
                        </v-icon>
                        <span
                            v-if="service"
                            class="text-body-2">{{ service.name }}</span>
                        <span
                            v-if="!config.public"
                            class="color text-links text-caption ml-10"
                            color="primary">{{
                                $t('remove')
                            }}</span>
                    </v-chip>
                </div>
                <div
                    v-else
                    class="no-service">
                    {{ $t('configManager.noServiceUsingThis') }}
                </div>
            </div>
            <config-data-table
                :in-edit-mode="false"
                :configurations="configDetails"
                :topology-name="topologyName" />

            <config-actions
                v-if="selectedConfig"
                ref="removeModal"
                :dialog-type="$config.DIALOG_TYPE.REMOVE"
                :selected-service="selectedService"
                :on-action="removeService" />
        </div>
    </div>
</template>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import ConfigDataTable from 'components/configurationManager/ConfigDataTable.vue'
    import ConfigActionsModal from 'components/alerts/ConfigActionsModal.vue'
    import ConfigureConfigVue from 'components/configurationManager/ConfigureConfig.vue'
    import { fixDefaultValues } from 'utils/configManager'
    export default {
        name: 'ConfigurationDetails',
        components: {
            'config-data-table': ConfigDataTable,
            'config-actions': ConfigActionsModal,
            'configure-config': ConfigureConfigVue,
        },
        data() {
            return {
                configDetails: [],
                doServicesExist: false,
                configServices: [],
                selectedService: null,
                inEditMode: false,
                configId: '',
                topologyName: '',
            }
        },
        computed: {
            ...mapGetters(['selectedConfig', 'services', 'configInEditMode', 'configsPerProduct', 'removeConfigSuccess', 'configs', 'selectedValues', 'topologyConfigs', 'allTopologies']),
        },
        watch: {
            selectedConfig: {
                handler() {
                    this.setConfigServices()
                },
            },
            services: {
                async handler() {
                    if (this.config && !this.config.public && !this.configInEditMode) await this.getSelectedConfig({ configId: this.config.id, })
                    this.setConfigServices()
                },
            },
        },
        methods: {
            ...mapMutations(['setSelectedConfig']),
            ...mapActions(['getSelectedConfig', 'clearSelectedConfig', 'getConfigsPerProduct', 'removeConfig', 'getConfigsList', 'getSelectedValues', 'getTopologyConfigs', 'getAllTopologies', 'setSelectedConfigDetailsList']),
            openRemoveDialog(service) {
                if (!this.config.public) {
                    // Only custom config be removed from a service
                    this.selectedService = service
                    this.$refs.removeModal.open()
                }
            },
            async removeService() {
                await this.removeConfig({ serviceId: this.selectedService.id, })
                if (this.removeConfigSuccess) {
                    this.$store.commit('showMessage', { text: this.$t('success.removeConfig'), type: this.$config.messageType.SUCCESS, })
                    await this.getSelectedConfig({ configId: this.config.id, })
                }
            },
            async getConfigDetails() {
                this.configDetails = []
                if (this.selectedConfig) {
                    this.topologyName = this.allTopologies.find(topology => topology.id === this.selectedConfig.topology_id).name
                    await this.getTopologyConfigs({ topologyName: this.topologyName, })
                    await this.getSelectedValues({ configId: this.config.id, })
                    this.selectedValues.forEach(valueObj => {
                        let config = this.topologyConfigs.find(config => config.id === valueObj.config_key_id)
                        if (config) config['selected_values'] = valueObj.selected_values
                    })
                    this.configDetails = this.$lodash.cloneDeep(this.topologyConfigs)
                    fixDefaultValues(this.configDetails)
                    this.setSelectedConfigDetailsList({ configDetails: this.configDetails, })
                }
            },
            async setConfigServices() {
                this.configServices = []
                if (this.config && this.config.public) {
                    const topologyNames = this.allTopologies
                        .filter(topology => topology.id === this.config.topology_id)
                        .map(topology => topology.name)

                    this.services.forEach(service => {
                        if (!service.config_id && topologyNames.includes(service.topology)) {
                            this.configServices.push(service)
                            this.doServicesExist = true
                        }
                    })
                } else {
                    this.doServicesExist = this.selectedConfig && this.selectedConfig.services
                    if (this.doServicesExist) {
                        this.selectedConfig.services.forEach(id => {
                            this.configServices.push(this.services.find(service => service.id === id))
                        })
                    }
                }
            },
        },
        async created() {
            await this.getAllTopologies()
            await this.getConfigsList()
            const configName = this.$route.params.configName
            this.config = this.configs.find(config => config.name === configName)
            if (this.config.public) {
                this.setSelectedConfig(this.config)
                this.topologyName = this.config.id.split('@default-')[1]
                await this.getTopologyConfigs({ topologyName: this.topologyName, })
                this.configDetails = this.$lodash.cloneDeep(this.topologyConfigs)
                fixDefaultValues(this.configDetails)
                this.setConfigServices()
            } else {
                await this.getSelectedConfig({ configId: this.config.id, })
                await this.getConfigDetails()
            }
        },
        destroyed() {
            this.clearSelectedConfig()
            this.$store.commit('toggleEditMode', false)
        },
    }
</script>
