<style lang="scss" scoped>
.current-storage {
    color: $info;
}

.menu-label>div:hover {
    font-weight: bold
}
</style>

<template>
    <div>
        <v-menu
            v-model="isMenuOpen"
            :disabled="isDisabled"
            rounded="lg"
            transition="slide-y-transition"
            offset-y>
            <template #activator="{ on, attrs }">
                <div
                    v-bind="attrs"
                    v-on="on">
                    <v-btn
                        color="primary"
                        :disabled="isDisabled"
                        text
                        rounded>
                        <v-icon>$vuetify.icons.gear</v-icon>
                        <span class="pl-2 text-subtitle-1">{{ $t('manage') }} </span>
                    </v-btn>
                </div>
            </template>
            <v-list
                :data-auto="`service-menu-list-${service.name}`"
                class="menu-label">
                <v-list-item
                    v-if="isXpandTopology"
                    :data-auto="`service-menu-list-${service.name}.xpand-rep`"
                    :disabled="!isServiceReady"
                    @click="openGlobalXpandModal">
                    <v-list-item-title>{{ $t('globalXpand.title') }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                    v-if="canScaleNodeInOut"
                    :data-auto="`service-menu-list-${service.name}.scale-in-out`"
                    :disabled="isScalingNodesInOutDisabled"
                    @click="openNodesScaleModal">
                    <v-list-item-title>{{ $t("scaleNodesInOut") }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                    v-if="canScaleNodeUpDown"
                    :data-auto="`service-menu-list-${service.name}.scale-up-down`"
                    :disabled="isScalingNodesUpDownDisabled"
                    @click="openScaleNodeUpModal">
                    <v-list-item-title>{{ $t("scaleNodeUp") }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                    v-if="canScaleStorage"
                    :data-auto="`service-menu-list-${service.name}.scale-storage`"
                    :disabled="isScalingStorageDisabled"
                    @click="openStorageScaleModal">
                    <v-list-item-title>{{ $t("scaleStorage") }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                    v-if="canStartStop"
                    :data-auto="`service-menu-list-${service.name}.start-stop`"
                    :disabled="isStartStopDisabled"
                    @click="openStartStopModal">
                    <v-list-item-title>{{ labelForStartStop }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                    v-if="canOpenSecurityAccess"
                    :data-auto="`service-menu-list-${service.name}.whitelist-option`"
                    :disabled="isSecurityAccessDisabled"
                    @click="$emit('openSecurityAccess')">
                    <v-list-item-title>{{ labelForAllowlist }}</v-list-item-title>
                </v-list-item>
                <div v-if="canConnect">
                    <v-list-item
                        v-if="canManagePrivateConnect"
                        :data-auto="`service-menu-list-${service.name}.manage-psc-option`"
                        :disabled="isPrivateConnectDisabled"
                        @click="openManagePrivateConnect">
                        <v-list-item-title>
                            {{
                                $t("privateConnect.manageMenuPrivateConnect", { connectionName: privateConnectType })
                            }}
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                        v-else
                        :data-auto="`service-menu-list-${service.name}.setup-psc-option`"
                        :disabled="isPrivateConnectDisabled"
                        @click="openSetupPrivateConnect">
                        <v-list-item-title>
                            {{
                                $t("privateConnect.setup", { connectionName: privateConnectType })
                            }}
                        </v-list-item-title>
                    </v-list-item>
                </div>
                <v-list-item
                    v-if="canScaleAutonomous"
                    :data-auto="`service-menu-list-${service.name}.autonomous`"
                    :disabled="isAutonomousDisabled"
                    @click="openAutonomousModal">
                    <v-list-item-title>{{ $t("autonomous") }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                    v-if="canApplyConfiguration"
                    :data-auto="`service-menu-list-${service.name}.db-config`"
                    :disabled="isApplyConfigDisabled"
                    @click="openApply">
                    <v-list-item-title>{{ $t('configManager.applyDatabaseConfig') }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                    v-if="isSilencingEnabled"
                    :data-auto="`service-menu-list-${service.name}.silence-alerts`"
                    :disabled="isSilencingDisabled"
                    @click="openSilence">
                    <v-list-item-title>{{ $t('silencing.alerts') }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                    v-if="canDeleteService"
                    :data-auto="`service-menu-list-${service.name}.delete`"
                    class="error--text"
                    @click="openDelModal()">
                    <v-list-item-title>{{ $t("deleteService") }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <alert-delete-modal
            ref="delModal"
            :title="deleteModalTitle()"
            :item-key-name="deleteModalTitle()"
            :on-delete="onDelete"
            :width="600"
            :is-save-disabled="disableDelete"
            :on-cancel="() => $emit('on-cancel-delete')">
            <template #content>
                <div>
                    {{ $t('sureToDelete') }}: <strong>{{ service.name }}</strong>?
                    <br /><span class="error--text">{{ $t('actionNotReversible') }}</span>
                    <br />{{ $t('typeDelete') }}
                    <v-text-field
                        v-model="deleteText"
                        class="pt-5"
                        outlined
                        dense
                        required
                        :placeholder="$t('typeDeleteHere')"
                        @input="checkForDelete" />
                </div>
            </template>
        </alert-delete-modal>
        <config-actions
            ref="applyModal"
            :dialog-type="$config.DIALOG_TYPE.APPLY"
            :selected-service="service"
            :width="990" />
        <global-xpand-modal
            ref="editSetupModal"
            :dialog-type="hasReplications ? $config.DIALOG_TYPE.EDIT : $config.DIALOG_TYPE.SETUP"
            :selected-service="service" />
        <SilenceAlertsModal
            ref="silenceAlertsModal"
            :selected-service="service" />
    </div>
</template>

<script>
    import AlertDeleteModalVue from 'components/alerts/AlertDeleteModal.vue'
    import autonomousModal from 'mixins/autonomousModal'
    import ConfigActionsModal from 'components/alerts/ConfigActionsModal.vue'
    import i18n from 'plugins/i18n'
    import { mapGetters, mapActions } from 'vuex'
    import GlobalXpandModalVue from 'components/service/GlobalXpandModal.vue'
    import { getReplicas } from 'utils/replication'
    import {
        isStandAloneTopology,
        isXpand,
        isXgres,
        isServerlessAnalytics,
        getNodeTypeForPricingAPI,
        doPrivateConnectHaveProjects,
        privateConnectLabel,
        hasMultipleEndpoints,
        isPrimaryEndpointPrivateServiceConnect,
        getIpAllowList
    } from 'utils/service'
    import SilenceAlertsModal from 'components/alerts/SilenceAlertsModal'
    import { findActiveSilence } from 'utils/alert'

    const ServiceManageMenu = {
        name: 'ServiceManageMenu',
        components: {
            'alert-delete-modal': AlertDeleteModalVue,
            'config-actions': ConfigActionsModal,
            'global-xpand-modal': GlobalXpandModalVue,
            SilenceAlertsModal,
        },
        mixins: [autonomousModal],
        props: {
            serviceId: {
                type: String,
                required: true,
            },
            serviceStatus: {
                type: String,
                required: true,
            },
            isRemoteService: {
                type: Boolean,
                default: false,
            },
            isDisabled: {
                type: Boolean,
                default: false,
            },
            autonomousPolicies: {
                type: Array,
                default: () => [],
            },
        },
        data() {
            return {
                isMenuOpen: false,
                disableDelete: true,
                deleteText: '',
            }
        },
        computed: {
            ...mapGetters(['getServiceById', 'deleteServiceSuccess', 'services', 'silences']),
            isServiceReady() {
                return this.serviceStatus === this.$config.serviceStatus.READY
            },
            isServiceStopped() {
                return this.serviceStatus === this.$config.serviceStatus.STOPPED
            },
            isStartStopDisabled() {
                const { READY, STOPPED, RETIRED, } = this.$config.serviceStatus
                return ![READY, STOPPED, RETIRED].includes(this.serviceStatus)
            },
            labelForStartStop() {
                return this.isServiceReady
                    ? this.$t('stopService')
                    : this.$t('startService')
            },
            service() {
                return this.getServiceById(this.serviceId)
            },
            isScalingStorageDisabled() {
                if (this.autonomousPolicies.length) {
                    const { STORAGE_SCALE_GROUP, } = this.$config
                    const storagePolicy = this.autonomousPolicies.find(policy => policy.service_id === this.serviceId && STORAGE_SCALE_GROUP === policy.group)
                    if (storagePolicy) return true
                }
                let shouldDisableScaling = false
                if (this.isServiceTypeAnalytics) {
                    if (this.$typy(this.service, 'storage_volume.volume_type').isDefined) {
                        const isGp2Analytics = this.service.storage_volume.volume_type === this.$config.iops.gp2
                        shouldDisableScaling = isGp2Analytics
                    } else {
                        // For GCP based analytical service disable
                        // scaling
                        shouldDisableScaling = true
                    }
                }

                return !this.isServiceReady && !this.isServiceStopped || shouldDisableScaling
            },
            isScalingNodesInOutDisabled() {
                // Disable scaling of node in case of single node
                // in case of service state other than ready
                if (this.autonomousPolicies.length) {
                    const { HORIZONTAL_SCALE_GROUP, } = this.$config
                    const horizontalPolicy = this.autonomousPolicies.find(policy => policy.service_id === this.serviceId && HORIZONTAL_SCALE_GROUP === policy.group)
                    if (horizontalPolicy) return true
                }
                if (this.isXpandTopology && this.isServiceStopped) {
                    return true
                }
                if (this.isServiceReady || this.isServiceStopped) {
                    return false
                }
                return true
            },
            canScaleNodeInOut() {
                const { service_type: serviceType, topology, } = this.service
                return serviceType === this.$config.TRANSACTIONAL &&
                    !isStandAloneTopology(topology) &&
                    !this.isServiceServerLess &&
                    !this.isRemoteService
            },
            isServiceTypeAnalytics() {
                return this.service.service_type === this.$config.ANALYTICAL
            },
            isScalingNodesUpDownDisabled() {
                if (this.autonomousPolicies.length) {
                    const { VERTICAL_SCALE_GROUP, } = this.$config
                    const verticalPolicy = this.autonomousPolicies.find(policy => policy.service_id === this.serviceId && VERTICAL_SCALE_GROUP === policy.group)
                    if (verticalPolicy) return true
                }
                if (this.isXpandTopology && this.isServiceStopped) {
                    return true
                }
                if (this.isServiceReady || this.isServiceStopped) {
                    return false
                }
                return true
            },
            canScaleNodeUpDown() {
                return !this.isServiceServerLess && !this.isRemoteService
            },
            canScaleStorage() {
                return !this.isRemoteService
            },
            isServiceServerLess() {
                return isServerlessAnalytics(this.service.topology)
            },
            canStartStop() {
                // TODO: Move this to DPS
                // Available for all topologies
                return true
            },
            canScaleAutonomous() {
                return !this.isServiceServerLess && !this.isRemoteService
            },
            canApplyConfiguration() {
                return !this.isServiceServerLess && !isXgres(this.service.topology) && !this.isRemoteService
            },
            isAutonomousDisabled() {
                // Enable autonomous feature in case service is not failed
                // or being deleted
                const { FAILED, PENDING_DELETE, } = this.$config.serviceStatus
                return [FAILED, PENDING_DELETE].includes(this.serviceStatus)
            },
            canOpenSecurityAccess() {
                if (this.isRemoteService) return false

                const { FAILED, PENDING_DELETE, } = this.$config.serviceStatus
                if (this.$unleash.isFeatureEnabled('enable-portal-dual-endpoints')) {
                    // Check if the service has opted for PrivateConnect and Ip Allowlist
                    const { endpoints, } = this.service
                    return hasMultipleEndpoints(endpoints) || !isPrimaryEndpointPrivateServiceConnect(endpoints)
                }
                return !this.hasPrivateConnectProjects && ![FAILED, PENDING_DELETE].includes(this.serviceStatus)
            },
            canConnect() {
                return !this.isServiceServerLess && !this.isRemoteService
            },
            isXpandTopology() {
                return isXpand(this.service.topology)
            },
            hasReplications() {
                return !!getReplicas(this.services, this.service).length
            },
            canManagePrivateConnect() {
                return this.hasPrivateConnectProjects
            },
            hasPrivateConnectProjects() {
                return doPrivateConnectHaveProjects(this.service.endpoints)
            },
            privateConnectType() {
                return privateConnectLabel(this.service.provider, this.$unleash.isFeatureEnabled('enable-portal-dual-endpoints'))
            },
            isPrivateConnectDisabled() {
                return !this.isServiceReady && !this.isServiceStopped
            },
            isSecurityAccessDisabled() {
                return !this.isServiceReady && !this.isServiceStopped
            },
            canDeleteService() {
                return !this.isRemoteService
            },
            isGP3Selected() {
                return this.service.storage_volume.volume_type === this.$config.iops.gp3
            },
            labelForAllowlist() {
                if (this.$unleash.isFeatureEnabled('enable-portal-dual-endpoints')) {
                    const ipAllowList = getIpAllowList(this.service.endpoints)
                    return ipAllowList.length ? this.$t('dualEndpoints.manageAllowlist') : this.$t('dualEndpoints.queryEditorSetupAllowList')
                }
                return this.$t('securityAccess')
            },
            isApplyConfigDisabled() {
                return !this.isServiceReady && !this.isServiceStopped
            },
            isSilencingEnabled() {
                return this.$unleash.isFeatureEnabled('enable-portal-silence-alerts') && !this.isRemoteService
            },
            isSilencingDisabled() {
                return (!this.isServiceReady && !this.isServiceStopped) || !!findActiveSilence(this.silences, `${this.service.name}/all`)
            },
        },
        methods: {
            ...mapActions([
                'openStartStopDialog',
                'openScaleNodesDialog',
                'openScaleStorageDialog',
                'deleteService',
                'openScaleNodeUpDialog',
                'fetchIops',
                'fetchInstancesCost',
                'fetchStorageCost',
                'fetchIOPSCost',
                'getInstanceSizes',
                'setServiceDepsFetching',
                'openPrivateConnectModal',
                'fetchThroughputCost',
                'getSilences'
            ]),
            openStartStopModal() {
                this.openStartStopDialog({ popupState: true, serviceId: this.service.id, })
            },
            async onDelete() {
                await this.deleteService({ serviceId: this.serviceId, })
                if (this.deleteServiceSuccess) {
                    // Navigate back to Dashboard page if currently somewhere else
                    if (!this.$route.path.includes('dashboard')) {
                        this.$router.push({ path: '/dashboard', })
                    }
                }
            },
            openDelModal() {
                this.$refs.delModal.open()
            },
            deleteModalTitle() {
                return i18n.t('delete') + ' ' + this.service.name
            },
            openStorageScaleModal() {
                this.setServiceDepsFetching(true)
                let dependencies = []
                dependencies.push(this.fetchPricingDetails())
                dependencies.push(this.fetchStorageSizes(this.service.topology))
                if (this.service.provider === this.$config.cloudProvider.AWS) dependencies.push(this.fetchIops())
                Promise.allSettled(dependencies).then(() => {
                    this.setServiceDepsFetching(false)
                })
                this.openScaleStorageDialog({ popupState: true, serviceId: this.service.id, })
            },
            dataAutoPrefix(string) {
                return 'manage-menu' + string
            },
            openNodesScaleModal() {
                this.setServiceDepsFetching(true)
                let dependencies = []
                dependencies.push(this.fetchNodeSizes(this.service.topology))
                dependencies.push(this.fetchPricingDetails())
                Promise.allSettled(dependencies).then(() => {
                    this.setServiceDepsFetching(false)
                })
                this.openScaleNodesDialog({ popupState: true, serviceId: this.service.id, })
            },
            checkForDelete() {
                this.disableDelete = this.deleteText !== i18n.t('delete').toLowerCase()
            },
            openScaleNodeUpModal() {
                this.setServiceDepsFetching(true)
                let dependencies = []
                this.$lodash.isEmpty(this.instanceSizes) && dependencies.push(this.getInstanceSizes())
                dependencies.push(this.fetchPricingDetails())
                Promise.allSettled(dependencies).then(() => {
                    this.setServiceDepsFetching(false)
                })
                this.openScaleNodeUpDialog({ popupState: true, serviceId: this.service.id, })
            },
            async openAutonomousModal() {
                this.fetchAutoScaleDependencies()
                this.openAutonomousDialog({ popupState: true, serviceId: this.serviceId, })
            },
            fetchPricingDetails() {
                const { provider, topology, architecture, } = this.service
                // Billing api has blank arch for AMD type
                let arch = architecture
                // Architecture is not supported for GCP yet
                const isAWSProvider = provider === this.$config.cloudProvider.AWS

                return Promise.all([
                    this.fetchInstancesCost({
                        topology,
                        provider,
                        ...(isAWSProvider && { arch, }),
                        node: getNodeTypeForPricingAPI(topology),
                    }),
                    this.fetchStorageCost({
                        topology,
                        provider,
                    }),
                    provider === this.$config.cloudProvider.AWS &&
                        this.fetchIOPSCost({
                            topology,
                            provider,
                        }),
                    this.isGP3Selected &&
                        this.fetchThroughputCost({
                            topology,
                            provider,
                        })
                ])
            },
            openApply() {
                this.$refs.applyModal.open()
            },
            openGlobalXpandModal() {
                this.$refs.editSetupModal.open()
            },
            openManagePrivateConnect() {
                this.openPrivateConnectModal({ popupState: true, serviceId: this.service.id, })
            },
            openSetupPrivateConnect() {
                this.openPrivateConnectModal({ popupState: true, serviceId: this.service.id, })
            },
            openSilence() {
                this.$refs.silenceAlertsModal.open()
            },
        },
        created() {
            this.getSilences()
        },
    }

    export default ServiceManageMenu
</script>
