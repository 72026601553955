<style lang="scss" scoped>
    path {
        fill: currentColor;
    }
</style>

<template>
    <svg viewBox="0 0 21 23">
        <path
            d="M13.5617 19.1622C13.4641 20.7458 12.1488 22 10.5405 22C8.93227 22 7.61696 20.7458 7.5193 19.1622H2.97669C1.92964 19.1622 1.08105 18.3155 1.08105 17.2703C1.08105 16.2265 1.929 15.3784 2.97374 15.3784C3.18129 15.3784 3.35132 15.2082 3.35132 14.9993V10.4592C3.35132 7.25893 5.45847 4.48069 8.45943 3.57599V3.0814C8.45943 1.93249 9.39247 1 10.5405 1C11.6906 1 12.6216 1.93037 12.6216 3.0814V3.57603C15.6225 4.48085 17.7297 7.25962 17.7297 10.4592V14.9993C17.7297 15.2104 17.8978 15.3784 18.1073 15.3784C19.1503 15.3784 20 16.2263 20 17.2703C20 18.3142 19.1505 19.1622 18.1043 19.1622H13.5617ZM12.803 19.1622H8.27801C8.37415 20.3275 9.35039 21.2432 10.5405 21.2432C11.7306 21.2432 12.7069 20.3275 12.803 19.1622ZM8.93208 4.22956C6.11332 4.95471 4.10808 7.50625 4.10808 10.4592V14.9993C4.10808 15.6261 3.59934 16.1351 2.97374 16.1351C2.34699 16.1351 1.83781 16.6444 1.83781 17.2703C1.83781 17.8972 2.34721 18.4054 2.97669 18.4054H18.1043C18.7329 18.4054 19.2432 17.8959 19.2432 17.2703C19.2432 16.6446 18.7327 16.1351 18.1073 16.1351C17.4799 16.1351 16.9729 15.6284 16.9729 14.9993V10.4592C16.9729 7.50694 14.9676 4.95482 12.1489 4.22958L11.8648 4.15648V3.0814C11.8648 2.34843 11.2727 1.75676 10.5405 1.75676C9.81031 1.75676 9.21619 2.35053 9.21619 3.0814V4.15647L8.93208 4.22956Z"
            fill="white"
            stroke="white"
            stroke-width="0.5" />
    </svg>
</template>

<script>
    export default {}
</script>
