<style lang="scss" scoped>
.border-bottom {
    border-bottom: 1px solid $cornsilk;
}

.per-min-cost {
    color: $grayed-out
}
</style>
<template>
    <div v-if="hasNode">
        <v-row
            :data-auto="`nodeItem-${childIndex}`"
            class="border-bottom pt-2">
            <v-col
                class="pl-10 font-weight-bold pointer"
                cols="5"
                @click="toggleTab">
                <v-icon
                    v-if="hasChildren"
                    :class="['mr-2', { 'rotate--right': !isExpanded }]"
                    size="13">
                    $expand
                </v-icon>
                <span
                    :data-auto="`childItem-title-${childIndex}`"
                    :class="['node-title', { 'pl-6': !hasChildren }]">{{
                        node.title
                    }}</span>
            </v-col>
            <v-col cols="4" />
            <v-col
                :data-auto="`childItem-cost-${childIndex}`"
                class="font-weight-bold text-right pr-0">
                {{ $t('currency', [node.cost.toFixed(2)]) }}
            </v-col>
            <v-col cols="2" />
        </v-row>
        <div v-if="isExpanded">
            <v-row
                v-for="(childNode, index) in node.children"
                :key="index"
                :class="['border-bottom mb-0', { 'mt-0': index }, { 'mt-3': !index }]">
                <v-col
                    cols="5"
                    class="pl-16 font-weight-regular mt-0 child-title pt-2"
                    :data-auto="`lineItem-title-${index}`">
                    {{ childNode.title }}
                </v-col>
                <v-col
                    class="mt-0 pt-2"
                    cols="4">
                    <span :data-auto="`lineItem-runTime-${index}`">{{ childNode.runtime.toFixed(2) }}</span>
                    <span
                        :data-auto="`lineItem-perMinCost-${index}`"
                        class="ml-6 font-weight-thin per-min-cost">{{
                            $t('billings.atPerMin',[$t('currency', [childNode.perMinCost.toFixed(8)])])
                        }}</span>
                </v-col>
                <v-col
                    :data-auto="`lineItem-totalCost-${index}`"
                    class="text-right pr-0 pt-2 mt-0">
                    {{ $t('currency', [childNode.cost.toFixed(2)]) }}
                </v-col>
                <v-col cols="2" />
            </v-row>
        </div>
    </div>
</template>
<script>
    const BillingPanelChildNodes = {
        name: 'BillingPanelChildNodes',
        props: {
            node: {
                type: Object,
                required: true,
            },
            childIndex: {
                type: Number,
                required: true,
                default: 0,
            },
        },
        data() {
            return {
                isExpanded: false,
            }
        },
        computed: {
            hasNode() {
                return this.node && this.node.title
            },
            hasChildren() {
                const { children, } = this.node
                return children && children.length
            },
        },
        methods: {
            toggleTab() {
                this.isExpanded = !this.isExpanded
            },
        },
    }

    export default BillingPanelChildNodes
</script>
