<template>
    <svg viewBox="0 0 22 18">
        <path
            d="M13.9602 4.93099H14.6311L16.5433 3.01878L16.6371 2.20661C13.0781 -0.935069 7.64647 -0.595677 4.50479 2.96331C3.63188 3.95111 2.99931 5.12776 2.65332 6.40081C2.86594 6.31365 3.10232 6.29912 3.32418 6.36119L7.14861 5.72995C7.14861 5.72995 7.34274 5.40772 7.44442 5.42753C9.14534 3.5589 12.0084 3.341 13.9734 4.93099H13.9602Z"
            fill="#EA4335" />
        <path
            d="M19.2676 6.40081C18.8279 4.78177 17.9259 3.32648 16.6713 2.21454L13.9879 4.89798C15.121 5.82371 15.7667 7.21958 15.739 8.68279V9.15952C17.0583 9.15952 18.128 10.2292 18.128 11.5485C18.128 12.8677 17.0583 13.9374 15.739 13.9374H10.9611L10.4844 14.4208V17.2864L10.9611 17.7632H15.739C19.1699 17.7896 21.9735 15.0295 21.9999 11.5987C22.0158 9.51476 20.9897 7.56557 19.2676 6.40081Z"
            fill="#4285F4" />
        <path
            d="M6.17626 17.7328H10.9542V13.9084H6.17626C5.83555 13.9084 5.50012 13.8344 5.18978 13.6931L4.51892 13.9018L2.5935 15.814L2.42578 16.4848C3.5047 17.301 4.82265 17.7394 6.17626 17.7328Z"
            fill="#34A853" />
        <path
            d="M6.17651 5.3272C2.74429 5.34701 -0.019703 8.14534 0.000105812 11.5776C0.0119911 13.4937 0.906031 15.2977 2.42603 16.4651L5.19663 13.6945C3.99489 13.1517 3.46005 11.736 4.00282 10.5343C4.54558 9.33255 5.96125 8.79771 7.16299 9.34047C7.69254 9.5795 8.11645 10.0047 8.3568 10.5343L11.1274 7.76369C9.94943 6.22124 8.11645 5.31928 6.17651 5.3272Z"
            fill="#FBBC05" />
    </svg>
</template>

<script>
    export default {}
</script>
