<style lang="scss" scoped>
.tag-label {
    font-size: 0.675rem;
}
</style>
<template>
    <div class="alert-tag-list">
        <v-row
            no-gutters
            class="mb-3">
            <v-col cols="6">
                <div class="pa-0 mr-2">
                    <label class="d-block color text-small-text tag-label label-required">
                        {{ $t('context') }}
                    </label>
                    <system-tag-input
                        ref="sysTags"
                        :labels="sysTags"
                        :is-notif-tags="isNotifTags"
                        :is-required="requiredTagTypes.includes('sysTags')"
                        @on-sys-tags-change="onContextChange" />
                </div>
            </v-col>
            <v-col cols="6">
                <div class="pa-0">
                    <label class="d-block color text-small-text tag-label">
                        {{ $t('customTag') }}
                    </label>
                    <custom-tag-input
                        :selected-tags="Object.values(_tags)"
                        @add-custom-tag="addCustomTag" />
                </div>
            </v-col>
        </v-row>

        <tag-listing
            :tags="_tags"
            small-tags
            :is-tag-without-label="isTagWithoutLabel"
            @on-tag-del="handleTagDel" />
    </div>
</template>
<script>
    import CustomTagInput from './CustomTagInput'
    import SystemTagInput from './SystemTagInput.vue'
    import { createCustomTag, createSystemTag } from 'utils/alert'
    export default {
        name: 'AlertTagsConfigurator',
        components: {
            'custom-tag-input': CustomTagInput,
            'system-tag-input': SystemTagInput,
        },
        props: {
            labels: { type: Object, default: () => { }, },
            context: { type: String, default: '', },
            isNotifTags: { type: Boolean, default: false, },
            requiredTagTypes: {
                type: Array,
                default: () => ([]),
                validator: (value) => Array.isArray(value) && value.includes('sysTags'),
            },
            selectedTags: { type: Array, default: () => [], },
            isTagWithoutLabel: { type: Boolean, default: false, },
        },
        computed: {
            _tags() {
                if (this.isTagWithoutLabel) return this.selectedTags
                const alertTagKeys = [this.$config.customTagPrefix, 'context']
                let tags = this.$help.objects.pickPropertyStartsWith({ object: this.labels, keys: alertTagKeys, })
                tags.context = this.context
                if (this.$typy(tags, 'context').isDefined) tags.context = this.$help.alerts.unescapeTags(tags.context)
                return this.$help.alerts.cortexLabelsToTags(tags)
            },
            sysTags() {
                return { context: this.isTagWithoutLabel ? this.getSystemTagAsContext : this.context, }
            },
            getSystemTagAsContext() {
                return this.selectedTags.filter(tag => tag.type === this.$config.TAG_BODY.TYPE.SYSTEM)
                    .map(tag => tag.value).join(',').toLowerCase()
            },
        },
        methods: {
            removeWhiteSpaces: (str) => str.replace(/\s+/g, ''),
            addCustomTag(customTag) {
                const validKey = this.removeWhiteSpaces(customTag)
                if (this.isTagWithoutLabel) {
                    const foundTag = this.selectedTags.find(tag => tag.value === validKey)
                    if (!foundTag) {
                        // Add tag if not existing
                        const customTagObj = createCustomTag(validKey)
                        this.$emit('on-tag-change', [...this.selectedTags, customTagObj])
                    }
                } else {
                    let currentLabels = this.$help.objects.clone(this.labels)
                    delete currentLabels.context
                    const customTagObj = {
                        [`${this.$config.customTagPrefix}${validKey}`]: customTag,
                    }
                    this.$emit('on-labels-change', { ...currentLabels, ...customTagObj, })
                }
            },
            onContextChange(sysTags) {
                if (this.isTagWithoutLabel && sysTags.context) {
                    const tagValues = sysTags.context.split(',')
                    let sysTagList = []
                    tagValues.forEach(value => sysTagList.push(createSystemTag(value)))
                    this.$emit('on-context-change', sysTagList)
                } else
                    this.$emit('on-context-change', sysTags.context || '')
            },
            handleTagDel(tag) {
                if (this.isTagWithoutLabel) {
                    let selectedTags = [...this.selectedTags]
                    const index = selectedTags.findIndex(({ type, value, }) => type == tag.type && (value == tag.text || value == tag.value))
                    selectedTags.splice(index, 1)
                    this.$emit('on-tag-change', selectedTags)
                } else {
                    switch (tag.type) {
                        case 'system':
                            let editedContext = this.$help.objects.clone(this.context)
                                .replace(`,${tag.text.toLowerCase()}`, '')
                                .replace(`${tag.text.toLowerCase()}`, '')

                            this.$emit('on-context-change', editedContext)
                            break
                        case 'custom': {
                            let currentLabels = this.$help.objects.clone(this.labels)
                            const validKey = this.removeWhiteSpaces(tag.text)
                            delete currentLabels[`${this.$config.customTagPrefix}${validKey}`]
                            this.$emit('on-labels-change', currentLabels)
                            break
                        }
                    }
                }
            },

        },
    }
</script>
