export function xpandStatsComSelect ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(mariadb_xpand_stats_Com_select{mariadb=~"${name}", kubernetes_namespace="${id}"}[300s]))`
    }
}

export function xpandStatsComSelectSeconds ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(increase(mariadb_xpand_stats_Com_select_seconds{mariadb=~"${name}", kubernetes_namespace=~"${id}"}[300s]))`
    }
}

export function xpandStatsComInsert ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(mariadb_xpand_stats_Com_insert{mariadb=~"${name}", kubernetes_namespace="${id}"}[300s]))`
    }
}
export function xpandStatsComInsertSeconds ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(increase(mariadb_xpand_stats_Com_insert_seconds{mariadb=~"${name}", kubernetes_namespace=~"${id}"}[300s]))`
    }
}

export function xpandStatsComUpdate ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(mariadb_xpand_stats_Com_update{mariadb=~"${name}", kubernetes_namespace="${id}"}[300s]))`
    }
}

export function xpandStatsComUpdateSeconds ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(increase(mariadb_xpand_stats_Com_update_seconds{mariadb=~"${name}", kubernetes_namespace="${id}"}[300s]))`
    }
}

export function xpandStatsComDelete ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(mariadb_xpand_stats_Com_delete{mariadb=~"${name}", kubernetes_namespace="${id}"}[300s]))`
    }
}

export function xpandStatsComDeleteSeconds ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(increase(mariadb_xpand_stats_Com_delete_seconds{mariadb=~"${name}", kubernetes_namespace=~"${id}"}[300s]))`
    }
}

export function xpandStatsComAlertCluster ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(mariadb_xpand_stats_Com_alter_cluster{mariadb=~"${name}", kubernetes_namespace="${id}"}[300s]))`
    }
}

export function xpandStatsComSlaveStats ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(mariadb_xpand_stats_Com_show_slave_status{mariadb=~"${name}", kubernetes_namespace="${id}"}[300s]))`
    }
}

export function xpandStatsComShowStatus ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(mariadb_xpand_stats_Com_show_status{mariadb=~"${name}", kubernetes_namespace="${id}"}[300s]))`
    }
}
export function xpandStatsComShowVariable ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(mariadb_xpand_stats_Com_show_variables{mariadb=~"${name}", kubernetes_namespace="${id}"}[300s]))`
    }
}
export function xpandStatsComSetOption ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(mariadb_xpand_stats_Com_set_option{mariadb=~"${name}", kubernetes_namespace="${id}"}[300s]))`
    }
}

export function xpandResponseTimeSeconds ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        // TODO: Adjust, fow now we divide by 1mill until the exporter is fixed
        return `avg(rate(mariadb_xpand_response_time_seconds{mariadb=~"${name}", kubernetes_namespace=~"${id}"}[300s])) by (type) / 1000000`
    }
}

export function xpandQueryLatency ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        // TODO: Adjust, fow now we divide by 1mill until the exporter is fixed
        return `avg(rate(mariadb_xpand_response_time_seconds{mariadb=~"${name}", kubernetes_namespace="${id}"}[300s])) by (mariadb, type) / 1000000`
    }
}
