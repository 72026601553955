<style lang="scss" scoped>
.tier-badge {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    background: $prelude;

    span {
        line-height: 20px;
        color: $azure;
    }
}
</style>

<template>
    <span class="tier-badge d-inline-block py-0 px-2 text-center rounded">
        <span
            class="text-uppercase">{{ $t(`serviceTier.${tierName}`) }}</span>
    </span>
</template>

<script>

    export default {
        name: 'ServiceTierBadge',
        props: {
            tierName: {
                type: String,
                required: true,
                validator(value) {
                    return ['foundation', 'power'].includes(value)
                },
            },
        },
    }
</script>
