export function serviceTableLocksImmediate ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(mariadb_global_status_table_locks_immediate{mariadb=~"${name}", kubernetes_namespace="${id}"}[300s]))`
    }
}

export function serviceTableLocksWaited ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(mariadb_global_status_table_locks_waited{mariadb=~"${name}", kubernetes_namespace="${id}"}[300s]))`
    }
}

export function serviceTableOpen ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(mariadb_global_status_open_tables{mariadb=~"${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}

export function serviceTableOpened ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(rate(mariadb_global_status_opened_tables{mariadb=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serverTableLocksImmediate ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(rate(mariadb_global_status_table_locks_immediate{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serverTableLocksWaited ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(rate(mariadb_global_status_table_locks_waited{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serverTableOpen ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(mariadb_global_status_open_tables{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}

export function serverTableOpened ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(rate(mariadb_global_status_opened_tables{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serverTableOpenCache ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(mariadb_global_variables_table_open_cache{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}

export function serverTableOpenCacheHits ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(rate(mariadb_global_status_table_open_cache_hits{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serverTableOpenCacheMisses ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(rate(mariadb_global_status_table_open_cache_misses{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serverTableOpenCacheOverflows ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(rate(mariadb_global_status_table_open_cache_overflows{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}

export function serverTableOpenCacheHitsRatio ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(rate(mariadb_global_status_table_open_cache_hits{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])/(rate(mariadb_global_status_table_open_cache_hits{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])+rate(mariadb_global_status_table_open_cache_misses{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s]))) by (kubernetes_pod_name)`
    }
}

export function serverTableOpenDefinitions ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(mariadb_global_status_open_table_definitions{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}

export function serverTableOpenedDefinitions ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `avg(rate(mariadb_global_status_opened_table_definitions{kubernetes_pod_name=~"${name}", kubernetes_namespace="${id}"}[300s])) by (kubernetes_pod_name)`
    }
}
