<style lang="scss" scoped>
    path {
        fill: currentColor;
        stroke: currentColor;
    }
    circle {
        stroke: currentColor;
    }
</style>

<template>
    <svg
        viewBox="0 0 16 16"
        fill="none">
        <circle
            cx="8"
            cy="8"
            r="7.5"
            stroke="#979797" />
        <path
            d="M5.70253 5.63104H10.2949C10.3344 5.63104 10.3663 5.66302 10.3663 5.70247V10.2949C10.3663 10.3343 10.3344 10.3663 10.2949 10.3663H5.70253C5.66308 10.3663 5.6311 10.3343 5.6311 10.2949V5.70247C5.6311 5.66302 5.66308 5.63104 5.70253 5.63104Z"
            fill="#979797"
            stroke="#979797" />
    </svg>
</template>

<script>
    export default {}
</script>
