<template>
    <SelectableTable
        :headers="headers"
        :items="items"
        :no-data-text="$t('info.noLogs')"
        :server-items-length="serverItemsLength"
        :footer-props="footerOptions"
        v-bind="{ ...$attrs }"
        v-on="$listeners">
        <template #[`item.name`]="{ item }">
            <td>
                {{
                    $t("logForX", [
                        $t(`logTypes.${item.type}`),
                        item.date.format("YYYY-MM-DD UTC (ddd)"),
                    ])
                }}
            </td>
        </template>
        <template #[`item.server`]="{ item }">
            <tag
                :small="true"
                color="#e8eef1">
                <template slot="left-slot">
                    <v-icon size="12">
                        mdi-server
                    </v-icon>
                </template>
                <span>
                    <span class="font-weight-medium"> {{ item.service }}</span>
                    <span> /{{ item.server }}</span>
                </span>
            </tag>
        </template>
        <template #[`item.startTime`]="{ item }">
            <v-tooltip top>
                <template #activator="{ on }">
                    <span
                        class="text-body-2 grey--text text--lighten2"
                        v-on="on">
                        {{ item.date.format("YYYY-MM-DD UTC (ddd)") }}
                    </span>
                </template>
                <span>
                    {{ $moment(item.date).format("YYYY-MM-DD") }} ({{ $t('logDateInfo') }})
                </span>
            </v-tooltip>
        </template>
    </SelectableTable>
</template>

<script>
    import SelectableTable from 'components/common/SelectableTable'
    import Tag from 'components/alerts/Tag'
    export default {
        name: 'LogsTable',
        components: { SelectableTable, Tag, },
        inheritAttrs: false,
        props: {
            data: { type: Object, required: true, },
        },
        computed: {
            headers() {
                return [
                    { text: this.$t('logName'), value: 'name', sortable: false, },
                    {
                        text: this.$tc('server', 1),
                        value: 'server',
                        sortable: false,
                    },
                    { text: this.$t('date'), value: 'startTime', },
                    { text: this.$t('size'), value: 'size', sortable: false, }
                ]
            },
            footerOptions() {
                return {
                    'items-per-page-options': [10, 20, 50, 100, -1],
                }
            },
            serverItemsLength() {
                return this.$typy(this.data, 'count').safeNumber
            },
            items() {
                const items = []
                this.$typy(this.data, 'logs').safeArray.forEach(
                    ({
                        id,
                        logType,
                        serverDataSourceId,
                        size,
                        service,
                        server,
                        endTime,
                    }) => {
                        items.push({
                            id,
                            type: logType,
                            service: service || '',
                            server: server || '',
                            dataSourceId: serverDataSourceId,
                            date: endTime ? this.$moment(endTime).utc() : this.$t('notApplicable'),
                            size: this.$typy(size).isNumber
                                ? this.$help.formatBytes(size)
                                : this.$t('notApplicable'),
                        })
                    }
                )
                return items
            },
        },
    }
</script>
