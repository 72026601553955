import alerts from 'config/alerts'
import launchService from 'config/launchService'
import i18n from 'plugins/i18n'
import common from 'config/common'
import MONITORING from 'config/monitoring'
import CATEGORIES from 'config/categories'
import BILLING from 'config/billing'
import CONFIG_MANAGER from 'config/configManager'
import SKY_QUERY from 'config/skyQuery'
import PARTNER_INTEGRATIONS from 'config/partnerIntegrations'

const DOCS_URL = 'https://r.mariadb.com/snr-skysql'
const SLACK_URL = 'https://mariadb-community.slack.com'

export const CONFIG = Object.freeze({
    asciiLogo: `
     ______     ______   ______     ______     _____     __  __     ______     ______
    /\\  ___\\   /\\__  _\\ /\\  __ \\   /\\  == \\   /\\  __-.  /\\ \\/\\ \\   /\\  ___\\   /\\__  _\\
    \\ \\___  \\  \\/_/\\ \\/ \\ \\  __ \\  \\ \\  __<   \\ \\ \\/\\ \\ \\ \\ \\_\\ \\  \\ \\___  \\  \\/_/\\ \\/
     \\/\\_____\\    \\ \\_\\  \\ \\_\\ \\_\\  \\ \\_\\ \\_\\  \\ \\____-  \\ \\_____\\  \\/\\_____\\    \\ \\_\\
      \\/_____/     \\/_/   \\/_/\\/_/   \\/_/ /_/   \\/____/   \\/_____/   \\/_____/     \\/_/`,
    productName: i18n.t('productName', ['SkySQL']),
    navigation: [
        {
            title: i18n.t('dashboard'),
            route: 'dashboard',
            size: 22,
            icon: '$vuetify.icons.dashboard',
        },
        {
            title: i18n.t('alerts'),
            route: 'alerts',
            size: 22,
            icon: '$vuetify.icons.alerts',
        },
        {
            title: i18n.tc('log', 2),
            route: 'logs',
            size: 25,
            icon: '$vuetify.icons.logs',
        },
        {
            title: i18n.t('workspace'),
            route: 'workspace',
            size: 22,
            icon: '$vuetify.icons.dbQuery',
        },
        {
            title: i18n.t('cloudBackup'),
            url: process.env.VUE_APP_CLOUD_BACKUP_URL,
            size: 22,
            icon: '$vuetify.icons.cloudBackupIcon',
            hideFlag: 'enable-portal-cloud-backup',
        },
        {
            title: i18n.t('integrations'),
            route: 'integrations',
            size: 22,
            icon: '$vuetify.icons.integrations',
        },
        {
            title: i18n.t('settings'),
            route: 'settings',
            size: 22,
            icon: '$vuetify.icons.settings',
        },
        {
            title: i18n.t('geospatial'),
            url: 'https://mariadb.com/contact/geospatial/',
            size: 24,
            icon: '$vuetify.icons.geospatial',
            hideFlag: 'enable-portal-geospatial-link',
            isBottomNav: true,
        },
        {
            title: i18n.t('support'),
            size: 22,
            icon: '$vuetify.icons.comments',
            isBottomNav: true,
            isMenu: true,
            menuItems: [
                { title: i18n.t('supportPortal'), url: common.CSM_URL, },
                { title: i18n.t('slackCommunity'), url: SLACK_URL, }
            ],
        },
        {
            title: i18n.t('documentation'),
            size: 22,
            icon: '$vuetify.icons.questionCircle',
            isBottomNav: true,
            isMenu: true,
            menuItems: [
                { title: i18n.t('generalDoc'), url: DOCS_URL, },
                { title: i18n.t('apiDoc'), url: process.env.VUE_APP_SWAGGER_URL, }
            ],
        }
    ],
    keepAliveInterval: 30, // seconds
    fetchServicesInterval: 30, // seconds
    fetchAlertsInterval: 30 * 60, // seconds
    logsFetchInterval: 30, // seconds
    maxLoginAttempts: 5,
    ...alerts,
    ...launchService,
    ...common,
    ...MONITORING,
    ...CATEGORIES,
    ...BILLING,
    ...CONFIG_MANAGER,
    ...SKY_QUERY,
    ...{PARTNER_INTEGRATIONS,},
})
