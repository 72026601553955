<style lang="scss" scoped>
    .nav-wrapper {
        width: 250px;
    }
    .nav-item {
        border: 1px solid $cornsilk;
        border-radius: 8px;
        &.selected {
            background: $cornsilk;
            color: $navigation;
            font-weight: 700;
        }
    }
</style>
<template>
    <div class="nav-wrapper text-body-2 info--text">
        <div
            :class="[
                'nav-item pointer mb-2 px-4 py-2',
                { 'selected': currentTab === 'summary' }
            ]"
            @click="handleClick('summary')">
            Summary
        </div>
        <div
            :class="[
                'nav-item pointer mb-2 px-4 py-2',
                { 'selected': currentTab === 'descriptive-analysis' }
            ]"
            @click="handleClick('descriptive-analysis')">
            Descriptive Analysis
        </div>
        <div
            :class="[
                'nav-item pointer mb-2 px-4 py-2',
                { 'selected': currentTab === 'predictive-analysis' }
            ]"
            @click="handleClick('predictive-analysis')">
            Predictive Analysis
        </div>
        <div
            :class="[
                'nav-item pointer mb-2 px-4 py-2',
                { 'selected': currentTab === 'prescriptive-analysis' }
            ]"
            @click="handleClick('prescriptive-analysis')">
            Prescriptive Analysis
        </div>
    </div>
</template>
<script>
    export default {
        name: 'SideNav',
        data(){
            return{
                currentTab: 'summary',
            }
        },
        methods: {
            handleClick(tab){
                this.currentTab = tab
                this.$emit('on-tab-change', tab)
            },
        },
    }
</script>
