<style lang="scss" scoped>
.maintenance-window-width {
    width: 320px;
    max-width: 320px;
}
</style>
<template>
    <v-card
        outlined
        class="details-card mt-2">
        <v-card-title class="details-title text-body-2 d-flex justify-space-between text-uppercase">
            {{ $t('serviceDetailsTab.databaseConfig') }}
        </v-card-title>
        <v-card-text>
            <v-row
                data-auto="configuration"
                class="text-caption color text-navigation">
                <v-col class="px-3 py-0">
                    <router-link
                        v-if="configValue"
                        :to="`/settings/configuration-manager/${configValue}`">
                        {{ configValue }}
                    </router-link>
                    <span v-else>
                        {{ $t('notApplicable') }}
                    </span>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
    const Configuration = {
        name: 'Configuration',
        props: {
            configValue: {
                type: String,
                required: true,
            },
        },
    }

    export default Configuration
</script>
