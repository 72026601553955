<style lang="scss" scoped>
h2 {
    font-size: 18px;
}

.delete-title {
    font-size: 1.875rem;
    color: $deep-ocean;
    word-break: keep-all;
}

.v-btn {
    vertical-align: top;

    &.v-btn--depressed {
        min-width: 17% !important;
    }
}

.notice {
    background: $estimate-calculations;
}

.setup-pl {
    color: $links;
}
</style>
<template>
    <div>
        <base-dialog
            v-model="isDialogOpen"
            :min-width="800"
            :max-width="800"
            :on-cancel="clearState"
            :on-close="clearState">
            <template #title>
                <p class="delete-title ml-2 font-weight-light">
                    <span>
                        {{ $t('mySkySQLServiceConnectToService', { serviceName: service.name }) }}
                    </span>
                </p>
            </template>
            <template #body>
                <v-col cols="12">
                    <div v-if="$unleash.isFeatureEnabled('enable-portal-dual-endpoints')">
                        <v-tabs v-model="endpointsTab">
                            <v-tabs-slider color="accent-dark" />
                            <v-tab
                                v-for="endpoint in endpointsText"
                                :key="endpoint"
                                class="text-capitalize">
                                {{ endpoint }}
                            </v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="endpointsTab">
                            <v-tab-item
                                v-for="(endpoint, index) in service.endpoints"
                                :key="index">
                                <v-card flat>
                                    <v-card-text>
                                        <ConnectServiceDetails
                                            :has-private-connect-projects="hasPrivateConnectProjects"
                                            :service="service"
                                            :endpoint="endpoint"
                                            :service-credentials="serviceCredentials"
                                            @on-setup-private-connect="openPrivateConnectSetup"
                                            @on-manage-allowlist="openSecurityDialog" />

                                        <ConnectionOptions
                                            :service="service"
                                            :endpoint="endpoint"
                                            :is-xgres="isXgres"
                                            :service-credentials="serviceCredentials" />
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>
                        </v-tabs-items>
                    </div>
                    <div v-else>
                        <div>
                            <div>
                                <div class="font-weight-bold">
                                    {{ $t('serviceDetails') }}
                                </div>
                                <div class="mt-2 d-flex">
                                    <div class="mr-8">
                                        <div>{{ $t('fullyQualifiedDomainName') }}</div>
                                        <div>{{ $t('readOnlyPort') }}</div>
                                        <div>{{ $t('readWritePort') }}</div>
                                        <div v-if="service.nosql_enabled">
                                            {{ $t('noSQLPort') }}
                                        </div>
                                        <div>{{ $t('username') }} </div>
                                        <div>{{ $t('defaultPassword') }}</div>
                                        <div v-if="service.ssl_enabled">
                                            {{ $t('certificateAuthenticationChain') }}
                                        </div>
                                    </div>
                                    <div>
                                        <div class="d-flex align-center">
                                            <div>{{ service.fqdn || $t('notApplicable') }}</div>
                                            <div v-if="service.fqdn">
                                                <v-btn
                                                    color="primary"
                                                    class="ml-1"
                                                    icon
                                                    x-small
                                                    @click="copyToClipboard(service.fqdn)">
                                                    <v-icon size="16">
                                                        mdi-content-copy
                                                    </v-icon>
                                                </v-btn>
                                            </div>
                                        </div>
                                        <div>{{ servicePorts.readonly || $t('notApplicable') }}</div>
                                        <div>{{ servicePorts.readwrite || $t('notApplicable') }}</div>
                                        <div v-if="service.nosql_enabled">
                                            {{ servicePorts.nosql || $t('notApplicable') }}
                                        </div>
                                        <div class="d-flex align-center">
                                            <div>{{ serviceCredentials.username || $t('notApplicable') }}</div>
                                            <div v-if="serviceCredentials.username">
                                                <v-btn
                                                    color="primary"
                                                    class="ml-1"
                                                    icon
                                                    x-small
                                                    @click="copyToClipboard(serviceCredentials.username)">
                                                    <v-icon size="16">
                                                        mdi-content-copy
                                                    </v-icon>
                                                </v-btn>
                                            </div>
                                        </div>
                                        <div
                                            v-if="serviceCredentials.password"
                                            class="d-flex align-center">
                                            <div
                                                class="pointer"
                                                @click="copyToClipboard(serviceCredentials.password)">
                                                <span v-if="showPassword">
                                                    {{ serviceCredentials.password }}
                                                </span>
                                                <span v-else>{{ serviceCredentials.password.replace(/./g, '*') }}</span>
                                            </div>
                                            <div>
                                                <v-btn
                                                    color="primary"
                                                    height="20"
                                                    class="ml-1"
                                                    icon
                                                    x-small
                                                    @click="showPassword = !showPassword">
                                                    <v-icon>
                                                        {{ showPassword ? 'mdi-eye-off' : 'mdi-eye' }}
                                                    </v-icon>
                                                </v-btn>
                                                <v-btn
                                                    color="primary"
                                                    class="ml-1"
                                                    icon
                                                    x-small
                                                    @click="copyToClipboard(serviceCredentials.password)">
                                                    <v-icon size="16">
                                                        mdi-content-copy
                                                    </v-icon>
                                                </v-btn>
                                            </div>
                                        </div>
                                        <div v-else>
                                            {{ $t('notApplicable') }}
                                        </div>
                                        <div>
                                            <a
                                                :href="serviceCredentials.authChainURL"
                                                target="_blank">{{ $t('download')
                                                }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="!isPrivateConnectApplicable"
                                class="notice rounded pa-4 mt-4">
                                <h2 class="mb-4">
                                    {{ $t('allowIPAddresses') }}
                                </h2>
                                {{ $t('rememberToAllowlistYourIP') }}
                            </div>
                            <div
                                v-if="isPrivateConnectApplicable"
                                class="notice rounded pa-4 mt-4">
                                <div v-if="hasPrivateConnectProjects">
                                    <i18n
                                        path="privateConnect.completeSetupOnCloud"
                                        tag="p">
                                        <span
                                            class="font-weight-medium"
                                            place="important">{{ $t('privateConnect.important')
                                            }}:</span>
                                        <span place="connectionName">
                                            {{ privateConnectType }}
                                        </span>
                                        <span
                                            place="cloud"
                                            class="text-uppercase">
                                            {{ service.provider }}
                                        </span>
                                        <a
                                            place="instructions"
                                            rel="noopener noreferrer"
                                            class="text-capitalize"
                                            target="_blank"
                                            :href="instructionsLink">{{ $t('privateConnect.instructions')
                                            }}</a>
                                    </i18n>
                                </div>
                                <div v-else>
                                    <span class="font-weight-bold">{{ $t('privateConnect.important') }}:</span> {{
                                        $t('please') }} <span
                                        class="setup-pl text-decoration-underline pointer"
                                        @click="openPrivateConnectSetup">{{
                                            $t('privateConnect.completePrivateConnectSetup', {
                                                connectionName:
                                                    privateConnectType
                                            }) }}</span>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="isXgres"
                            class="mt-6">
                            <h2>{{ $t('connectingUsing_X', [$t('psqlCLI')]) }}</h2>
                            <codeBlock
                                class="mt-2"
                                :text="generatePostgresConnectSnippet()" />
                        </div>
                        <div
                            v-else
                            class="mt-6">
                            <h2>{{ $t('connectingUsing_X', [$t('mariaDBCLI')]) }}</h2>
                            <codeBlock
                                class="mt-2"
                                :text="generateMariaDBConnectSnippet()" />

                            <div class="mt-3">
                                {{ $t('changeDefaultPassword') }}:
                            </div>
                            <codeBlock
                                class="mt-2"
                                :text="generateMariaDBChangePasswordSnippet()" />
                        </div>
                        <div
                            v-if="service.nosql_enabled"
                            class="mt-8">
                            <h2>{{ $t('connectingUsing_X', [$t('mongosh')]) }}</h2>
                            <codeBlock
                                class="mt-2"
                                :text="generateNoSQLConnectSnippet()" />

                            <div class="mt-3">
                                {{ $t('authenticate') }}:
                            </div>
                            <codeBlock
                                class="mt-2"
                                :text="generateNoSQLAuthSnippet()" />

                            <div class="mt-3">
                                {{ $t('changeDefaultPassword_MXS_MDB') }}:
                            </div>
                            <codeBlock
                                class="mt-2"
                                :text="generateNoSQLChangePasswordSnippet()" />

                            <div class="mt-3">
                                {{ $t('changeDefaultPassword_MXS') }}:
                            </div>
                            <codeBlock
                                class="mt-2"
                                :text="generateNoSQLChangePasswordSnippet(true)" />
                        </div>
                    </div>
                </v-col>
            </template>
            <template #actions="{ cancel }">
                <v-spacer />
                <v-btn
                    color="primary"
                    depressed
                    rounded
                    outlined
                    class="cancel"
                    @click="cancel">
                    <span class="text-none">{{ $t('close') }} </span>
                </v-btn>
            </template>
        </base-dialog>
        <whitelist-modal ref="whitelistModal" />
    </div>
</template>
<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
    import codeBlock from 'components/common/codeBlock.vue'
    import {
        doPrivateConnectHaveProjects,
        isPrivateConnectOpted,
        isXpand,
        privateConnectLabel,
        getPrivateConnectInstructionLink,
        isXgres,
        hasMultipleEndpoints
    } from 'utils/service'
    import ConnectServiceDetails from 'components/service/ConnectServiceDetails.vue'
    import ConnectionOptions from 'components/service/ConnectionOptions.vue'
    import WhitelistModal from 'components/service/WhitelistModal.vue'

    export default {
        name: 'ConnectServiceModal',
        components: { codeBlock, ConnectServiceDetails, ConnectionOptions, WhitelistModal, },
        data() {
            return {
                title: this.$t('mySkySQLServiceConnectToService'),
                showPassword: false,
                endpointsTab: null,
            }
        },
        computed: {
            ...mapState(['serviceCredentials']),
            ...mapGetters(['getServiceById', 'getServiceEndpointPorts']),
            service() {
                return this.getServiceById(this.serviceCredentials.serviceId)
            },
            servicePorts() {
                const ports = {}
                const servicePorts = this.getServiceEndpointPorts(this.serviceCredentials.serviceId, 'primary')
                servicePorts.forEach(({ purpose, port, }) => {
                    ports[purpose] = port
                })

                return ports
            },
            isDialogOpen: {
                get() {
                    return this.serviceCredentials.isConnectDialogOpen
                },
                set(value) {
                    this.setServiceCredentialsDialog(value)
                },
            },
            isXgres() {
                return isXgres(this.service.topology)
            },
            isPrivateConnectApplicable() {
                return isPrivateConnectOpted(this.service.endpoints)
            },
            privateConnectType() {
                return privateConnectLabel(this.service.provider)
            },
            hasPrivateConnectProjects() {
                return doPrivateConnectHaveProjects(this.service.endpoints)
            },
            instructionsLink() {
                return getPrivateConnectInstructionLink(this.service.provider)
            },
            endpointsText() {
                let endpoints = [this.$i18n.t('dualEndpoints.primaryEndpoint')]
                if (hasMultipleEndpoints(this.service.endpoints)) {
                    endpoints.push(this.$i18n.t('dualEndpoints.secondaryEndpoint'))
                }
                return endpoints
            },
        },
        methods: {
            ...mapMutations(['setServiceCredentialsDialog']),
            ...mapActions(['copyToClipboard', 'openPrivateConnectModal']),
            extractChainFileName(URL) {
                const parts = URL.split('/')
                return parts.slice(-1)
            },
            generatePostgresConnectSnippet() {
                const { username, } = this.serviceCredentials
                const { readwrite, } = this.servicePorts
                const { fqdn, } = this.service

                if (!fqdn || !readwrite || !username) return this.$t('notApplicable')

                return `psql -h ${fqdn} -U ${username} -d postgres -p ${readwrite} -W`
            },
            generateMariaDBConnectSnippet() {
                /* eslint-disable camelcase */
                const { username, authChainURL, } = this.serviceCredentials
                const { readwrite, } = this.servicePorts
                const { fqdn, ssl_enabled, topology, } = this.service

                if (!fqdn || !readwrite || !username || !authChainURL) return this.$t('notApplicable')
                let options = ''

                if (ssl_enabled) {
                    const certificate = this.extractChainFileName(authChainURL)
                    options += `--ssl-ca ~/Downloads/${certificate} `
                }

                if (isXpand(topology)) {
                    options += '--default-character-set=utf8'
                }

                return `mariadb --host ${fqdn} --port ${readwrite} --user ${username} -p ${options}`
            /* eslint-enable */
            },
            generateMariaDBChangePasswordSnippet() {
                const { host, username, } = this.serviceCredentials
                if (!host || !username) return this.$t('notApplicable')
                return `SET PASSWORD FOR '${username}'@'${host}' = PASSWORD('new_password');`
            },
            generateNoSQLConnectSnippet() {
                /* eslint-disable camelcase */
                const { fqdn, ssl_enabled, } = this.service
                const { nosql, } = this.servicePorts

                if (!fqdn || !nosql) return this.$t('notApplicable')
                let options = ''
                if (ssl_enabled) {
                    const { authChainURL, } = this.serviceCredentials
                    const certificate = this.extractChainFileName(authChainURL)
                    options += `--tls --tlsCAFile ~/Downloads/${certificate}`
                }

                return `mongosh --host ${fqdn} --port ${nosql} ${options}`
            /* eslint-enable */
            },
            generateNoSQLAuthSnippet() {
                const { username, } = this.serviceCredentials

                return `use mariadb;\ndb.auth("${username}");`
            },
            generateNoSQLChangePasswordSnippet(mxsOnly = false) {
                const { username, } = this.serviceCredentials
                if (!username) return this.$t('notApplicable')

                return `db.runCommand({ ${mxsOnly ? 'mxsUpdateUser' : 'updateUser'}: "${username}", pwd: "new_password" });`
            },
            openPrivateConnectSetup() {
                this.openPrivateConnectModal({ popupState: true, serviceId: this.service.id, })
            },
            clearState() {
                this.endpointsTab = null
            },
            openSecurityDialog() {
                this.$refs.whitelistModal.open(this.service.id)
            },
        },
    }
</script>
