<style lang="scss" scoped>
h2 {
    font-size: 18px;
}

.delete-title {
    font-size: 1.875rem;
    color: $deep-ocean;
}

.v-btn {
    vertical-align: top;

    &.v-btn--depressed {
        min-width: 17% !important;
    }
}

.notice {
    background: $estimate-calculations;
}

.code-wrapper {
    position: relative;
    background: #E8EEF1;

    .new-tab{
        position: absolute;
        right: 4px;
    }
}
</style>
<template>
    <div class="start-stop-popup">
        <base-dialog
            v-model="isServerlessOpen"
            :min-width="1000"
            :max-width="1000">
            <template #title>
                <div class="delete-title font-weight-light">
                    {{ $t('mySkySQLServiceConnectToService', { serviceName: service.name }) }}
                </div>
            </template>
            <template #body>
                <v-col cols="12 mt-8">
                    <div class="my-4">
                        <h2>{{ $t('gettingStartedSkyBook') }}</h2>
                        <div class="d-flex mt-2 code-wrapper pr-10 pa-3 rounded">
                            <a
                                :href="skyBookUrl"
                                target="_blank">{{ skyBookUrl }}</a>
                            <div class="new-tab">
                                <a
                                    :href="skyBookUrl"
                                    target="_blank">
                                    <v-icon color="primary">
                                        mdi-open-in-new
                                    </v-icon></a>
                            </div>
                        </div>
                    </div>
                    <div class="font-weight-bold">
                        <h2>{{ $t('serviceDetails') }}</h2>
                    </div>
                    <div class="mt-2 d-flex">
                        <div class="mr-8">
                            <div>{{ $t('sqlSpark') }}</div>
                            <div>
                                {{ $t('downloadJDBCDriver') }}
                            </div>
                        </div>
                        <div>
                            <div>
                                <a
                                    :href="sparkUIUrl"
                                    target="_blank">{{ sparkUIUrl }}</a>
                            </div>
                            <div>
                                <a
                                    :href="jdbcDriverUrl"
                                    target="_blank">{{ jdbcDriverUrl }}</a>
                            </div>
                            <div class="d-flex justify-center">
                                Or
                            </div>
                            <div>
                                <a
                                    :href="officialJDBCDriverUrl"
                                    target="_blank">{{ officialJDBCDriverUrl }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="notice rounded pa-4 mt-4">
                        <h2 class="mb-4">
                            {{ $t('allowIPAddresses') }}
                        </h2>
                        {{ $t('rememberToAllowlistYourIP') }}
                    </div>
                    <div class="mt-6">
                        <h2>{{ $t('connectingUsing_X', ['JDBC']) }}</h2>
                        <codeBlock
                            class="mt-2"
                            :text="serverlessConnectSnippet" />
                        <div class="mt-2 notice font-weight-bold pa-4">
                            <i18n
                                path="jdbcConnectNotice"
                                tag="h2">
                                <a
                                    place="notebook"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    :href="jdbcConnectNoteBookUrl">{{ $t('userMgmtNotebook') }}</a>
                            </i18n>
                        </div>
                    </div>
                </v-col>
            </template>
            <template #actions="{ cancel }">
                <v-spacer />
                <v-btn
                    color="primary"
                    depressed
                    rounded
                    outlined
                    class="cancel"
                    @click="cancel">
                    <span class="text-none">{{ $t('cancel') }} </span>
                </v-btn>
            </template>
        </base-dialog>
    </div>
</template>

<script>
    /**
     * DEV NOTE::
     * Everything inside this Component is mocked and hardcoded just for Demo purpose
     * Even the design of this Component is not approved by Prod/UX
     * This component may go huge refactoring or worst case we've to throw away this component
     *
     * The plan is to get the Demo milestone achieved and later figure out the right way
     * to do it.
     *
     */
    import { mapState, mapGetters, mapMutations } from 'vuex'
    import codeBlock from 'components/common/codeBlock.vue'

    export default {
        name: 'ServerlessConnectModal',
        components: { codeBlock, },
        computed: {
            ...mapState(['serviceCredentials']),
            ...mapGetters(['getServiceById', 'isServerlessModalOpen', 'serverlessToken', 'refreshTokenExpiry']),
            service() {
                return this.getServiceById(this.serviceCredentials.serviceId)
            },
            isServerlessOpen: {
                get() {
                    return this.serviceCredentials.isServerlessModalOpen
                },
                set(value) {
                    this.setServerlessConnectModalStatus(value)
                },
            },
            fqdn() {
                return this.service.fqdn || 'ec2-54-196-12-76.compute-1.amazonaws.com'
            },
            sparkUIUrl(){
                return `https://${this.fqdn}`
            },
            keystoreUrl(){
                return `https://${this.fqdn}/cacerts/spark-thrift-truststore.p12`
            },
            jdbcDriverUrl(){
                return `https://${this.fqdn}/hive-jdbc-standalone.jar`
            },
            officialJDBCDriverUrl(){
                return 'https://repo1.maven.org/maven2/org/apache/hive/hive-jdbc/'
            },
            skyBookUrl() {
                return `https://${this.fqdn}/skybook`
            },
            serverlessConnectSnippet() {
                return `jdbc:hive2://${this.fqdn}/;ssl=true;transportMode=http;httpPath=cliservice`
            },
            jdbcConnectNoteBookUrl() {
                return `https://${this.fqdn}/skybook/#/notebook/2HK2XM89Z`
            },
        },
        methods: {
            ...mapMutations(['setServerlessConnectModalStatus']),
        },
    }
</script>
