<template>
    <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.5 15.0002C5.67 15.0002 4.92 15.3402 4.38 15.8802C3.2 17.0602 2.5 22.0002 2.5 22.0002C2.5 22.0002 7.44 21.3002 8.62 20.1202C9.04099 19.7014 9.32802 19.1669 9.44467 18.5847C9.56131 18.0024 9.5023 17.3987 9.27513 16.85C9.04796 16.3014 8.66287 15.8326 8.16876 15.5032C7.67465 15.1739 7.09382 14.9988 6.5 15.0002ZM7.21 18.7102C6.93 18.9902 5.04 19.4702 5.04 19.4702C5.04 19.4702 5.51 17.5902 5.8 17.3002C5.97 17.1102 6.22 17.0002 6.5 17.0002C6.69798 17.0005 6.89145 17.0593 7.05604 17.1694C7.22062 17.2794 7.34896 17.4357 7.42488 17.6186C7.5008 17.8014 7.52091 18.0026 7.48267 18.1969C7.44443 18.3911 7.34956 18.5697 7.21 18.7102ZM17.92 13.6502C24.28 7.29018 22.16 2.34018 22.16 2.34018C22.16 2.34018 17.21 0.220177 10.85 6.58018L8.36 6.08018C8.03632 6.01803 7.70237 6.03563 7.38702 6.13145C7.07166 6.22728 6.78438 6.39845 6.55 6.63018L2.5 10.6902L7.5 12.8302L11.67 17.0002L13.81 22.0002L17.86 17.9502C18.33 17.4802 18.54 16.8002 18.41 16.1402L17.92 13.6502ZM7.91 10.8302L6 10.0102L7.97 8.04018L9.41 8.33018C8.84 9.16018 8.33 10.0302 7.91 10.8302ZM14.49 18.5002L13.67 16.5902C14.47 16.1702 15.34 15.6602 16.16 15.0902L16.45 16.5302L14.49 18.5002ZM16.5 12.2402C15.18 13.5602 13.12 14.6402 12.46 14.9702L9.53 12.0402C9.85 11.3902 10.93 9.33018 12.26 8.00018C16.94 3.32018 20.49 4.01018 20.49 4.01018C20.49 4.01018 21.18 7.56018 16.5 12.2402ZM15.5 11.0002C16.6 11.0002 17.5 10.1002 17.5 9.00018C17.5 7.90018 16.6 7.00018 15.5 7.00018C14.4 7.00018 13.5 7.90018 13.5 9.00018C13.5 10.1002 14.4 11.0002 15.5 11.0002Z"
            fill="white" />
    </svg>
</template>

<script>
    export default {}
</script>
