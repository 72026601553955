<template>
    <svg viewBox="0 0 512 512">
        <rect
            fill="currentColor"
            x="153.6"
            y="153.6"
            width="204.8"
            height="204.8" />
        <path
            fill="currentColor"
            d="M486.4,281.6c15.36,0,25.6-10.24,25.6-25.6c0-12.8-10.24-25.6-25.6-25.6h-25.6v-51.2h25.6c15.36,0,25.6-10.24,25.6-25.6
                c0-12.8-10.24-25.6-25.6-25.6h-25.6v-25.6c0-28.16-23.04-51.2-51.2-51.2H384V25.6C384,12.8,373.76,0,358.4,0
                c-12.8,0-25.6,10.24-25.6,25.6v25.6h-51.2V25.6C281.6,12.8,271.36,0,256,0s-25.6,10.24-25.6,25.6v25.6h-51.2V25.6
                c0-12.8-10.24-25.6-25.6-25.6C138.24,0,128,10.24,128,25.6v25.6h-25.6c-28.16,0-51.2,23.04-51.2,51.2V128H25.6
                C10.24,128,0,138.24,0,153.6c0,12.8,10.24,25.6,25.6,25.6h25.6v51.2H25.6C10.24,230.4,0,240.64,0,256c0,12.8,10.24,25.6,25.6,25.6
                h25.6v51.2H25.6C10.24,332.8,0,343.04,0,358.4C0,371.2,10.24,384,25.6,384h25.6v25.6c0,28.16,23.04,51.2,51.2,51.2H128v25.6
                c0,12.8,10.24,25.6,25.6,25.6c15.36,0,25.6-10.24,25.6-25.6v-25.6h51.2v25.6c0,12.8,10.24,25.6,25.6,25.6s25.6-10.24,25.6-25.6
                v-25.6h51.2v25.6c0,12.8,10.24,25.6,25.6,25.6c12.8,0,25.6-10.24,25.6-25.6v-25.6h25.6c28.16,0,51.2-23.04,51.2-51.2V384h25.6
                c15.36,0,25.6-10.24,25.6-25.6c0-12.8-10.24-25.6-25.6-25.6h-25.6v-51.2H486.4z M409.6,409.6H102.4V102.4h307.2V409.6z" />
    </svg>
</template>

<script>
    export default {}
</script>
