<style lang="scss" scoped>
    path {
        fill: currentColor;
    }
</style>

<template>
    <svg viewBox="0 0 50 45">
        <path
            d="M 3.7687081,0.15306095 C 1.6888961,0.15306095 1.75e-8,1.8725706 1.75e-8,3.9716545 V 30.248298 c 0,2.099084 1.6888960825,
            3.814061 3.7687080825,3.814061 H 23.55102 v 5.954649 c -5.016012,0.114451 -10.018663,0.785716 -14.8526069,2.022674 -0.754881,
            0.1911 -1.214759,0.972205 -1.020408,1.732427 0.160682,0.644818 0.731638,1.07483 1.365078,1.07483 0.113778,0 0.223794,-0.0137
            0.331066,-0.03174 l 0.01811,-0.0046 c 10.1447989,-2.600867 21.0695829,-2.613355 31.2199539,-0.04082 0.760686,0.190868 1.52667,
            -0.271827 1.714288,-1.03855 0.186688,-0.76324 -0.267401,-1.541094 -1.02948,-1.732427 v 0.0046 C 36.43862,40.771292 31.415213,
            40.109335 26.376417,40.012473 v -5.950114 h 19.85034 C 48.306337,34.062359 50,32.353419 50,30.248298 V 3.9716545 C 50,1.8725706
            48.306569,0.15306095 46.226757,0.15306095 Z m 0,2.85260865 H 46.226757 c 0.52036,0 0.947846,0.4316938 0.947846,0.9659849
            V 24.443309 H 2.8208621 V 3.9716545 c 0,-0.5342911 0.427254,-0.9659849 0.947846,-0.9659849 z M 2.8208621,27.295918 H 47.174603
            v 2.95238 c 0,0.534291 -0.427486,0.961453 -0.947846,0.961453 H 3.7687081 c -0.520592,0 -0.947846,-0.427162 -0.947846,
            -0.961453 z" />
    </svg>
</template>

<script>
    export default {}
</script>
