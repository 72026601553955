<style lang="scss" scoped>
.provider {
    border: 1px solid #bed1da;
    overflow: hidden;
    max-height: 6rem;
    position: relative;
    font-family: $body-font-family;

    .selection-status {
        position: absolute;
        right: 1rem;
        top: 1rem;
    }

    .coming-soon{
        position: absolute;
        top: 0;
        right:0;
        background: $selection-highlight;
        border-radius: 0px 10px;
        padding: 4px;
        font-weight: 600;
        font-size: 9px;
        color: $background
    }
}

.disabled-provider :not(.coming-soon){
    opacity: 0.7;
}

.provider:hover {
    cursor: pointer;
}
.right-card {
    border-radius: 10px 0px 0px 10px;
    border-right: none;
}

.left-card {
    border-radius: 0px 10px 10px 0px;
}
</style>
<template>
    <v-col
        :class="[
            'mt-4 pt-8 pb-8 provider',
            isPositionRight ? 'right-card' : 'left-card',
            isSelected && 'highlight-instance-row',
            isDisabled && 'disabled-provider'
        ]"
        :data-auto="dataAutoPrefix('content')"
        @click="onClick">
        <div
            v-if="isDisabled"
            class="coming-soon text-uppercase font-weight-medium">
            {{ $t('comingSoon') }}
        </div>
        <div
            v-if="isSelected"
            class="selection-status">
            <v-icon
                size="20"
                class="mr-4 mt-4">
                $vuetify.icons.statusOk
            </v-icon>
        </div>
        <div class="text-center">
            <v-icon
                style="height: 26px"
                :size="iconSize"
                class="mr-3">
                {{
                    icon
                }}
            </v-icon>
        </div>
    </v-col>
</template>
<script>
    const CloudServiceProvider = {
        name: 'cloud-service-provider',
        props: {
            isSelected: Boolean,
            icon: String,
            iconSize: Number,
            type: String,
            parentDataAutoPrefix: Function,
            isPositionRight: Boolean,
            isDisabled: {
                type: Boolean,
                default: false,
            },
        },

        methods: {
            onClick () {
                if(!this.isDisabled) this.$emit('handleCloudProviderSelection', this.type)
            },
            dataAutoPrefix (string) {
                return this.parentDataAutoPrefix(string + '-' + this.type)
            },
        },
    }

    export default CloudServiceProvider
</script>
