<style lang="scss" scopped>
.center {
    margin: 0 auto;
}

.right {
    float: right;
}



.search-box {
    max-width: 220px;
    padding: 13px 0;
    input {
        color: inherit;
        border-color: inherit !important;
        height: 30px;
        border: 1px solid;
        border-radius: 15px;
        width: 100%;
        padding-left: 15px;
        padding-right: 30px;

        &:focus {
            outline: $primary;
            border: 2px solid $primary !important;
        }

        &::placeholder {
            color: inherit;
            opacity: 0.5;
        }
    }

    .v-icon {
        position: absolute;
        line-height: 30px;
        transform: translate(-125%, 0);
        color: inherit;
        &:focus{
            color: $primary !important;
        }
        &:focus::after {
            opacity: 0;
            color: $primary;
        }
    }
}
</style>

<template>
    <div
        class="search-box text-body-2"
        :class="{ center: position === 'center', right: position === 'right' }">
        <input
            ref="search-input"
            type="text"
            :placeholder="$t('search')"
            :value="value"
            @input="onInput($event)" />
        <v-icon
            :size="20"
            @click="clearInput">
            {{ value.length ? 'mdi-close' : 'mdi-magnify' }}
        </v-icon>
    </div>
</template>

<script>
    export default {
        name: 'SearchBox',
        props: {
            focus: Boolean,
            position: {
                type: String,
                default: 'center',
            },
            placeholder: {
                type: String,
                default: 'searchTag',
            },
        },
        data() {
            return {
                value: '',
            }
        },
        methods: {
            onInput(e) {
                this.value = e.target.value
                this.$emit('input', e.target.value)
            },
            clearInput() {
                if (this.value.length) {
                    this.value = ''
                    this.$emit('input', '')
                }
            },
        },
    }
</script>
