import Dashboard from 'pages/Dashboard'

export default [
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            requiresAuth: true,
            title: 'Dashboard',
        },
    }
]
