import Vue from 'vue'
import { UnleashClient } from 'unleash-proxy-client'
import { getBootstrapFFsForUnleash } from 'utils/featureEval'

const { VUE_APP_UNLEASH_ENDPOINT, VUE_APP_UNLEASH_TOKEN,} = process.env

const unleash = new UnleashClient({
    url: VUE_APP_UNLEASH_ENDPOINT,
    clientKey: VUE_APP_UNLEASH_TOKEN,
    refreshInterval: 60, // 1 or 2 minute should be good enough
    environment: 'production',
    appName: 'portal',
    // Bootstrap features are loaded
    // and are replaced completely when Server features are available
    // to avoid replacing all feature flags
    // and just overriding which are available
    // added `isFeatureEnabled` method that
    // fallbacks on local copy if server evaluation is falsy
    // this is to ensure even if the feature flag is not available
    // on server maybe due to the reason it was not created
    // we still have a local copy that will give us expected value
    // for higher environment it may be beneficial to have to false
    // but for lower region to unblock development we need this setup
    bootstrap: getBootstrapFFsForUnleash(),
    bootstrapOverride: false,
})

unleash.isFeatureEnabled = function(flag){
    // Custom method to extend/customize feature
    // checks for unleash
    return unleash.isEnabled(flag)
}

Object.defineProperty(Vue.prototype, '$unleash', { value: unleash, })
