import Vue from 'vue'
import * as moment from 'moment'

Vue.filter('truncate', (fullStr, strLen, separator = '...') => {
    if (fullStr.length <= strLen) return fullStr

    const sepLen = separator.length
    const charsToShow = strLen - sepLen
    const frontChars = Math.ceil(charsToShow / 2)
    const backChars = Math.floor(charsToShow / 2)

    return fullStr.substr(0, frontChars) +
         separator +
         fullStr.substr(fullStr.length - backChars)
})

Vue.filter('unixToDate', (unix) => {
    return moment.unix(unix).format('DD.MM.YYYY HH:mm:ss')
})

Vue.filter('unixToFromNow', (unix) => {
    return moment.unix(unix).fromNow()
})
