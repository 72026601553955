export default Object.freeze({
    regions: {
        america: 'Americas',
        emea: 'EMEA',
        apac: 'APAC',
    },
    iops: {
        io1: 'io1',
        gp2: 'gp2',
        gp3: 'gp3',
    },
    cloudProvider: {
        AWS: 'aws',
        GCP : 'gcp',
    },
    DEFAULT_ARCHITECTURE: 'amd64',
    ARCH_AMD: 'amd64',
    ARCH_ARM: 'arm64',
    INSTANCE_SIZE_TYPE: {
        SERVER: 'server',
        PROXY: 'proxy',
    },
    GCP_STORAGE_TYPES: {
        pdStandard: 'pd-standard',
        zonalSsdPd: 'zonal_ssd_pd',
    },
})
