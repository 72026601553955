
<style lang="scss" scoped>
    path {
        fill: currentColor;
    }
</style>

<template>
    <svg
        width="14"
        height="10"
        viewBox="0 0 14 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.1716 1H1.84684L7.05708 4.77903L12.1716 1ZM1 1.62112V8.14546C1 8.43666 1.23607 8.67273 1.52727 8.67273H12.4727C12.7639 8.67273 13 8.43666 13 8.14546V1.63127L7.50682 5.69008C7.24229 5.88553 6.88164 5.88712 6.6154 5.69402L1 1.62112ZM0 1.52727C0 0.683783 0.683783 0 1.52727 0H12.4727C13.3162 0 14 0.683783 14 1.52727V8.14546C14 8.98895 13.3162 9.67273 12.4727 9.67273H1.52727C0.683784 9.67273 0 8.98894 0 8.14546V1.52727Z"
            fill="#0E6488" />
    </svg>
</template>

<script>
    export default {}
</script>
