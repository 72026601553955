<style lang="scss" scoped>
.remove-project {
    color: $links
}

.project-list {

    max-height: 250px;
    overflow: scroll;

    .header-row {
        min-height: 50px;
        background: $cornsilk;
        border-radius: 5px 5px 0px 0px;
    }

    .project-row {
        border: 1px solid $cornsilk;

        .v-input {
            height: 35px;
        }
    }
}

.disconnect-private-link {
    color: $links
}
</style>
<template>
    <div>
        <div class="project-list pa-4">
            <v-row class="pl-4 header-row text-caption font-weight-bold text-uppercase">
                <v-col cols="4">
                    {{ $t('privateConnect.projects', { connectionName: privateConnectType, projects: entityType }) }}
                </v-col>
                <v-col> {{ $t('privateConnect.projectAlias') }}</v-col>
            </v-row>
            <v-row
                v-for="(project, i) in existingProjectList"
                :key="project.key+'-'+i"
                class="pl-4 project-row"
                @mouseover="showOnMouseHover(i)"
                @mouseout="hideOnMouseOut()">
                <v-col cols="4">
                    <v-text-field
                        v-if="projectIdToEdit === project.id"
                        v-model="editedId"
                        outlined
                        dense
                        height="10px"
                        class="shrink input-class" />
                    <span v-else>
                        {{ project.id }}
                    </span>
                </v-col>
                <v-col cols="4">
                    <v-text-field
                        v-if="projectIdToEdit === project.id"
                        v-model="editedAlias"
                        outlined
                        dense
                        height="10px"
                        class="shrink input-class" />
                    <span v-else>
                        {{ project.alias }}
                    </span>
                </v-col>
                <v-col
                    class="pointer remove-project text-body-2">
                    <div v-show="currentRemoveIndex === i">
                        <span
                            class="mr-4"
                            @click="editProject(project.id, project.alias)">
                            {{ projectIdToEdit === project.id ? $t('save') : $t('edit') }}
                        </span>
                        <span @click="removeProject(project.id)">
                            {{ $t('remove') }}
                        </span>
                    </div>
                </v-col>
            </v-row>
        </div>
        <div class="mt-6">
            <span
                class="pointer disconnect-private-link"
                @click="disconnectProjectLink">
                {{ $t('privateConnect.disconnectPrivateConnect', { connectionName: privateConnectType }) }}
            </span>
        </div>
    </div>
</template>
<script>
    import { isProviderGCP } from 'utils/service'
    export default {
        name: 'PrivateConnectProjectList',
        props: {
            projectList: {
                type: Array,
                required: true,
            },
            privateConnectType: {
                type: String,
                required: true,
            },
            provider: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                currentRemoveIndex: null,
                projectIdToEdit: null,
                existingProjectList: this.projectList,
                editedAlias: '',
                editedId: '',
            }
        },
        computed: {
            entityType() {
                return isProviderGCP(this.provider) ? this.$t('privateConnect.projectIds') : this.$t('privateConnect.accountIds')
            },
        },
        methods: {
            removeProject(id) {
                // When the project List is just 1
                // removing the sole project is as
                // good as disconnecting the private
                // connection
                if (this.existingProjectList.length === 1) {
                    return this.disconnectProjectLink()
                }
                this.$emit('on-remove-project', id)
            },
            disconnectProjectLink() {
                this.$emit('on-disconnect-project-link')
            },
            showOnMouseHover(index) {
                this.currentRemoveIndex = index
            },
            hideOnMouseOut() {
                this.currentRemoveIndex = null
            },
            editProject(id, alias) {
                if (id === this.projectIdToEdit) {
                    const projectIndex = this.existingProjectList.findIndex(project => project.id === id)
                    this.$set(this.existingProjectList, projectIndex, { id: this.editedId, alias: this.editedAlias, })
                    this.projectIdToEdit = null
                    this.editedId = ''
                    this.editedAlias = ''
                    this.$emit('on-update-list', this.existingProjectList)
                } else {
                    // Make the inputs editable
                    this.projectIdToEdit = id
                    this.editedId = id
                    this.editedAlias = alias
                }
            },

        },
    }
</script>
