<template>
    <v-card
        data-auto="service-details.service-endpoints"
        outlined
        class="details-card">
        <v-card-title class="details-title text-body-2 d-flex justify-space-between text-uppercase">
            <v-row>
                <v-col
                    data-auto="service-details.service-endpoints.title"
                    cols="6">
                    {{ $t('serviceDetailsTab.serviceEndpoints') }}
                </v-col>
                <v-col
                    v-if="!secondaryEndpoint"
                    class="d-flex"
                    cols="6">
                    <v-spacer />
                    <v-btn
                        data-auto="service-details.service-endpoints.add-secondary-endpoint"
                        class="text-capitalize"
                        outlined
                        color="primary"
                        rounded
                        :disabled="!isServiceReady"
                        @click="addSecondaryEndpoint">
                        {{ $t('add') }} {{ $t('dualEndpoints.secondaryEndpoint') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text data-auto="service-details.service-endpoints.content">
            <template v-if="primaryEndpoint">
                <ServiceEndpoint
                    class="mb-6"
                    :endpoint="primaryEndpoint"
                    :service="service"
                    :title="$t('dualEndpoints.primaryEndpoint')" />
                <WarningBlock
                    v-if="isPrimaryEndpointNotSetup && !secondaryEndpoint"
                    class="pa-2">
                    <i18n
                        path="dualEndpoints.secureYourService"
                        tag="p">
                        <span
                            place="important"
                            class="font-weight-bold">
                            {{ $t('important') }}
                        </span>
                        <a
                            place="actionText"
                            class="text-decoration-underline"
                            @click="onActionClick">
                            {{ actionText }}</a>
                    </i18n>
                    <WhitelistModal ref="whitelistModal" />
                </WarningBlock>

                <ServiceEndpoint
                    v-if="secondaryEndpoint"
                    :endpoint="secondaryEndpoint"
                    :service="service"
                    :should-remove="true"
                    :title="$t('dualEndpoints.secondaryEndpoint')" />
            </template>

            <v-alert
                v-else
                dense
                outlined
                type="error">
                {{ $t('dualEndpoints.noEndpointsMsg') }}
            </v-alert>
        </v-card-text>
    </v-card>
</template>

<script>
    import { mapActions } from 'vuex'
    import { doPrivateConnectHaveProjects, getIpAllowList, getPrimaryEndpoint, getSecondaryEndpoint, isPrimaryEndpointPrivateServiceConnect, privateConnectLabel } from 'utils/service'
    import ServiceEndpoint from 'components/serviceDetails/ServiceEndpoint.vue'
    import WarningBlock from 'components/service/WarningBlock.vue'
    import WhitelistModal from 'components/service/WhitelistModal.vue'
    import { isServiceStatusReady } from 'utils/service'

    const ServiceEndpoints = {
        name: 'ServiceEndpoints',
        components: {
            ServiceEndpoint,
            WarningBlock,
            WhitelistModal,
        },
        props: {
            service: {
                type: Object,
                default: () => { },
            },
        },
        computed: {
            primaryEndpoint() {
                return getPrimaryEndpoint(this.service.endpoints)
            },
            secondaryEndpoint() {
                return getSecondaryEndpoint(this.service.endpoints)
            },
            isServiceReady() {
                return isServiceStatusReady(this.service)
            },
            actionText() {
                const { endpoints, provider, } = this.service
                let actionText = ''
                if (this.isPrimaryEndpointPrivateServiceConnect(endpoints)) {
                    if (!this.doPrivateConnectHaveProjects(endpoints))
                        actionText = this.$t('dualEndpoints.settingUpPrivateConnect', { privateConnectType: this.privateConnectLabel(provider, true), })
                } else {
                    const allowList = this.getIpAllowList(endpoints)
                    if (!allowList.length) {
                        actionText = this.$t('dualEndpoints.add1IP')
                    }
                }
                return actionText
            },
            isPrimaryEndpointNotSetup() {
                const { endpoints, } = this.service
                if (this.isPrimaryEndpointPrivateServiceConnect(endpoints)) {
                    return !this.doPrivateConnectHaveProjects(endpoints)
                }
                const allowList = this.getIpAllowList(endpoints)
                return !allowList.length
            },
        },
        methods: {
            ...mapActions(['addEndpoint', 'openPrivateConnectModal']),
            async addSecondaryEndpoint() {
                const endpointName = this.$help.getUniqueString(16)
                await this.addEndpoint({serviceId: this.service.id, endpointName,})

            },
            isPrimaryEndpointPrivateServiceConnect,
            doPrivateConnectHaveProjects,
            getIpAllowList,
            privateConnectLabel,
            onActionClick() {
                const { endpoints, id, } = this.service
                if (this.isPrimaryEndpointPrivateServiceConnect(endpoints)) {
                    this.openPrivateConnectModal({ popupState: true, serviceId: id, })
                } else {
                    this.$refs.whitelistModal.open(id)
                }
            },
        },
    }

    export default ServiceEndpoints
</script>
