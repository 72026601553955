<style lang="scss" scoped>
    path {
        fill: currentColor;
    }
</style>

<template>
    <svg
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.41309 1.15723L10.6988 10.4429Z"
            fill="#424F62" />
        <path
            d="M1.41309 1.15723L10.6988 10.4429"
            stroke="#424F62"
            stroke-width="1.5"
            stroke-linecap="round" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.6987 1.15723L1.41302 10.4429Z"
            fill="#424F62" />
        <path
            d="M10.6987 1.15723L1.41302 10.4429"
            stroke="#424F62"
            stroke-width="1.5"
            stroke-linecap="round" />
    </svg>
</template>

<script>
    export default {}
</script>
