<style lang="scss" scoped>
.partner-box {
    width: 150px;
    max-height: 55px;
    border: 0.602261px solid $service-border;
    border-radius: 6.02261px;
}

.selected{
    border: 1px solid $selection-highlight;
    border-radius: 6.02261px;
}


</style>
<template>
    <div
        :class="['partner-box text-center pointer', {'selected' : isSelected}]"
        @click="onClick">
        <slot
            name="icon"></slot>
    </div>
</template>
<script>
    export default {
        name: 'PartnerBox',
        props: {
            isSelected: {
                type: Boolean,
                default: false,
            },
            partner: {
                type: Object,
                required: true,
            },
        },
        methods: {
            onClick() {
                this.$emit('on-click', this.partner)
            },
        },
    }
</script>
