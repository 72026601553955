<template>
    <v-container
        class="ma-0 pa-12"
        fluid>
        <v-col cols="auto pa-0 wrapper-container">
            <div class="mb-4 view-headline color text-navigation font-weight-light">
                {{ $t('alerts') }}
            </div>

            <alerts />
        </v-col>
    </v-container>
</template>

<script>
    import Alerts from 'components/alerts/Alerts'
    export default {
        name: 'AlertsPage',
        components: {
            'alerts' : Alerts,
        },
    }
</script>
