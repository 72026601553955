import organization from 'api/organization'
import { camelize } from 'helpers/camelCase'

export async function listUsers() {
    return organization.get('users').then((response) => response.data)
}

export async function listTeams() {
    return organization.get('teams').then((response) => response.data)
}

export async function getRoles() {
    return organization.get('roles').then((response) => response.data)
}

export async function detachRole(userId, roleIds) {
    return organization.delete(`users/${userId}/roles`, roleIds).then((response) => response.data)
}

export async function attachRole(userId, roleIds) {
    return organization.patch(`users/${userId}/roles`, roleIds).then((response) => response.data)
}

export async function patchUser(userId, userObject) {
    return organization.patch(`users/${userId}`, userObject).then((response) => response.data)
}

export async function getUserById(userId) {
    return organization.get(`users/${userId}`).then((response) => camelize(response.data))
}
