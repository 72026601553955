<template>
    <div>
        <Summary v-show="currentTab === 'summary'" />
        <DescriptiveAnalysis v-if="currentTab === 'descriptive-analysis'" />
        <PredictiveAnalysis v-if="currentTab === 'predictive-analysis'" />
        <PrescriptiveAnalysis v-if="currentTab === 'prescriptive-analysis'" />
    </div>
</template>
<script>
    import Summary from './Summary.vue'
    import DescriptiveAnalysis from './DescriptiveAnalysis.vue'
    import PredictiveAnalysis from './PredictiveAnalysis.vue'
    import PrescriptiveAnalysis from './PrescriptiveAnalysis.vue'
    export default {
        name: 'Content',
        components: { DescriptiveAnalysis, PredictiveAnalysis, PrescriptiveAnalysis, Summary,},
        props: {
            currentTab: {
                type: String,
                default: 'summary',
            },
        },
    }
</script>
