<template>
    <svg
        width="18"
        height="13"
        viewBox="0 0 18 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1697_21128)">
            <path
                d="M-0.5 0H20.5V12.871H-0.5V0Z"
                fill="#D22F27" />
            <path
                d="M9.23746 4.95183C9.57007 4.72896 9.66288 4.15078 9.48133 3.85509C9.29978 3.55973 9.35296 3.13194 9.43256 2.94531C7.48057 3.39817 7.54628 5.57438 8.53938 6.14544C8.41745 5.41823 8.90485 5.1747 9.23746 4.95183V4.95183Z"
                fill="white"
                stroke="white"
                stroke-width="0.5"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M8.81067 4.54566C8.95097 4.54566 9.06471 4.43193 9.06471 4.29163C9.06471 4.15133 8.95097 4.0376 8.81067 4.0376C8.67037 4.0376 8.55664 4.15133 8.55664 4.29163C8.55664 4.43193 8.67037 4.54566 8.81067 4.54566Z"
                fill="#D22F27" />
            <path
                d="M10.5139 6.49615C10.8289 6.74341 11.4074 6.65298 11.6323 6.38912C11.8572 6.12493 12.2802 6.04364 12.4824 6.06159C11.4487 4.34535 9.39917 5.08001 9.16309 6.20114C9.81713 5.86039 10.1992 6.24856 10.5139 6.49615Z"
                fill="white"
                stroke="white"
                stroke-width="0.5"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M10.7682 5.96305C10.8115 6.09648 10.9548 6.16951 11.0883 6.12615C11.2217 6.0828 11.2947 5.93948 11.2514 5.80605C11.208 5.67262 11.0647 5.5996 10.9313 5.64295C10.7978 5.68631 10.7248 5.82962 10.7682 5.96305Z"
                fill="#D22F27" />
            <path
                d="M9.44055 8.18665C9.30269 8.56228 9.56723 9.08491 9.88798 9.217C10.2084 9.3491 10.4167 9.72676 10.4621 9.92457C11.7749 8.41087 10.4428 6.68888 9.30371 6.81081C9.83007 7.32768 9.5784 7.81102 9.44055 8.18665Z"
                fill="white"
                stroke="white"
                stroke-width="0.5"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M10.0271 8.26612C9.9136 8.34858 9.88844 8.50744 9.9709 8.62095C10.0534 8.73445 10.2122 8.75961 10.3257 8.67715C10.4392 8.59468 10.4644 8.43582 10.3819 8.32232C10.2995 8.20881 10.1406 8.18365 10.0271 8.26612Z"
                fill="#D22F27" />
            <path
                d="M7.49934 7.68878C7.09932 7.67354 6.6844 8.08711 6.65764 8.43259C6.63054 8.77841 6.33621 9.09307 6.16211 9.1974C8.0074 9.97846 9.23353 8.17924 8.76543 7.1333C8.43688 7.79345 7.89901 7.70369 7.49934 7.68845V7.68878Z"
                fill="white"
                stroke="white"
                stroke-width="0.5"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M7.60679 8.26875C7.49328 8.18629 7.33442 8.21145 7.25196 8.32495C7.16949 8.43846 7.19465 8.59732 7.30816 8.67979C7.42166 8.76225 7.58052 8.73709 7.66299 8.62359C7.74545 8.51008 7.72029 8.35122 7.60679 8.26875Z"
                fill="#D22F27" />
            <path
                d="M7.37762 5.69007C7.26822 5.30495 6.74695 5.03805 6.40993 5.11934C6.07292 5.20063 5.68272 5.01773 5.52963 4.88428C5.35688 6.88063 7.44706 7.49065 8.29722 6.72245C7.56764 6.61407 7.48703 6.07484 7.37796 5.68973L7.37762 5.69007Z"
                fill="white"
                stroke="white"
                stroke-width="0.5"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M6.85271 5.97155C6.89607 5.83812 6.82305 5.69481 6.68962 5.65145C6.55618 5.6081 6.41287 5.68112 6.36952 5.81455C6.32616 5.94798 6.39918 6.0913 6.53261 6.13465C6.66605 6.17801 6.80936 6.10499 6.85271 5.97155Z"
                fill="#D22F27" />
            <path
                d="M-0.5 0H20.5V12.871H-0.5V0Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
        </g>
        <rect
            x="0.5"
            y="0.5"
            width="17"
            height="11.6844"
            stroke="#D2D2D2" />
        <defs>
            <clipPath id="clip0_1697_21128">
                <rect
                    x="1"
                    y="1"
                    width="16"
                    height="10.6844"
                    fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
    export default {}
</script>
