import { t } from 'typy'
import moment from 'moment'

export function parseRelativeTime (time) {
    if (!t(time).isString) return time

    // Subtract
    const [start, end] = time.split('-')
    const date = start === 'now' ? moment() : moment(start)

    if (end) {
        const [value, measurement] = end.split(' ')
        date.subtract(value, measurement)
    }

    return date.valueOf()
}
