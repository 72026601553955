<style lang="scss" scoped>
:deep(.v-input--radio-group__input) {
    .v-radio:first-child {
        border-radius: 5px 5px 0 0;
    }

    .v-radio:last-child {
        border-radius: 0 0 5px 5px;
    }

    .v-radio {
        border: 1px solid $inner-border;

        width: 100%;
        padding: 10px 5px;
        margin: 0px !important;

        .config-rows {
            span {
                padding: 0 16px !important;
                font-size: 14px;
            }

            span:first-child {
                min-width: 330px;
            }
        }
    }
}

.grayed-out--text {
    color: $grayed-out !important;
}
</style>
<template>
    <base-dialog
        v-model="isDialogOpen"
        :on-save="action"
        :on-cancel="doCancel"
        :min-width="width"
        :max-width="width">
        <template #title>
            <div class="font-weight-light text-h5 pb-6">
                {{ dialogTitle() }}
            </div>
        </template>
        <template #body>
            <div v-if="dialogType === $config.DIALOG_TYPE.DELETE">
                <i18n
                    path="configManager.databaseConfigNotApplied"
                    tag="p">
                    <strong place="configName">{{ configName }}</strong>
                </i18n>
                <strong>{{ $t('configManager.pleaseNote') }}</strong>: {{ $t('configManager.notReversible') }}
            </div>
            <div v-if="dialogType === $config.DIALOG_TYPE.APPLY">
                <div v-if="selectedService">
                    <div v-if="viewConfig">
                        <span class="grayed-out--text">{{ $t('configManager.databaseConfigDetails') }}</span>
                        <config-data-table
                            class="pt-4"
                            :in-edit-mode="false"
                            :configurations="configDetails"
                            :topology-name="topologyNames[0]" />
                    </div>
                    <div v-else>
                        <i18n
                            path="configManager.configsAvailable"
                            tag="p">
                            <span place="topologyName">{{ topologyDisplayName }}</span>
                        </i18n>
                        <v-radio-group
                            v-model="checkedConfigId"
                            class="mt-0"
                            row>
                            <v-radio
                                v-for="(config, index) in filteredConfigs"
                                :key="index"
                                :label="config.name"
                                :value="config.id"
                                hide-details="auto">
                                <template #label>
                                    <div
                                        :key="config.index"
                                        class="config-rows pointer d-flex"
                                        @click="openViewConfig(config)">
                                        <span class="info--text text-body-2 px-2">
                                            {{ config.name }}
                                        </span>
                                        <span class="grayed-out--text text-body-2 px-2">
                                            {{ $t('updated') }} {{ new Date(config.updated_date * 1000) | moment('from', 'now')
                                            }}
                                        </span>
                                        <span class="text-body-2 px-2">
                                            {{ getTopologyName(config.topology_id) }}
                                        </span>
                                        <span class="text-body-2 px-2">
                                            {{ config.public ? $t('configManager.default') : user.name
                                            }}
                                        </span>
                                    </div>
                                </template>
                            </v-radio>
                        </v-radio-group>
                        <div
                            class="color text-links d-flex flex-row-reverse pointer"
                            @click="openConfigurations">
                            {{ $t('configManager.manageConfigs') }}
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div v-if="foundServices.length">
                        <p><strong class="text-uppercase"> {{ topologyDisplayName }}</strong></p>
                        <v-checkbox
                            v-for="(serviceItem, i) in foundServices"
                            :key="`check-${i}`"
                            v-model="checkedServices"
                            color="primary"
                            class="ma-0 pa-0 pr-2"
                            hide-details
                            :label="serviceItem.name"
                            :value="serviceItem.id" />
                    </div>
                    <div
                        v-else
                        class="text-center">
                        <i18n
                            path="configManager.noServiceToApply"
                            tag="p">
                            <span place="topologyName">{{ topologyDisplayName }}</span>
                            <strong place="configName">{{ configName }}</strong>
                        </i18n>
                    </div>
                </div>
            </div>
            <div v-if="dialogType === $config.DIALOG_TYPE.REMOVE">
                <i18n
                    path="configManager.sureToRemove"
                    tag="p">
                    <strong place="configName">{{ configName }}</strong>
                    <span place="serviceName">{{ selectedService.name }}</span>
                </i18n>
            </div>
            <div v-if="dialogType === $config.DIALOG_TYPE.DUPLICATE">
                <label class="text-uppercase text-caption">
                    {{ $t('configManager.configName') }}
                </label>
                <v-text-field
                    v-model="duplicateConfigName"
                    class="config-input"
                    outlined
                    dense
                    min-height="32px"
                    required />
            </div>
        </template>
        <template #actions="{ cancel, save }">
            <v-spacer />
            <v-btn
                color="primary"
                depressed
                rounded
                outlined
                class="cancel"
                @click="cancel">
                <span class="text-none text-body-1 px-3">{{ viewConfig ? $t('close') : $t('cancel') }} </span>
            </v-btn>
            <v-btn
                v-if="!viewConfig"
                :color="[$config.DIALOG_TYPE.APPLY, $config.DIALOG_TYPE.DUPLICATE].includes(dialogType) ? 'primary' : 'error'"
                depressed
                :disabled="disableAction"
                rounded
                @click="save">
                <span class="text-capitalize text-body-1 px-3"> {{ actionText() }}</span>
            </v-btn>
        </template>
    </base-dialog>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import ConfigDataTable from 'components/configurationManager/ConfigDataTable.vue'

    export default {
        name: 'ConfigActionsModal',
        components: {
            'config-data-table': ConfigDataTable,
        },
        props: {
            dialogType: {
                type: String,
                default: '',
                required: true,
            },
            currentConfig: {
                type: Object,
                default: null,
            },
            selectedService: {
                type: Object,
                default: () => { },
            },
            onAction: {
                type: Function,
                default: () => { },
            },
            width: {
                type: Number,
                default: 600,
            },
        },
        data() {
            return {
                isDialogOpen: false,
                foundServices: [],
                checkedServices: [],
                topologyNames: [],
                checkedConfig: null,
                checkedConfigId: '',
                viewConfig: false,
                configDetails: [],
                duplicateConfigName: '',
            }
        },
        computed: {
            ...mapGetters(['services', 'getServiceById', 'allTopologies', 'configs', 'configsLoading', 'configsPerProduct', 'selectedConfig', 'applyToServiceSuccess', 'topologyConfigs', 'selectedValues', 'user']),
            service() {
                return this.getServiceById(this.$route.params.id)
            },
            filteredServices() {
                return this.selectedService || this.currentConfig
            },
            filteredConfigs() {
                let configs = this.configs
                if (this.allTopologies.length > 1 && this.topologyNames) {
                    configs.sort((a, b) => this.$moment(b.updated_on) - this.$moment(a.updated_on))
                    const topology = this.allTopologies.find(topology => this.topologyNames.includes(topology.name))
                    return topology ? configs.filter(config => config.topology_id === topology.id) : []
                } else {
                    return configs
                }
            },
            topologyDisplayName() {
                return this.$help.getTopologyName(this.topologyNames[0])
            },
            configName() {
                return this.currentConfig ? this.currentConfig.name : this.selectedConfig.name
            },
            disableAction() {
                if (this.dialogType !== this.$config.DIALOG_TYPE.APPLY) return false
                else {
                    return this.currentConfig ? false : this.checkedConfigId === this.getSelectedConfigId()
                }
            },
        },
        methods: {
            ...mapActions(['fetchServices', 'getAllTopologies', 'getConfigsList', 'getConfigsPerProduct', 'getSelectedConfig', 'applyToService', 'getTopologyConfigs', 'getSelectedValues']),
            dialogTitle() {
                switch (this.dialogType) {
                    case this.$config.DIALOG_TYPE.APPLY:
                        return this.selectedService ? (this.viewConfig ? this.checkedConfig.name : this.$t('configManager.applyDatabaseConfig')) : this.$t('configManager.applyThisConfig')
                    case this.$config.DIALOG_TYPE.DELETE:
                        return this.$t('configManager.deleteThisConfiguration')
                    case this.$config.DIALOG_TYPE.REMOVE:
                        return this.$t('configManager.removeConfiguration')
                    case this.$config.DIALOG_TYPE.DUPLICATE:
                        return `${this.$t('duplicate')} ${this.configName}`
                }
            },
            actionText() {
                switch (this.dialogType) {
                    case this.$config.DIALOG_TYPE.APPLY:
                        return this.selectedService ? this.$t('configManager.applyDatabaseConfig') : (this.foundServices.length ? this.$t('apply') : this.$t('ok'))
                    case this.$config.DIALOG_TYPE.DELETE:
                        return this.$t('configManager.yesDeleteConfig')
                    case this.$config.DIALOG_TYPE.REMOVE:
                        return this.$t('configManager.yesRemoveConfig')
                    case this.$config.DIALOG_TYPE.DUPLICATE:
                        return this.$t('duplicate')
                }
            },
            open() {
                this.isDialogOpen = true
            },
            doCancel() {
                this.viewConfig ? this.viewConfig = false : this.isDialogOpen = false
            },
            async action() {
                switch (this.dialogType) {
                    case this.$config.DIALOG_TYPE.APPLY:
                        if (this.selectedService) {
                            await this.applyToService({ serviceId: this.selectedService.id, configId: this.checkedConfigId, })
                            if (this.applyToServiceSuccess) {
                                await this.fetchServices()
                                this.$store.commit('showMessage', { text: this.$t('success.applyToService'), type: this.$config.messageType.SUCCESS, })
                            }
                        } else {
                            this.$emit('on-apply', this.checkedServices)
                        }
                        break
                    case this.$config.DIALOG_TYPE.DUPLICATE:
                        this.$emit('on-duplicate', this.duplicateConfigName)
                        break
                    default:
                        await this.onAction()
                }
            },
            openConfigurations() {
                this.$router.push('/settings/configuration-manager/')
            },
            async openViewConfig(checkedConfig) {
                this.configDetails = []
                this.checkedConfig = checkedConfig
                if (checkedConfig.public) {
                    const topologyName = this.allTopologies.find(topology => topology.id === checkedConfig.topology_id).name
                    await this.getTopologyConfigs({ topologyName, })
                    this.configDetails = this.$lodash.cloneDeep(this.topologyConfigs)
                    this.viewConfig = true
                } else {
                    await this.getSelectedConfig({ configId: checkedConfig.id, })
                    if (this.selectedConfig) {
                        const topologyName = this.allTopologies.find(topology => topology.id === this.selectedConfig.topology_id).name
                        await this.getTopologyConfigs({ topologyName, })
                        await this.getSelectedValues({ configId: checkedConfig.id, })
                        this.selectedValues.forEach(valueObj => {
                            let config = this.topologyConfigs.find(config => config.id === valueObj.config_key_id)
                            config['selected_values'] = valueObj.selected_values
                        })
                        this.configDetails = this.$lodash.cloneDeep(this.topologyConfigs)
                        this.viewConfig = true
                    }
                }
            },
            getTopologyName(topologyId) {
                const topology = this.allTopologies.find(topology => topology.id === topologyId)
                return topology ? this.$help.getTopologyName(topology.name) : this.$i18n.t('notApplicable')
            },
            getSelectedConfigId() {
                if (this.selectedService.config_id) return this.selectedService.config_id
                else {
                    // If no config applied then assign default config
                    const foundConfig = this.filteredConfigs.find(config => config.name.includes(this.$t('configManager.default')))
                    return this.$typy(foundConfig, 'id').safeString
                }
            },
        },
        async updated() {
            const { APPLY, DUPLICATE, } = this.$config.DIALOG_TYPE
            if (this.dialogType === APPLY) {
                if (this.currentConfig) {
                    if (!this.topologyNames.length) {
                        // TODO: request to fix API inconsistent behavior
                        if (this.allTopologies.length < 2) await this.getAllTopologies()
                        this.topologyNames = this.allTopologies
                            .filter(topology => topology.id === this.currentConfig.topology_id)
                            .map(topology => topology.name)
                    }
                    await this.fetchServices()
                    // Getting READY services with same topology name as current config
                    // Don't show already applied services again for same config
                    const { public: isPublic, id, } = this.currentConfig
                    this.foundServices = this.services.filter(service => this.topologyNames.includes(service.topology) && service.status === this.$config.serviceStatus.READY &&
                        ((!isPublic && service.config_id !== id) || (isPublic && service.config_id)))
                } else {
                    this.topologyNames = [this.selectedService.topology]
                    if (this.allTopologies.length < 2) await this.getAllTopologies()
                    await this.getConfigsList()
                    this.checkedConfigId = this.getSelectedConfigId()
                }
            }
            if (this.dialogType === DUPLICATE) {
                this.duplicateConfigName = this.$t('configManager.copyOf', { configName: this.configName, })
            }
        },
    }
</script>
