<template>
    <v-container
        class="ma-0 pa-12"
        fluid>
        <v-col cols="auto pa-0 wrapper-container">
            <SettingHeader
                :header-text="routeName"
                class="mb-10" />
            <div v-if="$route.name === $t('configurationManager')">
                <div class="d-flex justify-space-between">
                    <div class="d-flex">
                        <h3 class="font-weight-medium">
                            {{ $t('configManager.savedConfigs') }}
                        </h3>
                        <v-tooltip top>
                            <template #activator="{ on }">
                                <v-icon
                                    size="21"
                                    class="ml-2"
                                    v-on="on">
                                    mdi-help-circle-outline
                                </v-icon>
                            </template>
                            <span>{{ $t('configManager.configManagerAllowsYouTo') }}</span>
                        </v-tooltip>
                    </div>
                </div>
                <br />
                <saved-configurations
                    :loading="configsLoading"
                    :configurations="configs" />
            </div>
            <router-view v-else />
        </v-col>
    </v-container>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import SavedConfigurationsVue from 'components/configurationManager/SavedConfigurations.vue'
    import SettingHeader from '../SettingHeader.vue'

    export default {
        name: 'ConfigurationManager',
        components: {
            'saved-configurations': SavedConfigurationsVue,
            SettingHeader,
        },
        data() {
            return {
                routeName: this.$route.name ? this.$route.name : this.$route.params.configName,
            }
        },
        computed: {
            ...mapGetters(['configs', 'configsLoading', 'services', 'allTopologies']),
        },
        watch: {
            $route(to) {
                this.routeName = to.name ? to.name : to.params.configName
            },
        },
        methods: {
            ...mapActions(['getConfigsList', 'getAllTopologies']),
        },
        async created() {
            await this.getConfigsList()
            if (this.allTopologies.length < 2) await this.getAllTopologies()
        },
    }
</script>
