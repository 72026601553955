import Vue from 'vue'
import store from '../store'

const baseUrl = process.env.VUE_APP_API_GATEWAY_BASE_URL
const provisioningURI = 'notifications/v1'

export default {
    post (uri, body, options) {
        return Vue.axios.post(`${baseUrl}/${provisioningURI}/${uri}`, body, {
            headers: {
                Authorization: `Bearer ${store.getters.authToken}`,
            },
            ...options,
        })
    },
    patch (uri, body, options) {
        return Vue.axios.patch(`${baseUrl}/${provisioningURI}/${uri}`, body, {
            headers: {
                Authorization: `Bearer ${store.getters.authToken}`,
            },
            ...options,
        })
    },
    put (uri, body, options) {
        return Vue.axios.put(`${baseUrl}/${provisioningURI}/${uri}`, body, {
            headers: {
                Authorization: `Bearer ${store.getters.authToken}`,
            },
            ...options,
        })
    },
    get (uri, params, options) {
        return Vue.axios.get(`${baseUrl}/${provisioningURI}/${uri}`, {
            params,
            headers: {
                Authorization: `Bearer ${store.getters.authToken}`,
            },
            ...options,
        })
    },
    delete (uri, params) {
        return Vue.axios.delete(`${baseUrl}/${provisioningURI}/${uri}`, {
            params,
            headers: {
                Authorization: `Bearer ${store.getters.authToken}`,
            },
        })
    },
}
