<style lang="scss" scoped>
    path {
        fill: currentColor;
    }
    circle {
        stroke: currentColor;
    }
</style>
<template>
    <svg
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle
            cx="8"
            cy="8"
            r="7.5"
            stroke="#979797" />
        <path
            d="M10.9176 7.50465L7.07565 5.2856C6.98875 5.23543 6.89018 5.20903 6.78984 5.20905C6.68951 5.20906 6.59094 5.2355 6.50406 5.28569C6.41718 5.33589 6.34505 5.40808 6.29493 5.495C6.2448 5.58192 6.21844 5.6805 6.21851 5.78084V10.2189C6.21844 10.3193 6.2448 10.4179 6.29493 10.5048C6.34505 10.5917 6.41718 10.6639 6.50406 10.7141C6.59094 10.7643 6.68951 10.7907 6.78984 10.7907C6.89018 10.7907 6.98875 10.7643 7.07565 10.7142L10.9176 8.49512C11.0046 8.44502 11.0769 8.37286 11.1272 8.28592C11.1774 8.19898 11.2039 8.10032 11.2039 7.99989C11.2039 7.89946 11.1774 7.8008 11.1272 7.71386C11.0769 7.62691 11.0046 7.55475 10.9176 7.50465V7.50465Z"
            fill="#979797" />
    </svg>
</template>

<script>
    export default {}
</script>
