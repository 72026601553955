export default Object.freeze({
    CONFIG_MANAGER_FLAGS: {
        REQUIRES_RESTART: 1 << 0,
        RANGE: 1 << 1,
        MULTISELECT: 1 << 2,
        READONLY: 1 << 3,
        SI_UNITS: 1 << 4,
        FREETEXT: 1 << 5,
    },
    OPERATOR_GENERATED: 'OPERATOR_GENERATED',
})
