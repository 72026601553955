<style lang="scss" scoped>
    .custom-tag {
        width: 100%;
    }
</style>
<template>
    <v-text-field
        v-model.trim="customTag"
        class="std custom-tag v-text-field--with-bottom-details"
        :placeholder="$t('labelSearchPlaceholder')"
        height="36"
        single-line
        outlined
        :rules="rules.customTag"
        hide-details="auto"
        @blur="addCustomTag"
        @keydown.enter="addCustomTag" />
</template>
<script>
    export default {
        name: 'CustomTagInput',
        props: {
            selectedTags: { type: Array, required: true, },
        },
        data () {
            return {
                customTag: null,
                rules: {
                    customTag: [(val) => this.validateCustomTag(val)],
                },
            }
        },

        methods: {
            addCustomTag () {
                let validStr = this.customTag
                if (this.$typy(validStr).isTruthy && this.validateCustomTag(this.customTag) === true) {
                    this.$emit('add-custom-tag', this.customTag)
                    this.customTag = ''
                }
            },
            validateCustomTag (v) {
                if (!v) return true
                else {
                    const regex = RegExp(/^[A-Za-z\d\s]*$/g)
                    if (this.selectedTags.includes(v)) return this.$t('errors.duplicatedValue', { v, })
                    else if (!regex.test(v)) return this.$t('errors.customTag')
                    return true
                }
            },
        },
    }
</script>
