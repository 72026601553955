
<style lang="scss" scoped>
    path {
        fill: currentColor;
    }
</style>

<template>
    <svg viewBox="0 0 18 12">
        <path
            d="M0.379452 -0.350641H17.6197L9.42328 6.98377C9.22428 7.1619 8.77489 7.1619 8.57588
            6.98377L0.379452 -0.350641Z" />
        <path d="M-0.351068 0.248385L5.46387 5.457L-0.351068 10.724V0.248385Z" />
        <path
            d="M6.16517 6.08522L7.94763 7.67778C8.54222 8.21002 9.45695 8.21002 10.0515 7.67778L11.834
            6.08522L17.6343 11.3377H0.364841L6.16517 6.08522Z" />
        <path d="M18.3502 0.248385V10.724L12.5353 5.457L18.3502 0.248385Z" />
    </svg>
</template>

<script>
    export default {}
</script>
