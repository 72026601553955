export function xpandClusterTotalNodes ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `mariadb_xpand_cluster_total_nodes{mariadb=~"${name}", kubernetes_namespace=~"${id}"}`
    }
}

export function xpandClusterNodesQuorum ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `mariadb_xpand_cluster_nodes_in_quorum{mariadb=~"${name}", kubernetes_namespace=~"${id}"}`
    }
}
export function xpandClusterUpTimeSeconds ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `max(mariadb_xpand_cluster_uptime_seconds{mariadb=~"${name}", kubernetes_namespace=~"${id}"})`
    }
}
export function xpandRebalancerUnderprotectedSlices ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(mariadb_xpand_rebalancer_underprotected_slices{mariadb=~"${name}", kubernetes_namespace=~"${id}"})`
    }
}
export function xpandRebalancerJobsQueued ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(mariadb_xpand_rebalancer_jobs_queued{mariadb=~"${name}", kubernetes_namespace=~"${id}"})`
    }
}

export function xpandRebalancerRebalancerRebalance ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(increase(mariadb_xpand_rebalancer_rebalancer_rebalance{mariadb=~"${name}", kubernetes_namespace=~"${id}"}[300s]))`
    }
}
export function xpandRebalancerRebalancerReprotects ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(increase(mariadb_xpand_rebalancer_rebalancer_reprotects{mariadb=~"${name}", kubernetes_namespace=~"${id}"}[300s]))`
    }
}
export function xpandRebalancerRebalancerReranks ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(increase(mariadb_xpand_rebalancer_rebalancer_reranks{mariadb=~"${name}", kubernetes_namespace=~"${id}"}[300s]))`
    }
}
export function xpandRebalancerRebalancerSplits ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(increase(mariadb_xpand_rebalancer_rebalancer_splits{mariadb=~"${name}", kubernetes_namespace=~"${id}"}[300s]))`
    }
}

export function xpandRebalancerRebalancerSoftfailReprotects ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(increase(mariadb_xpand_rebalancer_rebalancer_softfail_reprotects{mariadb=~"${name}", kubernetes_namespace=~"${id}"}[300s]))`
    }
}

export function xpandWalsAvgSyncTimeSeconds ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `max(mariadb_xpand_wals_avg_sync_time_seconds{mariadb=~"${name}", kubernetes_namespace=~"${id}"} * 1000)`
    }
}
