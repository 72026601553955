<style lang="scss" scoped>
    path {
        fill: currentColor;
    }
</style>

<template>
    <svg
        viewBox="0 0 23 23"
        fill="none">
        <path
            d="m 11.271915,0.00433334 c -0.807202,-0.020635 -1.6176111,0.046853 -2.4177241,0.2060045 C 6.7205224,0.63475276 4.7601766,1.6828991
        3.2218895,3.2211742 1.6836143,4.7594613 0.63546799,6.7198071 0.21105309,8.8534756 c -0.4244017,2.1337104 -0.2054389,4.3445734
        0.627069,6.3544494 0.83252031,2.009877 2.24108231,3.728701 4.04991441,4.937319 1.808832,1.208618 3.9367284,1.854041 6.1122245,
        1.854041 2.915206,-0.0054 5.710189,-1.16665 7.771579,-3.22816 C 20.833226,16.709739 21.994406,13.914858 22,10.999545 22,8.8240837
        21.354699,6.6984212 20.145959,4.889585 18.937346,3.0807602 17.218521,1.6699288 15.20864,0.83740689 14.203701,0.42115214 13.148691,
        0.15960684 12.077824,0.05413664 c -0.26772,-0.026367 -0.536841,-0.042925 -0.805909,-0.0498033 z M 11.000261,1.600303 c 2.4918,
        0.00458 4.879957,0.9952776 6.64195,2.7572921 1.761998,1.7620319 2.752681,4.1500765 2.757292,6.6419499 0,1.859224 -0.551764,3.67671
        -1.58465,5.222556 -1.03301,1.545858 -2.499786,2.749915 -4.217435,3.461331 -1.717647,0.711525 -3.607359,0.899143 -5.4308241,0.536517
        C 7.3431488,19.857212 5.6684005,18.960686 4.3537828,17.646023 3.0391483,16.331355 2.1425703,14.65656 1.7798574,12.833212 1.417155,
        11.00975 1.6049021,9.1200313 2.3163748,7.4023883 3.0278481,5.6847441 4.231872,4.2155875 5.7777047,3.1826899 7.3235491,2.1497919
        9.1410619,1.600303 11.000261,1.600303 Z M 9.1937589,7.2258129 c -0.202893,0 -0.397588,0.080658 -0.541045,0.2241149 -0.143468,
        0.1434683 -0.2218512,0.3381634 -0.2218512,0.5410451 v 6.0171451 c 0.00796,0.195533 0.092174,0.380703 0.2354342,0.513879 0.143271,
        0.133292 0.331917,0.203209 0.527462,0.19695 0.195719,0.0068 0.386304,-0.06354 0.529726,-0.19695 0.143421,-0.133408 0.228132,
        -0.318114 0.235433,-0.513879 V 7.9909729 c 0,-0.2028817 -0.08065,-0.3975768 -0.224114,-0.5410451 C 9.5913359,7.3064711 9.3966409,
        7.2258129 9.1937589,7.2258129 Z m 3.6107401,0 c -0.202951,0 -0.397669,0.080658 -0.541045,0.2241149 -0.143491,0.1434683 -0.221851,
        0.3381634 -0.221851,0.5410451 v 6.0171451 c 0.0073,0.195765 0.08979,0.380473 0.233169,0.513879 0.143492,0.133408 0.333962,0.203788
        0.529727,0.19695 0.195533,0.0063 0.386466,-0.06366 0.529726,-0.19695 0.14326,-0.133176 0.227436,-0.318346 0.235434,-0.513879
        V 7.9909729 c 0,-0.2028817 -0.08074,-0.3975768 -0.224115,-0.5410451 -0.143491,-0.1434567 -0.33821,-0.2241149 -0.541045,-0.2241149 z" />
    </svg>
</template>

<script>
    export default {}
</script>
