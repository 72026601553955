<style lang="scss" scoped>
#serviceStatusStrip.text-caption {
    letter-spacing: normal !important;
}

.highlight-auto {
    color: $links;
    text-decoration: underline;
}

// https://stackoverflow.com/a/60552591
// To allow click events inside tooltip
.v-tooltip__content {
    pointer-events: initial;
}
</style>

<template>
    <div
        id="serviceStatusStrip"
        class="d-flex pt-2 text-caption">
        <div
            v-if="!isServiceServerLess"
            class="mr-2 letter-spacing-none">
            <status-tooltip
                :topology="service"
                :servers="service.servers || []"
                :nudge-left="20">
                <icon-sprite-sheet
                    :size="20"
                    :frame="serviceStatus">
                    health
                </icon-sprite-sheet>
                {{ getStatusLocale(serviceStatus) }}
            </status-tooltip>
        </div>
        <div
            v-else
            class="mr-2 letter-spacing-none">
            <icon-sprite-sheet
                :size="20"
                :frame="service.status">
                health
            </icon-sprite-sheet>
            {{ getStatusLocale(service.status) }}
        </div>
        <div class="mr-2">
            <cloud-provider
                :size="18"
                :provider="service.provider">
                cloud
            </cloud-provider>
            {{ topologyName }}
            <TechPreview
                v-if="isTopologyTechPreview"
                class="pt-0 pb-0" />
        </div>
        <div class="mr-2">
            <v-icon
                size="21"
                color="primary">
                $vuetify.icons.mapMarker
            </v-icon>
            {{ service.data_center || service.region }}
            <v-tooltip
                v-if="hasSingleAvailabilityZone"
                open-delay="400"
                close-delay="2000"
                :max-width="250"
                open-on-hover
                color="rgba(0, 53, 69, 0.9)"
                top>
                <template #activator="{ on }">
                    <span v-on="on">
                        ({{ $t('launchService.singleZone') }})
                    </span>
                </template>
                <div
                    class="text-caption">
                    <p>{{ $t('singleZoneInfo') }}</p>
                    <a
                        v-if="singleZoneMoreInfoLink"
                        rel="noopener noreferrer"
                        class="text-capitalize"
                        target="_blank"
                        :href="singleZoneMoreInfoLink">{{
                            $t('moreInfo')
                        }}</a>
                </div>
            </v-tooltip>
        </div>
        <div class="mr-2">
            <v-tooltip
                color="rgba(0, 53, 69, 0.9)"
                open-delay="1000"
                top>
                <template #activator="{ on }">
                    <div v-on="on">
                        <v-icon
                            size="21"
                            color="primary">
                            mdi-clock-outline
                        </v-icon>
                        <span>{{ new Date(service.created_on * 1000) | moment('from', 'now') }}</span>
                    </div>
                </template>
                <div
                    class="text-caption">
                    <span>{{ $moment(service.created_on * 1000).format('MMM D, YYYY, h:mm A z') }}</span>
                </div>
            </v-tooltip>
        </div>
        <div
            v-if="!isRemoteService && currentTier !== service.tier"
            class="mr-2">
            <tier-badge :tier-name="service.tier" />
        </div>
        <div v-if="autonomousPolicies.length">
            <v-tooltip
                open-delay="400"
                close-delay="2000"
                open-on-hover
                :disabled="!isServiceReady"
                color="rgba(0, 53, 69, 0.9)"
                top>
                <template #activator="{ on }">
                    <div v-on="on">
                        <v-icon>
                            $vuetify.icons.autonomous
                        </v-icon>
                    </div>
                </template>
                <div
                    class="text-caption"
                    @click="openAutonomousModal">
                    <a>{{ $t('autonomousActions') }}</a> {{ $t('enabled') }}:
                    <ul
                        v-for="(scale, index) in autoLabels"
                        :key="index">
                        <li>{{ scale }}</li>
                    </ul>
                </div>
            </v-tooltip>
        </div>
        <DualEndpointStatus
            v-if="$unleash.isFeatureEnabled('enable-portal-dual-endpoints') && !isRemoteService"
            :service="service" />
        <div v-else-if="havePrivateConnectProjects">
            <v-tooltip
                open-delay="400"
                close-delay="2000"
                open-on-hover
                :max-width="200"
                :disabled="!isServiceReady"
                color="rgba(0, 53, 69, 0.9)"
                top>
                <template #activator="{ on }">
                    <div v-on="on">
                        <v-icon
                            size="20"
                            class="ml-2">
                            $vuetify.icons.privateConnect
                        </v-icon>
                        <span class="ml-2">{{ privateConnectionText }}</span>
                    </div>
                </template>
                <div class="text-caption">
                    <p>{{ $t('privateConnect.serviceIsSecuredNote', { connectionName: privateConnectionText }) }}</p>
                    <a
                        rel="noopener noreferrer"
                        class="text-capitalize"
                        target="_blank"
                        :href="privateConnectLearnMoreLink">{{
                            $t('learn-more')
                        }}</a>
                </div>
            </v-tooltip>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash'
    import CloudProvider from 'components/service/CloudProvider'
    import StatusTooltip from 'components/service/StatusTooltip'
    import TierBadge from 'components/service/TierBadge'
    import DualEndpointStatus from 'components/service/DualEndpointStatus'
    import i18n from 'plugins/i18n'
    import autonomousModal from 'mixins/autonomousModal'
    import {
        doPrivateConnectHaveProjects,
        isServerlessAnalytics,
        privateConnectLabel,
        getPrivateConnectInstructionLink,
        isOnPremService,
        isStandAloneTopology,
        isPrimaryReplica
    } from 'utils/service'
    import TechPreview from 'components/common/TechPreview.vue'

    const ServiceStatusStrip = {
        name: 'ServiceStatusStrip',
        components: {
            CloudProvider,
            StatusTooltip,
            TierBadge,
            TechPreview,
            DualEndpointStatus,
        },
        mixins: [autonomousModal],
        props: {
            serviceId: {
                required: true,
                type: String,
            },
            autonomousPolicies: {
                type: Array,
                default: () => [],
            },
            currentTier: {
                type: String,
                required: true,
            },
        },
        computed: {
            service() {
                return this.getServiceById(this.serviceId)
            },
            topologyName() {
                const { topology, serverType, } = this.service
                if (isStandAloneTopology(topology) || isPrimaryReplica(topology)) {
                    return this.$help.getTopologyName(serverType || topology, true)
                }
                return this.$help.getTopologyName(topology, true)
            },
            isServiceServerLess() {
                return isServerlessAnalytics(this.service.topology)
            },
            serviceStatus() {
                const { status, topologyStatus, } = this.service

                if (topologyStatus && status === this.$config.serviceStatus.READY) return topologyStatus

                return status
            },
            autoLabels() {
                return this.autonomousPolicies.map(({ group, }) => i18n.t(`autoScale.${group}`))
            },
            isServiceReady() {
                const { status, } = this.service
                return status === this.$config.serviceStatus.READY
            },
            havePrivateConnectProjects() {
                return doPrivateConnectHaveProjects(this.service.endpoints)
            },
            privateConnectionText() {
                return privateConnectLabel(this.service.provider)
            },
            isTopologyTechPreview() {
                const { topology, } = this.service
                return isServerlessAnalytics(topology)
            },
            privateConnectLearnMoreLink() {
                return getPrivateConnectInstructionLink(this.service.provider)
            },
            isRemoteService(){
                return isOnPremService(this.service.provider)
            },
            hasSingleAvailabilityZone(){
                return this.$unleash.isFeatureEnabled('enable-portal-single-zone') && this.$typy(this.service, 'availability_zone').isDefined && this.service.availability_zone
            },
            singleZoneMoreInfoLink(){
                // TODO:: Link to come from Marketing
                return ''
            },
        },
        methods: {
            getStatusLocale(status) {
                return i18n.t(`Service.${_.camelCase(status)}`)
            },
            openAutonomousModal() {
                this.fetchAutoScaleDependencies()
                this.openAutonomousDialog({ popupState: true, serviceId: this.serviceId, })
            },
        },
    }
    export default ServiceStatusStrip
</script>
