<style lang="scss" scoped>
.table-wrapper {

    width: 100%;

    .tableList {
        width: 100%;
        table-layout: fixed;
    }

    table,
    th,
    td {
        border: 1px solid black;
        border-collapse: collapse;
    }

    .tableList th,
    .tableList td {
        padding: 10px;
    }
}
</style>

<template>
    <div class="pa-4 table-wrapper">
        <table class="tableList">
            <thead>
                <tr>
                    <th
                        v-for="(thead, i) in tableHeader"
                        :key="i"
                        scope="col"
                        class="text-left">
                        {{ thead }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(row, i) in tabularData"
                    :key="i">
                    <td
                        v-for="(cell, j) in row"
                        :key="`${i}${j}`">
                        {{ cell }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        name: 'SlowQueryDetails',
        props: {
            data: {
                type: Array,
                required: true,
            },
        },
        computed: {
            tableHeader() {
                return this.getCells(this.data[0])
            },
            tabularData() {
                let tabularData = []
                for (let i = 1; i < this.data.length; i++) {
                    tabularData.push(this.getCells(this.data[i]))
                }
                return tabularData
            },
        },
        methods: {
            getCells(line) {
                return line.split('\t')
            },
        },
    }
</script>
