export function serviceReadOnlyStatus ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(mariadb_global_variables_read_only{mariadb="${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}

export function serviceUpStatus ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(mariadb_up{mariadb="${name}", kubernetes_namespace="${id}"}) by (kubernetes_pod_name)`
    }
}

export function serviceReplicaStatus ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(mariadb_slave_status_slave_sql_running{mariadb="${name}", kubernetes_namespace="${id}"} + mariadb_slave_status_slave_io_running{mariadb="${name}", kubernetes_namespace="${id}", master_host!=""}) by (kubernetes_pod_name) or sum(mariadb_up{mariadb="${name}", kubernetes_namespace="${id}"} * 0 - 1) by (kubernetes_pod_name)`
    }
}

export function serviceReplicaSQLRunning ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(mariadb_slave_status_slave_sql_running{mariadb="${name}", kubernetes_namespace="${id}", master_host!=""}) by (kubernetes_pod_name) or sum(mariadb_up{mariadb="${name}", kubernetes_namespace="${id}"} * 0 - 1) by (kubernetes_pod_name)`
    }
}

export function serviceReplicaIORunning ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(mariadb_slave_status_slave_io_running{mariadb="${name}", kubernetes_namespace="${id}", master_host!=""}) by (kubernetes_pod_name) or sum(mariadb_up{mariadb="${name}", kubernetes_namespace="${id}"} * 0 - 1) by (kubernetes_pod_name)`
    }
}

export function serviceReplicaSQLErrors ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(mariadb_slave_status_last_sql_errno{mariadb="${name}", kubernetes_namespace="${id}", master_host!=""}) by (kubernetes_pod_name) or sum(mariadb_up{mariadb="${name}", kubernetes_namespace="${id}"} * 0 - 1) by (kubernetes_pod_name)`
    }
}

export function serviceReplicaIOErrors ({ name, id, } = {}, datasource) {
    switch (datasource) {
    default:
        return `sum(mariadb_slave_status_last_io_errno{mariadb="${name}", kubernetes_namespace="${id}", master_host!=""}) by (kubernetes_pod_name) or sum(mariadb_up{mariadb="${name}", kubernetes_namespace="${id}"} * 0 - 1) by (kubernetes_pod_name)`
    }
}
