import ConfigurationManager from 'pages/settings/configurationManager/ConfigurationManager'
import NewDatabaseConfiguration from 'pages/settings/configurationManager/NewDatabaseConfiguration'
import ConfigurationDetails from 'pages/settings/configurationManager/ConfigurationDetails'

export default [
    {
        path: '/settings/configuration-manager',
        name: 'Configuration Manager',
        component: ConfigurationManager,
        meta: {
            requiresAuth: true,
            title: 'Configuration Manager',
        },
        children: [
            {
                path: 'new-database-configuration',
                name: 'New Database Configuration',
                component: NewDatabaseConfiguration,
                meta: {
                    title: 'New Database Configuration',
                },
            },
            {
                path: ':configName',
                component: ConfigurationDetails,
            }
        ],
    }
]
