<style lang="scss" scoped>
.start-option {
    width: 230px;
    border: 1px solid $service-border;
    border-radius: 8px;
}
.v-tooltip__content {
    background-color: $dark-charcoal;
}
</style>

<template>
    <div class="text-center color text-navigation">
        <h3 class="font-weight-regular">
            {{ $t('dashboardPage.welcomeMsg') }}
        </h3>
        <h2 class="my-2">
            {{ $t('dashboardPage.welcomeQuestion') }}
        </h2>

        <div class="d-flex justify-center mt-12">
            <v-tooltip
                bottom
                max-width="300">
                <template #activator="{ on }">
                    <div
                        class="start-option pa-4 pointer"
                        data-auto="launch-service-btn"
                        @click="launchService"
                        v-on="on">
                        <v-icon size="130">
                            $vuetify.icons.dbLaunch
                        </v-icon>
                        <div class="mt-4 font-weight-bold">
                            {{ $t('dashboardPage.welcomeOptionLaunch') }}
                        </div>
                    </div>
                </template>
                <span>{{ $t('dashboardPage.deployCloud') }}</span>
            </v-tooltip>
            <v-tooltip
                v-if="$unleash.isFeatureEnabled('enable-portal-remote-monitoring')"
                bottom
                max-width="300">
                <template #activator="{ on }">
                    <div
                        class="start-option pa-4 ml-4 pointer"
                        @click="monitorDb"
                        v-on="on">
                        <v-icon size="130">
                            $vuetify.icons.dbMonitor
                        </v-icon>
                        <div class="mt-4 font-weight-bold">
                            {{ $t('dashboardPage.welcomeOptionMonitor') }}
                        </div>
                    </div>
                </template>
                <span>{{ $t('dashboardPage.monitorMariDB') }}</span>
            </v-tooltip>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'WelcomeOptions',
        methods: {
            launchService() {
                this.$router.push({ name: 'Launch Service', })
            },
            monitorDb() {
                this.$emit('monitor-db')
            },
        },
    }
</script>
