const coords = [
    {
        'region': 'us-east-1',
        'country': 'Northern Virginia, USA',
        'x': 153,
        'y': 152,
    },
    {
        'region': 'us-east-2',
        'country': 'Ohio, USA',
        'x': 157,
        'y': 144,
    },
    {
        'region': 'us-west-1',
        'country': 'N. California, USA',
        'x': 108,
        'y': 146,
    },
    {
        'region': 'us-west-2',
        'country': 'Oregon, USA',
        'x': 107,
        'y': 137,
    },
    {
        'region': 'us-central1',
        'country': 'Iowa, USA',
        'x': 139,
        'y': 142,
    },
    {
        'region': 'us-east1',
        'country': 'South Carolina, USA',
        'x': 157,
        'y': 148,
    },
    {
        'region': 'us-east4',
        'country': 'Virginia, USA',
        'x': 159,
        'y': 146,
    },
    {
        'region': 'us-west1',
        'country': 'Oregon, USA',
        'x': 107,
        'y': 137,
    },
    {
        'region': 'us-west2',
        'country': 'California, USA',
        'x': 108,
        'y': 149,
    },
    {
        'region': 'us-west3',
        'country': 'Utah, USA',
        'x': 118,
        'y': 148,
    },
    {
        'region': 'us-west4',
        'country': 'Nevada, USA',
        'x': 115,
        'y': 148,
    },
    {
        'region': 'af-south-1',
        'country': 'Cape Town, Africa',
        'x': 272,
        'y': 234,
    },
    {
        'region': 'ap-east-1',
        'country': 'Hong Kong, China',
        'x': 382,
        'y': 167,
    },
    {
        'region': 'asia-east2',
        'country': 'Hong Kong, China',
        'x': 382,
        'y': 167,
    },
    {
        'region': 'ap-southeast-3',
        'country': 'Jakarta, Indonesia',
        'x': 380,
        'y': 207,
    },
    {
        'region': 'ap-south-1',
        'country': 'Mumbai, India',
        'x': 338,
        'y': 175,
    },
    {
        'region': 'asia-south1',
        'country': 'Mumbai, India',
        'x': 338,
        'y': 175,
    },
    {
        'region': 'ap-northeast-3',
        'country': 'Osaka, Japan',
        'x': 408,
        'y': 154,
    },
    {
        'region': 'asia-northeast1',
        'country': 'Tokyo, Japan',
        'x': 413,
        'y': 152,
    },
    {
        'region': 'asia-northeast2',
        'country': 'Osaka, Japan',
        'x': 408,
        'y': 154,
    },
    {
        'region': 'ap-northeast-1',
        'country': 'Tokyo, Japan',
        'x': 413,
        'y': 152,
    },
    {
        'region': 'ap-northeast-2',
        'country': 'Seoul, South Korea',
        'x': 398,
        'y': 150,
    },
    {
        'region': 'asia-northeast3',
        'country': 'Seoul, South Korea',
        'x': 398,
        'y': 150,
    },
    {
        'region': 'ap-southeast-1',
        'country': 'Jurong West, Singapore',
        'x': 369,
        'y': 194,
    },
    {
        'region': 'asia-southeast1',
        'country': 'Jurong West, Singapore',
        'x': 369,
        'y': 194,
    },
    {
        'region': 'asia-southeast2',
        'country': 'Jakarta, Indonesia',
        'x': 380,
        'y': 207,
    },
    {
        'region': 'ap-southeast-2',
        'country': 'Sydney, Australia',
        'x': 423,
        'y': 237,
    },
    {
        'region': 'australia-southeast1',
        'country': 'Sydney, Australia',
        'x': 423,
        'y': 237,
    },
    {
        'region': 'europe-north1',
        'country': 'Hamina, Finland',
        'x': 281,
        'y': 112,
    },
    {
        'region': 'ca-central-1',
        'country': 'Montréal, Québec, Canada',
        'x': 166,
        'y': 135,
    },
    {
        'region': 'northamerica-northeast1',
        'country': 'Montréal, Québec, Canada',
        'x': 166,
        'y': 135,
    },
    {
        'region': 'eu-central-1',
        'country': 'Frankfurt, Germany',
        'x': 257,
        'y': 132,
    },
    {
        'region': 'europe-west3',
        'country': 'Frankfurt, Germany',
        'x': 257,
        'y': 132,
    },
    {
        'region': 'eu-west-1',
        'country': 'Dublin, Ireland',
        'x': 239,
        'y': 128,
    },
    {
        'region': 'europe-west1',
        'country': 'St. Ghislain, Belgium',
        'x': 255,
        'y': 131,
    },
    {
        'region': 'eu-west-2',
        'country': 'London, UK',
        'x': 247,
        'y': 128,
    },
    {
        'region': 'europe-west2',
        'country': 'London, UK',
        'x': 247,
        'y': 128,
    },
    {
        'region': 'europe-west4',
        'country': 'Eemshaven, Netherlands',
        'x': 258,
        'y': 127,
    },
    {
        'region': 'europe-west6',
        'country': 'Zurich, Switzerland',
        'x': 259,
        'y': 135,
    },
    {
        'region': 'eu-south-1',
        'country': 'Milan, Italy',
        'x': 262,
        'y': 137,
    },
    {
        'region': 'eu-west-3',
        'country': 'Paris, France',
        'x': 254,
        'y': 134,
    },
    {
        'region': 'eu-north-1',
        'country': 'Stockholm, Sweden',
        'x': 269,
        'y': 116,
    },
    {
        'region': 'me-south-1',
        'country': 'Bahrain',
        'x': 308,
        'y': 165,
    },
    {
        'region': 'sa-east-1',
        'country': 'São Paulo, Brazil',
        'x': 194,
        'y': 221,
    },
    {
        'region': 'southamerica-east1',
        'country': 'São Paulo, Brazil',
        'x': 194,
        'y': 221,
    },
    {
        'region': 'asia-east1',
        'country': 'Changhua County, Taiwan',
        'x': 393,
        'y': 167,
    }
]

export { coords }
