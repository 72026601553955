import Unauthorized from 'pages/Unauthorized'

export default {
    path: '/unauthorized',
    name: 'unauthorized',
    component: Unauthorized,
    meta: {
        guest: true,
        title: 'Unauthorized',
    },
}
