<style lang="scss" scoped>
.tier-banner {
    min-height: 20px;

    .tier-badge {
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        background: $prelude;

        span {
            line-height: 20px;
            color: $azure;
        }
    }
}

.tier-subtitle {
    color: $grayed-out
}

.disable-feature {
    opacity: 0.5;
    border: 1.73941px solid $grayed-out;
    min-height: 450px;
}

.tier-title {
    color: $azure
}

.selected-tier {
    border: 1.73941px solid $selection-highlight;
}
</style>
<template>
    <v-card :class="[isDisabled && 'disable-feature', isSelected && 'selected-tier', 'rounded-lg']">
        <div class="text-center">
            <div class="tier-banner">
                <span
                    v-if="showCurrentTierBanner"
                    class="tier-badge py-0 px-2 rounded">
                    <span class="text-uppercase"> {{ $t('tierUpgrade.yourTier') }}</span>
                </span>
            </div>
            <v-card-title class="tier-title text-uppercase py-0 justify-center">
                {{ title }}
            </v-card-title>
            <v-card-subtitle class="mt-0 text-uppercase tier-subtitle">
                {{ $t('tierUpgrade.tier') }}
            </v-card-subtitle>
        </div>

        <v-card-text>
            <div class="px-8">
                <div>
                    {{ description }}
                </div>
                <div class="mt-5">
                    <FeatureLine
                        v-for="(feature, i) in features"
                        :key="i"
                        :text="feature" />
                </div>
            </div>

            <div
                v-if="hasAction"
                class="text-center my-8">
                <v-btn
                    color="primary"
                    depressed
                    rounded
                    @click="triggerAction">
                    <span class="text-none text-body-1 px-3"> Request </span>
                </v-btn>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
    import FeatureLine from './FeatureLine.vue'
    export default {
        name: 'TierFeatureCard',
        components: { FeatureLine, },
        props: {
            isSelected: {
                type: Boolean,
                default: false,
            },
            isDisabled: {
                type: Boolean,
                default: false,
            },
            showCurrentTierBanner: {
                type: Boolean,
                default: false,
            },
            title: {
                type: String,
                required: true,
            },
            description: {
                type: String,
                required: true,
            },
            features: {
                type: Array,
                default: () => [],
            },
            hasAction: {
                type: Boolean,
                default: false,
            },
        },
        methods: {
            triggerAction() {
                this.$emit('on-action')
            },
        },
    }
</script>
