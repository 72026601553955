/* eslint-disable camelcase */
import { CONFIG } from 'config/'
import i18n from 'plugins/i18n'
import { t } from 'typy'

export function isStandAloneTopology(topology) {
    return CONFIG.STANDALONE_TOPOLOGY.includes(topology)
}

export function isPostgresStandAlone(topology) {
    return CONFIG.POSTGRES_STANDALONE.includes(topology)
}

export function isSingleNodeAnalytics(topology, replicas) {
    return CONFIG.COLUMNSTORE.includes(topology) && replicas === 1
}

export function isColumnstore(topology) {
    return CONFIG.COLUMNSTORE.includes(topology)
}

export function isXpand(topology) {
    return CONFIG.XPAND.includes(topology)
}

export function isXgres(topology) {
    return CONFIG.XGRES.includes(topology)
}

export function isPrimaryReplica(topology) {
    return CONFIG.MASTERSLAVE.includes(topology)
}

export function isServerlessAnalytics(topology) {
    return CONFIG.SERVERLESS.includes(topology)
}

export function isMultiNodeTopology(topology) {
    return (
        isPrimaryReplica(topology) ||
        isXpand(topology) ||
        isColumnstore(topology)
    )
}

export function getNodeTypeForPricingAPI(topology) {
    return isMultiNodeTopology(topology) ? 'multi' : 'single'
}

export function isSingleNodeTopology(topology = '', nodes = 0) {
    return !nodes || isStandAloneTopology(topology)
}

export function isProviderGCP(provider) {
    return CONFIG.cloudProvider.GCP === provider
}

export function isProviderAWS(provider) {
    return CONFIG.cloudProvider.AWS === provider
}

export function isPrivateConnectOpted(endpoints = []) {
    if (!endpoints.length) return false
    return Boolean(getPrivateConnectObject(endpoints))
}

export function doPrivateConnectHaveProjects(endpoints = []) {
    if (!endpoints.length) return false
    const privateConnect = getPrivateConnectObject(endpoints)
    return Boolean(t(privateConnect, 'allowed_accounts').safeArray.length)
}

export function privateConnectLabel(provider, isDualEP = false) {
    // TODO:: Remove this if condition when dual endpoint feature
    // is deployed, also remove the default return as
    // per the new Marketing text for Dual EP those will be no longer
    // valid for Portal
    if (isDualEP) {
        return isProviderGCP(provider)
            ? i18n.t('dualEndpoints.privateServiceConnect')
            : i18n.t('dualEndpoints.privateLink')
    }

    return isProviderGCP(provider)
        ? i18n.t('privateConnect.privateServiceConnect')
        : i18n.t('privateConnect.privateLink')
}

export function getPrivateConnectServiceEndpoint(endpoints = []) {
    const privateConnect = getPrivateConnectObject(endpoints)
    return t(privateConnect, 'endpoint_service').safeString
}

function getPrivateConnectObject(endpoints = []) {
    return endpoints.find(isEndpointPrivateConnect)
}

export function getPrivateConnectProjects(endpoints, provider) {
    const privateConnectEndpoint = getPrivateConnectObject(endpoints)
    return isProviderGCP(provider)
        ? getPrivateConnectProjectsForGCP(privateConnectEndpoint)
        : getPrivateConnectProjectsForAWS(privateConnectEndpoint)
}

function getPrivateConnectProjectsForGCP(endpoint) {
    return t(endpoint, 'private_service_connect.projects').safeArray
}

function getPrivateConnectProjectsForAWS(endpoint) {
    return t(endpoint, 'private_link.accounts').safeArray
}

export function getPrivateConnectInstructionLink(provider) {
    return isProviderGCP(provider)
        ? 'https://r.mdbcdt.com/nr-psc'
        : 'https://r.mdbcdt.com/nr-privatelink'
}

export function getIpAllowList(endpoints = []) {
    const ipAllowListObject = getIpAllowedObject(endpoints)
    return t(ipAllowListObject, 'allow_list').safeArray
}

export function getIpAllowedObject(endpoints) {
    return endpoints.find(
        ({ mechanism, }) => mechanism === CONFIG.ENDPOINT_MECHANISM_NLB
    )
}

export function privateConnectEntityLabel(provider) {
    return isProviderGCP(provider) ? i18n.t('projectId') : i18n.t('accountId')
}

export function isOnPremService(provider) {
    return provider === 'onprem'
}

export function privateConnectEntity(provider) {
    return isProviderGCP(provider)
        ? i18n.t('privateConnect.project')
        : i18n.t('privateConnect.account')
}

export function getFilteredInstanceSizes(instances, params) {
    const { arch, provider, topology, } = params

    return instances.filter((instance) => {
        let isInstanceMatch = false
        // If providers is AWS
        // additionally need to filter out instances
        // for the current architecture
        if (isProviderAWS(provider)) {
            isInstanceMatch = instance.architecture === arch
            if (!isInstanceMatch) return isInstanceMatch
        }

        isInstanceMatch =
            instance.provider === provider && instance.topology === topology
        return isInstanceMatch
    })
}

export function hasMultipleEndpoints(endpoints = []) {
    return endpoints.length > 1
}

export function areBothSecureEndpointsAllowList(endpoints = []) {
    return (
        endpoints.filter((ep) => ep.mechanism === CONFIG.ENDPOINT_MECHANISM_NLB)
            .length > 1
    )
}

export function isPrimaryEndpointPrivateServiceConnect(endpoints = []) {
    const endpoint = getPrivateConnectObject(endpoints)
    if (endpoint) {
        return endpoint.name === 'primary'
    }
    return false
}

export function getReadWritePort(endpoint) {
    const { ports, } = endpoint
    if (!ports || !t(ports).safeArray || t(ports).isEmptyArray) return null
    return ports.find((port) => port.name === 'readwrite')
}

export function getReadOnlyPort(endpoint) {
    const { ports, } = endpoint
    if (!ports || !t(ports).safeArray || t(ports).isEmptyArray) return null

    return ports.find((port) => port.name === 'readonly')
}

export function isEndpointPrivateConnect(endpoint) {
    return endpoint.mechanism === CONFIG.ENDPOINT_MECHANISM_PRIVATELINK
}

export function isServiceStatusReady(service) {
    return service.status === CONFIG.serviceStatus.READY
}

export function getPrimaryEndpoint(endpoints = []) {
    return endpoints.find((endpoint) => endpoint.name === 'primary')
}

// There is no condition on naming of secondary endpoint
// to be secondary, it can be anything but primary
export function getSecondaryEndpoint(endpoints = []) {
    return endpoints.find((endpoint) => endpoint.name !== 'primary')
}

export function getLoadBalancerFQDN(endpoints) {
    const ipAllowListObject = getIpAllowedObject(endpoints)
    return t(ipAllowListObject, 'fqdn').safeString
}

export function isVersion23(version) {
    return version === '23.06.00-00' // hardcoding as per discussion for DBAAS-14051
}

export function sortServerVersions(serverVersions) {
    let sortedList = [...serverVersions].sort((version1, version2) => version1.version > version2.version ? 1 : -1)
    return sortedList.sort(({ version, }) => isVersion23(version) ? 1 : -1)
}

export function getEndpointFQDN(endpoint) {
    return t(endpoint, 'fqdn').safeString
}

export function getNoSQLPort(endpoint) {
    const { ports, } = endpoint
    if (!ports || !t(ports).safeArray || t(ports).isEmptyArray) return null

    return ports.find((port) => port.name === 'nosql')
}

export function isServicePendingCreate(service) {
    return service.status === CONFIG.serviceStatus.PENDING_CREATE
}
