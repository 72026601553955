<template>
    <div>
        <p class="mt-4">
            {{ $t('launchService.selectArchitecture') }}
            <HelpToolTip>
                <template #message>
                    <p>{{ $t('launchService.architectureInfo') }}</p>
                    <a
                        rel="noopener noreferrer"
                        class="text-capitalize"
                        target="_blank"
                        href="https://r.mariadb.com/snr-launch-hardware-architecture">{{ $t('learn-more') }}</a>
                </template>
            </HelpToolTip>
        </p>
        <v-radio-group
            v-model="selectedArchitecture"
            mandatory
            :data-auto="dataAutoPrefix('content-architecturesOptions')"
            @change="architectureSelection">
            <v-radio
                v-for="(architecture, index) in architectures"
                :key="architecture.name"
                :value="architecture.name"
                :label="architecture.displayName"
                :data-auto="dataAutoPrefix(`content-architectureSelection-${index + 1}`)" />
        </v-radio-group>
    </div>
</template>

<script>
    import HelpToolTip from 'components/common/HelpTooltip'
    export default {
        name: 'ArchitecturesList',
        components: { HelpToolTip, },
        props: {
            architectures: {
                type: Array,
                required: true,
            },
            parentDataAutoPrefix: {
                type: Function,
                required: true,
            },
            selectedArch: {
                type: String,
                required: true,
            },
        },
        data () {
            return {
                selectedArchitecture: this.selectedArch,
            }
        },
        watch: {
            selectedArch(newValue) {
                this.selectedArchitecture = newValue
            },
        },
        methods: {
            dataAutoPrefix (string) {
                return this.parentDataAutoPrefix(string)
            },
            architectureSelection (architectureName) {
                const architecture = this.architectures.find(({ name, }) => name === architectureName)
                if (architecture) this.$emit('architectureSelected', architecture)
            },
        },
    }
</script>
