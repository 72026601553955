import i18n from 'plugins/i18n'
import { isPrimaryReplica } from 'utils/service'

// TODO: This function has a confusing name and implications (needs refactoring)
export function hasNodesInTopology(topology) {
    return !isPrimaryReplica(topology)
}

export function getNodesTextForReplicatedTopology(nodeCount) {
    if (nodeCount > 1) {
        return `${i18n.t('launchService.primary')}, x${nodeCount - 1} ${i18n.tc(
            'launchService.replicas',
            nodeCount - 1
        )}`
    }
    return i18n.t('launchService.primary')
}

export function getNodesTextForNonReplicatedTopology(nodeCount) {
    return `x${nodeCount} ${i18n.tc('launchService.nodes', nodeCount)}`
}
