<style lang="scss" scoped>
    path {
        fill: currentColor;
    }
</style>

<template>
    <svg
        viewBox="0 0 16 16"
        fill="none">
        <g clip-path="url(#clip0_6:412)">
            <path
                d="M8.76452 7.53727L11.4071 5.34389C11.6662 5.11937 12.0462 5.15399 12.2709 5.41315C12.4955 5.67232 12.4437 6.06963 12.2019 6.27687L10.8547 7.38219L15.1561 7.40029C15.5016 7.40036 15.7781 7.67681 15.7608 8.00502C15.7609 8.17777 15.6918 8.31595 15.5709 8.43685C15.45 8.55775 15.3118 8.62682 15.1391 8.62679L10.855 8.62597L12.2026 9.7318C12.4618 9.95642 12.4964 10.3365 12.2719 10.5955C12.2546 10.6128 12.2373 10.6301 12.2373 10.6301C12.0128 10.8546 11.65 10.8718 11.4081 10.6645L8.7647 8.4701C8.62648 8.36642 8.54007 8.17638 8.54004 8.00364C8.55728 7.81362 8.62634 7.64089 8.76452 7.53727Z"
                fill="#979797" />
            <path
                d="M7.22677 7.55047C7.36499 7.65414 7.4514 7.84418 7.45143 8.01692C7.43418 8.17239 7.38239 8.32785 7.26149 8.44875L7.22695 8.4833L4.58435 10.6767C4.32527 10.9012 3.94522 10.8666 3.7206 10.6074C3.49598 10.3482 3.53046 9.96821 3.78954 9.74369L5.13675 8.63837L0.852627 8.603C0.524412 8.62021 0.247965 8.34376 0.247899 7.99827C0.230561 7.67005 0.506902 7.39371 0.852395 7.39377L5.13651 7.39459L3.78887 6.28876C3.52971 6.06414 3.49509 5.68409 3.71961 5.42501C3.92686 5.18321 4.32417 5.13146 4.58333 5.35608L7.22677 7.55047Z"
                fill="#979797" />
        </g>
        <defs>
            <clipPath id="clip0_6:412">
                <rect
                    width="16"
                    height="16"
                    fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
    export default {}
</script>
