<style lang="scss" scoped>
.config-selection-title {
    font-weight: 500;
}

.config-selection-subtitle {
    font-size: 10px;
    color: $grayed-out;
}

</style>
<style lang="scss">
    .selected-config-icon.v-icon {
        width: auto !important;
        svg {
            width: auto !important;
        }
    }
</style>

<template>
    <v-col class="text-center">
        <div>
            <slot></slot>
            <v-icon
                v-if="showInfo"
                class="selected-config-icon mb-2"
                :size="iconSize">
                {{ icon }}
            </v-icon>
            <p
                v-if="showInfo"
                :class="['mb-0 config-selection-title text-caption', {'text-capitalize': shouldCapitalize}]">
                {{ title }}
            </p>
        </div>
        <span class="text--disabled config-selection-subtitle">{{
            subTitle
        }}</span>
    </v-col>
</template>
<script>
    export default {
        name: 'SelectedConfig',
        props: {
            title: { type: String, default: '', },
            subTitle: { type: String, default: '', },
            icon: { type: String, default: '', },
            iconSize: { type: Number, default: 16, },
            showInfo: Boolean,
            shouldCapitalize: {
                default : false,
                type: Boolean,
            },
        },
    }
</script>
