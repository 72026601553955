<style lang="scss" scoped>
    path {
        fill: currentColor;
    }
</style>

<template>
    <svg
        viewBox="0 0 13 13"
        fill="none">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.5 13C10.0899 13 13 10.0899 13 6.5C13 2.91015 10.0899 0 6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0899 2.91015 13 6.5 13Z" />
        <path
            d="M4.20654 4.07851L8.8494 8.72137"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round" />
        <path
            d="M8.84912 4.07851L4.20626 8.72137"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round" />
    </svg>
</template>

<script>
    export default {}
</script>
