<style lang="scss" scoped>
.scale-reduce {
    transform: scale(0.725);
    transform-origin: 50% 50%;
}

.tags-list-chips-container {
    max-height: 250px;
    overflow-y: auto;
}

// Hide selected items
::v-deep .context-tag:not(.show) .v-select__selection {
    display: none;
}

// show placeholder even when selected items are hidden
::v-deep .context-tag.v-input--is-dirty ::placeholder {
    color: $navigation !important;
    opacity: 0.5;
}
</style>
<template>
    <div>
        <v-autocomplete
            ref="context"
            :value="getSelectedParentNodes"
            multiple
            :items="allSysTags"
            return-object
            item-text="txt"
            item-value="val"
            :placeholder="$t('contextPlaceholder')"
            :class="[{ show: showSelection }, 'std std--dropdown context-tag v-text-field--with-bottom-details']"
            :menu-props="{ bottom: true, offsetY: true, contentClass: 'std--dropdown-list' }"
            height="36"
            outlined
            attach
            single-line
            :rules="rules.sysTags"
            hide-details="auto">
            <template #item="{ on, item }">
                <v-list-item
                    class="list-item context-list-item d-flex align-center pr-6"
                    :class="{ 'font-weight-medium': item.isService }"
                    :style="tagLevelPadding(item)"
                    v-on="on"
                    @click="() => handleSelectTag(item)">
                    <v-checkbox
                        class="scale-reduce"
                        :input-value="item.selected"
                        primary />
                    <truncate-string
                        :text="item.txt"
                        :max-width="'190px'"
                        :dot-count="3"
                        :has-tooltip="true" />
                </v-list-item>
            </template>
            <template #selection="{ item, index }">
                <div
                    v-if="index === 0"
                    class="v-select__selection v-select__selection--comma">
                    <span v-if="(item.level > 1 && allSelectedServers.length > 1) || getSelectedParentNodes.length > 1">
                        {{ `${allSelectedServers.length} ${$t('selected')}` }}
                    </span>
                    <truncate-string
                        v-else
                        :text="item.txt"
                        :max-width="'190px'"
                        :dot-count="3"
                        :has-tooltip="true" />
                </div>
            </template>
        </v-autocomplete>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex'
    export default {
        name: 'SystemTags',
        props: {
            labels: { type: Object, required: true, },
            isNotifTags: { type: Boolean, default: false, },
            isRequired: { type: Boolean, default: false, },
            showSelection: { type: Boolean, default: false, },
            showOnlyTopologies: { type: Boolean, default: false, },
        },
        data() {
            return {
                ALL_REG: '(.*?)',
                allSysTags: [],
                rules: {
                    sysTags: this.isRequired ? [(val) => this.validateRequired(val)] : [],
                },
            }
        },
        computed: {
            ...mapGetters(['services']),
            selectedTags: function () {
                return this.allSysTags.filter(tag => tag.selected)
            },
            getSelectedParentNodes: function () {
                // This returns only selected parent nodes when all childrens are selected
                return this.selectedTags.filter(tag => !tag.isParentSelected)
            },
            allSelectedServers: function () {
                // get only server tags
                return this.selectedTags.filter(tag => !tag.val.includes('/all'))
            },
        },
        watch: {
            getSelectedParentNodes: {
                deep: true,
                handler(tags) {
                    if (this.isNotifTags) this.$emit('on-sys-tags-change', this.getNotifSysTag(tags))
                    else this.$emit('on-sys-tags-change', this.getRuleSysTags(tags))
                },
            },
        },
        methods: {
            createSysTags(topologies) {
                let results = []
                let services = this.$help.objects.clone(topologies)
                for (const { name: serviceName, servers, } of services) {
                    const serviceVal = `${serviceName}/all`
                    const serviceTag = {
                        type: 'context',
                        txt: `${serviceName}/Any`, // e.g. sd-htap-9/all will be labeled as sd-htap-9/Any
                        val: serviceVal,
                        serviceName: serviceName,
                        level: 1,
                        parentId: this.$config.allTopologiesTag,
                        childLength: servers.length,
                        leaf: false,
                        selected: false,
                        isService: true,
                        serverNames: servers.map(server => server.name),
                    }

                    results.push(serviceTag)
                    servers.forEach((server) => {
                        const serverVal = `${serviceName}/${server.name}`
                        const serverTag = {
                            type: 'context',
                            level: 2,
                            txt: serverVal,
                            val: serverVal,
                            serverName: server.name,
                            parentId: serviceVal,
                            parentName: serviceName,
                            leaf: true,
                            selected: false,
                        }

                        results.push(serverTag)
                    })
                }
                let allTags = {
                    type: 'context',
                    txt: this.$t('anyServer'),
                    val: this.$config.allTopologiesTag,
                    level: 0,
                    childLength: services.length,
                    leaf: false,
                    selected: false,
                }

                results.unshift(allTags)
                this.allSysTags = results
            },

            selectNotifSysTags(labels) {
                if (this.$help.unescapeAlertTags(labels.context) === this.$config.allTopologiesTag) {
                    this.handleSelectTag(this.getTagByVal(this.$config.allTopologiesTag))
                } else {
                    const allSysTags = this.$help.unescapeAlertTags(labels.context).split(',')
                    allSysTags.forEach(tag => this.handleSelectTag(this.getTagByVal(tag)))
                }
            },

            selectRuleSysTags(labels) {
                labels.context.split(',').forEach(tag => {
                    const item = this.getTagByVal(tag)
                    this.handleSelectTag(item)
                })
            },

            handleSelectSysTags(labels) {
                if (this.isNotifTags) this.selectNotifSysTags(labels)
                else this.selectRuleSysTags(labels)
            },

            getTagByVal(key) {
                return this.allSysTags.find((tag) => tag.val === key)
            },

            getNotifSysTag(tags) {
                let sysTags = {}
                const SLASH_ESCAPE = '\\/'
                let services = []
                let servers = []

                if (tags.length === 0) {
                    sysTags.mariadb = null
                    sysTags.kubernetesPodName = null
                    sysTags.compare = null
                    sysTags.context = null
                    return sysTags
                }
                const topologies = this.allSelectedServers.map(server => `${server.parentName}${SLASH_ESCAPE}${server.serverName}`)

                sysTags.compare = `(${topologies.join(',')}|.*)` // compare attribute: for FE matching function purpose

                for (const tag of tags) {
                    if (tag.val === this.$config.allTopologiesTag) {
                        sysTags.mariadb = this.ALL_REG
                        sysTags.kubernetesPodName = this.ALL_REG
                        sysTags.context = `(all${SLASH_ESCAPE}all|.*)`
                        return sysTags
                    }
                    if (tag.isService) {
                        services.push(tag.serviceName)
                        servers = [...servers, ...tag.serverNames]
                    } else {
                        services.push(tag.parentName)
                        servers.push(tag.serverName)
                    }
                }
                sysTags.mariadb = `^(${[...new Set(services)].join('|')})$`
                sysTags.kubernetesPodName = `^(${servers.join('|')})$`
                sysTags.context = `(${tags.map(tag => tag.val.replace(/\//g, SLASH_ESCAPE)).join(',')}|.*)`

                return sysTags
            },

            getRuleSysTags(tags) {
                let sysTags = {}
                if (tags.length === 0) {
                    sysTags.context = null
                    sysTags.compare = null
                    return sysTags
                }
                sysTags.context = tags.map(tag => tag.val).join(',')
                /* add `compare` to null if context is allTopologiesTag because
                 * default rules don't have compare property. Parent component
                 * needs to remove null property */
                if (sysTags.context !== this.$config.allTopologiesTag) {
                    sysTags.compare = this.allSelectedServers.map(server => server.val).join(',')
                } else sysTags.compare = null
                return sysTags
            },

            handleSelectChildrenTags(item) {
                this.allSysTags.forEach(tag => {
                    if (item.val === tag.parentId) {
                        tag.isParentSelected = item.selected
                        tag.selected = item.selected
                        if (!tag.leaf) this.handleSelectChildrenTags(tag)
                    }
                })
            },
            handleHideSelectedChildrenTags(item) {
                this.allSysTags.forEach(tag => {
                    if (item.val === tag.parentId) {
                        tag.isParentSelected = item.selected
                        if (!tag.leaf) this.handleHideSelectedChildrenTags(tag)
                    }
                })
            },

            handleSelectParentTags(item) {
                // parentItem or item, if parentItem is undefined then it is at root already
                const parentItem = this.getTagByVal(item.parentId) || item
                // siblings or children (children if parentItem is at root already)
                const siblings = this.selectedTags.filter(tag => tag.parentId === parentItem.val)

                // parent item need to be updated as well when all children items are selected and vice versa
                parentItem.selected = siblings.length === parentItem.childLength
                this.handleHideSelectedChildrenTags(parentItem)
                // recursive until see ancestor
                if (parentItem.parentId) {
                    const grandParent = this.getTagByVal(parentItem.parentId)
                    this.handleSelectParentTags(grandParent)
                }
            },

            /**
             * This function handles mutating selected key of each tag in this.allSysTags
             */
            handleSelectTag(item) {
                if (item) {
                    item.selected = !item.selected
                    this.handleSelectChildrenTags(item)
                    if (item.parentId) this.handleSelectParentTags(item)
                }
            },

            tagLevelPadding(item) {
                if (this.showOnlyTopologies && item.level === 2) { return { display: 'none !important', } }
                const basePadding = 8
                if (!item.level || item.level === 1) return { paddingLeft: `${basePadding}px`, }
                return {
                    paddingLeft: `${26 * (item.level - 1) + basePadding}px`,
                }
            },

            validateRequired(val) {
                if (val.length === 0) return this.$t('errors.fieldRequired', { field: this.$t('context'), })
                return true
            },
            closeDropdowns() {
                if (this.$refs.context && this.$refs.context.isFocused) {
                    this.$refs.context.blur()
                }
            },
        },
        created() {
            this.createSysTags(this.services)
        },
        mounted() {
            if (this.$typy(this.labels, 'context').isDefined) this.handleSelectSysTags(this.labels)
            else {
                // assign $config.allTopologiesTag tag if there is no context defined when mounted
                const item = this.getTagByVal(this.$config.allTopologiesTag)
                this.handleSelectTag(item)
            }
        },
    }
</script>
