<style lang="scss" scoped>
    path {
        fill: currentColor;
    }
</style>

<template>
    <svg viewBox="0 0 41 45">
        <path
            d="M 20.50203,1.5e-6 20.352492,0.0565715 0.52582,7.6461546 0.259092,7.7471912 v 0.286933 16.8522668 c 0,11.108009 9.06889,
            20.113607 20.24293,20.113607 11.174037,0 20.238886,-9.005598 20.238886,-20.113607 V 8.0341252 7.7471918 L 40.47418,7.6461552
            20.647508,0.0565725 Z m 0,3.524024 16.933092,6.4863025 v 14.876066 c 0,9.29195 -7.576177,16.819939 -16.933092,16.819939
            -9.356915,0 -16.937135,-7.527989 -16.937135,-16.819939 V 10.010328 Z m 6.559048,13.1989215 c -0.420592,0 -0.843204,0.15831
            -1.163897,0.476875 L 18.020664,25.0238 15.102838,22.122138 c -0.641395,-0.637384 -1.68236,-0.637384 -2.323754,0 -0.647257,
            0.643009 -0.647257,1.696911 0,2.339918 l 3.798831,3.770544 c 0.798453,0.7934 2.090892,0.793365 2.889539,0 l 8.753479,
            -8.696901 c 0.647238,-0.642991 0.647195,-1.692694 0,-2.335877 -0.320694,-0.318573 -0.739265,-0.476875 -1.159855,-0.476875 z" />
    </svg>
</template>

<script>
    export default {}
</script>
