<template>
    <svg
        width="18"
        height="13"
        viewBox="0 0 18 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1697_21344)">
            <path
                d="M-1.79004 0H19V12.7423H-1.79004V0Z"
                fill="#138808" />
            <path
                d="M-1.79004 0H19V4.3592H-1.79004V0Z"
                fill="#FF9933" />
            <path
                d="M-1.79004 4.35938H19V8.38325H-1.79004V4.35938Z"
                fill="white" />
            <path
                d="M8.70422 6.00088L9.27561 5.20952L8.87591 6.10014L9.76652 5.70043L8.97516 6.27182L9.94626 6.37108L8.97516 6.47033L9.76652 7.04172L8.87591 6.64202L9.27561 7.53264L8.70422 6.74127L8.60496 7.71237L8.50571 6.74127L7.93432 7.53264L8.33402 6.64202L7.4434 7.04172L8.23477 6.47033L7.26367 6.37108L8.23477 6.27182L7.4434 5.70043L8.33402 6.10014L7.93432 5.20952L8.50571 6.00088L8.60496 5.02979L8.70422 6.00088Z"
                stroke="#1E50A0"
                stroke-width="0.25"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M8.60533 8.04757C9.5313 8.04757 10.2819 7.29692 10.2819 6.37095C10.2819 5.44498 9.5313 4.69434 8.60533 4.69434C7.67936 4.69434 6.92871 5.44498 6.92871 6.37095C6.92871 7.29692 7.67936 8.04757 8.60533 8.04757Z"
                stroke="#1E50A0"
                stroke-width="0.5"
                stroke-linecap="round"
                stroke-linejoin="round" />
            <path
                d="M-1.79004 0H19V12.7423H-1.79004V0Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
        </g>
        <rect
            x="0.5"
            y="0.5"
            width="17"
            height="11.6844"
            stroke="#D2D2D2" />
        <defs>
            <clipPath id="clip0_1697_21344">
                <rect
                    x="1"
                    y="1"
                    width="16"
                    height="10.6844"
                    fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
    export default {}
</script>
