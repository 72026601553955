<template>
    <svg
        width="18"
        height="13"
        viewBox="0 0 18 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1697_21213)">
            <path
                d="M6.5 1H11.5V12H6.5V1Z"
                fill="white" />
            <path
                d="M3.5 1L1 1V12H3.5H6.5V1H3.5Z"
                fill="#137A08" />
            <path
                d="M14.5 1H11.5V12H14.5H17V1H14.5Z"
                fill="#F7941E" />
        </g>
        <rect
            x="0.5"
            y="0.5"
            width="17"
            height="11.6844"
            stroke="#D2D2D2" />
        <defs>
            <clipPath id="clip0_1697_21213">
                <rect
                    x="1"
                    y="1"
                    width="16"
                    height="10.6844"
                    fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
    export default {}
</script>
