import als from 'api/als'
import { queryCortex } from 'services/observability'
import { CONFIG } from 'config/'
export default {
    state: {
        alerts: [],
        alertsTotalCount: 0,
        alertsError: false,
        alertsLoading: false,
        activeInterval: null,
        cortexRegions: [],
        diskTypes:[],
        metrics: [],
    },
    mutations: {
        setAlerts (state, alerts) {
            state.alerts = alerts.alerts || []
            state.alertsTotalCount = alerts.count || 0
        },
        setAlertsLoading (state, payload) {
            state.alertsLoading = payload
        },
        setAlertsError (state, payload) {
            state.alertsError = payload
        },
        setMetrics (state, metrics) {
            state.metrics = metrics
        },
        setActiveInterval (state, intervalId) {
            state.activeInterval = intervalId
        },
    },
    actions: {
        async fetchAlerts ({ commit, }, { params, }) {
            commit('setAlertsLoading', true)
            try {
                const { data, } = await als.get('alerts', params)
                commit('setAlerts', data)
                commit('setAlertsError', false)
            } catch (error) {
                commit('showMessage', { text: 'Error fetching alerts.', type: 'error', })
                commit('setAlertsError', error)
            }
            commit('setAlertsLoading', false)
        },
        startAlertsInterval ({ dispatch, state, commit, }, { seconds = 60, params = {}, }) {
            clearInterval(state.activeInterval)
            dispatch('fetchAlerts', { params, })
            const intervalId = setInterval(() => { dispatch('fetchAlerts', { params, }) }, seconds * 1000)
            commit('setActiveInterval', intervalId)
        },
        stopAlertsInterval ({ state, commit, }) {
            clearInterval(state.activeInterval)
            commit('setActiveInterval', null)
        },
        async fetchMetrics ({ commit, }) {
            try {
                const { data, } = await als.get('metrics')
                commit('setMetrics', data)
            } catch (error) {
                commit('showMessage', { text: 'Error fetching metrics.', type: 'error', })
            }
        },
        async fetchDiskTypeInputs ({ state, }) {
            try {
                const query = 'sum by (disk_purpose)(kubelet_volume_stats_used_bytes)'
                const diskTypePromises = Object.values(CONFIG.CORTEX_REGIONS).map(cortexRegion => queryCortex(query, cortexRegion))

                const diskTypeResults = await Promise.all(diskTypePromises)

                let tmp = []
                diskTypeResults.forEach(diskType => {
                    const diskTypeResult = diskType.result.filter(e => e.metric.disk_purpose).map(e => e.metric.disk_purpose)
                    tmp = [...tmp, ...diskTypeResult]
                })
                state.diskTypes = tmp
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('error', error)
            }
        },
    },
    getters: {
        alerts: state => state.alerts,
        alertsTotalCount: state => state.alertsTotalCount,
        alertsLoading: state => state.alertsLoading,
        metrics: state => state.metrics,
        metric: (state) => (metricId) => {
            return state.metrics.find(metric => metric.id === metricId)
        },
        metricsMap: state => {
            let map = new Map()
            for (const metric of state.metrics) {
                map.set(metric.id, metric)
            }
            return map
        },
        diskTypes: state => state.diskTypes,
    },
}
