import Vue from 'vue'
import i18n from 'plugins/i18n'

export default {
    state: {
        isLoggedIn: false,
        user: '',
        role: 'admin',
        dashboards: [],
    },
    mutations: {
        setUser (state, user) {
            state.isLoggedIn = true
            const userObj = {
                name : `${user.first_name} ${user.last_name}`,
                firstName: user.first_name,
                lastName: user.last_name,
                hasTierSelection: false,
            }
            state.user = {...user, ...userObj,}
        },
        changeUser (state, { name, email, }) {
            if (name) state.name = name
            if (email) state.email = email
        },
        dashboards (state, dashboards) {
            state.dashboards = dashboards
        },
    },
    actions: {
        async setUser ({ commit, }, userObj) {
            commit('setUser', userObj)
            commit('hideOverlay')
        },

        async fetchUserDashboards ({ commit, state, }) {
            if (!state.isLoggedIn) return

            try {
                const res = await Vue.axios.get(
                    `/api/users/${state.id}/dashboards`
                )
                const dashboards = res.data

                commit('dashboards', dashboards)
            } catch (error) {
                commit('showMessage', {
                    text: i18n.t('errors.fetchUserDashboards'),
                    type: 'error',
                })
            }
        },
    },
    getters: {
        user: (state) => state.user,
        userDashboards: (state) => state.dashboards,
    },
}
